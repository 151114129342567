import { Upload } from "antd";
import _ from "lodash";
import React from "react";
import uparrow from "../assets/uparrow.png";
import { getS3UploadUrl, openNotification, uploadFiles } from "./../utils";

export default class FeaturedImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      uploadedImageList: [],
      fileList: []
    };
    this.showErrorNotification = _.debounce(this.showErrorNotification, 1000);
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  showErrorNotification = () => {
    openNotification("error", "You can only upload upto 3 images");
  };

  handleChange = ({ fileList }) => {
    // console.log(fileList);
    if (fileList.length <= 3) {
      this.props.onChange(fileList);
      this.setState({ fileList });
    } else {
      this.showErrorNotification();
      this.setState({ fileList: this.props.fileList });
      if (this.props.fileList.length === 0) {
        this.props.onChange(this.state.fileList);
        this.setState({ fileList: [] });
      }
    }
  };

  uploadToS3 = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    const signedUploadUrl = await getS3UploadUrl(
      `${file.name}`,
      `${file.type}`
    );
    const filesArray = [file];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    let uploadedImageList = this.state.uploadedImageList;
    uploadedImageList.push(uploadedResponse);
    this.setState({ uploadedImageList });
    this.props.onSuccess(uploadedImageList);
    onSuccess(uploadedResponse, file);
  };

  handleBeforeUpload = (file, fileList) => {
    if (this.props.fileList.length + fileList.length < 4) {
      return true;
    } else {
      this.props.onChange(
        this.state.fileList.filter(file => file.status === "done")
      );
      return false;
    }
  };

  render() {
    // console.log(this.props.multiple||false);
    return (
      <div className={this.props.className}>
        {this.props.isFeaturedImage ? (
          <Upload.Dragger
            beforeUpload={this.handleBeforeUpload}
            fileList={this.props.fileList}
            onPreview={this.handlePreview}
            multiple={this.props.multiple || false}
            onChange={this.handleChange}
            customRequest={this.uploadToS3}
          >
            <img src={uparrow} alt="uparrow" className="uparrow" />
            <p className="ant-upload-text">
              Drag & drop up to 3 images here or Browse to upload
            </p>
          </Upload.Dragger>
        ) : (
          <Upload.Dragger
            fileList={this.props.fileList}
            onPreview={this.handlePreview}
            multiple={this.props.multiple}
            onChange={this.handleChange}
            customRequest={this.uploadToS3}
          >
            <img src={uparrow} alt="uparrow" className="uparrow" />
            <p className="ant-upload-text">
              {this.props.displayText
                ? this.props.displayText
                : `Drag & drop an image here or Browse to upload`}
            </p>
          </Upload.Dragger>
        )}
      </div>
    );
  }
}
