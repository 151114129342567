import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Switch
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Prompt } from 'react-router'
import RichTextEditor from 'react-rte'
import Form from 'react-validation/build/form'
import BreadcrumbView from '../../components/BreadCrumb'
import ButtonC from '../../components/ButtonC'
import InputC from '../../components/InputC'
import WorkshopImageUpload from '../../components/ImageUploadCrop/workshopUpload'
import WorkshopPdfUpload from '../../components/workshopPdfUpload'
import WorkshopDateTime from '../../components/WorkshopDateTime'
import TextEditor from '../../components/TextEditor'
import { DateUtils } from 'react-day-picker'
import cc from 'currency-codes'
import {
  ADD_TAG,
  CHECK_YOUTUBE_LINK,
  GET_CREATE_ACTIVITY,
  GET_INTEREST,
  GET_TAGS
} from '../../graphql'

import {
  ADD_DRAFT_WORKSHOP,
  APPROVE_WORKSHOP,
  SEND_WORKSHOP_FOR_APPROVAL,
  VIEW_WORKSHOP,
  VIEW_DRAFT_WORKSHOP,
  EDIT_WORKSHOP,
  CHECK_AGE_GROUP
} from './graphql'

import deleteImage from '../../static/card/asdelete.png'

import {
  dragDrop_reorder,
  featuredImage_listStyle,
  openNotification,
  titleCase,
  triggerGAEvent,
  YOUTUBE_VIDEO_REGEX
} from '../../utils'
import { isRequired, required, youtubeUrl } from '../../validation'
import './createWorkshop.css'
import moment, { duration } from 'moment'

const Option = Select.Option
const RadioGroup = Radio.Group
const Panel = Collapse.Panel
const { TextArea } = Input
const { Content } = Layout

export default class EditWorkshopContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      workshop_draft_id: null,
      workshop_id: null,
      isFormSubmitting: false,
      longDescValue: RichTextEditor.createEmptyValue(''),
      learn_outcome: RichTextEditor.createEmptyValue(''),
      you_know: RichTextEditor.createEmptyValue(''),
      draft_status: 0,
      activities_id: null,
      user_id: null,
      shouldBlockNavigation: true,
      recommended: [{ title: '', link: '' }],
      ImagesfileList: [],
      pdfFileList: [],
      pdf_url: '',
      pdf_name: '',
      pdf_source_text: '',
      pdf_source_url: '',
      imageStepUpload: [],
      image_card_image: [],
      datapoints_array: [
        {
          value: '',
          act_data_grade: [],
          getselectdatapoint: []
        }
      ],
      material_required: [
        { name: '', image: '', fileList: [], position: 0 },
        { name: '', image: '', fileList: [], position: 1 }
        //{ name: "", image: "", fileList: [] },
      ],
      weekdays: [
        {
          name: 'M',
          day: 0,
          selected: false
        },
        {
          name: 'T',
          day: 1,
          selected: false
        },
        {
          name: 'W',
          day: 2,
          selected: false
        },
        {
          name: 'T',
          day: 3,
          selected: false
        },
        {
          name: 'F',
          day: 4,
          selected: false
        },
        {
          name: 'S',
          day: 5,
          selected: false
        },
        {
          name: 'S',
          day: 6,
          selected: false
        }
      ],
      is_loading: false,
      is_dragging: false,
      image_card_steps: '',
      name: ['Workshops', 'Workshops Card', 'Untitled Workshop'],
      // stepResult: [],
      // items: [],
      // mashup: [
      //   { id: "", data_point_details: [] },
      //   { id: "", data_point_details: [] },
      //   { id: "", data_point_details: [] },
      // ],
      stepTitle: '',
      stepDesc: '',
      selectedTags: [],
      post_prereq: [],
      // selectedTagsDatapoints: [],
      previewVisible: false,
      registration_status: true,
      previewImage: '',
      fileList: [],
      thumb_image: [],
      stepsView: false,
      act_int_cat: [],
      act_int: [],
      act_int_id: '',
      act_suitfor: [],
      workshop_age_group: [],
      act_age_dificulty: [],
      // getDataGrade: [],
      interest_categories_id: '',
      getselectint: [],
      workshop_Int_cat: [],
      workshop_Int_cat_ids: [],
      getselectdatapoint: [],
      m_workshop_tags: [],
      workshop_tags: [],
      tags: [],
      tag_ids: [],
      workshop_date: '',
      dateValue: '',
      selectedDays: [],
      indSelectedDays: [],
      trailer_link: '',
      you_knowVal: '',
      learn_outcomeVal: '',
      isPaid: '',
      workshop_type: '',
      changePrimaryHostModal: false,
      isApproved: false,
      primary_host: '',
      formValue: {
        workshop_name: '',
        work_int_cat_val: '',
        work_long_desc: '',
        act_type: '',
        status: 'Draft',
        act_state: 'Active',
        durationVal: 'hr',
        minimum_participants: '',
        maximum_participants: '',
        workshop_amount: '',
        // workshop_duration: '',
        workshop_durationTxt: '',
        workshop_minutes: '0m',
        workshop_currency: 'INR',
        repeatsType: 'dnr',
        trailer_url: '',
        workshop_time: '',
        workshop_Endtime: '',
        repeats_occurance: '1'
      },
      isConsecutive: true,
      isNC_commonTimings: true,
      changecomm: false,
      changesComments: '',
      displayNameVisible: false,
      imgVisible: false,
      // stepValue: "",
      // selectedInnovationSkills: null,
      editButtonVisibility: false,
      currency_arr: [],
      truthObj: {
        // workshop_duration: false,
        workshop_durationTxt: false,
        workshop_minutes: false,
        workshop_iduration: false,
        workshop_name: false,
        workshop_name_length: false,
        work_int_cat_val: false,
        getselectint: false,
        workshop_type: false,
        work_long_desc: false,
        workshop_amount: false,
        minimum_participants: false,
        maximum_participants: false,
        // prerequisites: false,
        durationVal: false,
        // video_link: false,
        // mashupData: false,
        ageData: false,
        materials_req: false,
        thumb_image: false,
        pdf: false,
        workshop_currency: false,
        repeatsType: false
      }
    }
    this.onDraftWorkshopApiCall = _.debounce(this.onDraftWorkshopApiCall, 3000)
  }

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem('role_id'))
    })
  }

  componentDidMount () {
    // console.log(this.props.history);
    this.getLocalStorage()
    this.getEmptyActivity()
    setTimeout(() => {
      if (this.props.history.location.pathname.includes('draft')) {
        this.fetchDraftData()
      } else {
        this.fetchWorkshopData()
      }
    }, 1500)

    this.setState(
      {
        selectedDays: [],
        workshop_date: new Date(moment().add(1, 'days')),
        workshopShow_date: new Date()
      },
      () => {
        this.state.selectedDays.map(x => {
          const dayNo = moment(x).day()
          let weekdays = this.state.weekdays.map(d => {
            d.selected = d.day == dayNo - 1
            return d
          })
          this.setState({
            weekdays
          })
        })
      }
    )
  }

  getEmptyActivity = () => {
    this.setState({ is_loading: true })
    axios
      .post(`${process.env.REACT_APP_API}`, GET_CREATE_ACTIVITY)
      .then(res => {
        const getResult = res.data.data
        let interestCategories = getResult.getGlobalInterestCategory.intrest_cat
          .filter(data => data.total_interest !== 0)
          .map(int => ({
            ...int,
            is_added:
              (getResult.getInterestCategory.intrest_cat || []).filter(
                data => data.id === int.id
              ).length !== 0
          }))
        this.setState({
          // workshop_age_group: age_grp,
          act_int_cat: interestCategories,
          act_suitfor: getResult.getSuitableFor,
          act_age_dificulty: getResult.getAge.age_dificulty,
          act_taxonomy: getResult.getDefultDataPoint[0].data_point_details,
          act_intelligence: getResult.getDefultDataPoint[1].data_point_details,
          act_innovation: getResult.getDefultDataPoint[2].data_point_details,
          act_data_grade: (getResult.getDataGrade.data_grade || []).filter(
            data => data.total_data_points !== 0
          ),
          is_loading: false,
          currency_arr: cc.codes()
        })
      })
      .catch(err => console.log(err))
    this.getTags()
  }

  getTags = () => {
    axios.post(`${process.env.REACT_APP_API}`, GET_TAGS).then(res => {
      // this.setState({ workshop_tags: res.data.data.getTags.tags });
      const tags = res.data.data.getTags.tags
      let uniqTag = _.uniqBy(tags, 'name')
      this.setState({ workshop_tags: uniqTag, m_workshop_tags: uniqTag })
    })
  }

  fetchDraftData = () => {
    let query = VIEW_DRAFT_WORKSHOP
    query.variables = {
      workshop_draft_id: Number(this.props.match.params.id)
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let getOneDraftWorkshop = res.data.data.getOneDraftWorkshop
        let getAgeGroup = res.data.data.getAge

        this.setState({ is_loading: true })
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else if (getOneDraftWorkshop.workshop_details.status === 'Approved') {
          openNotification('info', 'Workshop already Approved')
          this.setState({ shouldBlockNavigation: false })
          this.props.history.push(
            `/workshops/${getOneDraftWorkshop.workshop_details.activities_id}/view`
          )
        } else if (
          (this.state.role_id === 2 ||
            this.state.role_id === 4 ||
            this.state.role_id === 5) &&
          getOneDraftWorkshop.workshop_details.status === 'Pending'
        ) {
          openNotification('info', 'Workshop already Sent for Approval')
          this.setState({ shouldBlockNavigation: false })
          this.props.history.push(
            `/workshops/${getOneDraftWorkshop.workshop_details.id}/view/draft`
          )
        } else {
          console.log('draft workshop', res.data.data.getOneDraftWorkshop)
          let dur = getOneDraftWorkshop.workshop_details.workshop_duration
          // let mins1 = 0
          // let duration = '0'
          // if (dur.includes('h') || dur.includes('m')) {
          //   let spl = dur.split('h')
          //   let hr = spl[0]
          //   let min = spl[1].slice(0, -1)
          //   duration = hr
          //   mins = min ? `${min}m` : `0m`
          //   mins1 = min ? `${min}m` : mins1
          // }
          let form_value = {
            workshop_name:
              getOneDraftWorkshop.workshop_details.workshop_name || '',
            work_long_desc:
              getOneDraftWorkshop.workshop_details.description || '',
            status: getOneDraftWorkshop.workshop_details.status || '',
            workshop_currency:
              getOneDraftWorkshop.workshop_details.currency ||
              this.state.formValue.workshop_currency,
            workshop_amount:
              getOneDraftWorkshop.workshop_details.workshop_amount != 0
                ? getOneDraftWorkshop.workshop_details.workshop_amount
                : '',
            minimum_participants:
              getOneDraftWorkshop.workshop_details.minimum_participants || '',
            maximum_participants:
              getOneDraftWorkshop.workshop_details.maximum_participants || '',
            workshop_time: getOneDraftWorkshop.workshop_details.workshop_time,
            workshop_Endtime: getOneDraftWorkshop.workshop_details
              .workshop_EndTime
              ? getOneDraftWorkshop.workshop_details.workshop_EndTime
              : '',
            // workshop_duration: duration,
            // workshop_minutes: mins,
            workshop_durationTxt: dur,
            durationVal:
              getOneDraftWorkshop.workshop_details.workshop_duration_type,
            repeatsType: getOneDraftWorkshop.workshop_details.repeats_type,
            workshop_plan_id:
              getOneDraftWorkshop.workshop_details.workshop_plan_id,
            repeats_occurance: getOneDraftWorkshop.workshop_details.occurance
              ? getOneDraftWorkshop.workshop_details.occurance
              : this.state.formValue.repeats_occurance,
            endsOn:
              getOneDraftWorkshop.workshop_details.repeats_type == 'week'
                ? getOneDraftWorkshop.workshop_details.end_date
                  ? '1'
                  : '2'
                : '1'
          }
          let fileListCopy = getOneDraftWorkshop.workshop_details.featured_image.map(
            (image, index) => ({
              uid: image.id,
              name: index,
              response: image.image_url,
              status: 'done'
            })
          )
          setTimeout(() => {
            let ageGrpObj = {}

            getOneDraftWorkshop.Age_group.map(data => {
              ageGrpObj[data.age_group_id] = {
                is_active: !data.is_active,
                age: data.age,
                id: data.age_group_id
              }
            })

            const ageGroups = getAgeGroup.age_groups.map(agegrp => {
              if (ageGrpObj[agegrp.id]) {
                return ageGrpObj[agegrp.id]
              } else {
                return agegrp
              }
            })

            this.setState({ workshop_age_group: ageGroups })
          }, 0)
          setTimeout(() => {
            let ageGroups = this.state.workshop_age_group.map(agrGrp => {
              let diff =
                getOneDraftWorkshop.Age_group.filter(
                  x => x.age_group_id === agrGrp.id
                ).length !== 0
                  ? getOneDraftWorkshop.Age_group.filter(
                      x => x.age_group_id === agrGrp.id
                    )
                  : '1'
              return {
                ...agrGrp,
                is_active:
                  getOneDraftWorkshop.Age_group.filter(
                    x => x.age_group_id === agrGrp.id
                  ).length === 0
              }
            })
            this.setState({ workshop_age_group: ageGroups })
          }, 500)

          let materialsRequired = getOneDraftWorkshop.Materials_required
            ? getOneDraftWorkshop.Materials_required.map((matReq, index) => {
                return {
                  ...matReq,
                  fileList: [
                    {
                      uid: index,
                      url: matReq.image,
                      response: matReq.image,
                      name: matReq.name,
                      status: 'done'
                    }
                  ]
                }
              })
            : []
          if (materialsRequired.length == 1) {
            materialsRequired.push({
              name: '',
              image: '',
              fileList: []
            })
          }
          let pdfFileList = getOneDraftWorkshop.workshop_details.reference_link
            ? [
                {
                  uid: 1,
                  url: getOneDraftWorkshop.workshop_details.reference_link,
                  response: getOneDraftWorkshop.workshop_details.reference_link,
                  name: getOneDraftWorkshop.workshop_details.pdf_name,
                  status: 'done'
                }
              ]
            : []
          let interests =
            getOneDraftWorkshop.interest_details &&
            getOneDraftWorkshop.interest_details.interests.length > 0
              ? getOneDraftWorkshop.interest_details.interests
              : []
          let thumb_image = [
            { image_url: '', position: 0 },
            { image_url: '', position: 1 },
            { image_url: '', position: 2 }
          ]
          getOneDraftWorkshop.workshop_details.featured_image &&
          getOneDraftWorkshop.workshop_details.featured_image.length
            ? getOneDraftWorkshop.workshop_details.featured_image.forEach(
                (data, index) => {
                  thumb_image.splice(index, 1, {
                    image_url: data.image_url,
                    position: index
                  })
                }
              )
            : (thumb_image = [
                { image_url: '', position: 0 },
                { image_url: '', position: 1 },
                { image_url: '', position: 2 }
              ])

          let indDates = getOneDraftWorkshop.workshop_details.Dates
            ? getOneDraftWorkshop.workshop_details.Dates.map(x => {
                // let mins = '0m'
                // let mins1 = 0
                // let duration = '0'
                // let dur = x.duration
                // if (dur.includes('h') || dur.includes('m')) {
                //   let spl = dur.split('h')
                //   let hr = spl[0]
                //   let min = spl[1].slice(0, -1)
                //   duration = hr
                //   mins = min ? `${min}m` : `0m`
                //   mins1 = min ? `${min}m` : mins1
                // }
                // x.duration = duration
                // x.minutes = mins
                x.date = new Date(parseInt(x.workshop_Date))
                x.time = x.start_time
                x.Endtime = x.end_time
                x.durationTxt = x.duration
                return x
              })
            : []

          this.setState(
            {
              user_id: getOneDraftWorkshop.workshop_details.user_details.id,
              workshop_id: getOneDraftWorkshop.workshop_details.workshop_id,
              workshop_draft_id: getOneDraftWorkshop.workshop_details.id,
              formValue: form_value,
              longDescValue: this.state.longDescValue.setContentFromString(
                form_value.work_long_desc || '',
                'html'
              ),
              interest_categories_id: getOneDraftWorkshop.interest_details
                ? getOneDraftWorkshop.interest_details.interest_categories_id
                : '',
              isPaid: getOneDraftWorkshop.workshop_details.workshop_type || '',
              you_know: this.state.you_know.setContentFromString(
                getOneDraftWorkshop.workshop_details.prerequisites || '',
                'html'
              ),
              you_knowVal:
                getOneDraftWorkshop.workshop_details.prerequisites || '',
              learn_outcomeVal:
                getOneDraftWorkshop.workshop_details.learning_outcome || '',
              learn_outcome: this.state.learn_outcome.setContentFromString(
                getOneDraftWorkshop.workshop_details.learning_outcome || '',
                'html'
              ),
              trailer_link: getOneDraftWorkshop.workshop_details.trailer_link,
              changecomm: getOneDraftWorkshop.notifications
                ? getOneDraftWorkshop.notifications
                : false,
              changesComments: getOneDraftWorkshop.notifications
                ? getOneDraftWorkshop.notifications.comments
                : '',

              fileList: fileListCopy,
              thumb_image: thumb_image,
              // image_card_image: getOneDraftWorkshop.workshop_details.images
              //   ? getOneDraftWorkshop.workshop_details.images.map(
              //       data => data.image_url
              //     )
              //   : [],
              material_required:
                materialsRequired.length === 0
                  ? this.state.material_required
                  : materialsRequired,

              pdfFileList: pdfFileList,
              pdf_name: getOneDraftWorkshop.workshop_details.pdf_name,
              pdf_url: getOneDraftWorkshop.workshop_details.reference_link,
              selectedDays: getOneDraftWorkshop.workshop_details.workshop_schedule_date.map(
                x => new Date(x)
              ),
              weekdays: getOneDraftWorkshop.workshop_details.repeats_day
                ? this.state.weekdays.map(dd => {
                    if (
                      getOneDraftWorkshop.workshop_details.repeats_day.includes(
                        dd.day.toString()
                      )
                    ) {
                      dd.selected = true
                    }
                    return dd
                  })
                : this.state.weekdays,
              workshop_date: getOneDraftWorkshop.workshop_details.end_date
                ? getOneDraftWorkshop.workshop_details.end_date
                : this.state.workshop_date,
              isConsecutive:
                getOneDraftWorkshop.workshop_details.workshop_plan_id == 3
                  ? false
                  : true,
              isNC_commonTimings:
                getOneDraftWorkshop.workshop_details.is_common_timing != null
                  ? getOneDraftWorkshop.workshop_details.is_common_timing
                  : this.state.isNC_commonTimings,
              indSelectedDays: indDates,
              workshop_Int_cat: interests.length
                ? interests.map(intname => intname.name)
                : [],
              workshop_Int_cat_ids: interests.length
                ? interests.map(intname => intname.id)
                : [],
              tags: getOneDraftWorkshop.Workshop_tags
                ? getOneDraftWorkshop.Workshop_tags.map(tag => tag.name)
                : [],
              tag_ids: getOneDraftWorkshop.Workshop_tags
                ? getOneDraftWorkshop.Workshop_tags.map(tag => tag.id)
                : []
            },
            () => {
              this.setState({
                displayNameVisible: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] == 'pdf'
                  : false,
                isPdfType: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] == 'pdf'
                  : false,
                imgVisible: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] != 'pdf'
                  : false
              })
            }
          )
          setTimeout(() => {
            this.setState({ is_loading: false })
          }, 500)
        }
      })
      .catch(err => console.log(err))
  }

  fetchWorkshopData = () => {
    let query = VIEW_WORKSHOP
    query.variables = { id: Number(this.props.match.params.id) }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({ is_loading: true })
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          let getOneWorkshop = res.data.data.getOneWorkshop
          let getAgeGroup = res.data.data.getAge

          let dur = getOneWorkshop.workshop_details.workshop_duration
          let form_value = {
            workshop_name: getOneWorkshop.workshop_details.workshop_name || '',
            work_long_desc: getOneWorkshop.workshop_details.description || '',
            status: getOneWorkshop.workshop_details.status || '',
            workshop_currency:
              getOneWorkshop.workshop_details.currency ||
              this.state.formValue.workshop_currency,
            workshop_amount:
              getOneWorkshop.workshop_details.workshop_amount || '',
            minimum_participants:
              getOneWorkshop.workshop_details.minimum_participants || '',
            maximum_participants:
              getOneWorkshop.workshop_details.maximum_participants || '',
            workshop_time: getOneWorkshop.workshop_details.workshop_time,
            workshop_Endtime: getOneWorkshop.workshop_details.workshop_EndTime
              ? getOneWorkshop.workshop_details.workshop_EndTime
              : '',
            // workshop_duration: duration,
            // workshop_minutes: mins,
            workshop_durationTxt: dur,
            durationVal: getOneWorkshop.workshop_details.workshop_duration_type,
            repeatsType: getOneWorkshop.workshop_details.repeats_type,
            workshop_plan_id: getOneWorkshop.workshop_details.workshop_plan_id,
            repeats_occurance: getOneWorkshop.workshop_details.occurance
              ? getOneWorkshop.workshop_details.occurance
              : this.state.formValue.repeats_occurance,
            endsOn:
              getOneWorkshop.workshop_details.repeats_type == 'week'
                ? getOneWorkshop.workshop_details.end_date
                  ? '1'
                  : '2'
                : '1'
          }

          let fileListCopy = getOneWorkshop.workshop_details.featured_image.map(
            (image, index) => ({
              uid: image.id,
              name: index,
              response: image.image_url,
              status: 'done'
            })
          )

          setTimeout(() => {
            let ageGrpObj = {}
            getOneWorkshop.Age_group.map(data => {
              ageGrpObj[data.age_group_id] = {
                is_active: !data.is_active,
                age: data.age,
                id: data.age_group_id
              }
            })

            const ageGroups = getAgeGroup.age_groups.map(agegrp => {
              if (ageGrpObj[agegrp.id]) {
                return ageGrpObj[agegrp.id]
              } else {
                return agegrp
              }
            })
            this.setState({ workshop_age_group: ageGroups })
          }, 0)
          setTimeout(() => {
            let ageGroups = this.state.workshop_age_group.map(agrGrp => {
              let diff =
                getOneWorkshop.Age_group.filter(
                  x => x.age_group_id === agrGrp.id
                ).length !== 0
                  ? getOneWorkshop.Age_group.filter(
                      x => x.age_group_id === agrGrp.id
                    )
                  : '1'
              return {
                ...agrGrp,
                is_active:
                  getOneWorkshop.Age_group.filter(
                    x => x.age_group_id === agrGrp.id
                  ).length === 0
              }
            })
            this.setState({ workshop_age_group: ageGroups })
          }, 500)

          let materialsRequired = getOneWorkshop.Materials_required
            ? getOneWorkshop.Materials_required.map((matReq, index) => {
                return {
                  ...matReq,
                  fileList: [
                    {
                      uid: index,
                      url: matReq.image,
                      response: matReq.image,
                      name: matReq.name,
                      status: 'done'
                    }
                  ]
                }
              })
            : []
          if (materialsRequired.length == 1) {
            materialsRequired.push({
              name: '',
              image: '',
              fileList: []
            })
          }
          let pdfFileList = getOneWorkshop.workshop_details.reference_link
            ? [
                {
                  uid: 1,
                  url: getOneWorkshop.workshop_details.reference_link,
                  response: getOneWorkshop.workshop_details.reference_link,
                  name: getOneWorkshop.workshop_details.pdf_name,
                  status: 'done'
                }
              ]
            : []
          let interests =
            getOneWorkshop.interest_details &&
            getOneWorkshop.interest_details.interests.length > 0
              ? getOneWorkshop.interest_details.interests
              : []

          let thumb_image = [
            { image_url: '', position: 0 },
            { image_url: '', position: 1 },
            { image_url: '', position: 2 }
          ]
          getOneWorkshop.workshop_details.featured_image &&
          getOneWorkshop.workshop_details.featured_image.length
            ? getOneWorkshop.workshop_details.featured_image.forEach(
                (data, index) => {
                  thumb_image.splice(index, 1, {
                    image_url: data.image_url,
                    position: index
                  })
                }
              )
            : (thumb_image = [
                { image_url: '', position: 0 },
                { image_url: '', position: 1 },
                { image_url: '', position: 2 }
              ])

          let indDates = getOneWorkshop.workshop_details.Dates
            ? getOneWorkshop.workshop_details.Dates.map(x => {
                // let mins = '0m'
                // let mins1 = 0
                // let duration = '0'
                // let dur = x.duration
                // if (dur.includes('h') || dur.includes('m')) {
                //   let spl = dur.split('h')
                //   let hr = spl[0]
                //   let min = spl[1].slice(0, -1)
                //   duration = hr
                //   mins = min ? `${min}m` : `0m`
                //   mins1 = min ? `${min}m` : mins1
                // }
                // x.duration = duration
                // x.minutes = mins
                x.date = new Date(moment.utc(parseInt(x.workshop_Date)))
                x.time = x.start_time
                x.Endtime = x.end_time
                x.durationTxt = x.duration
                return x
              })
            : []

          this.setState(
            {
              isApproved: getOneWorkshop.workshop_details.status == 'Approved',
              user_id: getOneWorkshop.workshop_details.user_details.id,
              workshop_id: getOneWorkshop.workshop_details.id,
              workshop_draft_id:
                getOneWorkshop.workshop_details.workshop_draft_id,
              formValue: form_value,
              longDescValue: this.state.longDescValue.setContentFromString(
                form_value.work_long_desc || '',
                'html'
              ),
              interest_categories_id: getOneWorkshop.interest_details
                ? getOneWorkshop.interest_details.interest_categories_id
                : '',
              isPaid: getOneWorkshop.workshop_details.workshop_type || '',
              you_know: this.state.you_know.setContentFromString(
                getOneWorkshop.workshop_details.prerequisites || '',
                'html'
              ),
              you_knowVal: getOneWorkshop.workshop_details.prerequisites || '',
              learn_outcomeVal:
                getOneWorkshop.workshop_details.learning_outcome || '',
              learn_outcome: this.state.learn_outcome.setContentFromString(
                getOneWorkshop.workshop_details.learning_outcome || '',
                'html'
              ),
              trailer_link: getOneWorkshop.workshop_details.trailer_link,
              fileList: fileListCopy,
              thumb_image: thumb_image,
              // image_card_image: getOneWorkshop.workshop_details.images
              //   ? getOneWorkshop.workshop_details.images.map(
              //       data => data.image_url
              //     )
              //   : [],
              material_required:
                materialsRequired.length === 0
                  ? this.state.material_required
                  : materialsRequired,
              pdfFileList: pdfFileList,
              pdf_name: getOneWorkshop.workshop_details.pdf_name,
              pdf_url: getOneWorkshop.workshop_details.reference_link,
              selectedDays: getOneWorkshop.workshop_details.workshop_schedule_date.map(
                x => new Date(x)
              ),
              weekdays: getOneWorkshop.workshop_details.repeats_day
                ? this.state.weekdays.map(dd => {
                    if (
                      getOneWorkshop.workshop_details.repeats_day.includes(
                        dd.day.toString()
                      )
                    ) {
                      dd.selected = true
                    }
                    return dd
                  })
                : this.state.weekdays,
              workshop_date: getOneWorkshop.workshop_details.end_date
                ? getOneWorkshop.workshop_details.end_date
                : this.state.workshop_date,
              isConsecutive:
                getOneWorkshop.workshop_details.workshop_plan_id == 3
                  ? false
                  : true,
              isNC_commonTimings:
                getOneWorkshop.workshop_details.is_common_timing != null
                  ? getOneWorkshop.workshop_details.is_common_timing
                  : this.state.isNC_commonTimings,
              indSelectedDays: indDates,
              workshop_Int_cat: interests.length
                ? interests.map(intname => intname.name)
                : [],
              workshop_Int_cat_ids: interests.length
                ? interests.map(intname => intname.id)
                : [],
              tags: getOneWorkshop.Workshop_tags
                ? getOneWorkshop.Workshop_tags.map(tag => tag.name)
                : [],
              tag_ids: getOneWorkshop.Workshop_tags
                ? getOneWorkshop.Workshop_tags.map(tag => tag.id)
                : [],
              registration_status:
                getOneWorkshop.workshop_details.workshop_registration_status
            },
            () => {
              this.setState({
                displayNameVisible: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] == 'pdf'
                  : false,
                isPdfType: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] == 'pdf'
                  : false,
                imgVisible: this.state.pdf_url
                  ? this.state.pdf_url.split('.').reverse()[0] != 'pdf'
                  : false
              })
            }
          )
          setTimeout(() => {
            this.setState({ is_loading: false })
          }, 500)
        }
      })
      .catch(err => console.log(err))
  }

  getInterest = (val, interests = []) => {
    let query = GET_INTEREST
    query.variables = { interest_categories_id: val }
    axios.post(`${process.env.REACT_APP_API}`, query).then(res => {
      const interest_details = res.data.data.getInterest.interest_details.map(
        data => {
          data.is_active =
            interests.filter(int => int.id === data.id).length !== 0
          return data
        }
      )
      this.setState({ getselectint: interest_details })
      return res.data.data.getInterest.interest_details
    })
  }

  onSelectTags = value => {
    triggerGAEvent('Workshops', 'Adding Tags to workshop')
    let tag_ids = []
    let new_tags = []
    value.forEach(v => {
      let filter = this.state.workshop_tags.filter(
        tag => tag.name.trim().toLowerCase() === v.trim().toLowerCase()
      )
      if (filter.length) {
        tag_ids.push(filter[0].id)
      } else {
        new_tags.push(v)
      }
    })
    if (new_tags.length) {
      let mutation = ADD_TAG
      mutation.variables = { name: new_tags }
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          this.setState({
            tag_ids: [...tag_ids, ...res.data.data.AddTagsV2.tags_ids],
            tags: value
          })
          this.getTags()
        })
        .catch(err => console.log(err))
      this.onDraftApiCall()
    } else {
      this.setState({
        tag_ids: tag_ids,
        tags: value
      })
      this.onDraftApiCall()
    }
  }

  onChangeIndtimings = (i, val, key) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const { indSelectedDays } = this.state
    if (key == 'duration') {
      if (val.length) {
        if (isNaN(parseInt(val.charAt(0)))) {
          return
        } else if (parseInt(val.charAt(0)) <= 0) {
          return
        }
      }
    }
    indSelectedDays[i][key] = val
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangeIndDuration = (i, key, value, minkey = '') => {
    triggerGAEvent(
      'Workshop',
      'Entering duration for add or Editing an workshop'
    )
    const { indSelectedDays, truthObj } = this.state
    // if (key == 'duration') {
    //   if (value.length) {
    //     // if (isNaN(value.charAt(0))) {
    //     //   return
    //     // } else
    //     if (value.charAt(0) <= 0) {
    //       return
    //     }
    //   }
    // }
    // if (!value.includes('h') && !value.includes('m')) {
    //   truthObj['workshop_iduration'] = true
    // } else if (value.split(' ').length > 1) {
    //   if (value.includes('h') || value.includes('m')) {
    //     truthObj['workshop_iduration'] = false
    //   } else {
    //     truthObj['workshop_iduration'] = true
    //   }
    // } else {
    //   truthObj['workshop_iduration'] = false
    // }
    if (minkey == 'minutes') {
      indSelectedDays[i][minkey] = value
    }
    let min = '0'
    if (indSelectedDays[i]['minutes'].includes('m')) {
      let mv = indSelectedDays[i]['minutes'].split('m').shift()
      min = mv
        .split('')
        .reverse()
        .join('')
      min = min
        .slice(0, 2)
        .trim()
        .split('')
        .reverse()
        .join('')
    }

    let val =
      (parseInt(indSelectedDays[i][key]) ? `${indSelectedDays[i][key]}h` : ``) +
      (parseInt(indSelectedDays[i][key])
        ? parseInt(min) != 0
          ? ` ${min}m`
          : ``
        : parseInt(min) != 0
        ? `${min}m`
        : ``)
    // let minutes = parseInt(hr) * 60 + parseInt(min)
    indSelectedDays[i]['durationTxt'] = val
    this.setState({ indSelectedDays: indSelectedDays })
    this.onDraftApiCall()
  }

  confirmDialogModal = () => {
    const confirmDialog = Modal.confirm({
      // title: "Do you Want to proceed this?",
      // bodyStyle: {
      //   textAlign: 'justify'
      // },
      content:
        'You have sent for the changes required to the content creator and they are working on it. Do you want to ignore and proceed?',
      okText: 'Yes',
      onOk: () => {
        this.onChangePrimaryHostModal()
      }
    })
    this.setState({ confirmDialog })
  }

  onSearchSelect = (input, option) => {
    return option.props.children
      .toLowerCase()
      .trim()
      .includes(input.toLowerCase().trim())
  }

  onSelectInterestCat = value => {
    triggerGAEvent('Workshops', 'Adding Interest Categories to Workshop')
    let intCat_ids = []
    value.forEach(v => {
      let filter = this.state.act_int_cat.filter(
        tag => tag.name.trim().toLowerCase() === v.trim().toLowerCase()
      )
      if (filter.length) {
        intCat_ids.push(filter[0].id)
      }
    })
    this.setState({
      workshop_Int_cat_ids: intCat_ids,
      workshop_Int_cat: value
    })
    this.onDraftApiCall()
  }

  filterByAgeGroup = (contents, age) => {
    return contents
      .map(c => {
        if (
          !this.state.act_prereq
            .filter(p => p.id === c)[0]
            .age_group_id.includes(age)
        ) {
          return c
        }
      })
      .filter(c => c)
  }

  checkAgeGroup = age_group_id => {
    return new Promise(resolve => {
      let query = CHECK_AGE_GROUP
      query.variables = {
        age_group_id: age_group_id,
        workshop_id: this.state.workshop_id
      }
      axios.post(`${process.env.REACT_APP_API}`, query).then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          const check_ageGroup = res.data.data.checkAgeGroup.AgeStatus
          resolve(check_ageGroup)
        }
      })
    })
  }

  set_AgeGroupActiveN = async (i, e) => {
    var age_grp = [...this.state.workshop_age_group]
    if (this.state.isApproved) {
      if (!e.target.checked) {
        let checkAgeGroup_res = await this.checkAgeGroup(age_grp[i].id)
        if (checkAgeGroup_res) {
          const confirmDialog = Modal.confirm({
            title: '',
            icon: (
              <Icon
                style={{
                  background: '#FAAD14',
                  color: '#fff',
                  borderRadius: '50%'
                }}
                type='exclamation-circle'
              />
            ),
            centered: true,
            content: (
              <span>
                Learners from {age_grp[i].age} have shown interest in this
                workshop. Are you sure you want to edit this age category?
              </span>
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
              this.setAgeGroupActive(i, e)
            }
          })
          this.setState({ confirmDialog })
        } else {
          this.setAgeGroupActive(i, e)
        }
      } else {
        this.setAgeGroupActive(i, e)
      }
    } else {
      this.setAgeGroupActive(i, e)
    }
  }

  setAgeGroupActive = (i, e) => {
    triggerGAEvent('Workshops', 'Setting Age group for an Workshop')
    let age_grp = [...this.state.workshop_age_group]
    let age_groupSel = age_grp.filter(x => !x.is_active).map(r => r.is_active)

    if (age_grp[i].is_active) {
      if (
        (age_groupSel.length > 0 && i == 0 && age_grp[i + 1].is_active) ||
        (i != 0 &&
          age_groupSel.length > 0 &&
          age_grp[i - 1].is_active &&
          i != 0 &&
          age_groupSel.length > 0 &&
          (i == 6 ? age_grp[i - 1].is_active : age_grp[i + 1].is_active))
      ) {
        openNotification(
          'error',
          'Non-consecutive age groups can’t be selected'
        )
        return
      }
    }
    if (!age_grp[i].is_active) {
      if (
        (age_groupSel.length > 1 && i == 0 && age_grp[i + 1].is_active) ||
        (i != 0 &&
          age_groupSel.length > 0 &&
          !age_grp[i - 1].is_active &&
          i != 0 &&
          i != 6 &&
          age_groupSel.length > 0 &&
          !age_grp[i + 1].is_active)
      ) {
        openNotification(
          'error',
          'Non-consecutive age groups can’t be selected'
        )
        return
      }
    }

    if (age_grp[i].is_active) {
      if (e.target) {
        age_grp[i].age_group_id = e.target.value
      } else {
        age_grp[i].age_group_id = e
      }
      age_grp[i].is_active = !age_grp[i].is_active
      this.setState({ workshop_age_group: age_grp })
    } else {
      // const confirmDialog = Modal.confirm({
      //   title: "Do you want to change the age group selection?",
      //   content:
      //     "Prerequisites you selected already fall under this group, this action will be remove those items from this content. Do you want to continue?",
      //   okText: "Yes",
      //   onOk: () => {
      if (e.target) {
        age_grp[i].age_group_id = e.target.value
      } else {
        age_grp[i].age_group_id = e
      }
      age_grp[i].is_active = !age_grp[i].is_active
      const post_prereq = this.filterByAgeGroup(
        this.state.post_prereq,
        this.state.workshop_age_group[i].id
      )
      this.setState({
        workshop_age_group: age_grp,
        post_prereq: post_prereq
      })
      //   },
      // });
      // this.setState({ confirmDialog });
    }
    this.onDraftApiCall()
  }

  setAgeGroupDiff = (i, e) => {
    triggerGAEvent('Workshops', 'Setting Age group for an Activity')
    let age_grp = [...this.state.workshop_age_group]
    age_grp[i].age_group_dificulty_id = e
    this.setState({ workshop_age_group: age_grp })
    this.onDraftApiCall()
  }

  onSelectChange = (key, event) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an workshop')
    const state = { ...this.state }
    state.formValue[key] = event
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeNotReq = (key, value) => {
    triggerGAEvent('Workshops', 'Entering data for creating a new workshop')
    if (key == 'you_knowVal') {
      if (
        !this.state.you_know
          .getEditorState()
          .getCurrentContent()
          .hasText()
      ) {
        value = ''
      }
    }
    if (key == 'learn_outcomeVal') {
      if (
        !this.state.learn_outcome
          .getEditorState()
          .getCurrentContent()
          .hasText()
      ) {
        value = ''
      }
    }
    this.setState({ [key]: value })
    this.onDraftApiCall()
  }

  onChangeFee = (key, value) => {
    // const state = { ...this.state };
    this.setState({ [key]: value })
    this.onDraftApiCall()
  }

  TagClick = (tag, check, index, tagItem) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    if (tagItem !== 'data') {
      const state = [...this.state.getselectint]
      state[index].is_active = !state[index].is_active
      this.setState({ getselectint: state })
    } else {
      const state = [...this.state.getselectdatapoint]
      state[index].is_active = !state[index].is_active
      this.setState({ setselectdatapoint: state })
    }
    this.onDraftApiCall()
  }

  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return
    }

    if (
      moment(day).format('X') <
      moment()
        .add(-1, 'days')
        .format('X')
    ) {
      return
    }

    const { selectedDays, indSelectedDays } = this.state

    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      )
      selectedDays.splice(selectedIndex, 1)
      const indselectedIndex = indSelectedDays.findIndex(selectedday =>
        DateUtils.isSameDay(selectedday.date, day)
      )
      indSelectedDays.splice(indselectedIndex, 1)
    } else {
      selectedDays.push(day)
      indSelectedDays.push({
        date: day,
        time: '',
        Endtime: '',
        duration: '',
        minutes: '0m',
        durationTxt: '',
        durationVal: ''
      })
    }

    const selectedDays1 = selectedDays.sort(function (a, b) {
      return moment(a).format('X') - moment(b).format('X')
    })

    this.setState({ selectedDays: selectedDays1 })
    const isConsecutiveVal = []
    let selected_weekdays = []
    this.state.selectedDays.filter((day, i) => {
      let dayNo = moment(day).day()
      dayNo = dayNo == 0 ? 7 : dayNo
      selected_weekdays.push(dayNo - 1)

      if (
        i != this.state.selectedDays.length - 1 &&
        moment(selectedDays[i + 1]).format('YYYY-MM-DD') ===
          moment(selectedDays[i])
            .add(1, 'days')
            .format('YYYY-MM-DD')
      ) {
        isConsecutiveVal.push(true)
      } else {
        isConsecutiveVal.push(false)
      }
    })

    let weekdays = this.state.weekdays.map(d => {
      d.selected = selected_weekdays.includes(d.day)
      return d
    })
    this.setState({
      weekdays
    })

    const isconboolval = isConsecutiveVal.filter(boolval => !boolval).length > 1

    this.setState(
      {
        isConsecutive: !isconboolval
      },
      () => {
        const { formValue } = this.state
        formValue['repeatsType'] = this.state.isConsecutive
          ? 'dnr'
          : formValue['repeatsType']
        this.setState({
          isNC_commonTimings: this.state.isConsecutive
            ? true
            : this.state.isNC_commonTimings,
          formValue
        })
      }
    )
    this.onDraftApiCall()
  }

  onChangeDatePick = (date, dateValue) => {
    let dayNo = moment(date).day()
    let sel_weekdays = this.state.weekdays.map(d => {
      if (d.selected) {
        return d.day
      }
    })
    dayNo = dayNo == 0 ? 7 : dayNo
    if (sel_weekdays.includes(dayNo - 1)) {
      let workDate = moment(date).format('YYYY-MM-DD')
      this.setState(
        {
          workshop_date: workDate
        },
        () => this.onDraftApiCall()
      )
    } else {
      let wdate = moment(date).format('YYYY-MM-DD')
      let ldate = moment(
        this.state.selectedDays.length > 0
          ? this.state.selectedDays.reverse()[0]
          : new Date()
      )
        .add(7, 'days')
        .format('YYYY-MM-DD')
      if (wdate < ldate) {
        openNotification(
          'error',
          'Please ensure the chosen end date is one week from the current date'
        )
      } else {
        openNotification(
          'error',
          'There seems to be a mismatch between the days, making a repetitive occurrence impossible'
        )
      }
    }
  }

  onChangeTimePick = (time, timeValue) => {
    let { formValue } = this.state
    if (timeValue && formValue.workshop_Endtime) {
      var startTime = moment(timeValue, 'HH:mm A')
      var endTime = moment(formValue.workshop_Endtime, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification('error', 'Start time should be earlier than end time')
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      formValue['workshop_durationTxt'] = duration
    }
    formValue.workshop_time = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({
      formValue
    })
    this.onDraftApiCall()
  }

  onChangeEndTimePick = (time, timeValue) => {
    let { formValue } = this.state

    if (timeValue && formValue.workshop_time) {
      var startTime = moment(formValue.workshop_time, 'HH:mm A')
      var endTime = moment(timeValue, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification(
          'error',
          'The end time should be later than start time'
        )
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      formValue['workshop_durationTxt'] = duration
    }

    formValue.workshop_Endtime = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({
      formValue
    })
    this.onDraftApiCall()
  }

  onChangeIndTimePick = (i, time, timeValue) => {
    const { indSelectedDays } = this.state
    if (timeValue && indSelectedDays[i].Endtime) {
      var startTime = moment(timeValue, 'HH:mm A')
      var endTime = moment(indSelectedDays[i].Endtime, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification('error', 'Start time should be earlier than end time')
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      indSelectedDays[i]['durationTxt'] = duration
    }
    indSelectedDays[i].time = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangeIndEndTimePick = (i, time, timeValue) => {
    const { indSelectedDays } = this.state
    if (timeValue && indSelectedDays[i].time) {
      var startTime = moment(indSelectedDays[i].time, 'HH:mm A')
      var endTime = moment(timeValue, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification(
          'error',
          'The end time should be later than start time'
        )
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      indSelectedDays[i]['durationTxt'] = duration
    }
    indSelectedDays[i].Endtime = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangerepeatsType = (key, value) => {
    const { formValue } = this.state
    formValue[key] = value
    formValue['endsOn'] = value == 'week' ? '1' : null
    if (this.state.isConsecutive && value != 'dnr') {
      const selectedDays1 = this.state.selectedDays
        .sort(function (a, b) {
          return moment(a).format('X') - moment(b).format('X')
        })
        .reverse()
      this.setState({
        workshop_date:
          this.state.selectedDays.length > 0
            ? new Date(moment(selectedDays1[0]).add(7, 'days'))
            : this.state.workshop_date
      })
    }
    this.setState({ formValue })
    this.onDraftApiCall()
  }

  DataPointsTagClick = (tag, e, index, tagIndex, datapoints) => {
    triggerGAEvent('Workshops', 'Enter Mashup data for an activity')
    const state = { ...this.state }
    if (datapoints) {
      state.datapoints_array[index].getselectdatapoint[
        tagIndex
      ].is_active = !state.datapoints_array[index].getselectdatapoint[tagIndex]
        .is_active
    }
    this.setState({ datapoints_array: state.datapoints_array })
    this.onDraftApiCall()
  }

  materialRequiredOnchange = (key, index, value) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    const state = { ...this.state }
    state.material_required[index][key] = value
    this.setState({ material_required: state.material_required })
    this.onDraftApiCall()
  }

  onChangeDescEditor = (name, editor) => {
    this.setState({ [name]: editor })
  }

  materialRequiredImage = (file, index) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    const state = { ...this.state }
    // console.log(file)
    // file.url = file.length === 0 ? "" : file[0].response;
    state.material_required[index].fileList = file.map(data => {
      data.url = data.response
      return data
    })
    state.material_required[index].image =
      file.length === 0 ? '' : file[0].response
    this.setState({ material_required: state.material_required })
  }

  onSearchTags = input => {
    let tagsObj = {}

    const tags = this.state.workshop_tags.filter(data => {
      if (
        data.name
          .toLowerCase()
          .trim()
          .includes(input.toLowerCase().trim())
      ) {
        if (
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ]
        ) {
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        } else {
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ] = []
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        }
        return data
      }
      // return data.name.toLowerCase().trim()
    })
    // console.log(tagsObj)
    let orderedObj = []
    Object.keys(tagsObj)
      .sort()
      .forEach(function (key) {
        orderedObj.push(
          tagsObj[key].sort((a, b) =>
            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
              ? 1
              : b.name.toLowerCase().trim() > a.name.toLowerCase().trim()
              ? -1
              : 0
          )
        )
      })
    // console.log(orderedObj.flat(1))
    this.setState({ m_workshop_tags: orderedObj.flat(1) })

    // console.log(orderedObj.flat(1))
    // console.log(m_workshop_tags)
  }

  onClickMaterialRequired = () => {
    triggerGAEvent('Workshops', 'Add more materials required')
    const state = { ...this.state }
    const material_required = [
      { name: '', image: '', fileList: [], position: state.material_required.length },
      { name: '', image: '', fileList: [], position: (state.material_required.length) + 1 }
      // { name: "", image: "", fileList: [] },
    ]
    const mergematerial = state.material_required.concat(material_required)
    this.setState({ material_required: mergematerial })
  }

  onRemoveMaterialRequired = index => {
    triggerGAEvent('Workshops', 'remove materials required')
    const state = { ...this.state }
    state.material_required.splice(index, 1)
    this.setState({ material_required: state.material_required })
    this.onDraftApiCall()
  }

  addMoreDatapoints = () => {
    triggerGAEvent('Workshops', 'Add more data points')
    const state = { ...this.state }
    const datapoints = [
      {
        value: '',
        act_data_grade: [],
        getselectdatapoint: []
      }
    ]
    state.datapoints_array = state.datapoints_array.concat(datapoints)
    this.setState({ datapoints_array: state.datapoints_array })
  }

  removeDataPoints = index => {
    triggerGAEvent('Workshops', 'Remove data points')
    const state = { ...this.state }
    state.datapoints_array.splice(index, 1)
    this.setState({ datapoints_array: state.datapoints_array })
  }

  recommendedAddMore = () => {
    triggerGAEvent('Workshops', 'Add more useful links')
    const state = { ...this.state }
    const recommended = {
      title: '',
      link: ''
    }
    state.recommended = state.recommended.concat(recommended)
    this.setState({ recommended: state.recommended })
  }

  removeRecommend = index => {
    triggerGAEvent('Workshops', 'Remove useful links')
    const state = { ...this.state }
    state.recommended.splice(index, 1)
    this.setState({ recommended: state.recommended })
    this.onDraftApiCall()
  }

  onChangeRecommend = (key, event, index) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    const state = { ...this.state }
    state.recommended[index][key] = event.target.value
    this.setState({ recommended: state.recommended })
    this.onDraftApiCall()
  }

  onChange = (key, value) => {
    triggerGAEvent('Workshop', 'Entering data for Editing an workshop')
    const state = { ...this.state }
    if (key == 'minimum_participants') {
      if (value.length && value.charAt(0) == 0) {
        return
      }
    }
    if (key == 'workshop_duration') {
      if (value.length) {
        if (isNaN(parseInt(value.charAt(0)))) {
          return
        } else if (parseInt(value.charAt(0)) <= 0) {
          return
        }
      }
    }
    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeDuration = (key, value, minkey = '') => {
    triggerGAEvent(
      'Workshop',
      'Entering duration for add or Editing an workshop'
    )
    const state = { ...this.state }

    // if (key == 'workshop_duration') {
    //   if (value.length) {
    //     // if (isNaN(value.charAt(0))) {
    //     //   return
    //     // } else
    //     if (value.charAt(0) <= 0) {
    //       return
    //     }
    //   }
    // }
    if (minkey == 'workshop_minutes') {
      state.formValue[minkey] = value
    }

    let min = '0'
    if (state.formValue['workshop_minutes'].includes('m')) {
      let mv = state.formValue['workshop_minutes'].split('m').shift()
      min = mv
        .split('')
        .reverse()
        .join('')
      min = min
        .slice(0, 2)
        .trim()
        .split('')
        .reverse()
        .join('')
    }
    let val =
      (parseInt(state.formValue[key]) ? `${state.formValue[key]}h` : ``) +
      (parseInt(state.formValue[key])
        ? parseInt(min) != 0
          ? ` ${min}m`
          : ``
        : parseInt(min) != 0
        ? `${min}m`
        : ``)
    // let minutes = parseInt(state.formValue[key]) * 60 + parseInt(min)
    state.formValue['workshop_durationTxt'] = val
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeFeeAmount = (key, amnt) => {
    const { formValue } = { ...this.state }
    let val = amnt
    val = val.replace(/([^0-9.]+)/, '')
    val = val.replace(/^(0|\.)/, '')
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val)
    const value = match[1] + match[2]
    amnt = value
    formValue[key] = value
    this.setState({ formValue })
  }

  onChangeMinpart = (key, value, valid = false) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const state = { ...this.state }
    const re = /^[0-9\b]+$/
    if (value.length && value.charAt(0) == 0) {
      return
    }
    if (valid) {
      if (
        value != '' &&
        state.formValue['maximum_participants'] != '' &&
        parseInt(state.formValue['minimum_participants']) >=
          parseInt(state.formValue['maximum_participants'])
      ) {
        openNotification(
          'error',
          'Please ensure minimum participants are lesser than maximum participants'
        )
        state.formValue[key] = ''
        this.setState({ formValue: state.formValue })
        return
      }
    }

    if (value != '' && !re.test(value)) {
      return
    }

    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeMaxpart = (key, value, valid = false) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const state = { ...this.state }
    const re = /^[0-9\b]+$/
    if (value.length && value.charAt(0) == 0) {
      return
    }
    if (valid) {
      if (
        value != '' &&
        state.formValue['minimum_participants'] != '' &&
        parseInt(state.formValue['minimum_participants']) >=
          parseInt(state.formValue['maximum_participants'])
      ) {
        openNotification(
          'error',
          'Please ensure maximum participants are greater than minimum participants'
        )
        state.formValue[key] = ''
        this.setState({ formValue: state.formValue })
        return
      }
    }

    if (value != '' && !re.test(value)) {
      return
    }

    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeEditor = value => {
    triggerGAEvent(
      'Workshops',
      'Entering data for long description an workshop'
    )
    this.setState({ act_long_desc: value })
  }

  FeaturedImageUploadSuccess = (value, index) => {
    triggerGAEvent('Workshops', 'Uploading Featured Images')
    let thumb_image = [...this.state.thumb_image]
    thumb_image[index].image_url = value
    this.setState({ thumb_image })
    this.onDraftApiCall()
  }

  FeaturedImageUploadedFile = file => {
    this.setState({ fileList: file })
  }

  onPDFUpload = file => {
    if (file) {
      console.log('pdf file ==> ', file[0].type.split('/').reverse()[0])
      this.setState({
        displayNameVisible: file[0].type.split('/')[0] != 'image',
        imgVisible: file[0].type.split('/')[0] == 'image'
      })
    }
    this.setState({ pdfFileList: file })
  }

  onPDFUploadSuccess = value => {
    triggerGAEvent('Workshops', 'Add PDF file')
    this.setState({ pdf_url: value[0] })
    this.onDraftApiCall()
  }

  onPDFChange = (key, value) => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    let state = { ...this.state }
    state[key] = value
    this.setState({ ...state })
    this.onDraftApiCall()
  }

  deleteUploadPDF = () => {
    this.setState({
      pdf_url: '',
      pdfFileList: [],
      displayNameVisible: false,
      imgVisible: false
    })
  }

  FeaturedImageStepUploadSuccess = value => {
    triggerGAEvent('Workshops', 'Upload Images')
    this.setState({
      image_card_image: [
        ...this.state.image_card_image,
        value[value.length - 1]
      ]
    })
  }

  ImagesUploadedFile = file => {
    this.setState({ ImagesfileList: file })
  }

  deleteFeaturedImage = index => {
    triggerGAEvent('Workshops', 'Deleting Featured Images')
    let copyState = { ...this.state }
    copyState.thumb_image.splice(index, 1)
    this.setState({
      thumb_image: copyState.thumb_image
    })
    this.onDraftApiCall()
  }

  deleteStepImage = index => {
    triggerGAEvent('Workshops', 'Delete Images')
    let copyState = { ...this.state }
    copyState.ImagesfileList.splice(index, 1)
    copyState.image_card_image.splice(index, 1)
    this.setState({
      ImagesfileList: copyState.ImagesfileList,
      image_card_image: copyState.image_card_image
    })
  }

  handleStepsView = () => {
    triggerGAEvent('Workshops', 'Steps view')
    window.scrollTo(0, 0)
    this.setState({ stepsView: !this.state.stepsView })
  }

  onChangeStep = e => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    const stepLabel = e.target.name
    const stepValue = e.target.value
    this.setState({ [stepLabel]: stepValue })
  }

  onFeaturedDragStart = result => {
    this.setState({ is_dragging: true })
  }

  onFeaturedDragEnd = result => {
    triggerGAEvent('Workshops', 'Drag drop in Featured Images')
    if (!result.destination) {
      return
    }
    const thumb_image = dragDrop_reorder(
      this.state.thumb_image,
      result.source.index,
      result.destination.index
    )
    this.setState({ thumb_image, is_dragging: false })
  }

  onDragEnd = result => {
    triggerGAEvent('Workshops', 'Drag drop in Steps')
    if (!result.destination) {
      return
    }
    const items = dragDrop_reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )
    this.setState({ items })
  }

  onClickStep = e => {
    triggerGAEvent('Workshops', 'Entering data for Editing an activity')
    const newObj = {
      image: `${this.state.image_card_steps}`,
      position: this.state.items.length + 1,
      title: `${this.state.stepTitle}`,
      desc: `${this.state.stepDesc}`
    }
    const newStep = this.state.items.concat(newObj)
    this.setState({ items: newStep })
    this.setState({
      stepTitle: '',
      stepDesc: '',
      image_card_steps: '',
      imageStepUpload: []
    })
  }

  onCancelStep = () => {
    triggerGAEvent('Workshops', 'Cancel in Steps')
    this.setState({
      stepTitle: '',
      stepDesc: '',
      image_card_steps: '',
      imageStepUpload: [],
      editButtonVisibility: false
    })
  }

  onClickAddFeaturedImages = () => {
    triggerGAEvent('WorkshopFeatured_Images', 'Add more Featured Images')
    let position = this.state.thumb_image.length
    const AddThumb_img = [{ image_url: '', position: position - 1 + 1 }]
    const state = { ...this.state }
    const mergethumb_img = state.thumb_image.concat(AddThumb_img)
    this.setState({ thumb_image: mergethumb_img })
  }

  onRemoveBtnClick = index => {
    triggerGAEvent('Workshops', 'Delete Workshop Image')
    const state = { ...this.state }
    state.thumb_image.splice(index, 1)
    this.setState({ thumb_image: state.thumb_image })
  }

  stepsClick = e => {
    let sTitle = this.state.items[e].title
    let sImage = this.state.items[e].image
    let sDesc = this.state.items[e].desc
    this.setState({ editButtonVisibility: true, itemToEdit: e })
    this.setState({
      stepTitle: sTitle,
      image_card_steps: sImage,
      stepDesc: sDesc
    })
  }

  saveSteps = () => {
    triggerGAEvent('Workshops', 'Save details in Steps')
    let truthObj = { ...this.state.truthObj }
    if (this.state.formValue.video_link.length > 0) {
      truthObj['video_link'] = !YOUTUBE_VIDEO_REGEX.test(
        this.state.formValue.video_link
      )
      truthObj['video_credit_text'] = !this.state.formValue.video_credit_text
    } else {
      truthObj['video_link'] = false
      truthObj['video_credit_text'] = false
    }
    truthObj['video_credit_link'] = false
    truthObj['image_credit_text'] =
      this.state.image_card_image.length !== 0
        ? !this.state.formValue.image_credit_text
        : false
    truthObj['image_credit_link'] = false
    truthObj['steps'] =
      this.state.items.filter(
        step =>
          (step.desc === '' && step.image !== '') ||
          (step.desc !== '' && step.image === '')
      ).length !== 0
    truthObj['steps_credit_text'] =
      this.state.items.length !== 0
        ? !this.state.formValue.steps_credit_text
        : false
    truthObj['steps_credit_link'] = false
    this.setState({ truthObj })
    if (
      !truthObj['video_link'] &&
      !truthObj['video_credit_text'] &&
      !truthObj['image_credit_text'] &&
      !truthObj['steps'] &&
      !truthObj['steps_credit_text']
    ) {
      this.getSteps(this.state.items)
    }
  }

  getSteps = data => {
    this.setState({ stepResult: data })
    this.setState({ stepsView: false })
    this.onDraftApiCall()
  }

  stepImageUpload = file => {
    this.setState({ imageStepUpload: file })
  }

  onEditClick = () => {
    triggerGAEvent('Workshops', 'Edit Step')
    let copyOfState = { ...this.state }
    let indexOfItem = this.state.itemToEdit
    copyOfState.items[indexOfItem].desc = this.state.stepDesc
    copyOfState.items[indexOfItem].image = this.state.image_card_steps
    let sth = copyOfState.items

    this.setState({
      items: sth,
      stepTitle: '',
      image_card_steps: '',
      stepDesc: '',
      editButtonVisibility: false,
      itemToEdit: null
    })
  }

  onDeleteClick = e => {
    triggerGAEvent('Workshops', 'Delete Step')
    let copyOfState = { ...this.state }
    let copyOfItems = copyOfState.items
    copyOfItems.splice(e, 1)
    this.setState({ items: copyOfItems })
  }

  validateStateValuesForSending = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.formValue)
    getKeys.forEach((key, index) => {
      let getItem = this.state.formValue[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    truthObj['title'] = this.state.formValue.title === ''
    if (this.state.formValue.workshop_name.length > 140) {
      openNotification(
        'error',
        'Name of the Workshop can only be upto 140 characters'
      )
      truthObj['workshop_name_length'] =
        this.state.formValue.workshop_name.length > 140
    }
    let filterAgeGroup = _.filter(this.state.workshop_age_group, {
      is_active: false
    })
    truthObj['ageData'] = filterAgeGroup.length <= 0
    truthObj['thumb_image'] =
      this.state.thumb_image.filter(data => data.image_url).length < 1

    truthObj['materials_req'] =
      this.state.material_required.filter(x => {
        if (
          (x.name == '' && x.image != '') ||
          (x.name != '' && x.image == '')
        ) {
          return true
        }
      }).length > 0
    truthObj['work_int_cat_val'] = this.state.workshop_Int_cat_ids.length == 0
    truthObj['act_int_cat_val'] = this.state.workshop_Int_cat_ids.length == 0
    // let interests_ids = this.state.getselectint
    //   .filter(int => int.is_active)
    //   .map(int => parseInt(int.id, 10))
    // truthObj['getselectint'] = interests_ids.length < 1
    truthObj['selectedDays'] = this.state.selectedDays.length == 0
    truthObj['repeatsType'] = this.state.formValue.repeatsType === ''
    truthObj['endsOn'] =
      this.state.formValue.repeatsType == 'week'
        ? this.state.formValue.endsOn == ''
        : false
    truthObj['repeats_occurance'] =
      this.state.formValue.repeatsType == 'week'
        ? this.state.formValue.repeats_occurance == ''
        : false
    truthObj['workshop_date'] = this.state.workshop_date === ''
    let weekDays = this.state.weekdays.filter(dd => dd.selected).map(d => d.day)
    truthObj['weekdays'] =
      this.state.formValue.repeatsType == 'week' ? weekDays.length < 1 : false
    if (this.state.selectedDays.length > 0 && !this.state.isNC_commonTimings) {
      truthObj['selectedDays'] = !this.state.isNC_commonTimings
        ? this.state.indSelectedDays.filter(
            x => x.time == '' || x.Endtime == '' || x.durationVal == ''
          ).length > 0
        : false
    }
    truthObj['workshop_time'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_time == ''
    truthObj['workshop_Endtime'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_Endtime == ''
    // truthObj['workshop_duration'] = !this.state.isNC_commonTimings
    //   ? false
    //   : this.state.formValue.workshop_duration == ''
    truthObj['workshop_durationTxt'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_durationTxt == ''
    // truthObj['workshop_minutes'] = !this.state.isNC_commonTimings
    //   ? false
    //   : this.state.formValue.workshop_minutes == ''
    truthObj['work_long_desc'] = this.state.formValue.work_long_desc === ''
    truthObj['workshop_type'] = this.state.isPaid == ''
    truthObj['workshop_amount'] =
      this.state.isPaid == 'Paid'
        ? this.state.formValue.workshop_amount == ''
        : false
    truthObj['workshop_currency'] =
      this.state.isPaid == 'Paid'
        ? this.state.formValue.workshop_currency == ''
        : false
    truthObj['minimum_participants'] =
      this.state.formValue.minimum_participants === ''
    truthObj['maximum_participants'] =
      this.state.formValue.maximum_participants === ''
    truthObj['pdfDisplayName'] =
      this.state.pdf_url.split('.').reverse()[0] == 'pdf' &&
      this.state.pdf_name == ''
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  validateStateValues = () => {
    this.validateStateValuesForSending()
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      this.setState({ isFormSubmitting: false })
      openNotification('error', 'Please fill all mandatory fields correctly')
    } else {
      if (this.state.role_id === 1) {
        if (this.state.isApproved) {
          this.updateWorkshop()
        } else {
          this.approveWorkshop()
        }
      } else if (
        this.state.role_id === 2 ||
        this.state.role_id === 4 ||
        this.state.role_id === 5
      ) {
        this.saveDraftData()
        setTimeout(() => {
          this.sendForApproval()
        }, 1000)
      }
    }
  }

  checkYoutubeLink = key => {
    if (this.state[key] && YOUTUBE_VIDEO_REGEX.test(this.state[key])) {
      let query = CHECK_YOUTUBE_LINK
      query.variables = {
        url: this.state[key]
      }
      axios
        .post(`${process.env.REACT_APP_API}`, query)
        .then(res => {
          let truthObj = { ...this.state.truthObj }
          truthObj[key] = !res.data.data.checkValidUrl.embeddable
          this.setState({ truthObj })
          if (!res.data.data.checkValidUrl.embeddable) {
            message.error(
              'One of the video links added is not available for embedded view on iPad. Please check the highlighted section'
            )
          }
        })
        .catch(err => console.log(err))
    }
  }

  onViewLinkBlur = key => {
    if (this.state[key] && YOUTUBE_VIDEO_REGEX.test(this.state[key])) {
      this.checkYoutubeLink(key)
    } else {
      let truthObj = { ...this.state.truthObj }
      truthObj[key] = false
      this.setState({ truthObj })
    }
  }

  approveWorkshop = () => {
    // this.checkTitle();
    this.setState({ is_loading: true, changePrimaryHostModal: false })

    let mutation = APPROVE_WORKSHOP
    mutation.variables = {
      // default_activities_id:
      workshop_draft_id: this.state.workshop_draft_id,
      description: this.state.longDescValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.formValue.work_long_desc.replace('<script', '')
        : '',
      status: this.state.formValue.status,
      workshop_name: titleCase(this.state.formValue.workshop_name.trim()),
      featured_image: this.state.thumb_image
        .filter(data => data.image_url)
        .map(data => ({ image_url: data.image_url, position: data.position })),
      // featured_image: this.state.thumb_image,
      workshop_type: this.state.isPaid,
      workshop_amount: this.state.formValue.workshop_amount,
      maximum_participants: this.state.formValue.maximum_participants,
      minimum_participants: this.state.formValue.minimum_participants,
      // interest_categories_id: Number(this.state.formValue.work_int_cat_val),
      // skills_required: this.state.formValue.skills_required,
      // long_desc: this.state.longDescValue
      //   .getEditorState()
      //   .getCurrentContent()
      //   .hasText()
      //   ? this.state.formValue.act_long_desc.replace("<script", "")
      //   : "",
      workshop_time: this.state.formValue.workshop_time,
      workshop_duration: this.state.formValue.workshop_durationTxt,
      workshop_duration_type: this.state.formValue.durationVal,
      workshop_date: this.state.workshop_date,
      workshop_dates: this.state.selectedDays.map(x =>
        moment(x).format('YYYY-MM-DD')
      ),
      workshop_schedule_date: this.state.selectedDays.map(x =>
        moment(x).format('YYYY-MM-DD')
      ),
      workshop_plan_id:
        this.state.selectedDays.length == 1
          ? 1
          : this.state.isConsecutive
          ? 2
          : 3,
      repeats_type: this.state.formValue.repeatsType,
      repeats_day:
        this.state.formValue.repeatsType != 'dnr'
          ? this.state.weekdays.filter(dd => dd.selected).map(d => d.day)
          : null,
      repeats_occurance:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 2
            ? this.state.formValue.repeats_occurance
            : null
          : null,
      workshop_EndDate:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 1
            ? this.state.workshop_date
            : null
          : null,
      tags_id: this.state.tag_ids,
      currency: this.state.formValue.workshop_currency,
      pdf_name: this.state.pdf_name,
      // workshopdatedraft: this.state.formValue.act_time,
      learning_outcome: this.state.learn_outcome
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.learn_outcomeVal.replace('<script', '')
        : '',
      reference_link: this.state.pdf_url,
      workshopmaterials: this.state.material_required
        .filter(item => item.name !== '' && item.image !== '')
        .map(item => ({ name: item.name, image: item.image, position: item.position })),
      prerequisites: this.state.you_know
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.you_knowVal.replace('<script', '')
        : '',
      interest_id: this.state.workshop_Int_cat_ids.map(int =>
        parseInt(int, 10)
      ),
      age_group: this.state.workshop_age_group
        .filter(age => !age.is_active)
        .map(age => ({
          // age_group_difficulty_id: parseInt(age.age_group_dificulty_id, 10),
          age_group_id: age.id
        })),
      trailer_link: this.state.trailer_link,
      workshop_common_timing: this.state.isNC_commonTimings ? 1 : 0,
      primary_host: this.state.primary_host,
      workshop_registration_status: this.state.registration_status
    }

    if (!this.state.isConsecutive) {
      const indSelectDays = this.state.indSelectedDays.map(x => ({
        workshop_Date: moment(x.date).format('YYYY-MM-DD'),
        start_time: x.time,
        duration: x.durationTxt,
        duration_type: x.durationVal
      }))
      mutation.variables.workshop_nonConsecutive_type = indSelectDays
    }

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
        } else {
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
          openNotification('success', 'Workshop Content Approved Successfully')
          this.props.history.push(
            `/workshops/${res.data.data.approveWorkshop.workshop_details.id}/view`
          )
        }
      })
      .catch(err => console.log(err))
  }

  updateWorkshop = () => {
    this.setState({ is_loading: true, changePrimaryHostModal: false })

    let mutation = EDIT_WORKSHOP
    mutation.variables = {
      workshop_id: this.state.workshop_id,
      description: this.state.longDescValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.formValue.work_long_desc.replace('<script', '')
        : '',
      status: this.state.formValue.status,
      workshop_name: titleCase(this.state.formValue.workshop_name.trim()),
      featured_image: this.state.thumb_image
        .filter(data => data.image_url)
        .map(data => ({ image_url: data.image_url, position: data.position })),
      maximum_participants: this.state.formValue.maximum_participants,
      minimum_participants: this.state.formValue.minimum_participants,
      tags_id: this.state.tag_ids,
      pdf_name: this.state.pdf_name,
      learning_outcome: this.state.learn_outcome
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.learn_outcomeVal.replace('<script', '')
        : '',
      reference_link: this.state.pdf_url,
      workshopmaterials: this.state.material_required
        .filter(item => item.name !== '' && item.image !== '')
        .map(item => ({ name: item.name, image: item.image, position: item.position })),
      prerequisites: this.state.you_know
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.you_knowVal.replace('<script', '')
        : '',
      interest_id: this.state.workshop_Int_cat_ids.map(int =>
        parseInt(int, 10)
      ),
      age_group: this.state.workshop_age_group
        .filter(age => !age.is_active)
        .map(age => ({
          age_group_id: age.id
        })),
      trailer_link: this.state.trailer_link,
      primary_host: this.state.primary_host,
      workshop_registration_status: this.state.registration_status
    }

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
        } else {
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
          openNotification('success', 'Workshop updated successfully')
          this.props.history.push(
            `/workshops/${res.data.data.editWorkshop.workshop_details.id}/view`
          )
        }
      })
      .catch(err => console.log(err))
  }

  onChangePrimaryHostModal = () => {
    this.setState({
      changePrimaryHostModal: !this.state.changePrimaryHostModal
    })
  }

  onChangePrimaryHost = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  saveDraftData = () => {
    this.setState({ draft_status: 1 })
    // const dpoit = this.state.datapoints_array.map((data) => {
    //   const dps = data.getselectdatapoint.filter((dp) => dp.is_active);
    //   return {
    //     data_grade_types_id: Number(data.value),
    //     data_points_id: dps.map((dpss) => Number(dpss.id)),
    //   };
    // });
    // let stateMashup = this.state.mashup.map((data) => {
    //   if (_.isArray(data.data_point_details)) {
    //     return {
    //       data_grade_types_id: Number(data.id),
    //       data_points_id: data.data_point_details.map((dpss) => Number(dpss)),
    //     };
    //   } else {
    //     return {
    //       data_grade_types_id: Number(data.data_grade_types_id),
    //       data_points_id: data.data_points_id,
    //     };
    //   }
    // });
    // const mashup_dat = dpoit.concat(stateMashup);
    let selectedDays1 = this.state.selectedDays
    if (this.state.selectedDays.length) {
      selectedDays1 = this.state.selectedDays.sort(function (a, b) {
        return moment(a).format('X') - moment(b).format('X')
      })
    }
    let mutation = ADD_DRAFT_WORKSHOP
    mutation.variables = {
      // long_desc: this.state.longDescValue
      //   .getEditorState()
      //   .getCurrentContent()
      //   .hasText()
      //   ? this.state.formValue.work_long_desc.replace("<script", "")
      //   : "",
      workshop_id: this.props.history.location.pathname.includes('draft')
        ? this.state.workshop_id
        : Number(this.props.match.params.id),
      workshop_draft_id: this.props.history.location.pathname.includes('draft')
        ? Number(this.props.match.params.id)
        : this.state.workshop_draft_id,
      description: this.state.longDescValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.formValue.work_long_desc.replace('<script', '')
        : '',
      status: this.state.formValue.status,
      workshop_name: titleCase(this.state.formValue.workshop_name.trim()),
      featured_image: this.state.thumb_image
        .filter(data => data.image_url)
        .map(data => ({ image_url: data.image_url, position: data.position })),
      // featured_image: this.state.thumb_image,
      workshop_type: this.state.isPaid,
      workshop_amount: this.state.formValue.workshop_amount,
      maximum_participants: this.state.formValue.maximum_participants,
      minimum_participants: this.state.formValue.minimum_participants,
      interest_categories_id: Number(this.state.formValue.work_int_cat_val),
      // skills_required: this.state.formValue.skills_required,
      // long_desc: this.state.longDescValue
      //   .getEditorState()
      //   .getCurrentContent()
      //   .hasText()
      //   ? this.state.formValue.act_long_desc.replace("<script", "")
      //   : "",
      workshop_time: this.state.formValue.workshop_time,
      workshop_duration: this.state.formValue.workshop_durationTxt,
      workshop_duration_type: this.state.formValue.durationVal,
      workshop_date: moment(selectedDays1[0]).format('YYYY-MM-DD'),
      workshop_dates: selectedDays1.map(x => moment(x).format('YYYY-MM-DD')),
      workshop_schedule_date: selectedDays1.map(x =>
        moment(x).format('YYYY-MM-DD')
      ),
      workshop_plan_id:
        this.state.selectedDays.length == 1
          ? 1
          : this.state.isConsecutive
          ? 2
          : 3,
      repeats_type: this.state.formValue.repeatsType,
      repeats_day:
        this.state.formValue.repeatsType != 'dnr'
          ? this.state.weekdays.filter(dd => dd.selected).map(d => d.day)
          : null,
      repeats_occurance:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 2
            ? this.state.formValue.repeats_occurance
            : null
          : null,
      workshop_EndDate:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 1
            ? this.state.workshop_date
            : null
          : null,
      tags_id: this.state.tag_ids,
      currency: this.state.formValue.workshop_currency,
      pdf_name: this.state.pdf_name,
      // workshopdatedraft: this.state.formValue.act_time,
      learning_outcome: this.state.learn_outcome
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.learn_outcomeVal.replace('<script', '')
        : '',
      reference_link: this.state.pdf_url,
      workshopmaterials: this.state.material_required
        .filter(item => item.name !== '' && item.image !== '')
        .map(item => ({ name: item.name, image: item.image, position: item.position })),
      prerequisites: this.state.you_know
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.you_knowVal.replace('<script', '')
        : '',
      interest_id: this.state.workshop_Int_cat_ids.map(int =>
        parseInt(int, 10)
      ),
      age_group: this.state.workshop_age_group
        .filter(age => !age.is_active)
        .map(age => ({
          // age_group_difficulty_id: parseInt(age.age_group_dificulty_id, 10),
          age_group_id: age.id
        })),
      trailer_link: this.state.trailer_link,
      workshop_common_timing: this.state.isNC_commonTimings ? 1 : 0
    }

    if (!this.state.isConsecutive) {
      const indSelectDays = this.state.indSelectedDays.map(x => ({
        workshop_Date: moment(x.date).format('YYYY-MM-DD'),
        start_time: x.time,
        duration: x.durationTxt,
        duration_type: x.durationVal
      }))
      mutation.variables.workshop_nonConsecutive_type = indSelectDays
    }

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            draft_status: 0,
            shouldBlockNavigation: false
          })
        } else {
          this.setState({
            draft_status: 2,
            shouldBlockNavigation: false,
            workshop_draft_id: res.data.data.addWorkshopDraft.id
          })
        }
      })
      .catch(err => console.log(err))
  }

  onDraftApiCall = () => {
    this.setState({ draft_status: 0 })
    this.onDraftWorkshopApiCall()
  }

  onDraftWorkshopApiCall = () => {
    triggerGAEvent('Workshops', 'Save Workshop Draft')
    if (
      (this.state.role_id === 2 ||
        this.state.role_id === 4 ||
        this.state.role_id === 5) &&
      this.state.formValue.workshop_name !== '' &&
      !this.state.truthObj['workshop_name']
    ) {
      this.saveDraftData()
    }
  }

  sendForApproval = () => {
    this.setState({ is_loading: true })
    let mutation = SEND_WORKSHOP_FOR_APPROVAL
    mutation.variables = {
      workshop_draft_id: this.state.workshop_draft_id
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
        } else {
          if (res.data.data.sendWorkshopApproval) {
            this.setState({
              is_loading: false,
              loading: false,
              shouldBlockNavigation: false,
              isFormSubmitting: false
            })
            openNotification('success', 'Workshop content sent for Approval')
            this.props.history.push(
              `/workshops/${this.state.workshop_draft_id}/view/draft`
            )
          }
        }
      })
      .catch(err => console.log(err))
  }

  render () {
    return (
      <Layout className=''>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div className='bg-white'>
          <BreadcrumbView
            title={`${
              this.state.formValue.workshop_name
                ? this.state.formValue.workshop_name
                : `Untitled ${this.props.match.params.id}`
            }`}
            data={
              !this.state.stepsView
                ? `/Workshops/${(this.state.formValue.workshop_name
                    ? this.state.formValue.workshop_name
                    : `Untitled ${this.props.match.params.id}`
                  ).replace(/\//g, '~~')}`
                : `/Workshops/${(this.state.formValue.workshop_name
                    ? this.state.formValue.workshop_name
                    : `Untitled ${this.props.match.params.id}`
                  ).replace(/\//g, '~~')}`
            }
          >
            {this.state.stepsView && (
              <div>
                <ButtonC
                  category='Workshops'
                  action='Cancel changes in steps'
                  size='large'
                  className='mr-3'
                  onClick={() =>
                    this.setState({
                      items: this.state.stepResult,
                      stepsView: false
                    })
                  }
                >
                  Cancel
                </ButtonC>
                <Button
                  size='large'
                  className='mr-3'
                  type='primary'
                  onClick={this.saveSteps}
                >
                  Save details
                </Button>
              </div>
            )}
            {!this.state.stepsView && (
              <div>
                {[2, 4, 5].includes(this.state.role_id) && (
                  <div className='d-flex'>
                    <div
                      className='d-flex justify-content-center align-items-center mr-4'
                      style={{ flexDirection: 'column' }}
                    >
                      <span
                        className='font-14'
                        style={{
                          color: '#DA375B',
                          fontWeight: 800,
                          alignSelf: 'flex-end'
                        }}
                      >
                        Draft
                      </span>
                      {this.state.draft_status === 0 && (
                        <span
                          className='font-14'
                          style={{ color: '#9B9B9B', fontWeight: 800 }}
                        >
                          Unsaved Changes
                        </span>
                      )}
                      {this.state.draft_status === 1 && (
                        <span
                          className='font-14'
                          style={{ color: '#9B9B9B', fontWeight: 800 }}
                        >
                          Saving
                        </span>
                      )}
                      {this.state.draft_status === 2 && (
                        <span
                          className='font-14'
                          style={{ color: '#9B9B9B', fontWeight: 800 }}
                        >
                          Saved
                        </span>
                      )}
                    </div>
                    <ButtonC
                      category='Workshops'
                      action='Cancel the changes'
                      size='large'
                      className='mr-3'
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </ButtonC>
                    <ButtonC
                      category='Workshops'
                      action='Sending Workshop for Approval'
                      type='primary'
                      size='large'
                      onClick={this.validateStateValuesForSending}
                      className='btn-w250'
                      disabled={
                        this.state.formValue.workshop_name == '' ||
                        this.state.isFormSubmitting
                      }
                      loading={this.state.isFormSubmitting}
                    >
                      Send for Approval
                    </ButtonC>
                  </div>
                )}
                {this.state.role_id === 1 && (
                  <React.Fragment>
                    <div className='d-flex flex-column'>
                      <div className='d-flex mb-2 justify-content-between'>
                        <div>
                          <ButtonC
                            category='Workshops'
                            action='Cancel the changes'
                            size='large'
                            className='mr-3'
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </ButtonC>
                        </div>
                        <div>
                          <ButtonC
                            category='Workshops'
                            action='Approve Workshop'
                            type='success'
                            className='btn-w250 workshop-approve'
                            size='large'
                            block
                            onClick={() =>
                              this.state.formValue.status == 'Rejected'
                                ? this.confirmDialogModal()
                                : this.onChangePrimaryHostModal()
                            }
                            disabled={this.state.isFormSubmitting}
                            loading={this.state.isFormSubmitting}
                          >
                            {this.state.isApproved
                              ? 'Save changes'
                              : 'Proceed to approve'}
                          </ButtonC>
                        </div>
                      </div>
                      <div className='d-flex mt-2 mb-2 align-items-center justify-content-between'>
                        <div className='toggle-Btn-Txt'>
                          Allow registrations after workshop start date
                        </div>
                        <div className='toggle-Btn'>
                          <Switch
                            checked={this.state.registration_status}
                            onChange={checked =>
                              this.setState({
                                registration_status: checked
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </BreadcrumbView>
        </div>
        {this.state.is_loading && (
          <div className='vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center'>
            <Icon type='loading' theme='outlined' className='loading' />
          </div>
        )}

        <Content className='activityPageScroll' style={{ padding: 32 }}>
          {[1, 2, 4, 5].includes(this.state.role_id) && this.state.changecomm && (
            <div className='mb-4'>
              <div className='border-style Changecomment-border'>
                <span>
                  <h6 className='Changecomment'>
                    <Icon className='Changecomment-icon' type='info-circle' />
                    &emsp;&emsp;&emsp;
                    <span>
                      {this.state.changesComments
                        ? this.state.changesComments
                        : `Time needs to be changed. Available times: 2 - 3pm, 4 - 5pm`}
                    </span>
                  </h6>
                </span>
              </div>
            </div>
          )}

          <div style={{ minHeight: 280 }} className='create-activity'>
            {!this.state.stepsView && (
              <Form layout='vertical' ref='get_form_values'>
                <div className='bg-white p-4 rounded'>
                  <InputC
                    title='WORKSHOP NAME'
                    id={
                      this.state.truthObj['workshop_name'] ||
                      this.state.truthObj['workshop_name_length']
                        ? 'has-error'
                        : ''
                    }
                    className='col-12 ant-input ant-input-lg mb-4'
                    titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk'
                    placeholder='Enter workshop name'
                    value={this.state.formValue.workshop_name}
                    stateKey='workshop_name'
                    onChange={this.onChange}
                    // onBlur={this.checkTitle}
                    validations={[required]}
                    autoFocus
                  />

                  <div className='mb-2' />

                  <div className='mb-4 FontAvenirRoman text-secondary text-uppercase font-16'>
                    Featured Images
                    <span className='requiredAsterisk'></span>
                    <div
                      className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                      onClick={this.onClickAddFeaturedImages}
                    >
                      Add more
                    </div>
                  </div>
                  <div className='row mt-1'>
                    {this.state.thumb_image.map((data, index) => (
                      <div
                        className='col-4 featured-img-upload pl-2 pr-2 pt-3'
                        key={index}
                      >
                        <WorkshopImageUpload
                          onSuccess={data =>
                            this.FeaturedImageUploadSuccess(data, index)
                          }
                          imageUrl={data.image_url}
                          index={index}
                          aspect={16 / 9}
                          width={378}
                          height={212}
                          imageClassName='position-relative d-flex align-items-center justify-content-center'
                          imageStyle={{ height: 212, width: 378 }}
                          onClick={this.onRemoveBtnClick}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='pt-4 mb-2' />

                  <div
                    className={`mb-2 FontAvenirRoman text-secondary font-16 requiredAsterisk
                    ${this.state.isApproved ? 'Txt-disable' : ''}`}
                    id={
                      this.state.truthObj['selectedDays'] ||
                      this.state.truthObj['workshop_time'] ||
                      this.state.truthObj['workshop_Endtime'] ||
                      this.state.truthObj['workshop_iduration']
                        ? 'has-error-label'
                        : ''
                    }
                  >
                    DATE AND TIME
                  </div>

                  <WorkshopDateTime
                    selectedDays={this.state.selectedDays}
                    workshopShow_date={this.state.workshopShow_date}
                    setSelectedDays={day =>
                      this.setState(
                        {
                          day
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                    setHandleDayClick={this.handleDayClick}
                    onChangeIndTimePick={this.onChangeIndTimePick}
                    onChangeIndEndTimePick={this.onChangeIndEndTimePick}
                    onChangeIndtimings={this.onChangeIndtimings}
                    onChangeIndDuration={this.onChangeIndDuration}
                    durationVal={this.state.formValue.durationVal}
                    onSelectChange={this.onSelectChange}
                    onChangeDatePick={this.onChangeDatePick}
                    workshop_date={this.state.workshop_date}
                    onChangeTimePick={this.onChangeTimePick}
                    onChangeEndTimePick={this.onChangeEndTimePick}
                    repeats_occuranceVal={
                      this.state.formValue.repeats_occurance
                    }
                    setonChangeAfter={repeats_occurance => {
                      let { formValue } = this.state
                      formValue['repeats_occurance'] = repeats_occurance
                      this.setState(
                        {
                          formValue
                        },
                        () => this.onDraftApiCall()
                      )
                    }}
                    endsOnVal={this.state.formValue.endsOn}
                    setEndsOn={(endsOn, repeats_occurance) => {
                      let { formValue } = this.state
                      formValue['endsOn'] = endsOn
                      formValue['repeats_occurance'] = repeats_occurance
                      this.setState(
                        {
                          formValue
                        },
                        () => this.onDraftApiCall()
                      )
                    }}
                    workshop_time={this.state.formValue.workshop_time}
                    workshop_Endtime={this.state.formValue.workshop_Endtime}
                    datetime_truthObj={this.state.truthObj}
                    isApproved={this.state.isApproved}
                    // workshop_duration={this.state.formValue.workshop_duration}
                    // workshop_minutes={this.state.formValue.workshop_minutes}
                    onChange={this.onChange}
                    onChangeDuration={this.onChangeDuration}
                    IndselectedDays={this.state.indSelectedDays}
                    onChangeRepeatsType={this.onChangerepeatsType}
                    repeatsType={
                      !this.state.isConsecutive
                        ? 'dnr'
                        : this.state.formValue.repeatsType
                    }
                    isConsecutiveType={this.state.isConsecutive}
                    setIsConsecutiveType={isConsecutive =>
                      this.setState(
                        {
                          isConsecutive
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                    isNC_commonTimings={this.state.isNC_commonTimings}
                    setIsNC_commonTimings={isNC_commonTimings =>
                      this.setState(
                        {
                          isNC_commonTimings
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                    weekdays={this.state.weekdays}
                    onChangeDaySelect={weekdays =>
                      this.setState(
                        {
                          weekdays
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                  />

                  <div className='pt-4 mb-2' />
                  <React.Fragment>
                    <Row>
                      <InputC
                        id={
                          this.state.truthObj['trailer_link'] ? 'has-error' : ''
                        }
                        title='TRAILER LINK'
                        className='col-12 ant-input ant-input-lg'
                        placeholder='Enter trailer link'
                        value={this.state.trailer_link}
                        titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16'
                        stateKey='trailer_link'
                        onChange={this.onChangeNotReq}
                        onBlur={() => this.onViewLinkBlur('trailer_link')}
                        validations={[youtubeUrl]}
                      />
                    </Row>
                    <div className='pt-4 mb-2' />
                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4 requiredAsterisk'
                      id={
                        this.state.truthObj['work_long_desc']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      WORKSHOP DESCRIPTION
                    </div>
                    <TextEditor
                      value={this.state.formValue.work_long_desc}
                      data={this.state.longDescValue}
                      onEditorChange={e =>
                        this.onChangeDescEditor('longDescValue', e)
                      }
                      onChange={value => {
                        this.onChange('work_long_desc', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk'
                      id={
                        this.state.truthObj['getselectint']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      AREAS OF INTEREST
                    </div>

                    <Select
                      size='large'
                      placeholder='Select Interest Category'
                      value={
                        this.state.workshop_Int_cat
                          ? this.state.workshop_Int_cat
                          : undefined
                      }
                      onChange={
                        this.onSelectInterestCat
                        // this.getInterest(this.state.formValue.work_int_cat_val)
                      }
                      className={
                        this.state.truthObj['work_int_cat_val']
                          ? 'has-error'
                          : ''
                      }
                      mode='tags'
                      filterOption={false}
                      tokenSeparators={[',']}
                    >
                      <Select.OptGroup label='Preferred Interest Categories'>
                        {this.state.act_int_cat
                          .filter(data => data.is_added)
                          .map((data, i) => (
                            <Option
                              size='large'
                              key={`Pref-${i}`}
                              value={data.name}
                              id={`Pref-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Other Interest Categories'>
                        {this.state.act_int_cat
                          .filter(data => !data.is_added)
                          .map((data, i) => (
                            <Option
                              size='large'
                              key={`Other-${i}`}
                              value={data.name}
                              id={`Other-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                    </Select>

                    {/* <div
                      className='p-3 mt-3 rounded border  height-80'
                      id={
                        this.state.truthObj['getselectint']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      {this.state.getselectint.map((tag, index) => {
                        if (!tag.is_active) {
                          return (
                            <Tag
                              color='blue'
                              className='mb-2'
                              onClick={checked =>
                                this.TagClick(tag, checked, index, 'interest')
                              }
                              key={index}
                            >
                              {tag.name}
                            </Tag>
                          )
                        }
                      })}
                    </div> */}

                    {/* <div className='row ml-0 mr-0 mt-2'>
                      {this.state.getselectint.length > 0 && (
                        <div
                          className='col-12 border rounded p-2 height-40'
                          id={
                            this.state.truthObj['getselectint']
                              ? 'has-error-label'
                              : ''
                          }
                        >
                          {this.state.getselectint.map((tag, index) => {
                            if (tag.is_active) {
                              return (
                                <Tag
                                  closable
                                  onClose={() =>
                                    this.TagClick(
                                      tag,
                                      'checked',
                                      index,
                                      'interest'
                                    )
                                  }
                                  color='blue'
                                  className='mb-2'
                                  key={index}
                                >
                                  {tag.name}
                                </Tag>
                              )
                            }
                          })}
                        </div>
                      )}
                    </div> */}

                    <div className='pt-4' />
                  </React.Fragment>
                </div>
                <div>
                  <Card bordered={false}>
                    <Row>
                      <div
                        className='mb-3 text-uppercase FontAvenirRoman text-secondary font-16 requiredAsterisk'
                        id={
                          this.state.truthObj['ageData']
                            ? 'has-error-label'
                            : ''
                        }
                      >
                        {`AGE GROUP`}
                      </div>
                      <div
                        className={
                          this.props.match.params.id === 'workshop'
                            ? 'd-flex align-items-center justify-content-between'
                            : ''
                        }
                      >
                        {this.state.workshop_age_group.map((age_data, i) => {
                          return (
                            <div
                              className={`age FontAvenirRoman mr-5`}
                              // ${
                              //   i !== this.state.workshop_age_group.length - 1
                              //     ? ""
                              //     : "mr-5"
                              // }`}
                              key={i}
                            >
                              <Checkbox
                                key={i}
                                checked={!age_data.is_active}
                                value={age_data.id}
                                onChange={e => this.set_AgeGroupActiveN(i, e)}
                              >
                                {age_data.age}
                              </Checkbox>
                              {/* {this.props.match.params.id === "workshop" && (
                                <React.Fragment>
                                  :
                                  <Select
                                    size="large"
                                    className={
                                      age_data.is_active ? "no-color" : "color"
                                    }
                                    value={age_data.age_group_dificulty_id}
                                    onSelect={() => {
                                      if (age_data.is_active)
                                        this.setAgeGroupActive(i, age_data.id);
                                    }}
                                    onChange={(e) => {
                                      this.onSelectChange(
                                        "act_age_dificulty_val",
                                        e
                                      );

                                      this.setAgeGroupDiff(i, e);
                                    }}
                                  >
                                    {this.state.act_age_dificulty.map(
                                      (data, index) => {
                                        return (
                                          <Option
                                            size="large"
                                            key={index}
                                            value={data.id}
                                            id={`${age_data.age}-${index}`}
                                          >
                                            {data.dificulty}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                  
                                </React.Fragment>
                              )} */}
                            </div>
                          )
                        })}
                      </div>
                    </Row>
                    <div className='pt-4 mb-2' />

                    <React.Fragment>
                      <div className='pt-4 mb-2' />

                      <div
                        className={`mb-3 FontAvenirRoman text-secondary font-16 mb-4 requiredAsterisk 
                        ${this.state.isApproved ? 'Txt-disable' : ''}`}
                        id={
                          this.state.truthObj['workshop_type']
                            ? 'has-error-label'
                            : ''
                        }
                      >
                        FEE
                      </div>
                      <Row>
                        <Col span={6}>
                          <Radio.Group
                            disabled={this.state.isApproved}
                            className='p-2'
                            onChange={e =>
                              this.onChangeFee('isPaid', e.target.value)
                            }
                            value={this.state.isPaid}
                          >
                            <Radio value={'free'}>Free</Radio>
                            <Radio value={'Paid'}>Paid</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12}>
                          {this.state.isPaid === 'Paid' && (
                            <div>
                              <Col span={6}>
                                <Select
                                  value={
                                    this.state.formValue.workshop_currency
                                      ? this.state.formValue.workshop_currency
                                      : undefined
                                  }
                                  onChange={e => {
                                    this.onSelectChange('workshop_currency', e)
                                  }}
                                  size='large'
                                  style={{ width: 120 }}
                                  disabled
                                >
                                  {this.state.currency_arr.map((cur, i) => (
                                    <Option
                                      key={`CUR-${i}`}
                                      id={`CURID-${i}`}
                                      value={cur}
                                      size='large'
                                    >
                                      {cur}
                                    </Option>
                                  ))}
                                </Select>
                              </Col>
                              <Col span={6}>
                                <Input
                                  size='large'
                                  type='text'
                                  // addonAfter="mins"
                                  className='timeInput'
                                  id={
                                    this.state.truthObj['workshop_amount']
                                      ? 'has-error'
                                      : ''
                                  }
                                  placeholder='Enter fee'
                                  value={this.state.formValue.workshop_amount}
                                  onChange={e =>
                                    this.onChangeFeeAmount(
                                      'workshop_amount',
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>

                    <div className='pt-4 mb-2' />

                    <div className='FontAvenirRoman text-secondary font-16 mb-4'>
                      YOU SHOULD ALREADY KNOW
                    </div>
                    <TextEditor
                      value={this.state.you_knowVal}
                      data={this.state.you_know}
                      onEditorChange={e =>
                        this.onChangeDescEditor('you_know', e)
                      }
                      onChange={value => {
                        this.onChangeNotReq('you_knowVal', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4'
                      id={
                        this.state.truthObj['materials_req']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      YOU WOULD NEED
                      <div
                        className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                        onClick={this.onClickMaterialRequired}
                      >
                        Add more
                      </div>
                    </div>
                    <div className='row'>
                      {this.state.material_required.map((data, index) => (
                        <div className='col-6 mb-3' key={index}>
                          <div className='d-flex justify-content-start align-items-center'>
                            <WorkshopImageUpload
                              width={95}
                              height={95}
                              imageUrl={data.image}
                              imageClassName='position-relative d-flex align-items-center justify-content-center'
                              onSuccess={value =>
                                this.materialRequiredOnchange(
                                  'image',
                                  index,
                                  value
                                )
                              }
                              aspect={1}
                            />
                            <div
                              className='ml-3 d-flex flex-column align-items-end justify-content-start'
                              style={{ width: '80%' }}
                            >
                              <div className='w-100 d-flex align-items-center justify-content-center'>
                                <Input
                                  size='large'
                                  value={data.name}
                                  className='mb-2'
                                  // stateKey="name"
                                  onChange={e => {
                                    if (e.target.value.length <= 50) {
                                      this.materialRequiredOnchange(
                                        'name',
                                        index,
                                        e.target.value
                                      )
                                    }
                                  }}
                                  onPaste={e => {
                                    if (
                                      e.clipboardData.getData('Text').length >
                                      50
                                    ) {
                                      this.materialRequiredOnchange(
                                        'name',
                                        index,
                                        e.clipboardData
                                          .getData('Text')
                                          .substring(0, 50)
                                      )
                                    }
                                  }}
                                  placeholder='Enter material'
                                />
                                {this.state.material_required.length > 2 && (
                                  <Button
                                    className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                                    onClick={() =>
                                      this.onRemoveMaterialRequired(index)
                                    }
                                    size='large'
                                    style={{ marginBottom: '9px' }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                              <span
                                style={
                                  data.name.length >= 50
                                    ? { color: '#FF0A00' }
                                    : {}
                                }
                              >
                                {data.name.length} / 50
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className='pt-4 mb-2' />

                    <div className='FontAvenirRoman text-secondary font-16 mb-4'>
                      LEARNING OUTCOME
                    </div>
                    <TextEditor
                      value={this.state.learn_outcomeVal}
                      data={this.state.learn_outcome}
                      onEditorChange={e =>
                        this.onChangeDescEditor('learn_outcome', e)
                      }
                      onChange={value => {
                        this.onChangeNotReq('learn_outcomeVal', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div className='row'>
                      <div className='col-12'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='mr-5 w-100'>
                            <div className='FontAvenirRoman text-secondary font-16 mt-4 mb-2 requiredAsterisk'>
                              MINIMUM PARTICIPANTS
                            </div>
                            <Input
                              className='col-12 ant-input ant-input-lg mb-4'
                              value={this.state.formValue.minimum_participants}
                              // stateKey="minPart_name"
                              name='minimum_participants'
                              onChange={e => {
                                this.onChangeMinpart(
                                  'minimum_participants',
                                  e.target.value
                                )
                              }}
                              onBlur={e => {
                                this.onChangeMinpart(
                                  'minimum_participants',
                                  e.target.value,
                                  true
                                )
                              }}
                              type='number'
                              id={
                                this.state.truthObj['minimum_participants']
                                  ? 'has-error'
                                  : ''
                              }
                              placeholder='Enter Minimum Participants'
                              onKeyPress={evt => {
                                if (evt.which < 48 || evt.which > 57) {
                                  evt.preventDefault()
                                }
                              }}
                            />
                          </div>
                          <div className='mr-5 w-100'>
                            <div className='FontAvenirRoman text-secondary font-16 mb-2 requiredAsterisk'>
                              MAXIMUM PARTICIPANTS
                            </div>
                            <Input
                              className='col-12 ant-input ant-input-lg'
                              value={this.state.formValue.maximum_participants}
                              // stateKey="maxPart_name"
                              name='maximum_participants'
                              type='text'
                              id={
                                this.state.truthObj['maximum_participants']
                                  ? 'has-error'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeMaxpart(
                                  'maximum_participants',
                                  e.target.value
                                )
                              }}
                              onBlur={e => {
                                this.onChangeMaxpart(
                                  'maximum_participants',
                                  e.target.value,
                                  true
                                )
                              }}
                              placeholder='Enter Maximum Participants'
                              disabled={
                                this.state.formValue.minimum_participants == ''
                              }
                              onKeyPress={evt => {
                                if (evt.which < 48 || evt.which > 57) {
                                  evt.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4'
                      id={this.state.truthObj['pdf'] ? 'has-error-label' : ''}
                    >
                      REFERENCE TO SHARE WITH PARTICIPANTS POST WORKSHOP
                    </div>
                    <div className='row'>
                      <div
                        className={`col-${
                          this.state.imgVisible ? '6' : '12'
                        } pdf-upload`}
                      >
                        <WorkshopPdfUpload
                          multiple={false}
                          handleChange={this.onPDFUpload}
                          fileList={this.state.pdfFileList}
                          onSuccess={this.onPDFUploadSuccess}
                          previewImage={this.state.pdf_url}
                          isPdf={this.state.displayNameVisible}
                        />
                        {this.state.pdfFileList.length === 1 ? (
                          <div
                            onClick={this.deleteUploadPDF}
                            className='delete-img'
                          >
                            <img
                              src={deleteImage}
                              className='img-fluid'
                              style={{ height: 15, width: 15 }}
                              alt='delete pdf'
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className='mt-4' />

                    {this.state.displayNameVisible && (
                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex justify-content-center align-items-center'>
                            <div className='w-100'>
                              <div className='FontAvenirRoman text-secondary font-16 mt-2 mb-2'>
                                DISPLAY NAME
                              </div>
                              <Input
                                className='col-12 ant-input ant-input-lg mb-4'
                                value={this.state.pdf_name}
                                // stateKey="pdf_name"
                                id={
                                  this.state.truthObj['pdfDisplayName']
                                    ? 'has-error'
                                    : ''
                                }
                                name='pdf_name'
                                onChange={e =>
                                  this.setState({
                                    pdf_name: e.target.value
                                  })
                                }
                                type='text'
                                placeholder='Enter name of PDF'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='pt-2 mb-2' />
                    <div className='mb-2 FontAvenirRoman text-secondary font-16 mt-2'>
                      TAGS
                    </div>
                    <Select
                      mode='tags'
                      size='large'
                      placeholder='Select Tags'
                      className={this.state.truthObj['tags'] ? 'has-error' : ''}
                      value={this.state.tags ? this.state.tags : undefined}
                      style={{ width: '100%' }}
                      onChange={this.onSelectTags}
                      onSearch={value => {
                        this.onSearchTags(value)
                      }}
                      filterOption={false}
                      // filterOption={this.onSearchTags}
                      tokenSeparators={[',']}
                    >
                      {this.state.m_workshop_tags.map((data, index) => (
                        <Option
                          key={data.name}
                          value={data.name}
                          id={`Tags-${index}`}
                        >{` ${data.name} `}</Option>
                      ))}
                    </Select>
                    <div className='pt-4 mb-4' />
                  </Card>
                </div>
              </Form>
            )}
          </div>

          {/* proceed to approve workshop */}
          <Modal
            visible={this.state.changePrimaryHostModal}
            closable={true}
            footer={[
              <div className='d-flex justify-content-end align-items-center pl-2 p-1'>
                <div className='fontColor-blue font-16 FontAvenirMedium' />
                <div className='mr-2'>
                  <ButtonC
                    category='Workshops'
                    action='Approve Workshop Content'
                    size='large'
                    block
                    onClick={() => this.onChangePrimaryHostModal()}
                  >
                    Close
                  </ButtonC>
                </div>
                <div>
                  <ButtonC
                    category='Workshops'
                    action='Approve Workshop Content'
                    size='large'
                    block
                    className={`workshopApprbtn ${
                      !this.state.isApproved ? 'w-100px' : ''
                    }`}
                    type='primary'
                    disabled={!this.state.primary_host.length > 0}
                    onClick={() => this.validateStateValues()}
                  >
                    {this.state.isApproved ? 'Publish changes' : 'Approve'}
                  </ButtonC>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                changePrimaryHostModal: false
              })
            }
            width='30%'
          >
            <h4 className='heading'>Select the Primary Zoom Host account</h4>
            <div className='border-bottom'></div>
            <div className='mt-4'>
              <div>
                <Radio.Group
                  className='p-1 w-100'
                  onChange={e =>
                    this.onChangePrimaryHost('primary_host', e.target.value)
                  }
                  value={this.state.primary_host}
                >
                  {'https://app.learnwithbreadcrumb.com' !=
                  process.env.REACT_APP_URL ? (
                    <React.Fragment>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'qa-app-builds@nfnlabs.in'}
                      >
                        qa-app-builds@nfnlabs.in
                      </Radio>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'tamizh@nfnlabs.in'}
                      >
                        tamizh@nfnlabs.in
                      </Radio>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'contact@learnwithbreadcrumb.com'}
                      >
                        contact@learnwithbreadcrumb.com
                      </Radio>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'contact@questcommunity.in'}
                      >
                        contact@questcommunity.in
                      </Radio>
                    </React.Fragment>
                  )}
                </Radio.Group>
              </div>
              <div className='mb-4'></div>
            </div>
          </Modal>
        </Content>
      </Layout>
    )
  }
}
