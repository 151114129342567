import { Card, Checkbox, Col, Collapse, Input, Radio, Row, Slider } from "antd";
import React, { Component } from "react";
import "./filter.css";

const Search = Input.Search;
const Panel = Collapse.Panel;

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      time: [0, 100],
      marks: {
        0: "1",
        25: "2",
        50: "4",
        75: "6",
        100: "8"
      },
      ShowInterest: false
    };
  }

  onSelect() {}

  onOpen() {}

  onClose() {}

  onSearchChange = e => {
    this.setState({ ShowInterest: false });
    this.setState({ searchText: e.target.value });
  };

  onChange = (key, index, e) => {
    this.props.onCheckChange(key, index, e.target.checked);
  };

  onInterestChange = (key, index, e) => {
    const interestSelection = this.props.interestData
      .map((data, index) => {
        if ("" + data.id === "" + e.target.value) return index + 1;
      })
      .filter(data => data);
    this.props.onCheckChange(key, interestSelection[0] - 1, e.target.checked);
  };

  handleChange = value => {
    // var time = {
    //     min: Math.ceil(((this.props.time['100'] - this.props.time['0']) * (value[0] / 100)) + (this.props.time['0'])),
    //     max: Math.ceil(((this.props.time['100'] - this.props.time['0']) * (value[1] / 100)) + (this.props.time['0']))

    //     //     min: this.props.time[value[0] + ''],
    //     // max: this.props.time[value[1] + '']
    // }
    var time = [
      Math.ceil(
        (this.props.time["100"] - this.props.time["0"]) * (value[0] / 100) +
          this.props.time["0"]
      ),
      Math.ceil(
        (this.props.time["100"] - this.props.time["0"]) * (value[1] / 100) +
          this.props.time["0"]
      )
    ];
    this.props.timeChange(time);
    this.setState({ time: value });
  };

  formatter = value => {
    return Math.ceil(
      (this.props.time["100"] - this.props.time["0"]) * (value / 100) +
        this.props.time["0"]
    );
  };

  render() {
    // console.log(this.props.time)
    // console.log(this.props.showCollectionFilter, this.props.only_draft);
    // console.log(this.props.timeValue.map(data => { return Math.ceil((data - this.props.time['0']) / (this.props.time['100'] - this.props.time['0']) * 100) }))
    return (
      <Card className="FilterCard rounded vh-100 overflow-scroll p-0">
        <div className="mt-2 mb-2 border text-black border-left-0 border-right-0 border-top-0">
          <div className="p-3 FontAvenirMedium font-19 d-flex justify-content-between align-items-center">
            <span>Filters</span>
            {(this.props.ageData.filter(data => data.is_active).length > 0 ||
              this.props.interestData.filter(data => data.is_active).length >
                0 ||
              this.state.time[0] !== 0 ||
              this.state.time[1] !== 100) && (
              <span
                className="FontAvenirMedium font-12 color-blue-6 pointer-cursor"
                onClick={this.props.clearFilter}
              >
                Clear All
              </span>
            )}
          </div>
        </div>
        <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
          {this.props.showCollectionFilter && (
            <Panel header="SHOW" key="1">
              {this.props.isContentCreator && (
                <Radio.Group
                  onChange={this.props.onChangeFilterShowRadio}
                  value={this.props.filterShowRadio}
                >
                  <Radio className="pt-2 pb-2" value="all_contents">All contents</Radio>
                  <Radio className="pt-2 pb-2" value="isAddedToMyLC">Added to my LC</Radio>
                  <Radio className="pt-2 pb-2" value="only_collections">Only Collections</Radio>
                  <Radio className="pt-2 pb-2" value="only_playlist">Only Learning paths</Radio>
                </Radio.Group>
              )}
              {!this.props.isContentCreator && (
                <Radio.Group
                  onChange={this.props.radioChange}
                  value={
                    this.props.pendingOnly ? "pending" : this.props.radioData
                  }
                >
                  <Col span={24}>
                    <Checkbox
                      className="pt-2 pb-2"
                      checked={this.props.isCollectionSelected}
                      value={true}
                      onChange={() => {
                        this.props.onChangeShowCheckbox(
                          "only_collection",
                          !this.props.isCollectionSelected
                        );
                      }}
                    >
                      Collection
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      className="pt-2 pb-2"
                      checked={this.props.isPlaylistSelected}
                      value={true}
                      onChange={() => {
                        this.props.onChangeShowCheckbox(
                          "only_playlist",
                          !this.props.isPlaylistSelected
                        );
                      }}
                    >
                      Learning Paths
                    </Checkbox>
                  </Col>
                  {/* <Radio className="pt-2 pb-2" value={false}>
                  Collection
                </Radio>
                <Radio className="pt-2 pb-2" value={true}>
                  Playlist
                </Radio> */}
                </Radio.Group>
              )}
            </Panel>
          )}
          {this.props.showDraft && (
            <Panel header="SHOW" key="1">
              <Radio.Group
                onChange={this.props.radioChange}
                value={
                  this.props.pendingOnly ? "pending" : this.props.ungroupedContentsOnly ? "ungrouped" : this.props.radioData
                }
              >
                <Radio className="pt-2 pb-2" value={false}>
                  All Content
                </Radio>
                <Radio className="pt-2 pb-2" value={true}>
                  Only Drafts
                </Radio>
                <Radio className="pt-2 pb-2" value={"pending"}>
                  Pending approval
                </Radio>
                <Radio className="pt-2 pb-2" value={"ungrouped"}>
                  Ungrouped contents
                </Radio>
              </Radio.Group>
            </Panel>
          )}
          {!this.props.showJustInterest && (
            <Panel header="AGE" key="2">
              <Row>
                {this.props.ageData.map((data, index) => {
                  return (
                    <Col key={`checkbox-${index}`} span={24}>
                      <Checkbox
                        onChange={e =>
                          this.onChange(this.props.ageDataKey, index, e)
                        }
                        className="pt-2 pb-2"
                        checked={data.is_active}
                        value={data.id}
                      >
                        {data.age}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          )}
          <Panel header="INTEREST" key="3">
            <Search
              className="mb-3"
              placeholder="Search"
              onChange={this.onSearchChange}
            />
            <Row>
              {this.state.searchText === ""
                ? this.props.interestData.map((data, index) => {
                    if (index < 2 || this.state.ShowInterest) {
                      return (
                        <Col key={`checkbox-${index}`} span={24}>
                          <Checkbox
                            onChange={e => {
                              this.onInterestChange(
                                this.props.interestDataKey,
                                index,
                                e
                              );
                            }}
                            className="pt-2 pb-2"
                            checked={data.is_active}
                            value={data.id}
                          >
                            {data.name}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })
                : this.props.interestData
                    .filter(data =>
                      data.name
                        .toLowerCase()
                        .trim()
                        .includes(this.state.searchText.toLowerCase().trim())
                    )
                    .map((data, index) => {
                      if (index < 2 || this.state.ShowInterest) {
                        return (
                          <Col key={`checkbox-${index}`} span={24}>
                            <Checkbox
                              onChange={e => {
                                this.onInterestChange(
                                  this.props.interestDataKey,
                                  index,
                                  e
                                );
                              }}
                              className="pt-2 pb-2"
                              checked={data.is_active}
                              value={data.id}
                            >
                              {data.name}
                            </Checkbox>
                          </Col>
                        );
                      }
                    })}
              <div className="text-info FontAvenirMedium cursor-pointer">
                {!this.state.ShowInterest &&
                  this.props.interestData.filter(data =>
                    data.name
                      .trim()
                      .toLowerCase()
                      .includes(this.state.searchText.trim().toLowerCase())
                  ).length > 2 && (
                    <div
                      onClick={() => {
                        this.setState({
                          ShowInterest: !this.state.ShowInterest
                        });
                      }}
                    >
                      {`${this.props.interestData.filter(data =>
                        data.name
                          .trim()
                          .toLowerCase()
                          .includes(this.state.searchText.trim().toLowerCase())
                      ).length - 2} `}
                      More
                    </div>
                  )}
              </div>
            </Row>
          </Panel>

          {(!this.props.showTimeFilter && !this.props.isContentCreator) && (
            <Panel header="TIME (mins)" key="4">
              <Slider
                tipFormatter={this.formatter}
                onChange={this.handleChange}
                range
                marks={this.props.time}
                defaultValue={[0, 100]}
              />
            </Panel>
          )}
        </Collapse>
      </Card>
    );
  }
}
