import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonContainer = styled.button`
	border: 1px solid ${props => props.buttonColor};
	color: ${props => props.buttonColor};
	background-color: #FFFFFF;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	// width: 326px;
	width: 100%;
	padding: 0;
	margin-bottom: 1rem;
	cursor: pointer;
	&:focus {
		outline: none;
	}
	.button-icon {
		background-color: ${props => props.buttonColor};
    padding: 10px 16px;
    color: #FFFFFF;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
	}
	.button-text {
		font-family: 'Bariol';
		flex: 1;
		padding: 8px;
	}
`;

const SocialLoginButton = (props) => (
	<ButtonContainer className={props.className} onClick={props.onClick} buttonColor={props.buttonColor}>
		<div className="button-icon">
			{props.icon}
		</div>
		<div className="button-text">
			{props.buttonText}
		</div>
	</ButtonContainer>
);

SocialLoginButton.propTypes = {
	icon: PropTypes.element.isRequired,
	buttonText: PropTypes.string.isRequired,
	buttonColor: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default SocialLoginButton;
