import React, { PureComponent } from 'react'
import { Layout } from 'antd';
import FourOFour from '../static/404assest.png';
import {triggerGAEvent} from "../utils";
const {Content} = Layout;
export default class GenericNotFound extends PureComponent {

  goBack = () => {
    triggerGAEvent('404 Page', 'Going back');
    this.props.history.goBack()
  };

  goHome = () => {
    triggerGAEvent('404 Page', 'Going back to Home');
    this.props.history.push(`/dashboard`)
  };

  render() {
    return (
      <div>
        <Layout>
          <Content style={{textAlign: 'center'}}>
            <img src={FourOFour} style={{marginTop: '10rem'}} alt="404"/>
            <h5 style={{marginTop: '2rem'}}>The page you are looking for doesn't exist or an error occurred.</h5>
            <h5>
              <span onClick={this.goBack} className="linksIn404">Go Back</span> or head over to <span onClick={this.goHome} className="linksIn404">Home</span>
            </h5>
          </Content>
        </Layout>
      </div>
    );
  }
}
