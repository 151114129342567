import { Button, Card } from 'antd'
import axios from 'axios'
import React from 'react'
import Form from 'react-validation/build/form'
import logo from '../../assets/breadcrumb_logo.png'
import BGBLANKLOGO from '../../static/nav/bg_blank_img.svg'
import MBLBGBLANKLOGO from '../../static/nav/mbl_bg_blank_logo.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import arrowLeft from '../../assets/arrow_left.svg'
import InputC from '../../components/InputC'
import { LC_FORGOT_PSWD } from '../../graphql.js'
import { loginEmailCheck, email, required } from '../../validation'
import styled, { css, keyframes } from 'styled-components'
import './Signin.css'
import { openNotification, triggerGAEvent } from '../../utils'
import _ from 'lodash'
import { isRequired } from '../../validation'

const MCard = styled.div`
  @media (max-width: 576px) {
    background: url(${MBLBGBLANKLOGO}) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    .cardd1 {
      padding: 18px !important;
    }
  }
  background: url(${BGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .cardd1 {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 12px;
    padding: 40px;
  }
`

class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formData: {
        email: ''
      },
      truthObj: {
        email: false
      }
    }
  }

  handleSubmit = () => {
    triggerGAEvent('Forgot Password', 'Sending request for Reset Password')
    let errors = document.getElementsByClassName('form-error')
    if (errors.length < 1) {
      let mutation = LC_FORGOT_PSWD
      mutation.variables = {
        email: this.state.formData.email
      }

      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification('error', res.data.errors[0].message)
          } else {
            openNotification(
              'success',
              `We've sent you an e-mail to your ID, please follow the link to reset your password.`
            )
            setTimeout(() => {
              this.props.history.push('/')
            }, 1000)
          }
        })
        .catch(err => console.log(err))
    } else {
      openNotification('error', 'Please enter valid credentials')
    }
  }

  handleOnChange = (key, value) => {
    triggerGAEvent('Forgot Password', 'Entering email for Forgot Password')
    const state = { ...this.state }
    state.formData[key] = value
    this.setState({ formData: state.formData })
  }

  handleClick = e => {
    e.preventDefault()
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.formData)
    getKeys.forEach((key, index) => {
      let getItem = this.state.formData[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    let re = /\S+@\S+\.\S+/
    truthObj['email'] = !re.test(this.state.formData.email)
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'The email ID you have entered is invalid.')
      this.setState({ isFormSubmitting: false })
    } else {
      this.handleSubmit()
    }
  }

  routeChange = () => {
    this.props.history.push('/resetpassword')
  }

  render () {
    return (
      // <div>
      <MCard className='card-wrap'>
        {/* Max dev forgot password */}
        <div className='max-dev-forgotPass cardd1'>
          {/* <img src={logo} className="logo" alt="logo" /> */}
          <div className='d-flex align-items-center'>
            <div className='pb-4 mr-2'>
              <img
                src={arrowLeft}
                className='cursor-pointer'
                alt='left arrow'
                onClick={() => this.props.history.push('/')}
              />
            </div>
            <div className='ml-4'>
              <h3
                className='pb-3 mb-3 text-center FontBariolHeavy'
                style={{
                  color: '#000000',
                  fontSize: 36
                }}
              >
                Forgot your password?
              </h3>
            </div>
          </div>
          <div>
            <div
              className='FontBariol font-15 mb-4'
              style={{
                color: '#4f4f4f'
              }}
            >
              Enter your email address and we’ll send you a link to reset your
              password
            </div>
            <Form>
              <div className='row align-items-center justify-content-center'>
                <div className='col-4 FontBariol font-16'>Email</div>
                <div className='col-8'>
                  <InputC
                    titleStyle={'mb-0'}
                    id={this.state.truthObj['email'] ? 'has-error' : ''}
                    placeholder='Enter your Email address'
                    className='FontBariol ant-input ant-input-lg'
                    value={this.state.formData.email}
                    stateKey='email'
                    onChange={this.handleOnChange}
                    validations={[required, loginEmailCheck]}
                  />
                </div>
              </div>
              <div className='pt-3 mb-4' />
              <div className='d-flex align-items-center justify-content-center'>
                <Button
                  onClick={() => this.validateStateValues()}
                  type='primary'
                  size='large'
                  className='FontBariolHeavy disable-btn'
                  style={{
                    background: '#4C39B3',
                    border: 'none',
                    width: 260,
                    height: 42
                  }}
                  disabled={!this.state.formData.email}
                >
                  Request Password Reset
                </Button>
              </div>
            </Form>
          </div>
        </div>
        {/* Mbl dev forgot password */}
        <div className='mbl-dev-forgotPass cardd1'>
          <div className='mt-3 d-flex align-items-center justify-content-start'>
            {/* <img src={logo} className='mbl-forgot-logo' alt='logo' /> */}
            <div>
              <img
                style={{
                  height: 20
                }}
                src={arrowLeft}
                className='cursor-pointer'
                alt='left arrow'
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div className='ml-3'>
              <img
                style={{
                  height: 32,
                  width: 191
                }}
                src={mbllogo}
                className='mbl-logo1'
                alt='logo'
              />
            </div>
          </div>
          <div className='mt-4 d-flex'>
            <h3 className='pb-2 mb-2 FontBariolHeavy font-28'>
              Forgot your password?
            </h3>
          </div>
          <div
            className='mb-4 d-flex font-14 FontBariol'
            style={{
              color: '#4f4f4f',
              textAlign: 'center'
            }}
          >
            Enter your email address and we’ll send you a link to reset your
            password
          </div>
          <div className='mb-4'>
            <Form onSubmit={this.handleSubmit} className='mbl-forgotPass-input'>
              <InputC
                title='Email'
                titleStyle='FontBariol font-16 mb-3'
                placeholder='Enter Registered Email ID'
                className='FontBariol col-12 ant-input ant-input-lg mb-4'
                value={this.state.formData.email}
                stateKey='email'
                onChange={this.handleOnChange}
                validations={[required, loginEmailCheck]}
              />
              <div className='pt-1 mb-2' />
              <Button
                type='primary'
                htmlType='submit'
                block
                size='large'
                className='FontBariolHeavy font-16 disable-btn'
                style={{
                  background: '#4C39B3',
                  borderColor: 'none',
                  height: 42
                }}
                disabled={!this.state.formData.email}
              >
                Request Password Reset
              </Button>
            </Form>
          </div>
        </div>
      </MCard>
    )
  }
}

export default ForgotPassword
