import { Button, Col, Input, Modal, Row } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import CategoryCard from "../../components/Card/CategoryCard";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import {
  ADD_INTEREST_CATEGORY,
  DELETE_INTEREST_CATEGORY,
  EDIT_INTEREST_CATEGORY,
  GET_GLOBAL_INTEREST_CATEGORY
} from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import emptyState from "../../static/activity/emptyStateSearch.png";
import ErrorActivity from "../../static/activity/error.svg";
import {
  openNotification,
  showNotification,
  triggerGAEvent
} from "../../utils";

const Search = Input.Search;
export default class Interests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: null,
      loading: false,
      visible: false,
      interestCategories: [],
      newCategoryInput: "",
      searchText: "",
      sort: "newest",
      thumb_image: "",
      selected_id: ""
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData() {
    this.setState({ loading: true });
    let query = GET_GLOBAL_INTEREST_CATEGORY;
    query.variables = {
      search: this.state.searchText.trim(),
      sort: this.state.sort
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          interestCategories:
            res.data.data.getGlobalInterestCategory.intrest_cat,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false, is_500: true });
      });
  }

  addInterestCategory = e => {
    triggerGAEvent("Interests", "Create new Interest Category");
    if (this.state.newCategoryInput === "" || this.state.thumb_image === "") {
      openNotification("error", "Both name and Images are mandatory");
    } else {
      let mutation = ADD_INTEREST_CATEGORY;
      mutation.variables = {
        name: this.state.newCategoryInput.trim(),
        background_image: this.state.thumb_image
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
          } else {
            openNotification("success", "Interest Category added successfully");
            this.setState({ loading: false, visible: false });
            this.fetchData();
          }
        })
        .catch(err => console.log(err));
    }
  };

  editInterestCategory = () => {
    triggerGAEvent("Interests", "Edit Interest Category");
    if (this.state.newCategoryInput === "" || this.state.thumb_image === "") {
      openNotification("error", "Both name and Images are mandatory");
    } else {
      let mutation = EDIT_INTEREST_CATEGORY;
      mutation.variables = {
        id: this.state.selected_id,
        name: this.state.newCategoryInput.trim(),
        background_image: this.state.thumb_image
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
          } else {
            this.handleCancel();
            this.fetchData();
          }
        })
        .catch(err => console.log(err));
    }
  };

  deleteInterestCategory = id => {
    triggerGAEvent("Interests", "Delete Interest Category");
    if (
      this.state.interestCategories.filter(d => d.id === id)[0]
        .total_interest !== 0
    ) {
      showNotification(
        "error",
        "Unable to delete Interest Category",
        "Cannot delete Interest category when interests are present under it."
      );
    } else {
      const confirmDialog = Modal.confirm({
        title: "Delete an Interest category",
        content: "Are you sure?",
        okText: "Yes",
        onOk: () => {
          let mutation = DELETE_INTEREST_CATEGORY;
          mutation.variables = {
            cat_id: id
          };
          axios
            .post(`${process.env.REACT_APP_API}`, mutation)
            .then(res => {
              if (res.data.errors) {
                openNotification("error", res.data.errors[0].message);
              } else {
                this.fetchData();
              }
            })
            .catch(err => console.log(err));
        }
      });
      this.setState({ confirmDialog });
    }
  };

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id"))
    });
  };

  componentDidMount() {
    this.fetchData();
    this.getLocalStorage();
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  showModal = () => {
    triggerGAEvent("Interests", "Create Interest Category Popup");
    this.setState({
      visible: true,
      modal_type: "add"
    });
  };

  openEditModal = id => {
    triggerGAEvent("Interests", "Edit Interest Category Popup");
    this.setState({
      visible: true,
      modal_type: "edit",
      selected_id: id,
      newCategoryInput: this.state.interestCategories.filter(
        int => int.id === id
      )[0].name,
      thumb_image: this.state.interestCategories.filter(int => int.id === id)[0]
        .background_image
    });
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  FeaturedImageUploadSuccess = value => {
    this.setState({ thumb_image: value });
  };

  handleCancel = e => {
    triggerGAEvent("Interests", "Cancel in Create Modal Popup");
    this.setState({
      visible: false,
      thumb_image: "",
      newCategoryInput: "",
      modal_type: "",
      selected_id: ""
    });
  };

  onSearchFetchData = () => {
    triggerGAEvent("Interests", "Search Interests");
    this.fetchData();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };

  sortChange = value => {
    triggerGAEvent("Interests", "Search in Interests");
    this.setState({ sort: value }, () => {
      this.fetchData();
    });
  };

  render() {
    if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Loading is_loading={this.state.loading} />

          <div style={{ margin: "1.7rem" }}>
            <Row>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mt-3 mb-0 p-0">
                  Interest Categories: {this.state.interestCategories.length}
                </h4>
                <div className="d-flex align-items-center justify-content-center">
                  <Search
                    placeholder="Search"
                    style={{ width: 400 }}
                    size="large"
                    onChange={this.onSearch}
                    allowClear
                  />
                  {this.state.role_id === 1 && (
                    <Button
                      type="primary"
                      block
                      size="large"
                      onClick={this.showModal}
                      className="ml-3 btn-w350"
                    >
                      Create New Interest Category
                    </Button>
                  )}
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Sort
                Edit={true}
                data={this.state.sort}
                sortChange={this.sortChange}
              />
            </Row>
            <Row gutter={24} style={{ marginTop: "0.5rem" }}>
              {(this.state.interestCategories || []).length === 0 &&
                !this.state.loading && (
                  <div className=" d-flex justify-content-center align-items-center flex-column mt-4">
                    <img className="mb-3" src={emptyState} alt="" />
                    <div className="text-center">
                      <p className="FontAvenirMedium font-20 mb-2">
                        No results found. Please try again with different
                        keywords
                      </p>
                    </div>
                  </div>
                )}
              {this.state.interestCategories.map((intCat, index) => (
                <Col span={6} style={{ margin: "0.85rem 0" }} key={index}>
                  <CategoryCard
                    id={intCat.id}
                    title={intCat.name}
                    desc={`Number of interests ${intCat.total_interest}`}
                    loading={this.state.loading}
                    image={intCat.background_image}
                    delete={this.deleteInterestCategory}
                    edit={this.openEditModal}
                    role_id={this.state.role_id}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <Modal
            title={
              this.state.modal_type === "add"
                ? "Create New Interest Category"
                : "Edit Interest Category"
            }
            visible={this.state.visible}
            onCancel={this.handleCancel}
            maskClosable={false}
            footer={[
              <Button key="back" size="large" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                size="large"
                type="primary"
                onClick={
                  this.state.modal_type === "add"
                    ? this.addInterestCategory
                    : this.editInterestCategory
                }
              >
                {this.state.modal_type === "add"
                  ? "Create Interest Category"
                  : "Save Interest Category"}
              </Button>
            ]}
          >
            <div className="d-flex align-items-start flex-column">
              <div className="mb-3">
                <div className="FontAvenirRoman text-secondary font-16 mb-8px">
                  Interest category image
                </div>
                <Row>
                  <ImageUploadCrop
                    onSuccess={this.FeaturedImageUploadSuccess}
                    imageUrl={this.state.thumb_image}
                    aspect={16 / 9}
                  />
                </Row>
              </div>
              <div className="w-100">
                <div className="FontAvenirRoman text-secondary font-16 mb-8px">
                  Interest category name
                </div>
                <Input
                  name="newCategoryInput"
                  onChange={this.onInputChange}
                  size="large"
                  placeholder="Add name"
                  value={this.state.newCategoryInput}
                />
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
