import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { GET_POWER_LEARNERS_LIST } from '../graphql';

class PowerLearnersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      table_data: [],
      page: 1,
      total: 0,
      filterInfo: null,
      sorterInfo: {
        columnKey: "count",
        field: "count",
        order: "descend"
      },
    };
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    let query = GET_POWER_LEARNERS_LIST;
    query.variables = {
      page: this.state.page,
      filter: {
        cardsCount: sorterInfo.columnKey === "count" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
        last_login: sorterInfo.columnKey === "last_login" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          table_data: res.data.data.getStudentsRankingReports.student_cards_result,
          total: res.data.data.getStudentsRankingReports.totalPages,
          is_loading: false
        })
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sorterInfo: sorter,
      filterInfo: filters
    }, () => this.fetchData());
  };

  render() {
    let sorterInfo = this.state.sorterInfo || {};
    const columns = [
      {
        title: "LEARNERS NAME",
        width: "40%",
        dataIndex: "name",
        render: (text, record, index) => (
          <div
            className="cursor-pointer"
            onClick={() => this.props.history.push(`/students/${record.students_id}`)}
          >
            {`${record.fname} ${record.lname}`}
          </div>
        ),
      },
      {
        title: "LAST LOGIN",
        width: "30%",
        dataIndex: "last_login",
        sorter: (a, b) => a.last_login - b.last_login,
        sortOrder: sorterInfo.columnKey === "last_login" && sorterInfo.order,
        render: date => date ? moment(date).format("MMM DD, YYYY") : "",
      },
      {
        title: "NO OF CARDS COMPLETED",
        width: "30%",
        dataIndex: "count",
        sorter: (a, b) => a.count - b.count,
        sortOrder: sorterInfo.columnKey === "count" && sorterInfo.order,
        // render: (text, record, index) => (
        //   <div className="d-flex align-items-center justify-content-center">
        //     {text}
        //   </div>
        // ),
      },
    ];
    return (
      <div className="bg-white rounded">
        <Table
          loading={this.state.is_loading}
          columns={columns}
          dataSource={this.state.table_data}
          onChange={this.handleTableChange}
          rowKey={record => record.students_id}
          pagination={{
            current: this.state.page,
            total: this.state.total,
            onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
          }}
        />
      </div>
    )
  }
}

export default withRouter(PowerLearnersList);
