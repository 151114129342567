export const INVALID_YOUTUBE_URLS = {
  query: `
    query NotificationCheckUrl($page: Int!, $cardType: [Int], $roleType: [Int], $userType: [Int]) {
      NotificationCheckUrl(page: $page, cardType: $cardType, roleType: $roleType, userType: $userType) {
        count
        cronDate
        total_count
        user_details{
          id
          role_id
          name
          last_name
          displayName
          email
          phone_no
          address
        }
        youtubeUrlCheck_details {
          id
          activities_id
          user_id
          user_name
          name
          title
          default_activities_id
          url
          new_url
          trailer_url
          new_trailer_url
          user {
            name
            id
            role_id
          }
        }
      }
    }
  `,
};
