import { Button, Card, Col, Icon, Modal, Popover, Row } from "antd";
import moment from "moment";
import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import AchievementStar from "../../static/journal/achievement_star.svg";
import BreadcrumbLogo from "../../static/journal/breadcrumb_logo_color.svg";
import ExpertFeedbackIcon from "../../static/journal/expert_feedback_icon.svg";
import ExpertIcon from "../../static/journal/expert_symbol.svg";
import FeedbackIcon from "../../static/journal/feedbackIcon.svg";
import JournalAudioBg from "../../static/journal/journal_audio_bg.svg";
import MblJournalEmptyState from "../../static/journal/mbl_journal_empty.svg";
import DoCard from "../../static/journal/new_do_card.svg";
import DoPlaylistCard from "../../static/journal/new_do_playlist.svg";
import WatchCard from "../../static/journal/new_watch_card.svg";
import WatchPlaylistCard from "../../static/journal/new_watch_playlist.svg";
import PlayListComplete from "../../static/journal/playlist_complete.svg";
import ReadCard from "../../static/journal/read_card.svg";
import Star from "../../static/journal/star.svg";
import PlayAudio from "../../static/Polygon.svg";
import WorkshopJournalIcon from "../../static/workshop/workshop_journal.svg";
import WorkshopJournalTick from "../../static/workshop/workshop_journal_tick.svg";
import { base64regex } from "../../utils";
import Audio from "../Audio";
import Bars from "../Bars";
import Download from "./../../static/journal/download_share.svg";
import Facebook from "./../../static/journal/Facebook_share.svg";
import LinkedIn from "./../../static/journal/linkedin_share.svg";
import Twitter from "./../../static/journal/twitter_share.svg";
import "./index.css";
import JournalEntryIcon from "./JournalEntryIcon";

const NotesBg = (notes) => {
  let decoded_data = base64regex.test(notes) ? window.atob(notes) : notes;
  let data = "";
  let arr = decoded_data.match(/.{1,50}\b/g);
  if (arr) {
    arr.forEach((text, index) => {
      if (index < 9) {
        data =
          data +
          `<tspan y='${7 + index * 5}' x='4' font-size='4.5'>${text}</tspan>`;
      }
    });
  }
  return `"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><style> .FontAvenirMedium { font-family: Avenir; letter-spacing: 0.3px }</style><text x='4' y='7' fill='black' class='FontAvenirMedium'>${data}</text></svg>"`;
};

const FeedbackCardContainer = ({ journal, onFeedbackAudioBtnClick }) => {
  return (
    <Card className="feedback-card">
      <div className="d-flex align-items-center justify-content-between">
        <div className="card-title-pd">
          <span
            style={{
              color: "#EC1A58",
              fontSize: 14,
              fontWeight: "800",
            }}
          >
            FEEDBACK
          </span>
          <span className="dot" />
          <span
            style={{
              color: "#4A4A4A",
              fontSize: 14,
              opacity: 0.67,
            }}
            className="mbl-feedback-username"
          >
            By {(journal.user || {}).name || journal.lc_name}
          </span>
        </div>
        {journal.feedback_voice && (
          <div
            className="play-audio-btn pointer-cursor"
            onClick={() =>
              onFeedbackAudioBtnClick(
                journal.feedback_voice,
                journal.disp_time,
                (journal.user || {}).name || journal.lc_name,
                journal.feedback_desc
              )
            }
          >
            <img src={PlayAudio} alt="play button" className="mr-2" />
            <span style={{ color: "#3EC0F1", marginTop: 3 }}>
              Play audio feedback
            </span>
          </div>
        )}
      </div>
      <div className="lc-name">
        {journal.name === "general" ? "" : journal.name}
      </div>
      {journal.feedback_desc ||
        (journal.feedback && (
          <div className="font-16" style={{ minHeight: 25 }}>
            {journal.feedback_desc || journal.feedback}
          </div>
        ))}

      {journal.student_response && (
        <div className="feedback-response">
          <Icon type="message" />
          <p>
            {base64regex.test(journal.student_response)
              ? decodeURIComponent(
                escape(window.atob(journal.student_response))
              )
              : journal.student_response}
          </p>
        </div>
      )}
      {journal.feedback_voice && (
        <div
          className="mbl-play-audio-btn pointer-cursor"
          onClick={() =>
            onFeedbackAudioBtnClick(
              journal.feedback_voice,
              journal.disp_time,
              (journal.user || {}).name || journal.lc_name,
              journal.feedback_desc
            )
          }
        >
          <img src={PlayAudio} alt="play button" className="mr-2" />
          <span style={{ color: "#3EC0F1", marginTop: 1 }}>Play audio</span>
        </div>
      )}
    </Card>
  );
};
class JournalEntry extends Component {
  constructor(props) {
    super(props);
    console.log(localStorage.role_id);
    this.state = {
      audio_modal_visible: false,
      image_modal_visible: false,
      notes_modal_visible: false,
      audio_url: "",
      disp_time: "",
      audio_type: "",
      lc_name: "",
      notes: "",
      is_playing: false,
      view_scroll_to_top: false,
    };
  }

  onFeedbackAudioBtnClick = (url, disp_time, lc_name, notes) => {
    this.setState({
      audio_modal_visible: true,
      audio_type: "feedback",
      audio_url: url,
      disp_time: disp_time,
      lc_name: lc_name,
      notes: base64regex.test(notes) ? window.atob(notes) : notes,
    });
  };

  onJournalAudioBtnClick = (url, disp_time, lc_name, notes) => {
    this.setState({
      audio_modal_visible: true,
      audio_type: "journal",
      audio_url: url,
      disp_time: disp_time,
      lc_name: lc_name,
      // notes: base64regex.test(notes) ? window.atob(notes) : notes
      notes: base64regex.test(notes)
        ? decodeURIComponent(escape(window.atob(notes)))
        : notes,
    });
  };

  onViewJournalNotes = (notes) => {
    this.setState({
      notes_modal_visible: true,
      notes: base64regex.test(notes) ? window.atob(notes) : notes,
    });
  };

  onViewJournalImage = (url, notes) => {
    this.setState({
      image_modal_visible: true,
      audio_url: url,
      notes: base64regex.test(notes) ? window.atob(notes) : notes,
    });
  };

  onViewJournalAudio = (url, notes) => {
    this.setState({
      audio_modal_visible: true,
      audio_type: "journal",
      audio_url: url,
      notes: base64regex.test(notes) ? window.atob(notes) : notes,
    });
  };

  onCloseModal = () => {
    this.setState({
      audio_modal_visible: false,
      image_modal_visible: false,
      notes_modal_visible: false,
      audio_url: "",
      disp_time: "",
      audio_type: "",
      lc_name: "",
      notes: "",
    });
  };

  scrollJournalToTop = () => {
    document.getElementById("journal-top").scrollIntoView();
  };

  componentDidMount() {
    window.addEventListener("scroll", this.gambitGalleryIsInView);
  }

  gambitGalleryIsInView = () => {
    const el = document.getElementById(`journal-card-0`);
    if (el) {
      const scroll = window.scrollY || window.pageYOffset;
      const boundsTop = el.getBoundingClientRect().top + scroll;
      const viewport = { top: scroll, bottom: scroll + window.innerHeight };
      const bounds = { top: boundsTop, bottom: boundsTop + el.clientHeight };
      const return_data =
        (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
      this.setState({ view_scroll_to_top: return_data });
    }
  };

  render() {
    const { overall_journals } = this.props;
    const shareMenu = (sharing_img_url, bitly_url) => {
      let data = sharing_img_url
        ? sharing_img_url
          .substr(sharing_img_url.lastIndexOf("/") + 1)
          .split(".")[0]
        : "";
      let url = bitly_url
        ? bitly_url
        : `${process.env.REACT_APP_SHARE_URL}/progress?url=${data}`;
      let encodedURL = encodeURIComponent(url);
      // console.log(encodedURL, url, data)
      let facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
      let linkedIn = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;
      let twitter = `https://twitter.com/intent/tweet?url=${encodedURL}`;

      return (
        <Row style={{ width: "150px" }} type="flex" align="middle">
          <Col
            onClick={() => window.open(facebook)}
            id="create-do-expert"
            className="cursor-pointer"
            span={6}
            style={{ color: "#485A96", fontSize: 18 }}
          >
            <div className="d-flex share_center">
              <img src={Facebook} className="share_img_size" alt="" />
            </div>
          </Col>
          <Col
            onClick={() => window.open(linkedIn)}
            id="create-do-expert"
            span={6}
            className="cursor-pointer"
            style={{ color: "#485A96", fontSize: 18 }}
          >
            <div className="d-flex share_center">
              <img src={LinkedIn} className="share_img_size" alt="" />
            </div>
          </Col>
          <Col
            onClick={() => window.open(twitter)}
            id="create-do-expert"
            span={6}
            className="cursor-pointer"
            style={{ color: "#485A96", fontSize: 18 }}
          >
            <div className="d-flex share_center">
              <img src={Twitter} className="share_img_size" alt="" />
            </div>
          </Col>
          <Col
            span={6}
            id="create-do-expert"
            className="cursor-pointer"
            style={{ color: "#485A96", fontSize: 18 }}
          >
            <a className="d-flex share_center" href={sharing_img_url} download>
              <img
                src={Download}
                className="share_img_size"
                alt={sharing_img_url}
              />
            </a>
          </Col>
        </Row>
        // <Menu>
        //   <Menu.Item
        //     onClick={() => window.open(facebook)}
        //     id="create-do-expert"
        //     className="d-flex align-items-center justify-content-start mb-2"
        //     style={{ color: '#485A96', fontSize: 18 }}
        //   >
        //     <Icon type="facebook" className="mr-2" style={{ fontSize: 18 }} />
        //     Facebook
        //   </Menu.Item>
        //   <Menu.Item
        //     onClick={() => window.open(linkedIn)}
        //     id="create-do-expert"
        //     className="d-flex align-items-center justify-content-start mb-2"
        //     style={{ color: '#0077B5', fontSize: 18 }}
        //   >
        //     <Icon type="linkedin" className="mr-2" style={{ fontSize: 18 }} />
        //     LinkedIn
        //   </Menu.Item>
        //   <Menu.Item
        //     onClick={() => window.open(twitter)}
        //     id="create-do-expert"
        //     className="d-flex align-items-center justify-content-start mb-2"
        //     style={{ color: '#03A9F4', fontSize: 18 }}
        //   >
        //     <Icon type="twitter" className="mr-2" style={{ fontSize: 18 }} />
        //     Twitter
        //   </Menu.Item>
        //   <Menu.Item
        //     id="create-do-expert"
        //     className="d-flex align-items-center justify-content-start mb-2"
        //     style={{ color: '#0076FF', fontSize: 18 }}
        //   >
        //     <a className="d-flex align-items-center justify-content-start" href={sharing_img_url} download>
        //       <Icon type="download" className="mr-2" style={{ fontSize: 18 }} />
        //       Download
        //     </a>
        //   </Menu.Item>
        // </Menu>
      );
    };

    return (
      <div id="journal-top">
        {!this.props.is_loading &&
          (!overall_journals || overall_journals.length === 0) && (
            <Card
              id="journal-card-0"
              style={{
                backgroundColor: "transparent",
                textAlign: "center",
                border: "none",
              }}
              className="mbl-journal-card mt-4 mb-4"
            >
              {/* <div className="mbl-journal-EmptyDate">
              {moment(this.props.date).format(
                        'DD MMMM, YYYY'
                      )}
            </div> */}
              <img
                className="mbl-journal-Empty mt-2"
                src={MblJournalEmptyState}
                alt="empty State"
              />
              <div className="journal-title mt-4">
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {this.props.student_name
                    ? `${this.props.student_name}'s journal`
                    : "Journal"}
                </span>
                {` Updates will show up here`}
              </div>
            </Card>
          )}
        {overall_journals && overall_journals.length !== 0 && (
          <React.Fragment>
            <div
              style={{
                display: this.state.view_scroll_to_top ? "none" : "flex",
              }}
              className="journal-scrolltotop"
              onClick={this.scrollJournalToTop}
            >
              <Icon type="up" />
            </div>
            <Card
              style={{
                backgroundColor: "#EEF8FD",
                paddingBotttom: "0",
                borderRadius: "5px",
              }}
              className="mbl-journal-card"
            >
              {overall_journals.map((overall_journal, index) => (
                <React.Fragment key={index}>
                  <div
                    className="d-flex align-items-center justify-content-center mtb-3"
                    key={index}
                    id={`journal-card-${index}`}
                  >
                    <span style={{ fontSize: 20, fontWeight: 800 }}>
                      {moment(overall_journal.card_details[0].disp_time).format(
                        "DD MMMM, YYYY"
                      )}
                    </span>
                  </div>
                  {overall_journal.card_details.map((journal, index) => (
                    <React.Fragment key={index}>
                      {journal.type === "activities" && (
                        <Row
                          className="mbl-journal-cardRow"
                          style={{ margin: "16px 0" }}
                          key={index}
                        >
                          <Col lg={2} xl={2} sm={6} xs={4}>
                            <Card className="time-card">
                              <div className="time">
                                {moment(journal.disp_time).format("hh:mm A")}
                              </div>
                              <div className="d-flex align-items-end justify-content-end mt-2 mbl-journal-img">
                                {journal.playlist_id ? (
                                  <React.Fragment>
                                    {journal.default_activities_id === 1 ? (
                                      "Read Playlist"
                                    ) : journal.default_activities_id === 2 ? (
                                      <img
                                        src={WatchPlaylistCard}
                                        className="img-fluid "
                                        alt=""
                                      />
                                    ) : journal.default_activities_id === 3 ? (
                                      "Listen Playlist"
                                    ) : journal.default_activities_id === 4 ? (
                                      <img
                                        src={DoPlaylistCard}
                                        className="img-fluid "
                                        alt=""
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {journal.default_activities_id === 1 ? (
                                      <img
                                        src={ReadCard}
                                        className="img-fluid "
                                        alt=""
                                      />
                                    ) : journal.default_activities_id === 2 ? (
                                      <img
                                        src={WatchCard}
                                        className="img-fluid "
                                        alt=""
                                      />
                                    ) : journal.default_activities_id === 3 ? (
                                      "Listen"
                                    ) : journal.default_activities_id === 4 ? (
                                      <img
                                        src={DoCard}
                                        className="img-fluid "
                                        alt=""
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </Card>
                          </Col>
                          <Col lg={22} xl={22} sm={10} xs={20}>
                            {(journal.activity_status === "finish" ||
                              journal.activity_status === "In - progress") && (
                                <Card className="activity-card-completed">
                                  {/* Max dev journal title */}
                                  <div className="max-journal-titles d-flex align-items-start justify-content-between">
                                    <div>
                                      <div className="card-title-pd">
                                        {journal.activity_status === "finish" && (
                                          <span
                                            style={{
                                              color: "#72AB33",
                                              fontSize: 14,
                                              fontWeight: "800",
                                            }}
                                          >
                                            FINISHED
                                          </span>
                                        )}
                                        {journal.activity_status ===
                                          "In - progress" && (
                                            <span
                                              style={{
                                                color: "#DD7F31",
                                                fontSize: 14,
                                                fontWeight: "800",
                                              }}
                                            >
                                              IN - PROGRESS
                                            </span>
                                          )}
                                        <span className="dot" />
                                        <span
                                          style={{
                                            color: "#4A4A4A",
                                            fontSize: 14,
                                            opacity: 0.67,
                                          }}
                                        >
                                          Total Time Spent -{" "}
                                          {moment
                                            .duration(journal.total_time_spent)
                                            .humanize()}
                                        </span>
                                      </div>
                                      <div className="activity-name">
                                        {journal.name}
                                      </div>
                                      <div className="activity-subtitle">
                                        {journal.user &&
                                          journal.user.role_id === 4 && (
                                            <React.Fragment>
                                              <span className="mr-2">
                                                {journal.user.name}
                                              </span>
                                              <img
                                                src={ExpertIcon}
                                                alt="expert icon"
                                                className="mr-2"
                                              />
                                            </React.Fragment>
                                          )}
                                        At {journal.lc_name}
                                      </div>
                                    </div>
                                    {journal.activity_status === "finish" &&
                                      this.props.role_id === 3 && (
                                        <Popover
                                          content={shareMenu(
                                            journal.sharing_img_url,
                                            journal.bitly_url
                                          )}
                                          placement="bottom"
                                          trigger="click"
                                        >
                                          <div
                                            className="d-flex align-items-center justify-content-center cursor-pointer p-1 pl-3 pr-3"
                                            style={{
                                              border: "1px solid #0076FF",
                                              borderRadius: 50,
                                            }}
                                          >
                                            <span className="font-14 color-blue mr-2">
                                              Share
                                            </span>
                                            <Icon
                                              type="share-alt"
                                              className="font-18 color-blue"
                                            />
                                          </div>
                                        </Popover>
                                      )}
                                  </div>
                                  {/* Mbl dev journal title */}
                                  <div className="mbl-journal-titles d-flex align-items-start justify-content-between">
                                    <div>
                                      <div className="card-title-pd d-flex flex-column">
                                        {journal.activity_status === "finish" && (
                                          <span
                                            style={{
                                              color: "#68B415",
                                              fontSize: 14,
                                              fontWeight: "800",
                                            }}
                                          >
                                            FINISHED
                                          </span>
                                        )}
                                        {journal.activity_status ===
                                          "In - progress" && (
                                            <span
                                              style={{
                                                color: "#DD7F31",
                                                fontSize: 14,
                                                fontWeight: "800",
                                              }}
                                            >
                                              IN - PROGRESS
                                            </span>
                                          )}
                                        {/* <span className='dot' /> */}
                                        <span
                                          style={{
                                            color: "#828282",
                                            fontSize: 14,
                                            fontWeight: 500,
                                          }}
                                        >
                                          Total Time Spent -{" "}
                                          {moment
                                            .duration(journal.total_time_spent)
                                            .humanize()}
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      {journal.activity_status === "finish" &&
                                        this.props.role_id === 3 && (
                                          <Popover
                                            content={shareMenu(
                                              journal.sharing_img_url,
                                              journal.bitly_url
                                            )}
                                            placement="bottomRight"
                                            trigger="click"
                                          >
                                            <div
                                              className="d-flex align-items-center justify-content-center cursor-pointer p-1 pl-3 pr-3"
                                            // style={{
                                            //   border: '1px solid #0076FF',
                                            //   borderRadius: 50
                                            // }}
                                            >
                                              {/* <span className='font-14 color-blue mr-2'>
                                            Share
                                          </span> */}
                                              <Icon
                                                type="share-alt"
                                                className="font-18 color-blue"
                                              />
                                            </div>
                                          </Popover>
                                        )}
                                    </div>
                                  </div>
                                  <div className="mbl-journal-titles activity-name">
                                    {journal.name}
                                  </div>
                                  <div className="mbl-journal-titles activity-subtitle">
                                    {journal.user && journal.user.role_id === 4 && (
                                      <React.Fragment>
                                        <span className="mr-1">
                                          {journal.user.name}
                                        </span>
                                        <img
                                          src={ExpertIcon}
                                          alt="expert icon"
                                          className="mr-1"
                                        />
                                      </React.Fragment>
                                    )}
                                    At {journal.lc_name}
                                  </div>
                                  {/* Mbl journal card end */}
                                  {/* Max dev journal pictures */}
                                  <div className="d-flex align-items-start justify-content-start flex-wrap mt-2">
                                    {journal.pictures
                                      .sort((a, b) => b.createdAt - a.createdAt)
                                      .map((picture, index) => (
                                        <div key={index}>
                                          {(picture.type === "doodle" ||
                                            picture.type === "captured" ||
                                            picture.type === "upload") && (
                                              <div
                                                onClick={() =>
                                                  this.onViewJournalImage(
                                                    picture.image,
                                                    picture.notes
                                                  )
                                                }
                                                className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                                style={{
                                                  minWidth: 300,
                                                  maxWidth: 300,
                                                  height: 169,
                                                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${picture.image})`,
                                                  backgroundSize: "cover",
                                                  border: `1px solid #CCCCCC`,
                                                  borderRadius: 6,
                                                }}
                                              >
                                                <div className="h-100">
                                                  <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                    {(picture.type === "captured" ||
                                                      picture.type ===
                                                      "upload") && (
                                                        <React.Fragment>
                                                          <span
                                                            style={{
                                                              color: "#FFFFFF",
                                                              fontSize: 17,
                                                              fontWeight: 900,
                                                              marginBottom: 7,
                                                            }}
                                                          >
                                                            {`${moment(
                                                              picture.createdAt,
                                                              "x"
                                                            ).format(
                                                              "MMM DD, YYYY"
                                                            )} - Photo`}
                                                          </span>
                                                          {picture.notes && (
                                                            <span
                                                              style={{
                                                                color: "#FFFFFF",
                                                              }}
                                                            >
                                                              <LinesEllipsis
                                                                style={{
                                                                  whiteSpace:
                                                                    "pre-wrap",
                                                                }}
                                                                text={
                                                                  base64regex.test(
                                                                    picture.notes
                                                                  )
                                                                    ? window.atob(
                                                                      picture.notes
                                                                    )
                                                                    : picture.notes
                                                                }
                                                                trimRight={true}
                                                                maxLine="2"
                                                                ellipsis="..."
                                                                basedOn="letters"
                                                              />
                                                            </span>
                                                          )}
                                                        </React.Fragment>
                                                      )}
                                                    {picture.type === "doodle" && (
                                                      <React.Fragment>
                                                        <span
                                                          style={{
                                                            color: "#FFFFFF",
                                                            fontSize: 17,
                                                            fontWeight: 900,
                                                            marginBottom: 7,
                                                          }}
                                                        >
                                                          {`${moment(
                                                            picture.createdAt,
                                                            "x"
                                                          ).format(
                                                            "MMM DD, YYYY"
                                                          )} - Doodle`}
                                                        </span>
                                                        {picture.notes && (
                                                          <span
                                                            style={{
                                                              color: "#FFFFFF",
                                                            }}
                                                          >
                                                            <LinesEllipsis
                                                              style={{
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                              }}
                                                              text={
                                                                base64regex.test(
                                                                  picture.notes
                                                                )
                                                                  ? window.atob(
                                                                    picture.notes
                                                                  )
                                                                  : picture.notes
                                                              }
                                                              trimRight={true}
                                                              maxLine="2"
                                                              ellipsis="..."
                                                              basedOn="letters"
                                                            />
                                                          </span>
                                                        )}
                                                      </React.Fragment>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          {picture.type === "audio" && (
                                            <div
                                              onClick={() =>
                                                this.onViewJournalAudio(
                                                  picture.image,
                                                  picture.notes
                                                )
                                              }
                                              className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                              style={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                                height: 169,
                                                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${JournalAudioBg})`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "round",
                                                border: `1px solid #CCCCCC`,
                                                borderRadius: 6,
                                              }}
                                            >
                                              <div className="h-100">
                                                <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                  <span
                                                    style={{
                                                      color: "#FFFFFF",
                                                      fontSize: 17,
                                                      fontWeight: 900,
                                                      marginBottom: 7,
                                                    }}
                                                  >
                                                    {`${moment(
                                                      picture.createdAt,
                                                      "x"
                                                    ).format(
                                                      "MMM DD, YYYY"
                                                    )} - Audio`}
                                                  </span>
                                                  {picture.notes && (
                                                    <span
                                                      style={{ color: "#FFFFFF" }}
                                                    >
                                                      <LinesEllipsis
                                                        style={{
                                                          whiteSpace: "pre-wrap",
                                                        }}
                                                        text={
                                                          base64regex.test(
                                                            picture.notes
                                                          )
                                                            ? window.atob(
                                                              picture.notes
                                                            )
                                                            : picture.notes
                                                        }
                                                        trimRight={true}
                                                        maxLine="2"
                                                        ellipsis="..."
                                                        basedOn="letters"
                                                      />
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {picture.type === "notes" && (
                                            <div
                                              onClick={() =>
                                                this.onViewJournalNotes(
                                                  picture.notes
                                                )
                                              }
                                              className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                              style={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                                height: 169,
                                                fontFamily: "Avenir",
                                                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${NotesBg(
                                                  picture.notes
                                                )})`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "round",
                                                border: `1px solid #CCCCCC`,
                                                borderRadius: 6,
                                              }}
                                            >
                                              <div className="h-100">
                                                <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                  <span
                                                    style={{
                                                      color: "#FFFFFF",
                                                      fontSize: 17,
                                                      fontWeight: 900,
                                                      marginBottom: 7,
                                                    }}
                                                  >
                                                    {`${moment(
                                                      picture.createdAt,
                                                      "x"
                                                    ).format(
                                                      "MMM DD, YYYY"
                                                    )} - Notes`}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </Card>
                              )}
                            {journal.activity_status === "start" && (
                              <Card className="activity-card">
                                <div className="card-title-pd">
                                  <span
                                    style={{
                                      color: "#FFC90D",
                                      fontSize: 14,
                                      fontWeight: "800",
                                    }}
                                  >
                                    STARTED
                                  </span>
                                </div>
                                <div className="activity-name">
                                  {journal.name}
                                </div>
                                <div className="activity-subtitle">
                                  {journal.user && journal.user.role_id === 4 && (
                                    <React.Fragment>
                                      <span className="mr-2">
                                        {journal.user.name}
                                      </span>
                                      <img
                                        src={ExpertIcon}
                                        alt="expert icon"
                                        className="mr-2"
                                      />
                                    </React.Fragment>
                                  )}
                                  At {journal.lc_name}
                                </div>
                              </Card>
                            )}
                          </Col>
                        </Row>
                      )}
                      {/* workshops journal start */}
                      {journal.type === "Workshop" && (
                        <Row style={{ margin: "16px 0" }} key={index}>
                          <Col lg={2} xl={2} sm={6} xs={4}>
                            <Card className="time-card">
                              <div className="time">
                                {moment(journal.disp_time).format("hh:mm A")}
                              </div>
                              <div className="d-flex align-items-end justify-content-end mt-2">
                                {
                                  <React.Fragment>
                                    <img
                                      src={WorkshopJournalIcon}
                                      className="img-fluid"
                                      alt="workshop icon"
                                    />
                                  </React.Fragment>
                                }
                              </div>
                            </Card>
                          </Col>
                          <Col lg={22} xl={22} sm={10} xs={20}>
                            <Card className="activity-card-completed">
                              {/* mbl workshops completed */}
                              <div className="mbl-dev-workshopsComp d-flex flex-column align-items-start justify-content-start">
                                <div className="p-1">
                                  <div className="activity-subtitle">
                                    {journal.user && (
                                      <React.Fragment>
                                        <span
                                          style={{
                                            color: "#EA1F59",
                                            fontSize: 14,
                                            fontWeight: 800,
                                          }}
                                        >
                                          WORKSHOP
                                        </span>
                                        <span className="dot" />
                                        <span
                                          className="mr-1"
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                          }}
                                        >
                                          By&ensp;
                                          {journal.user.name}
                                        </span>
                                        <img
                                          src={WorkshopJournalTick}
                                          alt="journal workshop user icon"
                                          className="mr-1"
                                        />
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                                <div className="p-2">
                                  <img
                                    style={{
                                      borderRadius: 5,
                                      width: 200,
                                    }}
                                    src={journal.image}
                                    alt="journal workshop image"
                                  />
                                </div>
                                <div className="p-2">
                                  <div className="activity-name">
                                    <span
                                      style={{
                                        color: "#EA1F59",
                                      }}
                                    >
                                      {`Hurrah! You have attended `}
                                      {/* {`Hurrah! You have attended workshop session
                                      ${journal.session}`} */}
                                    </span>
                                    <span>
                                      <b>'{journal.name}'</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* max dev workshops completed */}
                              <div className="max-dev-workshopsComp d-flex flex-row align-items-start justify-content-start">
                                <div className="p-2">
                                  <img
                                    style={{
                                      borderRadius: 5,
                                      width: 200,
                                    }}
                                    src={journal.image}
                                    alt="journal workshop image"
                                  />
                                </div>
                                <div className="p-2">
                                  <div className="activity-name">
                                    <span
                                      style={{
                                        color: "#EA1F59",
                                      }}
                                    >
                                      {`Hurrah! You have attended `}
                                      {/* {`Hurrah! You have attended workshop session
                                      ${journal.session}`} */}
                                    </span>
                                    <span>
                                      <b>'{journal.name}'</b>
                                    </span>
                                  </div>
                                  <div className="activity-subtitle">
                                    {journal.user && (
                                      <React.Fragment>
                                        <span
                                          className="mr-2"
                                          style={{
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                          }}
                                        >
                                          By&ensp;
                                          {journal.user.name}
                                        </span>
                                        <img
                                          src={WorkshopJournalTick}
                                          alt="journal workshop user icon"
                                          className="mr-2"
                                        />
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {journal.pictures && journal.pictures.length > 0 && (
                                <div
                                  className="d-flex align-items-start justify-content-start flex-wrap mt-2"
                                  style={{
                                    borderTop: "1px solid #D8F4FF",
                                  }}
                                >
                                  {journal.pictures
                                    .sort((a, b) => b.createdAt - a.createdAt)
                                    .map((picture, index) => (
                                      <div key={index}>
                                        {(picture.type === "doodle" ||
                                          picture.type === "captured" ||
                                          picture.type === "upload") && (
                                            <div
                                              onClick={() =>
                                                this.onViewJournalImage(
                                                  picture.image,
                                                  picture.notes
                                                )
                                              }
                                              className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                              style={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                                height: 169,
                                                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${picture.image})`,
                                                backgroundSize: "cover",
                                                border: `1px solid #CCCCCC`,
                                                borderRadius: 6,
                                              }}
                                            >
                                              <div className="h-100">
                                                <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                  {(picture.type === "captured" ||
                                                    picture.type ===
                                                    "upload") && (
                                                      <React.Fragment>
                                                        <span
                                                          style={{
                                                            color: "#FFFFFF",
                                                            fontSize: 17,
                                                            fontWeight: 900,
                                                            marginBottom: 7,
                                                          }}
                                                        >
                                                          {`${moment(
                                                            picture.createdAt,
                                                            "x"
                                                          ).format(
                                                            "MMM DD, YYYY"
                                                          )} - Photo`}
                                                        </span>
                                                        {picture.notes && (
                                                          <span
                                                            style={{
                                                              color: "#FFFFFF",
                                                            }}
                                                          >
                                                            <LinesEllipsis
                                                              style={{
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                              }}
                                                              text={
                                                                base64regex.test(
                                                                  picture.notes
                                                                )
                                                                  ? window.atob(
                                                                    picture.notes
                                                                  )
                                                                  : picture.notes
                                                              }
                                                              trimRight={true}
                                                              maxLine="2"
                                                              ellipsis="..."
                                                              basedOn="letters"
                                                            />
                                                          </span>
                                                        )}
                                                      </React.Fragment>
                                                    )}
                                                  {picture.type === "doodle" && (
                                                    <React.Fragment>
                                                      <span
                                                        style={{
                                                          color: "#FFFFFF",
                                                          fontSize: 17,
                                                          fontWeight: 900,
                                                          marginBottom: 7,
                                                        }}
                                                      >
                                                        {`${moment(
                                                          picture.createdAt,
                                                          "x"
                                                        ).format(
                                                          "MMM DD, YYYY"
                                                        )} - Doodle`}
                                                      </span>
                                                      {picture.notes && (
                                                        <span
                                                          style={{
                                                            color: "#FFFFFF",
                                                          }}
                                                        >
                                                          <LinesEllipsis
                                                            style={{
                                                              whiteSpace:
                                                                "pre-wrap",
                                                            }}
                                                            text={
                                                              base64regex.test(
                                                                picture.notes
                                                              )
                                                                ? window.atob(
                                                                  picture.notes
                                                                )
                                                                : picture.notes
                                                            }
                                                            trimRight={true}
                                                            maxLine="2"
                                                            ellipsis="..."
                                                            basedOn="letters"
                                                          />
                                                        </span>
                                                      )}
                                                    </React.Fragment>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        {picture.type === "audio" && (
                                          <div
                                            onClick={() =>
                                              this.onViewJournalAudio(
                                                picture.image,
                                                picture.notes
                                              )
                                            }
                                            className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                            style={{
                                              minWidth: 300,
                                              maxWidth: 300,
                                              height: 169,
                                              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${JournalAudioBg})`,
                                              backgroundSize: "cover",
                                              backgroundRepeat: "round",
                                              border: `1px solid #CCCCCC`,
                                              borderRadius: 6,
                                            }}
                                          >
                                            <div className="h-100">
                                              <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                <span
                                                  style={{
                                                    color: "#FFFFFF",
                                                    fontSize: 17,
                                                    fontWeight: 900,
                                                    marginBottom: 7,
                                                  }}
                                                >
                                                  {`${moment(
                                                    picture.createdAt,
                                                    "x"
                                                  ).format(
                                                    "MMM DD, YYYY"
                                                  )} - Audio`}
                                                </span>
                                                {picture.notes && (
                                                  <span
                                                    style={{ color: "#FFFFFF" }}
                                                  >
                                                    <LinesEllipsis
                                                      style={{
                                                        whiteSpace: "pre-wrap",
                                                      }}
                                                      text={
                                                        base64regex.test(
                                                          picture.notes
                                                        )
                                                          ? window.atob(
                                                            picture.notes
                                                          )
                                                          : picture.notes
                                                      }
                                                      trimRight={true}
                                                      maxLine="2"
                                                      ellipsis="..."
                                                      basedOn="letters"
                                                    />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {picture.type === "notes" && (
                                          <div
                                            onClick={() =>
                                              this.onViewJournalNotes(
                                                picture.notes
                                              )
                                            }
                                            className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                            style={{
                                              minWidth: 300,
                                              maxWidth: 300,
                                              height: 169,
                                              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${NotesBg(
                                                picture.notes
                                              )})`,
                                              backgroundSize: "cover",
                                              backgroundRepeat: "round",
                                              border: `1px solid #CCCCCC`,
                                              borderRadius: 6,
                                            }}
                                          >
                                            <div className="h-100">
                                              <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                                <span
                                                  style={{
                                                    color: "#FFFFFF",
                                                    fontSize: 17,
                                                    fontWeight: 900,
                                                    marginBottom: 7,
                                                  }}
                                                >
                                                  {`${moment(
                                                    picture.createdAt,
                                                    "x"
                                                  ).format(
                                                    "MMM DD, YYYY"
                                                  )} - Notes`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      )}
                      {/* workshops journal end */}
                      {journal.type === "feedback" && (
                        <Row style={{ margin: "16px 0" }} key={index}>
                          <Col lg={2} xl={2} sm={6} xs={4}>
                            <Card className="time-card">
                              <div className="time">
                                {moment(journal.disp_time).format("hh:mm A")}
                              </div>
                              <div className="d-flex justify-content-center mbl-journal-img1">
                                {journal.user && journal.user.role_id === 4 ? (
                                  <img
                                    src={ExpertFeedbackIcon}
                                    alt="feedback icon"
                                    style={{
                                      marginTop: 16,
                                    }}
                                    className="img-fluid mbl-journalFeed-img"
                                  />
                                ) : (
                                  <img
                                    src={FeedbackIcon}
                                    alt="feedback icon"
                                    className="img-fluid mbl-journalFeed-img"
                                    style={{
                                      marginTop:
                                        journal.name === "general" ? 16 : 32,
                                    }}
                                  />
                                )}
                              </div>
                            </Card>
                          </Col>
                          <Col lg={22} xl={22} sm={10} xs={20}>
                            <FeedbackCardContainer
                              journal={journal}
                              onFeedbackAudioBtnClick={
                                this.onFeedbackAudioBtnClick
                              }
                            ></FeedbackCardContainer>
                          </Col>
                        </Row>
                      )}
                      {journal.type === "journal" && (
                        <Row style={{ margin: "16px 0" }} key={index}>
                          <Col lg={2} xl={2} sm={6} xs={4}>
                            <Card className="time-card">
                              <div className="time">
                                {moment(journal.disp_time).format("hh:mm A")}
                              </div>
                              <div className="mt-3">
                                <JournalEntryIcon
                                  type={journal.journal_state}
                                />
                              </div>
                            </Card>
                          </Col>
                          <Col lg={22} xl={22} sm={10} xs={20}>
                            <Card className="journal-card">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="journal-title">
                                  {journal.journal_state === "audio"
                                    ? `Journal Audio`
                                    : `Journal pictures / notes`}
                                </div>
                                <div className="d-flex">
                                  {localStorage.role_id === "2" &&
                                    !(journal.journal_feedback || [])
                                      .length && (
                                      <div
                                        className="play-audio-btn pointer-cursor"
                                        style={{
                                          color: "#3EC0F1",
                                          paddingLeft: 35,
                                          paddingRight: 35,
                                        }}
                                        onClick={() => {
                                          this.props.onShowFeedback(journal);
                                        }}
                                      >
                                        Add Feedback
                                      </div>
                                    )}
                                  {journal.journal_state === "audio" && (
                                    <div
                                      className="play-audio-btn pointer-cursor ml-3"
                                      onClick={() =>
                                        this.onJournalAudioBtnClick(
                                          journal.image,
                                          journal.disp_time,
                                          journal.user.name,
                                          journal.notes
                                        )
                                      }
                                    >
                                      <img
                                        src={PlayAudio}
                                        alt="play button"
                                        className="mr-2"
                                      />
                                      <span
                                        style={{
                                          color: "#3EC0F1",
                                          marginTop: 3,
                                        }}
                                      >
                                        Play audio feedback
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="journal-content">
                                {journal.journal_state !== "audio" &&
                                  journal.image && (
                                    <div
                                      onClick={() =>
                                        this.onViewJournalImage(
                                          journal.image,
                                          journal.notes
                                        )
                                      }
                                      className="categoryCard rounded position-relative p-2 m-2 cursor-pointer"
                                      style={{
                                        minWidth: 300,
                                        maxWidth: 300,
                                        height: 169,
                                        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.028) 28.96%, rgba(0, 0, 0, 0.07) 54.64%, rgba(0, 0, 0, 0.161) 74.21%, rgba(0, 0, 0, 0.392) 100%), url(${journal.image})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "round",
                                        border: `1px solid #CCCCCC`,
                                        borderRadius: 6,
                                      }}
                                    >
                                      <div className="h-100">
                                        <div className="d-flex flex-column align-items-start justify-content-end h-100">
                                          <span
                                            style={{
                                              color: "#FFFFFF",
                                              fontSize: 17,
                                              fontWeight: 900,
                                              marginBottom: 7,
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {journal.journal_state ===
                                              "uploaded" ||
                                              journal.journal_state ===
                                              "captured" ? (
                                              <React.Fragment>
                                                {`${moment(
                                                  journal.disp_time
                                                ).format(
                                                  "MMM DD, YYYY"
                                                )} - Photo`}
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {`${moment(
                                                  journal.disp_time
                                                ).format("MMM DD, YYYY")} - ${journal.journal_state
                                                  }`}
                                              </React.Fragment>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                <div className="journal-data">
                                  {base64regex.test(journal.notes)
                                    ? decodeURIComponent(
                                      escape(window.atob(journal.notes))
                                    )
                                    : journal.notes}
                                </div>
                              </div>
                              {journal.journal_feedback && (
                                <div className="m-2">
                                  {(journal.journal_feedback || []).map(
                                    (cardJournal, cardJournalIndex) => {
                                      return (
                                        <div
                                          className="mt-4"
                                          key={`Journal-${cardJournalIndex}`}
                                        >
                                          <FeedbackCardContainer
                                            journal={cardJournal}
                                            cardIndex={cardJournalIndex}
                                            onFeedbackAudioBtnClick={
                                              this.onFeedbackAudioBtnClick
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      )}
                      {journal.type === "milestone" && (
                        <Row style={{ margin: "16px 0" }} key={index}>
                          <Col lg={2} xl={2} sm={6} xs={4}>
                            <Card className="time-card">
                              <div className="time">
                                {moment(journal.disp_time).format("hh:mm A")}
                              </div>
                              <div className="mbl-journal-Imgcomp mt-3">
                                <img
                                  src={BreadcrumbLogo}
                                  alt="breadcrumb logo"
                                  className="img-fluid"
                                />
                              </div>
                            </Card>
                          </Col>
                          <Col lg={22} xl={22} sm={10} xs={20}>
                            <Card className="journal-card">
                              {journal.activity_status === "finish_playlist" ? (
                                <div className="d-flex align-items-start justify-content-between">
                                  <div className="d-flex flex-column align-items-start justify-content-between">
                                    <div className="milestone-title">
                                      Awesome! You Completed a Learning path
                                    </div>
                                    <div>
                                      <p className="milestone-content m-0">
                                        You seem to enjoy learning in-depth.
                                        Must feel good to finish this from start
                                        to end.
                                      </p>
                                      <p className="milestone-content m-0">
                                        Keep doing more!
                                      </p>
                                    </div>
                                  </div>
                                  <img
                                    src={PlayListComplete}
                                    alt="playlist achievement"
                                    className="mbl-playlist-img"
                                  />
                                </div>
                              ) : (
                                <div className="d-flex align-items-start justify-content-between">
                                  <div className="d-flex flex-column align-items-start justify-content-between">
                                    <div className="w-100 d-flex justify-content-between">
                                      <div className="milestone-title">
                                        Doing Great!
                                      </div>
                                      <div
                                        className="mbl-milestone-count-bg"
                                        style={{
                                          background: `url(${Star})`,
                                        }}
                                      >
                                        <div className="milestone-count">
                                          {journal.activity_status}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="milestone-content m-0">{`You just completed ${journal.activity_status} learning cards on Breadcrumb! How awesome is that? Keep going.`}</p>
                                      <p className="milestone-content m-0">
                                        {" "}
                                        Happy learning!
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="milestone-count-bg"
                                    style={{
                                      background: `url(${AchievementStar})`,
                                    }}
                                  >
                                    <div className="milestone-count">
                                      {journal.activity_status}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Card>
          </React.Fragment>
        )}
        <Modal
          title={
            this.state.audio_type === "journal"
              ? "Journal Notes"
              : "Audio Feedback"
          }
          centered={true}
          visible={this.state.audio_modal_visible}
          maskClosable={false}
          width={600}
          onCancel={this.onCloseModal}
          footer={[
            <Button
              key="close"
              size="large"
              type="primary"
              onClick={this.onCloseModal}
            >
              Close
            </Button>,
          ]}
        >
          <div>
            <div>
              <Bars is_playing={this.state.is_playing} />
            </div>
            {this.state.audio_type !== "journal" && (
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="card-title-pd">
                  <span
                    style={{
                      color: "#0076FF",
                      fontSize: 14,
                      fontWeight: "800",
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.audio_type}
                  </span>
                  <span className="dot" />
                  <span
                    style={{ color: "#4A4A4A", fontSize: 14, opacity: 0.67 }}
                  >
                    {this.state.lc_name}
                  </span>
                </div>
                <div
                  className="time"
                  style={{ fontSize: 14, color: "#999999" }}
                >
                  {moment(this.state.disp_time).format("hh:mm A")}
                </div>
              </div>
            )}
            {this.state.audio_modal_visible && (
              <div className="mb-4">
                <Audio
                  src={this.state.audio_url}
                  className="w-100"
                  controlsList="nodownload"
                  onPlay={() => this.setState({ is_playing: true })}
                  onPause={() => this.setState({ is_playing: false })}
                  onEnded={() => this.setState({ is_playing: false })}
                />
              </div>
            )}
            {this.state.notes && (
              <React.Fragment>
                <h4>Notes</h4>
                <p className="journal-notes">{this.state.notes}</p>
              </React.Fragment>
            )}
          </div>
        </Modal>
        <Modal
          title="Journal Notes"
          visible={this.state.image_modal_visible}
          maskClosable={false}
          width={1000}
          centered={true}
          onCancel={this.onCloseModal}
          footer={[
            <Button
              key="close"
              size="large"
              type="primary"
              onClick={this.onCloseModal}
            >
              Close
            </Button>,
          ]}
        >
          <div className="d-flex flex-column justify-content-start align-items-start mb-3">
            <img
              src={this.state.audio_url}
              alt="journal updates"
              width={950}
              height={535}
              className="mb-4 mbl-notes-modal"
              style={{ objectFit: "cover" }}
            />
            {this.state.notes && (
              <React.Fragment>
                <h4>Notes</h4>
                <p className="journal-notes">{this.state.notes}</p>
              </React.Fragment>
            )}
          </div>
        </Modal>
        <Modal
          title="Journal Notes"
          visible={this.state.notes_modal_visible}
          maskClosable={false}
          width={600}
          centered={true}
          onCancel={this.onCloseModal}
          footer={[
            <Button
              key="close"
              size="large"
              type="primary"
              onClick={this.onCloseModal}
            >
              Close
            </Button>,
          ]}
        >
          <div>
            {this.state.notes && (
              <p className="journal-notes">{this.state.notes}</p>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default JournalEntry;
