import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { Select, Radio, Table } from 'antd';
import CollectionPopularityChart from './CollectionPopularityChart';
import { GET_COLLECTION_POPULARITY, GET_ALL_USERS } from '../graphql';

class CollectionPopularity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      type: "collection",
      selected_lc: "",
      lcs: [],
      table_data: [],
      page: 1,
      total: 0,
      filterInfo: null,
      sorterInfo: null,
    };
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    let query = GET_COLLECTION_POPULARITY;
    query.variables = {
      type: this.state.type,
      lc_id: this.state.selected_lc,
      page: this.state.page,
      filter: {
        cardsCount: sorterInfo.columnKey === "completedCards" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : ""
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          table_data: res.data.data.getCollPlyReports.coll_Ply_result,
          total: res.data.data.getCollPlyReports.totalPages,
          is_loading: false,
        });
      })
      .catch(err => console.log(err));
  };

  fetchAllLc = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_ALL_USERS)
      .then(res => {
        this.setState({
          lcs: res.data.data.getAllUser.users_result,
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchAllLc();
    this.fetchData();
  }

  handleRadioChange = (e) => {
    this.setState({
      type: e.target.value,
      page: 1,
    }, () => {
      this.fetchData();
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sorterInfo: sorter,
      filterInfo: filters
    }, () => this.fetchData());
  };

  handleSelectChange = (value) => {
    this.setState({
      selected_lc: value,
      page: 1,
    }, () => {
      this.fetchData();
    });
  };

  render() {
    let sorterInfo = this.state.sorterInfo || {};
    const columns = [
      {
        title: "COLLECTION NAME",
        width: "30%",
        dataIndex: "name",
        // render: (text, record, index) => (
        //   <div
        //     className="cursor-pointer"
        //     onClick={() => this.props.history.push(`/${this.state.type}/${record.CollPly_id}/view`)}
        //   >
        //     {text}
        //   </div>
        // ),
      },
      {
        title: "TOTAL CARDS",
        width: "20%",
        dataIndex: "totalCards",
        // render: (text, record, index) => (
        //   <div className="d-flex align-items-center justify-content-center">
        //     {text}
        //   </div>
        // ),
      },
      {
        title: "MOST NO OF CARDS COMPLETED",
        width: "20%",
        dataIndex: "completedCards",
        sorter: (a, b) => a.completedCards - b.completedCards,
        sortOrder: sorterInfo.columnKey === "completedCards" && sorterInfo.order,
        // render: (text, record, index) => (
        //   <div className="d-flex align-items-center justify-content-center">
        //     {text}
        //   </div>
        // ),
      },
      {
        title: "",
        width: "30%",
        dataIndex: "cards_arr",
        render: (text, record, index) => ( <CollectionPopularityChart data={text} /> ),
      },
    ];
    return (
      <div className="pt-4 pb-4 bg-white rounded">
        <div className="d-flex align-items-center justify-content-between pl-4 pr-4">
          <div className="d-flex align-items-center justify-content-between">
            <Radio.Group onChange={this.handleRadioChange} value={this.state.type}>
              <Radio value={"collection"}>Collection</Radio>
              <Radio value={"playlist"}>Learning Path</Radio>
            </Radio.Group>
          </div>
          <Select
            size="large"
            placeholder="Select a Learning centre"
            onChange={this.handleSelectChange}
            style={{ width: 285 }}
            value={this.state.selected_lc || undefined}
            allowClear
          >
            {this.state.lcs.map((lc, index) => (
              <Select.Option value={lc.id} key={index}>{lc.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="mt-4">
          <Table
            loading={this.state.is_loading}
            columns={columns}
            dataSource={this.state.table_data}
            onChange={this.handleTableChange}
            rowKey={record => record.id}
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
            }}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(CollectionPopularity);
