import { Button, Icon, Modal } from "antd";
import MicRecorder from "mic-recorder-to-mp3";
import React from "react";
import Audio from "../../components/Audio";
import { getS3UploadUrl, openNotification, uploadFiles } from "../../utils";
import Bars from "../Bars";
import "./index.css";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class AudioRecord extends React.Component {
  constructor(props) {
    super(props);
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    this.state = {
      isRecording: false,
      isPaused: false,
      blobURL: "",
      isBlocked: false,
      confirmDialog: null
    };
  }

  startRecording = () => {
    if (this.state.isBlocked) {
      openNotification(
        "error",
        "Please give permission for the microphone to record audio."
      );
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch(e => console.error(e));
    }
  };

  stopRecording = () => {
    this.setState({ isRecording: false });
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now()
        });
        const signedUploadUrl = await getS3UploadUrl(
          `me-at-thevoice.mp3`,
          `audio/mp3`
        );
        const [uploadedResponse] = await uploadFiles([signedUploadUrl], [file]);
        this.props.onSuccess(uploadedResponse);
        this.setState({ isRecording: false });
      })
      .catch(e => console.log(e));
  };

  deleteRecording = () => {
    if (this.props.feedback_voice) {
      const confirmDialog = Modal.confirm({
        title: "Are you sure you want to Delete?",
        okText: "Yes",
        onOk: () => {
          this.setState({ isRecording: false });
          this.props.onSuccess("");
        }
      });
      this.setState({ confirmDialog });
    }
  };

  checkPermissionForAudio = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia =
          // navigator.getUserMedia ||
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        this.setState({ isBlocked: false });
      })
      .catch(err => {
        openNotification(
          "error",
          "Please give permission for the microphone for recording audio."
        );
        this.setState({ isBlocked: true });
        console.log(err.name + ": " + err.message);
      });
    // navigator.mediaDevices.getUserMedia(
    //   { audio: true },
    //   () => {
    //     this.setState({ isBlocked: false });
    //   },
    //   () => {

    //   }
    // );
  };

  componentDidMount() {
    this.checkPermissionForAudio();
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  render() {
    const { isRecording } = this.state;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <div className="mb-4 w-100">
          <Bars is_playing={this.state.isRecording} />
        </div>
        <div
          className={`d-flex  mt-3 align-items-center recorder-icons w-100 justify-content-between ${
            this.props.noMargin ? "mb-0" : "mb-3"
          }`}
        >
          <div className="d-flex align-items-center">
            <Button
              onClick={this.startRecording}
              icon="play-circle"
              type="primary"
              size="large"
              className="mr-3 add-collec-btn"
              disabled={isRecording}
            >
              Record
            </Button>
            <Button
              onClick={this.stopRecording}
              icon="stop"
              type="primary"
              size="large"
              className="mr-3 delete-btn"
              disabled={!isRecording}
            >
              Stop
            </Button>
          </div>

          {this.props.feedback_voice !== "" && (
            <Audio
              src={this.props.feedback_voice || ""}
              className="w-100"
              controlsList="nodownload"
              onPlay={() => this.setState({ is_playing: true })}
              onPause={() => this.setState({ is_playing: false })}
              onEnded={() => this.setState({ is_playing: false })}
            ></Audio>
          )}
          {/* <audio
            ref="audioSource"
            controls="controls"
            src={this.props.feedback_voice || ""}
            style={{ height: 40 }}
            controlsList="nodownload"
            className="mr-3"
          /> */}
          {this.props.feedback_voice !== "" && (
            <div
              onClick={this.deleteRecording}
              className={`d-flex ml-3 align-items-center justify-content-center ${
                this.props.feedback_voice ? "pointer-cursor" : ""
              }`}
            >
              <Icon
                type="delete"
                style={{
                  fontSize: 24,
                  color: this.props.feedback_voice ? "#f40000" : "#898989"
                }}
              />
            </div>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AudioRecord;
