import React from 'react';
import axios from 'axios';
import Processed from '../../static/PasswordReset/Processed.svg'
import Invalid from '../../static/PasswordReset/Invalid.svg'
import AlreadyProcessed from '../../static/PasswordReset/AlreadyProcessed.svg'
import Loading from "../../components/Loading";
import {openNotification} from "../../utils";
import {ACCEPT_PASSWORD_RESET} from "../../graphql";

class AcceptPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: '',
    };
  }

  checkLink = () => {
    this.setState({ loading: true });
    let query = ACCEPT_PASSWORD_RESET;
    query.variables = {
      token: this.props.match.params.token,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
          this.setState({ loading: false });
          if (res.data.errors[0].message === "Request not found"){
            this.setState({ status: "2" })
          }
        } else {
          this.setState({
            status: res.data.data.acceptResetV2.status,
            loading: false,
          })
        }
      }).catch(err => console.log(err));
  };

  componentDidMount() {
    this.checkLink();
  }

  render() {
    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading}/>
        <div className="tabscroll d-flex align-items-center justify-content-center">
          {this.state.status === '1' && (
            <div className="d-flex flex-column align-items-center justify-content-center bg-white">
              <img src={Processed} alt="request processed" style={{ padding: '4rem 8rem 2rem 4rem', maxWidth: '100vw' }}/>
              <p className="text-dark font-16">The passcode reset request has been processed</p>
            </div>
          )}
          {this.state.status === '2' && (
            <div className="d-flex flex-column align-items-center justify-content-center bg-white">
              <img src={Invalid} alt="request processed" style={{ padding: '4rem 8rem 2rem 4rem', maxWidth: '100vw' }}/>
              <p className="text-dark font-16">This is an invalid link</p>
            </div>
          )}
          {this.state.status === '0' && (
            <div className="d-flex flex-column align-items-center justify-content-center bg-white">
              <img src={AlreadyProcessed} alt="request processed" style={{ padding: '4rem 8rem 2rem 4rem', maxWidth: '100vw' }}/>
              <p className="text-dark font-16">This request has already been processed</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AcceptPasswordReset;
