import { Input, Layout } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import BreadcrumbView from "../../components/BreadCrumb";
import Card from "../../components/Expert/Card";
import Sort from "../../components/Sort";
import { triggerGAEvent } from "../../utils";
import { GET_ALL_FEEDBACKS } from "../Collection/api";
import noFeedBacks from "../../static/no_feedback.svg";
const { Content } = Layout;
const Search = Input.Search;

const TabContainer = props => {
  return (
    <div>
      <Sort data={props.sort} sortChange={props.sortChange} feedback />
      <div className="row m-0 tabscroll">
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={page => {
            // alert()
            if (!props.isLoading) {
              props.loadItems(page);
            }
          }}
          className="w-100 row m-0"
          hasMore={props.hasMoreItems}
          loader={
            <React.Fragment>
              {[{}, {}, {}, {}].map((data, index) => {
                return (
                  <div
                    className="col-4 pointer-events-none"
                    key={`loading-${index}`}
                  >
                    <Card
                      isLoading={true}
                      videoRefIcon={
                        data.default_activities_id === 2
                          ? data.video_link !== ""
                          : data.url !== ""
                      }
                      stepsRefIcon={data.steps}
                      pdfRefIcon={data.pdf_url}
                      imgRefIcon={data.activity_reference_images}
                      floatingContainerLeft={false}
                      title={data.name}
                      time={data.time}
                      difficultyVisible={data.age_diff}
                      difficulty={data.age_diff}
                      defaultActivitiesId={data.default_activities_id}
                      feedback
                    />
                  </div>
                );
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            return (
              <div className="col-4" key={index}>
                {/* <CollectionCard src="http://altius-testing-images.s3-website.ap-south-1.amazonaws.com/341x192/2JH3BTbKx5batman2.jpg"></CollectionCard> */}
                <Link
                  to={{
                    pathname: `/contentfeedback/${data.id}`
                  }}
                >
                  <Card
                    videoRefIcon={
                      data.default_activities_id === 2
                        ? data.video_link !== ""
                        : data.url !== ""
                    }
                    imgRefIcon={data.activity_reference_images}
                    stepsRefIcon={data.steps}
                    pdfRefIcon={data.pdf_url}
                    floatingContainer={true}
                    floatingContainerLeft={false}
                    title={data.name}
                    time={data.time}
                    isLoading={props.loader}
                    difficulty={data.age_diff}
                    difficultyVisible={data.age_diff}
                    feedback
                    subHeading={data.completed_student_count}
                    defaultActivitiesId={data.default_activities_id}
                    Tag={[
                      {
                        name: data.status,
                        type: data.status === "Approved" ? "success" : "danger"
                      },
                      {
                        name: data.activity_state,
                        type:
                          data.activity_state === "Active"
                            ? "warning"
                            : "danger"
                      },
                      {
                        name: data.is_changed ? "Unpublished changes" : "",
                        type: data.is_changed ? "danger" : "success"
                      }
                    ]}
                    src={
                      data.thumb_images
                        ? data.thumb_images.length
                          ? data.thumb_images[0].image_url
                          : ""
                        : ""
                    }
                  />
                </Link>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
export class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "newest",
      searchText: "",
      CardsList: []
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }
  sortChange = value => {
    triggerGAEvent(
      "View Learning centre",
      "Changing sort in Activities and Libraries"
    );
    this.setState({ sort: value }, () => {
      this.fetchData();
      // this.fetchActivities(this.state.user_details.id);
    });
  };
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    this.setState({ isActivity_loading: true });

    let query = GET_ALL_FEEDBACKS;
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        console.log(res);
        try {
          let CardsList =
            res.data.data.CompleteCardsList.activity_details || [];
          this.setState({ CardsList, isActivity_loading: false });
        } catch (error) {
          this.setState({ isActivity_loading: false });
        }
        // this.setState({

        // });
      })
      .catch(err => {
        this.setState({ isActivity_loading: false });
        console.log(err);
      });
  }
  onSearch = e => {
    triggerGAEvent("View Feedback", "Search in Feedback");
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };
  onSearchFetchData = () => {
    this.fetchData();
    // this.fetchActivities(this.state.user_details.id);
  };
  render() {
    return (
      <div>
        <Content>
          <BreadcrumbView
            title={`Completed Learning content`}
            data={`/feedbacks`}
          >
            <Search
              size="large"
              placeholder={`Search`}
              onChange={this.onSearch}
              style={{ width: 400 }}
              className="mr-3"
            />
          </BreadcrumbView>

          <div className="pl-4 mt-3">
            {this.state.CardsList.length === 0 && (
              <React.Fragment>
                {this.state.isActivity_loading && (
                  <TabContainer
                    sort={this.state.sort}
                    sortChange={this.sortChange}
                    data={[{}, {}, {}]}
                    isLoading
                    loader
                  />
                )}
              </React.Fragment>
            )}
            {!this.state.isActivity_loading && (
              <React.Fragment>
                {this.state.CardsList.length === 0 && (
                  <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                    <img src={noFeedBacks} alt="no feedbacks" className="mb-4"/>
                    <div className="text-center">
                      <p className="FontAvenirMedium font-20 mb-2">
                        Learners have not completed any card yet. Check back again soon!
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {this.state.CardsList.length !== 0 && (
              <TabContainer
                sort={this.state.sort}
                sortChange={this.sortChange}
                data={this.state.CardsList}
                filter={this.state.filter}
                filterData={this.state.filterData}
                page={this.state.tab2Page}
                hasMoreItems={this.state.tab2More}
                loadItems={this.loadTab2}
                isLoading={this.state.isActivity_loading}
              />
            )}
          </div>
        </Content>
      </div>
    );
  }
}

export default Feedback;
