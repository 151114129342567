import { Button, Card, Col, Row } from "antd";
import React from "react";
import Form from "react-validation/build/form";
import TextArea from "react-validation/build/textarea";
import "../../App.css";
import logo from "../../assets/breadcrumb_logo.png";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import InputC from "../../components/InputC";
import PhoneNumber from "../../components/PhoneNumber";
import { is_phoneNumber, openNotification } from "../../utils";
import { required } from "../../validation";
import "../ResetPswd.css";
import "../SignIn/Signin.css";
import "./LCEnroll.css";

class LCEnroll1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      mobileno: "",
    };
  }

  onChange = value => {
    this.setState({ mobileno: value });
    this.props.onChange("mobileno", value);
  };

  onChangeText = (key, value) => {
    this.props.onChange(key, value);
  };

  SetImageUpload = url => {
    this.props.onChange("profileImage", url);
  };

  onLCImageUpload = file => {
    const state = { ...this.state };

    state.LC.fileList = file.map(data => {
      data.url = data.response;
      return data;
    });
    this.setState({ LC: state.LC });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (is_phoneNumber.test(this.props.phno) && this.props.address !== "") {
      this.props.onboardingSubmit();
    } else {
      openNotification("error", "Address or phone number is not valid");
    }
  };

  render() {
    return (
      <div className="card-wrap lc-o1">
        <div className="">
          <img src={logo} className="logo" alt="logo" />
          <Card className="enroll-card">
            <Form>
              <h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18">{`Please fill the following details to enroll as a ${
                this.props.role_id === "4"
                  ? "Expert"
                  : this.props.role_id === "5"
                  ? "Curator"
                  : "Learning Centre"
              }`}</h3>
              <Row className="mb-4">
                <Col span={4}>
                  <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">
                    {" "}
                    Logo{" "}
                  </div>
                  <ImageUploadCrop
                    imageUrl={this.props.profileImage}
                    onSuccess={this.SetImageUpload}
                    aspect={this.props.role_id === "2" ? 1.47 : 1}
                    width={105}
                    height={105}
                  />
                  {/* <ImageUpload
                    fileList={this.state.LC.fileList}
                    width={50}
                    height={50}
                    onSuccess={this.SetImageUpload}
                    handleChange={file => {
                      this.onLCImageUpload(file);
                    }}
                  /> */}
                </Col>
                <Col span={20}>
                  <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">
                    {" "}
                    {this.props.role_id === "4" || this.props.role_id === "5"
                      ? "About"
                      : "description"}
                  </div>
                  <TextArea
                    value={this.props.desc}
                    className="min-hgt ant-input"
                    placeholder=""
                    size="large"
                    onChange={e =>
                      this.onChangeText("description", e.target.value)
                    }
                  />
                </Col>
              </Row>
              {this.props.role_id === "2" && (
                <Row>
                  <Col span={12} className="pr-3">
                    <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">Latitude</div>
                    <InputC
                      className="col-12 ant-input ant-input-lg mb-4"
                      stateKey="latitude"
                      value={this.props.latitude}
                      onChange={this.onChangeText}
                      validations={[required]}
                      type="decimal"
                    />
                  </Col>
                  <Col span={12}>
                    <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">Longitude</div>
                    <InputC
                      className="col-12 ant-input ant-input-lg mb-4"
                      stateKey="longitude"
                      value={this.props.longitude}
                      onChange={this.onChangeText}
                      validations={[required]}
                      type="decimal"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">
                  {" "}
                  Address{" "}
                </div>
                <InputC
                  className="col-12 ant-input ant-input-lg mb-4"
                  stateKey="address"
                  value={this.props.address}
                  onChange={this.onChangeText}
                  validations={[required]}
                />
                <div className="mb-3 text-uppercase FontAvenirRoman text-secondary">
                  {" "}
                  Phone Number{" "}
                </div>
                <PhoneNumber
                  value={this.props.phno}
                  onChange={this.onChange}
                  min="10"
                  max="10"
                />
              </Row>
              <div className="border-bottom pt-4 mb-4" />
              <div className="d-flex justify-content-between">
                <Button size="large" onClick={() => this.props.prev()}>
                  Back
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="f-r"
                  onClick={this.handleSubmit}
                >
                  Complete & Sign Up
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

export default LCEnroll1;
