import React, { Component } from "react";
import pdf from "../../static/activity/pdf.svg";
import picture from "../../static/expert/icons/picture.svg";
import progress from "../../static/expert/icons/progress-chart.svg";
import video from "../../static/expert/icons/video-player.svg";
import ImgPlaceholder from "../../static/nav/imageFallback.png";
import "./card.css";
const Img = require("react-image");
export default class ImageCard extends Component {
  render() {
    let imageUrl =
      this.props.img && this.props.img !== ""
        ? `${process.env.REACT_APP_IMAGE_API}/341x192/${this.props.img.substr(
            this.props.img.lastIndexOf("/") + 1
          )}`
        : "";
    return (
      <div style={{ height: "192px" }} className="position-relative image-card">
        {/* <ProgressiveImage src={imageUrl} placeholder="">
          {(src, loading) => {
            return loading ? (
              <Skeleton title={false} active />
            ) : (
              <img className="w-100 h-100" src={src} alt="activity card" />
            );
          }}
        </ProgressiveImage> */}
        <Img
          className="w-100  h-100"
          src={imageUrl}
          loader={<div className=" w-100 h-100 skeleton" />}
          unloader={
            <img
              className="w-100  h-100"
              src={ImgPlaceholder}
              alt="activity card"
            />
          }
        />
        {(!!this.props.videoRefIcon ||
          this.props.imgRefIcon ||
          this.props.stepsRefIcon) && (
          <div className="position-absolute floatingblockimage p-1 pl-2 pr-2">
            {this.props.videoRefIcon && (
              <img src={video} className="img-fluid" alt="" />
            )}
            {this.props.imgRefIcon && (
              <img src={picture} className="img-fluid " alt="" />
            )}
            {this.props.stepsRefIcon && (
              <img src={progress} className="img-fluid" alt="" />
            )}
            {this.props.pdfIcon && (
              <img src={pdf} className="img-fluid " alt="" />
            )}
          </div>
        )}
      </div>
    );
  }
}
