import React, { Component } from 'react'
import { Tabs } from 'antd';
import OnBoardingJourney from './ReportComponents/OnBoardingJourney';
import CollectionPopularity from './ReportComponents/CollectionPopularity';
import CardsPopularity from './ReportComponents/CardsPopularity';
import PowerLearnersList from './ReportComponents/PowerLearnersList';
import LoginActivityChart from './ReportComponents/LoginActivityChart';
import ReportDate from "./ReportComponents/ReportDate";

class Reports extends Component {
  render() {
    return (
      <div className="p-4">
        <div>
          <OnBoardingJourney />
        </div>
        <div className="mt-4 pt-4">
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={<ReportDate />}
          >
            <Tabs.TabPane
              tab="Collection / Learning path  popularity"
              key="1"
            >
              <CollectionPopularity />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Cards Popularity"
              key="2"
            >
              <CardsPopularity />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Power Learners List"
              key="3"
            >
              <PowerLearnersList />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="mt-4">
          <LoginActivityChart />
        </div>
      </div>
      
    )
  }
}

export default Reports;
