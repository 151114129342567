import { Input, Layout, Tabs } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Card from "../../components/Expert/Card";
import CollectionCard from "../../components/Expert/CollectionCard";
import Filter from "../../components/filter";
import Sort from "../../components/Sort";
import {
  ACTIVITY_FILTER_QUERY,
  GET_ALL_ACTIVITIES,
  GET_ALL_COLLECTION_PLAYLIST,
  GET_ALL_PENDING_ACTIVITIES,
} from "../../graphql";
import emptyState from "../../static/activity/emptystate.svg";
import emptyStateActivity from "../../static/activity/emptystateactivity.svg";

const { Content } = Layout;
const Search = Input.Search;
const TabPane = Tabs.TabPane;

const TitleContainer = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
`;
const CollectionContainer = (props) => {
  return (
    <div>
      {!props.noSort && (
        <Sort data={props.sort} sortChange={props.sortChange} />
      )}
      <div className="row m-0 tabscroll" style={{ height: `calc(100vh - 250px)` }}>
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={(page) => {
            if (!props.isLoading) {
              props.loadItems(page);
            }
          }}
          className="w-100 row m-0"
          hasMore={props.hasMoreItems}
          loader={
            <React.Fragment>
              {[{}, {}, {}, {}].map((data, index) => {
                return (
                  <div
                    className={`col-${props.isLC ? '3' : '4'} pointer-events-none`}
                    key={`loading-${index}`}
                  >
                    <CollectionCard
                      loading={true}
                      title={data.title}
                      noMargin
                      isLC={props.isLC}
                      src={data.background_image}
                    />
                  </div>
                );
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            return (
              <div
              className={`col-${props.isLC ? '3' : '4'}`}
              key={index}
              >
                {/* <CollectionCard src="http://altius-testing-images.s3-website.ap-south-1.amazonaws.com/341x192/2JH3BTbKx5batman2.jpg"></CollectionCard> */}
                <Link
                  to={{
                    pathname: `${
                      data.is_playlist ? "playlist" : "collection"
                    }/${data.id}/view`,
                  }}
                >
                  <CollectionCard
                    loading={props.loader}
                    title={data.title}
                    src={data.background_image}
                    isLC={props.isLC}
                    collectionIcon={data.is_playlist}
                  />
                </Link>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
const TabContainer = (props) => {
  return (
    <div>
      <Sort data={props.sort} sortChange={props.sortChange} />
      <div className="row m-0 tabscroll" style={{ height: `calc(100vh - 250px)` }}>
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={(page) => {
            // alert()
            if (!props.isLoading) {
              props.loadItems(page);
            }
          }}
          className="w-100 row m-0"
          hasMore={props.hasMoreItems}
          loader={
            <React.Fragment>
              {[{}, {}, {}, {}].map((data, index) => {
                return (
                  <div
                    className="col-4 pointer-events-none"
                    key={`loading-${index}`}
                  >
                    <Card
                      isLoading={true}
                      videoRefIcon={
                        data.default_activities_id === 2
                          ? data.video_link !== ""
                          : data.url !== ""
                      }
                      stepsRefIcon={data.steps}
                      pdfRefIcon={data.pdf_url}
                      imgRefIcon={data.activity_reference_images}
                      floatingContainerLeft={true}
                      title={data.name}
                      time={data.time}
                      difficultyVisible={data.age_diff}
                      difficulty={data.age_diff}
                    />
                  </div>
                );
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            {
              /* console.log(data); */
            }
            return (
              <div className="col-4" key={index}>
                {/* <CollectionCard src="http://altius-testing-images.s3-website.ap-south-1.amazonaws.com/341x192/2JH3BTbKx5batman2.jpg"></CollectionCard> */}
                <Link
                  to={{
                    pathname: props.isLoading
                      ? ""
                      : data.is_draft || data.is_changed
                      ? data.status === "Draft" ||
                        data.activities_draft_state === "Draft"
                        ? `learningcontent/${data.activities_draft_id}/edit/draft`
                        : data.status === "Pending" ||
                          data.activities_draft_state === "Pending"
                        ? data.status === "Approved"
                          ? `learningcontent/${data.id}/view`
                          : `learningcontent/${data.id}/view/draft`
                        : `learningcontent/${data.id}/view`
                      : `learningcontent/${data.id}/view`,
                    state: {
                      filter: props.filter,
                      filterData: props.filterData,
                    },
                  }}
                >
                  <Card
                    videoRefIcon={
                      data.default_activities_id === 2
                        ? data.video_link !== ""
                        : data.url !== ""
                    }
                    imgRefIcon={data.activity_reference_images}
                    stepsRefIcon={data.steps}
                    pdfRefIcon={data.pdf_url}
                    floatingContainer={true}
                    floatingContainerLeft={true}
                    title={data.name}
                    time={data.time}
                    isLoading={props.loader}
                    difficulty={data.age_diff}
                    defaultActivitiesId={data.default_activities_id}
                    difficultyVisible={data.age_diff ? true : false}
                    Tag={[
                      {
                        name: data.status,
                        type: data.status === "Approved" ? "success" : "danger",
                      },
                      {
                        name: data.activity_state,
                        type:
                          data.activity_state === "Active"
                            ? "warning"
                            : "danger",
                      },
                      {
                        name: data.is_changed ? "Unpublished changes" : "",
                        type: data.is_changed ? "danger" : "success",
                      },
                    ]}
                    src={
                      data.thumb_images
                        ? data.thumb_images.length
                          ? data.thumb_images[0].image_url
                          : ""
                        : ""
                    }
                  />
                </Link>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
export class LearningContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_id: 2,
      slider: true,
      filter: {},
      searchText: "",
      // filterData: {
      //   interest_details: [],
      //   age_groups: [],
      //   marks: {
      //     0: "",
      //     100: ""
      //   }
      // },
      getAllActivity: [],
      only_draft: false,
      only_playlist: true,
      ungroupedContent_only: false,
      only_collection: true,
      total_count: 0,
      get_pending_only: false,
      sort: "newest",
      activeTab: "3",
      PublishedCollection: [],
      InprogressCollection: [],

      ArchivedCollection: [],
      page: 1,

      adminApprovedActivities: [],
      adminPendingActivities: [],
      is_collection_loading: false,
      isActivity_loading: false,
      filterData: {
        interest_details: [],
        age_groups: [],
        marks: {
          0: 0,
          100: 1000,
        },
        time: {
          min: 0,
          max: 1000,
        },
      },
      tab1Page: 1,
      tab1Count: 0,
      tab2Count: 0,
      tab2Page: 1,
      tab1More: true,
      tab2More: true,
      switchContentTab: false,
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }
  componentDidMount() {
    this.setState(
      {
        role_id: Number(localStorage.getItem("role_id")),
      },
      () => {
        this.setState({
          activeTab:
            this.state.role_id === 1
              ? "1"
              : this.props.history.location.search.split("=")[1]
              ? this.props.history.location.search.split("=")[1]
              : "3",
        });
        this.fetchFilter();
        this.fetchAllData();
      }
    );
  }
  onSearch = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        getAllActivity: [],
        fetchData: true,
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
        is_collection_loading: true,
        isActivity_loading: true,
        adminApprovedActivities: [],
        adminPendingActivities: [],
        PublishedCollection: [],
        InprogressCollection: [],

        ArchivedCollection: [],
      },
      () => this.onSearchFetchData()
    );
  };

  onSearchFetchData = () => {
    // triggerGAEvent('Activities', 'Searching Activities');
    this.fetchAllData();
  };
  clearFilter = () => {
    // triggerGAEvent('Learning Content', 'Clearing Filters in Activities');
    this.setState({ slider: false });
    setTimeout(() => {
      this.setState({ slider: true });
    }, 1);
    const filterData = { ...this.state.filterData };
    filterData.age_groups = filterData.age_groups.map((data) => {
      data.is_active = false;
      return data;
    });
    filterData.interest_details = filterData.interest_details.map((data) => {
      data.is_active = false;
      return data;
    });
    this.setState(
      {
        filterData: filterData,
        filter: {},
        fetchData: true,
        adminApprovedActivities: [],
        adminPendingActivities: [],
        getAllActivity: [],
        PublishedCollection: [],
        InprogressCollection: [],

        ArchivedCollection: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
      },
      () => this.fetchAllData()
    );
  };
  fetchFilter = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, ACTIVITY_FILTER_QUERY)
      .then((res) => {
        const filterData = res.data.data.getWebFilterData;
        filterData.age_groups =
          res.data.data.getWebFilterData.difficulty_details.age_groups;
        filterData.marks = {
          0: Math.ceil(res.data.data.getWebFilterData.time.min),
          100: Math.ceil(res.data.data.getWebFilterData.time.max),
        };
        this.setState({
          filterData: res.data.data.getWebFilterData,
        });
      })
      .catch((err) => console.log(err));
  };

  filterRadioChange = (e) => {
    // triggerGAEvent('Learning centre', 'Changing Filters in Activities');
    // console.log(e.target.value);
    this.setState(
      {
        get_pending_only: e.target.value === "pending",
        only_draft: e.target.value ? !(e.target.value === "pending" || e.target.value === "ungrouped") : false,
        ungroupedContent_only: e.target.value === "ungrouped",
        getAllActivity: [],
        adminApprovedActivities: [],
        adminPendingActivities: [],
        PublishedCollection: [],
        InprogressCollection: [],
        ArchivedCollection: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
      },
      () => this.fetchAllData()
    );
  };
  fetchData = () => {
    const query = GET_ALL_COLLECTION_PLAYLIST;
    this.setState({ is_collection_loading: true });
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      only_playlist: this.state.only_playlist,
      only_collection: this.state.only_collection,
      filter: this.state.filter,
      page: this.state.tab1Page,
    };
    // alert(this.state.tab1Page);
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        const cards = res.data.data.GetCollectionsPlayList.cards
          ? res.data.data.GetCollectionsPlayList.cards
          : [];
        let InprogressCollection = [],
          ArchivedCollection = [],
          PublishedCollection = [];
        if (cards.length === 0 || cards.length < 20) {
          // alert();
          this.setState({ tab1More: false });
        }
        cards.map((data) => {
          if (data.is_archive) {
            ArchivedCollection.push(data);
          } else if (data.is_publish) {
            PublishedCollection.push(data);
          } else {
            InprogressCollection.push(data);
          }
        });
        this.setState({
          InprogressCollection:
            this.state.tab1Page === 1
              ? [...InprogressCollection]
              : [...this.state.InprogressCollection, ...InprogressCollection],
          ArchivedCollection:
            this.state.tab1Page === 1
              ? [...ArchivedCollection]
              : [...this.state.ArchivedCollection, ...ArchivedCollection],
          PublishedCollection:
            this.state.tab1Page === 1
              ? [...PublishedCollection]
              : [...this.state.PublishedCollection, ...PublishedCollection],
          tab1More: false,
        });
        setTimeout(() => {
          this.setState({
            is_collection_loading: false,
          });
        }, 100);
      })
      .catch((e) => {
        this.setState({ is_collection_loading: false });

        // console.log(e);
      });
  };

  filterTimeChange = (value) => {
    // triggerGAEvent('Learning centre', 'Changing Filters in Activities');
    const state = { ...this.state };
    state.filter.time = value;
    this.setState(
      {
        filter: state.filter,
        fetchData: true,
        adminApprovedActivities: [],
        adminPendingActivities: [],
        getAllActivity: [],

        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
      },
      () => this.fetchAllData()
    );
  };
  onChange = (key, index, value) => {
    // triggerGAEvent('Learning Content', 'Changing Filters in Activities');
    // console.log(key, value, index);
    const state = { ...this.state };
    state.filterData[key][index].is_active = value;
    if (key === "age_groups") {
      state.filter["age"] = state.filterData[key]
        .filter((data) => data.is_active)
        .map((data) => parseInt(data.id, 10));
    }
    if (key === "interest_details") {
      state.filter["interest"] = state.filterData[key]
        .filter((data) => data.is_active)
        .map((data) => parseInt(data.id, 10));
    }
    this.setState(
      {
        filterData: state.filterData,
        fetchData: true,
        filter: state.filter,
        getAllActivity: [],
        adminApprovedActivities: [],
        adminPendingActivities: [],
        PublishedCollection: [],
        InprogressCollection: [],
        ArchivedCollection: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
      },
      () => this.fetchAllData()
    );
  };
  fetchAllActivities = () => {
    this.setState({ isActivity_loading: true });
    const query = GET_ALL_ACTIVITIES;
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText.trim(),
      filter: this.state.filter,
      only_draft: this.state.only_draft,
      get_pending_only: this.state.get_pending_only,
      ungroupedContent_only: this.state.ungroupedContent_only,
      page: this.state.tab2Page,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        if (
          res.data.data.AllCardsLCNew.activity_details.length === 0 ||
          res.data.data.AllCardsLCNew.activity_details.length < 20
        ) {
          this.setState({ tab2More: false });
        }
        const activities_details = [
          ...res.data.data.AllCardsLCNew.activity_details,
        ];
        const activity = activities_details.map((data) => {
          let difficulty = { Easy: [], Medium: [], Hard: [] };
          (data.age_groups || []).map((ageGrp) => {
            if (ageGrp) {
              if (ageGrp.age_group)
                difficulty[ageGrp.age_group_dificulty.dificulty].push(
                  ageGrp.age_group.age
                );
            }
          });
          data.age_diff = difficulty;
          return data;
        });
        // let activeTab = this.state.active_tab;
        this.setState(
          {
            getAllActivity:
              this.state.tab2Page !== 1
                ? [...this.state.getAllActivity, ...activity]
                : [...activity],
            total_count: res.data.data.AllCardsLCNew.total_count,
            //activeTab: this.state.switchContentTab ? "3" : this.state.activeTab,
            switchContentTab: false,
            // page: this.state.tab2Page + 1,
            loading: false,
            pagination_loading: false,
          },
          () => {
            setTimeout(() => {
              // console.log(this.state.getAllActivity);
              this.setState({
                isActivity_loading: false,
              });
            }, 100);
            // console.log(this.state.getAllActivity);
          }
        );
      })
      .catch((err) => {
        this.setState({ isActivity_loading: false });

        console.log(err);
      });
  };
  fetchAllData = () => {
    // if (this.state.role_id === 4) {
    //   this.fetchAllActivities();
    // }
    if (
      this.state.role_id === 2 ||
      this.state.role_id === 4 ||
      this.state.role_id === 5
    ) {
      this.fetchData();
      this.fetchAllActivities();
    } else if (this.state.role_id === 1) {
      this.getCollectionForAdmin();
    }
  };
  getCollectionForAdmin = () => {
    // if (this.state.activeTab === "2") {
    this.fetchOldPendingActivities();
    // } else {
    setTimeout(() => {
      this.fetchOldApprovedActivities();
    }, 1);
    // }
  };
  fetchOldApprovedActivities = () => {
    // const query = GET_ALL_APPROVED_ACTIVITIES;
    const query = GET_ALL_ACTIVITIES;
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: this.state.filter,
      only_draft: false,
      get_pending_only: false,
      page: this.state.tab2Page,
    };
    // console.log(query.variables);
    this.setState({
      isActivity_loading: true,
    });
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        if (res.data.data.AllCardsLCNew.activity_details.length === 0) {
          this.setState({ tab2More: false });
        }
        const activities_details = [
          ...res.data.data.AllCardsLCNew.activity_details,
        ];
        const activity = activities_details.map((data) => {
          let difficulty = { Easy: [], Medium: [], Hard: [] };
          (data.age_groups || []).map((ageGrp) => {
            if (ageGrp) {
              if (ageGrp.age_group)
                difficulty[ageGrp.age_group_dificulty.dificulty].push(
                  ageGrp.age_group.age
                );
            }
          });
          data.age_diff = difficulty;
          return data;
        });
        console.log("activity", activity)
        this.setState(
          {
            adminApprovedActivities:
              this.state.tab2Page !== 1
                ? [...this.state.adminApprovedActivities, ...activity]
                : [...activity],
            tab1Count: res.data.data.AllCardsLCNew.total_count,
            // page: this.state.page + 1,
            //pagination_loading: false,
            //loading: false,
            fetchData: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                isActivity_loading: false,
              });
            }, 100);
          }
        );
      })
      .catch((err) => console.log(err));
  };
  // fetchApprovedActivities = () => {
  //   const query = GET_ALL_APPROVED_ACTIVITIES;
  //   this.setState({ isActivity_loading: true });

  //   query.variables = {
  //     sort: this.state.sort,
  //     search: this.state.searchText,
  //     filter: this.state.filter,
  //     page: this.state.tab2Page
  //   };
  //   // console.log(query.variables);
  //   axios
  //     .post(`${process.env.REACT_APP_API}`, query)
  //     .then(res => {
  //       if (
  //         res.data.data.getAllCollection.all_activities.activity_details
  //           .length === 0
  //       ) {
  //         this.setState({ tab2More: false });
  //       }
  //       this.setState(
  //         {
  //           adminApprovedActivities:
  //             this.state.tab2Page === 1
  //               ? [
  //                   ...res.data.data.getAllCollection.all_activities
  //                     .activity_details
  //                 ]
  //               : [
  //                   ...this.state.adminApprovedActivities,
  //                   ...res.data.data.getAllCollection.all_activities
  //                     .activity_details
  //                 ],
  //           tab1Count:
  //             res.data.data.getAllCollection.all_activities.total_count,
  //           // page: this.state.page + 1,
  //           //pagination_loading: false,
  //           //loading: false,
  //           fetchData: false
  //         },
  //         () => {
  //           setTimeout(() => {
  //             this.setState({
  //               isActivity_loading: false
  //             });
  //           }, 100);
  //         }
  //       );
  //     })
  //     .catch(err => console.log(err));
  // };
  fetchOldPendingActivities = () => {
    const query = GET_ALL_ACTIVITIES;
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: this.state.filter,
      only_draft: false,
      get_pending_only: true,

      page: this.state.tab1Page,
    };
    this.setState({ is_collection_loading: true });

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        if (res.data.data.AllCardsLCNew.activity_details.length === 0) {
          this.setState({ tab1More: false });
        }
        const activities_details = [
          ...res.data.data.AllCardsLCNew.activity_details,
        ];
        const activity = activities_details.map((data) => {
          let difficulty = { Easy: [], Medium: [], Hard: [] };
          (data.age_groups || []).map((ageGrp) => {
            if (ageGrp) {
              // if (ageGrp.age_group)
                difficulty[ageGrp.age_group_dificulty.dificulty].push(
                  ageGrp.age_group.age
                );
            }
          });
          data.age_diff = difficulty;
          return data;
        });
        this.setState(
          {
            adminPendingActivities:
              this.state.tab2Page !== 1
                ? [...this.state.adminPendingActivities, ...activity]
                : [...activity],
            tab2Count: res.data.data.AllCardsLCNew.total_count,
            // page: this.state.page + 1,
            // pagination_loading: false,
            // loading: false,
            fetchData: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ is_collection_loading: false });
            }, 100);
          }
        );
      })
      .catch((err) => console.log(err));
  };
  fetchPendingActivities = () => {
    const query = GET_ALL_PENDING_ACTIVITIES;
    this.setState({ is_collection_loading: true });
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: this.state.filter,
      page: this.state.tab1Page,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        if (res.data.data.getPendingActivity.activity_details.length === 0) {
          this.setState({ tab1More: false });
        }
        this.setState(
          {
            adminPendingActivities:
              this.state.tab1Page === 1
                ? [...res.data.data.getPendingActivity.activity_details]
                : [
                    ...this.state.adminPendingActivities,
                    ...res.data.data.getPendingActivity.activity_details,
                  ],
            tab2Count: res.data.data.getPendingActivity.total_count,
            // page: this.state.page + 1,
            // pagination_loading: false,
            // loading: false,
            fetchData: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ is_collection_loading: false });
            }, 100);
          }
        );
      })
      .catch((err) => console.log(err));
  };
  sortChange = (value) => {
    // triggerGAEvent('Content Learning', 'Changing Sort in Activities');
    this.setState(
      {
        sort: value,
        getAllActivity: [],
        adminApprovedActivities: [],
        adminPendingActivities: [],
        PublishedCollection: [],
        InprogressCollection: [],

        ArchivedCollection: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        fetchData: true,
      },
      () => {
        this.fetchAllData();
        // this.fetchAllActivities();
      }
    );
  };
  onTabChange = (key) => {
    // console.log(`${key}`, key === "3", key === 3);
    // triggerGAEvent('Content Learning', 'Changing Tabs in Activities');

    if (this.state.role_id === 1) {
      this.setState(
        {
          activeTab: key,
          fetchData: true,
          // adminApprovedActivities: [],
          // adminPendingActivities: [],
          // page: 1,
          // total_count: 0
        },
        () => {
          // alert();
          // `${key}` === "1" ? this.fetchApprovedActivities() : this.fetchPendingActivities()
        }
      );
    }

    this.setState({ activeTab: key }, () => {
      this.props.history.replace(`/learningcontent?q=${this.state.activeTab}`);
    });
  };
  onChangeShowCheckbox = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.fetchData();
    });
  };
  loadTab1 = (page) => {
    if (this.state.tab1More) {
      this.setState(
        {
          tab1Page: this.state.tab1Page + 1,
          is_collection_loading: true,
        },
        () => {
          if (this.state.role_id === 1) {
            // this.fetchPendingActivities();
            this.fetchOldPendingActivities();
          } else {
            // this.fetchAllActivities();
            this.fetchData();
          }
        }
      );
    }
  };
  loadTab2 = (page) => {
    // alert(page);
    if (this.state.tab2More) {
      this.setState(
        {
          tab2Page: this.state.tab2Page + 1,
          isActivity_loading: true,
        },
        () => {
          // this.fetchData();
          if (this.state.role_id === 1) {
            this.fetchOldApprovedActivities();
          } else {
            this.fetchAllActivities();
          }
        }
      );
    }
  };

  render() {
    // console.log(
    //   this.state.activeTab === "1" ||
    //     this.state.activeTab === "2" ||
    //     this.state.activeTab === "4",
    //   this.state.activeTab === "3"
    //     ? this.state.role_id === 4 || this.state.role_id === 2
    //     : false
    // );
    return (
      <Layout className="createActivity">
        <div className="row m-0 mt-4">
          <div className="col p-0 min-300">
            {this.state.slider && (
              <Filter
                interestData={this.state.filterData.interest_details}
                interestDataKey="interest_details"
                ageData={this.state.filterData.age_groups}
                ageDataKey="age_groups"
                onCheckChange={this.onChange}
                time={this.state.filterData.marks}
                timeChange={this.filterTimeChange}
                radioData={this.state.only_draft}
                pendingOnly={this.state.get_pending_only}
                ungroupedContentsOnly={this.state.ungroupedContent_only}
                radioChange={this.filterRadioChange}
                clearFilter={this.clearFilter}
                showDraft={
                  this.state.activeTab === "3"
                    ? this.state.role_id === 2 ||
                      this.state.role_id === 4 ||
                      this.state.role_id === 5
                    : false
                }
                showCollectionFilter={
                  this.state.role_id !== 1
                    ? this.state.activeTab === "1" ||
                      this.state.activeTab === "2" ||
                      this.state.activeTab === "4"
                    : false
                }
                isCollectionSelected={this.state.only_collection}
                isPlaylistSelected={this.state.only_playlist}
                onChangeShowCheckbox={this.onChangeShowCheckbox}
                showTimeFilter={
                  this.state.role_id !== 1
                    ? this.state.activeTab === "1" ||
                      this.state.activeTab === "2" ||
                      this.state.activeTab === "4"
                    : false
                }
              />
            )}
          </div>
          <div className="col-10 p-0">
            <Content>
              <div className="col-12 row mb-3 d-flex text-right justify-content-between align-items-center">
                <TitleContainer className="mb-0 ml-2">
                  Learning Content
                </TitleContainer>

                <div className="d-flex text-right justify-content-end align-items-center">
                  <Search
                    size="large"
                    placeholder={`Search`}
                    onChange={this.onSearch}
                    style={{ width: 400 }}
                    className="mr-3"
                  />
                </div>
              </div>
              {this.state.role_id !== 1 && (
                <Tabs
                  defaultActiveKey={this.state.activeTab}
                  onChange={this.onTabChange}
                  activeKey={this.state.activeTab}
                >
                  <TabPane
                    className=""
                    tab={`Contents  ${
                      this.state.total_count
                        ? `- ${this.state.total_count}`
                        : ""
                    }`}
                    key="3"
                    // disabled={this.state.disabled}
                  >
                    {this.state.getAllActivity.length === 0 && (
                      <React.Fragment>
                        {this.state.isActivity_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            data={[{}, {}, {}]}
                            isLoading
                            loader
                          />
                        )}
                      </React.Fragment>
                    )}
                    {!this.state.isActivity_loading && (
                      <React.Fragment>
                        {this.state.getAllActivity.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img
                              className="mb-3"
                              src={emptyStateActivity}
                              alt=""
                            />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                Contents created will show up here. Go ahead and
                                create your first content
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {this.state.getAllActivity.length !== 0 && (
                      <TabContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.getAllActivity}
                        filter={this.state.filter}
                        filterData={this.state.filterData}
                        page={this.state.tab2Page}
                        hasMoreItems={this.state.tab2More}
                        loadItems={this.loadTab2}
                        isLoading={this.state.isActivity_loading}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    className=""
                    tab={
                      this.state.InprogressCollection.length <= 0
                        ? `Unpublished`
                        : `Unpublished - ${this.state.InprogressCollection.length}`
                    }
                    key="2"
                    // disabled={this.state.disabled}
                  >
                    {this.state.InprogressCollection.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <div className="pointer-events-none">
                            <CollectionContainer
                              data={[{}, {}, {}]}
                              isLoading
                              loader
                              isLC={this.state.role_id === 2}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      {this.state.InprogressCollection.length !== 0 && (
                        <CollectionContainer
                          sort={this.state.sort}
                          page={this.state.tab1Count}
                          hasMoreItems={this.state.tab1More}
                          loadItems={this.loadTab1}
                          sortChange={this.sortChange}
                          data={this.state.InprogressCollection}
                          isLoading={this.state.is_collection_loading}
                          isLC={this.state.role_id === 2}
                        />
                      )}
                      {!this.state.is_collection_loading && (
                        <React.Fragment>
                          {this.state.InprogressCollection.length === 0 && (
                            <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                              <img className="mb-3" src={emptyState} alt="" />
                              <div className="text-center">
                                <p className="FontAvenirMedium font-20 mb-2">
                                  Create new contents and make it as collections
                                  / learning path
                                </p>
                                <p className="FontAvenirMedium font-20">
                                  Unpublished collections / learning path appear
                                  here.
                                </p>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                    {/* )} */}
                  </TabPane>
                  <TabPane
                    className=""
                    tab={
                      this.state.PublishedCollection.length <= 0
                        ? `Published`
                        : `Published - ${this.state.PublishedCollection.length}`
                    }
                    key="1"
                    // disabled={this.state.disabled}
                  >
                    {this.state.PublishedCollection.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <div className="pointer-events-none">
                            <CollectionContainer
                              data={[{}, {}, {}]}
                              isLoading
                              loader
                              isLC={this.state.role_id === 2}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {this.state.PublishedCollection.length !== 0 && (
                      <CollectionContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.PublishedCollection}
                        page={this.state.tab1Count}
                        hasMoreItems={this.state.tab1More}
                        loadItems={this.loadTab1}
                        isLoading={this.state.is_collection_loading}
                        isLC={this.state.role_id === 2}
                      />
                    )}
                    {!this.state.is_collection_loading && (
                      <React.Fragment>
                        {this.state.PublishedCollection.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img className="mb-3" src={emptyState} alt="" />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                Create new contents and make it as collections /
                                learning path
                              </p>
                              <p className="FontAvenirMedium font-20">
                                Published collections / learning path appear
                                here.
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </TabPane>

                  <TabPane
                    className=""
                    tab={
                      this.state.ArchivedCollection.length <= 0
                        ? `Archived`
                        : `Archived - ${this.state.ArchivedCollection.length}`
                    }
                    key="4"
                    // disabled={this.state.disabled}
                  >
                    {this.state.ArchivedCollection.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <div className="pointer-events-none">
                            <CollectionContainer
                              data={[{}, {}, {}]}
                              isLoading
                              loader
                              isLC={this.state.role_id === 2}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {this.state.ArchivedCollection.length !== 0 && (
                      <CollectionContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.ArchivedCollection}
                        page={this.state.tab1Count}
                        hasMoreItems={this.state.tab1More}
                        isLoading={this.state.is_collection_loading}
                        loadItems={this.loadTab1}
                        isLC={this.state.role_id === 2}
                      />
                    )}
                    {!this.state.is_collection_loading && (
                      <React.Fragment>
                        {this.state.ArchivedCollection.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img className="mb-3" src={emptyState} alt="" />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20">
                                Archived Collections / Learning Path will appear
                                here.
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </TabPane>
                </Tabs>
              )}
              {this.state.role_id === 1 && (
                <Tabs
                  defaultActiveKey={this.state.activeTab}
                  onChange={this.onTabChange}
                  activeKey={this.state.activeTab}
                >
                  <TabPane
                    className=""
                    tab={`Approved  ${
                      this.state.tab1Count ? `- ${this.state.tab1Count}` : ""
                    }`}
                    key="1"
                    // disabled={this.state.disabled}
                  >
                    {this.state.adminApprovedActivities.length === 0 && (
                      <React.Fragment>
                        {this.state.isActivity_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            loader
                            data={[{}, {}, {}]}
                            isLoading
                          />
                        )}
                      </React.Fragment>
                    )}
                    {!this.state.isActivity_loading && (
                      <React.Fragment>
                        {this.state.adminApprovedActivities.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img
                              className="mb-3"
                              src={emptyStateActivity}
                              alt=""
                            />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                Contents created will show up here. Go ahead and
                                create your first content
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {this.state.adminApprovedActivities.length !== 0 && (
                      <TabContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.adminApprovedActivities}
                        filter={this.state.filter}
                        filterData={this.state.filterData}
                        page={this.state.tab2Page}
                        hasMoreItems={this.state.tab2More}
                        loadItems={this.loadTab2}
                        isLoading={this.state.isActivity_loading}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    className=""
                    tab={`Requests  ${
                      this.state.tab2Count ? `- ${this.state.tab2Count}` : ""
                    }`}
                    key="2"
                    // disabled={this.state.disabled}
                  >
                    {this.state.adminPendingActivities.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            data={[{}, {}, {}]}
                            isLoading
                            loader
                          />
                        )}
                      </React.Fragment>
                    )}
                    {!this.state.is_collection_loading && (
                      <React.Fragment>
                        {this.state.adminPendingActivities.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img
                              className="mb-3"
                              src={emptyStateActivity}
                              alt=""
                            />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                Contents created will show up here. Go ahead and
                                create your first content
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {this.state.adminPendingActivities.length !== 0 && (
                      <TabContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.adminPendingActivities}
                        filter={this.state.filter}
                        filterData={this.state.filterData}
                        page={this.state.tab1Page}
                        hasMoreItems={this.state.tab1More}
                        loadItems={this.loadTab1}
                        isLoading={this.state.is_collection_loading}
                      />
                    )}
                  </TabPane>
                </Tabs>
              )}
            </Content>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LearningContent;
