import { Button, Card, Collapse, List, Row, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import BreadcrumbView from "../../components/BreadCrumb/BreadcrumbPlayList";
import Loading from "../../components/Loading";
import { isLink, openNotification, triggerGAEvent } from "../../utils";
import { GET_ONE_PLAYLIST, PUBLISH_PLAYLIST } from "./api";
import "./playlist.css";

const Panel = Collapse.Panel;

export default class ViewPlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: "backimagecheck",
      source_text: "dumy text",
      source_link: "no link",
      is_archive: false,
      is_publish: false,
      age_groups: [],
      interests: [],
      data_points: [],
      arrayToRenderPlaylist: []
    };
  }

  fetchOneData = () => {
    let pListQuery = GET_ONE_PLAYLIST;
    pListQuery.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        this.setState({
          arrayToRenderPlaylist: res.data.data.getOnePlaylist.array.lib_act.sort(
            (a, b) => a.order - b.order
          ),
          title: res.data.data.getOnePlaylist.title,
          source_text: res.data.data.getOnePlaylist.source_text,
          source_link: res.data.data.getOnePlaylist.source_link,
          interests: res.data.data.getOnePlaylist.array.intrest,
          data_points: res.data.data.getOnePlaylist.array.data_point,
          age_groups: res.data.data.getOnePlaylist.age_group,
          loading: false,
          is_archive: res.data.data.getOnePlaylist.is_archive,
          is_publish: res.data.data.getOnePlaylist.is_publish
        });
      })
      .catch(err => console.log(err));
  };

  publishPlaylist = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    let mutation = PUBLISH_PLAYLIST;

    mutation.variables = {
      p_id: parseInt(id, 10)
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data.publishPlaylist.status === true) {
          this.fetchOneData();
          openNotification("success", "Learning path Published");
        } else {
          openNotification("error", res.data.data.publishPlaylist.message);
        }
        this.setState({ loading: false });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchOneData();
  }

  onCollapseChange = () => {
    triggerGAEvent("Playlist", "Changing Collapse in View Playlist");
  };

  render() {
    const {
      arrayToRenderPlaylist,
      data_points,
      interests,
      age_groups
    } = this.state;
    return (
      <React.Fragment>
        <div className="view-playlist">
          <Loading is_loading={this.state.loading} />
          <div style={{ background: "white" }}>
            {this.state.is_archive && (
              <BreadcrumbView
                title={this.state.title}
                data={`/playlists/archived/${this.state.title}`}
              />
            )}
            {!this.state.is_archive && this.state.is_publish && (
              <BreadcrumbView
                title={this.state.title}
                data={`/playlists/published/${this.state.title}`}
              >
                <Button
                  ghost
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.props.history.push(
                      `/playlists/edit/${this.props.match.params.id}`
                    )
                  }
                >
                  Edit
                </Button>
              </BreadcrumbView>
            )}
            {!this.state.is_archive && !this.state.is_publish && (
              <BreadcrumbView
                title={this.state.title}
                data={`/playlists/un-published/${this.state.title}`}
              >
                <Button
                  ghost
                  size="large"
                  type="primary"
                  className="mr-3"
                  onClick={() =>
                    this.props.history.push(
                      `/playlists/edit/${this.props.match.params.id}`
                    )
                  }
                >
                  Edit
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="btn-w250 add-collec-btn"
                  onClick={this.publishPlaylist}
                >
                  Publish Playlist
                </Button>
              </BreadcrumbView>
            )}
          </div>
          <div style={{ margin: "2rem" }}>
            <Card title="Title" className="plistViewTitle">
              <p className="playlist-title">{this.state.title}</p>
            </Card>

            <Collapse
              defaultActiveKey={["1"]}
              className="viewPlistCollapse"
              onChange={this.onCollapseChange}
            >
              <Panel header="Details of this Playlist" key="1">
                {interests.length > 0 && (
                  <Row>
                    <label className="mb-3">Interests</label>
                    <div>
                      {interests.map((ai, i) => (
                        <Tag key={i} color="blue">
                          {ai.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <Row>
                  <label className="mb-3">Source Credit</label>
                  <div className="font-16">
                    <span>{this.state.source_text}</span>
                    <span>
                      <a
                        href={
                          isLink.test(this.state.source_link)
                            ? this.state.source_link
                            : `http://${this.state.source_link}`
                        }
                        target="_blank"
                      >{` - ${this.state.source_link}`}</a>
                    </span>
                  </div>
                </Row>
                {data_points.length > 0 && (
                  <Row>
                    <label className="mb-3">Skill</label>
                    <div>
                      {data_points.map((ad, i) => (
                        <Tag key={i} color="blue">
                          {ad.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <Row className="age_groups">
                  <label className="mb-3">Age group & Difficulty</label>
                  <div>
                    {age_groups.map((age, index) => (
                      <span key={index} className="borderful">
                        {age.age}
                      </span>
                    ))}
                  </div>
                </Row>
              </Panel>
            </Collapse>
            <Collapse
              defaultActiveKey={["1"]}
              className="viewPlistCollapse"
              onChange={this.onCollapseChange}
            >
              <Panel header="Linked Activities and Libraries" key="1">
                <div className="linkedActivities">
                  {arrayToRenderPlaylist.map((item, index) => (
                    <List.Item key={index}>
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="badge">{index + 1}</div>
                        {item.thumb_image.length && (
                          <img
                            src={item.thumb_image[0].image_url}
                            className="img-fluid activityImage ml-3 mr-3"
                            alt=""
                          />
                        )}
                        <span>{item.name}</span>
                      </div>
                      <Tag color="lime">{item.status}</Tag>
                    </List.Item>
                  ))}
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
