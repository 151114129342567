import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  Tag
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { Prompt } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import styled, { keyframes } from 'styled-components'
import BreadcrumbView from '../../components/BreadCrumb/BreadcrumbPlayList'
import ExpertCard from '../../components/Expert/Card'
import ImageUploadCrop from '../../components/ImageUploadCrop'
import InputC from '../../components/InputC'
import Loading from '../../components/Loading'
import { ARCHIVE_COLLECTION } from '../../graphql'
import Question from '../../static/activity/Question.svg'
import EmptyList from '../../static/nav/emptyList.png'
import ImgPlaceholder from '../../static/nav/imageFallback.png'
import {
  dragDrop_reorder,
  openNotification,
  triggerGAEvent,
  YOUTUBE_VIDEO_REGEX
} from '../../utils'
import {
  COLLECTION_PLAYLIST_ACTIVITY,
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  GET_ACTIVITY,
  GET_AGE_DATA,
  GET_ALL_ACTIVITY_COLLECTION,
  GET_ALL_LIBRARY_COLLECTION,
  GET_ASSOCIATED_DATA,
  GET_LIBRARY,
  GET_ONE_COLLECTION,
  GET_ONE_PLAYLIST
} from './api'
import './playlist.css'
const Img = require('react-image')

const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`
const PlayListContainerStyle = styled.div`
  .playlistTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */

    color: #000000;
  }
  .playlistTitle .skeleton {
    width: 200px;
    height: 10px;
    border-radius: 50px;
  }
  .playlistInterest .skeleton {
    width: 100px;
    height: 10px;
    border-radius: 50px;
  }
  .playlistActive .skeleton {
    width: 30px;
    height: 10px;
    border-radius: 50px;
  }
  .skeleton {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;

    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
`
const SortableItem = SortableElement(
  ({ item, itemIndex, onRemovePlaylist }) => (
    <li className='col-4 SortItem'>
      <div>
        <ExpertCard
          // videoRefIcon={true}
          videoRefIcon={
            item.default_activities_id === 2
              ? item.video_link !== ''
              : item.url !== ''
          }
          counter={itemIndex}
          imgRefIcon={item.activity_reference_images}
          stepsRefIcon={item.steps}
          pdfRefIcon={item.pdf_url}
          floatingContainerLeft={true}
          floatingContainer={true}
          title={item.name}
          close
          time={item.time}
          difficulty={item.age_diff}
          defaultActivitiesId={item.default_activities_id}
          difficultyVisible={item.age_diff}
          onClose={() => {
            onRemovePlaylist()
          }}
          Tag={[
            {
              name: item.status,
              type: item.status === 'Approved' ? 'success' : 'danger'
            },
            {
              name: item.activity_state,
              type: item.activity_state === 'Active' ? 'warning' : 'danger'
            },
            {
              name: item.is_changed ? 'Unpublished changes' : '',
              type: item.is_changed ? 'danger' : 'success'
            }
          ]}
          src={
            item.thumb_images
              ? item.thumb_images.length
                ? item.thumb_images[0].image_url
                : ''
              : ''
          }
        />
      </div>
    </li>
  )
)

const SortableList = SortableContainer(({ items, onRemovePlaylist }) => {
  return (
    <ul className='row p-0'>
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            itemIndex={index + 1}
            item={value}
            onRemovePlaylist={() => {
              onRemovePlaylist(index)
            }}
          />
        )
      })}
    </ul>
  )
})
const PlayListContainer = props => {
  return (
    <div className='p-2 mt-4 rounded mb-4 border d-flex justify-content-between align-items-center'>
      <PlayListContainerStyle className='FontAvenirMedium font-16 text-dark w-100'>
        <div className='row m-0 justify-content-between d-flex'>
          <div className='PlaylistImgCard'>
            {!props.isLoading && (
              <Img
                className='w-100 PlaylistImgCard h-100'
                src={props.data.src}
                loader={
                  <div className='PlaylistImgCard w-100 h-100 skeleton' />
                }
                unloader={
                  <img
                    className='w-100 PlaylistImgCard h-100'
                    src={ImgPlaceholder}
                    alt='activity card'
                  />
                }
              />
            )}
            {props.isLoading && (
              <div className='PlaylistImgCard w-100 h-100 skeleton' />
            )}
            {/* <ProgressiveImage src={props.data.src} placeholder="">
              {(src, loading) => {
                return loading ? (
                  <div className="PlaylistImgCard w-100 h-100 skeleton" />
                ) : (
                  <img
                    className="w-100 PlaylistImgCard h-100"
                    src={src}
                    alt="activity card"
                  />
                );
              }}
            </ProgressiveImage> */}
          </div>
          {!props.isLoading && (
            <React.Fragment>
              <div className='col-4 playlistTitle d-flex align-items-center'>
                <div>{props.data.name}</div>
              </div>
              <div className='col-3 d-flex  align-items-center'>
                {props.data.interests && (
                  <Tag className='mb-2' color='blue'>
                    {props.data.interests}
                  </Tag>
                )}
              </div>
              <div className='d-flex align-items-center mr-3'>
                {/* value={this.state.is_playlist_selected} */}
                <Checkbox
                  value={props.data.isActive}
                  checked={props.data.isActive}
                  onChange={() => {
                    props.onChangeCheckbox(!props.data.isActive)
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {props.isLoading && (
            <React.Fragment>
              <div className='col-4 playlistTitle d-flex align-items-center'>
                <div className='skeleton'></div>
              </div>
              <div className='col-3 d-flex playlistInterest align-items-center'>
                <div className='skeleton'></div>
              </div>
              <div className='d-flex playlistActive align-items-center'>
                <div className='skeleton'></div>
              </div>
            </React.Fragment>
          )}
        </div>
      </PlayListContainerStyle>
    </div>
  )
}

class CreateCollection extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmDialog: null,
      is_playlist_selected: false,
      loading: false,
      all_loading: false,
      modalVisible: false,
      role_id: '',
      shouldBlockNavigation: true,
      sort: 'newest',
      searchText: '',
      title: '',
      description: '',
      source_text: '',
      source_link: '',
      completion_msg: '',
      skills: [],
      interests: [],
      age_groups: [],
      playlistImage: '',
      selectedAgeGroups: [],
      arrayToRenderPlaylist: [],
      library_collections: [],
      activity_collections: [],
      visibleCards: [],
      selectedCardIds: [],
      filterArrToRemove: [],
      age_groups_selected: [],
      currentCollectionType: '',
      currentCollectionID: '',
      isActivityLoading: false,
      truthObj: {
        title: false,
        age_groups: false,
        arrayToRenderPlaylist: false
      },
      fileList: [],
      default_activities_id: [1, 2], //, 3, 4],
      default_activities: ['Do', 'Watch'],
      allActivity: [],
      is_collection: false,
      is_publish: false,
      change_to_play_list: false,
      is_new: true
    }
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700)
  }

  fetchAgeData = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_AGE_DATA)
      .then(res => {
        const age_grp = res.data.data.getAge.age_groups
        //  let m_age_grp = age_grp.map((data)=>{if(this.state.age_groups_selected.includes(data.id)){
        //     data.is_active=true;
        //   }
        //   return data;
        // })
        // console.log(m_age_grp);
        this.setState({ age_groups: age_grp })
      })
      .catch(err => console.log(err))
  }

  fetchAllCollections = () => {
    this.setState({ loading: true })
    const activityCollectionQuery = GET_ALL_ACTIVITY_COLLECTION
    const libraryCollectionQuery = GET_ALL_LIBRARY_COLLECTION

    activityCollectionQuery.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: { age: this.state.selectedAgeGroups }
    }

    libraryCollectionQuery.variables = {
      filter: { age: this.state.selectedAgeGroups }
    }

    axios
      .all([
        axios.post(`${process.env.REACT_APP_API}`, activityCollectionQuery),
        axios.post(`${process.env.REACT_APP_API}`, libraryCollectionQuery)
      ])
      .then(
        axios.spread((activityRes, libRes) => {
          const inPCollection =
            activityRes.data.data.getAllCollection.inprogress_collection
          const pubCollection =
            activityRes.data.data.getAllCollection.published_collection
          const newActCollection = inPCollection.concat(pubCollection)

          const inPLibCollection =
            libRes.data.data.getAllLibraryCollection.inprogress_libcollection
          const pubLibCollection =
            libRes.data.data.getAllLibraryCollection.published_libcollection
          const newLibCollection = inPLibCollection.concat(pubLibCollection)

          this.setState({
            library_collections: newLibCollection,
            activity_collections: newActCollection,
            loading: false
          })
        })
      )
  }

  fetchActivityCards = id => {
    triggerGAEvent('Playlist', 'Fetching Activity cards')
    this.setState({
      currentCollectionType: 'activity',
      currentCollectionID: id
    })

    let query = GET_ACTIVITY
    query.variables = {
      collections_id: id,
      filter: { age: this.state.selectedAgeGroups }
    }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const activityCards = res.data.data.getAllActivity.activity_details.map(
          i => ({
            ...i,
            is_selected: false,
            type: this.state.currentCollectionType,
            collectionID: parseInt(this.state.currentCollectionID, 10)
          })
        )
        this.setState({ visibleCards: activityCards })
      })
      .catch(err => console.log(err))
  }

  fetchLibraryCards = id => {
    triggerGAEvent('Playlist', 'Fetching Library cards')
    this.setState({
      currentCollectionType: 'library',
      currentCollectionID: id
    })
    let query = GET_LIBRARY
    query.variables = {
      libcollections_id: id,
      filter: { age: this.state.selectedAgeGroups }
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const libraryCards = res.data.data.getAllLibrary.library_details.map(
          i => ({
            ...i,
            is_selected: false,
            type: this.state.currentCollectionType,
            collectionID: parseInt(this.state.currentCollectionID, 10)
          })
        )
        this.setState({ visibleCards: libraryCards })
      })
      .catch(err => console.log(err))
  }

  fetchAssociatedData = () => {
    let associatedDataQuery = GET_ASSOCIATED_DATA
    associatedDataQuery.variables = {
      array: this.state.arrayToRenderPlaylist.map(data => {
        return { id: parseInt(data.id), type: 'activity', order: data.order }
      })
    }
    axios
      .post(`${process.env.REACT_APP_API}`, associatedDataQuery)
      .then(res => {
        this.setState(
          {
            // arrayToRenderPlaylist: res.data.data.getListLibIntrest.lib_act.sort(
            //   (a, b) => a.order - b.order
            // ),
            interests: res.data.data.getListLibIntrest.intrest,
            skills: res.data.data.getListLibIntrest.data_point
          },
          () => {
            // this.loadCheckedItemsFromState()
          }
        )
      })
      .catch(err => console.log(err))
  }

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem('role_id'))
    })
  }

  componentDidMount () {
    // setTimeout(() => {
    this.fetchAgeData()
    this.getLocalStorage()
    // }, 1000);
    if (this.props.match.params.id) {
      if (this.props.location.pathname.includes('collection')) {
        this.fetchOneCollectionData()
        this.setState({ is_collection: true, is_new: false })
      } else {
        this.setState({ is_collection: false, is_new: false })
        this.fetchOneData()
      }
    }
  }
  componentWillReceiveProps = next => {
    console.log('checking', next)
  }
  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount () {
    this.state.confirmDialog && this.state.confirmDialog.destroy()
  }

  onInputChange = (key, value) => {
    triggerGAEvent('Playlist', 'Entering data for a new Playlist')
    this.setState({
      [key]: value
    })
  }

  onSearchFetchData = () => {
    triggerGAEvent('Playlist', 'Searching Activity and Library Collections')
    // this.fetchAllCollections();
    this.fetchAllActivities()
  }

  onSearchChange = e => {
    this.setState({ searchText: e.target.value, loading: true }, () =>
      this.onSearchFetchData()
    )
  }
  onDefaultActivitiesChange = checkedValues => {
    this.setState({ default_activities_id: checkedValues }, () => {
      this.fetchAllActivities()
    })
  }
  filterByAgeGroup = (contents, age) => {
    return _.filter(contents, function (content) {
      return _.some(content.age_group, function (ageGrp) {
        return _.includes(age, parseInt(ageGrp.id))
      })
    })
  }
  onAgeGroupChange = checkedValues => {
    // console.log(checkedValues);
    triggerGAEvent('Playlist', 'Entering data for a new Playlist')
    if (this.state.selectedAgeGroups.length < checkedValues.length) {
      this.setState({ selectedAgeGroups: checkedValues })
    } else {
      const confirmDialog = Modal.confirm({
        title: 'Do you want to change the age group selection?',
        content:
          'Items you have selected are already fall under this group, this action will remove those items from learning path. Are you sure do you want to continue?',
        okText: 'Yes',
        onOk: () => {
          const arrayToRenderPlaylist = this.filterByAgeGroup(
            this.state.arrayToRenderPlaylist,
            checkedValues
          )

          this.setState({
            selectedAgeGroups: checkedValues,
            arrayToRenderPlaylist
          })
        }
      })
      this.setState({ confirmDialog })
    }
  }

  loadCheckedItemsFromState = () => {
    if (this.state.arrayToRenderPlaylist.length) {
      this.setState({
        checkedValues: this.state.arrayToRenderPlaylist.map(
          item => `${item.type}-${item.id}`
        )
      })
    }
  }

  onCardsChange = checkedValues => {
    triggerGAEvent('Playlist', 'Selecting cards from collections for Playlist')
    this.setState({
      selectedCardIds: checkedValues
    })
  }
  onDragEnd = ({ oldIndex, newIndex }) => {
    triggerGAEvent('Playlist', 'Drag and Drop for a new Playlist')

    const items = dragDrop_reorder(
      this.state.arrayToRenderPlaylist,
      oldIndex,
      newIndex
    )

    this.setState({ arrayToRenderPlaylist: items })
  }

  fetchAllActivities = async () => {
    const activities = COLLECTION_PLAYLIST_ACTIVITY
    this.setState({ allActivity: [], isActivityLoading: true })
    activities.variables = {
      // search: this.state.searchText,
      default_activities_id: this.state.default_activities_id.map(data => {
        if (data === 1) {
          return 4
        }
        return data
      }),
      activities_id: this.state.arrayToRenderPlaylist.map(data =>
        Number(data.id)
      ),
      age: this.state.selectedAgeGroups
    }
    // if (this.state.default_activities_id.length) {
    //   activities.variables.
    // }
    if (this.state.searchText !== '') {
      activities.variables.search = this.state.searchText
    }
    const selectedCardIds = [...this.state.selectedCardIds].map(data => data.id)
    const allActivity = await axios.post(
      `${process.env.REACT_APP_API}`,
      activities
    )
    const renderArray = this.state.arrayToRenderPlaylist.map(data => data.id)
    try {
      const allActivityDetails = allActivity.data.data
        .ColectionPlaylistActivites
        ? allActivity.data.data.ColectionPlaylistActivites.activity_details ||
          []
        : []
      const modifiedActivityDetails = allActivityDetails
        .map(data => {
          // console.log(
          //   selectedCardIds,
          //   selectedCardIds.includes(data.id),
          //   data.id
          // );
          data.src = data.thumb_images
            ? data.thumb_images.length
              ? data.thumb_images[0].image_url
              : ''
            : ''
          // id: data.id,
          data.interests = data.interests
            ? (data.interests.interest_details[0] || {}).name
            : ''
          data.isActive = selectedCardIds.includes(data.id) ? true : false
          if (!renderArray.includes(data.id)) return data
        })
        .filter(data => data)
      // console.log(selectedCardIds);
      this.setState({
        allActivity: modifiedActivityDetails,
        isActivityLoading: false
      })
    } catch (e) {
      console.log(e)
    }
    // console.log(allActivity);
  }
  showModal = () => {
    triggerGAEvent('Playlist', 'Fetching Activity and Library Collections')
    this.fetchAllActivities()
    //this.fetchAllCollections();
    this.setState({ modalVisible: true })
  }

  addCardsToPlayList = () => {
    // triggerGAEvent("Playlist", "Adding cards for a new Playlist");
    // if (this.state.selectedCardIds.length > 2) {
    let arrayToRenderPlaylist = [
      ...this.state.arrayToRenderPlaylist,
      ...this.state.selectedCardIds
    ].map((card, index) => ({
      id: card.id,
      order: this.state.arrayToRenderPlaylist.filter(x => x.id === card.id)
        .length
        ? this.state.arrayToRenderPlaylist.filter(x => x.id === card.id)[0]
            .order
        : index,
      ...card
      //type: card.includes("activity") ? "activity" : "library"
    }))
    this.setState(
      {
        arrayToRenderPlaylist,
        modalVisible: false,
        selectedCardIds: [],
        searchText: ''
      },
      () => {
        this.fetchAssociatedData()
      }
    )
    // } else {
    //   openNotification(
    //     "error",
    //     "A Collection should have a minimum of 3 Approved contents to get published."
    //   );
    // }
  }

  handleCancel = () => {
    triggerGAEvent('Playlist', 'Closing Add Activity and Library Modal Popup')
    this.setState({ modalVisible: false, selectedCardIds: [], searchText: '' })
  }

  removeFilteredArray = () => {
    let removedArray = this.state.filterArrToRemove.map(card => {
      this.state.arrayToRenderPlaylist.slice(card.order)
      return removedArray
    })
    const arrayToRenderPlaylist = _.difference(
      this.state.arrayToRenderPlaylist,
      this.state.filteredArrToRemove
    ).map((card, index) => ({
      ...card,
      order: index
    }))
    this.setState({ arrayToRenderPlaylist })
  }

  validateStateValues = () => {
    let truthObj = {}
    truthObj['title'] = this.state.title === ''
    truthObj['age_groups'] = this.state.selectedAgeGroups.length === 0
    truthObj['description'] = this.state.description === ''
    // truthObj["arrayToRenderPlaylist"] =
    //   // this.state.arrayToRenderPlaylist.length === 0 ||
    //   this.state.arrayToRenderPlaylist.length < 2;
    truthObj['source_text'] =
      this.state.source_text === '' && this.state.source_link !== ''
    truthObj['source_link'] =
      this.state.source_link !== ''
        ? !YOUTUBE_VIDEO_REGEX.test(this.state.source_link)
        : false
    truthObj['playlistImage'] = this.state.playlistImage === ''
    // console.log(truthObj);

    setTimeout(() => {
      this.setState({ truthObj: truthObj })
    }, 1000)
    setTimeout(() => {
      this.checkErrorExistence()
    }, 1500)
  }

  checkErrorExistence = () => {
    if (this.state.arrayToRenderPlaylist.length < 3) {
      openNotification(
        'error',
        `A ${
          this.state.is_playlist_selected ? 'Learning path' : 'Collection'
        } should have a minimum of 3 Approved contents to get published.`
      )
    } else {
      if (_.includes(this.state.truthObj, true)) {
        openNotification('error', 'Please fill required values')
      } else {
        this.createPlayList()
      }
    }
  }

  onCollapseChange = () => {
    triggerGAEvent('Playlist', 'Changing Collapse in Create Playlist')
  }
  fetchOneData = () => {
    let pListQuery = GET_ONE_PLAYLIST
    pListQuery.variables = {
      id: this.props.match.params.id
    }
    this.setState({ all_loading: true })

    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        // this.setState({
        //   arrayToRenderPlaylist: res.data.data.getOnePlaylist.array.lib_act.sort(
        //     (a, b) => a.order - b.order
        //   ),
        //   title: res.data.data.getOnePlaylist.title,
        //   source_text: res.data.data.getOnePlaylist.source_text,
        //   source_link: res.data.data.getOnePlaylist.source_link,
        //   interests: res.data.data.getOnePlaylist.array.intrest,
        //   data_points: res.data.data.getOnePlaylist.array.data_point,
        //   age_groups: res.data.data.getOnePlaylist.age_group,
        //   loading: false,
        //   is_archive: res.data.data.getOnePlaylist.is_archive,
        //   is_publish: res.data.data.getOnePlaylist.is_publish
        // });
        const arrayToRenderPlaylist = res.data.data.getOnePlaylist.array.lib_act.map(
          data => {
            let difficulty = { Easy: [], Medium: [], Hard: [] }
            ;(data.age_groups || []).map(ageGrp => {
              if (ageGrp) {
                if (ageGrp.age_group)
                  difficulty[ageGrp.age_group_dificulty.dificulty].push(
                    ageGrp.age_group.age
                  )
              }
            })
            data.age_diff = difficulty
            data.thumb_images = data.thumb_image
            return data
          }
        )
        this.setState(
          {
            arrayToRenderPlaylist: arrayToRenderPlaylist.sort(
              (a, b) => a.order - b.order
            ),
            title: res.data.data.getOnePlaylist.title,
            description:
              res.data.data.getOnePlaylist.description === null
                ? ''
                : res.data.data.getOnePlaylist.description,
            completion_msg:
              res.data.data.getOnePlaylist.completion_msg === null
                ? ''
                : res.data.data.getOnePlaylist.completion_msg,
            background_image: res.data.data.getOnePlaylist.background_image,
            // fileList:[{thumbUrl:res.data.data.getOnePlaylist.background_image,response:res.data.data.getOnePlaylist.background_image}],
            all_loading: false,
            source_text: res.data.data.getOnePlaylist.source_text || '',
            source_link: res.data.data.getOnePlaylist.source_link || '',
            interests: res.data.data.getOnePlaylist.array.intrest,
            data_points: res.data.data.getOnePlaylist.array.data_point,
            playlistImage: res.data.data.getOnePlaylist.background_image,
            age_groups_selected: res.data.data.getOnePlaylist.age_group.map(
              data => data.id + ''
            ),
            loading: false,
            fileList: [
              {
                uid: 1,
                url: res.data.data.getOnePlaylist.background_image,
                response: res.data.data.getOnePlaylist.background_image,
                name: 'Thumbnail',
                status: 'done'
              }
            ],
            is_archive: res.data.data.getOnePlaylist.is_archive,
            is_publish: res.data.data.getOnePlaylist.is_publish,
            is_playlist_selected: true
            // selectedCardIds: res.data.data.getOnePlaylist.array.lib_act.map(
            //   data => {
            //     return { id: data.id + "" };
            //   }
            // )
          },
          () => {
            const age_grp = this.state.age_groups
            // console.log(this.state.age_groups_selected);
            const m_age = age_grp
              .filter(data => {
                if (this.state.age_groups_selected.includes(data.id)) {
                  data.is_active = false
                  return data
                }
              })
              .map(data => parseInt(data.id))
            this.setState({ selectedAgeGroups: m_age })
          }
        )
      })
      .catch(err => console.log(err))
  }
  fetchOneCollectionData = () => {
    let pListQuery = GET_ONE_COLLECTION
    pListQuery.variables = {
      id: this.props.match.params.id
    }
    this.setState({ all_loading: true })

    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        const arrayToRenderPlaylist = res.data.data.GetOneCollection.array.lib_act.map(
          data => {
            let difficulty = { Easy: [], Medium: [], Hard: [] }
            ;(data.age_groups || []).map(ageGrp => {
              if (ageGrp) {
                if (ageGrp.age_group)
                  difficulty[ageGrp.age_group_dificulty.dificulty].push(
                    ageGrp.age_group.age
                  )
              }
            })
            data.age_diff = difficulty
            data.thumb_images = data.thumb_image
            return data
          }
        )
        this.setState(
          {
            all_loading: false,
            arrayToRenderPlaylist: arrayToRenderPlaylist.sort(
              (a, b) => a.order - b.order
            ),
            title: res.data.data.GetOneCollection.title,
            description:
              res.data.data.GetOneCollection.description === null
                ? ''
                : res.data.data.GetOneCollection.description,
            completion_msg:
              res.data.data.GetOneCollection.completion_msg === null
                ? ''
                : res.data.data.GetOneCollection.completion_msg,
            background_image: res.data.data.GetOneCollection.background_image,
            fileList: [
              {
                uid: 1,
                url: res.data.data.GetOneCollection.background_image,
                response: res.data.data.GetOneCollection.background_image,
                name: 'Thumbnail',
                status: 'done'
              }
            ],
            // fileList:[{thumbUrl:res.data.data.GetOneCollection.background_image,response:res.data.data.GetOneCollection.background_image}],
            source_text: res.data.data.GetOneCollection.source_text || '',
            source_link: res.data.data.GetOneCollection.source_link || '',
            interests: res.data.data.GetOneCollection.array.intrest,
            data_points: res.data.data.GetOneCollection.array.data_point,
            age_groups_selected: res.data.data.GetOneCollection.age_group.map(
              data => data.id + ''
            ),
            loading: false,
            is_archive: res.data.data.GetOneCollection.is_archive,
            is_publish: res.data.data.GetOneCollection.is_publish,
            playlistImage: res.data.data.GetOneCollection.background_image,
            is_playlist_selected: false

            // selectedCardIds: res.data.data.GetOneCollection.array.lib_act.map(
            //   data => {
            //     return { id: data.id + "" };
            //   }
            // )
          },
          () => {
            const age_grp = this.state.age_groups
            // console.log(this.state.age_groups_selected);
            const m_age = age_grp
              .filter(data => {
                if (this.state.age_groups_selected.includes(data.id)) {
                  data.is_active = false
                  return data
                }
              })
              .map(data => parseInt(data.id))
            this.setState({ selectedAgeGroups: m_age })
          }
        )
      })
      .catch(err => console.log(err))
  }

  createPlayList = () => {
    triggerGAEvent('Collection', 'Creating a new Collection')
    this.setState({ all_loading: true })
    let mutation = this.props.match.params.id
      ? EDIT_COLLECTION
      : CREATE_COLLECTION
    mutation.variables = {
      title: this.state.title.trim(),
      source_text: this.state.source_text,
      description: this.state.description,
      completion_msg: this.state.completion_msg,
      source_link: this.state.source_link,
      is_play_list: this.state.is_playlist_selected,
      age_group: this.state.selectedAgeGroups.map(a => ({ age_group_id: a })),
      array: this.state.arrayToRenderPlaylist.map((card, index) => ({
        id: parseInt(card.id),
        order: index, //card.order,
        type: 'activity' //card.type
      })),
      background_image: this.state.playlistImage
    }

    if (this.props.match.params.id) {
      mutation.variables.change_to_collection = this.props.location.pathname.includes(
        'playlist'
      )
        ? !this.state.is_playlist_selected
        : false
      mutation.variables.change_to_play_list = this.state.change_to_play_list
      mutation.variables.id = this.props.match.params.id
      mutation.variables.is_play_list = this.state.is_collection ? false : true
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        // console.log(res);
        this.setState({
          loading: false,
          shouldBlockNavigation: false,
          all_loading: false
        })
        if (res.data.errors) {
          openNotification(
            'error',
            res.data.errors
              ? res.data.errors[0]
                ? res.data.errors[0].message
                : 'Collection title already available try different title.'
              : ''
          )
        } else {
          if (res.data.data.PostCollectionPlayList) {
            if (this.state.is_playlist_selected) {
              this.props.history.push(
                `/playlist/${res.data.data.PostCollectionPlayList.id}/view`
              )
            } else {
              this.props.history.push(
                `/collection/${res.data.data.PostCollectionPlayList.id}/view`
              )
            }
          } else if (res.data.data.EditCollectionPlaylist) {
            if (this.state.is_playlist_selected) {
              this.props.history.push(
                `/playlist/${res.data.data.EditCollectionPlaylist.id}/view`
              )
            } else {
              this.props.history.push(
                `/collection/${res.data.data.EditCollectionPlaylist.id}/view`
              )
            }
          }
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({
          loading: false,
          shouldBlockNavigation: false,
          all_loading: false
        })
      })
  }
  materialRequiredImage = file => {
    // triggerGAEvent('Activities', 'Entering data for creating a new activity');
    // console.log(file);
    if (file.length === 0) {
      this.setState({ playlistImage: '' })
    }
    const state = { ...this.state }
    state.fileList = file
    state.fileList = state.fileList.map(data => {
      data.url = data.response
      return data
    })
    this.setState({ fileList: state.fileList })
  }
  materialRequiredOnchange = (key, value) => {
    // triggerGAEvent("Activities", "Entering data for creating a new activity");
    // console.log(value);
    this.setState({ playlistImage: value })
    // const state = { ...this.state };
    // state.material_required[index][key] = value;
    // this.setState({ material_required: state.material_required });
  }
  onChangeCheckbox = (index, val) => {
    const mdata = [...this.state.allActivity]
    mdata[index].isActive = val
    const selectedCardIdsActive = mdata.filter(data => data.isActive)
    // console.log(selectedCardIdsActive);
    const selectedCardIds = selectedCardIdsActive.map(data => {
      let difficulty = { Easy: [], Medium: [], Hard: [] }
      ;(data.age_groups || []).map(ageGrp => {
        if (ageGrp) {
          difficulty[ageGrp.age_group_dificulty.dificulty].push(
            ageGrp.age_group.age
          )
        }
      })
      data.age_diff = difficulty
      return data
    })
    this.setState({ allActivity: mdata, selectedCardIds })
  }
  onRemovePlaylist = index => {
    if (this.state.arrayToRenderPlaylist.length < 4) {
      openNotification(
        'error',
        `A ${
          this.state.is_playlist_selected ? 'Learning path' : 'Collection'
        } should have a minimum of 3 Approved contents to get published.`
      )
    } else {
      Modal.confirm({
        title: 'Do you want to remove the learning content?',
        content: `This learning content will be removed from ${
          this.state.is_playlist_selected ? 'Learning path' : 'Collection'
        }. Do you want to continue?`,
        okText: 'Yes',
        onOk: () => {
          // onRemovePlaylist()
          let arrayToRenderPlaylist = [...this.state.arrayToRenderPlaylist]

          arrayToRenderPlaylist.splice(index, 1)

          this.setState({ arrayToRenderPlaylist: arrayToRenderPlaylist })
        }
      })
    }
  }
  ArchiveVisibleProgress = () => {
    const query = ARCHIVE_COLLECTION
    query.variables = { id: this.props.match.params.id }
    this.setState({ loading: true })
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({ loading: false })
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          message.success('Collection is Archived')
          this.props.history.push('/learningcontent')
        }
      })
      .catch(err => console.log(err))
  }
  onArchiveCollectionClick = () => {
    triggerGAEvent('Collection', 'Archiving a collection')
    const confirmDialog = Modal.confirm({
      icon: <img src={Question} alt='question' />,
      title: 'Archive Collection',
      content:
        'This will remove the Collection. The collection will anyways stored and saved under Archive Tab',
      okText: 'Continue Anyway',
      okType: 'danger',
      onOk: () => {
        this.ArchiveVisibleProgress()
      },
      centered: true
    })
    this.setState({ confirmDialog })
  }
  render () {
    const { interests, skills, age_groups } = this.state
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Loading is_loading={this.state.all_loading} />
        <div className='view-playlist'>
          <div style={{ background: 'white' }}>
            <BreadcrumbView
              title={
                this.state.title
                  ? this.state.title
                  : `Untitled ${
                      this.state.is_playlist_selected
                        ? 'Learning path'
                        : 'Collection'
                    }`
              }
              data={
                !this.state.is_playlist_selected
                  ? `/${
                      this.props.match.params.id
                        ? 'Learning Content'
                        : 'collections'
                    }/${
                      this.state.title
                        ? (!this.state.is_new
                            ? (this.state.is_publish
                                ? '/published'
                                : '/un-published') + '/'
                            : '') + this.state.title
                        : `Untitled ${
                            this.state.is_playlist_selected
                              ? 'Learning path'
                              : 'Collection'
                          }`
                    }`
                  : `/${
                      this.props.match.params.id
                        ? 'Learning Content'
                        : 'Learning Path'
                    }/${
                      this.state.title
                        ? (!this.state.is_new
                            ? (this.state.is_publish
                                ? '/published'
                                : '/un-published') + '/'
                            : '') + this.state.title
                        : `Untitled ${
                            this.state.is_playlist_selected
                              ? 'Learning Path'
                              : 'Collection'
                          }`
                    }`
              }
            >
              {!this.props.match.params.id && (
                <Button
                  size='large'
                  type='primary'
                  onClick={this.validateStateValues}
                  className='btn-w250'
                >
                  {`Create ${
                    this.state.is_playlist_selected
                      ? 'Learning Path'
                      : 'Collection'
                  }`}
                </Button>
              )}
              {this.props.match.params.id && (
                <React.Fragment>
                  <React.Fragment>
                    <Button
                      size='large'
                      className='mr-3'
                      onClick={
                        () => {
                          if (
                            this.props.location.pathname.includes('playlist')
                          ) {
                            this.props.history.push(
                              `/playlist/${this.props.match.params.id}/view`
                            )
                          } else {
                            this.props.history.push(
                              `/collection/${this.props.match.params.id}/view`
                            )
                          }
                        }
                        // this.props.history.push(
                        //   `/playlists/view/${this.props.match.params.id}`
                        // )
                      }
                    >
                      Cancel
                    </Button>
                  </React.Fragment>

                  {this.state.is_publish && (
                    <Button
                      ghost
                      size='large'
                      className='mr-3'
                      type='danger'
                      onClick={this.onArchiveCollectionClick}
                    >
                      Archive
                    </Button>
                  )}
                  <Button
                    size='large'
                    type='primary'
                    onClick={this.validateStateValues}
                    className='btn-w250'
                  >
                    {/* {`Update ${
                    this.state.is_collection ? "Collection" : "Playlist"
                  }`} */}
                    Save Changes
                  </Button>
                </React.Fragment>
              )}
            </BreadcrumbView>
          </div>
          <div style={{ margin: '2rem' }}>
            <Card className='create-playlist-title'>
              <div className='row m-0'>
                <div className='col-6 p-0'>
                  <InputC
                    title='Title'
                    stateKey='title'
                    id={this.state.truthObj['title'] ? 'has-error' : ''}
                    onChange={this.onInputChange}
                    value={this.state.title}
                    placeholder={`Title of the ${
                      this.state.is_playlist_selected
                        ? 'learning path'
                        : 'collection'
                    }`}
                    className='ant-input ant-input-lg font-16'
                    titleStyle='mb-3 FontAvenirMedium text-uppercase  text-secondary font-16 requiredAsterisk'
                  />
                </div>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                  {/* {this.props.match.params.id && (
                    <div>
                      {" "}
                      <div className="mb-3 FontAvenirRoman font-16 text-uppercase text-secondary">
                        Type
                      </div>
                      <div>
                        {this.props.location.pathname.includes("collection")
                          ? "Collection"
                          : "Playlist"}
                      </div>
                    </div>
                  )} */}
                  {!this.props.location.pathname.includes('playlist') && (
                    <Checkbox
                      name={'createPlaylist'}
                      checked={this.state.is_playlist_selected}
                      value={this.state.is_playlist_selected}
                      onChange={() => {
                        this.setState({
                          is_playlist_selected: !this.state
                            .is_playlist_selected,
                          change_to_play_list: !this.state.change_to_play_list
                        })
                      }}
                    >
                      Make as learning path
                    </Checkbox>
                  )}
                  {this.props.location.pathname.includes('playlist') && (
                    <Checkbox
                      name={'createPlaylist'}
                      checked={this.state.is_playlist_selected}
                      value={this.state.is_playlist_selected}
                      disabled={this.state.is_publish}
                      onChange={() => {
                        this.setState({
                          is_playlist_selected: !this.state.is_playlist_selected
                        })
                      }}
                    >
                      Make as learning path
                    </Checkbox>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-start justify-content-start'>
                <div className='mt-4 playlistUpload'>
                  <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary requiredAsterisk'>
                    Thumbnail
                  </div>
                  <div
                    className={`position-relative ${
                      this.state.truthObj['playlistImage'] ? 'has-error' : ''
                    }`}
                  >
                    <ImageUploadCrop
                      width={this.state.role_id === 2 ? 234 : 325}
                      height={this.state.role_id === 2 ? 274 : 182}
                      imageUrl={this.state.playlistImage}
                      onSuccess={value => {
                        this.materialRequiredOnchange('image', value)
                      }}
                      aspect={1}
                      uploadIcon
                      imageClassName='d-flex align-items-center justify-content-center'
                    />
                  </div>
                </div>
                <div className='mt-4 ml-4 playlistUpload d-flex flex-column align-items-start justify-content-start'>
                  <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary requiredAsterisk'>
                    DESCRIPTION
                  </div>
                  <div className='d-flex flex-column align-items-end justify-content-end'>
                    <Input.TextArea
                      id={this.state.truthObj['description'] ? 'has-error' : ''}
                      className={`position-relative mb-2`}
                      style={{ height: 80, width: 726 }}
                      placeholder='Enter the collection description'
                      onChange={e => {
                        if (e.target.value.length <= 280) {
                          this.onInputChange('description', e.target.value)
                        }
                      }}
                      onPaste={e => {
                        if (e.clipboardData.getData('Text').length > 280) {
                          this.onInputChange(
                            'description',
                            e.clipboardData.getData('Text').substring(0, 280)
                          )
                        }
                      }}
                      value={this.state.description}
                    />
                    <div
                      style={
                        this.state.description.length >= 280
                          ? {
                              color: '#FF0A00',
                              float: 'right',
                              marginTop: 8
                            }
                          : { float: 'right', marginTop: 8 }
                      }
                    >
                      {this.state.description.length} / 280
                    </div>
                  </div>
                  <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary'>
                    COMPLETION MESSAGE
                  </div>
                  <div className='d-flex flex-column align-items-end justify-content-end'>
                    <Input.TextArea
                      id={
                        this.state.truthObj['completion_msg'] ? 'has-error' : ''
                      }
                      className={`position-relative`}
                      style={{ height: 80, width: 726 }}
                      placeholder='The learner will see this message after completing the collection or learning path'
                      onChange={e => {
                        if (e.target.value.length <= 240) {
                          this.onInputChange('completion_msg', e.target.value)
                        }
                      }}
                      onPaste={e => {
                        if (e.clipboardData.getData('Text').length > 240) {
                          this.onInputChange(
                            'completion_msg',
                            e.clipboardData.getData('Text').substring(0, 240)
                          )
                        }
                      }}
                      value={this.state.completion_msg}
                    />
                    <div
                      style={
                        this.state.completion_msg.length >= 240
                          ? {
                              color: '#FF0A00',
                              float: 'right',
                              marginTop: 8
                            }
                          : { float: 'right', marginTop: 8 }
                      }
                    >
                      {this.state.completion_msg.length} / 240
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className='titleCard text-uppercase'>
              {`Details of this ${
                this.state.is_playlist_selected ? 'Learning Path' : 'Collection'
              }`}
            </div>
            <Card
              defaultActiveKey={['1']}
              className='createPlistCollapse mt-0'
              // onChange={this.onCollapseChange}
            >
              <div header='Details of this Learning Path' key='1'>
                {interests.length > 0 && (
                  <Row className='mb-4'>
                    <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary'>
                      Interests
                    </div>
                    {/* className="interests-view-box" */}
                    <div>
                      {interests.map((ai, i) => (
                        <Tag className='mb-2' key={i} color='blue'>
                          {ai.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <div className='mb-4'>
                  <div className='d-flex align-items-end justify-content-start'>
                    <div className='col-6 p-0 mr-2'>
                      <InputC
                        title='Source Credit'
                        stateKey='source_text'
                        id={
                          this.state.truthObj['source_text'] ? 'has-error' : ''
                        }
                        onChange={(key, value) => {
                          if (value.length <= 32) {
                            this.onInputChange(key, value)
                          }
                        }}
                        onPaste={e => {
                          if (e.clipboardData.getData('Text').length > 32) {
                            this.onInputChange(
                              'source_text',
                              e.clipboardData.getData('Text').substring(0, 32)
                            )
                          }
                        }}
                        value={this.state.source_text}
                        placeholder='Enter display name for credit link'
                        className='ant-input ant-input-lg font-16'
                        titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16'
                      />
                    </div>
                    <span
                      style={
                        this.state.source_text.length >= 32
                          ? { color: '#FF0A00' }
                          : {}
                      }
                    >
                      {`${this.state.source_text.length} / 32`}
                    </span>
                  </div>
                  <InputC
                    title=''
                    stateKey='source_link'
                    id={this.state.truthObj['source_link'] ? 'has-error' : ''}
                    onChange={this.onInputChange}
                    value={this.state.source_link}
                    placeholder='Enter credit link'
                    className='ant-input ant-input-lg font-16'
                  />
                </div>
                {skills.length > 0 && (
                  <Row className='mb-4'>
                    <div className='mb-4 FontAvenirRoman font-16 text-uppercase text-secondary'>
                      Skills
                    </div>
                    {/* className="interests-view-box" */}
                    <div>
                      {skills.map((ai, i) => (
                        <Tag className='mb-2' key={i} color='blue'>
                          {ai.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary requiredAsterisk'>
                  Age group
                </div>
                <Checkbox.Group
                  value={this.state.selectedAgeGroups}
                  style={{ width: '100%' }}
                  onChange={this.onAgeGroupChange}
                >
                  <Row>
                    {age_groups.map((group, index) => (
                      <Col span={3} key={index}>
                        <Checkbox name={group.age} value={Number(group.id)}>
                          {group.age}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
            </Card>
            {this.state.arrayToRenderPlaylist.length !== 0 && (
              <div className='mb-3 position-relative'>
                <div className='titleCard text-uppercase'>
                  {`CONTENTS AVAILABLE IN THIS ${
                    this.state.is_playlist_selected
                      ? 'LEARNING PATH'
                      : 'COLLECTION'
                  }
                  `}
                </div>
                <React.Fragment>
                  {!this.state.is_publish && (
                    <div className='position-absolute approvedButton'>
                      <Button
                        block
                        size='large'
                        onClick={this.showModal}
                        icon='plus'
                        type='primary'
                      >
                        Add approved contents
                      </Button>
                    </div>
                  )}
                  {this.props.location.pathname.includes('collection') &&
                    this.state.is_publish && (
                      <div className='position-absolute approvedButton'>
                        <Button
                          block
                          size='large'
                          onClick={this.showModal}
                          icon='plus'
                          type='primary'
                        >
                          Add approved contents
                        </Button>
                      </div>
                    )}
                </React.Fragment>
              </div>
            )}

            {this.state.arrayToRenderPlaylist.length === 0 && (
              <React.Fragment>
                <React.Fragment>
                  {!this.state.is_publish && (
                    <div className='mb-3'>
                      <Button
                        block
                        disabled={this.state.selectedAgeGroups.length === 0}
                        size='large'
                        onClick={this.showModal}
                        icon='plus'
                        type='primary'
                      >
                        Click to add approved contents
                      </Button>
                    </div>
                  )}
                  {this.props.location.pathname.includes('collection') &&
                    this.state.is_publish && (
                      <div className='mb-3'>
                        <Button
                          block
                          disabled={this.state.selectedAgeGroups.length === 0}
                          size='large'
                          onClick={this.showModal}
                          icon='plus'
                          type='primary'
                        >
                          Click to add approved contents
                        </Button>
                      </div>
                    )}
                </React.Fragment>
              </React.Fragment>
            )}
            {this.state.arrayToRenderPlaylist.length !== 0 && (
              <div className='mt-3'>
                <Card>
                  <SortableList
                    axis='xy'
                    items={this.state.arrayToRenderPlaylist}
                    onSortEnd={this.onDragEnd}
                    onRemovePlaylist={this.onRemovePlaylist}
                    distance={1}
                  />
                  {/* <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="row"
                        >
                          {this.state.arrayToRenderPlaylist.map(
                            (item, index) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`${index}item`}
                                  index={index}
                                  // className="col-4"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="col-4"
                                    >
                                      <ExpertCard
                                        videoRefIcon={true}
                                        counter={index + 1}
                                        imgRefIcon={
                                          item.activity_reference_images
                                        }
                                        stepsRefIcon={item.steps}
                                        pdfIcon={item.pdf_url}
                                        floatingContainerLeft={true}
                                        title={item.name}
                                        close
                                        time={item.time}
                                        difficulty={item.age_diff}
                                        difficultyVisible={
                                          item.age_diff ? true : false
                                        }
                                        onClose={() => {
                                          this.onRemovePlaylist(index);
                                        }}
                                        Tag={[
                                          {
                                            name: item.status,
                                            type:
                                              item.status === "Approved"
                                                ? "success"
                                                : "danger"
                                          },
                                          {
                                            name: item.activity_state,
                                            type:
                                              item.activity_state === "Active"
                                                ? "warning"
                                                : "danger"
                                          },
                                          {
                                            name: item.is_changed
                                              ? "Unpublished changes"
                                              : "",
                                            type: item.is_changed
                                              ? "danger"
                                              : "success"
                                          }
                                        ]}
                                        src={
                                          item.thumb_images
                                            ? item.thumb_images.length
                                              ? item.thumb_images[0].image_url
                                              : ""
                                            : ""
                                        }
                                      ></ExpertCard>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> */}
                </Card>
              </div>
            )}
            {/* <Collapse
              defaultActiveKey={["1"]}
              className="createPlistCollapse"
              onChange={this.onCollapseChange}
            >
              <Collapse.Panel
                className={
                  this.state.truthObj["arrayToRenderPlaylist"]
                    ? "has-error-ma"
                    : ""
                }
                header="Linked Activities and Libraries"
                key="1"
              >
                <div className="link-act-lib">
                  {this.state.selectedAgeGroups.length > 0 && (
                    <Button
                      block
                      onClick={this.showModal}
                      icon="plus"
                      type="primary"
                    >
                      Click to link Activities & Libraries
                    </Button>
                  )}
                </div>
              </Collapse.Panel>
            </Collapse> */}
          </div>
        </div>
        <Modal
          width='75%'
          visible={this.state.modalVisible}
          title={`Select Contents (Approved) to be added in this ${
            this.state.is_playlist_selected ? 'learning path' : 'collection'
          } - ${
            this.state.title
              ? this.state.title
              : `Untitled ${
                  this.state.is_playlist_selected
                    ? 'Learning Path'
                    : 'Collection'
                }`
          }`}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={[
            <div
              className='d-flex align-items-center justify-content-between'
              key='footer'
            >
              <div className='FontAvenirMedium font-16 text-primary'>
                {`${
                  this.state.selectedCardIds.length
                    ? `${this.state.selectedCardIds.length} Content${
                        this.state.selectedCardIds.length > 1 ? 's' : ''
                      } Selected`
                    : ``
                }`}
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <Button key='back' size='large' onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button
                  key='submit'
                  size='large'
                  type='primary'
                  className='ml-3'
                  //loading={this.state.loading}
                  onClick={this.addCardsToPlayList}
                >
                  {`Add to ${
                    this.state.is_playlist_selected
                      ? 'Learning Path'
                      : 'Collection'
                  }`}
                </Button>
              </div>
            </div>
          ]}
        >
          <Row className='rounded'>
            <div className='col-12 p-0'>
              <Checkbox.Group
                value={this.state.default_activities_id}
                style={{ width: '100%' }}
                onChange={this.onDefaultActivitiesChange}
              >
                <Row>
                  {this.state.default_activities.map((group, index) => (
                    <Col span={3} key={index}>
                      <Checkbox name={group} value={Number(index + 1)}>
                        {group}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </div>
            <div className='col-12 p-0 mt-2'>
              <Input.Search
                style={{ width: '100%', border: '1px solid #4A90E2' }}
                value={this.state.searchText}
                name='searchText'
                size='large'
                placeholder='Search'
                onChange={e => this.onSearchChange(e)}
              />
            </div>
            <div style={{ height: '25vh', overflow: 'scroll' }}>
              {!this.state.isActivityLoading && (
                <React.Fragment>
                  {this.state.allActivity.length === 0 && (
                    <React.Fragment>
                      <div className=' d-flex justify-content-center align-items-center flex-column'>
                        <img className='mb-3' src={EmptyList} alt='' />
                        <div className='text-center'>
                          <p className='FontAvenirMedium font-20 mb-2'>
                            {this.state.searchText === ''
                              ? 'Your contents are not approved yet. Please wait until your contents get approved'
                              : 'No results found. Please try again with different keywords'}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.allActivity.length !== 0 && (
                    <React.Fragment>
                      {this.state.allActivity.map((data, index) => {
                        return (
                          <PlayListContainer
                            data={data}
                            onChangeCheckbox={val => {
                              this.onChangeCheckbox(index, val)
                            }}
                          />
                        )
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {this.state.isActivityLoading && (
                <React.Fragment>
                  {[{}, {}, {}].map((data, index) => {
                    return (
                      <PlayListContainer
                        data={{}}
                        isLoading={true}
                        onChangeCheckbox={val => {
                          // this.onChangeCheckbox(index, val);
                        }}
                      />
                    )
                  })}
                </React.Fragment>
              )}
              {/* <PlayListContainer ></PlayListContainer> */}
            </div>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CreateCollection
