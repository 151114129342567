import React from "react";
import validator from "validator";

export const isRequired = (value) => {
  return validator.isEmpty(value);
};

export const isEmail = (value) => {
  return validator.isEmail(value);
};

export const required = (value, props) => {
  if (!value || (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">Required</span>;
  }
};

export const required1 = (value, props) => {
  if (!value || (props.isCheckable && !props.checked)) {
    return <span className="form-error1 is-visible">Required</span>;
  }
};

export const password = (value, props) => {
  const RegExp = /^(?=.*\d)(?=.*[!@#$_%^&*])(?=.*[a-zA-z]).{8,}$/;
  if (!RegExp.test(value)) {
    return (
      <span className="form-error is-visible">
        {value.length < 8 ? "Password must be minimum 8 Character" : "Password does not match the criteria mentioned below."}
      </span>
    );
  }
};

export const youtubeUrl = (value) => {
  const YOUTUBE_VIDEO_REGEX = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;
  if (value.length > 0 && !YOUTUBE_VIDEO_REGEX.test(value)){
    return <span className="form-error is-visible">This is not a valid Video URL</span>;
  }
};

export const email = value => {
  if (!validator.isEmail(value)) {
    return (
      <span className="form-error is-visible">{`${value} is not a valid email.`}</span>
    );
  }
};

export const loginEmailCheck = value => {
  if (!validator.isEmail(value)) {
    return (
      <span className="form-error1 is-visible">{`Your email address has an invalid format. Please correct and try again.`}</span>
    );
  }
};

export const confirmpassword = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
  if (value !== components["confirm"][0].value) {
    // components['password'][0].value !== components['confirm'][0].value
    // 'confirm' - name of input
    // components['confirm'] - array of same-name components because of checkboxes and radios
    return <span className="form-error is-visible">Password Mismatch</span>;
  }
};


export const confirm_password = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
  if (value !== components["confirm_password"][0].value) {
    // components['password'][0].value !== components['confirm'][0].value
    // 'confirm' - name of input
    // components['confirm'] - array of same-name components because of checkboxes and radios
    return <span className="form-error is-visible">Password Mismatch</span>;
  }
};
