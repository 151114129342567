import { Icon, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import { GET_LEARNERS_CREATED_PARENTS_LIST } from './graphql';

class LearnersNotCreatedParents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      table_data: [],
      page: 1,
      total: 0,
      filterInfo: null,
      sorterInfo: {
        columnKey: "last_login",
        field: "last_login",
        order: "descend"
      },
    };
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    let query = GET_LEARNERS_CREATED_PARENTS_LIST;
    query.variables = {
      page: this.state.page,
      isStudent: true,
      isParent: false,
      filter: {
        student_name: sorterInfo.columnKey === "name" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
        last_login: sorterInfo.columnKey === "last_login" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          table_data: res.data.data.getLearnerSignedupReports.learner_signedup_result,
          total: res.data.data.getLearnerSignedupReports.totalPages,
          is_loading: false
        })
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sorterInfo: sorter,
      filterInfo: filters
    }, () => this.fetchData());
  };

  render() {
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    const columns = [
      {
        title: "CHILD NAME",
        dataIndex: "name",
        render: (text, record, index) => (
          <div
            className="cursor-pointer"
            onClick={() => this.props.history.push(`/learners/${record.id}`)}
          >
            {record.is_delete ? <span>Account Deleted</span> :
              `${record.fname} ${record.lname}`}
          </div>
        ),
      },
      {
        title: "USERNAME",
        dataIndex: "username",
      },
      {
        title: "DOB",
        dataIndex: "dob",
      },
      {
        title: "LAST LOGIN",
        dataIndex: "last_login",
        sorter: (a, b) => a.last_login - b.last_login,
        sortOrder: sorterInfo.columnKey === "last_login" && sorterInfo.order,
        render: date => date ? <TimeAgo date={Number(moment(date).format("x"))} /> : "",
      },
    ];
    return (
      <div className="mt-4 p-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center justify-content-start">
            <Icon
              type="arrow-left"
              style={{ color: '#0076FF', cursor: 'pointer', fontSize: 25 }}
              onClick={() => this.props.history.push(`/reports`)}
            />
            <h5 className="m-0 ml-4">Number of learners yet to create parent account - {this.state.total}</h5>
          </div>
        </div>
        <div>
          <Table
            rowClassName="bg-white"
            loading={this.state.is_loading}
            columns={columns}
            dataSource={this.state.table_data}
            onChange={this.handleTableChange}
            rowKey={record => record.students_id}
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
            }}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(LearnersNotCreatedParents);
