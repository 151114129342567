import React, { Component } from 'react'
import axios from 'axios';
import moment from 'moment';
import { DatePicker } from "antd";
import {CartesianGrid, BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";
import { GET_LOGIN_ACTIVITY } from '../graphql';
import { getDates } from '../../../utils';

class LoginActivityChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      selected_date: "", //moment().format('YYYY-MM'),
      chart_data: [],
    };
  }

  fetchData = () => {
    let query = GET_LOGIN_ACTIVITY;
    query.variables = {
      date: this.state.selected_date,
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let date = this.state.selected_date.split("-");
        let dates = this.state.selected_date ? getDates(`${this.state.selected_date}-1`, `${this.state.selected_date}-${new Date(date[0], date[1], 0).getDate()}`) : getDates(moment().subtract(30, 'days').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        let chart_data = dates.map((date, index) => {
          let filtered_data = res.data.data.getStudentLoginActivity.studentActivity_result.filter(data => moment(data.date, "YYYY-MM-DD").isSame(date, 'day'));
          return ({
            date: date.split("-")[2],
            count: filtered_data.length ? filtered_data[0].count : 0,
          })
        });
        this.setState({ chart_data })
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }

  handleMonthChange = (date, dateString) => {
    this.setState({
      selected_date: date ? date.format("YYYY-MM") : "",
    }, () => this.fetchData())
  };

  render() {
    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        return (
          <div className="pt-1 pb-1 pl-4 pr-4 bg-white rounded shadow border">
            <p className="label m-0" style={{ color: '#3EC1F0' }}>{`Users : ${payload ? payload[0].value : ""}`}</p>
          </div>
        );
      }
      return null;
    };

    return (
      <div className="p-4 bg-white rounded">
        <div className="d-flex align-items-center justify-content-between p-4">
          <h4 className="m-0">Login activity</h4>
          <DatePicker.MonthPicker
            size="large"
            onChange={this.handleMonthChange}
            disabledDate={(currentDate) => currentDate.isAfter(moment(), 'month')}
            placeholder="Select month"
            allowClear={true}
            value={this.state.selected_date ? moment(this.state.selected_date, 'YYYY-MM') : null}
            popupStyle={{ width: 350 }}
            format="MMMM YYYY"
          />
        </div>
        <div className="mt-4" style={{ width: "100%", height: 500 }}>
          <ResponsiveContainer>
            <BarChart
              width={500}
              height={300}
              data={this.state.chart_data}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                padding={{ left: 50, right: 50 }}
                tick={{ fill: '#9A9A9A' }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                tick={{ fill: '#9A9A9A' }}
              />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Bar
                dataKey="count"
                fill="#3EC1F0"
                barSize={10}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default LoginActivityChart;
