import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Tag
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import LinesEllipsis from 'react-lines-ellipsis'
import { Prompt } from 'react-router-dom'
import BreadcrumbView from '../../components/BreadCrumb/BreadcrumbPlayList'
import InputC from '../../components/InputC'
import Loading from '../../components/Loading'
import { dragDrop_reorder, openNotification, triggerGAEvent } from '../../utils'
import {
  ARCHIVE_PLAYLIST,
  EDIT_PLAYLIST,
  GET_ACTIVITY,
  GET_AGE_DATA,
  GET_ALL_ACTIVITY_COLLECTION,
  GET_ALL_LIBRARY_COLLECTION,
  GET_ASSOCIATED_DATA,
  GET_LIBRARY,
  GET_ONE_PLAYLIST
} from './api'
import './playlist.css'

class EditPlayList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmDialog: null,
      loading: false,
      all_loading: false,
      modalVisible: false,
      shouldBlockNavigation: true,
      is_published: false,
      sort: 'newest',
      searchText: '',
      title: '',
      source_text: '',
      source_link: '',
      skills: [],
      interests: [],
      age_groups: [],
      selectedAgeGroups: [],
      arrayToRenderPlaylist: [],
      library_collections: [],
      activity_collections: [],
      visibleCards: [],
      selectedCardIds: [],
      filterArrToRemove: [],
      currentCollectionType: '',
      currentCollectionID: '',
      truthObj: {
        title: false,
        age_groups: false,
        arrayToRenderPlaylist: false
      }
    }
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700)
  }

  fetchAgeData = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_AGE_DATA)
      .then(res => {
        this.setState({ age_groups: res.data.data.getAge.age_groups })
      })
      .catch(err => console.log(err))
  }

  fetchPlayListData = () => {
    let query = GET_ONE_PLAYLIST
    query.variables = {
      id: this.props.match.params.id
    }
    // console.log("Feature {P")
    console.log('Fetch PLaylist')
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          is_publish: res.data.data.getOnePlaylist.is_publish,
          title: res.data.data.getOnePlaylist.title,
          source_text: res.data.data.getOnePlaylist.source_text,
          source_link: res.data.data.getOnePlaylist.source_link,
          interests: res.data.data.getOnePlaylist.array.intrest,
          skills: res.data.data.getOnePlaylist.array.data_point,
          selectedAgeGroups: res.data.data.getOnePlaylist.age_group.map(
            age => age.id
          ),
          arrayToRenderPlaylist: res.data.data.getOnePlaylist.array.lib_act.sort(
            (a, b) => a.order - b.order
          ),
          selectedCardIds: res.data.data.getOnePlaylist.array.lib_act.map(
            card => `${card.type}-${card.id}`
          )
        })
      })
      .catch(err => console.log(err))
  }

  fetchAllCollections = () => {
    this.setState({ loading: true })
    const activityCollectionQuery = GET_ALL_ACTIVITY_COLLECTION
    const libraryCollectionQuery = GET_ALL_LIBRARY_COLLECTION

    activityCollectionQuery.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: { age: this.state.selectedAgeGroups }
    }

    libraryCollectionQuery.variables = {
      filter: { age: this.state.selectedAgeGroups }
    }

    axios
      .all([
        axios.post(`${process.env.REACT_APP_API}`, activityCollectionQuery),
        axios.post(`${process.env.REACT_APP_API}`, libraryCollectionQuery)
      ])
      .then(
        axios.spread((activityRes, libRes) => {
          const inPCollection =
            activityRes.data.data.getAllCollection.inprogress_collection
          const pubCollection =
            activityRes.data.data.getAllCollection.published_collection
          const newActCollection = inPCollection.concat(pubCollection)

          const inPLibCollection = libRes.data.data.getAllLibraryCollection
            ? libRes.data.data.getAllLibraryCollection.inprogress_libcollection
            : []
          const pubLibCollection = libRes.data.data.getAllLibraryCollection
            ? libRes.data.data.getAllLibraryCollection.published_libcollection
            : []
          const newLibCollection = inPLibCollection.concat(pubLibCollection)

          this.setState({
            library_collections: newLibCollection,
            activity_collections: newActCollection,
            loading: false
          })
        })
      )
  }

  fetchActivityCards = id => {
    triggerGAEvent('Playlist', 'Fetching Activity cards')
    this.setState({
      currentCollectionType: 'activity',
      currentCollectionID: id
    })

    let query = GET_ACTIVITY
    query.variables = {
      collections_id: id,
      filter: { age: this.state.selectedAgeGroups }
    }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const activityCards = res.data.data.getAllActivity.activity_details.map(
          i => ({
            ...i,
            is_selected: false,
            type: this.state.currentCollectionType,
            collectionID: parseInt(this.state.currentCollectionID, 10)
          })
        )
        this.setState({ visibleCards: activityCards })
      })
      .catch(err => console.log(err))
  }

  fetchLibraryCards = id => {
    triggerGAEvent('Playlist', 'Fetching Library cards')
    this.setState({
      currentCollectionType: 'library',
      currentCollectionID: id
    })
    let query = GET_LIBRARY
    query.variables = {
      libcollections_id: id,
      filter: { age: this.state.selectedAgeGroups }
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const libraryCards = res.data.data.getAllLibrary.library_details.map(
          i => ({
            ...i,
            is_selected: false,
            type: this.state.currentCollectionType,
            collectionID: parseInt(this.state.currentCollectionID, 10)
          })
        )
        this.setState({ visibleCards: libraryCards })
      })
      .catch(err => console.log(err))
  }

  fetchAssociatedData = () => {
    let associatedDataQuery = GET_ASSOCIATED_DATA
    associatedDataQuery.variables = {
      array: this.state.arrayToRenderPlaylist
    }
    axios
      .post(`${process.env.REACT_APP_API}`, associatedDataQuery)
      .then(res => {
        this.setState(
          {
            arrayToRenderPlaylist: res.data.data.getListLibIntrest.lib_act.sort(
              (a, b) => a.order - b.order
            ),
            interests: res.data.data.getListLibIntrest.intrest,
            skills: res.data.data.getListLibIntrest.data_point
          },
          () => this.loadCheckedItemsFromState()
        )
      })
      .catch(err => console.log(err))
  }

  componentDidMount () {
    this.fetchAgeData()
    this.fetchPlayListData()
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount () {
    this.state.confirmDialog && this.state.confirmDialog.destroy()
  }

  onInputChange = (key, value) => {
    triggerGAEvent('Playlist', 'Entering data for the Playlist')
    this.setState({
      [key]: value
    })
  }

  onSearchFetchData = () => {
    triggerGAEvent('Playlist', 'Searching Activity and Library Collections')
    this.fetchAllCollections()
  }

  onSearchChange = e => {
    this.setState({ searchText: e.target.value, loading: true }, () =>
      this.onSearchFetchData()
    )
  }

  onAgeGroupChange = checkedValues => {
    triggerGAEvent('Playlist', 'Entering data for the Playlist')
    if (this.state.selectedAgeGroups.length < checkedValues.length) {
      this.setState({ selectedAgeGroups: checkedValues })
    } else {
      const confirmDialog = Modal.confirm({
        title: 'Do you want to change the age group selection?',
        content:
          'Items you have selected are already fall under this group, this action will remove those items from learning path. Are you sure do you want to continue?',
        okText: 'Yes',
        onOk: () => {
          const uncheckedItem = _.difference(
            this.state.selectedAgeGroups,
            checkedValues
          )
          let filteredArr = _.filter(this.state.arrayToRenderPlaylist, {
            age_group: [{ id: uncheckedItem[0] }]
          })
          if (filteredArr.length > 0) {
            this.setState({ filteredArrToRemove: filteredArr }, () =>
              this.removeFilteredArray()
            )
          }
          this.setState({ selectedAgeGroups: checkedValues })
        }
      })
      this.setState({ confirmDialog })
    }
  }

  loadCheckedItemsFromState = () => {
    if (this.state.arrayToRenderPlaylist.length) {
      this.setState({
        checkedValues: this.state.arrayToRenderPlaylist.map(
          item => `${item.type}-${item.id}`
        )
      })
    }
  }

  onCardsChange = checkedValues => {
    triggerGAEvent('Playlist', 'Selecting cards from collections for Playlist')
    this.setState({
      selectedCardIds: checkedValues
    })
  }

  onDragEnd = result => {
    triggerGAEvent('Playlist', 'Drag and Drop for Playlist')
    if (!result.destination) {
      return
    }
    const items = dragDrop_reorder(
      this.state.arrayToRenderPlaylist,
      result.source.index,
      result.destination.index
    )
    this.setState({ arrayToRenderPlaylist: items })
  }

  showModal = () => {
    triggerGAEvent('Playlist', 'Fetching Activity and Library Collections')
    this.fetchAllCollections()
    this.setState({ modalVisible: true })
  }

  addCardsToPlayList = () => {
    triggerGAEvent('Playlist', 'Adding cards for the Playlist')
    let arrayToRenderPlaylist = this.state.selectedCardIds.map(
      (card, index) => ({
        id: Number(card.split('-')[1]),
        order: this.state.arrayToRenderPlaylist.filter(
          x => x.id === card.split('-')[1]
        ).length
          ? this.state.arrayToRenderPlaylist.filter(
              x => x.id === card.split('-')[1]
            )[0].order
          : index,
        type: card.includes('activity') ? 'activity' : 'library'
      })
    )
    this.setState(
      {
        arrayToRenderPlaylist,
        modalVisible: false,
        searchText: ''
      },
      () => this.fetchAssociatedData()
    )
  }

  handleCancel = () => {
    triggerGAEvent('Playlist', 'Closing Add Activity and Library Modal Popup')
    this.setState({ modalVisible: false })
  }

  onCollapseChange = () => {
    triggerGAEvent('Playlist', 'Changing Collapse in Create Playlist')
  }

  removeFilteredArray = () => {
    let removedArray = this.state.filterArrToRemove.map(card => {
      this.state.arrayToRenderPlaylist.slice(card.order)
      return removedArray
    })
    const arrayToRenderPlaylist = _.difference(
      this.state.arrayToRenderPlaylist,
      this.state.filteredArrToRemove
    ).map((card, index) => ({
      ...card,
      order: index
    }))
    this.setState({ arrayToRenderPlaylist })
  }

  validateStateValues = () => {
    let truthObj = {}
    truthObj['title'] = this.state.title === ''
    truthObj['age_groups'] = this.state.selectedAgeGroups.length === 0
    truthObj['arrayToRenderPlaylist'] =
      this.state.arrayToRenderPlaylist.length === 0
    truthObj['source_text'] = this.state.source_text === ''
    setTimeout(() => {
      this.setState({ truthObj: truthObj })
    }, 2000)
    setTimeout(() => {
      this.checkErrorExistence()
    }, 2000)
  }

  checkErrorExistence = () => {
    if (_.includes(this.state.truthObj, true)) {
      openNotification('error', 'Please fill required values')
    } else {
      this.editPlayList()
    }
  }

  editPlayList = () => {
    triggerGAEvent('Playlist', 'Editing the Playlist')
    this.setState({ all_loading: true })
    let mutation = EDIT_PLAYLIST
    mutation.variables = {
      id: this.props.match.params.id,
      title: this.state.title.trim(),
      source_text: this.state.source_text,
      source_link: this.state.source_link,
      age_group: this.state.selectedAgeGroups.map(a => ({ age_group_id: a })),
      array: this.state.arrayToRenderPlaylist.map((card, index) => ({
        id: card.id,
        order: index, //card.order,
        type: card.type
      }))
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        this.setState({ loading: false, shouldBlockNavigation: false })
        this.props.history.push(
          `/playlists/view/${res.data.data.editPlaylist.id}`
        )
      })
      .catch(err => console.log(err))
  }

  archivePlaylist = () => {
    triggerGAEvent('Playlist', 'Archiving the Playlist')
    this.setState({ loading: true })
    let mutation = ARCHIVE_PLAYLIST
    mutation.variables = {
      p_id: Number(this.props.match.params.id)
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        this.setState({ loading: false, shouldBlockNavigation: false })
        if (res.data.data.archivePlaylist.status === true) {
          this.props.history.push('/playlists?q=3')
          openNotification('success', 'Learning path Archived')
        } else {
          openNotification('error', res.data.data.archivePlaylist.message)
        }
      })
      .catch(err => console.log(err))
  }

  render () {
    const { interests, skills, age_groups } = this.state
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Loading is_loading={this.state.all_loading} />
        <div className='view-playlist'>
          <div style={{ background: 'white' }}>
            <BreadcrumbView
              title={this.state.title ? this.state.title : 'Untitled Playlist'}
              data={`/playlists/${
                this.state.is_publish ? 'published' : 'un-published'
              }/${this.state.title ? this.state.title : 'Untitled Playlist'}`}
            >
              <Button
                size='large'
                className='mr-3'
                onClick={() =>
                  this.props.history.push(
                    `/playlists/view/${this.props.match.params.id}`
                  )
                }
              >
                Cancel
              </Button>
              <Button
                ghost
                size='large'
                className='mr-3'
                type='danger'
                onClick={this.archivePlaylist}
              >
                Archive
              </Button>
              <Button
                ghost
                size='large'
                type='primary'
                onClick={this.validateStateValues}
                className='btn-w250'
              >
                Save Changes
              </Button>
            </BreadcrumbView>
          </div>
          <div style={{ margin: '2rem' }}>
            <Card className='create-playlist-title'>
              <InputC
                title='Title'
                stateKey='title'
                id={this.state.truthObj['title'] ? 'has-error' : ''}
                onChange={this.onInputChange}
                value={this.state.title}
                placeholder='Title of the playlist'
                className='ant-input ant-input-lg font-16'
                titleStyle='mb-3 FontAvenirMedium text-uppercase  text-secondary font-16 requiredAsterisk'
              />
            </Card>
            <Collapse
              defaultActiveKey={['1']}
              className='createPlistCollapse'
              onChange={this.onCollapseChange}
            >
              <Collapse.Panel header='Details of this Playlist' key='1'>
                {interests.length > 0 && (
                  <Row className='mb-4'>
                    <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary'>
                      Interests
                    </div>
                    <div className='interests-view-box'>
                      {interests.map((ai, i) => (
                        <Tag key={i} color='blue'>
                          {ai.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <div className='mb-4'>
                  <InputC
                    title='Source Credit'
                    stateKey='source_text'
                    id={this.state.truthObj['source_text'] ? 'has-error' : ''}
                    onChange={this.onInputChange}
                    value={this.state.source_text}
                    placeholder='Enter display name for credit link'
                    className='ant-input ant-input-lg font-16'
                    titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16'
                  />
                  <InputC
                    title=''
                    stateKey='source_link'
                    id={this.state.truthObj['source_link'] ? 'has-error' : ''}
                    onChange={this.onInputChange}
                    value={this.state.source_link}
                    placeholder='Enter credit link'
                    className='ant-input ant-input-lg font-16'
                  />
                </div>
                {skills.length > 0 && (
                  <Row className='mb-4'>
                    <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary'>
                      Skills
                    </div>
                    <div className='interests-view-box'>
                      {skills.map((ai, i) => (
                        <Tag key={i} color='blue'>
                          {ai.name}
                        </Tag>
                      ))}
                    </div>
                  </Row>
                )}
                <div className='mb-3 FontAvenirRoman font-16 text-uppercase text-secondary requiredAsterisk'>
                  Age group & difficulty
                </div>
                <Checkbox.Group
                  value={this.state.selectedAgeGroups}
                  style={{ width: '100%' }}
                  onChange={this.onAgeGroupChange}
                >
                  <Row>
                    {age_groups.map((group, index) => (
                      <Col span={3} key={index}>
                        <Checkbox name={group.age} value={Number(group.id)}>
                          {group.age}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Collapse.Panel>
            </Collapse>
            <Collapse
              defaultActiveKey={['1']}
              className='createPlistCollapse'
              onChange={this.onCollapseChange}
            >
              <Collapse.Panel
                className={
                  this.state.truthObj['arrayToRenderPlaylist']
                    ? 'has-error-ma'
                    : ''
                }
                header='Linked Activities and Libraries'
                key='1'
              >
                <div className='link-act-lib'>
                  {this.state.selectedAgeGroups.length > 0 && (
                    <Button
                      block
                      onClick={this.showModal}
                      icon='plus'
                      type='primary'
                    >
                      Click to link Activities & Libraries
                    </Button>
                  )}
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {this.state.arrayToRenderPlaylist.map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={`${index}item`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {console.log(item)}
                                <List.Item>
                                  <div className='d-flex align-items-center justify-content-between w-100'>
                                    <div className='d-flex align-items-center justify-content-start'>
                                      <div className='badge'>{index + 1}</div>
                                      {item.thumb_image && (
                                        <span>
                                          {item.thumb_image.length && (
                                            <img
                                              src={
                                                item.thumb_image[0].image_url
                                              }
                                              className='img-fluid activityImage mr-3 ml-3'
                                              alt=''
                                            />
                                          )}
                                        </span>
                                      )}
                                      <div className='list-name'>
                                        {item.name}
                                      </div>
                                    </div>
                                    <Tag color='lime'>{item.status}</Tag>
                                  </div>
                                </List.Item>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
        <Modal
          width='75%'
          visible={this.state.modalVisible}
          title='Select Activity and Library Cards to be linked to this Playlist'
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={[
            <div
              className='d-flex align-items-center justify-content-between'
              key='footer'
            >
              <div className='FontAvenirMedium font-16 text-dark'>
                {`${this.state.selectedCardIds.length} Cards Selected`}
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <Button key='back' size='large' onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button
                  key='submit'
                  size='large'
                  type='primary'
                  loading={this.state.loading}
                  onClick={this.addCardsToPlayList}
                >
                  Add to playlist
                </Button>
              </div>
            </div>
          ]}
        >
          <Row className='rounded'>
            <Col span={8} style={{ border: '1px solid #4A90E2' }}>
              <Input.Search
                style={{ width: '100%', border: '1px solid #4A90E2' }}
                value={this.state.searchText}
                name='searchText'
                size='large'
                placeholder='Search Activity or Library Collection'
                onChange={e => this.onSearchChange(e)}
              />
              <Menu style={{ height: '406px', overflow: 'scroll' }}>
                {this.state.activity_collections.map((act, index) => (
                  <Menu.Item
                    key={index}
                    name={act.id}
                    onClick={e => this.fetchActivityCards(act.id)}
                  >
                    {act.name}
                  </Menu.Item>
                ))}
                {this.state.library_collections.map((lib, index) => (
                  <Menu.Item
                    key={this.state.activity_collections.length + index}
                    name={lib.id}
                    onClick={e => this.fetchLibraryCards(lib.id)}
                  >
                    {lib.name}
                  </Menu.Item>
                ))}
              </Menu>
            </Col>
            <Col
              span={16}
              style={{
                border: '1px solid #4A90E2',
                height: '448px',
                overflow: 'scroll',
                padding: '1rem'
              }}
            >
              {this.state.currentCollectionType && (
                <div className='text-right font-16 text-capitalize'>{`${this.state.currentCollectionType} - ${this.state.visibleCards.length}`}</div>
              )}
              <Checkbox.Group
                defaultValue={this.state.selectedCardIds}
                className='w-100'
                onChange={this.onCardsChange}
              >
                {this.state.visibleCards.map((card, index) => (
                  <div
                    className='p-4 mt-4 rounded mb-4 border d-flex justify-content-between align-items-center'
                    key={index}
                  >
                    <div className='FontAvenirMedium font-16 text-dark w-100'>
                      <LinesEllipsis
                        text={card.name}
                        maxLine='1'
                        ellipsis='...'
                        trimRight
                        basedOn='words'
                      />
                    </div>
                    <div>
                      <Checkbox
                        name={card.id}
                        alt={card.name}
                        value={`${this.state.currentCollectionType}-${card.id}`}
                      />
                    </div>
                  </div>
                ))}
              </Checkbox.Group>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
}

export default EditPlayList
