import {
  Button, Checkbox, Input, Menu, Modal, Radio, Tooltip
} from 'antd'
import { Select } from 'antd/lib/index'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import YearPicker from 'rc-year-picker'
import React from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-validation/build/form'
import styled from 'styled-components'
import '../../App.css'
import arrowLeft from '../../assets/arrow_left.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import InviteLogo from '../../assets/Invite_sent.svg'
import BGLogo from '../../assets/login_bg.png'
import BGLogoMax from '../../assets/login_bg_max.png'
import mblbglogo from '../../assets/mblBGlogosignup.svg'
import InputC from '../../components/InputC'
import { country_codes } from '../../static/countryCodes.json'
import { openNotification, triggerGAEvent } from '../../utils'
import { isRequired } from '../../validation'
import { SEND_PARENT_INVITE } from '../Students/api'
import './Signin.css'
const { confirm } = Modal
const SubMenu = Menu.SubMenu

const MaxCard = styled.div`
  .mbl-signup-input::placeholder {
    font-family: 'Bariol' !important;
  }
  input.year-input::placeholder {
    font-family: 'Bariol';
  }
  @media (max-width: 576px) {
    display: none !important;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .card-w-auto {
      width: 520px !important;
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    .mbl-card-radius {
      height: 670px !important;
      overflow: scroll !important;
    }
  }
  @media (min-width: 1440px) {
    .card-w-auto {
      width: 565px !important;
      height: auto !important;
    }
    .lg-mr-6 {
      margin-right: 4.5rem !important;
    }
  }
  @media (min-width: 1500px) {
    background: url(${BGLogoMax});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .card-w-auto {
      width: 565px !important;
    }
    .lg-mr-6 {
      margin-right: 8.5rem !important;
    }
  }
  .cusColor .ant-radio-checked .ant-radio-inner {
    border-color: #4c39b3 !important;
  }
  .cusColor > .ant-radio-checked .ant-radio-inner::after {
    background-color: #4c39b3 !important;
  }
  background: url(${BGLogo});
  @media (max-width: 900px) {
    background: url(${mblbglogo});
  }
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  background-position: center; */
  background-size: cover ;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  /* margin-bottom: 0; */
  border-radius: 4px;
  margin: 0 auto;
`

const MblCard = styled.div`
  .mbl-signup-input::placeholder {
    font-family: Bariol !important;
  }
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  @media (max-width: 576px) {
    .mbl-cardStart {
      display: none;
    }
  }
  @media (max-width: 900px) {
    background: url(${mblbglogo}) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    .cusColor .ant-radio-checked .ant-radio-inner {
      border-color: #4c39b3 !important;
    }
    .cusColor > .ant-radio-checked .ant-radio-inner::after {
      background-color: #4c39b3 !important;
    }
    .btn-social {
      display: inline-flex !important;
    }
    .mblSignIn {
      width: 90% !important;
      max-height: 95% !important;
    }
    justify-content: center !important;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  border-radius: 4px;
  margin: 0 auto;
`

const StickTxt = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  justify-content: space-around;
  align-items: center;
  .fTxt {
    font-family: 'Bariol';
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }
  .btn-Txt > a {
    font-family: 'Bariol';
    font-size: 16px;
    color: #0076ff;
  }
  .bg-btn {
    padding: 5px 30px !important;
  }
`
const StickTxt1 = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 5px;
  .fTxt {
    font-family: 'Bariol';
    font-size: 14px;
    font-weight: 400;
    color: #00;
  }
  .btn-Txt > a {
    font-family: 'Bariol';
    font-size: 14px;
    color: #0076ff;
  }
  .bg-btn {
    padding: 2px 10px !important;
  }
`

class SignUpAccountSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      whatsappVal: false,
      btnDisabled: false,
      emailRedirect: 'gmail.com',
      login: {
        childCheck: 'no',
        email: '',
        first_name: '',
        last_name: '',
        address: '',
        phone_no: '',
        country: 'India',
        country_code: '+91',
        dob: ''
      },
      truthObj: {
        email: false,
        first_name: false,
        last_name: false,
        country: false,
        address: false,
        phone_no: false,
        dob: false
      },
      form: React.createRef(),
      submitted: false
    }
  }

  showConfirm = () => {
    let confirmPopup = confirm({
      icon: null,
      content: (
        <React.Fragment>
          <div className='d-flex align-items-center flex-column'>
            <div>
              <img src={InviteLogo} className='' alt='logo' />
            </div>
            <div className='mt-4 mb-2'>
              <h3
                style={{
                  color: '#333',
                  fontSize: 24,
                  fontWeight: 800
                }}
              >
                Invite sent
              </h3>
            </div>
            <div
              className='mblInvite-pd'
              style={{
                textAlign: 'center',
                padding: '0 30px',
                color: '#595959',
                fontSize: 16,
                fontWeight: 400
              }}
            >
              An invitation to join the Breadcrumb community has been sent to
              your email ID. Please check.
            </div>
            <div className='mt-3'>
              <Button
                style={{
                  height: 40,
                  color: '#fff',
                  background: '#4C39B3',
                  borderRadius: 8,
                  border: 'none',
                  width: 100
                }}
                type='primary'
                block
                size='large'
                onClick={() => {
                  confirmPopup.destroy()
                  window.open(
                    this.state.emailRedirect == 'gmail.com'
                      ? `https://mail.google.com`
                      : `https://${this.state.emailRedirect}`,
                    '_blank'
                  )
                  window.location.reload()
                }}
              >
                Okay
              </Button>
            </div>
          </div>
        </React.Fragment>
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      centered: true
    })
  }

  clear = () => {
    document.getElementsByClassName('year-input')[0].defaultValue = ''
    document.getElementsByClassName('year-input')[0].value = ''
    document.getElementsByClassName(
      'ant-select-selection-selected-value'
    )[0].innerHTML = 'Select Country'
  }

  parentInviteEmail = () => {
    let mutation = SEND_PARENT_INVITE
    mutation.variables = {
      invite: [
        {
          first_name: this.state.login.first_name,
          last_name: this.state.login.last_name,
          email: this.state.login.email,
          address: this.state.login.address,
          phone_no: `${this.state.login.country_code} ${this.state.login.phone_no}`,
          country: this.state.login.country,
          year_of_birth: this.state.login.dob,
          is_whatsapp: this.state.whatsappVal,
          child_setup: this.state.login.childCheck === 'yes'
        }
      ]
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          triggerGAEvent('Signup', 'Signup as Parent on Email')
          openNotification('error', res.data.errors[0].message)
          this.setState({ isFormSubmitting: false, btnDisabled: false })
        } else {
          this.setState(
            {
              whatsappVal: false,
              btnDisabled: false,
              emailRedirect: this.state.login.email
                ? this.state.login.email.split('@')[1]
                : 'gmail.com',
              login: {
                email: '',
                first_name: '',
                last_name: '',
                dob: '',
                address: '',
                phone_no: '',
                country: '',
                country_code: '',
                childCheck: 'no'
                // status: ""
              },
              truthObj: {
                email: false,
                name: false,
                last_name: false,
                address: false,
                phone_no: false
              }
            },
            () => {
              this.clear()
              // openNotification("success", "Invite has been sent to your email ID. Please check")
              this.showConfirm()
            }
          )
        }
      })
      .catch(err => console.log(err))
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.login)
    getKeys.forEach((key, index) => {
      let getItem = this.state.login[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    let re = /\S+@\S+\.\S+/
    truthObj['email'] = !re.test(this.state.login.email)
    truthObj['phone_no'] = this.state.login.phone_no
      ? this.state.login.phone_no.length < 10
      : true
    let dobPic = document.getElementsByClassName('year-picker')
    if (!this.state.login.dob) {
      dobPic[0].id = 'has-error'
    } else {
      dobPic[0].id = ''
      truthObj['dob'] = false
    }
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    this.setState({ isSubmit: true })
    this.state.form.current.validateAll()
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please fill all mandatory fields correctly')
      this.setState({ isFormSubmitting: false, btnDisabled: false })
    } else {
      this.parentInviteEmail()
    }
  }

  onChange = (key, value) => {
    triggerGAEvent('Signup', 'Enter data for Login')
    const state = { ...this.state }
    state.login[key] = value
    this.setState({ login: state.login })
  }

  showPwdClick = key => {
    triggerGAEvent('Signup', 'Show password in Login')
    const state = { ...this.state }
    state.showHide[key] = !state.showHide[key]
    this.setState({ showHide: state.showHide })
  }

  updateParentData = (key, value) => {
    let login = { ...this.state.login }
    if (key == 'dob') {
      if (!moment(value).isBefore(moment().subtract(18, 'years'))) {
        openNotification(
          'error',
          'You must be atleast 18 years of age, to Sign Up as an adult. '
        )
        login[key] = ''
        this.setState({
          login
        })
        return
      }
    }
    if (key == 'address') {
      if (/^[0-9]+$/.test(value)) {
        return
      }
    }
    login[key] = value
    this.setState({
      login
    })
  }

  render() {
    return (
      <React.Fragment>
        <MaxCard className='card-wrap d-flex justify-content-lg-end '>
          <div className='mt-5 mb-5 lg-mr-6'>
            <div
              className='mbl-signup-card mbl-card-radius mx-auto pb-5 card-w-auto'
              style={{ width: 565, padding: '0 35px' }}
            >
              <div className='pt-4 d-flex align-items-center mb-2'>
                <div className='p-0'>
                  <img
                    src={arrowLeft}
                    className='cursor-pointer'
                    alt='left arrow'
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className='p-2 w-100'>
                  <h3
                    className='max-signup-title FontBariolHeavy text-center'
                    style={{ fontSize: 28, marginBottom: 0 }}
                  >
                    Create your Breadcrumb account!
                  </h3>
                </div>
              </div>

              <StickTxt className='mb-4'>
                <div className='fTxt'>Already a member on Breadcrumb?</div>
                <div className='btn-Txt bg-btn'>
                  <Link to='/'>
                    <span
                      className='font-16'
                      style={{
                        color: '#4C39B3',
                        fontFamily: 'Bariol',
                        textDecoration: 'none',
                        fontWeight: 800
                      }}
                    >
                      Sign in
                    </span>
                  </Link>
                </div>
              </StickTxt>
              <div className='mb-0'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      First Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Last Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Email
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Email ID'
                        id={this.state.truthObj['email'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.email}
                        stateKey='email'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Country
                    </div>
                    <div className='col-8'>
                      <Select
                        id='country'
                        placeholder='Select country'
                        defaultValue='+91~~India'
                        className='w-100'
                        size='large'
                        value={`${this.state.login.country_code}~~${this.state.login.country}`}
                        onChange={value => {
                          let login = { ...this.state.login }
                          login.country_code = value.split('~~')[0]
                          login.country = value.split('~~')[1]
                          this.setState({
                            login
                          })
                        }}
                      >
                        {country_codes.map((code, index) => (
                          <Select.Option
                            key={index}
                            value={`${code.dial_code}~~${code.name}`}
                          >{`${code.name} (${code.dial_code})`}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      City
                    </div>
                    <div className='col-8'>
                      <Input
                        type='text'
                        placeholder='Enter City'
                        id={this.state.truthObj['address'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.address}
                        stateKey='address'
                        validations={[]}
                        onChange={e =>
                          this.updateParentData('address', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Age&ensp;
                      <Tooltip
                        placement='right'
                        title={`Based on your age category, we will display the workshops that you can learn from your web interface`}
                      >
                        <span
                          className='FontBariolHeavy font-14 cursor-pointer'
                          style={{
                            color: '#4C39B3'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-8'>
                      <YearPicker
                        value={this.state.login.dob}
                        placeholder='Select Year Of Birth'
                        onChange={date =>
                          this.updateParentData('dob', date.toString())
                        }
                      ></YearPicker>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-1'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Phone Number
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='number'
                        size='large'
                        placeholder='Enter Phone Number'
                        id={this.state.truthObj['phone_no'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol font-16 w-100'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.phone_no}
                        stateKey='phone_no'
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div className='col-4'></div>
                    <div className='col-1 pb-3'>
                      <Checkbox
                        onChange={e =>
                          this.setState({
                            whatsappVal: e.target.checked
                          })
                        }
                        value={this.state.whatsappVal}
                      ></Checkbox>
                    </div>
                    <div className='col-7 p-0 pr-2'>
                      <span
                        className='FontBariol'
                        style={{
                          color: '#4F4F4F',
                          fontSize: 12
                        }}
                      >
                        Tap if this is your Whatsapp number to receive reminders
                        and updates from us
                      </span>
                    </div>
                  </div>
                  <div
                    className='row align-items-center justify-content-between m-0 mb-3'
                    style={{
                      background: '#FAFAFA'
                    }}
                  >
                    <div
                      className='col-8 FontBariol'
                      style={{
                        color: '#333333',
                        fontSize: 16,
                        padding: 12
                      }}
                    >
                      Would you like to setup a child profile?
                    </div>
                    <div className='col-4'>
                      <Radio.Group
                        onChange={e =>
                          this.updateParentData('childCheck', e.target.value)
                        }
                        value={this.state.login.childCheck}
                      >
                        <Radio className='cusColor' value={`yes`}>
                          Yes
                        </Radio>
                        <Radio className='cusColor' value={`no`}>
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Button
                      className='btn-fontf mbl-btn-bottom'
                      type='primary'
                      block
                      size='large'
                      disabled={this.state.btnDisabled}
                      onClick={() => {
                        this.setState({
                          btnDisabled: true
                        })
                        this.validateStateValues()
                      }}
                      style={{
                        background: '#4C39B3',
                        border: 0,
                        width: 232,
                        height: 50,
                        fontWeight: 700
                      }}
                    >
                      Create Account
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </MaxCard>

        {/* Mbl signup page */}
        <MblCard className='d-flex'>
          <div className='mblSignIn mt-3 mb-3'>
            <div
              className='mbl-card-radius card-w-auto'
              style={{
                padding: 16
              }}
            >
              <div className='d-flex pt-3 mb-2'>
                <div className='mr-2 p-2'>
                  <img
                    src={arrowLeft}
                    className='cursor-pointer'
                    alt='left arrow'
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className='ml-2'>
                  <img
                    src={mbllogo}
                    style={{
                      height: 30
                    }}
                    alt='logo'
                  />
                </div>
              </div>
              <div
                className='pt-2 mb-4 FontBariolHeavy text-center'
                style={{
                  color: '#000',
                  fontSize: 20
                }}
              >
                Create your Breadcrumb account!
              </div>
              <StickTxt1 className='mb-4'>
                <div className='fTxt'>Already a member on Breadcrumb?</div>
                <div className='btn-Txt bg-btn'>
                  <Link to='/'>
                    <span
                      className='font-16 FontBariol'
                      style={{
                        color: '#4C39B3',
                        textDecoration: 'none',
                        fontWeight: 800
                      }}
                    >
                      Sign in
                    </span>
                  </Link>
                </div>
              </StickTxt1>
              <div className='mb-2'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='First Name'
                        titleStyle='FontBariolMedium font-16 mb-2'
                        placeholder='Enter Your First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='Last Name'
                        titleStyle='FontBariolMedium font-16 mb-2'
                        placeholder='Enter Your Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='Email'
                        placeholder='Enter Email ID'
                        id={this.state.truthObj['email'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium font-16 mb-2'
                        value={this.state.login.email}
                        stateKey='email'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Country
                    </div>
                    <div className='col-12'>
                      <Select
                        id='country'
                        placeholder='Select country'
                        defaultValue='+91~~India'
                        className='w-100'
                        size='large'
                        value={`${this.state.login.country_code}~~${this.state.login.country}`}
                        onChange={value => {
                          let login = { ...this.state.login }
                          login.country_code = value.split('~~')[0]
                          login.country = value.split('~~')[1]
                          this.setState({
                            login
                          })
                        }}
                      >
                        {country_codes.map((code, index) => (
                          <Select.Option
                            key={index}
                            value={`${code.dial_code}~~${code.name}`}
                          >{`${code.name} (${code.dial_code})`}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='City'
                        placeholder='Enter City'
                        id={this.state.truthObj['address'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium font-16 mb-2'
                        value={this.state.login.address}
                        stateKey='address'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Age&ensp;
                      <Tooltip
                        placement='right'
                        title={`Based on your age category, we will display the workshops that you can learn from your web interface`}
                      >
                        <span
                          className='FontBariolHeavy font-14'
                          style={{
                            color: '#4C39B3'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-12'>
                      <YearPicker
                        value={this.state.login.dob}
                        placeholder='Select Year Of Birth'
                        onChange={date =>
                          this.updateParentData('dob', date.toString())
                        }
                      ></YearPicker>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div className='col-12'>
                      <InputC
                        type='number'
                        size='large'
                        title='Phone Number'
                        placeholder='Enter Phone Number'
                        id={this.state.truthObj['phone_no'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol font-16 w-100'
                        titleStyle='FontBariolMedium font-16 mb-2'
                        value={this.state.login.phone_no}
                        stateKey='phone_no'
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-1'>
                      <Checkbox
                        onChange={e =>
                          this.setState({
                            whatsappVal: e.target.checked
                          })
                        }
                        value={this.state.whatsappVal}
                      ></Checkbox>
                    </div>
                    <div className='col-10 col-md-10 col-sm-10'>
                      <span
                        className="FontBariol"
                        style={{
                          color: '#4F4F4F',
                          fontSize: 12
                        }}
                      >
                        Tap if this is your Whatsapp number to receive reminders
                        and updates from us
                      </span>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div
                      className='col-12 FontBariol'
                      style={{
                        color: '#333333',
                        fontSize: 16,
                        padding: 12
                      }}
                    >
                      Would you like to setup a child profile?
                    </div>
                    <div className='col-12'>
                      <Radio.Group
                        onChange={e =>
                          this.updateParentData('childCheck', e.target.value)
                        }
                        value={this.state.login.childCheck}
                      >
                        <Radio className='cusColor' value={`yes`}>
                          Yes
                        </Radio>
                        <Radio className='cusColor' value={`no`}>
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <Button
                    className='btn-fontf FontBariolHeavy'
                    type='primary'
                    block
                    size='large'
                    disabled={this.state.btnDisabled}
                    onClick={() => {
                      this.setState({
                        btnDisabled: true
                      })
                      this.validateStateValues()
                    }}
                    style={{
                      background: '#4C39B3',
                      border: 0,
                      height: 50,
                      fontWeight: 700
                    }}
                  >
                    Create account
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </MblCard>
      </React.Fragment>
    )
  }
}

export default SignUpAccountSetup
