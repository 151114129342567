import React from "react";
import { Upload } from 'antd';
import { getS3UploadUrl, uploadFiles } from './../utils';
import uparrow from "../assets/uparrow.png";
import pdf from "../assets/pdf.png";

class WorkshopPdfUpload extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      uploadedImageList: [],
      fileList: [],
      isPreview: false,
    };
  }

  componentDidMount = async () => {
    const signedUploadUrl = await getS3UploadUrl('logo.png', "application/pdf");

    this.setState({ signedUploadUrl: signedUploadUrl,
      isPreview: this.props.previewImage != "",
      previewImage: this.props.previewImage
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handlePreview = async (file) => {

    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
      this.setState({
        isPreview: true,
        previewImage: file.url || file.preview
      });
  };

  handleChange = async ({ fileList, file }) => {
    this.props.handleChange(fileList);
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.setState({
      isPreview: file.type.split('/')[0] == 'image',
      previewImage: file.url || file.preview,
      fileList
    });
  };

  uploadToS3 = async ({action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials,}) => {
    const signedUploadUrl = await getS3UploadUrl(`${file.name}`, `${file.type}`);
    const filesArray = [file];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    let uploadedImageList = this.state.uploadedImageList;
    uploadedImageList.push(uploadedResponse);
    this.setState({ uploadedImageList });
    this.props.onSuccess(uploadedImageList);
    onSuccess(uploadedResponse, file);
  };

  render() {
    return (
      <div className="clearfix pdf-upload">
        <Upload.Dragger
          accept=".jpg,.png,.jpeg,.pdf"
          fileList={this.props.fileList}
          onPreview={this.handlePreview}
          multiple={this.props.multiple}
          disabled={this.props.fileList.length === 1}
          onChange={this.handleChange}
          customRequest={this.uploadToS3}>
          {this.props.fileList.length === 1 ? (
            <div>
              {
                !this.props.isPdf && this.props.previewImage != "" ? 
                <img src={this.state.previewImage ? this.state.previewImage : this.props.previewImage } style={{ width: 450, height: 250, marginBottom: '1.5rem' }} alt="preview image" />
                : <img src={pdf} alt="uparrow" style={{ width: 66, marginBottom: '1.5rem' }}/>
              }
              <p className="ant-upload-text">
                To update - Drag and drop files here or browse to upload
              </p>
            </div>
          ) : (
            <div>
              <img src={uparrow} alt="pdf upload complete" className="uparrow" />
              <p className="ant-upload-text">
              Drag and drop files here or browse to upload
              </p>
            </div>
          )}
        </Upload.Dragger>
      </div>
    );
  }

}

export default WorkshopPdfUpload;
