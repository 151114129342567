import React, { Component } from "react";
import styled from "styled-components";
import "./index.css";
const TitleContainer = styled.span`
  font-family: Avenir;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 22px;
  margin-right: 28px !important;

  /* identical to box height, or 137% */

  color: #000000;
`;
const SubHeadingContainer = styled.span`
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-right: 25px !important;
  line-height: 22px;
  /* identical to box height, or 137% */

  color: #4a4a4a;
  &.active {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */

    color: #0076ff;
  }
`;
export default class Sort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      is_active: true,
      ShowInterest: false
    };
  }
  onSelect() {}

  onOpen() {}

  onClick = value => {
    this.props.sortChange = value;
  };

  onClose() {}

  render() {
    // console.log(this.props.Edit)
    return (
      <div className="row m-0 d-flex justify-content-center align-items-center">
        <div className="col-4">
          {this.props.Edit}
          {this.props.Edit === undefined
            ? true
            : this.props.Edit && this.props.children}
        </div>

        <div className="col-8 FontAvenirMedium sortBar font-16 text-right mt-1 pb-2">
          <TitleContainer className="ml-2 mr-2 FontAvenirBlack">
            Sort By
          </TitleContainer>

          {this.props.feedback && (
            <SubHeadingContainer
              onClick={e => this.props.sortChange("newest")}
              className={`${
                this.props.data === "newest" ? "ml-2 mr-2 active" : "ml-2 mr-2"
              } pointer-cursor`}
            >
              Latest
            </SubHeadingContainer>
          )}
          {this.props.feedback && (
            <SubHeadingContainer
              onClick={e => this.props.sortChange("completed")}
              className={`${
                this.props.data === "completed"
                  ? "ml-2 mr-2 active"
                  : "ml-2 mr-2"
              } pointer-cursor`}
            >
              Learners who have completed
            </SubHeadingContainer>
          )}
          <SubHeadingContainer
            onClick={e => this.props.sortChange("A-Z")}
            className={`${
              this.props.data === "A-Z" ? "ml-2 mr-2 active" : "ml-2 mr-2"
            } pointer-cursor`}
          >
            A-Z
          </SubHeadingContainer>
          <SubHeadingContainer
            onClick={e => this.props.sortChange("Z-A")}
            className={`${
              this.props.data === "Z-A" ? "ml-2 mr-2 active" : "ml-2 mr-2"
            } pointer-cursor`}
          >
            Z-A
          </SubHeadingContainer>
          {!this.props.feedback && (
            <SubHeadingContainer
              onClick={e => this.props.sortChange("newest")}
              className={`${
                this.props.data === "newest" ? "ml-2 mr-2 active" : "ml-2 mr-2"
              } pointer-cursor`}
            >
              Newest First
            </SubHeadingContainer>
          )}
          {!this.props.feedback && (
            <SubHeadingContainer
              onClick={e => this.props.sortChange("oldest")}
              className={`${
                this.props.data === "oldest" ? "ml-2 mr-2 active" : "ml-2 mr-2"
              } pointer-cursor`}
            >
              Oldest First
            </SubHeadingContainer>
          )}
          {!this.props.feedback && (
            <SubHeadingContainer
              onClick={e => this.props.sortChange("edited")}
              className={`${
                this.props.data === "edited" ? "ml-2 mr-2 active" : "ml-2 mr-2"
              } pointer-cursor`}
            >
              Last Edited
            </SubHeadingContainer>
          )}
        </div>
      </div>
    );
  }
}
