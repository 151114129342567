import React from "react";
import axios from "axios";
import ResetPswd from "../ResetPswd";
import { USER_UPDATE_PASSWORD } from "../../graphql.js";
import {triggerGAEvent} from "../../utils";

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signin: {
        email: "",
        password: "",
        confirm: ""
      },
    };
  }

  next = () => {
    triggerGAEvent('Reset Password', 'Resetting Password');
    let mutation = USER_UPDATE_PASSWORD;
    mutation.variables = {
      forgotPasswordToken: this.props.match.params.token,
      password: this.state.signin.password
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data) {
          this.props.history.push("/");
        }
      }).catch(error => console.log(error));
  };

  onChangeSignIn = (key, value) => {
    triggerGAEvent('Reset Password', 'Entering Password for Reset Password');
    const state = { ...this.state };
    state.signin[key] = value;
    this.setState({ signin: state.signin });
  };

  render() {
    return (
      <div>
        <div className="steps-content">
          <ResetPswd next={this.next} is_set={false} onChange={this.onChangeSignIn} currState={this.state.signin}/>
        </div>
      </div>
    );
  }
}
