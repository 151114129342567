import React, { Component } from 'react'
import Tick from '../../static/workshop/tick.png'
import styled from 'styled-components'
import './paymentSuccess.css'
import axios from 'axios'
import { GET_PARENT_DASHBOARD } from '../../graphql'

const PaymentMain = styled.div`
  margin-left: 3rem;
  margin-right: 3rem;
  @media  only screen and (max-width: 576px) {
    margin: 0;
    .mbl-div-start {
      margin: 0 1rem 1rem 1rem!important;
    }
    .mbl-marginTop {
      margin-top: 1rem!important;
      padding: 12px!important;
    }
    .mbl-img-padding {
      margin-top: 1rem!important;
      padding-bottom: 1rem!important;
    }
    .payment-title {
      font-weight: 800;
      font-size: 22px;
    }
  }
`

export default class paymentSuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user_details: [],
      role_id: ''
    }
  }

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem('role_id'))
    }, () => {
      if (this.state.role_id !== 3) {
        this.props.history.push("/")
      }
    })
  }

  fetchData () {
    let query = GET_PARENT_DASHBOARD
    // query.variables = {
    //   name: this.props.match.params.name
    // }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          user_details: res.data.data.ParentDashbordV2.user_details
        })
      })
      .catch(err => console.log(err))
  }

  componentDidMount () {
    this.fetchData()
    this.getLocalStorage()
  }

  render () {
    return (
      <PaymentMain>
        <div className='mbl-div-start m-5 d-flex align-items-center justify-content-center'>
          <div className='mbl-marginTop mt-5 successMain border-style'>
            <div className='mbl-img-padding mx-auto mt-5 pb-5 text-center'>
              <img src={Tick} alt='success' className='img-fluid' />
            </div>
            <h5 className='payment-title'>Payment was successful</h5>
            <p className='payment-para'>
              The payment invoice and the workshop details have been sent to {' '}
              <b>
                {this.state.user_details.phone_no
                  ? `your whatsapp number (${this.state.user_details.phone_no}) and `
                  : ''}
              </b>
              email (
              <b>
                {this.state.user_details ? this.state.user_details.email : ''}
              </b>
              ).
            </p>
            <a
              style={{
                cursor: 'pointer',
                color: '#0076FF',
                textDecorationLine: 'underline'
              }}
              onClick={() => this.props.history.push('/parent/workshops')}
            >
              Explore more Workshops
            </a>
          </div>
        </div>
      </PaymentMain>
    )
  }
}
