import {
    Button,
    Col,
    Icon,
    Modal,
    Row,
    Table,
    Tag,
    Breadcrumb,
    Card
  } from 'antd'
  import axios from 'axios'
  import moment from 'moment'
  import React from 'react'
  import LinesEllipsis from 'react-lines-ellipsis'
  import { Link } from 'react-router-dom'
  import { GET_WEEKLY_REPORTS } from '../Parent/ParentDashboardComponents/graphql'
  import {openNotification} from "../../utils";
  import './StudentFeedback.css'

  class StudentLearnerCards extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        is_loading: false,
        student_details: { fname: '', lname: '' },
        allFeedBacks: []
      }
    }
  
	fetchData = () => {
		this.setState({ is_loading: true });
		let query = GET_WEEKLY_REPORTS;
		query.variables = {
			students_id: this.props.students_id,
			start_date: this.state.start_date,
			end_date: this.state.end_date,
		};
		axios
			.post(`${process.env.REACT_APP_API}`, query)
			.then(res => {
				if (res.data.errors) {
					openNotification("error", res.data.errors[0].message);
				} else {
					const all_cards = res.data.data.getStudentWeeklyProgress.cards.map((data) => {
						let difficulty = { Easy: [], Medium: [], Hard: [] };
						(data.age_groups || []).map((ageGrp) => {
							if (ageGrp) {
								if (ageGrp.age_group)
									difficulty[ageGrp.age_group_dificulty.dificulty].push(
										ageGrp.age_group.age
									);
							}
						});
						data.age_diff = difficulty;
						return data;
					});
					const expert_cards = res.data.data.getStudentWeeklyProgress.expert_cards.map((data) => {
						let difficulty = { Easy: [], Medium: [], Hard: [] };
						(data.age_groups || []).map((ageGrp) => {
							if (ageGrp) {
								if (ageGrp.age_group)
									difficulty[ageGrp.age_group_dificulty.dificulty].push(
										ageGrp.age_group.age
									);
							}
						});
						data.age_diff = difficulty;
						return data;
					});
					this.setState({
						total_hours_spent: res.data.data.getStudentWeeklyProgress.total_hours_spent,
						cards: all_cards,
						PlayList: res.data.data.getStudentWeeklyProgress.PlayList,
						expert_cards: expert_cards,
						is_loading: false,
					});
				}
			}).catch(err => console.log(err));
	};
  
    componentDidMount () {
        console.log("props ", this.props.location)
      if (this.props.history.location.pathname.includes('learnerCards')) {
        this.fetchData()
      }
    }
  
    render () {
      return (
        <React.Fragment>
          <div style={{ margin: '1.7rem' }} className='mbl-main-div'>
            <div className='mbl-dev-feedback-title'>
              <div className='d-flex align-items-center justify-content-start mb-2'>
                <div>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      style={{ color: '#828282' }}
                      href={`/parent/dashboard`}
                    >
                      Learner details
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='Mbl-Feedback-breadcumb'>
                      Cards
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className='d-flex justify-content-start'>
                  <div className='mr-2'>
                    <Icon
                      type='arrow-left'
                      style={{
                        color: '#333333',
                        cursor: 'pointer',
                        fontSize: 18
                      }}
                      onClick={() => this.props.history.goBack()}
                    />
                  </div>
                  <div>
                      <h5
                        style={{
                          color: '#333333',
                          fontWeight: 800,
                          fontSize: 18
                        }}
                      >
                        Srikanth learnt these cards
                      </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='max-dev-feedback-title'>
              <Link
                to={{
                  pathname: this.props.history.location.pathname.includes(
                    'learner'
                  )
                    ? `/learners/${this.props.match.params.id}`
                    : `/parent/dashboard`
                }}
              >
                <h5 style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  Back to{' '}
                  {this.props.history.location.pathname.includes('learner')
                    ? `${this.state.student_details.fname} ${this.state.student_details.lname}`
                    : `Dashboard`}
                </h5>
              </Link>
            </div>
            <Row className='max-dev-feedback-title'>
              <Col span={16}>
                <h4 style={{ margin: '0.5rem 0 0 0', padding: 0 }}>
                  Learner Feedback
                </h4>
              </Col>
            </Row>
          </div>
          <div style={{ margin: '1rem' }} className='studentsDataTable'>
            
            
            
            {/* Mbl dev feedback cards */}
            {this.state.allFeedBacks.length !== 0 && (
              <div className='mbl-FeedbacksCards'>
                {this.state.allFeedBacks.map((feedback, index) => {
                  return (
                    <React.Fragment>
                      {/* <Card className='mbl-Feedbackcard mb-2'>
                        <div className='row mb-2'>
                          <div className='col-9 pr-0'>
                            <span style={{ color: '#000', fontSize: 15 }}>
                              {feedback.feedback}
                            </span>
                          </div>
                          <div className='col-3 pr-0'>
                            {this.mblTypeFormatter(feedback)}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-8'>
                            <span style={{ color: '#828282', fontSize: 14 }}>
                              By {feedback.lc_name}
                            </span>
                          </div>
                          <div className='col-4'>
                            {feedback.feedback_voice && (
                              <div
                                className='play-audio-btn pointer-cursor'
                                onClick={() => this.onAudioBtnClick(feedback)}
                              >
                                <img
                                  src={PlayAudio}
                                  alt='play button'
                                  className='mr-2'
                                />
                                <span style={{ color: '#42C0EF' }}>Play</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Card> */}
                    </React.Fragment>
                  )
                })}
              </div>
            )}
          </div>
          
        </React.Fragment>
      )
    }
  }
  
  export default StudentLearnerCards;
  