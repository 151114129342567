import { Card, Icon } from 'antd'
import axios from 'axios'
import * as firebase from 'firebase/app'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import '../../App.css'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import BGLogo from '../../assets/login_bg.png'
import BGLogoMax from '../../assets/login_bg_max.png'
import mblbglogo from '../../assets/mblBGlogo.svg'
import SocialLoginButton from '../../components/ButtonC/SocialLoginButton'
import { LOGIN } from '../../graphql.js'
import AppleIco from '../../static/AppleIconResp.svg'
import Email from '../../static/Email.svg'
import FacebookIcon from '../../static/FacebookIcon.svg'
import FBIco from '../../static/FacebookIconResp.svg'
import GoogleIco from '../../static/GoogleIconResp.svg'
import { openNotification, triggerGAEvent } from '../../utils'
import './Signin.css'

const MblCard = styled.div`
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  @media (max-width: 576px) {
    .mbl-cardStart {
      display: none;
    }
  }
  @media (max-width: 900px) {
    background: url(${mblbglogo}) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    .card-wrap {
      height: unset;
    }
    .btn-social {
      display: inline-flex !important;
    }
    .mblSignIn {
      width: 90% !important;
      max-height: 95% !important;
    }
    justify-content: center !important;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .lg-mr-6 {
      margin-right: 1.5rem !important;
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    .mbl-card-radius {
      height: 670px !important;
    }
  }
  @media (min-width: 1440px) {
    .card-w-auto {
      width: 530px !important;
      height: 700px !important;
    }
    .lg-mr-6 {
      margin-right: 6.5rem !important;
    }
  }
  @media (min-width: 1500px) {
    background: url(${BGLogoMax});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .card-w-auto {
      width: 565px !important;
    }
    .lg-mr-6 {
      margin-right: 8.5rem !important;
    }
  }
  background: url(${BGLogo});
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  /* background-position: center; */
  background-size: cover ;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  /* margin-bottom: 0; */
  border-radius: 4px;
  margin: 0 auto;
`
const StickTxt = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  .fTxt {
    font-family: 'Bariol';
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }
  .btn-Txt > a {
    font-size: 16px;
    color: #0076ff;
  }
`
const StickTxt1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
  align-items: center;
  .fTxt {
    font-family: 'Bariol';
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-align: center;
  }
  .btn-Txt > a {
    font-size: 14px;
    color: #0076ff;
  }
  .bg-btn {
    padding: 5px 20px !important;
  }
`
class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      login: {
        email: '',
        password: ''
      },
      form: React.createRef(),
      showHide: {
        password: false
      }
    }
  }

  loginWithGoogle = () => {
    this.setState({ login_type: 'google' }, () => {
      const user = firebase.auth().currentUser
      // if(user) {
      //   user.linkWithPopup(new firebase.auth.GoogleAuthProvider())
      //   .then((result) => {
      //     console.log("result ", result);
      //     var credential = result.credential;
      //     var user = result.user;
      //   }).catch((error) => {
      //     console.log("error ", error);
      //   })
      // } else {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(res => {
          console.log('resp ', res)
          res.user
            .getIdToken(true)
            .then(resp => {
              this.userLogin(resp)
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
      // }
    })
  }

  loginWithFacebook = () => {
    this.setState({ login_type: 'facebook' }, () => {
      var user = firebase.auth().currentUser
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(res => {
          res.user
            .getIdToken(true)
            .then(resp => {
              this.userLogin(resp)
            })
            .catch(err => console.log(err))
        })
        .catch(function (error) {
          console.log('error ', error)
          console.log('User ', user)
          // An error happened.
          if (error.code === 'auth/account-exists-with-different-credential') {
            var pendingCred = error.credential
            if (user) {
              user
                .linkWithPopup(new firebase.auth.FacebookAuthProvider())
                .then(result => {
                  console.log('result ', result)
                  //     var credential = result.credential;
                  //     var User = result.user;
                  // User.getIdToken(true)
                  // .then(async resp => {
                  //       console.log("resp token ", resp);
                  //       await this.userLogin(resp);
                  // })
                })
                .catch(error => {
                  console.log('linkwithpopup error ', error)
                })
            }
            // firebase.auth().currentUser.linkWithCredential(pendingCred)
            // .then((usercred) => {
            //   console.log("userCred ", usercred);
            //   if (usercred) {
            //    var User = usercred.user;
            //     User.getIdToken(true)
            //             .then(async resp => {
            //               console.log("resp token ", resp);
            //               await this.userLogin(resp);
            //             })
            //   }
            // }).catch((error) => {
            //   console.log("Account linking error", error);
            // });
          }
        })
    })
  }

  loginWithApple = () => {
    this.setState({ login_type: 'apple' }, () => {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.OAuthProvider('apple.com'))
        .then(res => {
          res.user
            .getIdToken(true)
            .then(resp => {
              this.userLogin(resp)
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    })
  }

  loginWithEmailAndPassword = () => {
    this.setState({ login_type: 'email' }, () => {
      this.userLogin()
    })
  }

  userLogin = (id_token = '') => {
    let mutation = LOGIN
    mutation.variables = {
      email: this.state.login.email,
      password: this.state.login.password,
      id_token: id_token,
      login_type: this.state.login_type
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          triggerGAEvent('Login', 'Logging in error')
          openNotification('error', res.data.errors[0].message)
        } else {
          if (res.data.data.login !== null) {
            let loginData = res.data.data.login
            if (loginData.error && loginData.is_email) {
              this.props.history.push({
                pathname: '/signup',
                state: { is_email: loginData.is_email }
              })
            } else if (loginData.error && !loginData.is_email) {
              triggerGAEvent('Login', 'Logging in error')
              openNotification('error', loginData.msg)
            } else {
              localStorage.setItem(
                'token',
                res.data.data.login.user_details.token
              )
              localStorage.setItem(
                'role_id',
                res.data.data.login.user_details.role_id
              )
              localStorage.setItem(
                'isChild',
                res.data.data.login.is_childAdded
                  ? res.data.data.login.is_childAdded
                  : false
              )

              if (res.data.data.login.user_details.role_id === '1') {
                triggerGAEvent('Login', 'Logging in as Breadcrumb Admin')
                this.props.history.push('/dashboard')
              } else if (res.data.data.login.user_details.role_id === '2') {
                triggerGAEvent('Login', 'Logging in as Learning centre')
                this.props.history.push('/dashboard')
              } else if (res.data.data.login.user_details.role_id === '3') {
                triggerGAEvent('Login', 'Logging in as Parent')
                this.props.history.push(
                  `/parent/workshops${res.data.data.login.user_details.isNewLogin
                    ? '?isTour=1'
                    : ''
                  }`
                )
              } else if (res.data.data.login.user_details.role_id === '4') {
                triggerGAEvent('Login', 'Logging in as Expert')
                this.props.history.push('/learningcontent')
              } else if (res.data.data.login.user_details.role_id === '5') {
                triggerGAEvent('Login', 'Logging in as Curator')
                this.props.history.push('/learningcontent')
              }
            }
          } else {
          }
        }
      })
      .catch(err => console.log(err))
  }

  onLoginBtnClick = () => {
    // e.preventDefault();
    this.setState({ isSubmit: true })
    this.state.form.current.validateAll()
    setTimeout(() => {
      if (document.querySelectorAll('.form-error').length === 0) {
        this.loginWithEmailAndPassword()
      }
    }, 1)
  }

  onChange = (key, value) => {
    triggerGAEvent('Login', 'Enter data for Login')
    const state = { ...this.state }
    state.login[key] = value
    this.setState({ login: state.login })
  }

  showPwdClick = key => {
    triggerGAEvent('Login', 'Show password in Login')
    const state = { ...this.state }
    state.showHide[key] = !state.showHide[key]
    this.setState({ showHide: state.showHide })
  }

  onKeyPress = e => {
    let key_code = e.keyCode ? e.keyCode : e.which
    if (key_code === 13) {
      this.onLoginBtnClick()
    }
  }

  onKeyDownClick = e => {
    if (e.key === 'Enter') {
      this.onLoginBtnClick()
    }
  }

  render() {
    return (
      <MblCard className='card-wrap d-flex justify-content-end'>
        <div className='mbl-cardStart lg-mr-6'>
          {/* mr-5 <img src={logo} className="logo" alt="logo" /> */}

          <Card
            className='mbl-card-radius d-flex flex-column align-items-center justify-content-between card-w-auto'
            style={{ width: 510, height: 700 }}
          >
            {/* <div className='d-flex justify-content-center mt-3'>
              <img src={mbllogo} className='mbl-logo' alt='logo' />
            </div> */}
            <div className='d-flex align-items-center justify-content-start mt-3'>
              <div className='p-4'>
                <img src={mbllogo} className='mbl-logo1' alt='logo' />
              </div>
            </div>
            <h3 className='mbl-fontTxt pb-3 mb-3 FontBariolHeavy font-18 text-center'>
              Hello! Welcome back.
            </h3>
            <h5
              style={{
                color: '#828282',
                padding: '0 5px'
              }}
              className='pb-3 mb-3 font-18 text-center FontBariol'
            >
              Log in with information that you entered during your registration
            </h5>
            <div className='max-dev-stickTxt'>
              <StickTxt>
                <div className='fTxt'>
                  Is this your first time on Breadcrumb?
                  <br />
                  You will have to set up an account
                </div>
                <div className='btn-Txt bg-btn'>
                  <Link to='/signup'>
                    <span
                      className='font-16'
                      style={{
                        color: '#4C39B3',
                        fontFamily: 'Bariol',
                        textDecoration: 'none',
                        fontWeight: 800
                      }}
                    >
                      Sign up
                    </span>
                  </Link>
                </div>
              </StickTxt>
            </div>
            <SocialLoginButton
              icon={<Icon type='google' theme='outlined' />}
              buttonColor='#EB4335'
              buttonText='Sign in with Google'
              onClick={this.loginWithGoogle}
              className='btn-social'
            />
            <SocialLoginButton
              icon={
                <img
                  src={FacebookIcon}
                  alt='facebook icon'
                  height='21px'
                  width='21px'
                />
              }
              buttonColor='#3B5998'
              buttonText='Sign in with Facebook'
              onClick={this.loginWithFacebook}
              className='btn-social'
            />
            <SocialLoginButton
              icon={<Icon type='apple' theme='filled' />}
              buttonColor='#000000'
              buttonText='Sign in with Apple'
              onClick={this.loginWithApple}
              iconTheme='filled'
              className='btn-social'
            />
            <SocialLoginButton
              icon={<img src={Email} alt='mail-logo' />}
              buttonColor='#BE0000'
              buttonText='Sign in with Email'
              onClick={() => this.props.history.push(`/signIn${this.props.location.search}`)}
              iconTheme='filled'
              className='btn-social'
            />
            {/* <div className="ORTXT d-flex align-items-center justify-content-between">
              <hr className="mr-4 w-100" />
              <h3 className="FontAvenirHeavy font-18 m-0 mr-4 text-muted">
                Or
              </h3>
              <hr className="mr-2 w-100" />
            </div> */}
            {/* <Form
              className={this.state.isSubmit ? "formSubmitted mt-5" : "mt-5"}
              ref={this.state.form}
              onSubmit={this.onLoginBtnClick}
            >
              <InputC
                className="col-12 ant-input ant-input-lg mb-4"
                stateKey="email"
                onChange={this.onChange}
                placeholder="Enter your Email ID"
                validations={[required, email]}
                autoFocus
                onKeyPress={this.onKeyPress}
              />
              <InputC
                type="password"
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.state.login.password}
                stateKey="password"
                showPwd={this.state.showHide.showpwd}
                onChange={this.onChange}
                placeholder="Enter your password"
                showPwdClick={this.showPwdClick}
                showPwdKey="showpwd"
                validations={[required]}
                onKeyPress={this.onKeyPress}
                onKeyDown={this.onKeyDownClick}
                name="login"
              />
              <div className="d-flex align-items-center justify-content-between max-btn-hide mt-3 mb-3">
              <div>
                <Link to="/forgotpassword">
                  <div className="d-flex font-13 align-items-center justify-content-center FontAvenirMedium"
                    style={{
                      color: '#0076FF'
                    }}
                  >
                    Forgot Password?
                </div>
                </Link>
              </div>
              <div>
                <Button
                  type="primary"
                  block
                  size="large"
                  style={{
                    width: 162
                  }}
                  onClick={this.onLoginBtnClick}
                >
                  Sign in
                </Button>
              </div>
            </div>
            <Button
                type="primary"
                block
                size="large"
                className="mbl-btn-hide"
                onClick={this.onLoginBtnClick}
              >
                Sign in
            </Button>
            </Form> */}
            <div className='d-flex justify-content-center mbl-btn-hide'>
              <Link to='/forgotpassword'>
                <div className='d-flex font-16 align-items-center justify-content-center FontAvenirMedium mt-3'>
                  Forgot Password?
                </div>
              </Link>
            </div>
            {/* <div className="lap-footer">
              <div className="FontAvenirMedium font-16 m-0 mr-4 text-muted text-center mt-3">
                Don’t have an account?
                <Link to="/signup">
                  <span className="ml-1 font-16 FontAvenirMedium mt-3 text-uppercase font-weight-bold" style={{ textDecoration: 'underline', fontWeight: 900 }}>
                    Sign up
                  </span>
                </Link>
              </div>
            </div> */}
            <div className='txt-footr mbl-footer d-flex justify-content-center pt-2'>
              Or continue with
            </div>
            <div className='mbl-footer d-flex justify-content-center p-2'>
              <div className='p-2'>
                <img
                  src={GoogleIco}
                  className='social-ico'
                  alt='social-icons'
                  onClick={this.loginWithGoogle}
                />
              </div>
              <div className='p-2'>
                <img
                  src={FBIco}
                  className='social-ico'
                  alt='social-icons'
                  onClick={this.loginWithFacebook}
                />
              </div>
              <div className='p-2'>
                <img
                  src={AppleIco}
                  className='social-ico'
                  alt='social-icons'
                  onClick={this.loginWithApple}
                />
              </div>
            </div>
            <div className='lap-footer'>
              <div className='mbl-accountTxt FontAvenirMedium font-16 m-0 mr-4 text-muted text-center mt-3'>
                Don’t have an account?
                <Link to='/signup'>
                  <span
                    className='mbl-signupTxt ml-1 font-16 FontAvenirMedium mt-3 text-uppercase font-weight-bold'
                    style={{ textDecoration: 'underline', fontWeight: 900 }}
                  >
                    Sign up
                  </span>
                </Link>
              </div>
            </div>
          </Card>
        </div>
        {/* Mbl signin page */}
        <div className='mblSignIn'>
          <div
            className='mbl-card-radius card-w-auto'
            style={{
              padding: 16
            }}
          >
            <div className='d-flex pt-4 mb-2 justify-center'>
              <div className='ml-2'>
                <img
                  src={mbllogo}
                  style={{
                    height: 30
                  }}
                  alt='logo'
                />
              </div>
            </div>
            <h3 className='pt-2 pb-2 mb-2 FontBariolHeavy font-28 text-center'>
              Hello! Welcome back.
            </h3>
            <h5
              style={{
                color: '#828282',
                padding: '0 5px'
              }}
              className='pl-3 pr-3 pb-2 mb-2 font-16 text-center FontBariol'
            >
              Log in with information that you entered during your registration
            </h5>
            <div className='mb-3'>
              <StickTxt1>
                <div className='fTxt mb-2'>
                  Is this your first time on Breadcrumb? You will
                  <br />
                  have to set up an account
                </div>
                <div className='btn-Txt bg-btn'>
                  <Link to='/signup'>
                    <span
                      className='font-16'
                      style={{
                        color: '#4C39B3',
                        fontFamily: 'Bariol',
                        textDecoration: 'none',
                        fontWeight: 800
                      }}
                    >
                      Sign up
                    </span>
                  </Link>
                </div>
              </StickTxt1>
            </div>
            <SocialLoginButton
              icon={<Icon type='google' theme='outlined' />}
              buttonColor='#EB4335'
              buttonText='Sign in with Google'
              onClick={this.loginWithGoogle}
              className='btn-social'
            />
            <SocialLoginButton
              icon={
                <img
                  src={FacebookIcon}
                  alt='facebook icon'
                  height='21px'
                  width='21px'
                />
              }
              buttonColor='#3B5998'
              buttonText='Sign in with Facebook'
              onClick={this.loginWithFacebook}
              className='btn-social'
            />
            <SocialLoginButton
              icon={<Icon type='apple' theme='filled' />}
              buttonColor='#000000'
              buttonText='Sign in with Apple'
              onClick={this.loginWithApple}
              iconTheme='filled'
              className='btn-social'
            />
            <SocialLoginButton
              icon={<img src={Email} alt='mail-logo' />}
              buttonColor='#BE0000'
              buttonText='Sign in with Email'
              onClick={() => this.props.history.push(`/signIn${this.props.location.search}`)}
              iconTheme='filled'
              className='btn-social'
            />
            <div className='mb-4'></div>
          </div>
        </div>
      </MblCard>
    )
  }
}

export default SignIn
