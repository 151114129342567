import { post } from "axios";
import React from "react";
import BreadcrumbView from "../../components/BreadCrumb";
import TitleDescBlue from "../../components/TitleDescBlue";
import { VIEW_ACTIVITY_IMAGE, VIEW_DRAFT_ACTIVITY_IMAGE } from "../../graphql";
// import Nav from '../../../../components/Nav';

class ContentImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      name: "",
      image_source_text: "",
      image_source_link: "",
      url: "",
      channel_url: "",
      channel_name: "",
      steps_source_text: "",
      steps_source_link: "",
      featuredImage: []
    };
  }

  getDraftData = () => {
    let query = VIEW_DRAFT_ACTIVITY_IMAGE;
    query.variables = { activities_draft_id: this.props.match.params.id };
    post(`${process.env.REACT_APP_API}`, query).then(res => {
      if (res.data.errors) {
        console.log(res.data.errors[0].message);
        this.setState({ data: [] });
      } else {
        const name = res.data.data.getOneDraftActivity.activity_details[0].name;
        const image_source_text =
          res.data.data.getOneDraftActivity.activity_details[0]
            .image_source_text;
        const image_source_link =
          res.data.data.getOneDraftActivity.activity_details[0]
            .image_source_link;
        const url = res.data.data.getOneDraftActivity.activity_details[0].url;
        const channel_url =
          res.data.data.getOneDraftActivity.activity_details[0].channel_url;
        const channel_name =
          res.data.data.getOneDraftActivity.activity_details[0].channel_name;
        const steps_source_text =
          res.data.data.getOneDraftActivity.activity_details[0]
            .steps_source_text;
        const steps_source_link =
          res.data.data.getOneDraftActivity.activity_details[0]
            .steps_source_link;
        const steps = res.data.data.getOneDraftActivity.steps;
        this.setState({
          data: steps,
          name: name,
          image_source_text: image_source_text,
          image_source_link: image_source_link,
          url: url,
          featuredImage: res.data.data.getOneDraftActivity.images,
          channel_url: channel_url,
          channel_name: channel_name,
          steps_source_text: steps_source_text,
          steps_source_link: steps_source_link
        });
      }
    });
  };

  getActivityData = () => {
    let query = VIEW_ACTIVITY_IMAGE;
    query.variables = { id: this.props.match.params.id };
    post(`${process.env.REACT_APP_API}`, query).then(res => {
      if (res.data.errors) {
        console.log(res.data.errors[0].message);
        this.setState({ data: [] });
      } else {
        const name = res.data.data.getOneActivityV2.activity_details[0].name;
        const image_source_text =
          res.data.data.getOneActivityV2.activity_details[0].image_source_text;
        const image_source_link =
          res.data.data.getOneActivityV2.activity_details[0].image_source_link;
        const url = res.data.data.getOneActivityV2.activity_details[0].url;
        const channel_url =
          res.data.data.getOneActivityV2.activity_details[0].channel_url;
        const channel_name =
          res.data.data.getOneActivityV2.activity_details[0].channel_name;
        const steps_source_text =
          res.data.data.getOneActivityV2.activity_details[0].steps_source_text;
        const steps_source_link =
          res.data.data.getOneActivityV2.activity_details[0].steps_source_link;
        const steps = res.data.data.getOneActivityV2.steps;
        this.setState({
          data: steps,
          name: name,
          image_source_text: image_source_text,
          image_source_link: image_source_link,
          url: url,
          featuredImage: res.data.data.getOneActivityV2.images,
          channel_url: channel_url,
          channel_name: channel_name,
          steps_source_text: steps_source_text,
          steps_source_link: steps_source_link
        });
      }
    });
  };

  componentDidMount() {
    if (this.props.history.location.pathname.includes("draft")) {
      this.getDraftData();
    } else {
      this.getActivityData();
    }
  }

  fetchData = () => {};

  TitleAndDesc = (title, desc) => {
    return (
      <React.Fragment>
        <div className="pb-3 titleColor font-weight-300 font16 text-uppercase">
          {title}
        </div>
        {desc !== "" && <div className="font16">{desc}</div>}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-white">
          <BreadcrumbView
            data={`/Learning Content/${this.state.name.replace(
              /\//g,
              "~~"
            )}/Images, Videos & Steps`}
            title="Images, Videos & Steps"
            id={this.props.match.params.id}
            draft={this.props.history.location.pathname.includes("draft")}
          />
        </div>
        <div className="col-11 m-3 bg-white mx-auto box-sdw-rad">
          <div className="p-2" />
          <div className="">
            <div className="text-uppercase m-3 titleColor">VIDEOS</div>
            <div />
            <div className="m-3">
              <TitleDescBlue
                title={this.state.url}
                desc={this.state.url}
                heading="LINK"
              />
            </div>
            <div className="m-3">
              <TitleDescBlue
                title={this.state.channel_name}
                desc={this.state.channel_url}
                heading="SOURCE CREDIT"
              />
            </div>
          </div>
          <div className="border m-3" />
          <div className="">
            <div className="text-uppercase m-3 titleColor">STEPS</div>
          </div>
          <div className="row m-3 steps-img">
            {this.state.data.map((data, index) => {
              let imageUrl = data.image;
              return (
                <div className="col-4 p-1" key={index}>
                  <div className="col-12 border p-1 br-5">
                    <div>
                      <img
                        className="col-12 p-0"
                        style={{ height: '229px', width: '100%', objectFit: 'cover' }}
                        src={data.image}
                        alt={data.title}
                        style={{height: 180}}
                      />
                    </div>
                    <div
                      style={{
                        width: "96px",
                        border: "1px solid #D9D9D9",
                        margin: "0 0.5rem",
                        padding: "0 0.5rem",
                        fontSize: 14,
                        fontWeight: "bold"
                      }}
                    >
                      {`Step - ${index + 1}`}
                    </div>
                    <div className="p-2">{data.title}</div>
                    <div className="p-2 steps-desc">{data.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row m-3">
            <TitleDescBlue
              title={this.state.steps_source_text}
              desc={this.state.steps_source_link}
              heading="SOURCE CREDIT"
            />
          </div>
          <div className="border m-3" />
          <div className="m-3">
            {/* <div className="m-3 ml-4 mr-4 p-0 pt-3 pb-3 row col-12 bg-white box-sdw-rad">
                    <div className="col-4 mb-3"> */}
            {this.TitleAndDesc("IMAGES", "")}
            <div className="row activity-img">
              {this.state.featuredImage.map((data, index) => {
                let imageUrl = data.image_url;
                return (
                  <div className="col-4" key={index}>
                    <img
                      className="col-12 p-0"
                      style={{ height: 212, width: 378, objectFit: 'cover' }}
                      src={data.image_url}
                      alt={`references ${index}`}
                      style={{height: 212}}
                    />
                  </div>
                );
              })}
            </div>
            <div className="mt-3 pb-3">
              <TitleDescBlue
                title={this.state.image_source_text}
                desc={this.state.image_source_link}
                heading="SOURCE CREDIT"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContentImage;
