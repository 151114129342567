import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Icon } from "antd";
import moment from "moment";
import './WeekPicker.css';

class WeekPickerC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_open: false,
		};
	}
	
	handleChange = (date, dateString) => {
		this.props.onChange(date, dateString);
		this.setState({ is_open: false });
	};
	
	render() {
		return (
			<React.Fragment>
				<div
					onClick={() => this.setState({ is_open: !this.state.is_open })}
					className="bg-white rounded border d-flex align-items-center justify-content-center pl-4 pr-2"
					style={{ height: 40 }}
				>
					<span className="mr-3">{moment(this.props.start_date).format('DD MMMM YYYY')}</span>
					<span className="mr-3">~</span>
					<span className="mr-3">{moment(this.props.end_date).format('DD MMMM YYYY')}</span>
					<span className="mr-3"><Icon type="down" style={{ verticalAlign: 'middle' }}/></span>
				</div>
				<DatePicker.WeekPicker
					open={this.state.is_open}
					className="datePicker"
					dropdownClassName="weekPicker"
					format="YYYY-wo"
					value={moment(this.props.start_date)}
					onChange={this.handleChange}
					disabledDate={(current) => !moment().isAfter(current)}
					renderExtraFooter={() => (
						<span
							className="color-blue cursor-pointer"
							onClick={() => this.setState({ is_open: false })}
						>
							Close
						</span>
					)}
				/>
			</React.Fragment>
		);
	}
}

WeekPickerC.propType = {
	start_date: PropTypes.string.isRequired,
	end_date: PropTypes.string.isRequired,
	week: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default WeekPickerC;
