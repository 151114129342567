export const GET_WEEKLY_REPORTS = {
	query: `
		query getStudentWeeklyProgress($students_id: ID, $start_date: String, $end_date: String) {
			getStudentWeeklyProgress(students_id: $students_id, start_date: $start_date, end_date: $end_date) {
				total_hours_spent {
					total_hours
					do_cards_hours
					watch_cards_hours
				}
				cards {
					id
					name
					default_activities_id
					thumb_images {
						id
						image_url
					}
					age_groups{
						age_group{
							id,
							age,
							is_active
						}
						age_group_dificulty{
							id,
							dificulty,
							is_active
						}
					}
					time
					difficulty
					total_time
					activity_reference_images
					video_link
					steps
					pdf_url
					url
				}
				expert_cards {
					id
					name
					default_activities_id
					thumb_images {
						id
						image_url
					}
					time
					age_groups{
						age_group{
							id,
							age,
							is_active
						}
						age_group_dificulty{
							id,
							dificulty,
							is_active
						}
					}
					difficulty
					total_time
					activity_reference_images
					video_link
					steps
					pdf_url
					url
				}
				PlayList {
					id
					title
					background_image
					color_code
					cards {
						id
						name
						default_activities_id
						thumb_images {
							id
							image_url
						}
						time
						age_groups{
							age_group{
								id,
								age,
								is_active
							}
							age_group_dificulty{
								id,
								dificulty,
								is_active
							}
						}
						difficulty
						total_time
						activity_reference_images
						video_link
						steps
						pdf_url
						url
						activity_status
					}
				}
			}
		}
	`
};
