import React, { Component } from "react";
// import RadarChart from 'react-svg-radar-chart';

export default class Radar extends Component {
  render() {
    return (
      <div className="d-flex justify-content-between align-items-center h-100 pt-4  pb-2 mb-4">
        <div className="d-flex justify-content-between flex-column h-100">
          <div>
            <h3 className="mb-3 FontAvenirHeavy font-18">
              Based on children attending this Learning Centre -{" "}
              {this.props.studentCount}
            </h3>
            <div className="row m-0">
              <p className="FontAvenirMedium col-6 p-0 font-14 d-flex ChartLegendColor  align-items-center">
                <span className="mr-2 legendPreviousMonth d-inline-block" />{" "}
                <span>Previous month’s progress</span>
              </p>
              <p className="FontAvenirMedium col-6 p-0 font-14 d-flex ChartLegendColor  align-items-center">
                <span className="mr-2 legendNextMonth d-inline-block" />
                <span>Current month’s progress</span>
              </p>
            </div>
          </div>
          <div>
            {this.props.data.map(data => {
              return <p className="FontAvenirRoman font-14">{data}</p>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Incase of Text alignment issue refer radar.js search caption function
 */
