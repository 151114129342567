import React, { Component } from 'react';
import { Spin } from 'antd';
import { withRouter } from "react-router-dom";
import SankeyChart from '../../../components/Charts/SankeyChart';
import axios from 'axios';
import { GET_ONBOARDING_JOURNEY } from '../graphql';
import ReportDate from './ReportDate';

class OnBoardingJourney extends Component {
  constructor(props) {
		super(props);
		this.state = {
      is_loading: false,
      width: 0,
      height: 0,
      count_data: {
        parents_signed_up: 0,
        students_signed_up: 0,
        parents_created_learners: 0,
        parents_not_created_learners: 0,
        learners_created_parents: 0,
        learners_not_created_parents: 0,
        learners_logged_in: 0,
        learners_not_logged_in: 0,
        learners_started_learning: 0,
        learners_not_started_learning: 0,
        started_learning_past_month: 0,
      },
      chart_data: {
        parents_signed_up: 0,
        students_signed_up: 0,
        parents_created_learners: 0,
        parents_not_created_learners: 0,
        learners_created_parents: 0,
        learners_not_created_parents: 0,
        parents_created_learners_logged_in: 0,
        parents_not_created_learners_logged_in: 0,
        learners_created_parents_logged_in: 0,
        learners_not_created_parents_logged_in: 0,
        parents_created_learners_not_logged_in: 0,
        parents_not_created_learners_not_logged_in: 0,
        learners_created_parents_not_logged_in: 0,
        learners_not_created_parents_not_logged_in: 0,
        logged_in_started_learning: 0,
        logged_in_not_started_learning: 0,
        started_learning_past_month: 0,
      }
    };
    this.svgRef = React.createRef();
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, GET_ONBOARDING_JOURNEY)
      .then(res => {
        this.setState({
          is_loading: false,
          count_data: {
            parents_signed_up: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.parent_onboard_count,
            students_signed_up: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_onboard_count,
            parents_created_learners: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_onboard_count,
            parents_not_created_learners: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_notOnboard_count,
            learners_created_parents: res.data.data.getParentOnboardsStudReports.student_onboarded_details.parent_onboard_count,
            learners_not_created_parents: res.data.data.getParentOnboardsStudReports.student_onboarded_details.parent_notOnboard_count,
            learners_logged_in: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_logged_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentIn_logged + res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentNot_logged,
            learners_not_logged_in: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_notlogged_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentIn_Notlogged + res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentNot_Notlogged,
            learners_started_learning: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_start_learn_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_start_learn_count,
            learners_not_started_learning: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_not_start_learn_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_not_start_learn_count,
            started_learning_past_month: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_pastMonth_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_pastMonth_count,
          },
          chart_data: {
            parents_signed_up: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.parent_onboard_count,
            students_signed_up: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_onboard_count,

            parents_created_learners: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_onboard_count,
            parents_not_created_learners: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_notOnboard_count,
            learners_created_parents: res.data.data.getParentOnboardsStudReports.student_onboarded_details.parent_onboard_count,
            learners_not_created_parents: res.data.data.getParentOnboardsStudReports.student_onboarded_details.parent_notOnboard_count,

            parents_created_learners_logged_in: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_logged_count,
            parents_created_learners_not_logged_in: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.student_notlogged_count,
            parents_not_created_learners_logged_in: 0,
            parents_not_created_learners_not_logged_in: 0,

            learners_created_parents_logged_in: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentIn_logged,
            learners_created_parents_not_logged_in: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentIn_Notlogged,
            learners_not_created_parents_logged_in: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentNot_logged,
            learners_not_created_parents_not_logged_in: res.data.data.getParentOnboardsStudReports.student_onboarded_details.student_parentNot_Notlogged,

            logged_in_started_learning: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_start_learn_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_start_learn_count,
            logged_in_not_started_learning: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_not_start_learn_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_not_start_learn_count,

            started_learning_past_month: res.data.data.getParentOnboardsStudReports.parent_onboarded_details.students_pastMonth_count + res.data.data.getParentOnboardsStudReports.student_onboarded_details.students_pastMonth_count,
          }
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.measureSVG();
    window.addEventListener("resize", this.measureSVG);
    this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measureSVG);
  }

  measureSVG = () => {
    const { width, height } = this.svgRef.current.getBoundingClientRect();
    this.setState({
      width,
      height
    });
  };

  render() {
    const { width, height } = this.state;
    const data = {
      nodes: [
        {
          name: `${this.state.count_data.parents_signed_up} Parents signed up via iPad`,
          color: '#0076FF',
          onClick: () => this.props.history.push(`/reports/parents`),
        },
        {
          name: `${this.state.count_data.parents_created_learners} Parents created learner profile`,
          color: '#5432BA',
          onClick: () => this.props.history.push(`/reports/parents-to-learners`),
        },
        {
          name: `${this.state.count_data.parents_not_created_learners} Parents yet to create learner profile`,
          color: '#BDBDBD',
          onClick: () => this.props.history.push(`/reports/parents-not-created-learners`),
        },
        {
          name: `${this.state.count_data.students_signed_up} Learners signed up via iPad`,
          color: '#0076FF',
          onClick: () => this.props.history.push(`/reports/learners`),
        },
        {
          name: `${this.state.count_data.learners_created_parents} Learners set up parent`,
          color: '#5432BA',
          onClick: () => this.props.history.push(`/reports/learners-to-parents`),
        },
        {
          name: `${this.state.count_data.learners_not_created_parents} Learners yet to create parent`,
          color: '#BDBDBD',
          onClick: () => this.props.history.push(`/reports/learners-not-created-parents`),
        },
        {
          name: `${this.state.count_data.learners_logged_in} Learners logged in`,
          color: '#FFC90D',
          onClick: () => this.props.history.push(`/reports/loggedin-learners`),
        },
        {
          name: `${this.state.count_data.learners_not_logged_in} Learners yet to log in`,
          color: '#BDBDBD',
          onClick: () => this.props.history.push(`/reports/yet-to-login-learners`),
        },
        {
          name: `${this.state.count_data.learners_started_learning} Learners started learning`,
          color: '#EC1A58',
          onClick: () => this.props.history.push(`/reports/learners-started-learning`),
        },
        {
          name: `${this.state.count_data.learners_not_started_learning} Learners yet to start learning`,
          color: '#BDBDBD',
          onClick: () => this.props.history.push(`/reports/learners-not-started-learning`),
        },
        {
          name: `${this.state.count_data.started_learning_past_month} Learners in the past month`,
          color: '#3EC1F0',
          onClick: () => this.props.history.push(`/reports/past-month-learners`),
        },
      ],
      links: [
        {
          source: 0,
          target: 1,
          value: this.state.chart_data.parents_created_learners,
          gradient: 'grad1',
          onClick: () => {},
        },
        {
          source: 0,
          target: 2,
          value: this.state.chart_data.parents_not_created_learners,
          gradient: 'grad2',
          onClick: () => {},
        },
        {
          source: 3,
          target: 4,
          value: this.state.chart_data.learners_created_parents,
          gradient: 'grad1',
          onClick: () => {},
        },
        {
          source: 3,
          target: 5,
          value: this.state.chart_data.learners_not_created_parents,
          gradient: 'grad2',
          onClick: () => {},
        },
        {
          source: 1,
          target: 6,
          value: this.state.chart_data.parents_created_learners_logged_in,
          gradient: 'grad3',
          onClick: () => {},
        },
        {
          source: 1,
          target: 7,
          value: this.state.chart_data.parents_created_learners_not_logged_in,
          gradient: 'grad4',
          onClick: () => {},
        },
        {
          source: 4,
          target: 6,
          value: this.state.chart_data.learners_created_parents_logged_in,
          gradient: 'grad3',
          onClick: () => {},
        },
        {
          source: 4,
          target: 7,
          value: this.state.chart_data.learners_created_parents_not_logged_in,
          gradient: 'grad4',
          onClick: () => {},
        },
        {
          source: 5,
          target: 6,
          value: this.state.chart_data.learners_not_created_parents_logged_in,
          gradient: 'grad5',
          onClick: () => {},
        },
        {
          source: 5,
          target: 7,
          value: this.state.chart_data.learners_not_created_parents_not_logged_in,
          gradient: 'grad6',
          onClick: () => {},
        },
        {
          source: 6,
          target: 8,
          value: this.state.chart_data.logged_in_started_learning,
          gradient: 'grad7',
          onClick: () => {},
        },
        {
          source: 6,
          target: 9,
          value: this.state.chart_data.logged_in_not_started_learning,
          gradient: 'grad8',
          onClick: () => {},
        },
        {
          source: 8,
          target: 10,
          value: this.state.chart_data.started_learning_past_month,
          gradient: 'grad9',
          onClick: () => {},
        },
      ]
    };
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h4 className="m-0 mb-2">Onboarding Journey</h4>
        </div>
        <Spin spinning={this.state.is_loading}>
          <svg width="100%" height="600" ref={this.svgRef}>
            <defs>
              <linearGradient id="grad1" gradientUnits="userSpaceOnUse">
                <stop offset="0%" style={{ stopColor: "#0076FF", stopOpacity: 1 }} />
                <stop offset="20%" style={{ stopColor: "#5432BA", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad2" gradientUnits="userSpaceOnUse">
                <stop offset="0%" style={{ stopColor: "#0076FF", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad3" gradientUnits="userSpaceOnUse">
                <stop offset="15%" style={{ stopColor: "#5432BA", stopOpacity: 1 }} />
                <stop offset="50%" style={{ stopColor: "#FFC90D", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad4" gradientUnits="userSpaceOnUse">
                <stop offset="30%" style={{ stopColor: "#5432BA", stopOpacity: 1 }} />
                <stop offset="70%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad5" gradientUnits="userSpaceOnUse">
                <stop offset="0%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "#FFC90D", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad6" gradientUnits="userSpaceOnUse">
                <stop offset="50%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
                <stop offset="90%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad7" gradientUnits="userSpaceOnUse">
                <stop offset="30%" style={{ stopColor: "#FFC90D", stopOpacity: 1 }} />
                <stop offset="70%" style={{ stopColor: "#EC1A58", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad8" gradientUnits="userSpaceOnUse">
                <stop offset="20%" style={{ stopColor: "#FFC90D", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "#9A9A9A", stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="grad9" gradientUnits="userSpaceOnUse">
                <stop offset="50%" style={{ stopColor: "#EC1A58", stopOpacity: 1 }} />
                <stop offset="90%" style={{ stopColor: "#3EC1F0", stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            {data && (
              <SankeyChart data={data} width={width - 250} height={height} />
            )}
          </svg>
        </Spin>
      </div>
    )
  }
}

export default withRouter(OnBoardingJourney);
