import React from 'react';
import { Button } from 'antd';
import './index.css';
import {triggerGAEvent} from "../../utils";

class ButtonC extends React.PureComponent{
  onBtnClick = () => {
    triggerGAEvent(this.props.category, this.props.action);
    this.props.onClick();
  };

  render() {
    return (
      <React.Fragment>
        <Button{...this.props} onClick={this.onBtnClick}>
          {this.props.children}
        </Button>
      </React.Fragment>
    );
  }
}

export default ButtonC;
