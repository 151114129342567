import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Tabs, Icon, Spin } from "antd";
import { StickyContainer, Sticky } from 'react-sticky';
import moment from "moment";
import Card from '../../../components/Expert/Card/index';
import MblCard from '../../../components/Expert/Card/mblCard';
import {GET_WEEKLY_REPORTS} from "./graphql";
import {openNotification} from "../../../utils";
import WeeklyReportsChart from "../../../components/Charts/WeeklyReportsChart";
import DoIcon from "../../../static/card/do.svg"
import WatchIcon from "../../../static/card/watch.svg";
import CollectionCard from "../../../components/Expert/CollectionCard";
// import CardsEmptyState from "../../../static/WeeklyReports/CardsEmptyState.svg";
// import ExpertsCardsEmptyState from "../../../static/WeeklyReports/ExpertsCardsEmptyState.svg";
// import PlaylistEmptyState from "../../../static/WeeklyReports/PlaylistEmptyState.svg";
import WeeklyProgressEmptyState from "../../../static/WeeklyReports/WeeklyProgressEmptyState.svg";
import MblWeeklyProgressEmptyState from "../../../static/WeeklyReports/weekly_progress_empty_state.svg";
import WeekPickerC from "../../../components/WeekPickerC";
import './WeeklyReports.css';
import { Link } from 'react-router-dom';

class WeeklyReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: false,
			active_key: "1",
			start_date: this.props.start_date || moment().startOf('week').format('YYYY-MM-DD'),
			end_date: this.props.end_date || moment().endOf('week').format('YYYY-MM-DD'),
			week: moment().format('YYYY-wo'),
			total_hours_spent: {
				total_hours: 0,
				do_cards_hours: 0,
				watch_cards_hours: 0,
			},
			cards: [],
			PlayList: [],
			expert_cards: [],
			is_playlist_selected: false,
			selected_playlist: {},
		}
	}
	
	fetchData = () => {
		this.setState({ is_loading: true });
		let query = GET_WEEKLY_REPORTS;
		query.variables = {
			students_id: this.props.students_id,
			start_date: this.state.start_date,
			end_date: this.state.end_date,
		};
		axios
			.post(`${process.env.REACT_APP_API}`, query)
			.then(res => {
				if (res.data.errors) {
					openNotification("error", res.data.errors[0].message);
				} else {
					const all_cards = res.data.data.getStudentWeeklyProgress.cards.map((data) => {
						let difficulty = { Easy: [], Medium: [], Hard: [] };
						(data.age_groups || []).map((ageGrp) => {
							if (ageGrp) {
								if (ageGrp.age_group)
									difficulty[ageGrp.age_group_dificulty.dificulty].push(
										ageGrp.age_group.age
									);
							}
						});
						data.age_diff = difficulty;
						return data;
					});
					const expert_cards = res.data.data.getStudentWeeklyProgress.expert_cards.map((data) => {
						let difficulty = { Easy: [], Medium: [], Hard: [] };
						(data.age_groups || []).map((ageGrp) => {
							if (ageGrp) {
								if (ageGrp.age_group)
									difficulty[ageGrp.age_group_dificulty.dificulty].push(
										ageGrp.age_group.age
									);
							}
						});
						data.age_diff = difficulty;
						return data;
					});
					this.setState({
						total_hours_spent: res.data.data.getStudentWeeklyProgress.total_hours_spent,
						cards: all_cards,
						PlayList: res.data.data.getStudentWeeklyProgress.PlayList,
						expert_cards: expert_cards,
						is_loading: false,
					});
				}
			}).catch(err => console.log(err));
	};
	
	componentDidMount() {
		this.fetchData();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.students_id !== this.props.students_id) {
			this.fetchData();
		}
	}
	
	handleDateChange = (date, dateString) => {
		this.setState({
			start_date: date.startOf('week').format('YYYY-MM-DD'),
			end_date: date.endOf('week').format('YYYY-MM-DD'),
			week: dateString,
		}, () => {
			this.fetchData();
		})
  };
	
	handlePlaylistClick = (data) => {
		this.setState({
			is_playlist_selected: true,
			selected_playlist: data
		});
	};
	
	handleBackClick = () => {
		this.setState({
			is_playlist_selected: false,
			selected_playlist: {}
		});
	};
	
	render() {
		const renderTabBar = (props, DefaultTabBar) => (
			<Sticky topOffset={10}>
			  {({style}) => (
				<DefaultTabBar {...props} className="site-custom-tab-bar" 
				style={{ ...style }} />
			  )}
			</Sticky>
		  );
		return (
			<div className="d-flex flex-column align-items-start justify-content-start mbl-weekpicker">
				<div className="mb-4">
					<WeekPickerC
						start_date={this.state.start_date}
						end_date={this.state.end_date}
						week={this.state.week}
						onChange={this.handleDateChange}
					/>
				</div>
				<Spin spinning={this.state.is_loading} wrapperClassName="w-100">
					{this.state.total_hours_spent.total_hours === 0 && (
						<div
							style={{
								backgroundColor: "white",
								textAlign: "center",
								border: 'none',
								padding: '1.5rem 0',
								boxShadow: '0px 4px 8px rgba(0, 118, 255, 0.08)',
								borderRadius: 6
							}}
						>
							<img className="max-weeklyempty-img" src={WeeklyProgressEmptyState} alt="empty State" />
							<img className="mbl-weeklyempty-img" src={MblWeeklyProgressEmptyState} alt="empty State" />
							<div className="journal-title mt-3 mbl-weeklyempty">
								Your child hasn’t done any activity on this week
							</div>
						</div>
					)}
					{this.state.total_hours_spent.total_hours > 0 && (
						<div className="mbl-pd-10 bg-white shadow p-4 w-100 rounded">
							<h5 className="mbl-weeklyupdates-title">Time spent on learning with Breadcrumb</h5>
							{/* Mbl cards weekly updates */}
							<div className="mbl-weekly-updates d-flex flex-column align-items-center justify-content-start mb-4">
								<div className="">
									<WeeklyReportsChart data={this.state.total_hours_spent} />
								</div>
								<div className="d-flex flex-column align-items-start justify-content-start pl-4 pr-4">
									<div className="d-flex align-items-center justify-content-start mb-2">
										<img
											src={DoIcon}
											alt="Do cards"
											height="24px"
											width="24px"
											className="mr-2"
										/>
										<span className="font-14" style={{color: '#000000'}}>Time spent on doing and learning</span>
									</div>
									<div className="d-flex align-items-center justify-content-start mb-2">
										<img
											src={WatchIcon}
											alt="Watch cards"
											height="24px"
											width="24px"
											className="mr-2"
										/>
										<span className="font-14" style={{color: '#000000'}}>Time spent on watching and increasing knowledge base</span>
									</div>
								</div>
							</div>
							{/* Max dev cards weekly updates */}
							<div className="max-weekly-updates d-flex align-items-center justify-content-start">
								<div className="pt-2 pb-4 pr-4 mr-4">
									<WeeklyReportsChart data={this.state.total_hours_spent} />
								</div>
								<div className="d-flex flex-column align-items-start justify-content-start">
									<div className="d-flex align-items-center justify-content-start mb-4">
										<img
											src={DoIcon}
											alt="Do cards"
											height="30px"
											width="30px"
											className="mr-4"
										/>
										<span className="font-18">Time spent on doing and learning</span>
									</div>
									<div className="d-flex align-items-center justify-content-start">
										<img
											src={WatchIcon}
											alt="Watch cards"
											height="30px"
											width="30px"
											className="mr-4"
										/>
										<span className="font-18">Time spent on watching and increasing knowledge base</span>
									</div>
								</div>
							</div>
							
							<h5 className="max-weekupdate-name">Cards I've completed</h5>
							<h5 className="mbl-weekupdate-name">Completed learning content details</h5>
							<StickyContainer>
							<Tabs renderTabBar={renderTabBar}>
								{this.state.cards.length !== 0 && (
									<Tabs.TabPane key="1" tab={`Cards ${this.state.cards.length ? ` - ${this.state.cards.length}` : ""}`}
									className="cardTab"
									>
										{/*{this.state.cards.length === 0 && (*/}
										{/*	<div*/}
										{/*		style={{*/}
										{/*			backgroundColor: "transparent",*/}
										{/*			textAlign: "center",*/}
										{/*			border: 'none',*/}
										{/*			padding: '1.5rem 0',*/}
										{/*		}}*/}
										{/*	>*/}
										{/*		<img src={CardsEmptyState} alt="empty State" />*/}
										{/*		<div className="journal-title mt-3">*/}
										{/*			Your child hasn’t completed any cards yet*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*)}*/}
										{this.state.cards.length !== 0 && (
											<div className="row mbl-weekCardsRow">
												{this.state.cards.map((data, index) => (
													<React.Fragment>
													<div className="max-weeklyCards col-4" key={index}>
														<Card
															src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
															videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
															imgRefIcon={data.activity_reference_images}
															stepsRefIcon={data.steps}
															pdfRefIcon={data.pdf_url}
															floatingContainer={true}
															floatingContainerLeft={true}
															title={data.name}
															time={data.time}
															difficulty={data.age_diff}
															defaultActivitiesId={data.default_activities_id}
															difficultyVisible={data.age_diff}
														/>
													</div>
													<div className="mbl-weeklyCards col-12" key={index}>
														<MblCard
															src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
															videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
															imgRefIcon={data.activity_reference_images}
															stepsRefIcon={data.steps}
															pdfRefIcon={data.pdf_url}
															floatingContainer={true}
															floatingContainerLeft={true}
															title={data.name}
															time={data.time}
															difficulty={data.age_diff}
															defaultActivitiesId={data.default_activities_id}
															difficultyVisible={data.age_diff}
														/>
													</div>
													</React.Fragment>
												))}
												{/* {this.state.cards.length > 3 && (
															<div className="mbl-weeklyCards col-8">
																		<div className="mbl-viewAllCard border-style cursor-pointer"
																		onClick={
																			() => this.props.history.push({
																				pathname: `/parent/learnerCards/${this.props.students_id}`,
																				state: {
																					start_date: this.state.start_date,
																					end_date: this.state.end_date,
																					students_name: this.props.student_name
																				}
																			})
																		}
																		>
																			View all
																		</div>
															</div>
														)} */}
											</div>
										)}
									</Tabs.TabPane>
								)}
								{this.state.expert_cards.length !== 0 && (
									<Tabs.TabPane key="2" tab={`Expert cards ${this.state.expert_cards.length ? ` - ${this.state.expert_cards.length}` : ""}`}
										className="cardTab"
									>
										{/*{this.state.expert_cards.length === 0 && (*/}
										{/*	<div*/}
										{/*		style={{*/}
										{/*			backgroundColor: "transparent",*/}
										{/*			textAlign: "center",*/}
										{/*			border: 'none',*/}
										{/*			padding: '1.5rem 0',*/}
										{/*		}}*/}
										{/*	>*/}
										{/*		<img src={ExpertsCardsEmptyState} alt="empty State" />*/}
										{/*		<div className="journal-title mt-3">*/}
										{/*			Your child hasn’t completed expert’s card*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*)}*/}
										{this.state.expert_cards.length !== 0 && (
											<div className="row mbl-weekCardsRow">
												{this.state.expert_cards.map((data, index) => (
													<React.Fragment>
													<div className="max-weeklyCards col-4" key={index}>
														<Card
															src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
															videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
															imgRefIcon={data.activity_reference_images}
															stepsRefIcon={data.steps}
															pdfRefIcon={data.pdf_url}
															floatingContainer={true}
															floatingContainerLeft={true}
															title={data.name}
															time={data.time}
															difficulty={data.age_diff}
															defaultActivitiesId={data.default_activities_id}
															difficultyVisible={data.age_diff}
														/>
													</div>
													<div className="mbl-weeklyCards col-12" key={index}>
														<MblCard
															src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
															videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
															imgRefIcon={data.activity_reference_images}
															stepsRefIcon={data.steps}
															pdfRefIcon={data.pdf_url}
															floatingContainer={true}
															floatingContainerLeft={true}
															title={data.name}
															time={data.time}
															difficulty={data.age_diff}
															defaultActivitiesId={data.default_activities_id}
															difficultyVisible={data.age_diff}
														/>
													</div>
													</React.Fragment>
												))}
											</div>
										)}
									</Tabs.TabPane>
								)}
								{this.state.PlayList.length !== 0 && (
									<Tabs.TabPane key="3" tab={`Learning paths ${this.state.PlayList.length ? ` - ${this.state.PlayList.length}` : ""}`}
									className="cardTab"
									>
										{/*{this.state.PlayList.length === 0 && (*/}
										{/*	<div*/}
										{/*		style={{*/}
										{/*			backgroundColor: "transparent",*/}
										{/*			textAlign: "center",*/}
										{/*			border: 'none',*/}
										{/*			padding: '1.5rem 0',*/}
										{/*		}}*/}
										{/*	>*/}
										{/*		<img src={PlaylistEmptyState} alt="empty State" />*/}
										{/*		<div className="journal-title mt-3">*/}
										{/*			Your child hasn’t completed any learning paths*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*)}*/}
										{this.state.PlayList.length !== 0 && (
											<React.Fragment>
												{!this.state.is_playlist_selected && (
													<div className="row mbl-weekCardsRow">
														{this.state.PlayList.map((data, index) => (
															<React.Fragment>
																<div className="max-weeklyCards col-3" key={index}>
																	<div className="cursor-pointer" onClick={() => this.handlePlaylistClick(data)}>
																		<CollectionCard
																			title={data.title}
																			src={data.background_image}
																			isLC={true}
																			collectionIcon={true}
																		/>
																	</div>
																</div>
																<div className="mbl-weeklyCards col-12" key={index}>
																	<div className="cursor-pointer" onClick={() => this.handlePlaylistClick(data)}>
																		<CollectionCard
																			title={data.title}
																			src={data.background_image}
																			isLC={true}
																			collectionIcon={true}
																		/>
																	</div>
																</div>
															</React.Fragment>
														))}
													</div>
												)}
												{this.state.is_playlist_selected && (
													<div>
														<div className="d-flex align-items-center justify-content-between mb-4">
															<div className="d-flex align-items-center justify-content-start">
																<Icon
																	type="arrow-left"
																	style={{ color: '#0076FF', cursor: 'pointer', fontSize: 25 }}
																	onClick={() => this.handleBackClick()}
																/>
																<h5 className="m-0 ml-4">{this.state.selected_playlist.title}</h5>
															</div>
														</div>
														<div className="row mbl-weekCardsRow">
															{this.state.selected_playlist.cards.map((data, index) => {
																let difficulty = { Easy: [], Medium: [], Hard: [] };
																(data.age_groups || []).map((ageGrp) => {
																	if (ageGrp) {
																		if (ageGrp.age_group)
																			difficulty[ageGrp.age_group_dificulty.dificulty].push(
																				ageGrp.age_group.age
																			);
																	}
																});
																return (
																	<React.Fragment>
																		<div className="max-weeklyCards col-4" key={index}>
																			<Card
																				src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
																				videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
																				imgRefIcon={data.activity_reference_images}
																				stepsRefIcon={data.steps}
																				pdfRefIcon={data.pdf_url}
																				floatingContainer={true}
																				floatingContainerLeft={true}
																				title={data.name}
																				time={data.time}
																				defaultActivitiesId={data.default_activities_id}
																				difficulty={difficulty}
																				difficultyVisible={difficulty}
																				Tag={data.activity_status === "Completed" ? [{name: data.activity_status, type: "success"}] : [{ name: "", status: "" }]}
																			/>
																		</div>
																		<div className="mbl-weeklyCards col-12" key={index}>
																			<MblCard
																				src={data.thumb_images ? data.thumb_images.length ? data.thumb_images[0].image_url : "" : ""}
																				videoRefIcon={data.default_activities_id === 2 ? data.video_link !== "" : data.url !== ""}
																				imgRefIcon={data.activity_reference_images}
																				stepsRefIcon={data.steps}
																				pdfRefIcon={data.pdf_url}
																				floatingContainer={true}
																				floatingContainerLeft={true}
																				title={data.name}
																				time={data.time}
																				defaultActivitiesId={data.default_activities_id}
																				difficulty={difficulty}
																				difficultyVisible={difficulty}
																				Tag={data.activity_status === "Completed" ? [{name: data.activity_status, type: "success"}] : [{ name: "", status: "" }]}
																			/>
																		</div>
																	</React.Fragment>
																)
															})}
														</div>
													</div>
												)}
											</React.Fragment>
										)}
									</Tabs.TabPane>
								)}
							</Tabs>
						</StickyContainer>
						</div>
					)}
				</Spin>
			</div>
		);
	}
}

export default withRouter(WeeklyReports);
