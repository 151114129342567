import { notification } from "antd";
import { post } from "axios";
import moment from "moment";
import ReactGA from 'react-ga';

function isJSON(str) {
  if (/^\s*$/.test(str)) return false;
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@");
  str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]");
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, "");
  return /^[\],:{}\s]*$/.test(str);
}

export const getString = str => {
  if (!isJSON(str)) {
    return str;
  }
  return JSON.parse(str);
};

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};

export async function getS3UploadUrl(fileName, contentType) {
  const query = `{"operationName":null,"variables":{},"query":"{\\n  getS3SignedUrl(key: \\"${fileName}\\", contentType: \\"${contentType}\\") {\\n    signedUrl\\n  }\\n}\\n"}`;
  const { data: uploadLink } = await post(
    `${process.env.REACT_APP_API}`,
    query,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return uploadLink.data.getS3SignedUrl.signedUrl;
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map((file, index) =>
      new Promise((resolveUpload, rejectUpload) => {
        const xhr = new XMLHttpRequest();
        xhr.file = file;
        xhr.onreadystatechange = function (e) {
          if (this.readyState === 4 && this.status === 200) {
            resolveUpload(urlList[index]);
          } else if (this.readyState === 4 && this.status !== 200) {
            rejectUpload(e);
          }
        };
        xhr.open("put", urlList[index], true);
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "application/pdf") {
          xhr.setRequestHeader("Content-Type", file.type);
        } else if (file.type === "audio/wav" || file.type === "audio/webm") {
          xhr.setRequestHeader("Content-Type", file.type);
        } else {
          xhr.setRequestHeader("Content-Type", "binary/octet-stream");
        }
        xhr.send(file);
      })
    );

    Promise.all(formUploadPromiseArray)
      .then(urls => {
        resolve(urls.map(url => url.split("?")[0]));
      })
      .catch(err => {
        reject(err);
      });
  });
}

export const showNotification = (type, heading = "", message = "") => {
  if (type === "success") {
    notification["success"]({
      message: `${heading}`,
      description: `${message}`
    });
  } else if (type === "error") {
    notification["error"]({
      message: `${heading}`,
      description: `${message}`
    });
  } else {
    notification['info']({
      message: `${heading}`,
      description: `${message}`
    });
  }
};

export const openNotification = (type, message, msg = "Something went wrong") => {
  if (type === "success") {
    notification["success"]({
      message: `Notification`,
      description: `${message}`
    });
  } else if (type === "error") {
    notification["error"]({
      message: "Something went wrong",
      description: `${message}`
    });
  } else if (type === "customError") {
    notification["error"]({
      message: msg,
      description: `${message}`
    });
  } else {
    notification['info']({
      message: "Notification",
      description: `${message}`
    });
  }
};

export const triggerGAEvent = (category, action, label = "") => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// export const S3_BUCKET_URL = "http://altius-prod-images.s3-website.ap-south-1.amazonaws.com";

// export const findDupes = array => {
//   let dupeFree = _.map(
//     _.uniq(
//       _.map(array, function(obj) {
//         return JSON.stringify(obj);
//       })
//     ),
//     function(obj) {
//       return JSON.parse(obj);
//     }
//   );
// };

export const isLink = /(http(s?))\/\//gi;

export const YOUTUBE_VIDEO_REGEX = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;

export const is_phoneNumber = /([+(\d]{1})(([\d+() -.]){8,13})([+(\d]{1})/gm;

export const isValidPassword = /^(?=.*\d)(?=.*[!@#$_%^&*])(?=.*[a-zA-z]).{8,}$/;

export const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const isDecimal = /^(\d*\.)?\d+$/g

export const titleCase = str => {
  if (str === null || str === "") return false;
  //str = str.toString();
  else
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
};


export const dragDrop_reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item, index) => {
    const newItem = { ...item };
    newItem.position = index;
    return newItem;
  });
};

export const featuredImage_listStyle = (snapshot, isDraggingOver) => ({
  // display: "flex",
  // flexWrap: "wrap",
  padding: '16px 0',
  overflow: "auto",
  flexDirection: 'row',
  transition: '0.5s',
  borderRadius: 5
});

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  openNotification("success", "Copied to clipboard");
};

export const getDates = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  let endDate = moment(stopDate);
  while (currentDate <= endDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}
export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};
export const SetTokenCookie = () => {
  var baseDomain = '.learnwithbreadcrumb.com', expireAfter = new Date();
  const d = new Date();
  d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = "Token" + "=" + localStorage.token + ";" + expires + "; domain=" + baseDomain + ";path=/";
}