import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import TimeAgo from "react-timeago";
import { GET_REPORT_DATE } from '../graphql';

class ReportDate extends Component {
  constructor(props) {
		super(props);
		this.state = {
			date: "",
		};
  }
  
  fetchData = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_REPORT_DATE)
      .then(res => {
        this.setState({
          date: res.data.data.getReportsDate.cards_cronDate
        });
      }).catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }
  
  render() {
    return (
      <span 
        style={{ 
          color: '#000000',
          fontWeight: 700
        }} 
        className="font-14 font-weight-bold"
      >
        {`Last updated: `}<TimeAgo date={Number(moment(this.state.date).format("x"))} />
      </span>
    )
  }
}

export default ReportDate;
