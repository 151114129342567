import React, { Component } from 'react';
import { Radio, DatePicker, Table, Input, Button, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { GET_CARDS_POPULARITY } from '../graphql';
import CollectionPlaylistName from './CollectionPlaylistName';
import TimeAgo from "react-timeago";

class CardsPopularity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      type: "all_time",
      start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      table_data: [],
      page: 1,
      total: 0,
      searchText: "",
      searchedColumn: "",
      filterInfo: null,
      sorterInfo: {
        columnKey: "completedCount",
        field: "completedCount",
        order: "descend"
      },
    };
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    let query = GET_CARDS_POPULARITY;
    query.variables = {
      type: this.state.type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      page: this.state.page,
      filter: {
        cardsCount: sorterInfo.columnKey === "completedCount" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
        collply_name: this.state.searchText,
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          table_data: res.data.data.getCardsPopularityReports.cards_result,
          total: res.data.data.getCardsPopularityReports.totalPages,
          is_loading: false,
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  };

  handleRadioChange = (e) => {
    this.setState({
      type: e.target.value,
      page: 1,
    }, () => {
      this.fetchData();
    });
  };

  handleDateChange = (dates, dateStrings) => {
    this.setState({
      start_date: dates[0].format('YYYY-MM-DD'),
      end_date: dates[1].format('YYYY-MM-DD'),
      page: 1,
    }, () => {
      this.fetchData();
    })
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sorterInfo: sorter,
      filterInfo: filters
    }, () => this.fetchData());
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleSearchClick = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      page: 1,
    }, () => this.fetchData());
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({
      searchText: '',
      page: 1,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearchClick(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record, index) => (
      <CollectionPlaylistName
        highLight={this.state.searchedColumn === dataIndex}
        searchText={this.state.searchText}
        data={record.collply_arr}
      />),
  });

  render() {
    let sorterInfo = this.state.sorterInfo || {};
    const columns = [
      {
        title: "CARD NAME",
        width: "20%",
        dataIndex: "name",
        render: (text, record, index) => (
          <div
            className="cursor-pointer"
            onClick={() => this.props.history.push(`/learningcontent/${record.activities_id}/view`)}
          >
            {text}
          </div>
        ),
      },
      {
        title: "CREATED BY",
        width: "20%",
        dataIndex: "username",
      },
      {
        title: "COLLECTION / LEARNING PATH NAME",
        width: "30%",
        key: 'collply_name',
        ...this.getColumnSearchProps('collply_name'),
      },
      {
        title: "NO OF LEARNERS COMPLETED",
        width: "15%",
        dataIndex: "completedCount",
        sorter: (a, b) => a.completedCount - b.completedCount,
        sortOrder: sorterInfo.columnKey === "completedCount" && sorterInfo.order,
        // render: (text, record, index) => (
        //   <div className="d-flex align-items-center justify-content-center">
        //     {text}
        //   </div>
        // ),
      },
      {
        title: "LAST COMPLETED ON",
        width: "15%",
        dataIndex: "recentDate",
        render: (text) => <TimeAgo date={Number(moment(text).format("x"))} />,
      },
    ];
    return (
      <div className="pt-4 pb-4 bg-white rounded">
        <div className="d-flex align-items-center justify-content-between pl-4 pr-4" style={{ minHeight: 40 }}>
          <div className="d-flex align-items-center justify-content-between">
            <Radio.Group onChange={this.handleRadioChange} value={this.state.type}>
              <Radio value={"all_time"}>All time popular</Radio>
              <Radio value={"recently"}>Recently popular</Radio>
            </Radio.Group>
            {this.state.type === "recently" && (
              <DatePicker.RangePicker
                size="large"
                onChange={this.handleDateChange}
                placeholder="Select Range"
                allowClear={false}
                value={[moment(this.state.start_date), moment(this.state.end_date)]}
                popupStyle={{ width: 500 }}
                format="DD MMMM YYYY"
              />
            )}
          </div>
        </div>
        <div className="mt-4">
          <Table
            rowClassName="align-top"
            loading={this.state.is_loading}
            columns={columns}
            dataSource={this.state.table_data}
            onChange={this.handleTableChange}
            rowKey={record => record.activities_id}
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
            }}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(CardsPopularity);
