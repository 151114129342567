import React from 'react';
import {Card, Skeleton, Popover} from "antd";
import {Link} from "react-router-dom";
import './card.css';

const CategoryCard = (props) => {
  const popoverActions = (
    <div className="d-flex flex-column">
      {!props.DpCard && (
        <div className="FontAvenirRoman text-secondary font-16 pointer-cursor mb-3" onClick={() => props.edit(props.id)}>
          Edit
        </div>
      )}
      <div className="FontAvenirRoman text-secondary font-16 pointer-cursor" onClick={() => props.delete(props.id)}>
        Delete
      </div>
    </div>
  );
  return (
    <div>
      {props.DpCard ? (
        <Card
          title={props.title}
          className="interestCard rounded position-relative"
          style={{
            height: props.height,
            borderWidth: props.borderLeft ? "0 0 0 5px" : "5px 0 0 0",
            borderColor: `${props.borderColor}`
          }}
          extra={props.role_id === 1 && props.id > 3 && (
            <Popover content={popoverActions} trigger="hover" placement="bottomRight">
              <div className="triple-dot pointer-cursor">•••</div>
            </Popover>
          )}
        >
          <Link to={`data/${props.id}`}>
            <Skeleton className="h-100" loading={props.loading} avatar active>
              <Card.Meta
                className="h-100"
                description={props.desc}
              />
            </Skeleton>
          </Link>
        </Card>
      ) : (
        <Card
          className="categoryCard rounded position-relative"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(0, 0, 0, 0.87) 125%), url(${props.image})`,
            backgroundRepeat: 'round'
          }}
          extra={props.role_id === 1 ? (
            <Popover content={popoverActions} trigger="hover" placement="bottomRight">
              <div className="d-flex pointer-cursor">
                <div className="triple-dot"/>
                <div className="triple-dot"/>
                <div className="triple-dot"/>
              </div>
            </Popover>
          ) : <div/>}
        >
          {props.id ? (
            <Link to={`interests/${props.id}`}>
              <Skeleton className="h-100" loading={props.loading} avatar active>
                <Card.Meta
                  className="h-100"
                  description={
                    <div className="d-flex flex-column">
                      <span style={{color: '#FFFFFF', fontSize: 17, fontWeight: 900, marginBottom: 7}}>{props.title}</span>
                      <span style={{color: '#FFFFFF'}}>{props.desc}</span>
                    </div>
                  }
                />
              </Skeleton>
            </Link>
          ) : (
            <Skeleton className="h-100" loading={props.loading} avatar active>
              <Card.Meta
                className="h-100"
                description={
                  <div className="d-flex flex-column">
                    <span style={{color: '#FFFFFF', fontSize: 17, fontWeight: 900, marginBottom: 7}}>{props.title}</span>
                    <span style={{color: '#FFFFFF'}}>{props.desc}</span>
                  </div>
                }
              />
            </Skeleton>
          )}
        </Card>
      )}
    </div>
  );
};

export default CategoryCard;
