import { Button, Card, Col, DatePicker, Input, Modal, Row, Tag } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import arrowLeft from '../../assets/arrow_left.svg'
import Loading from '../../components/Loading'
import { AppContext } from '../../context/AppContext'
import {
  APPROVE_PARENT,
  GET_PARENT_DETAILS,
  ONHOLD_PARENT,
  RESEND_INVITE
} from '../../graphql'
import { openNotification, triggerGAEvent } from '../../utils'
import {
  GET_ONE_STUDENT_ATTENDANCE,
  INITIATE_PASSWORD_RESET
} from '../Students/api'

class ViewParent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDialog: null,
      is_loading: false,
      parentVisible: false,
      parent_details: {
        first_name: '',
        last_name: '',
        address: '',
        email: '',
        phone_no: '',
        status: '',
        comments: '',
        createdAt: '',
        onboardedAt: ''
      },
      students: [],
      calender_details: [],
      selected_student_id: '',
      on_hold_text: ''
    }
  }

  fetchParentData = () => {
    let query = GET_PARENT_DETAILS
    query.variables = {
      status: this.props.match.params.status,
      email: this.props.match.params.email,
      date: moment().format('YYYY-MM-DD')
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          let parentDetails = res.data.data.ParentDashbordV2.user_details
            ? res.data.data.ParentDashbordV2.user_details
            : {}
          this.props.setUserInfo({ parentDetails, students: res.data.data.ParentDashbordV2.students | [] })
          this.setState({
            parent_details: res.data.data.ParentDashbordV2.user_details,
            students: res.data.data.ParentDashbordV2.students
              ? res.data.data.ParentDashbordV2.students.map(stud => ({
                ...stud,
                is_open: false
              }))
              : []
          })
        }
      })
      .catch(err => console.log(err))
  }

  approveParent = () => {
    triggerGAEvent('Parents', 'Approving a Parent')
    let mutation = APPROVE_PARENT
    mutation.variables = {
      notification_id: this.state.parent_details.id,
      comment: this.state.on_hold_text
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          openNotification('success', 'Parent approved')
          window.location.href = `/parents/1/${this.props.match.params.email}`
          this.handleModalCancel()
        }
      })
      .catch(err => console.log(err))
  }

  onHoldParent = () => {
    triggerGAEvent('Parents', 'Setting a Parent to On-Hold')
    let mutation = ONHOLD_PARENT
    mutation.variables = {
      notification_id: this.state.parent_details.id,
      comment: this.state.on_hold_text
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          openNotification('success', 'Parent is set to on-hold')
          this.handleModalCancel()
          window.location.href = `/parents/2/${this.props.match.params.email}`
        }
      })
      .catch(err => console.log(err))
  }

  resendParentInvite = () => {
    triggerGAEvent('Parents', 'Resending Parent Invite')
    const confirmDialog = Modal.confirm({
      title: 'Do you want to resend the invite email?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let query = RESEND_INVITE
        query.variables = {
          email: this.state.parent_details.email
        }
        axios
          .post(`${process.env.REACT_APP_API}`, query)
          .then(res => {
            if (res.data.errors) {
              openNotification('error', res.data.errors[0].message)
            } else {
              openNotification('success', 'Invite sent successfully')
            }
          })
          .catch(err => console.log(err))
      }
    })
    this.setState({ confirmDialog })
  }

  fetchAttendance = month => {
    let query = GET_ONE_STUDENT_ATTENDANCE
    query.variables = {
      student_id: this.state.selected_student_id,
      date: `${month}`
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let dateArray = this.state.calender_details.slice()
        let cal_deets = res.data.data.webCalenderView.calender_details
        cal_deets.forEach((item, i) => {
          dateArray.push(item.date)
        })
        this.setState({
          calender_details: dateArray
        })
      })
      .catch(err => console.log(err))
  }

  getMonths = () => {
    let allMonths = moment.months()
    let thisYear = moment().year()
    let thisMonth = moment().month()
    let monthArray = []

    if (thisMonth === 0) {
      monthArray.push(
        `November ${thisYear - 1}`,
        `December ${thisYear - 1}`,
        `January ${thisYear}`
      )
    } else if (thisMonth === 1) {
      monthArray.push(
        `December ${thisYear - 1}`,
        `January ${thisYear}`,
        `February ${thisYear}`
      )
    } else {
      monthArray = this.getLastTwelveMonths(allMonths, thisMonth, thisYear)
    }
    this.fetchLastThreeMonths(monthArray)
  }

  getLastTwelveMonths = (allMonths, thisMonth, thisYear) => {
    let timesArr = [
      thisMonth,
      thisMonth - 1,
      thisMonth - 2,
      thisMonth - 3,
      thisMonth - 4,
      thisMonth - 5,
      thisMonth - 6,
      thisMonth - 7,
      thisMonth - 8,
      thisMonth - 9,
      thisMonth - 10,
      thisMonth - 11
    ]
    let localMonthArr = []
    _.forEach(timesArr, function (value) {
      localMonthArr.push(`${allMonths[value]} ${thisYear}`)
    })
    return localMonthArr
  }

  fetchLastThreeMonths = monthArray => {
    monthArray.forEach((month, index) => {
      setTimeout(() => {
        this.fetchAttendance(month)
      }, 1000)
    })
  }

  renderAttendanceDate = current => {
    if (this.state.calender_details.includes(current.format('YYYY-MM-DD'))) {
      return (
        <div
          className='ant-calendar-date present'
          style={{ border: '1px solid green', borderRadius: '50%' }}
        >
          {current.date()}
        </div>
      )
    } else {
      return <div className='ant-calendar-date'>{current.date()}</div>
    }
  }

  onOpenViewAttendanceClick = index => {
    triggerGAEvent('Parents', 'View Attendance for Learners')

    let students = [...this.state.students]
    students[index].is_open = !students[index].is_open
    this.setState(
      {
        students,
        selected_student_id: students[index].id,
        calender_details: []
      },
      () => this.getMonths()
    )
  }

  onCloseViewAttendanceClick = index => {
    triggerGAEvent('Parents', 'Close Attendance for Learners')

    let students = [...this.state.students]
    students[index].is_open = !students[index].is_open
    this.setState({ students, selected_student_id: '', calender_details: [] })
  }

  onInputChange = e => {
    if (e.target.name === 'on_hold_text') {
      e.target.value.length <= 280 &&
        this.setState({ [e.target.name]: e.target.value })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  componentDidMount() {
    this.fetchParentData()
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy()
  }

  handleModalCancel = () => {
    this.setState({
      parentVisible: false,
      on_hold_text: ''
    })
  }

  approveResetPassword = () => {
    triggerGAEvent('Students', 'Approve Password reset request')
    this.setState({ loading: true })
    let mutation = INITIATE_PASSWORD_RESET
    mutation.variables = {
      student_id: parseInt(this.props.match.params.id, 10)
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data.adminAcceptResetPasswordV2.requestAccepted) {
          this.openNotification('success', 'Password Reset Accepted')
          this.setState({ loading: false })
        } else {
          this.openNotification('error', 'Something Went Wrong')
        }
      })
      .catch(err => console.log(err))
  }

  render() {
    const { parent_details } = this.state
    return (
      <React.Fragment>
        <Loading is_loading={this.state.is_loading} />
        <div className='studentView'>
          <div className='d-flex align-items-start justify-content-between'>
            <h4 style={{ paddingLeft: '1rem' }}>
              <img
                src={arrowLeft}
                className='cursor-pointer mr-2'
                alt='left arrow'
                onClick={() => this.props.history.goBack()}
              />
              {`${parent_details.first_name ? parent_details.first_name : ``
                } ${parent_details.last_name ? parent_details.last_name : ``}`}</h4>
            {parent_details.status === '0' && (
              <Button
                type='primary'
                className='btn-w250'
                size='large'
                onClick={() => this.setState({ parentVisible: true })}
              >
                Approve / On-hold
              </Button>
            )}
            {parent_details.status === '1' && (
              <Button
                type='primary'
                className='btn-w250'
                size='large'
                onClick={() => this.resendParentInvite()}
              >
                Resend Invite
              </Button>
            )}
            {parent_details.status === '2' && (
              <Button
                type='primary'
                className='btn-w250'
                size='large'
                onClick={() => this.setState({ parentVisible: true })}
              >
                Approve
              </Button>
            )}
          </div>
          <div style={{ padding: '1rem' }}>
            <Card
              style={{
                border: '1px solid #4A90E2',
                borderRadius: 5,
                paddingBottom: '0'
              }}
            >
              <Row>
                <Col span={3} style={{ fontSize: 17 }}>
                  <div className='text-uppercase'>City</div>
                  <h5>{parent_details.address}</h5>
                </Col>
                <Col span={7} style={{ fontSize: 17 }}>
                  <div className='text-uppercase'>Email</div>
                  <h5>{parent_details.email}</h5>
                </Col>
                <Col span={4} style={{ fontSize: 17 }}>
                  <div className='text-uppercase'>Mobile</div>
                  <h5>{parent_details.phone_no}</h5>
                </Col>
                <Col span={4} style={{ fontSize: 17 }}>
                  <div className='text-uppercase'>APPLIED ON</div>
                  <h5>
                    {moment(parent_details.createdAt, 'x').format(
                      'MMM DD, YYYY'
                    )}
                  </h5>
                </Col>
                <Col span={4} style={{ fontSize: 17 }}>
                  {parent_details.status === '3' && (
                    <React.Fragment>
                      <div className='text-uppercase'>ONBOARDED ON</div>
                      <h5>
                        {moment(parent_details.onboardedAt, 'x').format(
                          'MMM DD, YYYY'
                        )}
                      </h5>
                    </React.Fragment>
                  )}
                </Col>
                <Col span={2} style={{ fontSize: 17 }}>
                  <div className='text-uppercase'>Status</div>
                  {parent_details.status === '0' && (
                    <Tag color='red'>Pending approval</Tag>
                  )}
                  {parent_details.status === '1' && (
                    <Tag color='orange'>Yet to onboard</Tag>
                  )}
                  {parent_details.status === '2' && (
                    <Tag color='blue'>On hold</Tag>
                  )}
                  {parent_details.status === '3' && (
                    <Tag color='green'>Onboarded</Tag>
                  )}
                </Col>
              </Row>
            </Card>
            {parent_details.comments && (
              <div>
                <div className='mtb-24' />
                <div className='text-gray font-16'>Comments</div>
                <Card
                  style={{
                    border: '1px solid #CFCFCF',
                    borderRadius: 5,
                    paddingBottom: '0'
                  }}
                >
                  <div className='font-16'>{parent_details.comments}</div>
                </Card>
              </div>
            )}
          </div>

          {this.state.students && this.state.students.length > 0 && (
            <div className='mtb-24 border-bottom' />
          )}

          {this.state.students &&
            this.state.students.map((student, index) => (
              <div style={{ padding: '1rem' }} key={index}>
                <Link to={{ pathname: `/students/${student.id}` }}>
                  <div className='d-flex align-items-start justify-content-start pointer-cursor'>
                    <h5>{student.student_details.is_delete ? "Account Deleted" : `${student.student_details.fname} ${student.student_details.lname}`}</h5>
                  </div>
                </Link>
                <Card
                  style={{
                    border: '1px solid #4A90E2',
                    borderRadius: 5,
                    paddingBottom: '0'
                  }}
                >
                  <Row>
                    <Col span={4} style={{ fontSize: 17 }}>
                      <div className='text-uppercase'>user name</div>
                      <h5>{!student.student_details.is_delete ? student.student_details.username : "Account deleted"}</h5>
                    </Col>
                    <Col span={4} style={{ fontSize: 17 }}>
                      <div className='text-uppercase'>D.O.B</div>
                      <h5>{!student.student_details.is_delete ? student.student_details.dob : "-"}</h5>
                    </Col>
                    <Col span={4} style={{ fontSize: 17 }}>
                      <div className='text-uppercase'>Age Category</div>
                      <h5>{student.student_details.age_group}</h5>
                    </Col>
                    <Col span={8} style={{ fontSize: 17 }}>
                      <div className='text-uppercase'>
                        Learnt with Breadcrumb today
                      </div>
                      <Tag
                        color={
                          student.student_details.attendance === 'Present'
                            ? 'green'
                            : 'red'
                        }
                      >
                        {student.student_details.attendance === 'Present'
                          ? 'Yes'
                          : 'No'}
                      </Tag>
                      <span
                        style={{
                          color: '#1890ff',
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                        onClick={() => this.onOpenViewAttendanceClick(index)}
                      >
                        {!student.is_open ? 'View all days' : ''}
                      </span>
                      <DatePicker
                        className='datePicker'
                        showToday={false}
                        open={student.is_open}
                        size='small'
                        dateRender={this.renderAttendanceDate}
                        renderExtraFooter={() => (
                          <span
                            style={{
                              color: '#1890ff',
                              fontWeight: 500,
                              cursor: 'pointer'
                            }}
                            onClick={() =>
                              this.onCloseViewAttendanceClick(index)
                            }
                          >
                            Close
                          </span>
                        )}
                      />
                    </Col>
                    <Col span={4} style={{ fontSize: 17 }}>
                      <div className='text-uppercase'>Feedback</div>

                      <Link
                        to={{ pathname: `/students/${student.id}/feedbacks` }}
                      >
                        <h5
                          style={{ color: '#1890ff', cursor: 'pointer' }}
                        >{`View All Feedback`}</h5>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
        </div>
        <Modal
          title={
            <div className='d-flex flex-column'>
              <div style={{ margin: '0.5rem 0' }}>{parent_details.name}</div>
              <div style={{ margin: '0.5rem 0' }}>{parent_details.address}</div>
              <div style={{ margin: '0.5rem 0' }}>
                {parent_details.email} • {parent_details.phone_no}
              </div>
            </div>
          }
          visible={this.state.parentVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          footer={[
            <Button
              key='1'
              size='large'
              type='default'
              className='step-cancel'
              onClick={this.onHoldParent}
            >
              On hold
            </Button>,
            <Button
              key='2'
              size='large'
              type='primary'
              onClick={this.approveParent}
            >
              Approve
            </Button>
          ]}
        >
          <Input.TextArea
            rows={4}
            name='on_hold_text'
            placeholder='Enter a description'
            value={this.state.on_hold_text}
            onChange={this.onInputChange}
            size='large'
          />
          <div
            style={
              this.state.on_hold_text
                ? this.state.on_hold_text.length >= 280
                  ? { color: '#FF0A00', float: 'right', marginTop: 8 }
                  : { float: 'right', marginTop: 8 }
                : { float: 'right', marginTop: 8 }
            }
          >
            {this.state.on_hold_text ? this.state.on_hold_text.length : 0} / 280
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
const ParentView = (props) =>
  <div>

    <AppContext.Consumer>
      {userInfo => (
        <ViewParent {...userInfo} {...props} />
      )

      }
    </AppContext.Consumer>
  </div>


export default ParentView
