import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { Row, Col, Icon } from 'antd'
import styled, { css, keyframes } from 'styled-components'
import Moment from 'moment'
import Close from '../../static/expert/icons/close.svg'
import ImgPlaceholder from '../../static/nav/imageFallback.png'
import FireImg from '../../static/workshop/workshopFire.svg'
import ClockImg from '../../static/workshop/workClock.svg'
// import contentFace from '../../static/workshop/profile_no_bg.svg'
import contentFace from '../../static/workshop/noProfileImage.png'
import UserProfile from '../../static/workshop/user_profile.svg'
import dateArrow from '../../static/workshop/dateArrow.svg'

const Img = require('react-image')

const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`
const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
  img.ImgCard,
  .ImgCard.skeleton {
    width: auto;
    min-height: 192px;
    height: 192px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    object-fit: cover;
    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
  .ImgCard .closeContainer {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .closeContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .plusmoreTag {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #000000;
  }
  .floatingContainer {
    bottom: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    &.position-left {
      left: 10px;
      right: initial;
    }
    img {
      margin-right: 9px;
      width: 13px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .w-300px {
    min-height: 20px;
    width: 200px;
  }
  .ttitle {
    width: 150px;
    height: 16px;
    @media (min-width: 768px) {
      height: 18px;
    }
  }
  .w-150px {
    width: 150px;
    height: 16px;
  }
  .w-100px {
    width: 100px;
    height: 16px;
  }
  .w-50px {
    width: 50px;
    height: 16px;
  }
  .skeleton {
    border-radius: 10px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;

    animation: ${LoadingAnimation} 1.4s ease infinite;
  }

  ${props =>
    props.rounded &&
    css`
      img.ImgCard {
        border-radius: 8px;
      }
    `}
  ${props =>
    props.floatingContainerLeft &&
    css`
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 3px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      img.ImgCard {
        background: #c4c4c4;
        border-radius: 8px;
      }
    `}
`
const CardBody = styled.div`
  cursor: pointer;
  background: #fff;
  padding: 15px 15px;
  .heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  .subHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 8px;
  }
  .DifficultyHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: #b9b9b9;
  }
  .DifficultySubHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: #262626;
  }
  .dateText {
    color: #828282;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .workshoptypeText {
    color: #000000;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    float: right;
  }
  .WorkshopViewTitleText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #828282;
  }
  .workRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  .workCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 10px;
    padding-right: 10px;
    // margin-bottom: 5px;
  }
  .hurryText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #ea1f59;
  }
  .dateDiv {
    padding: 5px;
    margin-right: 5px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  .workDate {
    position: relative;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #333333;
    margin: 5px 0px;
  }
  .workTime {
    position: relative;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    text-align: center;
    color: #828282;
    margin: 5px 0px;
  }
  .preqTextDiv {
    position: relative;
    background: #fff6d6;
    border-radius: 2px;
  }
  .preqText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-transform: capitalize;
    color: #333333;
    padding: 4px 10px;
  }
  .preqTextBord {
    background: #f8c947;
    border: 2px solid #f8c947;
    margin-right: 5px;
  }
  .comContentDiv {
    position: relative;
    float: right;
  }
  .ContentDiv {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 10px;
    text-align: center;
    box-shadow: 10px 10px #fff;
  }
  .ContentDiv1 {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 4px;
    margin-right: 10px;
    text-align: center;
    box-shadow: 10px 10px #fff;
  }
  .clickDetail {
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #333333;
    cursor: pointer;
  }
  .clickDiv {
    margin-top: 15px;
  }
  .fireimg {
    padding-right: 5px;
    padding-left: 5px;
  }
  .ContentImg {
    // margin: 15px;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    object-fit: cover;
  }
  .contentName {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #fff;
    padding: 5px;
    width: 150px;
  }
  .arrowIcon {
    color: #333333;
  }
  .min-w-400 {
    min-width: 400px;
  }
  .w-400 {
    width: 400px;
  }
  .overflow-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .overflow-scroll::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1439px) {
    .min-w-400 {
      min-width: 230px;
    }
    .w-400 {
      width: 230px;
    }
  }
  @media only screen and (min-width: 576px) {
    .mbl-workshop-contentCreator, .mbl-viewCardDetails
    {
      display: none!important;
    }
  }
  @media only screen and (max-width: 576px) {
    .max-dev-contentCreator, .max-dev-workshopDate, .max-dev-workshopType
    {
      display: none!important;
    }
    .mbl-dev-LoadingState {
      padding: 16px!important;
    }
    .mbl-WCL-heading, .preqTextDiv {
      font-size: 14px;
      padding-bottom: 0!important;
      // margin-bottom: 1rem!important;
    }
    .WorkshopViewTitleText, .hurryText {
      font-size: 12px!important;
    }
    .workshoptypeText {
      font-size: 12px!important;
      color: #333!important;
      font-weight: 800!important;
    }
    .mbl-workshopType-txt {
      font-size: 14px!important;
      font-weight: 800;
      margin-right: 5px;
    }
    .ContentDiv {
      width: 85px;
      height: 84px;
    }
    .ContentImg {
      margin: 4px;
      width: 50px;
      height: 50px;
    }
    .contentName {
      font-size: 10px;
      padding: 2px;
      width: auto;
    }
    .clickDetail, .arrowIcon {
      font-weight: 500;
      font-size: 14px;
      color: #57479C;
    }
  }
  ${props =>
    props.loading &&
    css`
      .heading,
      .subHeading,
      .difficultyContainer,
      .floatingContainer {
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        border-radius: 50px;
        height: 16px;
        background-size: 400% 100%;
        margin-bottom: 10px;
        animation: ${LoadingAnimation} 1.4s ease infinite;
      }
      .difficultyContainer {
        width: 25%;
        margin-top: -8px !important;
      }
      .subHeading {
        width: 75%;
      }
    `}
`
const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 6px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    }
    padding: 0;
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
    color: #57479c;
    // max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`
const Tag = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  margin-bottom: 8px;

  font-weight: 600;
  line-height: 16px;
  /* min-width:80px; */
  text-align: center;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  ${props =>
    props.grey &&
    css`
      color: #333333;
      font-family: Avenir;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      background: #f2f2f2;
      border-radius: 2px;
    `}
  ${props =>
    props.loading &&
    css`
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      border-radius: 50px;
      height: 16px;
      background-size: 400% 100%;
      margin-bottom: 10px;
      animation: ${LoadingAnimation} 1.4s ease infinite;
      border: none;
      width: 45%;
    `}
`
const CounterContainer = styled.div`
  background: #36cfc9;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  left: 5px;
  top: 10px;
  /* identical to box height, or 171% */

  color: rgba(255, 255, 255, 0.97);
`
const PopoverContentStyle = styled.div`
  position: absolute;
  bottom: 150%;
  left: 0%;
  .arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: -3px;
    border-top-color: transparent;
    border-right-color: #000000;
    border-bottom-color: #000000;
    border-left-color: transparent;
    -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    /* left: 16px; */
    left: 84px;
    /* background-color: #000000; */
  }
  .popoverInner {
    background-color: #000000;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-clip: padding-box;
    color: #fff;
    font-size: 12px;
    min-width: 220px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`
const PopoverContent = props => {
  return (
    <PopoverContentStyle>
      <div className='arrow' />
      <div className='popoverInner'>
        Based on age group selected, cards will be displayed in iPad
        {props.data.Easy.length !== 0 && (
          <div>{`${props.defaultActivitiesId !== 2 ? 'Easy: ' : ''
            }${props.data.Easy.map(data => data).join(', ')}`}</div>
        )}
        {props.defaultActivitiesId !== 2 && (
          <React.Fragment>
            {props.data.Medium.length !== 0 && (
              <div>{`Medium: ${props.data.Medium.map(data => data).join(
                ', '
              )}`}</div>
            )}
            {props.data.Hard.length !== 0 && (
              <div>{`Hard: ${props.data.Hard.map(data => data).join(
                ', '
              )}`}</div>
            )}
          </React.Fragment>
        )}
        {/* <div>Medium: 7 - 8 years</div> */}
      </div>
    </PopoverContentStyle>
  )
}

class WorkshopParentCard extends Component {
  state = {
    visible: false,
    TagInWidth: 0.0
  }

  setVisible = value => {
    this.setState({ visible: value })
  }

  addWidth = width => {
    // console.log("Width ==> ", width);
    // this.setState({
    //   TagInWidth: this.state.TagInWidth + width
    // })
  }

  render() {
    const DateView = ({ date, time, every, duration }) => {
      return (
        <DateContainer className='d-flex'>
          {every && every.length && (
            <div className='d-flex'>
              <div className='subTitle'>{every}</div>
              <div className='title-border' />
            </div>
          )}
          <div>
            <h4 className='title white-space-nowrap'>
              {date ? Moment.utc(parseInt(date)).format(`ddd, Do MMM ’YY`) : ''}
            </h4>
            <div className='desc'>
              {time ? Moment(time, 'h m a').format('h:mm A') : ''}
              {duration ? ` - ${duration}` : ``}
            </div>
          </div>
        </DateContainer>
      )
    }

    const workshopDateList =
      this.props.parentData && this.props.parentData.workshop_plan_id
        ? this.props.parentData.Dates && this.props.parentData.Dates.length > 0
          ? this.props.parentData.Dates
          : []
        : []

    return (
      <div>
        <CardContainer
          floatingContainerLeft={this.props.floatingContainerLeft}
          rounded={this.props.rounded}
          loading={this.props.isLoading}
        >
          <div className='position-relative'>
            {this.props.close && (
              <div onClick={this.props.onClose}>
                <img className='closeContainer img-fluid' src={Close} />
              </div>
            )}
            {this.props.counter && (
              <CounterContainer>{this.props.counter}</CounterContainer>
            )}
          </div>
          <CardBody
            loading={this.props.isLoading}
            onClick={() =>
              this.props.history.push(`/parent/workshops/${this.props.id}/view`)
            }
          >
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-sm-12 col-xs-12 pr-0 mbl-dev-LoadingState'>
                {!this.props.isLoading && (
                  <Img
                    className='ImgCard h-100 w-100'
                    // src={this.props.src}
                    src={`${process.env.REACT_APP_IMAGE_API}/480x360/${this.props.src.substr(this.props.src.lastIndexOf("/") + 1)}`}
                    loader={<div className='ImgCard w-100 h-100 skeleton' />}
                    unloader={
                      <img
                        className='ImgCard w-100 h-100'
                        src={ImgPlaceholder}
                        alt='workshop content'
                      />
                    }
                  />
                )}
                {this.props.isLoading && (
                  <div className='ImgCard w-100 h-100 skeleton' />
                )}
              </div>
              <div className='col-lg-5 col-xl-5 col-sm-12 col-xs-12 pr-0 d-flex flex-column align-items-start'>
                {this.props.isLoading && (
                  <div className='pl-md-3 d-flex align-items-end justify-content-between w-100'>
                    <div className='pt-1 pt-md-0'>
                      <h4 className='ttitle d-none d-md-block skeleton'></h4>
                      <div className='mt-2 d-flex align-items-center justify-content-center w-100 w-md-100 min-w-300 mbl-min-w-300'>
                        <div className='pr-2'>
                          <div className='desc text-center skeleton w-150px mbl-w-90'></div>
                        </div>
                        <div className='pr-2'>
                          <div className='desc text-center skeleton w-100px mbl-w-90'></div>
                        </div>
                        <div className='pr-2'>
                          <div className='desc text-center skeleton w-100px mbl-w-90'></div>
                        </div>
                      </div>
                      <div className='mt-3'>
                        <div className='desc text-center skeleton w-300px'></div>
                      </div>
                    </div>
                  </div>
                )}
                {!this.props.isLoading && (
                  <React.Fragment>
                    <div className='heading mbl-WCL-heading mb-2'>
                      {this.props.title && (
                        <LinesEllipsis
                          style={{ whiteSpace: 'pre-wrap' }}
                          text={this.props.title}
                          trimRight={true}
                          maxLine='5'
                          ellipsis='...'
                          lineHeight='16'
                          basedOn='letters'
                        />
                      )}
                    </div>
                    {this.props.InterestedChild && (
                      <div className='d-flex preqTextDiv mb-2'>
                        <div className='preqTextBord'></div>
                        <span style={{ padding: 5 }}>
                          Interest shown by&nbsp;
                          <span className='preqText'>
                            {this.props.InterestedChild &&
                              this.props.InterestedChild.length > 0
                              ? this.props.InterestedChild.map(
                                x => x.username
                              ).join(', ')
                              : ''}
                          </span>
                        </span>
                      </div>
                    )}
                    {this.props.RegisteredChild && this.props.titleID == 8 && (
                      <div className='d-flex preqTextDiv mb-2'>
                        <div className='preqTextBord'></div>
                        <span style={{ padding: 5 }}>
                          Registered for&nbsp;
                          <span className='preqText'>
                            {this.props.RegisteredChild &&
                              this.props.RegisteredChild.length > 0
                              ? this.props.RegisteredChild.map(
                                x => x.username
                              ).join(', ')
                              : ''}
                          </span>
                        </span>
                      </div>
                    )}
                    {this.props.AttendedChild && this.props.titleID == 7 && (
                      <div className='d-flex preqTextDiv mb-2'>
                        <div className='preqTextBord'></div>
                        <span style={{ padding: 5 }}>
                          Attended by&nbsp;
                          <span className='preqText'>
                            {this.props.AttendedChild &&
                              this.props.AttendedChild.length > 0
                              ? this.props.AttendedChild.map(
                                x => x.username
                              ).join(', ')
                              : ''}
                          </span>
                        </span>
                      </div>
                    )}
                    <div className='d-flex flex-row mbl-WCL-heading'>
                      <div className='workCol'>
                        <div className='WorkshopViewTitleText'>AGE GROUP</div>
                        <div className='workshoptypeText'>
                          {this.props.parentData.age_group
                            ? this.props.parentData.age_group
                            : ''}
                        </div>
                      </div>
                      <div className='workCol'>
                        <div className='WorkshopViewTitleText'>SESSIONS</div>
                        <div className='workshoptypeText'>
                          {this.props.parentData.workshop_session
                            ? this.props.parentData.workshop_session
                            : ''}
                        </div>
                      </div>
                      {this.props.parentData && (
                        <div className='workCol'>
                          <div className='WorkshopViewTitleText'>DURATION</div>
                          <div className='workshoptypeText'>
                            {this.props.parentData.workshop_plan_id != 3 ? (
                              <span>
                                {this.props.parentData &&
                                  this.props.parentData.workshop_duration
                                  ? `${this.props.parentData.workshop_duration}`
                                  : ''}
                                {/* {this.props.parentData &&
                                this.props.parentData.workshop_duration_type
                                  ? this.props.parentData
                                      .workshop_duration_type == 'hr'
                                    ? 'h'
                                    : ''
                                  : ''} */}
                                {this.props.parentData &&
                                  this.props.parentData.workshop_session
                                  ? this.props.parentData.workshop_session > 1
                                    ? ` each`
                                    : ''
                                  : ''}
                              </span>
                            ) : this.props.parentData.workshop_plan_id == 3 &&
                              this.props.parentData.is_common_timing ? (
                              <span>
                                {this.props.parentData &&
                                  this.props.parentData.workshop_duration
                                  ? `${this.props.parentData.workshop_duration}`
                                  : ''}
                                {/* {this.props.parentData &&
                                this.props.parentData.workshop_duration_type
                                  ? this.props.parentData
                                      .workshop_duration_type == 'hr'
                                    ? 'h'
                                    : ''
                                  : ''} */}
                                {this.props.parentData &&
                                  this.props.parentData.workshop_session
                                  ? this.props.parentData.workshop_session > 1
                                    ? ` each`
                                    : ''
                                  : ''}
                              </span>
                            ) : (
                              `Varies`
                            )}
                          </div>
                        </div>
                      )}
                      <div className="mbl-workshop-contentCreator mr-1">
                        {this.props.isLoading && (
                          <div className='ImgCard w-100 h-100 skeleton' />
                        )}
                        {!this.props.isLoading && (
                          <React.Fragment>
                            <div className='comContentDiv'>
                              <div
                                className='ContentDiv'
                                style={{
                                  backgroundImage: `url(${UserProfile})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  backgroundSize: 'contain'
                                }}
                              >
                                <div>
                                  <img
                                    className='ContentImg'
                                    src={
                                      this.props.user_details
                                        ? this.props.user_details.profile_image
                                        : contentFace
                                    }
                                    alt='Contentcreator-Face'
                                    onError={e => {
                                      e.target.onerror = null
                                      e.target.src = contentFace
                                    }}
                                  />
                                </div>
                                <div className='contentName'>
                                  <LinesEllipsis
                                    style={{ whiteSpace: 'pre-wrap', width: '100%' }}
                                    text={this.props.user_details
                                      ? this.props.user_details.displayName ? this.props.user_details.displayName
                                        : `${this.props.user_details.name}`
                                      : ''}
                                    trimRight={true}
                                    maxLine='1'
                                    ellipsis='...'
                                    // lineHeight='5'
                                    basedOn='letters'
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className='max-dev-workshopType mb-2'>
                      <div>
                        <span
                          className="mbl-workshopType-txt"
                          style={{
                            color: '#42C0EF',
                            textTransform: 'uppercase'
                          }}
                        >
                          {this.props.workshop_type == 'Paid'
                            ? `\u20B9 ${this.props.workshop_amount}`
                            : this.props.workshop_type}
                        </span>
                        <span className='hurryText'>
                          {
                            this.props.parentData.InProgress ? (<span>{' '}Registrations closed!</span>) :
                              !this.props.parentData.workshop_registration_status ? (<span>{' '}Registrations closed!</span>) :
                                this.props.parentData.available_seats ? (
                                  <span>
                                    {this.props.parentData.available_seats ? (
                                      [11, 12, 13, 14, 15].includes(this.props.parentData.available_seats) ? (
                                        <img
                                          className='fireimg'
                                          src={ClockImg}
                                          alt='WorkshopFire'
                                        />
                                      ) : this.props.parentData.available_seats <= 10 ? (
                                        <span>
                                          <img
                                            className='fireimg'
                                            src={FireImg}
                                            alt='WorkshopFire'
                                          />
                                          {`Hurry! `}
                                        </span>
                                      ) : ' '
                                    ) : (
                                      ' '
                                    )}
                                    Only{' '}
                                    {this.props.parentData.available_seats
                                      ? this.props.parentData.available_seats
                                      : ''}{' '}
                                    seat{this.props.parentData.available_seats
                                      ? this.props.parentData.available_seats > 1 ? 's' : ''
                                      : ''} left
                                  </span>
                                ) : this.props.titleID == 7 ? (
                                  ``
                                ) : (
                                  <span style={{ color: '#68B415' }}>
                                    &ensp;We are fully booked!
                                  </span>
                                )}
                        </span>
                      </div>
                    </div>
                    <div className='max-dev-workshopDate flex'>
                      {this.props.parentData &&
                        this.props.parentData.workshop_plan_id &&
                        [1, 2, 3].includes(
                          parseInt(this.props.parentData.workshop_plan_id)
                        ) &&
                        this.props.parentData.repeats_type == 'dnr' &&
                        this.props.parentData.Dates &&
                        this.props.parentData.Dates.length > 0 && (
                          <div
                            className='d-flex flex-row min-w-400 w-400 d-flex overflow-scroll'
                            id={`workshop-${this.props.index}`}
                          >
                            {workshopDateList.map((x, DateIndex) => (
                              <span
                                className='pr-3 d-inline-block'
                                key={`DateIndex-${DateIndex}`}
                              >
                                <div>
                                  <DateView
                                    date={x.workshop_Date}
                                    time={x.start_time}
                                    every={
                                      this.props.parentData.repeatDays
                                        ? this.props.parentData.repeatDays
                                        : ''
                                    }
                                    duration={
                                      this.props.parentData.workshop_plan_id ==
                                        3 &&
                                        !this.props.parentData.is_common_timing
                                        ? x.duration
                                        : ''
                                    }
                                  />
                                </div>
                              </span>
                            ))}
                          </div>
                        )}
                      {this.props.parentData &&
                        this.props.parentData.workshop_plan_id &&
                        (this.props.parentData.workshop_plan_id == 1 ||
                          this.props.parentData.workshop_plan_id == 2) &&
                        this.props.parentData.repeats_type != 'dnr' &&
                        this.props.parentData.Dates &&
                        this.props.parentData.Dates.length > 0 && (
                          <DateContainer className='d-flex'>
                            <div className='d-flex align-items-center'>
                              <div className='subTitle'>
                                {this.props.parentData.repeatDays
                                  ? this.props.parentData.repeatDays
                                  : ''}
                              </div>
                              &nbsp;
                            </div>
                            <div className='title-border' />
                            <div>
                              <h4 className='title white-space-nowrap'>
                                {`
                                    ${this.props.parentData.Dates[0]
                                    .workshop_Date
                                    ? Moment(
                                      parseInt(
                                        this.props.parentData.Dates[0]
                                          .workshop_Date
                                      )
                                    ).format(`MMM DD`)
                                    : ''
                                  } -
                                    ${this.props.parentData.Dates[0]
                                    .workshop_Date
                                    ? Moment(
                                      parseInt(
                                        this.props.parentData.Dates[
                                          this.props.parentData.Dates
                                            .length - 1
                                        ].workshop_Date
                                      )
                                    ).format(`MMM DD, YYYY`)
                                    : ''
                                  }
                                    `}
                              </h4>
                              <div className='desc'>
                                {this.props.parentData.workshop_time
                                  ? Moment(
                                    this.props.parentData.workshop_time,
                                    'h m a'
                                  ).format('h:mm A')
                                  : ''}
                              </div>
                            </div>
                          </DateContainer>
                        )}
                      {/* {workshopDateList.length > 2 && (
                        <img
                          className='ml-2 cursor-pointer'
                          src={dateArrow}
                          alt='arrow'
                          onClick={() => {
                            document
                              .getElementById(`workshop-${this.props.index}`)
                              .scrollTo({
                                left:
                                  document.getElementById(
                                    `workshop-${this.props.index}`
                                  ).scrollLeft + 250,
                                behavior: 'smooth'
                              })
                          }}
                        />
                      )} */}
                    </div>

                    <div className="w-100 d-flex justify-content-between mbl-workshop-contentCreator">
                      <div className="mr-3">
                        <div className='mb-2'>
                          <div>
                            <span
                              className="mbl-workshopType-txt"
                              style={{
                                color: '#42C0EF',
                                textTransform: 'uppercase'
                              }}
                            >
                              {this.props.workshop_type == 'Paid'
                                ? `\u20B9 ${this.props.workshop_amount}`
                                : this.props.workshop_type}
                            </span>
                            <span className='hurryText'>
                              {
                                // this.props.parentData.InProgress ? (<span>{' '}Registrations closed!</span>) :
                                !this.props.parentData.workshop_registration_status ? (<span>{' '}Registrations closed!</span>) :
                                  this.props.parentData.available_seats ? (
                                    <span>
                                      {this.props.parentData.available_seats ? (
                                        [11, 12, 13, 14, 15].includes(this.props.parentData.available_seats) ? (
                                          <img
                                            className='fireimg'
                                            src={ClockImg}
                                            alt='WorkshopFire'
                                          />
                                        ) : this.props.parentData.available_seats <= 10 ? (
                                          <span>
                                            <img
                                              className='fireimg'
                                              src={FireImg}
                                              alt='WorkshopFire'
                                            />
                                            {`Hurry! `}
                                          </span>
                                        ) : ' '
                                      ) : (
                                        ' '
                                      )}
                                      Only{' '}
                                      {this.props.parentData.available_seats
                                        ? this.props.parentData.available_seats
                                        : ''}{' '}
                                      seat{this.props.parentData.available_seats
                                        ? this.props.parentData.available_seats > 1 ? 's' : ''
                                        : ''} left
                                    </span>
                                  ) : this.props.titleID == 7 ? (
                                    ``
                                  ) : (
                                    <span style={{ color: '#68B415' }}>
                                      &ensp;We are fully booked!
                                    </span>
                                  )}
                            </span>
                          </div>
                        </div>
                        <div className='flex'>
                          {this.props.parentData &&
                            this.props.parentData.workshop_plan_id &&
                            [1, 2, 3].includes(
                              parseInt(this.props.parentData.workshop_plan_id)
                            ) &&
                            this.props.parentData.repeats_type == 'dnr' &&
                            this.props.parentData.Dates &&
                            this.props.parentData.Dates.length > 0 && (
                              <div
                                className='d-flex flex-row min-w-400 w-400 d-flex overflow-scroll'
                                id={`workshop-${this.props.index}`}
                              >
                                {workshopDateList.map((x, DateIndex) => (
                                  <span
                                    className='pr-3 d-inline-block'
                                    key={`DateIndex-${DateIndex}`}
                                  >
                                    <div>
                                      <DateView
                                        date={x.workshop_Date}
                                        time={x.start_time}
                                        every={
                                          this.props.parentData.repeatDays
                                            ? this.props.parentData.repeatDays
                                            : ''
                                        }
                                        duration={
                                          this.props.parentData.workshop_plan_id ==
                                            3 &&
                                            !this.props.parentData.is_common_timing
                                            ? x.duration
                                            : ''
                                        }
                                      />
                                    </div>
                                  </span>
                                ))}
                              </div>
                            )}
                          {this.props.parentData &&
                            this.props.parentData.workshop_plan_id &&
                            (this.props.parentData.workshop_plan_id == 1 ||
                              this.props.parentData.workshop_plan_id == 2) &&
                            this.props.parentData.repeats_type != 'dnr' &&
                            this.props.parentData.Dates &&
                            this.props.parentData.Dates.length > 0 && (
                              <DateContainer className='d-flex'>
                                <div className='d-flex align-items-center'>
                                  <div className='subTitle'>
                                    {this.props.parentData.repeatDays
                                      ? this.props.parentData.repeatDays
                                      : ''}
                                  </div>
                                  &nbsp;
                                </div>
                                <div className='title-border' />
                                <div>
                                  <h4 className='title white-space-nowrap'>
                                    {`
                                      ${this.props.parentData.Dates[0]
                                        .workshop_Date
                                        ? Moment(
                                          parseInt(
                                            this.props.parentData.Dates[0]
                                              .workshop_Date
                                          )
                                        ).format(`MMM DD`)
                                        : ''
                                      } -
                                      ${this.props.parentData.Dates[0]
                                        .workshop_Date
                                        ? Moment(
                                          parseInt(
                                            this.props.parentData.Dates[
                                              this.props.parentData.Dates
                                                .length - 1
                                            ].workshop_Date
                                          )
                                        ).format(`MMM DD, YYYY`)
                                        : ''
                                      }
                                      `}
                                  </h4>
                                  <div className='desc'>
                                    {this.props.parentData.workshop_time
                                      ? Moment(
                                        this.props.parentData.workshop_time,
                                        'h m a'
                                      ).format('h:mm A')
                                      : ''}
                                  </div>
                                </div>
                              </DateContainer>
                            )}
                          {/* {workshopDateList.length > 2 && (
                          <img
                            className='ml-2 cursor-pointer'
                            src={dateArrow}
                            alt='arrow'
                            onClick={() => {
                              document
                                .getElementById(`workshop-${this.props.index}`)
                                .scrollTo({
                                  left:
                                    document.getElementById(
                                      `workshop-${this.props.index}`
                                    ).scrollLeft + 250,
                                  behavior: 'smooth'
                                })
                            }}
                          />
                        )} */}
                        </div>
                      </div>

                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className='max-dev-contentCreator col-xl-3 col-lg-3 pr-lg-0 pl-lg-0'>
                {this.props.isLoading && (
                  <div className='ImgCard w-100 h-100 skeleton' />
                )}
                {!this.props.isLoading && (
                  <React.Fragment>
                    <div className='mr-2 comContentDiv'>
                      <div
                        className='ContentDiv'
                        style={{
                          backgroundImage: `url(${UserProfile})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain'
                        }}
                      >
                        <div>
                          <img
                            className='ContentImg'
                            src={
                              this.props.user_details
                                ? this.props.user_details.profile_image
                                : contentFace
                            }
                            alt='Contentcreator-Face'
                            onError={e => {
                              e.target.onerror = null
                              e.target.src = contentFace
                            }}
                          />
                        </div>
                        <div className='contentName'>
                          <LinesEllipsis
                            style={{ whiteSpace: 'pre-wrap', width: '100%' }}
                            text={this.props.user_details
                              ? this.props.user_details.displayName ? this.props.user_details.displayName
                                : `${this.props.user_details.name}`
                              : ''}
                            trimRight={true}
                            maxLine='1'
                            ellipsis='...'
                            // lineHeight='5'
                            basedOn='letters'
                          />

                        </div>
                      </div>
                      <div className='ContentDiv1'>
                        <div className='clickDiv'>
                          <span
                            className='clickDetail'
                          // onClick={() =>
                          //   this.props.history.push(
                          //     `workshops/${this.props.id}/view`
                          //   )
                          // }
                          >
                            View workshop details{' '}
                            <Icon className='arrowIcon' type='right' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            {/* {this.props.isLoading && (
                  <div className='ImgCard w-100 h-100 skeleton' />
                )} */}
            {!this.props.isLoading && (
              <div className="mbl-viewCardDetails">
                <div className='ContentDiv1'>
                  <div className='clickDiv'>
                    <span
                      className='clickDetail'
                    >
                      View workshop details{' '}
                      <Icon className='arrowIcon' type='right' />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </CardBody>
        </CardContainer>
      </div>
    )
  }
}

WorkshopParentCard.propTypes = {
  src: PropTypes.string,
  videoRefIcon: PropTypes.bool,
  imgRefIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  stepsRefIcon: PropTypes.bool,
  pdfRefIcon: PropTypes.bool,
  rounded: PropTypes.bool,
  floatingContainer: PropTypes.bool,
  floatingContainerLeft: PropTypes.bool,
  close: PropTypes.bool,
  Tag: PropTypes.array,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool
}

export default WorkshopParentCard
