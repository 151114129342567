import { Card, Icon } from 'antd'
import axios from 'axios'
import * as firebase from 'firebase/app'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import '../../App.css'
import arrowLeft from '../../assets/arrow_left.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import BGLogo from '../../assets/login_bg.png'
import BGLogoMax from '../../assets/login_bg_max.png'
import mblbglogo from '../../assets/mblBGlogo.svg'
import SocialLoginButton from '../../components/ButtonC/SocialLoginButton'
import { CHECKEMAIL, LOGIN } from '../../graphql.js'
import Email from '../../static/Email.svg'
import FacebookIcon from '../../static/FacebookIcon.svg'
import { openNotification, triggerGAEvent } from '../../utils'
import './Signin.css'

const MblCard = styled.div`
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  @media (max-width: 576px) {
    .mbl-cardStart {
      display: none;
    }
  }
  @media (max-width: 900px) {
    background: url(${mblbglogo}) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    .card-wrap {
      height: unset;
    }
    /* .mbl-cardStart {
      display: none;
    } */
    .btn-social {
      display: inline-flex !important;
    }
    .mblSignIn {
      width: 90% !important;
      max-height: 95% !important;
      height: 95% !important;
    }
    justify-content: center !important;
    .card-w-auto {
      height: 100% !important;
    }
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .card-w-auto {
      width: 515px !important;
      height: 650px !important;
    }
  }
  @media (min-width: 1440px) {
    .card-w-auto {
      width: 540px !important;
      height: 700px !important;
    }
    .lg-mr-6 {
      margin-right: 6rem !important;
    }
  }
  @media (min-width: 1500px) {
    background: url(${BGLogoMax});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .card-w-auto {
      width: 565px !important;
    }
    .lg-mr-6 {
      margin-right: 8.5rem !important;
    }
  }
  background: url(${BGLogo});
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  /* background-position: center; */
  background-size: cover ;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  /* margin-bottom: 0; */
  border-radius: 4px;
  margin: 0 auto;
`
const StickTxt = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 12px;
  justify-content: space-around;
  align-items: center;
  .fTxt {
    font-family: 'Bariol';
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }
  .btn-Txt > a {
    font-family: 'Bariol';
    font-size: 16px;
    color: #0076ff;
  }
`

const StickTxt1 = styled.div`
  display: flex;
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 5px;
  .fTxt {
    font-family: 'Bariol';
    font-size: 14px;
    font-weight: 400;
    color: #00;
  }
  .btn-Txt > a {
    font-family: 'Bariol';
    font-size: 14px;
    color: #0076ff;
  }
  .bg-btn {
    padding: 2px 10px !important;
}
`

class SocialSignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      login: {
        email: '',
        password: ''
      },
      form: React.createRef(),
      showHide: {
        password: false
      }
    }
  }

  loginWithGoogle = () => {
    this.setState({ login_type: 'google' }, () => {
      const user = firebase.auth().currentUser
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(res => {
          res.user
            .getIdToken(true)
            .then(resp => {
              // this.userLogin(resp);
              let mutation = CHECKEMAIL
              mutation.variables = {
                email: res.additionalUserInfo.profile.email,
                loginType: this.state.login_type
              }
              axios
                .post(`${process.env.REACT_APP_API}`, mutation)
                .then(resl => {
                  if (resl.data.errors) {
                    triggerGAEvent('Login', 'Logging in error')
                    openNotification('error', resl.data.errors[0].message)
                  } else {
                    if (resl.data.data.checkParentEmail.is_available) {
                      triggerGAEvent('Login', 'Logging in error')
                      localStorage.setItem(
                        'token',
                        resl.data.data.checkParentEmail.token
                      )
                      localStorage.setItem(
                        'role_id',
                        resl.data.data.checkParentEmail.role_id
                      )
                      localStorage.setItem(
                        'isChild',
                        resl.data.data.checkParentEmail.is_childAdded
                          ? resl.data.data.checkParentEmail.is_childAdded
                          : false
                      )
                      this.props.history.push('/parent/workshops')
                    } else {
                      this.props.history.push({
                        pathname: '/socialAccountSetup',
                        state: {
                          token: resp,
                          userData: res.additionalUserInfo.profile,
                          signupType: 'google'
                        }
                      })
                    }
                  }
                })
                .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    })
  }

  loginWithFacebook = () => {
    this.setState({ login_type: 'facebook' }, () => {
      var user = firebase.auth().currentUser
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(res => {
          res.user
            .getIdToken(true)
            .then(resp => {
              // this.userLogin(resp)
              // this.userLogin(resp);
              let mutation = CHECKEMAIL
              mutation.variables = {
                email: res.additionalUserInfo.profile.email,
                loginType: this.state.login_type
              }
              axios
                .post(`${process.env.REACT_APP_API}`, mutation)
                .then(resl => {
                  if (resl.data.errors) {
                    triggerGAEvent('Login', 'Logging in error')
                    openNotification('error', resl.data.errors[0].message)
                  } else {
                    console.log(resl.data.data.checkParentEmail)
                    if (resl.data.data.checkParentEmail.is_available) {
                      triggerGAEvent('Login', 'Logging in error')
                      localStorage.setItem(
                        'token',
                        resl.data.data.checkParentEmail.token
                      )
                      localStorage.setItem(
                        'role_id',
                        resl.data.data.checkParentEmail.role_id
                      )
                      localStorage.setItem(
                        'isChild',
                        resl.data.data.checkParentEmail.is_childAdded
                          ? resl.data.data.checkParentEmail.is_childAdded
                          : false
                      )
                      this.props.history.push('/parent/workshops')
                    } else {
                      this.props.history.push({
                        pathname: '/socialAccountSetup',
                        state: {
                          token: resp,
                          userData: res.additionalUserInfo.profile,
                          signupType: 'facebook'
                        }
                      })
                    }
                  }
                })
                .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
        })
        .catch(function (error) {
          console.log('error ', error)
          console.log('User ', user)
          // An error happened.
          if (error.code === 'auth/account-exists-with-different-credential') {
            var pendingCred = error.credential
            if (user) {
              user
                .linkWithPopup(new firebase.auth.FacebookAuthProvider())
                .then(result => {
                  console.log('result ', result)
                  //     var credential = result.credential;
                  //     var User = result.user;
                  // User.getIdToken(true)
                  // .then(async resp => {
                  //       console.log("resp token ", resp);
                  //       await this.userLogin(resp);
                  // })
                })
                .catch(error => {
                  console.log('linkwithpopup error ', error)
                })
            }
            // firebase.auth().currentUser.linkWithCredential(pendingCred)
            // .then((usercred) => {
            //   console.log("userCred ", usercred);
            //   if (usercred) {
            //    var User = usercred.user;
            //     User.getIdToken(true)
            //             .then(async resp => {
            //               console.log("resp token ", resp);
            //               await this.userLogin(resp);
            //             })
            //   }
            // }).catch((error) => {
            //   console.log("Account linking error", error);
            // });
          }
        })
    })
  }

  loginWithApple = () => {
    this.setState({ login_type: 'apple' }, () => {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.OAuthProvider('apple.com'))
        .then(res => {
          res.user
            .getIdToken(true)
            .then(resp => {
              // this.userLogin(resp)
              // this.userLogin(resp);
              let mutation = CHECKEMAIL
              mutation.variables = {
                email: res.additionalUserInfo.profile.email,
                loginType: this.state.login_type
              }
              axios
                .post(`${process.env.REACT_APP_API}`, mutation)
                .then(resl => {
                  if (resl.data.errors) {
                    triggerGAEvent('Login', 'Logging in error')
                    openNotification('error', resl.data.errors[0].message)
                  } else {
                    console.log(resl.data.data.checkParentEmail)
                    if (resl.data.data.checkParentEmail.is_available) {
                      triggerGAEvent('Login', 'Logging in error')
                      localStorage.setItem(
                        'token',
                        resl.data.data.checkParentEmail.token
                      )
                      localStorage.setItem(
                        'role_id',
                        resl.data.data.checkParentEmail.role_id
                      )
                      localStorage.setItem(
                        'isChild',
                        resl.data.data.checkParentEmail.is_childAdded
                          ? resl.data.data.checkParentEmail.is_childAdded
                          : false
                      )
                      this.props.history.push('/parent/workshops')
                    } else {
                      this.props.history.push({
                        pathname: '/socialAccountSetup',
                        state: {
                          token: resp,
                          userData: res.additionalUserInfo.profile,
                          signupType: 'apple'
                        }
                      })
                    }
                  }
                })
                .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    })
  }

  loginWithEmailAndPassword = () => {
    this.setState({ login_type: 'email' }, () => {
      this.userLogin()
    })
  }

  userLogin = (id_token = '') => {
    let mutation = LOGIN
    mutation.variables = {
      email: this.state.login.email,
      password: this.state.login.password,
      id_token: id_token,
      login_type: this.state.login_type
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          triggerGAEvent('Login', 'Logging in error')
          openNotification('error', res.data.errors[0].message)
        } else {
          if (res.data.data.login !== null) {
            let loginData = res.data.data.login
            if (loginData.error && loginData.is_email) {
              this.props.history.push({
                pathname: '/signup',
                state: { is_email: loginData.is_email }
              })
            } else if (loginData.error && !loginData.is_email) {
              triggerGAEvent('Login', 'Logging in error')
              openNotification('error', loginData.msg)
            } else {
              localStorage.setItem(
                'token',
                res.data.data.login.user_details.token
              )
              localStorage.setItem(
                'role_id',
                res.data.data.login.user_details.role_id
              )
              if (res.data.data.login.user_details.role_id === '1') {
                triggerGAEvent('Login', 'Logging in as Breadcrumb Admin')
                this.props.history.push('/dashboard')
              } else if (res.data.data.login.user_details.role_id === '2') {
                triggerGAEvent('Login', 'Logging in as Learning centre')
                this.props.history.push('/dashboard')
              } else if (res.data.data.login.user_details.role_id === '3') {
                triggerGAEvent('Login', 'Logging in as Parent')
                this.props.history.push(
                  `/parent/workshops${res.data.data.login.user_details.isNewLogin
                    ? '?isTour=1'
                    : ''
                  }`
                )
              } else if (res.data.data.login.user_details.role_id === '4') {
                triggerGAEvent('Login', 'Logging in as Expert')
                this.props.history.push('/learningcontent')
              } else if (res.data.data.login.user_details.role_id === '5') {
                triggerGAEvent('Login', 'Logging in as Curator')
                this.props.history.push('/learningcontent')
              }
            }
          } else {
          }
        }
      })
      .catch(err => console.log(err))
  }

  onLoginBtnClick = () => {
    // e.preventDefault();
    this.setState({ isSubmit: true })
    this.state.form.current.validateAll()
    setTimeout(() => {
      if (document.querySelectorAll('.form-error').length === 0) {
        this.loginWithEmailAndPassword()
      }
    }, 1)
  }

  onChange = (key, value) => {
    triggerGAEvent('Login', 'Enter data for Login')
    const state = { ...this.state }
    state.login[key] = value
    this.setState({ login: state.login })
  }

  showPwdClick = key => {
    triggerGAEvent('Login', 'Show password in Login')
    const state = { ...this.state }
    state.showHide[key] = !state.showHide[key]
    this.setState({ showHide: state.showHide })
  }

  onKeyPress = e => {
    let key_code = e.keyCode ? e.keyCode : e.which
    if (key_code === 13) {
      this.onLoginBtnClick()
    }
  }

  onKeyDownClick = e => {
    if (e.key === 'Enter') {
      this.onLoginBtnClick()
    }
  }

  render() {
    return (
      <MblCard className='card-wrap d-flex justify-content-end'>
        <div className='mbl-cardStart lg-mr-6 '>
          {/* <img src={logo} className="logo" alt="logo" /> */}
          <Card
            className='mbl-card-radius d-flex flex-column align-items-center justify-content-between card-w-auto'
            style={{ width: 510, height: 700 }}
          >
            <div className='d-flex justify-content-center mt-3'>
              <img src={mbllogo} className='mbl-logo' alt='logo' />
            </div>
            <h3 className='mbl-fontTxt1 mt-4 FontBariolHeavy text-center'>
              Sign up
            </h3>
            <div className='max-dev-stickTxt'>
              <StickTxt>
                <div className='fTxt'>Already a member on Breadcrumb?</div>
                <div className='btn-Txt bg-btn'>
                  <Link to='/'>
                    <span
                      className='font-16 FontBariol'
                      style={{
                        color: '#4C39B3',
                        textDecoration: 'none',
                        fontWeight: 800
                      }}
                    >
                      Sign in
                    </span>
                  </Link>
                </div>
              </StickTxt>
            </div>
            <SocialLoginButton
              icon={<Icon type='google' theme='outlined' />}
              buttonColor='#EB4335'
              buttonText='Sign up with Google'
              onClick={this.loginWithGoogle}
              className='btn-social'
            />
            <SocialLoginButton
              icon={
                <img
                  src={FacebookIcon}
                  alt='facebook icon'
                  height='21px'
                  width='21px'
                />
              }
              buttonColor='#3B5998'
              buttonText='Sign up with Facebook'
              onClick={this.loginWithFacebook}
              className='btn-social'
            />
            <SocialLoginButton
              icon={<Icon type='apple' theme='filled' />}
              buttonColor='#000000'
              buttonText='Sign up with Apple'
              onClick={this.loginWithApple}
              iconTheme='filled'
              className='btn-social'
            />
            <SocialLoginButton
              icon={<img src={Email} alt='mail-logo' />}
              buttonColor='#BE0000'
              buttonText='Sign up with Email'
              onClick={() => this.props.history.push('/accountSetup')}
              iconTheme='filled'
              className='btn-social'
            />
            {/* <div className="ORTXT d-flex align-items-center justify-content-between">
              <hr className="mr-4 w-100" />
              <h3 className="FontAvenirHeavy font-18 m-0 mr-4 text-muted">
                Or
              </h3>
              <hr className="mr-2 w-100" />
            </div> */}
            {/* <Form
              className={this.state.isSubmit ? "formSubmitted mt-5" : "mt-5"}
              ref={this.state.form}
              onSubmit={this.onLoginBtnClick}
            >
              <InputC
                className="col-12 ant-input ant-input-lg mb-4"
                stateKey="email"
                onChange={this.onChange}
                placeholder="Enter your Email ID"
                validations={[required, email]}
                autoFocus
                onKeyPress={this.onKeyPress}
              />
              <InputC
                type="password"
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.state.login.password}
                stateKey="password"
                showPwd={this.state.showHide.showpwd}
                onChange={this.onChange}
                placeholder="Enter your password"
                showPwdClick={this.showPwdClick}
                showPwdKey="showpwd"
                validations={[required]}
                onKeyPress={this.onKeyPress}
                onKeyDown={this.onKeyDownClick}
                name="login"
              />
              <div className="d-flex align-items-center justify-content-between max-btn-hide mt-3 mb-3">
              <div>
                <Link to="/forgotpassword">
                  <div className="d-flex font-13 align-items-center justify-content-center FontAvenirMedium"
                    style={{
                      color: '#0076FF'
                    }}
                  >
                    Forgot Password?
                </div>
                </Link>
              </div>
              <div>
                <Button
                  type="primary"
                  block
                  size="large"
                  style={{
                    width: 162
                  }}
                  onClick={this.onLoginBtnClick}
                >
                  Sign in
                </Button>
              </div>
            </div>
            <Button
                type="primary"
                block
                size="large"
                className="mbl-btn-hide"
                onClick={this.onLoginBtnClick}
              >
                Sign in
            </Button>
            </Form> */}
            <div className='d-flex justify-content-center mbl-btn-hide'>
              <Link to='/forgotpassword'>
                <div className='d-flex font-16 align-items-center justify-content-center FontAvenirMedium mt-3'>
                  Forgot Password?
                </div>
              </Link>
            </div>
            {/* <div className="lap-footer">
              <div className="FontAvenirMedium font-16 m-0 mr-4 text-muted text-center mt-3">
                Don’t have an account?
                <Link to="/signup">
                  <span className="ml-1 font-16 FontAvenirMedium mt-3 text-uppercase font-weight-bold" style={{ textDecoration: 'underline', fontWeight: 900 }}>
                    Sign up
                  </span>
                </Link>
              </div>
            </div> */}
          </Card>
        </div>
        {/* Mbl signin page */}
        <div className='mblSignIn'>
          <div
            className='mbl-card-radius card-w-auto'
            style={{
              padding: 16
            }}
          >
            <div className='d-flex pt-4 mb-2'>
              <div className='mr-2 p-2'>
                <img
                  src={arrowLeft}
                  className='cursor-pointer'
                  alt='left arrow'
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className='ml-2'>
                <img
                  src={mbllogo}
                  style={{
                    height: 30
                  }}
                  alt='logo'
                />
              </div>
            </div>
            <div className='pt-2 pb-2 mb-4 FontBariolHeavy text-center' style={{
              color: '#000',
              fontSize: 28
            }}>
              Sign up
            </div>
            <StickTxt1 className="mb-4">
              <div className='fTxt'>Already a member on Breadcrumb?</div>
              <div className='btn-Txt bg-btn'>
                <Link to='/'>
                  <span
                    className='font-16 FontBariol'
                    style={{
                      color: '#4C39B3',
                      textDecoration: 'none',
                      fontWeight: 800
                    }}
                  >
                    Sign in
                  </span>
                </Link>
              </div>
            </StickTxt1>
            <SocialLoginButton
              icon={<Icon type='google' theme='outlined' />}
              buttonColor='#EB4335'
              buttonText='Sign up with Google'
              onClick={this.loginWithGoogle}
              className='btn-social'
            />
            <SocialLoginButton
              icon={
                <img
                  src={FacebookIcon}
                  alt='facebook icon'
                  height='21px'
                  width='21px'
                />
              }
              buttonColor='#3B5998'
              buttonText='Sign up with Facebook'
              onClick={this.loginWithFacebook}
              className='btn-social'
            />
            <SocialLoginButton
              icon={<Icon type='apple' theme='filled' />}
              buttonColor='#000000'
              buttonText='Sign up with Apple'
              onClick={this.loginWithApple}
              iconTheme='filled'
              className='btn-social'
            />
            <SocialLoginButton
              icon={<img src={Email} alt='mail-logo' />}
              buttonColor='#BE0000'
              buttonText='Sign up with Email'
              onClick={() => this.props.history.push('/accountSetup')}
              iconTheme='filled'
              className='btn-social'
            />
            <div className='mb-4'></div>
          </div>
        </div>
      </MblCard>
    )
  }
}

export default SocialSignUp
