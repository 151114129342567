import {
  Button, DatePicker,
  Icon, Modal, Tooltip
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Form from 'react-validation/build/form'
import styled from 'styled-components'
import '../../App.css'
import AccountLogo from '../../assets/Account_created.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import InputC from '../../components/InputC'
import { ADDCHILD } from '../../graphql'
import BGBLANKLOGO from '../../static/nav/bg_blank_img.svg'
import MBLBGBLANKLOGO from '../../static/nav/mbl_bg_blank_logo.svg'
import { openNotification, triggerGAEvent } from '../../utils'
import { isRequired } from '../../validation'
import './Signin.css'
const { confirm } = Modal

const MaxChildSetup = styled.div`
  @media (max-width: 576px) {
    display: none !important;
  }
  .pt-c {
    padding-top: 6.5rem !important;
  }
  @media (min-width: 1500px) {
    .pt-c {
      padding-top: 15rem !important;
    }
  }
  height: 100vh;
  background: url(${BGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .has-error-c {
    border: 1px solid #f5222d;
    border-radius: 6px;
  }
`

const MblChildSetup = styled.div`
  background: url(${MBLBGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .cardd1 {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 12px;
    padding: 40px;
  }
  .has-error-c {
    border: 1px solid #f5222d;
    border-radius: 6px;
  }
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  @media (max-width: 576px) {
    background: #f9fafb;
    height: 100vh;
    .mblSignIn {
      width: 90% !important;
    }
    .cusColor .ant-radio-checked .ant-radio-inner {
      border-color: #4c39b3 !important;
    }
    .cusColor > .ant-radio-checked .ant-radio-inner::after {
      background-color: #4c39b3 !important;
    }
    .mbl-cardStart {
      display: none;
    }
    justify-content: center !important;
    .navSticky {
      top: 0;
      z-index: 1000;
    }
    .btnBottomSticky {
      box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
      background: #fff;
      position: absolute;
      bottom: 0;
      padding: 16px;
      z-index: 1000;
      width: 100%;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  border-radius: 4px;
  margin: 0 auto;
`
class ChildAccountSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      whatsappVal: false,
      login: {
        first_name: '',
        last_name: '',
        username: '',
        dob: ''
      },
      truthObj: {
        first_name: false,
        last_name: false,
        username: false,
        dob: false
      },
      form: React.createRef(),
      submitted: false,
      showHide: {
        password: false
      }
    }
  }
  childSetup = () => {
    let mutation = ADDCHILD
    mutation.variables = {
      first_name: this.state.login.first_name,
      last_name: this.state.login.last_name,
      user_name: this.state.login.username,
      dob: this.state.login.dob
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          triggerGAEvent('Signup', 'Setup child account with Parent')
          let { truthObj } = this.state
          truthObj['username'] = true
          openNotification('error', res.data.errors[0].message)
          this.setState({
            truthObj
          })
        } else {
          let result = res.data.data.addChild
          if (result.student_id) {
            localStorage.setItem(
              'isChild',
              result.is_childAdded ? result.is_childAdded : false
            )
            localStorage.setItem(
              'isAddChild',
              result.child_count ? result.child_count : false
            )
            this.showConfirm()
          }
        }
      })
      .catch(err => console.log(err))
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.login)
    getKeys.forEach((key, index) => {
      let getItem = this.state.login[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please fill all mandatory fields correctly')
      this.setState({ isFormSubmitting: false })
    } else {
      this.childSetup()
    }
  }

  updateParentData = (key, value) => {
    let login = { ...this.state.login }
    login[key] = value
    this.setState({
      login
    })
  }

  disableDobHandler = current => {
    return current.isAfter(moment())
  }

  dobChangeHandler = (date, dateString) => {
    let { login } = this.state
    if (date) {
      if (date.isBefore(moment().subtract(4, 'years'))) {
        login.dob = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        this.setState({
          login
        })
      } else {
        openNotification(
          'customError',
          'The minimum age for a learner to join Breadcrumb is 4',
          'Uh oh! Something needs to be fixed'
        )
      }
    } else {
      login.dob = ''
      this.setState({
        login
      })
    }
  }

  showConfirm = () => {
    let confirmPopup = confirm({
      icon: null,
      content: (
        <React.Fragment>
          <div className='d-flex align-items-center flex-column'>
            <div>
              <img src={AccountLogo} className='' alt='logo' />
            </div>
            <div className='mt-4 mb-2'>
              <h5
                style={{
                  color: '#111827',
                  fontSize: 22,
                  fontWeight: 800
                }}
              >
                Child account created!
              </h5>
            </div>
            <div
              style={{
                textAlign: 'center',
                color: '#4F4F4F',
                fontSize: 14,
                fontWeight: 400
              }}
            >
              Log in with the username you’ve created to get access to explore a lot of awesome content for your kid
            </div>
            <div className='mt-4 d-flex justify-content-between'>

              <div>
                <Button
                  style={{
                    height: 40,
                    color: '#fff',
                    background: '#4C39B3',
                    borderRadius: 6,
                    border: 'none',
                    width: 120
                  }}
                  type='primary'
                  block
                  size='large'
                  onClick={() => {
                    this.setState(
                      {
                        login: {
                          first_name: '',
                          last_name: '',
                          username: '',
                          dob: ''
                        }
                      },
                      () => {
                        confirmPopup.destroy();
                        triggerGAEvent('Login', 'Logging in as Parent')
                        this.props.history.push(`/parent/workshops?isTour=1`)

                      }
                    )
                  }}
                >
                  Got it
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      centered: true
    })
  }

  render() {
    const suffix = this.state.login.username ? (
      <Icon
        style={{
          background: '#68B415',
          color: '#fff'
        }}
        type='check-circle'
      />
    ) : (
      <span />
    )
    return (
      <React.Fragment>
        <MaxChildSetup>
          {/* <div>
            <Menu
              className='pr-2 w-100 parentMenu'
              mode='horizontal'
              style={{
                background: '#4C39B3',
                boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
              }}
            >
              <Menu.Item
                key='logo'
                index='1'
                className='pl-0'
                title={`BREADCRUMB`}
              >
                <img
                  src={ParentLogo}
                  alt='breadcrumb logo'
                  style={{ width: 230, marginBottom: -1 }}
                />
              </Menu.Item>
            </Menu>
          </div> */}
          <div className='d-flex align-items-center justify-content-center pt-c'>
            <div
              className='mbl-signup-card mbl-card-radius mx-auto'
              style={{ width: 584, padding: 40 }}
            >
              <div className='d-flex align-items-center justify-content-center mb-4'>
                <div>
                  <h3
                    className='max-signup-title FontBariolHeavy text-center'
                    style={{ fontSize: 36, marginBottom: 0 }}
                  >
                    Tell us about your kid
                  </h3>
                </div>
              </div>
              <div className='mb-0'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      First Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Child’s First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Last Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Child’s Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Date Of Birth &ensp;
                      <Tooltip
                        placement='bottom'
                        title={`Based on your age category, a whole lot of awesome content would show up`}
                      >
                        <span
                          style={{
                            color: '#4C39B3',
                            cursor: 'pointer'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-8'>
                      <DatePicker
                        style={{
                          width: '100%'
                        }}
                        value={
                          this.state.login.dob
                            ? moment(this.state.login.dob, 'DD-MM-YYYY')
                            : undefined
                        }
                        className={
                          this.state.truthObj['dob'] ? 'has-error-c' : ''
                        }
                        disabledDate={this.disableDobHandler}
                        placeholder='Select Child’s Date Of Birth'
                        size='large'
                        format='DD-MM-YYYY'
                        onChange={this.dobChangeHandler}
                      ></DatePicker>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Username &ensp;
                      <Tooltip
                        placement='bottom'
                        title={`This username is what your child would use to login on the iPad`}
                      >
                        <Icon
                          style={{
                            color: '#4C39B3',
                            cursor: 'pointer'
                          }}
                          type='info-circle'
                          className='font-20'
                        />
                      </Tooltip>
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Create Username'
                        id={this.state.truthObj['username'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.username}
                        stateKey='username'
                        validations={[]}
                        suffix={suffix}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>

                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-12 FontBariol'
                      style={{
                        fontSize: 14
                      }}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontWeight: 'bold'
                        }}
                      >
                        Please Note:
                      </span>
                      <br />
                      If you have more than one child and wish to provide all of
                      them access to Breadcrumb, you can add their details after
                      you complete these steps.
                    </div>
                  </div>

                  <div className='mt-4'>

                    <div className='text-center'>
                      <Button
                        className='text-center'
                        type='primary'
                        size='large'
                        onClick={this.validateStateValues}
                        style={{
                          background: '#4C39B3',
                          border: 0,
                          width: 230,
                          height: 50
                        }}
                      >
                        Create Account
                      </Button>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-2'>

                      <Button
                        className='FontBariolHeavy text-center'
                        block
                        type='link'
                        size='large'
                        onClick={() =>
                          this.props.history.push(`/parent/workshops?isTour=1`)}
                        style={{
                          color: '#4F4F4F',
                          width: 142,
                          height: 50
                        }}
                      >
                        Skip
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </MaxChildSetup>

        {/* Mbl child signup */}
        <MblChildSetup className='d-flex'>
          <div className='mblSignIn'>
            {/* <div className=''>
              <Menu
                className='pr-2 w-100'
                mode='horizontal'
                style={{
                  background: '#4C39B3',
                  boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
                }}
              >
                <Menu.Item
                  key='logo'
                  index='1'
                  className='pl-0'
                  title={`BREADCRUMB`}
                >
                  <img
                    src={ParentLogo}
                    alt='breadcrumb logo'
                    style={{ width: 230, marginBottom: -1 }}
                  />
                </Menu.Item>
              </Menu>
            </div> */}
            <div
              className='card-w-auto cardd1'
              style={{
                padding: 16
              }}
            >
              <div className='d-flex align-items-center justify-content-center mt-2'>
                <img
                  style={{
                    height: 31,
                    width: 190
                  }}
                  src={mbllogo}
                  className='mbl-logo1'
                  alt='logo'
                />
              </div>
              <div
                className='pt-3 mb-3 FontBariolHeavy text-center'
                style={{
                  color: '#000',
                  fontSize: 28
                }}
              >
                Tell us about your kid
              </div>

              <div className='mb-0'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='First Name'
                        placeholder='Enter Child’s First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='Last Name'
                        placeholder='Enter Child’s Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Date Of Birth &ensp;
                      <Tooltip
                        placement='bottom'
                        title={`Based on your age category, a whole lot of awesome content would show up`}
                      >
                        <span
                          style={{
                            color: '#4C39B3',
                            cursor: 'pointer'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-12'>
                      <DatePicker
                        style={{
                          width: '100%'
                        }}
                        value={
                          this.state.login.dob
                            ? moment(this.state.login.dob, 'DD-MM-YYYY')
                            : undefined
                        }
                        className={
                          this.state.truthObj['dob'] ? 'has-error-c' : ''
                        }
                        disabledDate={this.disableDobHandler}
                        placeholder='Select Child’s Date Of Birth'
                        size='large'
                        format='DD-MM-YYYY'
                        onChange={this.dobChangeHandler}
                      ></DatePicker>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Username &ensp;
                      <Tooltip
                        placement='bottom'
                        title={`This username is what your child would use to login on the iPad`}
                      >
                        <Icon
                          style={{
                            color: '#4C39B3',
                            cursor: 'pointer'
                          }}
                          type='info-circle'
                          className='font-20'
                        />
                      </Tooltip>
                    </div>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        placeholder='Create Username'
                        id={this.state.truthObj['username'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.username}
                        stateKey='username'
                        validations={[]}
                        suffix={suffix}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-12 FontBariol'
                      style={{
                        fontSize: 14
                      }}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontWeight: 'bold'
                        }}
                      >
                        Please Note:
                      </span>
                      <br />
                      If you have more than one child and wish to provide all of
                      them access to Breadcrumb, you can add their details after
                      you complete these steps.
                    </div>
                  </div>

                  <div className='mt-4'>

                    <div className='text-center'>
                      <Button
                        className='text-center'
                        type='primary'
                        size='large'
                        onClick={this.validateStateValues}
                        style={{
                          background: '#4C39B3',
                          border: 0,
                          width: 230,
                          height: 50
                        }}
                      >
                        Create Account
                      </Button>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-2'>

                      <Button
                        className='FontBariolHeavy text-center'
                        block
                        type='link'
                        size='large'
                        onClick={() =>
                          this.props.history.push(`/parent/workshops?isTour=1`)}
                        style={{
                          color: '#4F4F4F',
                          width: 142,
                          height: 50
                        }}
                      >
                        Skip
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </MblChildSetup>

      </React.Fragment>
    )
  }
}

export default ChildAccountSetup
