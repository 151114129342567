import { Button, Input, Modal, Popover, Select, Table, Tag } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Loading from '../../components/Loading'
import {
  APPROVE_PARENT,
  GET_ALL_PARENTS, GET_COUNTRY_CITY_LIST, ONHOLD_PARENT,
  RESEND_INVITE
} from '../../graphql'
import whatsappLogo from '../../static/nav/whatsapp_ico.png'
import { copyToClipboard, openNotification, triggerGAEvent } from '../../utils'

class Parents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDialog: null,
      parentType: null,
      filteredInfo: null,
      sortedInfo: {
        columnKey: 'createdAt',
        field: 'createdAt',
        order: 'descend'
      },
      is_loading: false,
      selectedParents: [],
      searchText: '',
      countries: [],
      cities: [],
      selectedCity: '',
      selectedCountry: '',
      parentVisible: false,
      on_hold_text: '',
      page: 1,
      total: 0,
      selected_parent: {
        notification_name: '',
        city: '',
        phone_no: '',
        email: ''
      }
    }
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700)
  }

  fetCountryCityData = () => {
    let query = GET_COUNTRY_CITY_LIST
    query.variables = {
      country: this.state.selectedCountry,
      city: this.state.selectedCity
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          this.setState({
            countries: res.data.data.getCountryCityListV2.country_list.map(
              c => c.name
            ),
            cities: res.data.data.getCountryCityListV2.city_list.map(
              c => c.name
            )
          })
        }
      })
      .catch(err => console.log(err))
  }

  fetchParentData = () => {
    let { sortedInfo, filteredInfo } = this.state
    sortedInfo = sortedInfo || { columnKey: '', order: '' }
    filteredInfo = filteredInfo || { status: [] }
    let query = GET_ALL_PARENTS
    query.variables = {
      search: this.state.searchText,
      parent: true,
      page: this.state.page,
      filter: {
        first_name:
          sortedInfo.columnKey === 'name'
            ? sortedInfo.order === 'ascend'
              ? 'ASC'
              : 'DESC'
            : '',
        appliedOn:
          sortedInfo.columnKey === 'createdAt'
            ? sortedInfo.order === 'ascend'
              ? 'ASC'
              : 'DESC'
            : '',
        onboardedOn:
          sortedInfo.columnKey === 'onboardedAt'
            ? sortedInfo.order === 'ascend'
              ? 'ASC'
              : 'DESC'
            : '',
        status: filteredInfo.status,
        country: this.state.selectedCountry,
        city: this.state.selectedCity
      },
      parentType: this.state.parentType == 'adults' ? 'adult' : 'parent'
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        // if (this.state.selectedCity || this.state.selectedCountry) {
        //   let selectedParents = [];
        //   res.data.data.getDashboardLearningCentreV2.parent_details.forEach(parent => {
        //     if (this.state.selectedCity && this.state.selectedCountry) {
        //       if (parent.address && parent.address.toLowerCase() === this.state.selectedCity && parent.country && parent.country.toLowerCase() === this.state.selectedCountry) {
        //         selectedParents.push(parent)
        //       }
        //     } else if (this.state.selectedCity) {
        //       if (parent.address && parent.address.toLowerCase() === this.state.selectedCity) {
        //         selectedParents.push(parent)
        //       }
        //     } else if (this.state.selectedCountry) {
        //       if (parent.country && parent.country.toLowerCase() === this.state.selectedCountry) {
        //         selectedParents.push(parent)
        //       }
        //     }
        //   });
        //   console.log(selectedParents);
        //   this.setState({
        //     parents: res.data.data.getDashboardLearningCentreV2.parent_details,
        //     selectedParents: selectedParents,
        //     total: res.data.data.getDashboardLearningCentreV2.count,
        //   });
        // } else {
        //   this.setState({
        //     parents: res.data.data.getDashboardLearningCentreV2.parent_details,
        //     selectedParents: res.data.data.getDashboardLearningCentreV2.parent_details,
        //     total: res.data.data.getDashboardLearningCentreV2.count,
        //   });
        // }
        this.setState({
          selectedParents:
            res.data.data.getDashboardLearningCentreV2.parent_details,
          total: res.data.data.getDashboardLearningCentreV2.count
        })
      })
      .catch(err => console.log(err))
  }

  approveParent = () => {
    triggerGAEvent('Parents', 'Approving a Parent')
    let mutation = APPROVE_PARENT
    mutation.variables = {
      notification_id: this.state.selected_parent.id,
      comment: this.state.on_hold_text
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          openNotification('success', 'Parent approved')
          this.fetchParentData()
          this.handleModalCancel()
        }
      })
      .catch(err => console.log(err))
  }

  onHoldParent = () => {
    triggerGAEvent('Parents', 'Setting a Parent to On-Hold')
    let mutation = ONHOLD_PARENT
    mutation.variables = {
      notification_id: this.state.selected_parent.id,
      comment: this.state.on_hold_text
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          openNotification('success', 'Parent is set to on-hold')
          this.fetchParentData()
          this.handleModalCancel()
        }
      })
      .catch(err => console.log(err))
  }

  resendParentInvite = record => {
    triggerGAEvent('Parents', 'Resending Parent Invite')
    console.log(record)
    const confirmDialog = Modal.confirm({
      title: 'Do you want to resend the invite email?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let query = RESEND_INVITE
        query.variables = {
          email: record.email
        }
        axios
          .post(`${process.env.REACT_APP_API}`, query)
          .then(res => {
            if (res.data.errors) {
              openNotification('error', res.data.errors[0].message)
            } else {
              openNotification('success', 'Invite sent successfully')
            }
          })
          .catch(err => console.log(err))
      }
    })
    this.setState({ confirmDialog })
  }

  onInputChange = e => {
    if (e.target.name === 'on_hold_text') {
      e.target.value.length <= 280 &&
        this.setState({ [e.target.name]: e.target.value })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  onSearchFetchData = () => {
    triggerGAEvent('Parents', 'Searching a Parent')
    this.fetchParentData()
  }

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    triggerGAEvent('Parents', 'Changing sort and filters in Parents Table')
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter
      },
      () => this.fetchParentData()
    )
  }

  componentDidMount() {
    let ParentType = this.props.history.location.state.ParentType
      ? this.props.history.location.state.ParentType
      : null
    // console.log(this.props.history.location.state.ParentType);
    this.setState(
      {
        parentType: ParentType
      },
      () => {
        this.fetchParentData()
        this.fetCountryCityData()
      }
    )
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy()
  }

  componentDidUpdate() {
    let ParentType = this.props.history.location.state.ParentType
      ? this.props.history.location.state.ParentType
      : null
    if (this.state.parentType != ParentType) {
      this.setState(
        {
          parentType: ParentType
        },
        () => this.fetchParentData()
      )
    }
  }

  openModal = record => {
    this.setState({
      parentVisible: true,
      selected_parent: record,
      on_hold_text: record.comments
    })
  }

  handleModalCancel = () => {
    this.setState({
      parentVisible: false,
      on_hold_text: '',
      selected_parent: {
        notification_name: '',
        city: '',
        phone_no: '',
        email: ''
      }
    })
  }

  onRenderOperation = (text, record, index) => {
    switch (record.status) {
      case '0':
        return (
          <Popover
            placement='bottom'
            content={
              <div className='d-flex flex-column align-items-start'>
                <span
                  className='mb-2 pointer-cursor'
                  onClick={() => this.openModal(record)}
                >
                  Approve / On-hold
                </span>
                <span
                  className='pointer-cursor'
                  onClick={() =>
                    this.props.history.push(
                      `/parents/${record.status}/${record.email}`
                    )
                  }
                >
                  View
                </span>
              </div>
            }
          >
            Manage
          </Popover>
        )
      case '1':
        return (
          <Popover
            placement='bottom'
            content={
              <div className='d-flex flex-column align-items-start'>
                <span
                  className='mb-2 pointer-cursor'
                  onClick={() =>
                    this.props.history.push(
                      `/parents/${record.status}/${record.email}`
                    )
                  }
                >
                  View
                </span>
                <span
                  className='mb-2 pointer-cursor'
                  onClick={() => this.resendParentInvite(record)}
                >
                  Resend Email
                </span>
                <span
                  className='pointer-cursor'
                  onClick={() => copyToClipboard(record.invite_link)}
                >
                  Copy invite link
                </span>
              </div>
            }
          >
            Manage
          </Popover>
        )
      case '2':
        return (
          <Popover
            placement='bottom'
            content={
              <div className='d-flex flex-column align-items-start'>
                <span
                  className='mb-2 pointer-cursor'
                  onClick={() => this.openModal(record)}
                >
                  Approve
                </span>
                <span
                  className='pointer-cursor'
                  onClick={() =>
                    this.props.history.push(
                      `/parents/${record.status}/${record.email}`
                    )
                  }
                >
                  View
                </span>
              </div>
            }
          >
            Manage
          </Popover>
        )
      case '3':
        return (
          <div
            className='pointer-cursor'
            onClick={() =>
              this.props.history.push(
                `/parents/${record.status}/${record.email}`
              )
            }
          >
            View
          </div>
        )
      default:
        return null
    }
  }

  onRenderStatus = (text, record, index) => {
    switch (record.status) {
      case '0':
        return <Tag color='red'>Pending approval</Tag>
      case '1':
        return <Tag color='orange'>Yet to onboard</Tag>
      case '2':
        return <Tag color='blue'>On hold</Tag>
      case '3':
        return <Tag color='green'>Onboarded</Tag>
      default:
        return null
    }
  }

  onChangeCity = value => {
    triggerGAEvent('Parents', 'Changing City in Parents')
    this.setState({ selectedCity: value }, () => this.fetchParentData())
  }

  onChangeCountry = value => {
    triggerGAEvent('Parents', 'Changing City in Parents')
    this.setState({ selectedCountry: value }, () => {
      this.fetchParentData()
      this.fetCountryCityData()
    })
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}

    const newColumns = [
      {
        title: 'First Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        sorter: (a, b) =>
          a.last_name && b.last_name && a.last_name.localeCompare(b.last_name),
        sortOrder: sortedInfo.columnKey === 'last_name' && sortedInfo.order,
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '14%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Mobile Number',
        dataIndex: 'phone_no',
        width: '12%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        }),
        render: (text, record, i) => (
          <div>
            {
              record.is_whatsapp ? <img src={whatsappLogo} alt='whatsapp icon' /> : ''
            }
            &nbsp;{record.phone_no}
          </div>
        )
      },
      {
        title: 'Country',
        dataIndex: 'country',
        width: '11%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'City',
        dataIndex: 'address',
        width: '11%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Onboarded on',
        dataIndex: 'onboardedAt',
        key: 'onboardedAt',
        sorter: (a, b) => a.onboardedAt - b.onboardedAt,
        sortOrder: sortedInfo.columnKey === 'onboardedAt' && sortedInfo.order,
        render: (text, record, index) =>
          record.onboardedAt
            ? moment(parseInt(record.onboardedAt, 10)).format('MMM D, YYYY')
            : '',
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          { value: 0, text: 'Pending' },
          { value: 1, text: 'Yet to onboard' },
          { value: 2, text: 'On hold' },
          { value: 3, text: 'Onboarded' }
        ],
        onFilter: (value, record) =>
          record.status && record.status.includes(value),
        filteredValue: filteredInfo.status || null,
        render: this.onRenderStatus,
        width: '8%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      }
      // {
      //   title: "Applied on",
      //   dataIndex: "createdAt",
      //   key: 'createdAt',
      //   sorter: (a, b) => a.createdAt - b.createdAt,
      //   sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      //   render: (text, record, index) => moment(parseInt(record.createdAt, 10)).format("MMM D, YYYY"),
      //   width: "10%",
      //   onCell: (record, rowIndex) => ({
      //     onClick: e =>
      //       this.props.history.push(`/parents/${record.status}/${record.email}`)
      //   })
      // },
      // {
      //   title: "More",
      //   dataIndex: "",
      //   width: "8%",
      //   render: this.onRenderOperation
      // }
    ]

    const parentColumns = [
      {
        title: 'First Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        sorter: (a, b) =>
          a.last_name && b.last_name && a.last_name.localeCompare(b.last_name),
        sortOrder: sortedInfo.columnKey === 'last_name' && sortedInfo.order,
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Child Name',
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        }),
        render: (text, record, index) => (
          <div className='d-flex flex-column'>
            {record.students.map((stud, i) => {
              if (stud.is_delete) {
                return <span>Account Deleted</span>
              }
              return <span key={i} className='mb-1'>{`${stud.fname ||
                ''} ${stud.lname || ''}`}</span>

            }
            )}
          </div>
        )
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '14%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Mobile Number',
        dataIndex: 'phone_no',
        width: '12%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        }),
        render: (text, record, i) => (
          <div>
            {
              record.is_whatsapp ? <img src={whatsappLogo} alt='whatsapp icon' /> : ''
            }
            &nbsp;{record.phone_no}
          </div>
        )
      },
      {
        title: 'Country',
        dataIndex: 'country',
        width: '11%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'City',
        dataIndex: 'address',
        width: '11%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Onboarded on',
        dataIndex: 'onboardedAt',
        key: 'onboardedAt',
        sorter: (a, b) => a.onboardedAt - b.onboardedAt,
        sortOrder: sortedInfo.columnKey === 'onboardedAt' && sortedInfo.order,
        render: (text, record, index) =>
          record.onboardedAt
            ? moment(parseInt(record.onboardedAt, 10)).format('MMM D, YYYY')
            : '',
        width: '10%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          { value: 0, text: 'Pending' },
          { value: 1, text: 'Yet to onboard' },
          { value: 2, text: 'On hold' },
          { value: 3, text: 'Onboarded' }
        ],
        onFilter: (value, record) =>
          record.status && record.status.includes(value),
        filteredValue: filteredInfo.status || null,
        render: this.onRenderStatus,
        width: '8%',
        onCell: (record, rowIndex) => ({
          onClick: e =>
            this.props.history.push(`/parents/${record.status}/${record.email}`)
        })
      }
      // {
      //   title: "Applied on",
      //   dataIndex: "createdAt",
      //   key: 'createdAt',
      //   sorter: (a, b) => a.createdAt - b.createdAt,
      //   sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      //   render: (text, record, index) => moment(parseInt(record.createdAt, 10)).format("MMM D, YYYY"),
      //   width: "10%",
      //   onCell: (record, rowIndex) => ({
      //     onClick: e =>
      //       this.props.history.push(`/parents/${record.status}/${record.email}`)
      //   })
      // },
      // {
      //   title: "More",
      //   dataIndex: "",
      //   width: "8%",
      //   render: this.onRenderOperation
      // }
    ]

    return (
      <React.Fragment>
        <Loading is_loading={this.state.is_loading} />
        <div style={{ margin: '1.7rem' }}>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 style={{ margin: '0.5rem 0 0 0', padding: 0 }}>
              {this.state.parentType == 'adults'
                ? `Learners > 18 profiles - ${this.state.total}`
                : `Parent profiles - ${this.state.total}`}
            </h4>
            <div className='d-flex align-items-center justify-content-end'>
              <Select
                placeholder='select city'
                size='large'
                className='mr-3'
                style={{ width: 185 }}
                value={this.state.selectedCountry}
                onChange={this.onChangeCountry}
              >
                <Select.Option key={0} value={''}>
                  -- Select Country --
                </Select.Option>
                {this.state.countries.map((country, index) => (
                  <Select.Option key={index + 1} value={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
              <Select
                placeholder='select city'
                size='large'
                className='mr-3'
                style={{ width: 155 }}
                value={this.state.selectedCity}
                onChange={this.onChangeCity}
              >
                <Select.Option key={0} value={''}>
                  -- Select City --
                </Select.Option>
                {this.state.cities.map((city, index) => (
                  <Select.Option key={index + 1} value={city}>
                    {city}
                  </Select.Option>
                ))}
              </Select>
              <Input.Search
                placeholder='Search'
                style={{ width: 350, height: '100%' }}
                size='large'
                onChange={this.onSearch}
              />
            </div>
          </div>
        </div>
        <div style={{ margin: '1rem' }} className='studentsDataTable'>
          <Table
            className='table-full'
            columns={
              this.state.parentType == 'adults' ? newColumns : parentColumns
            }
            rowKey={parent => parent.email}
            dataSource={this.state.selectedParents}
            onChange={this.handleTableChange}
            rowClassName='table-row'
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) =>
                this.setState({ page: page }, () => this.fetchParentData())
            }}
          />
        </div>
        <Modal
          title={
            <div className='d-flex flex-column'>
              <div style={{ margin: '0.5rem 0' }}>
                {this.state.selected_parent.name}
              </div>
              <div style={{ margin: '0.5rem 0' }}>
                {this.state.selected_parent.address}
              </div>
              <div style={{ margin: '0.5rem 0' }}>
                {this.state.selected_parent.email} •{' '}
                {this.state.selected_parent.phone_no}
              </div>
            </div>
          }
          visible={this.state.parentVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          footer={[
            <Button
              key='1'
              size='large'
              type='default'
              className='step-cancel'
              onClick={this.onHoldParent}
            >
              On hold
            </Button>,
            <Button
              key='2'
              size='large'
              type='primary'
              onClick={this.approveParent}
            >
              Approve
            </Button>
          ]}
        >
          <Input.TextArea
            rows={4}
            name='on_hold_text'
            placeholder='Enter a description'
            value={this.state.on_hold_text}
            onChange={this.onInputChange}
            size='large'
          />
          <div
            style={
              this.state.on_hold_text
                ? this.state.on_hold_text.length >= 280
                  ? { color: '#FF0A00', float: 'right', marginTop: 8 }
                  : { float: 'right', marginTop: 8 }
                : { float: 'right', marginTop: 8 }
            }
          >
            {this.state.on_hold_text ? this.state.on_hold_text.length : 0} / 280
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Parents
