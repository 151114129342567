import { Icon } from "antd";
import React from "react";
const Loading = props => {
  return (
    <span>
      {props.is_loading && (
        <div
          className={`${
            props.absolute
              ? "position-absolute absolute-loading opacity-white-5"
              : "vh-100 position-fixed opacity-black-5"
          } ${
            props.transparent ? "opacity-transparent" : ""
          } z-index-999 align-items-center col-12 fixed-top d-flex justify-content-center`}
        >
          <Icon type="loading" theme="outlined" className="loading" />
        </div>
      )}
    </span>
  );
};

export default Loading;
