import { Button, Checkbox, Col, Icon, Modal, Row } from "antd";
import axios, { post } from "axios";
import Moment from "moment";
import queryString from "query-string";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";
import { AppContext } from "../../context/AppContext";
import { GET_PARENT_DASHBOARD } from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import ErrorActivity from "../../static/activity/error.svg";
import ImgPlaceholder from "../../static/nav/imageFallback.png";
import Cross from "../../static/workshop/cross.png";
import dateArrow from "../../static/workshop/dateArrow.svg";
import EmptyCart from "../../static/workshop/empty_cart.svg";
import ClockImg from "../../static/workshop/workClock.svg";
import FireImg from "../../static/workshop/workshopFire.svg";
import { openNotification } from "../../utils";
import {
  GET_CART,
  PAYMENT_WORKSHOP_CART,
  PAYMENT_WORKSHOP_CART_VERIFY,
  PAYMENT_WORKSHOP_CART_VERIFY_RAZOR,
  UPDATE_STUDENT_WORKSHOP_CART,
  UPDATE_WORKSHOP_CART
} from "./graphql";
import "./paymentCart.css";
import "./paymentSuccess.css";
import "./workshop.css";

const Img = require("react-image");

const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    }
    @media (max-width: 576px) {
      color: #828282;
    }
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
    color: #57479c;
    // max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`;

const PaymentMain = styled.div`
  @media only screen and (max-width: 576px) {
    .mbl-main {
      margin: 0 1rem 0 1rem !important;
    }
    .successMain {
      margin-top: 2rem !important;
      padding: 12px !important;
    }
    .payment-title {
      font-weight: 800;
      font-size: 22px;
    }
    .payment-para {
      margin-bottom: 2rem;
    }
    .mbl-img-padding {
      margin-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }
`;

class CartView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_selected: false,
      is_loading: false,
      payment_loadingState: false,
      is_error: false,
      is_500: false,
      emptyCart: false,
      changesChildpaidModal: false,
      paymentFailed: false,
      changesChildModal: false,
      instamojoModal: false,
      payment_requrl: "",
      cart_details: [],
      studentsData: [],
      selected_studId: [],
      cart_count: 0,
      user_details: null,
      saveFor_details: [],
      save_for_later_count: 0,
      workshop_details: {
        activities_id: "",
        user_id: "",
        user_details: {
          id: "",
          name: "",
          role_id: "",
        },
        user_activity_type: "",
        activity_state: "",
        status: "",
        workshop_name: "",
        learning_outcome: "",
        description: "",
        workshop_time: "",
        workshop_date: "",
        workshop_duration: "",
        workshop_type: "",
        workshop_amount: "",
        prerequisites: "",
        reference_link: "",
        skills_required: "",
        status: "",
        trailer_link: "",
        minimum_participants: "",
        maximum_participants: "",
        pdf_name: "",
        pdf_url: "",
        pdf_source_text: "",
        pdf_source_url: "",
      },
      filter: {},
      truthObj: {},
    };
  }

  fetchWorkshopCartData = () => {
    this.setState({
      is_loading: true,
    });
    let query = GET_CART;
    post(`${process.env.REACT_APP_API}`, query).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
        this.setState({ is_error: true });
      } else {
        // console.log('cart details ==> ', res.data.data.getCart)
        if (this.state.role_id == 3 && res.data.data.getCart) {
          if (
            !res.data.data.getCart.cart_count &&
            !res.data.data.getCart.save_for_later_count
          ) {
            this.setState({ emptyCart: true });
          } else {
            this.setState(
              {
                is_loading: false,
                amount: parseFloat(
                  res.data.data.getCart.Price
                    ? res.data.data.getCart.Price
                    : 0.0
                ),
                gst_amount: parseFloat(
                  res.data.data.getCart.GST ? res.data.data.getCart.GST : 0.0
                ),
                total_amount:
                  parseFloat(
                    res.data.data.getCart.Price
                      ? res.data.data.getCart.Price
                      : 0.0
                  ) +
                  parseFloat(
                    res.data.data.getCart.GST ? res.data.data.getCart.GST : 0.0
                  ),
                cart_details: res.data.data.getCart.cart,
                cart_count: res.data.data.getCart.cart_count,
                saveFor_details: res.data.data.getCart.save_for_later,
                save_for_later_count:
                  res.data.data.getCart.save_for_later_count,
                user_details: res.data.data.getCart.ParentDetails,
              },
              () => {
                let total_items =
                  this.state.cart_details && this.state.cart_details.length
                    ? this.state.cart_details
                      .map((x) => x.learners.length)
                      .reduce((xx, yy) => xx + yy)
                    : 0;
                this.setState({
                  total_items,
                });
              }
            );
          }
        } else {
          this.setState({ emptyCart: true });
        }
      }
    });
  };

  updateCartData = (cartId, savelater, deletecart = false) => {
    this.setState({
      is_loading: true,
    });
    let mutation = UPDATE_WORKSHOP_CART;
    mutation.variables = {
      cart_id: cartId,
      isSaveLater: savelater,
      isDelete: deletecart,
    };
    post(`${process.env.REACT_APP_API}`, mutation).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
      } else {
        // console.log(
        //   'update cart details ==> ',
        //   res.data.data.updateWorkshopCart
        // )
        if (this.state.role_id == 3 && res.data.data.updateWorkshopCart) {
          if (res.data.data.updateWorkshopCart.error) {
            this.setState({ is_loading: false });
          } else {
            this.setState(
              {
                is_loading: false,
              },
              () => window.location.reload()
            );
          }
        } else {
          this.setState({ is_loading: false });
        }
      }
    });
  };

  fetchAllStudentsFilterData = () => {
    let query = GET_PARENT_DASHBOARD;
    query.variables = {
      date: "",
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        if (res.data.errors) {
          this.setState({
            studentsData: [],
          });
        } else {
          let studentsFilter = res.data.data.ParentDashbordV2.students
            ? res.data.data.ParentDashbordV2.students.map((data) => {
              data.type = "student";
              data.is_active = false;
              return data;
            })
            : [];
          let studentsDataFilter = [];

          // if (this.state.cart_details.workshop_details) {
          //   this.state.age_groups.map(x => {
          //     studentsFilter.map(age => {
          //       if (
          //         !this.state.req_studId.includes(age.id) &&
          //         x.age_group_id == age['student_details'].age_group_id
          //       ) {
          //         studentsDataFilter.push(age)
          //       }
          //     })
          //   })
          // }

          studentsFilter.map((age) => {
            studentsDataFilter.push(age);
          });
          let parentData = res.data.data.ParentDashbordV2.user_details
            ? res.data.data.ParentDashbordV2.user_details
            : {};
          this.props.setUserInfo({ parentDetails: parentData, students: res.data.data.ParentDashbordV2.students })

          parentData.type = Object.keys(parentData).length ? "parent" : null;
          parentData.student_details = {
            age_group_id: 7,
          };
          if (!studentsDataFilter.length) {
            parentData.type = Object.keys(parentData).length ? "adult" : null;
          }
          parentData.id = parentData.id ? parseInt(parentData.id) : null;
          parentData.is_active = false;
          studentsDataFilter.splice(0, 0, parentData);

          this.setState(
            {
              studentsData: studentsDataFilter,
            },
            () => {
              let cartDetails = this.state.cart_details;
              let saveForDetails = this.state.saveFor_details;
              // cartDetails.map(async (x) => {
              //   let studAgelen = await this.getStudentAgeData(x, x.workshop_details);
              //   x.isEdit = (studAgelen || []).length > 1;
              //   return x;
              // });
              // saveForDetails.map(async (x) => {
              //   let studAgelen = await this.getStudentAgeData(x, x.workshop_details);
              //   x.isEdit = (studAgelen || []).length > 1;
              //   return x;
              // });
              // this.setState({
              //   cart_details: cartDetails,
              //   saveFor_details: saveForDetails
              // })
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  onSelectstudentID = (index, val) => {
    const state = { ...this.state };
    state.studentsData[index].is_active = val.target.checked;
    let regDetails = state.studentsData
      .filter((x) => x.is_active)
      .map((x) => ({
        student_id: x.type == "student" ? x.id : null,
        registration_type: x.type,
      }));
    let selected_studId = state.studentsData
      .filter((x) => x.is_active)
      .map((x) => x.id);
    this.setState({
      selected_studId: regDetails,
      studentsData: state.studentsData,
    });
  };

  getStudentAgeData = (cartData, workshop_details) => {
    return new Promise((resolve, reject) => {
      let studentIDs = cartData.learners
        ? cartData.learners.map((x) => parseInt(x.id))
        : [];
      let agegrpIDs = workshop_details.cart_age_group.length
        ? workshop_details.cart_age_group.map((x) => parseInt(x.age_group_id))
        : [];
      let GetAllstudents = [];
      if (workshop_details) {
        this.state.studentsData.map((age) => {
          if (agegrpIDs.includes(age["student_details"].age_group_id)) {
            if (studentIDs.includes(age.id)) {
              age.is_active = true;
            }
            GetAllstudents.push(age);
          }
        });
        resolve(GetAllstudents);
      }
    });
  };

  updateStudentModal = (cartData, workshop_details) => {
    let studentIDs = cartData.learners
      ? cartData.learners.map((x) => parseInt(x.id))
      : [];
    let agegrpIDs = workshop_details.cart_age_group.length
      ? workshop_details.cart_age_group.map((x) => parseInt(x.age_group_id))
      : [];
    let GetAllstudents = [];
    if (workshop_details) {
      this.state.studentsData.map((age) => {
        if (agegrpIDs.includes(age["student_details"].age_group_id)) {
          if (studentIDs.includes(age.id)) {
            age.is_active = true;
          }
          GetAllstudents.push(age);
        }
      });
    }

    this.setState(
      {
        changesChildModal: true,
        selected_studId: studentIDs,
        studentsData: GetAllstudents,
        selectedCartID: cartData.id,
        selectedWorkshopID: workshop_details.id,
      },
      () => {
        this.setState({
          changesChildpaidModal: true,
        });
      }
      // () => {
      //   let modal = Modal.confirm({
      //     content: (
      //       <React.Fragment>
      //         <h4 className='heading'>Learner selection</h4>

      //         <div className='border-bottom'></div>
      //         <div className='mt-4'>
      //           <p className='FontAvenirRoman font-16 mb-2'>
      //             Please select the learner to make the purchase
      //           </p>

      //           {this.state.studentsData &&
      //             this.state.studentsData.length > 0 &&
      //             this.state.studentsData.map((x, i) => (
      //               <div className='col-12 p-0'>
      //                 <Checkbox
      //                   onChange={e => this.onSelectstudentID(i, e)}
      //                   className='pt-2 pb-2'
      //                   checked={x.is_active}
      //                   value={x.id}
      //                 >
      //                   {x.student_details.username}
      //                 </Checkbox>
      //               </div>
      //             ))}
      //         </div>
      //         <div className='d-flex justify-content-between align-items-center pl-3 pr-3 p-1'>
      //           <div className='fontColor-blue font-16 FontAvenirMedium' />
      //           <div>
      //             <Button
      //               key='wsubmit'
      //               category='workshop Contents'
      //               action='Changes required for workshop Content'
      //               type='primary'
      //               className='btn-changeHover'
      //               size='large'
      //               onClick={() =>
      //                 this.updateStudentCartData(
      //                   cartData.id,
      //                   workshop_details.id
      //                 )
      //               }
      //               disabled={this.state.selected_studId.length < 1}
      //             >
      //               Continue
      //             </Button>
      //           </div>
      //         </div>
      //       </React.Fragment>
      //     ),
      //     icon: null,
      //     okButtonProps: { style: { display: 'none' } },
      //     closable: true,
      //     cancelButtonProps: { style: { display: 'none' } },
      //     maskClosable: true,
      //     onCancel: () => {
      //       this.setState({
      //         selected_studId: [],
      //         studentsData: this.state.studentsData.map(x => {
      //           x.is_active = false
      //           return x
      //         })
      //       })
      //     }
      //   })
      // }
    );
  };

  updateStudentCartData = (cartId, workshopId) => {
    this.setState({
      is_loading: true,
    });

    let mutation = UPDATE_STUDENT_WORKSHOP_CART;
    mutation.variables = {
      cart_id: cartId,
      registration_details: this.state.selected_studId,
      workshop_id: workshopId,
    };
    post(`${process.env.REACT_APP_API}`, mutation).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
        this.setState(
          {
            is_loading: false,
          },
          () => window.location.reload()
        );
      } else {
        // console.log(
        //   'update student cart details ==> ',
        //   res.data.data.updateStudentWorkshopCart
        // )
        if (
          this.state.role_id == 3 &&
          res.data.data.updateStudentWorkshopCart
        ) {
          if (res.data.data.updateStudentWorkshopCart.error) {
            this.setState({ is_loading: false });
          } else {
            this.setState(
              {
                is_loading: false,
              },
              () => window.location.reload()
            );
          }
        } else {
          this.setState({ is_loading: false });
        }
      }
    });
  };

  ChildEdit = () => {
    this.setState({
      changesChildModal: true,
    });
  };

  getLocalStorage = () => {
    this.setState(
      {
        role_id: Number(localStorage.getItem("role_id")),
      },
      () => {
        if (this.state.role_id !== 3) {
          this.props.history.push("/");
        }
      }
    );
  };

  onPaymentCompleted = async (response) => {
    this.setState({
      payment_loadingState: true,
    });
    let query = PAYMENT_WORKSHOP_CART_VERIFY_RAZOR;
    query.variables = {
      payment_request_id: response.razorpay_payment_id,
      order_id: response.razorpay_order_id,
    };
    post(`${process.env.REACT_APP_API}`, query).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
      } else {
        let status = res.data.data.paymentWorkshopCartVerify.msg;
        if (status === "payment success") {
          //openNotification("success", "Payment success");
          this.props.history.push(`/parent/workshops/paymentSuccess`);
        } else {
          openNotification("error", "Payment failed");
        }
      }
      this.setState(
        {
          payment_loadingState: false,
          is_loading: false,
        },
        () => this.fetchWorkshopCartData()
      );
    });
  };

  loadScript=(src)=> {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  payWithRazorpay = async (order_id, amount) => {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount.toString(),
      currency: "INR",
      name: "Workshop",
      description: `WorkshopID: ${
        this.state.cart_details && this.state.cart_details.length
          ? this.state.cart_details.map((x) => x.id)
          : []
      }`,
      order_id: order_id,
      handler: this.onPaymentCompleted,
      prefill: {
        name: this.props.userInfo.parentDetails.first_name,
        email: this.props.userInfo.parentDetails.email,
        contact: this.props.userInfo.parentDetails.phone_no,
      },
      notes: {
        address: "test",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    this.setState({
      payment_loadingState: false,
      is_loading: false,
    });
  }

  paymentCart = (amount, token) => {
    this.setState({
      is_loading: true,
    });

    let cartIDs =
      this.state.cart_details && this.state.cart_details.length
        ? this.state.cart_details.map((x) => x.id)
        : [];

    amount = amount * 100;//to paise
    let mutation = PAYMENT_WORKSHOP_CART;
    mutation.variables = {
      cart_id: cartIDs,
      amount: amount,
      //token_id: token,
    };
    // console.log('tok ', mutation.variables)

    post(`${process.env.REACT_APP_API}`, mutation).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
        this.setState({
          is_loading: false,
        });
      } else {
        // console.log('payment details ==> ', res.data.data.paymentWorkshopCart)
        if (this.state.role_id == 3 && res.data.data.paymentWorkshopCart) {
          if (res.data.data.paymentWorkshopCart.error) {
            this.setState({
              is_loading: false,
              payment_loadingState: false,
              payment_requrl: "",
              instamojoModal: false,
            });
            openNotification("error", res.data.data.paymentWorkshopCart.msg);
          } else {
            let orderId = res.data.data.paymentWorkshopCart.data.id;
            this.payWithRazorpay(orderId, amount);
          }
        } else {
          this.setState({
            is_loading: false,
            payment_loadingState: false,
            payment_requrl: "",
            instamojoModal: false,
          });
        }
      }
    });
  };

  paymentCartVerify = (paymentReqID) => {
    this.setState({
      payment_loadingState: true,
    });

    let mutation = PAYMENT_WORKSHOP_CART_VERIFY;
    mutation.variables = {
      payment_request_id: paymentReqID,
    };
    // console.log('tok ', mutation.variables)

    post(`${process.env.REACT_APP_API}`, mutation).then((res) => {
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
        this.setState({
          payment_loadingState: false,
          paymentFailed: true,
        });
      } else {
        // console.log('payment details ==> ', res.data.data.paymentWorkshopCartVerify)
        if (
          this.state.role_id == 3 &&
          res.data.data.paymentWorkshopCartVerify
        ) {
          if (res.data.data.paymentWorkshopCartVerify.error) {
            this.setState({
              payment_loadingState: false,
              payment_requrl: "",
              paymentFailed: true,
            });
          } else {
            this.setState(
              {
                payment_loadingState: false,
              },
              () => {
                this.props.history.push(`paymentSuccess`);
              }
            );
          }
        } else {
          this.setState({
            payment_loadingState: false,
            payment_requrl: "",
            paymentFailed: true,
          });
        }
      }
    });
  };

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://js.instamojo.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    this.getLocalStorage();
    let payment_status = queryString.parse(this.props.location.search);

    if (payment_status && payment_status.payment_request_id) {
      // console.log("location url ", payment_status.payment_request_id);
      this.paymentCartVerify(payment_status.payment_request_id);
    } else {
      if (
        this.props.history.location.pathname.includes("parent") &&
        this.props.history.location.pathname.includes("cart")
      ) {
        this.fetchWorkshopCartData();
        this.fetchAllStudentsFilterData();
      }
    }
  }

  handleSubmit = async (e) => {
    // console.log("stripe token ==> ", e);
    if (e) {
      this.paymentCart(this.state.total_amount);
    }
  };

  onClosed = async (data) => {
    // console.log('stripe closed ==> ', data)
  };

  render() {
    const DateView = ({ date, time, every, duration }) => {
      return (
        <DateContainer className="d-flex">
          {every && every.length > 0 && (
            <div className="d-flex">
              <div className="subTitle">{every}</div>
              <div className="title-border" />
            </div>
          )}
          <div>
            <h4 className="title white-space-nowrap">
              {date ? Moment.utc(parseInt(date)).format(`ddd, Do MMM ’YY`) : ""}
            </h4>
            <div className="desc">
              {time ? Moment(time, "h m a").format("h:mm A") : ""}
              {duration ? ` - ${duration}` : ``}
            </div>
          </div>
        </DateContainer>
      );
    };

    if (this.state.emptyCart) {
      return (
        <React.Fragment>
          <div className="mbl-dev-emptycart col-12 mx-auto mt-5 pb-5 text-center">
            <img
              src={this.state.emptyCart ? EmptyCart : EmptyCart}
              alt="error"
              className="img-fluid"
            />
          </div>
          <div className="max-dev-emptycart col-8 mx-auto mt-5 pb-5 text-center">
            <img
              src={this.state.emptyCart ? EmptyCart : EmptyCart}
              alt="error"
              className="img-fluid"
            />
          </div>
        </React.Fragment>
      );
    } else if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else if (this.state.paymentFailed) {
      return (
        <PaymentMain>
          <div className="mbl-main m-5 d-flex align-items-center justify-content-center">
            <div className="mt-5 successMain border-style">
              <div className="mbl-img-padding mx-auto mt-5 pb-5 text-center">
                <img src={Cross} alt="success" className="img-fluid" />
              </div>
              <h5 className="payment-title">Payment was unsuccessful</h5>
              <p className="payment-para">
                Oh no! That's unfortunate. We could not process your payment.
                Please try again?
              </p>
              <a
                style={{
                  textDecorationLine: "underline",
                  color: "#0076FF",
                }}
                onClick={() => this.props.history.push(`/parent/workshops`)}
              >
                Explore more Workshops
              </a>
            </div>
          </div>
        </PaymentMain>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.is_loading && (
            <div className="vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center">
              <Icon type="loading" theme="outlined" className="loading" />
            </div>
          )}
          {this.state.payment_loadingState && (
            <div className="vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex flex-column justify-content-center">
              <Icon type="loading" theme="outlined" className="loading" />
              <div className="paymentLoading">
                Please do not refresh or go back while the payment is in
                progress
              </div>
            </div>
          )}
          {/* Max dev cart page */}
          <Row className="max-dev-cart">
            <Col span={18}>
              {this.state.cart_count &&
                this.state.cart_details &&
                this.state.cart_details.length > 0 && (
                  <div className="border-style m-3">
                    <div className="purchase-heading">
                      My cart ({this.state.cart_count})
                    </div>
                    <div className="border-bottom mt-4" />
                    {this.state.cart_details.map((x) => {
                      return (
                        <div className="row">
                          <div
                            header="DETAILS OF THIS WORKSHOP CART"
                            className="col-12"
                            key="1"
                          >
                            <div className="selectchildtext mt-4 mb-4">
                              Selected for&nbsp;
                              <b style={{ fontWeight: 800 }}>
                                {x.learners &&
                                  x.learners.length > 0 &&
                                  x.learners
                                    .map((stud) => stud.username)
                                    .join(", ")}
                              </b>
                              {x.is_Edit &&
                                (this.state.studentsData || []).length > 1 && (
                                  <Icon
                                    className="editicon"
                                    type="edit"
                                    onClick={() =>
                                      this.updateStudentModal(
                                        x,
                                        x.workshop_details
                                      )
                                    }
                                  />
                                )}
                            </div>

                            <Col span={10} xl={10} lg={9} className="pr-2">
                              <div>
                                <Img
                                  className="workcartimg w-100 h-100"
                                  src={
                                    x.workshop_details &&
                                      x.workshop_details.featured_image &&
                                      x.workshop_details.featured_image.length
                                      ? x.workshop_details.featured_image[0]
                                        .image_url
                                      : ""
                                  }
                                  loader={
                                    <div className="workcartimg skeleton" />
                                  }
                                  unloader={
                                    <img
                                      className="workcartimg w-100 h-100"
                                      src={ImgPlaceholder}
                                      alt="workshop content"
                                    />
                                  }
                                />
                              </div>
                            </Col>
                            <Col
                              span={14}
                              xl={14}
                              lg={15}
                              className="pl-2 pr-2"
                            >
                              <div className="mb-2">
                                <span className="heading">
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_name
                                    ? x.workshop_details.workshop_name
                                    : ""}
                                </span>
                                <span className="btns-cart">
                                  <a
                                    className="btn-save"
                                    onClick={() => {
                                      this.updateCartData(x.id, 1, false);
                                    }}
                                  >
                                    Save for later
                                  </a>
                                  <span className="linebar" />
                                  <a
                                    className="btn-del"
                                    onClick={() => {
                                      this.updateCartData(x.id, null, true);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </span>
                              </div>
                              <div className="workRow mb-2">
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    AGE GROUP
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details &&
                                      x.workshop_details.age_group
                                      ? x.workshop_details.age_group
                                      : ""}
                                  </div>
                                </div>
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    SESSIONS
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details &&
                                      x.workshop_details.workshop_session
                                      ? x.workshop_details.workshop_session
                                      : ""}
                                  </div>
                                </div>
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    DURATION
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details.workshop_plan_id !=
                                      3 ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ""}
                                        {/* {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''} */}
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_session
                                          ? x.workshop_details
                                            .workshop_session > 1
                                            ? ` each`
                                            : ""
                                          : ""}
                                      </span>
                                    ) : x.workshop_details.workshop_plan_id ==
                                      3 &&
                                      x.workshop_details.is_common_timing ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ""}
                                        {/* {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''} */}
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_session
                                          ? x.workshop_details
                                            .workshop_session > 1
                                            ? ` each`
                                            : ""
                                          : ""}
                                      </span>
                                    ) : (
                                      `Varies`
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="workRow mb-2">
                                <div>
                                  <span
                                    style={{
                                      color: "#42C0EF",
                                      textTransform: "uppercase",
                                    }}
                                  >{`\u20B9 ${x.workshop_details &&
                                    x.workshop_details.workshop_amount
                                    }`}</span>
                                  <span className="hurryText">
                                    {x.workshop_details &&
                                      x.workshop_details.available_seats ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.available_seats ? (
                                          [11, 12, 13, 14, 15].includes(
                                            x.workshop_details.available_seats
                                          ) ? (
                                            <span>
                                              <img
                                                className="fireimg"
                                                src={ClockImg}
                                                alt="WorkshopClock"
                                              />
                                            </span>
                                          ) : x.workshop_details
                                            .available_seats <= 10 ? (
                                            <span>
                                              <img
                                                className="fireimg"
                                                src={FireImg}
                                                alt="WorkshopFire"
                                              />
                                              Hurry!&nbsp;
                                            </span>
                                          ) : (
                                            " "
                                          )
                                        ) : (
                                          " "
                                        )}
                                        Only{" "}
                                        {x.workshop_details &&
                                          x.workshop_details.available_seats
                                          ? x.workshop_details.available_seats
                                          : ""}{" "}
                                        seat
                                        {x.workshop_details.available_seats
                                          ? x.workshop_details.available_seats >
                                            1
                                            ? "s"
                                            : ""
                                          : ""}{" "}
                                        left
                                      </span>
                                    ) : (
                                      <span style={{ color: "#68B415" }}>
                                        &ensp;We are fully booked!
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="workRow mb-2">
                                {x.workshop_details &&
                                  x.workshop_details.workshop_plan_id &&
                                  [1, 2, 3].includes(
                                    parseInt(
                                      x.workshop_details.workshop_plan_id
                                    )
                                  ) &&
                                  x.workshop_details.repeats_type == "dnr" &&
                                  x.workshop_details.Dates &&
                                  x.workshop_details.Dates.length > 0 && (
                                    <div
                                      className="d-flex flex-row min-w-400 w-400 d-flex overflow-scroll"
                                      id={`workshop-${this.props.index}`}
                                    >
                                      {x.workshop_details.Dates.map(
                                        (xx, DateIndex) => (
                                          <span
                                            className="pr-3 d-inline-block"
                                            key={`DateIndex-${DateIndex}`}
                                          >
                                            <div>
                                              <DateView
                                                date={xx.workshop_Date}
                                                time={xx.start_time}
                                                duration={
                                                  x.workshop_details
                                                    .workshop_plan_id == 3 &&
                                                    !x.workshop_details
                                                      .is_common_timing
                                                    ? xx.duration
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}
                                {x.workshop_details &&
                                  x.workshop_details.workshop_plan_id &&
                                  (x.workshop_details.workshop_plan_id == 1 ||
                                    x.workshop_details.workshop_plan_id == 2) &&
                                  x.workshop_details.repeats_type != "dnr" &&
                                  x.workshop_details.Dates &&
                                  x.workshop_details.Dates.length > 0 && (
                                    <DateContainer className="d-flex">
                                      <div className="d-flex align-items-center">
                                        <div className="subTitle">
                                          {x.workshop_details.repeatDays
                                            ? x.workshop_details.repeatDays
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="title-border" />
                                      <div>
                                        <h4 className="title white-space-nowrap">
                                          {`
                                    ${x.workshop_details.Dates[0].workshop_Date
                                              ? Moment(
                                                parseInt(
                                                  x.workshop_details.Dates[0]
                                                    .workshop_Date
                                                )
                                              ).format(`MMM DD`)
                                              : ""
                                            } -
                                    ${x.workshop_details.Dates[0].workshop_Date
                                              ? Moment(
                                                parseInt(
                                                  x.workshop_details.Dates[
                                                    x.workshop_details.Dates
                                                      .length - 1
                                                  ].workshop_Date
                                                )
                                              ).format(`MMM DD, YYYY`)
                                              : ""
                                            }
                                    `}
                                        </h4>
                                        <div className="desc">
                                          {x.workshop_details.workshop_time
                                            ? Moment(
                                              x.workshop_details
                                                .workshop_time,
                                              "h m a"
                                            ).format("h:mm A")
                                            : ""}
                                        </div>
                                      </div>
                                    </DateContainer>
                                  )}
                              </div>
                            </Col>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

              {/* saved for later */}

              {this.state.save_for_later_count &&
                this.state.saveFor_details &&
                this.state.saveFor_details.length > 0 && (
                  <div className="border-style m-3 mt-2">
                    <div className="purchase-heading">
                      Saved for later ({this.state.save_for_later_count})
                    </div>
                    <div className="border-bottom mt-4" />
                    {this.state.saveFor_details.map((x) => {
                      return (
                        <div className="row">
                          <div
                            header="DETAILS OF THIS WORKSHOP CART"
                            className="col-12"
                            key="1"
                          >
                            <div className="selectchildtext mt-4 mb-4">
                              Selected for&nbsp;
                              <b style={{ fontWeight: 800 }}>
                                {x.learners &&
                                  x.learners.length > 0 &&
                                  x.learners
                                    .map((stud) => stud.username)
                                    .join(", ")}
                              </b>
                              {x.is_Edit &&
                                (this.state.studentsData || []).length > 1 && (
                                  <Icon
                                    className="editicon"
                                    type="edit"
                                    onClick={() =>
                                      this.updateStudentModal(
                                        x,
                                        x.workshop_details
                                      )
                                    }
                                  />
                                )}
                            </div>

                            <Col span={10} xl={10} lg={9} className="pr-2">
                              <Img
                                className="workcartimg w-100 h-100"
                                src={
                                  x.workshop_details &&
                                    x.workshop_details.featured_image &&
                                    x.workshop_details.featured_image.length
                                    ? x.workshop_details.featured_image[0]
                                      .image_url
                                    : ""
                                }
                                loader={
                                  <div className="workcartimg skeleton" />
                                }
                                unloader={
                                  <img
                                    className="workcartimg w-100 h-100"
                                    src={ImgPlaceholder}
                                    alt="workshop content"
                                  />
                                }
                              />
                            </Col>
                            <Col span={14} xl={14} lg={15}>
                              <div className="mb-2">
                                <span className="heading">
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_name
                                    ? x.workshop_details.workshop_name
                                    : ""}
                                </span>
                                <span className="btns-cart">
                                  <a
                                    className="btn-save"
                                    onClick={() => {
                                      this.updateCartData(x.id, 0, false);
                                    }}
                                  >
                                    Move to cart
                                  </a>
                                  <span className="linebar" />
                                  <a
                                    className="btn-del"
                                    onClick={() => {
                                      this.updateCartData(x.id, null, true);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </span>
                              </div>
                              <div className="workRow mb-2">
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    AGE GROUP
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details &&
                                      x.workshop_details.age_group
                                      ? x.workshop_details.age_group
                                      : ""}
                                  </div>
                                </div>
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    SESSIONS
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details &&
                                      x.workshop_details.workshop_session
                                      ? x.workshop_details.workshop_session
                                      : ""}
                                  </div>
                                </div>
                                <div className="workCol">
                                  <div className="WorkshopViewTitleText">
                                    DURATION
                                  </div>
                                  <div className="workshoptypeText">
                                    {x.workshop_details.workshop_plan_id !=
                                      3 ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ""}
                                        {/* {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''} */}
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_session
                                          ? x.workshop_details
                                            .workshop_session > 1
                                            ? ` each`
                                            : ""
                                          : ""}
                                      </span>
                                    ) : x.workshop_details.workshop_plan_id ==
                                      3 &&
                                      x.workshop_details.is_common_timing ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ""}
                                        {/* {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''} */}
                                        {x.workshop_details &&
                                          x.workshop_details.workshop_session
                                          ? x.workshop_details
                                            .workshop_session > 1
                                            ? ` each`
                                            : ""
                                          : ""}
                                      </span>
                                    ) : (
                                      `Varies`
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="workRow mb-2">
                                <div>
                                  <span
                                    style={{
                                      color: "#42C0EF",
                                      textTransform: "uppercase",
                                    }}
                                  >{`\u20B9 ${x.workshop_details &&
                                    x.workshop_details.workshop_amount
                                    }`}</span>
                                  <span className="hurryText">
                                    {x.workshop_details &&
                                      x.workshop_details.available_seats ? (
                                      <span>
                                        {x.workshop_details &&
                                          x.workshop_details.available_seats ? (
                                          [11, 12, 13, 14, 15].includes(
                                            x.workshop_details.available_seats
                                          ) ? (
                                            <span>
                                              <img
                                                className="fireimg"
                                                src={ClockImg}
                                                alt="WorkshopFire"
                                              />
                                            </span>
                                          ) : x.workshop_details
                                            .available_seats <= 10 ? (
                                            <span>
                                              <img
                                                className="fireimg"
                                                src={FireImg}
                                                alt="WorkshopFire"
                                              />
                                              Hurry!&nbsp;
                                            </span>
                                          ) : (
                                            " "
                                          )
                                        ) : (
                                          " "
                                        )}
                                        Only{" "}
                                        {x.workshop_details &&
                                          x.workshop_details.available_seats
                                          ? x.workshop_details.available_seats
                                          : ""}{" "}
                                        seat
                                        {x.workshop_details.available_seats
                                          ? x.workshop_details.available_seats >
                                            1
                                            ? "s"
                                            : ""
                                          : ""}{" "}
                                        left
                                      </span>
                                    ) : (
                                      <span style={{ color: "#68B415" }}>
                                        &ensp;We are fully booked!
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="workRow mb-2">
                                {x.workshop_details &&
                                  x.workshop_details.workshop_plan_id &&
                                  [1, 2, 3].includes(
                                    parseInt(
                                      x.workshop_details.workshop_plan_id
                                    )
                                  ) &&
                                  x.workshop_details.repeats_type == "dnr" &&
                                  x.workshop_details.Dates &&
                                  x.workshop_details.Dates.length > 0 && (
                                    <div
                                      className="d-flex flex-row min-w-400 w-400 d-flex overflow-scroll"
                                      id={`workshop-${this.props.index}`}
                                    >
                                      {x.workshop_details.Dates.map(
                                        (xx, DateIndex) => (
                                          <span
                                            className="pr-3 d-inline-block"
                                            key={`DateIndex-${DateIndex}`}
                                          >
                                            <div>
                                              <DateView
                                                date={xx.workshop_Date}
                                                time={xx.start_time}
                                                duration={
                                                  x.workshop_details
                                                    .workshop_plan_id == 3 &&
                                                    !x.workshop_details
                                                      .is_common_timing
                                                    ? xx.duration
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}
                                {x.workshop_details &&
                                  x.workshop_details.workshop_plan_id &&
                                  (x.workshop_details.workshop_plan_id == 1 ||
                                    x.workshop_details.workshop_plan_id == 2) &&
                                  x.workshop_details.repeats_type != "dnr" &&
                                  x.workshop_details.Dates &&
                                  x.workshop_details.Dates.length > 0 && (
                                    <DateContainer className="d-flex">
                                      <div className="d-flex align-items-center">
                                        <div className="subTitle">
                                          {x.workshop_details.repeatDays
                                            ? x.workshop_details.repeatDays
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="title-border" />
                                      <div>
                                        <h4 className="title white-space-nowrap">
                                          {`
                                    ${x.workshop_details.Dates[0].workshop_Date
                                              ? Moment(
                                                parseInt(
                                                  x.workshop_details.Dates[0]
                                                    .workshop_Date
                                                )
                                              ).format(`MMM DD`)
                                              : ""
                                            } -
                                    ${x.workshop_details.Dates[0].workshop_Date
                                              ? Moment(
                                                parseInt(
                                                  x.workshop_details.Dates[
                                                    x.workshop_details.Dates
                                                      .length - 1
                                                  ].workshop_Date
                                                )
                                              ).format(`MMM DD, YYYY`)
                                              : ""
                                            }
                                    `}
                                        </h4>
                                        <div className="desc">
                                          {x.workshop_details.workshop_time
                                            ? Moment(
                                              x.workshop_details
                                                .workshop_time,
                                              "h m a"
                                            ).format("h:mm A")
                                            : ""}
                                        </div>
                                      </div>
                                    </DateContainer>
                                  )}
                              </div>
                            </Col>
                            <div className="mt-2 mb-2" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </Col>
            {this.state.cart_count &&
              this.state.cart_details &&
              this.state.cart_details.length && (
                <Col span={6}>
                  <div className="m-3">
                    <div className="row m-0">
                      <div
                        header="Workshop DETAILS"
                        className="col-12 p-3 border-style"
                        key="1"
                      >
                        <div className="purchase-heading">
                          <LinesEllipsis
                            style={{ whiteSpace: "pre-wrap" }}
                            text="Purchase summary"
                            trimRight={true}
                            maxLine="4"
                            ellipsis="..."
                            basedOn="letters"
                          />
                        </div>
                        <div className="w-100 border-bottom mt-3 mb-3" />
                        <div className="purchase-row  mb-2">
                          <span className="cartitem-text">
                            Price ({this.state.total_items}{" "}
                            {this.state.total_items > 1 ? `items` : `item`})
                          </span>
                          <span className="cartitem-amt">
                            ₹ {this.state.amount}
                          </span>
                        </div>
                        {/* <div className='purchase-row '>
                          <span className='cartitem-text'>GST and taxes</span>
                          <span className='cartitem-amt'>
                            ₹ {this.state.gst_amount}
                          </span>
                        </div> */}
                        <div className="w-100 border-bottom mt-3 mb-3" />
                        <div className="d-flex justify-content-between purchase-row mb-3">
                          <div className="d-flex flex-column">
                            <div className="carttotal-text">Total amount</div>
                            <div className="TaxText">
                              (Excluding GST and transaction fees)
                            </div>
                          </div>
                          <div className="carttotal-amt">
                            ₹ {this.state.total_amount}
                          </div>
                        </div>
                        <div className="w-100">
                          <Button
                            key="proceedRegister"
                            category="workshop Contents register"
                            action="register a workshop"
                            type="primary"
                            className="btn-register"
                            size="large"
                            disabled={this.state.is_loading}
                            onClick={this.handleSubmit}
                          >
                            Proceed to payment
                          </Button>
                          {/* <StripeCheckout
                            token={this.handleSubmit}
                            stripeKey={process.env.REACT_APP_STRIPE_KEY}
                            name='Payment'
                            description='Payment towards a learning engagement curated by Breadcrumb'
                            image='https://altius-prod-images.s3.ap-south-1.amazonaws.com/Favicon64.png' // the pop-in header image (default none)
                            ComponentClass='div'
                            label='Buy the workshop' // text inside the Stripe button
                            panelLabel='Pay'
                            amount={this.state.total_amount * 100}
                            currency='INR'
                            // locale='IN'
                            email={
                              this.state.user_details
                                ? this.state.user_details.email
                                : null
                            }
                            // billingAddress={true}
                            allowRememberMe={false}
                            closed={this.onClosed}
                          >

                          </StripeCheckout> */}
                        </div>
                        <div className="mt-2 mb-2" />
                      </div>
                    </div>
                  </div>
                </Col>
              )}
          </Row>

          {/* Mbl dev cart page */}
          <Row className="mbl-dev-cart mt-4">
            <Col sm={24} xs={24}>
              {this.state.cart_count &&
                this.state.cart_details &&
                this.state.cart_details.length > 0 && (
                  <div className="border-style padding-12">
                    <div className="purchase-heading">
                      My cart ({this.state.cart_count})
                    </div>
                    <div className="border-bottom mt-4" />
                    {this.state.cart_details.map((x, i) => {
                      return (
                        <React.Fragment>
                          <div className="row m-0 mt-3">
                            <div
                              header="DETAILS OF THIS WORKSHOP CART"
                              className="p-0 col-12"
                              key="1"
                            >
                              <div className="selectchildtext mt-4 mb-4">
                                Selected for&nbsp;
                                <b style={{ fontWeight: 800 }}>
                                  {x.learners &&
                                    x.learners.length > 0 &&
                                    x.learners
                                      .map((stud) => stud.username)
                                      .join(", ")}
                                </b>
                                {x.is_Edit &&
                                  (this.state.studentsData || []).length >
                                  1 && (
                                    <Icon
                                      className="editicon"
                                      type="edit"
                                      onClick={() =>
                                        this.updateStudentModal(
                                          x,
                                          x.workshop_details
                                        )
                                      }
                                    />
                                  )}
                              </div>

                              <Col sm={24} xs={24}>
                                <div>
                                  <Img
                                    className="workcartimg w-100 h-100"
                                    src={
                                      x.workshop_details &&
                                        x.workshop_details.featured_image &&
                                        x.workshop_details.featured_image.length
                                        ? x.workshop_details.featured_image[0]
                                          .image_url
                                        : ""
                                    }
                                    loader={
                                      <div className="workcartimg skeleton" />
                                    }
                                    unloader={
                                      <img
                                        className="workcartimg w-100 h-100"
                                        src={ImgPlaceholder}
                                        alt="workshop content"
                                      />
                                    }
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={24}
                                xs={24}
                              // className='pl-2 pr-2'
                              >
                                <div className="mt-3 mb-3">
                                  <span className="heading">
                                    {x.workshop_details &&
                                      x.workshop_details.workshop_name
                                      ? x.workshop_details.workshop_name
                                      : ""}
                                  </span>
                                </div>
                                {/* <div className='workRow mb-2'>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    AGE GROUP
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details &&
                                    x.workshop_details.age_group
                                      ? x.workshop_details.age_group
                                      : ''}
                                  </div>
                                </div>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    SESSIONS
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details &&
                                    x.workshop_details.workshop_session
                                      ? x.workshop_details.workshop_session
                                      : ''}
                                  </div>
                                </div>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    DURATION
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details.workshop_plan_id !=
                                    3 ? (
                                      <span>
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_session
                                          ? x.workshop_details
                                              .workshop_session > 1
                                            ? ` each`
                                            : ''
                                          : ''}
                                      </span>
                                    ) : x.workshop_details.workshop_plan_id ==
                                        3 &&
                                      x.workshop_details.is_common_timing ? (
                                      <span>
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_session
                                          ? x.workshop_details
                                              .workshop_session > 1
                                            ? ` each`
                                            : ''
                                          : ''}
                                      </span>
                                    ) : (
                                      `Varies`
                                    )}
                                  </div>
                                </div>
                              </div> */}
                                <div className="workRow mb-2">
                                  <div>
                                    <span
                                      style={{
                                        color: "#42C0EF",
                                        textTransform: "uppercase",
                                        fontWeight: 800,
                                      }}
                                    >{`\u20B9 ${x.workshop_details &&
                                      x.workshop_details.workshop_amount
                                      }`}</span>
                                    <span className="hurryText">
                                      {x.workshop_details &&
                                        x.workshop_details.available_seats ? (
                                        <span>
                                          {x.workshop_details &&
                                            x.workshop_details.available_seats ? (
                                            [11, 12, 13, 14, 15].includes(
                                              x.workshop_details.available_seats
                                            ) ? (
                                              <span>
                                                <img
                                                  className="fireimg"
                                                  src={ClockImg}
                                                  alt="WorkshopClock"
                                                />
                                              </span>
                                            ) : x.workshop_details
                                              .available_seats <= 10 ? (
                                              <span>
                                                <img
                                                  className="fireimg"
                                                  src={FireImg}
                                                  alt="WorkshopFire"
                                                />
                                                Hurry!&nbsp;
                                              </span>
                                            ) : (
                                              " "
                                            )
                                          ) : (
                                            " "
                                          )}
                                          Only{" "}
                                          {x.workshop_details &&
                                            x.workshop_details.available_seats
                                            ? x.workshop_details.available_seats
                                            : ""}{" "}
                                          seat
                                          {x.workshop_details.available_seats
                                            ? x.workshop_details
                                              .available_seats > 1
                                              ? "s"
                                              : ""
                                            : ""}{" "}
                                          left
                                        </span>
                                      ) : (
                                        <span style={{ color: "#68B415" }}>
                                          &ensp;We are fully booked!
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="workRow mb-2">
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_plan_id &&
                                    [1, 2, 3].includes(
                                      parseInt(
                                        x.workshop_details.workshop_plan_id
                                      )
                                    ) &&
                                    x.workshop_details.repeats_type == "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 0 && (
                                      <div
                                        className="d-flex flex-row min-w-80per w-80per d-flex overflow-scroll"
                                        id={`workshopCart-scrollIndex${i}`}
                                      >
                                        {x.workshop_details.Dates.map(
                                          (xx, DateIndex) => (
                                            <span
                                              className="pr-3 d-inline-block"
                                              key={`DateIndex-${DateIndex}`}
                                            >
                                              <div>
                                                <DateView
                                                  date={xx.workshop_Date}
                                                  time={xx.start_time}
                                                  duration={
                                                    x.workshop_details
                                                      .workshop_plan_id == 3 &&
                                                      !x.workshop_details
                                                        .is_common_timing
                                                      ? xx.duration
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_plan_id &&
                                    (x.workshop_details.workshop_plan_id == 1 ||
                                      x.workshop_details.workshop_plan_id ==
                                      2) &&
                                    x.workshop_details.repeats_type != "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 0 && (
                                      <DateContainer className="d-flex">
                                        <div className="d-flex align-items-center">
                                          <div className="subTitle">
                                            {x.workshop_details.repeatDays
                                              ? x.workshop_details.repeatDays
                                              : ""}
                                          </div>
                                        </div>
                                        <div className="title-border" />
                                        <div>
                                          <h4 className="title white-space-nowrap">
                                            {`
                                    ${x.workshop_details.Dates[0].workshop_Date
                                                ? Moment(
                                                  parseInt(
                                                    x.workshop_details.Dates[0]
                                                      .workshop_Date
                                                  )
                                                ).format(`MMM DD`)
                                                : ""
                                              } -
                                    ${x.workshop_details.Dates[0].workshop_Date
                                                ? Moment(
                                                  parseInt(
                                                    x.workshop_details.Dates[
                                                      x.workshop_details.Dates
                                                        .length - 1
                                                    ].workshop_Date
                                                  )
                                                ).format(`MMM DD, YYYY`)
                                                : ""
                                              }
                                    `}
                                          </h4>
                                          <div className="desc">
                                            {x.workshop_details.workshop_time
                                              ? Moment(
                                                x.workshop_details
                                                  .workshop_time,
                                                "h m a"
                                              ).format("h:mm A")
                                              : ""}
                                          </div>
                                        </div>
                                      </DateContainer>
                                    )}
                                  {x.workshop_details.workshop_plan_id &&
                                    [1, 2, 3].includes(
                                      parseInt(
                                        x.workshop_details.workshop_plan_id
                                      )
                                    ) &&
                                    x.workshop_details.repeats_type == "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 2 && (
                                      <img
                                        className="ml-3 cursor-pointer"
                                        src={dateArrow}
                                        alt="arrow"
                                        onClick={(e) => {
                                          document
                                            .getElementById(
                                              `workshopCart-scrollIndex${i}`
                                            )
                                            .scrollTo({
                                              left:
                                                document.getElementById(
                                                  `workshopCart-scrollIndex${i}`
                                                ).scrollLeft + 250,
                                              behavior: "smooth",
                                            });
                                        }}
                                      />
                                    )}
                                </div>
                              </Col>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2 mb-2"
                            style={{
                              height: 48,
                              border: "0.5px solid #D9D9D9",
                              borderLeft: "none",
                              borderRight: "none",
                            }}
                          >
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                width: "175.5px",
                              }}
                            >
                              <div>
                                <a
                                  className="btn-save"
                                  onClick={() => {
                                    this.updateCartData(x.id, 1, false);
                                  }}
                                >
                                  Save for later
                                </a>
                                {/* <span className='linebar' /> */}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 48,
                                border: "0.5px solid #D9D9D9",
                              }}
                            ></div>
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                width: "175.5px",
                              }}
                            >
                              <div>
                                <a
                                  className="btn-del"
                                  onClick={() => {
                                    this.updateCartData(x.id, null, true);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* {
                          this.state.cart_details.length != (i + 1) &&
                          (<div className='border-bottom mt-4' />)
                        } */}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}

              {/* saved for later */}

              {this.state.save_for_later_count &&
                this.state.saveFor_details &&
                this.state.saveFor_details.length > 0 && (
                  <div className="border-style mt-3 padding-12">
                    <div className="purchase-heading">
                      Saved for later ({this.state.save_for_later_count})
                    </div>
                    <div className="border-bottom mt-4" />
                    {this.state.saveFor_details.map((x, i) => {
                      return (
                        <React.Fragment>
                          <div className="row m-0 mt-3">
                            <div
                              header="DETAILS OF THIS WORKSHOP CART"
                              className="p-0 col-12"
                              key="1"
                            >
                              <div className="selectchildtext mt-4 mb-4">
                                Selected for&nbsp;
                                <b style={{ fontWeight: 800 }}>
                                  {x.learners &&
                                    x.learners.length > 0 &&
                                    x.learners
                                      .map((stud) => stud.username)
                                      .join(", ")}
                                </b>
                                {x.is_Edit &&
                                  (this.state.studentsData || []).length >
                                  1 && (
                                    <Icon
                                      className="editicon"
                                      type="edit"
                                      onClick={() =>
                                        this.updateStudentModal(
                                          x,
                                          x.workshop_details
                                        )
                                      }
                                    />
                                  )}
                              </div>

                              <Col sm={24} xs={24}>
                                <Img
                                  className="workcartimg w-100 h-100"
                                  src={
                                    x.workshop_details &&
                                      x.workshop_details.featured_image &&
                                      x.workshop_details.featured_image.length > 0
                                      ? x.workshop_details.featured_image[0]
                                        .image_url
                                      : ""
                                  }
                                  loader={
                                    <div className="workcartimg skeleton" />
                                  }
                                  unloader={
                                    <img
                                      className="workcartimg w-100 h-100"
                                      src={ImgPlaceholder}
                                      alt="workshop content"
                                    />
                                  }
                                />
                              </Col>
                              <Col xs={24} sm={24}>
                                <div className="mt-3 mb-3">
                                  <span className="heading">
                                    {x.workshop_details &&
                                      x.workshop_details.workshop_name
                                      ? x.workshop_details.workshop_name
                                      : ""}
                                  </span>
                                </div>
                                {/* <div className='workRow mb-2'>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    AGE GROUP
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details &&
                                    x.workshop_details.age_group
                                      ? x.workshop_details.age_group
                                      : ''}
                                  </div>
                                </div>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    SESSIONS
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details &&
                                    x.workshop_details.workshop_session
                                      ? x.workshop_details.workshop_session
                                      : ''}
                                  </div>
                                </div>
                                <div className='workCol'>
                                  <div className='WorkshopViewTitleText'>
                                    DURATION
                                  </div>
                                  <div className='workshoptypeText'>
                                    {x.workshop_details.workshop_plan_id !=
                                    3 ? (
                                      <span>
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_session
                                          ? x.workshop_details
                                              .workshop_session > 1
                                            ? ` each`
                                            : ''
                                          : ''}
                                      </span>
                                    ) : x.workshop_details.workshop_plan_id ==
                                        3 &&
                                      x.workshop_details.is_common_timing ? (
                                      <span>
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_duration
                                          ? `${x.workshop_details.workshop_duration}`
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details
                                          .workshop_duration_type
                                          ? x.workshop_details
                                              .workshop_duration_type == 'hr'
                                            ? 'h'
                                            : ''
                                          : ''}
                                        {x.workshop_details &&
                                        x.workshop_details.workshop_session
                                          ? x.workshop_details
                                              .workshop_session > 1
                                            ? ` each`
                                            : ''
                                          : ''}
                                      </span>
                                    ) : (
                                      `Varies`
                                    )}
                                  </div>
                                </div>
                              </div> */}
                                <div className="workRow mb-2">
                                  <div>
                                    <span
                                      style={{
                                        color: "#42C0EF",
                                        textTransform: "uppercase",
                                        fontWeight: 800,
                                      }}
                                    >{`\u20B9 ${x.workshop_details &&
                                      x.workshop_details.workshop_amount
                                      }`}</span>
                                    <span className="hurryText">
                                      {x.workshop_details &&
                                        x.workshop_details.available_seats ? (
                                        <span>
                                          {x.workshop_details &&
                                            x.workshop_details.available_seats ? (
                                            [11, 12, 13, 14, 15].includes(
                                              x.workshop_details.available_seats
                                            ) ? (
                                              <span>
                                                <img
                                                  className="fireimg"
                                                  src={ClockImg}
                                                  alt="WorkshopFire"
                                                />
                                              </span>
                                            ) : x.workshop_details
                                              .available_seats <= 10 ? (
                                              <span>
                                                <img
                                                  className="fireimg"
                                                  src={FireImg}
                                                  alt="WorkshopFire"
                                                />
                                                Hurry!&nbsp;
                                              </span>
                                            ) : (
                                              " "
                                            )
                                          ) : (
                                            " "
                                          )}
                                          Only{" "}
                                          {x.workshop_details &&
                                            x.workshop_details.available_seats
                                            ? x.workshop_details.available_seats
                                            : ""}{" "}
                                          seat
                                          {x.workshop_details.available_seats
                                            ? x.workshop_details
                                              .available_seats > 1
                                              ? "s"
                                              : ""
                                            : ""}{" "}
                                          left
                                        </span>
                                      ) : (
                                        <span style={{ color: "#68B415" }}>
                                          &ensp;We are fully booked!
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="workRow mb-2">
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_plan_id &&
                                    [1, 2, 3].includes(
                                      parseInt(
                                        x.workshop_details.workshop_plan_id
                                      )
                                    ) &&
                                    x.workshop_details.repeats_type == "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 0 && (
                                      <div
                                        className="d-flex flex-row min-w-80per w-80per d-flex overflow-scroll"
                                        id={`workshopScroll-${i}`}
                                      >
                                        {x.workshop_details.Dates.map(
                                          (xx, DateIndex) => (
                                            <span
                                              className="pr-3 d-inline-block"
                                              key={`DateIndex-${DateIndex}`}
                                            >
                                              <div>
                                                <DateView
                                                  date={xx.workshop_Date}
                                                  time={xx.start_time}
                                                  duration={
                                                    x.workshop_details
                                                      .workshop_plan_id == 3 &&
                                                      !x.workshop_details
                                                        .is_common_timing
                                                      ? xx.duration
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  {x.workshop_details &&
                                    x.workshop_details.workshop_plan_id &&
                                    (x.workshop_details.workshop_plan_id == 1 ||
                                      x.workshop_details.workshop_plan_id ==
                                      2) &&
                                    x.workshop_details.repeats_type != "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 0 && (
                                      <DateContainer className="d-flex">
                                        <div className="d-flex align-items-center">
                                          <div className="subTitle">
                                            {x.workshop_details.repeatDays
                                              ? x.workshop_details.repeatDays
                                              : ""}
                                          </div>
                                        </div>
                                        <div className="title-border" />
                                        <div>
                                          <h4 className="title white-space-nowrap">
                                            {`
                                    ${x.workshop_details.Dates[0].workshop_Date
                                                ? Moment(
                                                  parseInt(
                                                    x.workshop_details.Dates[0]
                                                      .workshop_Date
                                                  )
                                                ).format(`MMM DD`)
                                                : ""
                                              } -
                                    ${x.workshop_details.Dates[0].workshop_Date
                                                ? Moment(
                                                  parseInt(
                                                    x.workshop_details.Dates[
                                                      x.workshop_details.Dates
                                                        .length - 1
                                                    ].workshop_Date
                                                  )
                                                ).format(`MMM DD, YYYY`)
                                                : ""
                                              }
                                    `}
                                          </h4>
                                          <div className="desc">
                                            {x.workshop_details.workshop_time
                                              ? Moment(
                                                x.workshop_details
                                                  .workshop_time,
                                                "h m a"
                                              ).format("h:mm A")
                                              : ""}
                                          </div>
                                        </div>
                                      </DateContainer>
                                    )}
                                  {x.workshop_details.workshop_plan_id &&
                                    [1, 2, 3].includes(
                                      parseInt(
                                        x.workshop_details.workshop_plan_id
                                      )
                                    ) &&
                                    x.workshop_details.repeats_type == "dnr" &&
                                    x.workshop_details.Dates &&
                                    x.workshop_details.Dates.length > 2 && (
                                      <img
                                        className="ml-3 cursor-pointer"
                                        src={dateArrow}
                                        alt="arrow"
                                        onClick={(e) => {
                                          document
                                            .getElementById(
                                              `workshopScroll-${i}`
                                            )
                                            .scrollTo({
                                              left:
                                                document.getElementById(
                                                  `workshopScroll-${i}`
                                                ).scrollLeft + 250,
                                              behavior: "smooth",
                                            });
                                        }}
                                      />
                                    )}
                                </div>
                              </Col>
                              {/* <div className='mt-2 mb-2' /> */}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2 mb-2"
                            style={{
                              height: 48,
                              border: "0.5px solid #D9D9D9",
                              borderLeft: "none",
                              borderRight: "none",
                            }}
                          >
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                width: "175.5px",
                              }}
                            >
                              <div>
                                <a
                                  className="btn-save"
                                  onClick={() => {
                                    this.updateCartData(x.id, 0, false);
                                  }}
                                >
                                  Move to cart
                                </a>
                                {/* <span className='linebar' /> */}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 48,
                                border: "0.5px solid #D9D9D9",
                              }}
                            ></div>
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                width: "175.5px",
                              }}
                            >
                              <div>
                                <a
                                  className="btn-del"
                                  onClick={() => {
                                    this.updateCartData(x.id, null, true);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* {
                          this.state.saveFor_details.length != (i + 1) &&
                          (<div className='border-bottom mt-4' />)
                        } */}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
            </Col>
            {this.state.cart_count &&
              this.state.cart_details &&
              this.state.cart_details.length > 0 && (
                <React.Fragment>
                  <Col xs={24} sm={24}>
                    <div className="mt-3">
                      <div className="row m-0 mb-6rem">
                        <div
                          header="Workshop DETAILS"
                          className="col-12 p-3 border-style"
                          key="1"
                        >
                          <div className="purchase-heading">
                            <LinesEllipsis
                              style={{ whiteSpace: "pre-wrap" }}
                              text="Purchase summary"
                              trimRight={true}
                              maxLine="4"
                              ellipsis="..."
                              basedOn="letters"
                            />
                          </div>
                          <div className="w-100 border-bottom mt-3 mb-3" />
                          <div className="purchase-row  mb-2">
                            <span className="cartitem-text">
                              Price ({this.state.total_items}{" "}
                              {this.state.total_items > 1 ? `items` : `item`})
                            </span>
                            <span className="cartitem-amt">
                              ₹ {this.state.amount}
                            </span>
                          </div>
                          <div className="w-100 border-bottom mt-3 mb-3" />
                          <div className="d-flex justify-content-between purchase-row mb-3">
                            <div className="d-flex flex-column">
                              <div className="carttotal-text">Total amount</div>
                              <div className="TaxText">
                                (Excluding GST and transaction fees)
                              </div>
                            </div>
                            <div className="carttotal-amt">
                              ₹ {this.state.total_amount}
                            </div>
                          </div>
                          <div className="mt-4 mb-4" />
                        </div>
                      </div>
                      <div id="payment-details"></div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} className="mbl-bottom-btn border-style">
                    <div className="d-flex justify-content-between w-100">
                      <div>
                        <div className="payment-totalAmount">
                          ₹ {this.state.total_amount}
                        </div>
                        <div
                          className="payment-purchaseSummary"
                          onClick={() => {
                            document
                              .getElementById(`payment-details`)
                              .scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                              });
                          }}
                        >
                          View purchase summary
                        </div>
                      </div>
                      <div>
                        <Button
                          key="proceedRegister"
                          category="workshop Contents register"
                          action="register a workshop"
                          type="primary"
                          className="btn-register btn-payment"
                          size="large"
                          onClick={this.handleSubmit}
                          disabled={this.state.is_loading}
                        >
                          Proceed to payment
                        </Button>
                      </div>
                    </div>
                  </Col>
                </React.Fragment>
              )}
          </Row>

          <Modal
            visible={this.state.changesChildpaidModal}
            closable={true}
            centered={true}
            footer={[
              <div className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1">
                <div className="fontColor-blue font-16 FontAvenirMedium" />
                <div>
                  <Button
                    key="wsubmit"
                    category="workshop Contents"
                    action="Changes required for workshop Content"
                    type="primary"
                    className="btn-changeHover"
                    size="large"
                    onClick={() =>
                      this.updateStudentCartData(
                        this.state.selectedCartID,
                        this.state.selectedWorkshopID
                      )
                    }
                    disabled={this.state.selected_studId.length < 1}
                  >
                    Continue
                  </Button>
                </div>
              </div>,
            ]}
            onCancel={() =>
              this.setState({
                selected_studId: [],
                changesChildpaidModal: false,
                studentsData: this.state.studentsData.map((x) => {
                  x.is_active = false;
                  return x;
                }),
              })
            }
          // width='30%'
          >
            <h4 className="heading">Learner selection</h4>

            <div className="border-bottom"></div>
            <div className="mt-4">
              <p className="FontAvenirRoman font-16 mb-2">
                Please select the learner to make the purchase
              </p>

              {this.state.studentsData &&
                this.state.studentsData.length > 0 &&
                this.state.studentsData.map((x, i) => (
                  <React.Fragment>
                    {x.type == "student" && (
                      <div className="col-12 p-0">
                        <Checkbox
                          onChange={(e) => this.onSelectstudentID(i, e)}
                          className="pt-2 pb-2"
                          checked={x.is_active}
                          value={x.id}
                        >
                          {x.student_details.username}
                        </Checkbox>
                      </div>
                    )}
                    {(x.type == "parent" || x.type == "adult") && (
                      <div className="col-12 p-0">
                        <Checkbox
                          onChange={(e) => this.onSelectstudentID(i, e)}
                          className="pt-2 pb-2"
                          checked={x.is_active}
                          value={x.id}
                        >
                          {x.first_name ? `Myself (${x.first_name})` : ""}
                        </Checkbox>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </Modal>

          {/* Instamojo payment gateway */}
          {/* <Modal
            visible={this.state.instamojoModal}
            closable={true}
            footer={false}
            onCancel={() =>
              this.setState({
                instamojoModal: false,
                payment_requrl: ""
              })
            }
            width='50%'
          >
            <h4 className='heading'>Payment</h4>
            <div className='border-bottom'></div>
            <div className='mt-4'>
              <iframe src={this.state.payment_requrl} width="100"></iframe>
            </div>
          </Modal> */}
        </React.Fragment>
      );
    }
  }
}
const ParentWorkshopCartView = (props) => {
  return <div>
    <AppContext.Consumer>
      {userInfo => (
        <CartView {...userInfo} {...props} />
      )}
    </AppContext.Consumer>
  </div>
}
export default ParentWorkshopCartView;
