import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Logo from "../../static/nav/breadcrumb_logo.png";
import AppStore from "../../static/appstore.svg";

class SharingProgress extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:url" content={`${process.env.REACT_APP_URL}/progress/${this.props.match.params.photo}`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={`${process.env.REACT_APP_S3_URL}/${this.props.match.params.photo}.jpeg`} />
          <meta property="og:image:secure_url" content={`${process.env.REACT_APP_S3_URL}/${this.props.match.params.photo}.jpeg`} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="@BreadcrumbHQ"/>
          <meta name="twitter:creator" content="@BreadcrumbHQ"/>
          <meta name="twitter:title" content="Breadcrumb"/>
          <meta name="twitter:image" content={`${process.env.REACT_APP_S3_URL}/${this.props.match.params.photo}.jpeg`} />
        </Helmet>
        <div className="parent-card-wrap" style={{ justifyContent: 'flex-start', position: 'fixed', top: 0, right: 0, left: 0, bottom: 0, background: 'white' }}>
          <div className="d-flex flex-column align-items-center justify-content-start" style={{ width: 1500 }}>
            <div className="parent-nav">
              <img
                src={Logo}
                alt="breadcrumb logo"
                style={{ width: 152, height: 25 }}
              />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                src={`${process.env.REACT_APP_S3_URL}/${this.props.match.params.photo}.jpeg`}
                alt="breadcrumb logo"
                style={{ width: 787, height: 443 }}
              />
              <div className="d-flex flex-column align-items-start justify-content-center p-4 mt-4" style={{ background: '#F9F9F9', borderRadius: 6, width: 787, height: 200 }}>
                <span className="font-18 mb-2" style={{fontFamily: "Bariol, sans-serif"}}>Breadcrumb is a learning app designed to assist your child to be the best learner they can be! Make the most of the content we’ve curated suited for every age group!</span>
                <span className="font-18 mb-2" style={{fontFamily: "Bariol, sans-serif"}}>Visit: <a href="https://learnwithbreadcrumb.com" target="_blank">https://learnwithbreadcrumb.com</a>
                <a style={{display: "inline-block", marginLeft: "209px"}} href="https://apps.apple.com/us/app/breadcrumb-the-learning-app/id1459486844?ls=1" target="_blank">
                    <img
                      src={AppStore}
                      alt="App store link"
                    />
                  </a>
                </span>
                {/* <div className="d-flex align-items-center justify-content-center">
                  <span className="font-18 mr-2" style={{fontFamily: "Bariol, sans-serif"}}>Download on the App Store now!</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SharingProgress;
