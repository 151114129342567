import { Breadcrumb } from "antd";
import React from "react";

const BreadcrumbPlayList = props => (
  <div className="pl-4 pr-4  row m-0">
    <div className={`col-${props.span ? props.span : "6"}`}>
      <div className="p-2 mt-2 mb-1 ">
        <Breadcrumb className="mb-3 FontAvenirBook">
          {props.data.split("/").map((data, index) => {
            if (index === props.data.split("/").length - 1) {
              return (
                <Breadcrumb.Item
                  className="FontAvenirHeavy text-capitalize"
                  key={`key-${index}`}
                  separator=" "
                >
                  {data.replace(/~~/g, "/")}
                </Breadcrumb.Item>
              );
            } else if (index !== 0) {
              if (data === "playlists") {
                return (
                  <Breadcrumb.Item
                    className="text-seconday text-capitalize"
                    key={`key-${index}`}
                    href={`/learningcontent`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              } else if (data === "un-published") {
                return (
                  <Breadcrumb.Item
                    className="text-seconday text-capitalize"
                    key={`key-${index}`}
                    href={`/learningcontent?q=2`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              } else if (data === "published") {
                return (
                  <Breadcrumb.Item
                    className="text-seconday text-capitalize"
                    key={`key-${index}`}
                    href={`/learningcontent?q=1`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              } else if (data === "archived") {
                return (
                  <Breadcrumb.Item
                    className="text-seconday text-capitalize"
                    key={`key-${index}`}
                    href={`/learningcontent?q=4`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              } else if (data === "content creator") {
                return (
                  <Breadcrumb.Item
                    className="text-seconday text-capitalize"
                    key={`key-${index}`}
                    href={`/contentcreators`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              } else {
                return (
                  <Breadcrumb.Item
                    className="text-secondary text-capitalize"
                    key={`key-${index}`}
                    href={`/learningcontent`}
                  >
                    {data}
                  </Breadcrumb.Item>
                );
              }
            }
          })}
        </Breadcrumb>
        <h4 className="text-capitalize">{props.title || ""}</h4>
      </div>
    </div>
    <div className="col-6 d-flex justify-content-end align-items-center mt-2 mb-1 ">
      {props.children}
    </div>
  </div>
);

export default BreadcrumbPlayList;
