import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import PlaylistIcon from "../../../static/expert/icons/collection.svg";

class CollectionPlaylistName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    }
  }

  handleVisibleChange = (visible) => {
    this.setState({ is_visible: visible })
  };

  render() {
    return (
      <div className="d-flex flex-column align-items-start justify-content-start">
        {this.state.is_visible ? (
          <React.Fragment>
            {this.props.data.map((coll, i) => (
              <div className="d-flex align-items-center justify-content-start mb-2">
                <div className="mr-2" style={{ height: 20, width: 20 }}>
                  <img
                    src={PlaylistIcon}
                    height="20px"
                    width="20px"
                    alt="playlist icon"
                    style={{ display: coll.isPlaylist ? 'block' : 'none' }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  {this.props.highLight ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: '#4393FC', padding: '2px 0', color: '#FFFFFF' }}
                      searchWords={[this.props.searchText]}
                      autoEscape
                      textToHighlight={coll.collply_name.toString()}
                    />
                  ) : (
                    <span>{coll.collply_name}</span>
                  )}
                  <span 
                    className="ml-2 text-primary font-weight-bold cursor-pointer" 
                    onClick={() => this.handleVisibleChange(false)}
                  >
                    {(this.props.data.length - 1) === i && `Show Less`}
                  </span>
                </div>
              </div>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.props.data.map((coll, i) => {
              if(i < 2) {
                return(
                  <div className="d-flex align-items-center justify-content-start mb-2">
                    <div className="mr-2" style={{ height: 20, width: 20 }}>
                      <img
                        src={PlaylistIcon}
                        height="20px"
                        width="20px"
                        alt="playlist icon"
                        style={{ display: coll.isPlaylist ? 'block' : 'none' }}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-start">
                      {this.props.highLight ? (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#4393FC', padding: '2px 0', color: '#FFFFFF' }}
                          searchWords={[this.props.searchText]}
                          autoEscape
                          textToHighlight={coll.collply_name.toString()}
                        />
                      ) : (
                        <span>{coll.collply_name}</span>
                      )}
                      <span 
                        className="ml-2 text-primary font-weight-bold cursor-pointer" 
                        onClick={() => this.handleVisibleChange(true)}
                      >
                        {(this.props.data.length > 2 && i === 1) ? `+${this.props.data.length - 2} More` : null}
                      </span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default CollectionPlaylistName;
