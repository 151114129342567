import PropTypes from "prop-types";
import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import styled, { css, keyframes } from "styled-components";
import pdf from "../../../static/activity/pdf.svg";
import DoCard from "../../../static/card/do.svg";
import WatchCard from "../../../static/card/watch.svg";
import Close from "../../../static/expert/icons/close.svg";
import info from "../../../static/expert/icons/info-circle.svg";
import picture from "../../../static/expert/icons/picture.svg";
import progress from "../../../static/expert/icons/progress-chart.svg";
import video from "../../../static/expert/icons/video-player.svg";
import ImgPlaceholder from "../../../static/nav/imageFallback.png";
const Img = require("react-image");

const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`;
const CardContainer = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
  img.ImgCard,
  .ImgCard.skeleton {
    min-height: 192px;
    height: 192px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    object-fit: cover;
    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
  .ImgCard .closeContainer {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .closeContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .floatingContainer {
    bottom: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    &.position-left {
      left: 10px;
      right: initial;
    }
    img {
      margin-right: 9px;
      width: 13px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  ${(props) =>
    props.rounded &&
    css`
      img.ImgCard {
        border-radius: 8px;
      }
    `}
  ${(props) =>
    props.floatingContainerLeft &&
    css`
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      img.ImgCard {
        background: #c4c4c4;
        border-radius: 8px;
      }
    `}
`;
const CardBody = styled.div`
  background: #fff;
  padding: 20px 18px;
  .heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    height: 50px;
    /* padding-bottom: 16px; */
    color: #000000;
  }
  .subHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 8px;
  }
  .DifficultyHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: #b9b9b9;
  }
  .DifficultySubHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: #262626;
  }
  ${(props) =>
    props.loading &&
    css`
      .heading,
      .subHeading,
      .difficultyContainer,
      .floatingContainer {
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        border-radius: 50px;
        height: 16px;
        background-size: 400% 100%;
        margin-bottom: 10px;
        animation: ${LoadingAnimation} 1.4s ease infinite;
      }
      .difficultyContainer {
        width: 25%;
        margin-top: -8px !important;
      }
      .subHeading {
        width: 75%;
      }
    `}
`;
const Tag = styled.div`
-webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    margin-bottom:8px;

    font-weight: 600;
    line-height: 16px;
    /* min-width:80px; */
    text-align:center;
    transition: all .3s cubic-bezier(.78, .14, .15, .86);

    ${(props) =>
      props.danger &&
      css`
        color: #d0021b;
        background: #ffdce0;
        border-color: #ffdce0;
      `}
        ${(props) =>
          props.warning &&
          css`
            color: #f5a623;
            background: rgba(245, 166, 35, 0.1);
            border-color: rgba(245, 166, 35, 0.1);
          `}
        ${(props) =>
          props.success &&
          css`
            color: #68b415;
            background: rgba(126, 211, 33, 0.1);
            border-color: rgba(126, 211, 33, 0.1);
          `}
          ${(props) =>
            props.loading &&
            css`
              background: linear-gradient(
                90deg,
                #f2f2f2 25%,
                #e6e6e6 37%,
                #f2f2f2 63%
              );
              border-radius: 50px;
              height: 16px;
              background-size: 400% 100%;
              margin-bottom: 10px;
              animation: ${LoadingAnimation} 1.4s ease infinite;
              border: none;
              width: 45%;
            `}


`;
const CounterContainer = styled.div`
  background: #36cfc9;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  left: 5px;
  top: 10px;
  /* identical to box height, or 171% */

  color: rgba(255, 255, 255, 0.97);
`;
const PopoverContentStyle = styled.div`
  position: absolute;
  bottom: 150%;
  left: 0%;
  .arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: -3px;
    border-top-color: transparent;
    border-right-color: #000000;
    border-bottom-color: #000000;
    border-left-color: transparent;
    -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    /* left: 16px; */
    left: 84px;
    /* background-color: #000000; */
  }
  .popoverInner {
    background-color: #000000;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-clip: padding-box;
    color: #fff;
    font-size: 12px;
    min-width: 220px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;
const PopoverContent = (props) => {
  return (
    <PopoverContentStyle>
      <div className="arrow" />
      <div className="popoverInner">
        Based on age group selected, cards will be displayed in iPad
        {props.data.Easy.length !== 0 && (
          <div>{`${
            props.defaultActivitiesId !== 2 ? "Easy: " : ""
          }${props.data.Easy.map((data) => data).join(", ")}`}</div>
        )}
        {props.defaultActivitiesId !== 2 && (
          <React.Fragment>
            {props.data.Medium.length !== 0 && (
              <div>{`Medium: ${props.data.Medium.map((data) => data).join(
                ", "
              )}`}</div>
            )}
            {props.data.Hard.length !== 0 && (
              <div>{`Hard: ${props.data.Hard.map((data) => data).join(
                ", "
              )}`}</div>
            )}
          </React.Fragment>
        )}
        {/* <div>Medium: 7 - 8 years</div> */}
      </div>
    </PopoverContentStyle>
  );
};

class Card extends Component {
  state = {
    visible: false,
  };
  setVisible = (value) => {
    this.setState({ visible: value });
  };
  render() {
    return (
      <div>
        <CardContainer
          floatingContainerLeft={this.props.floatingContainerLeft}
          rounded={this.props.rounded}
          loading={this.props.isLoading}
        >
          <div className="position-relative">
            {this.props.close && (
              <div onClick={this.props.onClose}>
                <img className="closeContainer img-fluid" src={Close} />
              </div>
            )}
            {this.props.counter && (
              <CounterContainer>{this.props.counter}</CounterContainer>
            )}
            {/* <ProgressiveImage
              src={this.props.src}
              placeholder={ImgPlaceholder}
              // onError={err => {
              //   console.log(err);
              //   return (
              //     <img
              //       className="w-100 ImgCard h-100"
              //       src={ImgPlaceholder}
              //       alt="activity card"
              //     />
              //   );
              // }}
            >
              {(src, loading) => {
                return loading ? (
                  <div className="ImgCard w-100 h-100 skeleton" />
                ) : (
                  <img
                    className="w-100 ImgCard h-100"
                    src={src}
                    alt="activity card"
                  />
                );
              }}
            </ProgressiveImage> */}
            {!this.props.isLoading && (
              <Img
                className="w-100 ImgCard h-100"
                src={this.props.src}
                loader={<div className="ImgCard w-100 h-100 skeleton" />}
                unloader={
                  <img
                    className="w-100 ImgCard h-100"
                    src={ImgPlaceholder}
                    alt="activity card"
                  />
                }
              />
            )}
            {this.props.isLoading && (
              <div className="ImgCard w-100 h-100 skeleton" />
            )}
            {this.props.floatingContainer && (
              <React.Fragment>
                {(this.props.videoRefIcon ||
                  this.props.stepsRefIcon ||
                  this.props.imgRefIcon ||
                  this.props.pdfRefIcon) && (
                  <React.Fragment>
                    {!this.props.isLoading && (
                      <div
                        className={`position-absolute floatingContainer p-1 pl-2 pr-2 ${
                          this.props.floatingContainerLeft
                            ? "position-left"
                            : ""
                        }`}
                      >
                        {this.props.videoRefIcon && (
                          <img src={video} className="img-fluid" alt="" />
                        )}
                        {this.props.imgRefIcon && (
                          <img src={picture} className="img-fluid " alt="" />
                        )}
                        {this.props.stepsRefIcon && (
                          <img src={progress} className="img-fluid" alt="" />
                        )}
                        {this.props.pdfRefIcon && (
                          <img src={pdf} className="img-fluid " alt="" />
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <CardBody loading={this.props.isLoading}>
            {/* <div className="heading">{this.props.title}</div> */}
            <div className="w-100 heading">
              {this.props.title && (
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={this.props.title}
                  trimRight={true}
                  maxLine="2"
                  ellipsis="..."
                  basedOn="letters"
                />
              )}
            </div>
            {this.props.feedback && (
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="DifficultyHeading">
                    Learners who have completed:
                  </div>{" "}
                  <div className="ml-2 DifficultySubHeading">
                    {this.props.subHeading}
                  </div>
                </div>
                <div>
                  {this.props.defaultActivitiesId === 1 ? (
                    "Read"
                  ) : this.props.defaultActivitiesId === 2 ? (
                    <img src={WatchCard} className="img-fluid " alt="" />
                  ) : this.props.defaultActivitiesId === 3 ? (
                    "Listen"
                  ) : this.props.defaultActivitiesId === 4 ? (
                    <img src={DoCard} className="img-fluid " alt="" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {!this.props.feedback && (
              <React.Fragment>
                <div className="subHeading">{this.props.subHeading}</div>
                {this.props.isLoading && (
                  <Tag
                    loading={this.props.isLoading}
                    danger={this.props.danger || false}
                    warning={this.props.warning || false}
                    success={this.props.danger || true}
                  >
                    {""}
                  </Tag>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    {this.props.Tag && !this.props.isLoading && (
                      <React.Fragment>
                        {this.props.Tag.map((data, index) => {
                          if (data.name !== "" && data.name)
                            return (
                              <Tag
                                key={`Tags-${index}`}
                                danger={data.type === "danger"}
                                warning={data.type === "warning"}
                                success={data.type === "success"}
                              >
                                {data.name === "Pending"
                                  ? "Pending approval"
                                  : data.name}
                              </Tag>
                            );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                  <div>
                    {this.props.defaultActivitiesId === 1 ? (
                      "Read"
                    ) : this.props.defaultActivitiesId === 2 ? (
                      <img src={WatchCard} className="img-fluid " alt="" />
                    ) : this.props.defaultActivitiesId === 3 ? (
                      "Listen"
                    ) : this.props.defaultActivitiesId === 4 ? (
                      <img src={DoCard} className="img-fluid " alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-1 difficultyContainer">
                  {this.props.difficultyVisible && (
                    <div
                      className="d-flex align-items-center justify-content-center position-relative"
                      onMouseOver={() => {
                        this.setVisible(true);
                      }}
                      onMouseOut={() => {
                        this.setVisible(false);
                      }}
                    >
                      <div className="DifficultyHeading">
                        {!this.props.isLoading ? "Difficulty:" : ""}
                      </div>
                      <div className="ml-2 DifficultySubHeading ">
                        {!this.props.isLoading && <img src={info} alt="info" />}
                        {this.state.visible && (
                          <div>
                            <PopoverContent
                              data={this.props.difficulty}
                              defaultActivitiesId={
                                this.props.defaultActivitiesId
                              }
                            ></PopoverContent>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {this.props.time && (
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="DifficultyHeading">Time:</div>{" "}
                      <div className="ml-2 DifficultySubHeading">
                        {this.props.time} mins
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </CardBody>
        </CardContainer>
      </div>
    );
  }
}

// const Card = props => {
//   // const [visible, setVisible] = useState(false);
//   // const [hidden, setHidden] = useState(true);

//   return (
//   );
// };

Card.propTypes = {
  src: PropTypes.string,
  videoRefIcon: PropTypes.bool,
  imgRefIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  stepsRefIcon: PropTypes.bool,
  pdfRefIcon: PropTypes.bool,
  rounded: PropTypes.bool,
  floatingContainer: PropTypes.bool,
  floatingContainerLeft: PropTypes.bool,
  close: PropTypes.bool,
  Tag: PropTypes.array,
  danger: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

export default Card;
