import { Spin } from "antd";
import axios from "axios";
import React, { Component } from "react";
// import { hot } from 'react-hot-loader'
import { Detector } from "react-detect-offline";
import { version } from "../package.json";
import "./App.css";
import { AppContext } from "./context/AppContext";
import Main from "./main";
const moment = require("moment");

console.log(`${process.env.REACT_APP_ENV}`);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      userInfo: {}
    };
  }
  getWebBuildVersion = () => {
    axios
      .get(`${process.env.REACT_APP_RESTAPI}/getWebBuildVersion`)
      .then((res) => {
        let data = res.data;
        if (!data.error) {
          console.log(
            moment(version, "YYYY-MM-DD") < moment(data.buildDate, "YYYY-MM-DD")
          );
          if (
            moment(version, "YYYY-MM-DD") < moment(data.buildDate, "YYYY-MM-DD")
          ) {
            if (caches) {
              // Service worker cache should be cleared with caches.delete()
              caches.keys().then((names) => {
                for (const name of names) {
                  caches.delete(name);
                }
              });
              // delete browser cache and hard reload
              window.location.reload(true);
              this.setState({
                isLoad: true,
              });
            }
          } else {
            this.setState({
              isLoad: true,
            });
          }
        } else {
          this.setState({
            isLoad: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    process.env.NODE_ENV !== "development" && this.getWebBuildVersion();
  }

  render() {
    return (
      <div className="App Container">
        {typeof navigator !== "undefined" && /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(navigator.userAgent) ? <>
          {this.state.isLoad ? <AppContext.Provider value={{
            userInfo: this.state.userInfo,
            setUserInfo: (data) => {
              this.setState({ userInfo: data })
            }
          }}>

            <Main />
          </AppContext.Provider>
            : ``}
        </> :
          <Detector
            render={({ online }) => (
              <Spin
                className="noNetError"
                spinning={!online}
                tip="No Internet Connection. Reconnecting..."
              >
                {this.state.isLoad ? <AppContext.Provider value={{
                  userInfo: this.state.userInfo,
                  setUserInfo: (data) => {
                    this.setState({ userInfo: data })
                  }
                }}>

                  <Main />
                </AppContext.Provider>
                  : ``}
              </Spin>
            )}
          />}
      </div>
    );
  }
}

// export default hot(module)(App)
export default App;
