import { Button, Checkbox, Modal, Radio, Tooltip } from 'antd'
import { Select } from 'antd/lib/index'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import YearPicker from 'rc-year-picker'
import React from 'react'
import Form from 'react-validation/build/form'
import styled from 'styled-components'
import '../../App.css'
import AccountLogo from '../../assets/Account_created.svg'
import arrowLeft from '../../assets/arrow_left.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import InputC from '../../components/InputC'
import { SIGNUP } from '../../graphql'
import { country_codes } from '../../static/countryCodes.json'
import BGBLANKLOGO from '../../static/nav/bg_blank_img.svg'
import MBLBGBLANKLOGO from '../../static/nav/mbl_bg_blank_logo.svg'
import { openNotification, SetTokenCookie, triggerGAEvent } from '../../utils'
import { isRequired } from '../../validation'
import './Signin.css'
const { confirm } = Modal

const MaxSignup = styled.div`
  background: url(${BGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  @media (max-width: 576px) {
    display: none !important;
  }
`

const MblCard = styled.div`
  background: url(${MBLBGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  .cardd1 {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 12px;
    padding: 40px;
  }
  @media (max-width: 576px) {
    .mblSignIn {
      width: 90% !important;
      padding: 1.5rem 0 !important;
    }
    background: #f9fafb;
    .cusColor .ant-radio-checked .ant-radio-inner {
      border-color: #4c39b3 !important;
    }
    .cusColor > .ant-radio-checked .ant-radio-inner::after {
      background-color: #4c39b3 !important;
    }
    .mbl-cardStart {
      display: none;
    }
    justify-content: center !important;
    .navSticky {
      position: sticky;
      top: 0;
      z-index: 1000;
    }
    .btnBottomSticky {
      box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
      background: #fff;
      position: sticky;
      bottom: 0;
      padding: 16px;
      z-index: 1000;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  border-radius: 4px;
  margin: 0 auto;
`

class SocialSignUpAccountSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      whatsappVal: false,
      login: {
        email: '',
        first_name: '',
        last_name: '',
        address: '',
        phone_no: '',
        country: 'India',
        country_code: '+91',
        dob: '',
        childCheck: 'no'
      },
      truthObj: {
        email: false,
        first_name: false,
        last_name: false,
        address: false,
        country: false,
        phone_no: false,
        dob: false
      },
      form: React.createRef(),
      submitted: false
    }
  }

  showConfirm = () => {
    let confirmPopup = confirm({
      icon: null,
      content: (
        <React.Fragment>
          <div className='d-flex align-items-center flex-column'>
            <div>
              <img src={AccountLogo} className='' alt='logo' />
            </div>
            <div className='mt-4 mb-2'>
              <h3
                style={{
                  color: '#111827',
                  fontSize: 22,
                  fontWeight: 800
                }}
              >
                Account created
              </h3>
            </div>
            <div
              style={{
                textAlign: 'center',
                color: '#4F4F4F',
                fontSize: 14,
                fontWeight: 400
              }}
            >
              You can continue to use Breadcrumb as a learner yourself or  add a child if you're setting up Breadcrumb for them
            </div>
            <div className='mt-3 d-flex justify-content-between'>
              <div>
                <Button
                  style={{
                    height: 40,
                    color: '#333333',
                    width: 100
                  }}
                  type='link'
                  block
                  size='large'
                  onClick={() => {
                    triggerGAEvent('Login', 'Logging in as Parent')
                    SetTokenCookie()
                    confirmPopup.destroy()
                    window.open(
                      process.env.REACT_APP_LEARNER_APP + "dashboard"
                    )
                    // this.props.history.push(`/parent/workshops`)
                  }}
                >
                  Continue
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    height: 40,
                    color: '#fff',
                    background: '#4C39B3',
                    borderRadius: 6,
                    border: 'none',
                    width: 120
                  }}
                  type='primary'
                  block
                  size='large'
                  onClick={() => {
                    triggerGAEvent('Login', 'Child setup with as Parent')
                    confirmPopup.destroy()
                    this.props.history.push(`/childSetup`)
                  }}
                >
                  Add a child
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      centered: true
    })
  }

  parentSignup = () => {
    let mutation = SIGNUP

    mutation.variables = {
      first_name: this.state.login.first_name,
      last_name: this.state.login.last_name,
      email: this.state.login.email,
      city: this.state.login.address,
      phone_number: `${this.state.login.country_code} ${this.state.login.phone_no}`,
      country: this.state.login.country,
      ID_token: this.state.login.accessToken,
      loginType: this.state.login_type,
      year_of_birth: this.state.login.dob,
      is_whatsapp: this.state.whatsappVal
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          triggerGAEvent('Signup', 'Signup as Parent')
          openNotification('error', res.data.errors[0].message)
        } else {
          var result = res.data.data.webSignup
          console.log('result ', result)
          if (result.isEmailExist) {
            openNotification('error', result.message)
          } else if (this.state.login.childCheck == 'yes') {
            localStorage.setItem('token', result.token)
            localStorage.setItem('role_id', result.role_id)
            localStorage.setItem(
              'isChild',
              result.is_childAdded ? result.is_childAdded : false
            )
            this.setState(
              {
                login: {
                  email: '',
                  first_name: '',
                  last_name: '',
                  address: '',
                  dob: '',
                  phone_no: '',
                  country: 'India',
                  country_code: '+91',
                  childCheck: 'no'
                }
              },
              () => this.showConfirm()
            )
          } else {
            if (result.status == 'false') {
              localStorage.setItem('token', result.token)
              localStorage.setItem('role_id', result.role_id)
              localStorage.setItem(
                'isChild',
                result.is_childAdded ? result.is_childAdded : false
              )

              // openNotification('success', result.message);
              if (result.role_id === 3) {
                triggerGAEvent('Login', 'Logging in as Parent')
                this.setState(
                  {
                    login: {
                      email: '',
                      first_name: '',
                      last_name: '',
                      address: '',
                      dob: '',
                      phone_no: '',
                      country: 'India',
                      country_code: '+91',
                      childCheck: 'no'
                    }
                  },
                  () =>
                    this.props.history.push(
                      `/parent/workshops${result.isNewLogin ? '?isTour=1' : ''}`
                    )
                )
              }
            }
          }
        }
      })
      .catch(err => console.log(err))
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.login)
    getKeys.forEach((key, index) => {
      let getItem = this.state.login[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    let re = /\S+@\S+\.\S+/
    truthObj['email'] = !re.test(this.state.login.email)
    truthObj['phone_no'] = this.state.login.phone_no
      ? this.state.login.phone_no.length < 10
      : true
    let dobPic = document.getElementsByClassName('year-picker')
    if (!this.state.login.dob) {
      dobPic[0].id = 'has-error'
    } else {
      dobPic[0].id = ''
      truthObj['dob'] = false
    }
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please fill all mandatory fields correctly')
      this.setState({ isFormSubmitting: false })
    } else {
      this.parentSignup()
    }
  }

  showPwdClick = key => {
    triggerGAEvent('Signup', 'Show password in Login')
    const state = { ...this.state }
    state.showHide[key] = !state.showHide[key]
    this.setState({ showHide: state.showHide })
  }

  updateParentData = (key, value) => {
    let login = { ...this.state.login }
    if (key == 'dob') {
      if (!moment(value).isBefore(moment().subtract(18, 'years'))) {
        openNotification(
          'error',
          'You must be atleast 18 years of age, to Sign Up as an adult. '
        )
        login[key] = ''
        this.setState({
          login
        })
        return
      }
    }
    if (key == 'address') {
      if (/^[0-9]+$/.test(value)) {
        return
      }
    }
    login[key] = value
    this.setState({
      login
    })
  }

  componentDidMount() {
    let { login } = this.state
    let data = this.props.location.state
    if (!data) {
      this.props.history.push('/signup')
    } else {
      login.accessToken = data.token
      login.first_name =
        data.userData.given_name == undefined ? '' : data.userData.given_name
      login.last_name =
        data.userData.family_name == undefined ? '' : data.userData.family_name
      login.email = data.userData.email
      login.country = 'India'
      login.country_code = '+91'
      login.childCheck = 'no'
      this.setState({
        login,
        login_type: data.signupType
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <MaxSignup>
          {/* <div>
            <Menu
              className='pr-2 w-100 parentMenu'
              mode='horizontal'
              style={{
                background: '#4C39B3',
                boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
              }}
            >
              <Menu.Item
                key='logo'
                index='1'
                className='pl-0'
                title={`BREADCRUMB`}
              >
                <img
                  src={ParentLogo}
                  alt='breadcrumb logo'
                  style={{ width: 230, marginBottom: -1 }}
                />
              </Menu.Item>
            </Menu>
          </div> */}
          <div className='pt-4 pb-5'>
            <div
              className='mbl-signup-card mbl-card-radius mx-auto'
              style={{ width: 584, padding: 30 }}
            >
              <div className='d-flex align-items-center mb-4'>
                <div className="mr-4">
                  <img
                    src={arrowLeft}
                    style={{
                      height: 20
                    }}
                    className='cursor-pointer'
                    alt='left arrow'
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className='w-100'>
                  <h3
                    className='max-signup-title FontBariolHeavy text-center'
                    style={{ fontSize: 36, marginBottom: 0 }}
                  >
                    Let’s set up your account
                  </h3>
                </div>
              </div>
              <div className='mb-0'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      First Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Last Name
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Email
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter Email ID'
                        id={this.state.truthObj['email'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.email}
                        stateKey='email'
                        validations={[]}
                        disabled={
                          this.state.login.email
                            ? this.state.login_type == 'apple'
                              ? false
                              : true
                            : false
                        }
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Country
                    </div>
                    <div className='col-8'>
                      <Select
                        placeholder='Select country'
                        defaultValue='+91~~India'
                        className='w-100'
                        size='large'
                        value={`${this.state.login.country_code}~~${this.state.login.country}`}
                        onChange={value => {
                          let login = { ...this.state.login }
                          login.country_code = value.split('~~')[0]
                          login.country = value.split('~~')[1]
                          this.setState({
                            login
                          })
                        }}
                      >
                        {country_codes.map((code, index) => (
                          <Select.Option
                            key={index}
                            value={`${code.dial_code}~~${code.name}`}
                          >{`${code.name} (${code.dial_code})`}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      City
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='text'
                        placeholder='Enter City'
                        id={this.state.truthObj['address'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.address}
                        stateKey='address'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-4'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Age&ensp;
                      <Tooltip
                        placement='right'
                        title={`Based on your age category, we will display the workshops that you can learn from your web interface`}
                      >
                        <span
                          className='FontBariolHeavy font-14'
                          style={{
                            color: '#4C39B3'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-8'>
                      <YearPicker
                        value={this.state.login.dob}
                        placeholder='Select Year Of Birth'
                        onChange={date =>
                          this.updateParentData('dob', date.toString())
                        }
                      ></YearPicker>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-1'>
                    <div
                      className='col-4 FontBariolMedium'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Phone Number
                    </div>
                    <div className='col-8'>
                      <InputC
                        type='number'
                        size='large'
                        placeholder='Enter Phone Number'
                        id={this.state.truthObj['phone_no'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol font-16 w-100'
                        titleStyle='FontBariolMedium text-darkBlack font-16'
                        value={this.state.login.phone_no}
                        stateKey='phone_no'
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-4'></div>
                    <div className='col-1 pb-3'>
                      <Checkbox
                        onChange={e =>
                          this.setState({
                            whatsappVal: e.target.checked
                          })
                        }
                        value={this.state.whatsappVal}
                        className='pr-0'
                      ></Checkbox>
                    </div>
                    <div className='col-7 p-0'>
                      <span
                        className="FontBariol"
                        style={{
                          color: '#4F4F4F',
                          fontSize: 12
                        }}
                      >
                        Tap if this is your Whatsapp number to receive reminders
                        and updates from us
                      </span>
                    </div>
                  </div>
                  <div
                    className='row align-items-center justify-content-center m-0 mb-4'
                    style={{
                      background: '#FAFAFA'
                    }}
                  >
                    <div
                      className='col-7 FontBariol'
                      style={{
                        color: '#333333',
                        fontSize: 16,
                        padding: 12
                      }}
                    >
                      Would you like to setup a child profile?
                    </div>
                    <div className='col-5'>
                      <Radio.Group
                        onChange={e =>
                          this.updateParentData('childCheck', e.target.value)
                        }
                        value={this.state.login.childCheck}
                      >
                        <Radio value={`yes`}>Yes</Radio>
                        <Radio value={`no`}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      className='mbl-btn-bottom'
                      type='primary'
                      size='large'
                      onClick={this.validateStateValues}
                      style={{
                        background: '#4C39B3',
                        border: 0,
                        width: 232,
                        height: 50
                      }}
                    >
                      Create Account
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </MaxSignup>

        {/* Mbl signup page */}
        <MblCard className='d-flex'>
          <div className='mblSignIn'>
            {/* <div className='navSticky'>
              <Menu
                className='pr-2 w-100'
                mode='horizontal'
                style={{
                  background: '#4C39B3',
                  boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
                }}
              >
                <Menu.Item
                  key='logo'
                  index='1'
                  className='pl-0'
                  title={`BREADCRUMB`}
                >
                  <img
                    src={ParentLogo}
                    alt='breadcrumb logo'
                    style={{ width: 230, marginBottom: -1 }}
                  />
                </Menu.Item>
              </Menu>
            </div> */}
            <div
              className='card-w-auto cardd1'
              style={{
                padding: 16
              }}
            >
              <div className='d-flex align-items-center pt-3 mb-2'>
                <div>
                  <img
                    src={arrowLeft}
                    className='cursor-pointer'
                    alt='left arrow'
                    style={{
                      height: 25
                    }}
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className='ml-4'>
                  <img
                    style={{
                      height: 32,
                      width: 191
                    }}
                    src={mbllogo}
                    className='mbl-logo1'
                    alt='logo'
                  />
                </div>
              </div>
              <div
                className='pt-2 mb-2 FontBariolHeavy text-center'
                style={{
                  color: '#000',
                  fontSize: 28
                }}
              >
                Let’s set up your account
              </div>

              <div className='mb-0'>
                <Form
                  ref={this.state.form}
                  className={this.state.submitted ? 'formSubmitted' : ''}
                >
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='First Name'
                        placeholder='Enter Your First Name'
                        id={
                          this.state.truthObj['first_name'] ? 'has-error' : ''
                        }
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.first_name}
                        stateKey='first_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='Last Name'
                        placeholder='Enter Your Last Name'
                        id={this.state.truthObj['last_name'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.last_name}
                        stateKey='last_name'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='Email'
                        placeholder='Enter Email ID'
                        id={this.state.truthObj['email'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.email}
                        stateKey='email'
                        validations={[]}
                        disabled={
                          this.state.login.email
                            ? this.state.login_type == 'apple'
                              ? false
                              : true
                            : false
                        }
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Country
                    </div>
                    <div className='col-12'>
                      <Select
                        placeholder='Select country'
                        defaultValue='+91~~India'
                        className='w-100'
                        size='large'
                        value={`${this.state.login.country_code}~~${this.state.login.country}`}
                        onChange={value => {
                          let login = { ...this.state.login }
                          login.country_code = value.split('~~')[0]
                          login.country = value.split('~~')[1]
                          this.setState({
                            login
                          })
                        }}
                      >
                        {country_codes.map((code, index) => (
                          <Select.Option
                            key={index}
                            value={`${code.dial_code}~~${code.name}`}
                          >{`${code.name} (${code.dial_code})`}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div className='col-12'>
                      <InputC
                        type='text'
                        title='City'
                        placeholder='Enter City'
                        id={this.state.truthObj['address'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.address}
                        stateKey='address'
                        validations={[]}
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-2'>
                    <div
                      className='col-12 FontBariolMedium mb-2'
                      style={{
                        fontSize: 16,
                        color: '#333333'
                      }}
                    >
                      Age&ensp;
                      <Tooltip
                        placement='right'
                        title={`Based on your age category, we will display the workshops that you can learn from your web interface`}
                      >
                        <span
                          className='FontBariolHeavy font-14'
                          style={{
                            color: '#4C39B3'
                          }}
                        >
                          Why?
                        </span>
                      </Tooltip>
                    </div>
                    <div className='col-12'>
                      <YearPicker
                        value={this.state.login.dob}
                        placeholder='Select Year Of Birth'
                        onChange={date =>
                          this.updateParentData('dob', date.toString())
                        }
                      ></YearPicker>
                    </div>
                  </div>
                  <div className='row align-items-center justify-content-center mb-3'>
                    <div className='col-12'>
                      <InputC
                        type='number'
                        title='Phone Number'
                        size='large'
                        placeholder='Enter Phone Number'
                        id={this.state.truthObj['phone_no'] ? 'has-error' : ''}
                        className='mbl-signup-input FontBariol font-16 w-100'
                        titleStyle='FontBariolMedium mb-2 font-16'
                        value={this.state.login.phone_no}
                        stateKey='phone_no'
                        onChange={(key, value) =>
                          this.updateParentData(key, value)
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-1'>
                      <Checkbox
                        onChange={e =>
                          this.setState({
                            whatsappVal: e.target.checked
                          })
                        }
                        value={this.state.whatsappVal}
                        className='pr-0'
                      ></Checkbox>
                    </div>
                    <div className='col-10 col-md-10 col-sm-10'>
                      <span
                        className="FontBariol"
                        style={{
                          color: '#4F4F4F',
                          fontSize: 12
                        }}
                      >
                        Tap if this is your Whatsapp number to receive reminders
                        and updates from us
                      </span>
                    </div>
                  </div>
                  <div
                    className='d-flex flex-column justify-content-between m-0 mb-2'
                    style={{
                      background: '#FFF',
                      padding: '10px 0'
                    }}
                  >
                    <div
                      className='FontBariolMedium pb-2'
                      style={{
                        color: '#333333',
                        fontSize: 12
                      }}
                    >
                      Would you like to setup a child profile?
                    </div>
                    <div>
                      <Radio.Group
                        onChange={e =>
                          this.updateParentData('childCheck', e.target.value)
                        }
                        value={this.state.login.childCheck}
                      >
                        <Radio className='cusColor' value={`yes`}>
                          Yes
                        </Radio>
                        <Radio className='cusColor' value={`no`}>
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={this.validateStateValues}
                    style={{
                      background: '#4C39B3',
                      border: 0,
                      height: 42
                    }}
                  >
                    Create Account
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </MblCard>
      </React.Fragment>
    )
  }
}

export default SocialSignUpAccountSetup
