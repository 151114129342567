import { Button, Card } from 'antd';
import React from 'react';
import Form from 'react-validation/build/form';
import '../App.css';
import logo from '../assets/breadcrumb_logo.png';
import InputC from '../components/InputC';
import { confirmpassword, password, required } from '../validation';
import './ResetPswd.css';
import './SignIn/Signin.css';
// import Input from 'react-validation/build/input';

class ResetPswd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      showHide: {
        showPwd: false,
        showconfirmpwd: false
      },
      form: React.createRef(),
      isSubmit: false
      // password:props.currState.password,
      // confirm:props.currState.confirm
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    this.state.form.current.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll('.form-error').length === 0) {
        this.props.next();
      }
    }, 1);
  };

  onChange = (key, value) => {
    this.props.onChange(key, value);
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  showPwdClick = (key) => {
    const state = { ...this.state };
    state.showHide[key] = !state.showHide[key];
    this.setState({ showHide: state.showHide })
  };

  render() {
    return (
      <div className="card-wrap">
        <div className="">
          <img src={logo} className="logo" alt="logo" />
          <Card className="card-radius">
            <Form ref={this.state.form} className={this.state.isSubmit ? "formSubmitted" : ""}>
              <h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18">
                {this.props.is_set ? 'Set Password' : 'Reset Password'}
              </h3>
              <InputC type="password"
                placeholder="Enter password"
                title="Set Password"
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.props.currState.password}
                stateKey="password"
                name="password"
                showPwd={this.state.showHide.showpwd}
                onChange={this.onChange}
                showPwdClick={this.showPwdClick}
                showPwdKey="showpwd"
                validations={[required, password, confirmpassword]}
                autoFocus 
              />
              <InputC type="password"
                placeholder="Confirm password"
                title="Confirm New Password"
                name="confirm"
                showPwd={this.state.showHide.showconfirmpwd}
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.props.currState.confirm}
                stateKey="confirm"
                onChange={this.onChange}
                showPwdClick={this.showPwdClick}
                validations={[required, password, confirmpassword]}
                showPwdKey="showconfirmpwd"
              />
              <div className="hint mb-3 pb-4 border-bottom">
                Use 8 or more characters with a mix of letters, numbers & symbols
            </div>
              <Button type="primary" ghost onClick={(e) => { this.handleSubmit(e) }} block size="large">
                Set new password & continue
              </Button>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

// const ResetPswd = Form.create()(App);

export default ResetPswd;
