import {
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Menu,
  Dropdown
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { Prompt } from 'react-router-dom'
import Form from 'react-validation/build/form'
import ButtonC from '../../components/ButtonC'
import CategoryCard from '../../components/Card/CategoryCard'
import ImageUploadCrop from '../../components/ImageUploadCrop'
import InputC from '../../components/InputC'
import Loading from '../../components/Loading'
import YearPicker from 'rc-year-picker'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'
import {
  GET_LC_PROFILE,
  GET_PROFILE,
  SAVE_PROFILE,
  USER_CHANGE_PASSWORD
} from '../../graphql'
import emptyStateActivity from '../../static/activity/emptystateactivity.svg'
import noProfileImage from '../../static/EmptyProfileplace.png'
import { isValidPassword, openNotification, triggerGAEvent } from '../../utils'
import {
  confirm_password,
  isRequired,
  password,
  required
} from '../../validation'
import './index.css'
import styled, { css, keyframes } from 'styled-components'

class Profile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmDialog: null,
      shouldBlockNavigation: false,
      is_loading: false,
      interest_visible: false,
      password_visible: false,
      edit: false,
      all_interests: [],
      selected_interests: [],
      checked_interests: [],
      login_type: 'email',
      user_details: {
        name: '',
        last_name: '',
        displayName: '',
        email: '',
        profile_image: '',
        country: '',
        address: '',
        phone_no: '',
        bio: '',
        latitude: '',
        longitude: '',
        year_of_birth: ''
      },
      new_user_details: {
        name: '',
        last_name: '',
        displayName: '',
        email: '',
        profile_image: '',
        country: '',
        address: '',
        phone_no: '',
        bio: '',
        latitude: '',
        longitude: '',
        year_of_birth: ''
      },
      truthObj: {
        name: false,
        last_name: false,
        email: false,
        profile_image: false,
        displayName: false,
        address: false,
        country: false,
        phone_no: false,
        bio: false,
        latitude: false,
        longitude: false,
        year_of_birth: false
      },
      change_password: {
        current_password: '',
        new_password: '',
        confirm_password: ''
      },
      show_hide: {
        current_password: false,
        new_password: false,
        confirm_password: false
      }
    }
  }

  fetchLcData = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_LC_PROFILE)
      .then(res => {
        this.setState({
          is_loading: false,
          all_interests: res.data.data.getLcInterestCategory.intrest_cat,
          selected_interests: res.data.data.getInterestCategory.intrest_cat
        })
      })
      .catch(err => console.log(err))
  }

  fetchProfileData = () => {
    this.setState({ is_loading: true })
    axios
      .post(`${process.env.REACT_APP_API}`, GET_PROFILE)
      .then(res => {
        let user_details = {
          address: res.data.data.getLearningCentre.user_details.address
            ? res.data.data.getLearningCentre.user_details.address
            : '',
          bio: res.data.data.getLearningCentre.user_details.bio
            ? res.data.data.getLearningCentre.user_details.bio
            : '',
          email: res.data.data.getLearningCentre.user_details.email
            ? res.data.data.getLearningCentre.user_details.email
            : '',
          name: res.data.data.getLearningCentre.user_details.name
            ? res.data.data.getLearningCentre.user_details.name
            : '',
          last_name: res.data.data.getLearningCentre.user_details.last_name
            ? res.data.data.getLearningCentre.user_details.last_name
            : '',
          country: res.data.data.getLearningCentre.user_details.country
            ? res.data.data.getLearningCentre.user_details.country
            : '',
          displayName: res.data.data.getLearningCentre.user_details.displayName
            ? res.data.data.getLearningCentre.user_details.displayName
            : '',
          phone_no: res.data.data.getLearningCentre.user_details.phone_no
            ? res.data.data.getLearningCentre.user_details.phone_no
            : '',
          profile_image: res.data.data.getLearningCentre.user_details
            .profile_image
            ? res.data.data.getLearningCentre.user_details.profile_image
            : '',
          latitude: res.data.data.getLearningCentre.user_details.latitude
            ? `${res.data.data.getLearningCentre.user_details.latitude}`
            : '',
          longitude: res.data.data.getLearningCentre.user_details.longitude
            ? `${res.data.data.getLearningCentre.user_details.longitude}`
            : '',
          year_of_birth: res.data.data.getLearningCentre.user_details
            .year_of_birth
            ? `${res.data.data.getLearningCentre.user_details.year_of_birth}`
            : ''
        }

        this.setState({
          is_loading: this.state.role_id === '2',
          user_details: user_details,
          new_user_details: user_details,
          login_type: res.data.data.getLearningCentre.user_details.loginType
            ? res.data.data.getLearningCentre.user_details.loginType
            : 'email'
        })
      })
      .catch(err => console.log(err))
  }

  saveProfile = () => {
    let query = SAVE_PROFILE
    query.variables = {
      ...this.state.new_user_details,
      profileImage: this.state.new_user_details.profile_image,
      latitude: parseFloat(this.state.new_user_details.latitude),
      longitude: parseFloat(this.state.new_user_details.longitude),
      interest_categories_id: this.state.selected_interests.map(int =>
        Number(int.id)
      )
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          edit: false,
          shouldBlockNavigation: false,
          is_loading: false
        })
        this.fetchData()
      })
      .catch(err => console.log(err))
  }

  validateStateValues = () => {
    let truthObj = {}
    let getKeys = Object.keys(this.state.new_user_details)
    getKeys.forEach(key => {
      truthObj[key] = isRequired(this.state.new_user_details[key])
    })
    if (this.state.role_id !== 2) {
      truthObj['latitude'] = false
      truthObj['longitude'] = false
      truthObj['displayName'] = false
      truthObj['address'] = false
      truthObj['bio'] = false
      truthObj['year_of_birth'] = false
    }
    console.log('truthObj', truthObj)
    console.log('new_user_details', this.state.new_user_details)
    this.setState({ truthObj: truthObj }, () => this.checkErrorExistence())
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please fill all mandatory fields correctly')
    } else {
      this.saveProfile()
    }
  }

  onPasswordOk = () => {
    triggerGAEvent('Profile', 'Change Password')
    if (
      isValidPassword.test(this.state.change_password.new_password) &&
      this.state.change_password.new_password ===
        this.state.change_password.confirm_password
    ) {
      let query = USER_CHANGE_PASSWORD
      query.variables = {
        oldpassword: this.state.change_password.current_password,
        password: this.state.change_password.confirm_password
      }
      axios
        .post(`${process.env.REACT_APP_API}`, query)
        .then(res => {
          if (res.data.errors) {
            message.error(res.data.errors[0].message)
          } else {
            message.success('Password changed successfully')
            this.onPasswordCancel()
          }
        })
        .catch(err => console.log(err))
    }
  }

  getLocalStorage = () => {
    this.setState({ role_id: Number(localStorage.getItem('role_id')) })
    if (localStorage.getItem('role_id') === '2') {
      this.fetchLcData()
    }
  }

  fetchData = () => {
    this.getLocalStorage()
    this.fetchProfileData()
  }

  componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    this.state.confirmDialog && this.state.confirmDialog.destroy()
  }

  TitleAndDesc = (title, desc, stateKey, value, placeholder) => (
    <React.Fragment>
      <div className='titleColor font-weight-300 font16 mb-2'>{title}</div>
      {desc !== '' && !this.state.edit && (
        <div className='pb-3 FontAvenirMedium font18'>{desc}</div>
      )}
      {this.state.edit && (
        <InputC
          className='col-12 ant-input ant-input-lg mb-4 edit-input'
          value={value}
          stateKey={stateKey}
          id={this.state.truthObj[stateKey] ? 'has-error' : ''}
          placeholder={placeholder}
          onChange={this.updateProfileInput}
          validations={[required]}
          autoFocus
          disabled={stateKey === 'email'}
          type={
            stateKey === 'latitude' || stateKey === 'longitude' ? 'decimal' : ''
          }
        />
      )}
    </React.Fragment>
  )

  updatePasswordInput = (key, value) => {
    triggerGAEvent('Profile', 'Entering Passwords')
    let state = { ...this.state }
    state.change_password[key] = value
    this.setState({ change_password: state.change_password })
  }

  updateProfileInput = (key, value) => {
    triggerGAEvent('Profile', 'Changing Profile Data')
    let state = { ...this.state }
    if (key == 'year_of_birth') {
      if (!moment(value).isBefore(moment().subtract(18, 'years'))) {
        openNotification(
          'error',
          'You must be atleast 18 years of age, to Sign Up as an adult. '
        )
        let new_user_details = state.new_user_details
        new_user_details[key] = ''
        this.setState({
          new_user_details
        })
        return
      }
    }
    state.new_user_details[key] = value
    this.setState({ new_user_details: state.new_user_details })
  }

  showPasswords = key => {
    triggerGAEvent('Profile', 'Show Passwords')
    const state = { ...this.state }
    state.show_hide[key] = !state.show_hide[key]
    this.setState({ show_hide: state.show_hide })
  }

  onEditClick = () => {
    this.setState({ edit: true })
  }

  onCancelClick = () => {
    this.setState({ edit: false })
    this.fetchData()
  }

  onPasswordCancel = () => {
    triggerGAEvent('Profile', 'Close change passwords popup')
    this.setState({
      password_visible: false,
      change_password: {
        current_password: '',
        new_password: '',
        confirm_password: ''
      }
    })
  }

  showConfirmLogout = () => {
    const confirmDialog = Modal.confirm({
      title: 'Are you sure you want to Sign Out?',
      centered: true,
      okText: 'Yes',
      onOk: () => {
        localStorage.clear()
        this.props.history.push('/')
      }
    })
    this.setState({ confirmDialog })
  }

  onInterestCategoryOk = () => {
    triggerGAEvent('Profile', 'Adding new Interest Categories')
    this.setState({
      interest_visible: false,
      selected_interests: this.state.all_interests.filter(int =>
        this.state.checked_interests.includes(int.id)
      )
    })
  }

  onInterestCategoryCancel = () => {
    triggerGAEvent('Profile', 'Close Interest Categories popup')
    this.setState({
      interest_visible: false
    })
  }

  selectAllInterestCategory = () => {
    triggerGAEvent('Profile', 'Select all Interest Categories')
    this.setState({
      checked_interests: this.state.all_interests.map(int => int.id)
    })
  }

  clearAllInterestCategory = () => {
    triggerGAEvent('Profile', 'Clearing all Interest Categories')
    this.setState({ checked_interests: [] })
  }

  onAddMoreInterests = () => {
    triggerGAEvent('Profile', 'Open Interest Categories popup')
    this.setState({
      interest_visible: true,
      checked_interests: this.state.selected_interests.map(i => i.id)
    })
  }

  interestCheckBoxClick = checkedValues => {
    triggerGAEvent('Profile', 'Adding new Interest Categories')
    this.setState({ checked_interests: checkedValues })
  }

  FeaturedImageUploadSuccess = value => {
    const state = { ...this.state }
    state.new_user_details.profile_image = value
    this.setState({ new_user_details: state.new_user_details })
  }

  renderYear (props, year, selectedDate) {
    return <td {...props}>{year}</td>
  }

  render () {
    let inputProps = {
      placeholder: 'Select Year Of Birth'
    }
    var valid = function (current) {
      return current.isBefore(moment().subtract(1, 'year'))
    }

    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Loading is_loading={this.state.is_loading} />
        <div className='p-3 mbl-dev-profile'>
          {/* Dropdown Text */}
          <div className='mb-3 d-flex justify-content-between'>
            <div className='FontAvenirHeavy text-black font-16'>
              Profile Details
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='pr-2'>
                {this.state.login_type !== 'email' && (
                  <Button
                    className='flex align-items-center justify-content-center btn-txt-black'
                    size='small'
                  >
                    Logged in using
                    {this.state.login_type === 'google' && (
                      <Icon
                        type='google'
                        theme='outlined'
                        style={{ color: '#EB4335' }}
                      />
                    )}
                    {this.state.login_type === 'facebook' && (
                      <Icon
                        type='facebook'
                        theme='filled'
                        style={{ color: '#3B5998' }}
                      />
                    )}
                    {this.state.login_type === 'apple' && (
                      <Icon
                        type='apple'
                        theme='filled'
                        style={{ color: '#000000' }}
                      />
                    )}
                  </Button>
                )}
              </div>
              <div className='pr-2'>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        className='btn-txt-black'
                        key='0'
                        onClick={this.onEditClick}
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        className='btn-txt-black'
                        key='1'
                        onClick={() =>
                          this.setState({ password_visible: true })
                        }
                      >
                        Change password
                      </Menu.Item>
                      <Menu.Item
                        className='btn-txt-black'
                        key='3'
                        onClick={this.showConfirmLogout}
                      >
                        Log out
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <a
                    className='dropdown-txt ant-dropdown-link font-16'
                    onClick={e => e.preventDefault()}
                  >
                    ...
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* Profile Details */}
          <div
            className='p-3 border-style'
            style={{ height: `${!this.state.edit ? '424px' : 'auto'}` }}
          >
            {!this.state.edit && this.state.role_id == 3 && (
              <Row>
                <Col span={24} className='d-flex justify-content-center mb-2'>
                  <div className='mbl-profile-img'>
                    <img
                      className='img-fluid'
                      src={
                        this.state.user_details.profile_image
                          ? `${this.state.user_details.profile_image}`
                          : noProfileImage
                      }
                      alt='profile images'
                    />
                  </div>
                </Col>
                <Col span={24} className='d-flex justify-content-center mb-2'>
                  <div className='font-16 btn-txt-black font-weight-800'>
                    {this.state.new_user_details.name}
                  </div>
                </Col>
                <Col span={24} className='d-flex justify-content-center mb-2'>
                  <div className='profile-email-txt'>
                    {this.state.user_details.email}
                  </div>
                </Col>
                <Col span={24} className='d-flex justify-content-center mb-3'>
                  <div className='font-14 btn-txt-black'>
                    {this.state.new_user_details.phone_no}
                  </div>
                </Col>
                <Col span={24} className='mb-3'>
                  <div className='titleColor font-14 mb-2'>Year Of Birth</div>
                  <div className='FontAvenirMedium text-black font-14'>
                    {this.state.new_user_details.year_of_birth}
                  </div>
                </Col>
                <Col span={24} className='mb-3'>
                  <div className='titleColor font-14 mb-2'>Country</div>
                  <div className='FontAvenirMedium text-black font-14'>
                    {this.state.new_user_details.country}
                  </div>
                </Col>
                <Col span={24} className='mb-3'>
                  <div className='titleColor font-14 mb-3'>City</div>
                  <div className='FontAvenirMedium text-black font-14'>
                    {this.state.new_user_details.address}
                  </div>
                </Col>
                {/* <Col span={24} className=' mb-2'>
                  <div className='titleColor font-14 mb-2 text-uppercase'>
                    Description:
                  </div>
                  <div className='FontAvenirMedium text-black  font-14'>
                    {this.state.user_details.bio}
                  </div>
                </Col> */}
              </Row>
            )}
            {/* mobile device Edit button */}
            {this.state.edit && (
              <div>
                <Row
                  type='flex'
                  justify='space-around'
                  align='top'
                  className='p-3'
                >
                  <Col
                    span={24}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <ImageUploadCrop
                      imageUrl={this.state.new_user_details.profile_image}
                      onSuccess={this.FeaturedImageUploadSuccess}
                      id={
                        this.state.truthObj['profile_image'] ? 'has-error' : ''
                      }
                      aspect={1}
                      height={100}
                      width={100}
                      borderRadius='50%'
                    />
                  </Col>
                  <Col span={24}>
                    <Form>
                      <Row>
                        <Col span={24}>
                          <div className='titleColor font-weight-300 font16 mb-2'>
                            First Name
                          </div>
                          <div className='d-flex flex-column align-items-end justify-content-start'>
                            <Input
                              size='large'
                              className='edit-input'
                              value={this.state.user_details.name}
                              id={
                                this.state.truthObj['name'] ? 'has-error' : ''
                              }
                              placeholder='Enter First Name'
                              onChange={e => {
                                if (e.target.value.length <= 32) {
                                  this.updateProfileInput(
                                    'name',
                                    e.target.value
                                  )
                                }
                              }}
                              onPaste={e => {
                                if (
                                  e.clipboardData.getData('Text').length > 32
                                ) {
                                  this.updateProfileInput(
                                    'name',
                                    e.clipboardData
                                      .getData('Text')
                                      .substring(0, 32)
                                  )
                                }
                              }}
                              validations={[required]}
                              autoFocus
                            />
                            <span
                              style={
                                this.state.user_details.name.length >= 32
                                  ? { color: '#FF0A00' }
                                  : {}
                              }
                            >
                              {this.state.user_details.name.length} / 32
                            </span>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className='titleColor font-weight-300 font16 mb-2'>
                            Last Name
                          </div>
                          <div className='d-flex flex-column align-items-end justify-content-start'>
                            <Input
                              size='large'
                              className='edit-input'
                              value={this.state.user_details.last_name}
                              id={
                                this.state.truthObj['last_name']
                                  ? 'has-error'
                                  : ''
                              }
                              placeholder='Enter Last Name'
                              onChange={e => {
                                if (e.target.value.length <= 32) {
                                  this.updateProfileInput(
                                    'last_name',
                                    e.target.value
                                  )
                                }
                              }}
                              onPaste={e => {
                                if (
                                  e.clipboardData.getData('Text').length > 32
                                ) {
                                  this.updateProfileInput(
                                    'last_name',
                                    e.clipboardData
                                      .getData('Text')
                                      .substring(0, 32)
                                  )
                                }
                              }}
                              validations={[required]}
                              autoFocus
                            />
                            <span
                              style={
                                this.state.user_details.last_name.length >= 32
                                  ? { color: '#FF0A00' }
                                  : {}
                              }
                            >
                              {this.state.user_details.last_name.length} / 32
                            </span>
                          </div>
                        </Col>
                        <Col span={24}>
                          {this.TitleAndDesc(
                            'Email',
                            this.state.user_details.email,
                            'email',
                            this.state.new_user_details.email,
                            'Enter Your Email ID'
                          )}
                        </Col>
                        <Col span={24}>
                          <div className='row align-items-center justify-content-center pb-3'>
                            <div className='col-12 titleColor font-weight-300 font16 mb-3'>
                              Year Of Birth
                            </div>
                            <div className='col-12'>
                              <Datetime
                                dateFormat='YYYY'
                                timeFormat={false}
                                value={
                                  this.state.new_user_details.year_of_birth
                                }
                                isValidDate={valid}
                                renderYear={this.renderYear}
                                inputProps={inputProps}
                                onChange={date =>
                                  this.updateProfileInput(
                                    'year_of_birth',
                                    date.format('YYYY').toString()
                                  )
                                }
                                closeOnSelect={true}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          {this.TitleAndDesc(
                            'Country',
                            this.state.user_details.country,
                            'country',
                            this.state.new_user_details.country,
                            'Enter Your Country'
                          )}
                        </Col>
                        <Col span={24}>
                          {this.TitleAndDesc(
                            'City',
                            this.state.user_details.address,
                            'address',
                            this.state.new_user_details.address,
                            'Enter Your City'
                          )}
                        </Col>
                        <Col span={24} className='mb-6'>
                          {this.TitleAndDesc(
                            'Mobile Number',
                            this.state.user_details.phone_no,
                            'phone_no',
                            this.state.new_user_details.phone_no,
                            'Enter Your Mobile Number'
                          )}
                        </Col>
                        {/* <Col span={24} className='mb-6'>
                          <div className='titleColor font-weight-300 font16 mb-1 text-uppercase'>
                            Description
                          </div>
                          <Input.TextArea
                            value={this.state.new_user_details.bio}
                            className='min-hgt ant-input edit-input'
                            id={this.state.truthObj['bio'] ? 'has-error' : ''}
                            placeholder=''
                            size='large'
                            onChange={e =>
                              this.updateProfileInput('bio', e.target.value)
                            }
                          />
                        </Col> */}
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        {this.state.edit && (
          <div className='mbl-dev-profile mbl-edit-btn border-style'>
            <div>
              <ButtonC
                className='mr-3 w-130'
                size='large'
                onClick={this.onCancelClick}
                category='Profile'
                action='Cancel Edit Profile'
              >
                Cancel
              </ButtonC>
            </div>
            <div>
              <ButtonC
                size='large'
                type='primary'
                className='blue-btn w-130'
                onClick={this.validateStateValues}
                category='Profile'
                action='Save Edited Profile'
              >
                Save changes
              </ButtonC>
            </div>
          </div>
        )}
        <div className='m-4 p-2 max-dev-profile'>
          <div className='d-flex justify-content-between mb-4'>
            <div className='p-0 FontAvenirHeavy text-dark font-20 d-flex align-items-center'>
              Profile Details
            </div>
            {this.state.edit && (
              <div>
                <ButtonC
                  className='mr-3'
                  size='large'
                  onClick={this.onCancelClick}
                  category='Profile'
                  action='Cancel Edit Profile'
                >
                  Cancel
                </ButtonC>
                <ButtonC
                  size='large'
                  type='primary'
                  className='green-btn'
                  onClick={this.validateStateValues}
                  category='Profile'
                  action='Save Edited Profile'
                >
                  Save Changes
                </ButtonC>
              </div>
            )}
            {!this.state.edit && (
              <div className='flex align-items-center justify-content-end'>
                {this.state.login_type !== 'email' && (
                  <Button
                    className='flex align-items-center justify-content-center'
                    size='large'
                  >
                    Logged in using
                    {this.state.login_type === 'google' && (
                      <Icon
                        type='google'
                        theme='outlined'
                        style={{ color: '#EB4335' }}
                      />
                    )}
                    {this.state.login_type === 'facebook' && (
                      <Icon
                        type='facebook'
                        theme='filled'
                        style={{ color: '#3B5998' }}
                      />
                    )}
                    {this.state.login_type === 'apple' && (
                      <Icon
                        type='apple'
                        theme='filled'
                        style={{ color: '#000000' }}
                      />
                    )}
                  </Button>
                )}
                <ButtonC
                  className='ml-3'
                  size='large'
                  onClick={this.onEditClick}
                  category='Profile'
                  action='Edit Profile'
                >
                  Edit
                </ButtonC>
                {this.state.login_type === 'email' && (
                  <ButtonC
                    className='button-border ml-3'
                    size='large'
                    onClick={() => this.setState({ password_visible: true })}
                    category='Profile'
                    action='Open Change Password Popup'
                  >
                    Change Password
                  </ButtonC>
                )}
                <ButtonC
                  ghost
                  type='primary'
                  className='ml-3'
                  onClick={this.showConfirmLogout}
                  size='large'
                  category='Profile'
                  action='Logging out'
                >
                  Sign Out
                </ButtonC>
              </div>
            )}
          </div>
          {!this.state.edit && (
            <div className='col-12 row m-0 p-0'>
              <div className='col-4 p-3 bg-white'>
                <img
                  className='img-fluid'
                  src={
                    this.state.user_details.profile_image
                      ? `${this.state.user_details.profile_image}`
                      : noProfileImage
                  }
                  alt='profile images'
                />
              </div>
              <div className='col-8 p-3 bg-white'>
                <Row>
                  <Col
                    span={[2, 3].includes(this.state.role_id) ? 12 : 24}
                    className='pb-2'
                  >
                    {this.TitleAndDesc(
                      'First Name',
                      this.state.user_details.name,
                      'name',
                      this.state.new_user_details.name,
                      'Enter First Name'
                    )}
                  </Col>
                  {this.state.role_id == 3 && (
                    <Col span={12} className='pb-2'>
                      {this.TitleAndDesc(
                        'Last Name',
                        this.state.user_details.last_name,
                        'last_name',
                        this.state.new_user_details.last_name,
                        'Enter Last Name'
                      )}
                    </Col>
                  )}
                  {this.state.role_id === 2 && (
                    <Col span={12} className='pb-2'>
                      {this.TitleAndDesc(
                        'DISPLAY NAME',
                        this.state.user_details.displayName,
                        'displayName',
                        this.state.new_user_details.displayName,
                        'Enter display name'
                      )}
                    </Col>
                  )}
                  <Col span={12} className='pb-2'>
                    {this.TitleAndDesc(
                      'Email',
                      this.state.user_details.email,
                      'email',
                      this.state.new_user_details.email,
                      'Enter Email'
                    )}
                  </Col>
                  {this.state.role_id == 3 && (
                    <React.Fragment>
                      <Col span={12} className='pb-2'>
                        {this.TitleAndDesc(
                          'Year Of Birth',
                          this.state.user_details.year_of_birth,
                          'year_of_birth',
                          this.state.new_user_details.year_of_birth,
                          'Enter Year Of Birth'
                        )}
                      </Col>
                      <Col span={12} className='pb-2'>
                        {this.TitleAndDesc(
                          'Country',
                          this.state.user_details.country,
                          'country',
                          this.state.new_user_details.country,
                          'Enter Country'
                        )}
                      </Col>
                      <Col span={12} className='pb-2'>
                        {this.TitleAndDesc(
                          'City',
                          this.state.user_details.address,
                          'address',
                          this.state.new_user_details.address,
                          'Enter City'
                        )}
                      </Col>
                    </React.Fragment>
                  )}
                  <Col span={24} className='pb-2'>
                    <Row>
                      {this.state.role_id != 3 && (
                        <Col span={12} className='pr-3'>
                          {this.TitleAndDesc(
                            'address',
                            this.state.user_details.address,
                            'address',
                            this.state.new_user_details.address,
                            'Enter Address'
                          )}
                        </Col>
                      )}
                      <Col span={12} className='pr-3'>
                        {this.state.role_id === 2 && (
                          <Row>
                            <Col span={12} className='pr-3'>
                              {this.TitleAndDesc(
                                'Lat',
                                this.state.user_details.latitude,
                                'latitude',
                                this.state.new_user_details.latitude,
                                'Enter Latitude'
                              )}
                            </Col>
                            <Col span={12}>
                              {this.TitleAndDesc(
                                'Long',
                                this.state.user_details.longitude,
                                'longitude',
                                this.state.new_user_details.longitude,
                                'Enter Longitude'
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className='pb-2'>
                    {this.TitleAndDesc(
                      'Mobile Number',
                      this.state.user_details.phone_no,
                      'phone_no',
                      this.state.new_user_details.phone_no,
                      'Enter Mobile Number'
                    )}
                  </Col>
                  {this.state.role_id !== 3 && (
                    <Col span={24} className='pb-2'>
                      <div className='titleColor font-weight-300 font16 mb-2 text-uppercase'>
                        {this.state.role_id === 4 || this.state.role_id === 5
                          ? 'About'
                          : 'Description'}
                      </div>
                      <div className='pb-3 FontAvenirMedium font18'>
                        {this.state.user_details.bio}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          )}
          {this.state.edit && (
            <Row
              type='flex'
              justify='space-around'
              align='top'
              className='bg-white p-3'
            >
              <Col
                span={8}
                className='d-flex align-items-center justify-content-center'
              >
                <ImageUploadCrop
                  imageUrl={this.state.new_user_details.profile_image}
                  onSuccess={this.FeaturedImageUploadSuccess}
                  id={this.state.truthObj['profile_image'] ? 'has-error' : ''}
                  aspect={this.state.role_id === 2 ? 1.47 : 1}
                  height={400}
                  width={400}
                />
              </Col>
              <Col span={16} className='pl-3'>
                <Form>
                  <Row>
                    <Col span={12} className='pr-3'>
                      <div className='titleColor font-weight-300 font16 mb-3'>
                        First Name
                      </div>
                      <div className='d-flex flex-column align-items-end justify-content-start'>
                        <Input
                          size='large'
                          className='mb-2 edit-input'
                          value={this.state.user_details.name}
                          id={this.state.truthObj['name'] ? 'has-error' : ''}
                          placeholder='Enter First Name'
                          onChange={e => {
                            if (e.target.value.length <= 32) {
                              this.updateProfileInput('name', e.target.value)
                            }
                          }}
                          onPaste={e => {
                            if (e.clipboardData.getData('Text').length > 32) {
                              this.updateProfileInput(
                                'name',
                                e.clipboardData.getData('Text').substring(0, 32)
                              )
                            }
                          }}
                          validations={[]}
                          autoFocus
                        />
                        <span
                          style={
                            this.state.user_details.name.length >= 32
                              ? { color: '#FF0A00' }
                              : {}
                          }
                        >
                          {this.state.user_details.name.length} / 32
                        </span>
                      </div>
                    </Col>
                    {this.state.role_id == 3 && (
                      <Col span={12} className='pr-3'>
                        <div className='titleColor font-weight-300 font16 mb-3'>
                          Last Name
                        </div>
                        <div className='d-flex flex-column align-items-end justify-content-start'>
                          <Input
                            size='large'
                            className='mb-2 edit-input'
                            value={this.state.user_details.last_name}
                            id={
                              this.state.truthObj['last_name']
                                ? 'has-error'
                                : ''
                            }
                            placeholder='Enter Last Name'
                            onChange={e => {
                              if (e.target.value.length <= 32) {
                                this.updateProfileInput(
                                  'last_name',
                                  e.target.value
                                )
                              }
                            }}
                            onPaste={e => {
                              if (e.clipboardData.getData('Text').length > 32) {
                                this.updateProfileInput(
                                  'last_name',
                                  e.clipboardData
                                    .getData('Text')
                                    .substring(0, 32)
                                )
                              }
                            }}
                            validations={[]}
                            autoFocus
                          />
                          <span
                            style={
                              this.state.user_details.last_name.length >= 32
                                ? { color: '#FF0A00' }
                                : {}
                            }
                          >
                            {this.state.user_details.last_name.length} / 32
                          </span>
                        </div>
                      </Col>
                    )}
                    {this.state.role_id === 2 && (
                      <Col span={12} className='pb-2'>
                        <div className='titleColor font-weight-300 font16 mb-3 text-uppercase'>
                          DISPLAY NAME
                        </div>
                        <div className='d-flex flex-column align-items-end justify-content-start'>
                          <Input
                            size='large'
                            className='mb-2 edit-input'
                            value={this.state.user_details.displayName}
                            id={
                              this.state.truthObj['displayName']
                                ? 'has-error'
                                : ''
                            }
                            placeholder='Enter Display name'
                            onChange={e => {
                              if (e.target.value.length <= 20) {
                                this.updateProfileInput(
                                  'displayName',
                                  e.target.value
                                )
                              }
                            }}
                            onPaste={e => {
                              if (e.clipboardData.getData('Text').length > 20) {
                                this.updateProfileInput(
                                  'displayName',
                                  e.clipboardData
                                    .getData('Text')
                                    .substring(0, 20)
                                )
                              }
                            }}
                            validations={[required]}
                            autoFocus
                          />
                          <span
                            style={
                              this.state.user_details.displayName.length >= 20
                                ? { color: '#FF0A00' }
                                : {}
                            }
                          >
                            {this.state.user_details.displayName.length} / 20
                          </span>
                        </div>
                      </Col>
                    )}
                    {this.state.role_id !== 3 && (
                      <Col span={24} className='pb-2'>
                        {this.TitleAndDesc(
                          'address',
                          this.state.user_details.address,
                          'address',
                          this.state.new_user_details.address,
                          'Enter Address'
                        )}
                      </Col>
                    )}
                    {this.state.role_id === 2 && (
                      <Col span={24} className='pb-2'>
                        <Row>
                          <Col span={12} className='pr-3'>
                            {this.TitleAndDesc(
                              'Lat',
                              this.state.user_details.latitude,
                              'latitude',
                              this.state.new_user_details.latitude,
                              'Enter Latitude'
                            )}
                          </Col>
                          <Col span={12}>
                            {this.TitleAndDesc(
                              'Long',
                              this.state.user_details.longitude,
                              'longitude',
                              this.state.new_user_details.longitude,
                              'Enter Longitude'
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Row>
                      <Col span={12} className='pb-2 pr-3'>
                        {this.TitleAndDesc(
                          'Email id',
                          this.state.user_details.email,
                          'email',
                          this.state.new_user_details.email,
                          'Enter Email'
                        )}
                      </Col>
                      {this.state.role_id == 3 && (
                        <Col span={12} className='pb-2 pr-3'>
                          <div className='row align-items-center justify-content-center pb-2'>
                            <div className='col-12 titleColor font-weight-300 font16 mb-3'>
                              Year Of Birth
                            </div>
                            <div className='col-12'>
                              <Datetime
                                dateFormat='YYYY'
                                timeFormat={false}
                                value={
                                  this.state.new_user_details.year_of_birth
                                }
                                isValidDate={valid}
                                renderYear={this.renderYear}
                                inputProps={inputProps}
                                onChange={date =>
                                  this.updateProfileInput(
                                    'year_of_birth',
                                    date.format('YYYY').toString()
                                  )
                                }
                                closeOnSelect={true}
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {this.state.role_id == 3 && (
                      <Row>
                        <Col span={12} className='pb-2 pr-3'>
                          {this.TitleAndDesc(
                            'Country',
                            this.state.user_details.country,
                            'country',
                            this.state.new_user_details.country,
                            'Enter Country'
                          )}
                        </Col>
                        <Col span={12} className='pb-2 pr-3'>
                          {this.TitleAndDesc(
                            'City',
                            this.state.user_details.address,
                            'address',
                            this.state.new_user_details.address,
                            'Enter City'
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col span={12} className='pb-2'>
                        {this.TitleAndDesc(
                          'Mobile Number',
                          this.state.user_details.phone_no,
                          'phone_no',
                          this.state.new_user_details.phone_no,
                          'Enter Mobile Number'
                        )}
                      </Col>
                    </Row>
                    {this.state.role_id != 3 && this.state.edit && (
                      <Col span={24} className='pb-2'>
                        <div className='titleColor font-weight-300 font16 mb-2 text-uppercase'>
                          Description
                        </div>
                        <Input.TextArea
                          value={this.state.new_user_details.bio}
                          className='min-hgt ant-input edit-input'
                          id={this.state.truthObj['bio'] ? 'has-error' : ''}
                          placeholder=''
                          size='large'
                          onChange={e =>
                            this.updateProfileInput('bio', e.target.value)
                          }
                        />
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
          {this.state.role_id === 2 && (
            <div className='w-100'>
              <div className='col-12 p-0 pb-2 FontAvenirHeavy text-dark font-20 d-flex align-items-center mb-2 mt-4'>
                Interest Categories
                {this.state.edit && (
                  <div
                    className='d-inline addmorebutton font-16 ml-3 FontAvenirMedium border-0 pointer-cursor'
                    onClick={this.onAddMoreInterests}
                  >
                    Add more
                  </div>
                )}
              </div>
              <div className='col-12 p-0 pb-2'>
                {this.state.selected_interests.length === 0 ? (
                  <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                    <img className='mb-3' src={emptyStateActivity} alt='' />
                    <div className='text-center'>
                      <p className='FontAvenirMedium font-20 mb-2'>
                        Interest categories selected will show up here. To Add
                        click on edit and tap "Add More"
                      </p>
                    </div>
                  </div>
                ) : (
                  <Row>
                    {this.state.selected_interests.map((intCat, index) => (
                      <Col span={6} style={{ padding: '1rem' }} key={index}>
                        <CategoryCard
                          title={intCat.name}
                          loading={this.state.loading}
                          image={intCat.background_image}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          width='60%'
          title='Select to add more Interest categories'
          visible={this.state.interest_visible}
          maskClosable={false}
          onCancel={this.onInterestCategoryCancel}
          footer={[
            <div
              className='d-flex justify-content-between align-items-center pl-3 pr-3 p-1'
              key={1}
            >
              <div className='fontColor-blue font-16 FontAvenirMedium'>
                {`${this.state.checked_interests.length} Interest categories selected`}
              </div>
              <div>
                <Button onClick={this.onInterestCategoryCancel} size='large'>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  onClick={this.onInterestCategoryOk}
                  size='large'
                >
                  Okay
                </Button>
              </div>
            </div>
          ]}
        >
          {/*<div className="d-flex justify-content-start align-items-start mb-3">*/}
          {/*  <Button onClick={this.clearAllInterestCategory} className="mr-3">Clear All</Button>*/}
          {/*  <Button type="primary" onClick={this.selectAllInterestCategory}>Select All</Button>*/}
          {/*</div>*/}
          <Row
            className='chkbox lc-onboarding modal-popup'
            style={{ height: '50vh', overflow: 'scroll', padding: '15px' }}
          >
            <Checkbox.Group
              onChange={this.interestCheckBoxClick}
              value={this.state.checked_interests}
            >
              {this.state.all_interests.map((data, i) => (
                <Col
                  span={7}
                  key={i}
                  className='lc-onboarding__card'
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(0, 0, 0, 0.87) 125%), url(${data.background_image})`
                  }}
                >
                  <Checkbox name={data.name} value={data.id} />
                  <span
                    style={{
                      color: '#FFFFFF',
                      fontSize: 17,
                      fontWeight: 900,
                      marginBottom: 7
                    }}
                  >
                    {data.name}
                  </span>
                </Col>
              ))}
            </Checkbox.Group>
          </Row>
        </Modal>
        <Modal
          title='Change Password'
          visible={this.state.password_visible}
          maskClosable={false}
          width={430}
          centered={true}
          onCancel={this.onPasswordCancel}
          footer={[
            <Button size='large' key='back' onClick={this.onPasswordCancel}>
              Cancel
            </Button>,
            <Button
              size='large'
              key='submit'
              type='primary'
              onClick={this.onPasswordOk}
              disabled={_.values(this.state.change_password).includes('')}
            >
              Save Changes
            </Button>
          ]}
        >
          <Form
            ref={this.state.form}
            className={
              this.state.change_password.new_password !== '' ||
              this.state.change_password.confirm_password !== ''
                ? 'formSubmitted'
                : ''
            }
          >
            <InputC
              type='password'
              placeholder='Enter current password'
              titleStyle='mbl-profileBtn-txt'
              title='Enter current password'
              className='col-12 ant-input ant-input-lg mb-4 font-16'
              name='current_password'
              stateKey='current_password'
              value={this.state.change_password.current_password}
              onChange={this.updatePasswordInput}
              showPwdKey='current_password'
              showPwd={this.state.show_hide.current_password}
              showPwdClick={this.showPasswords}
              validations={[required]}
              autoFocus
            />
            <InputC
              type='password'
              title='Enter new password'
              titleStyle='mbl-profileBtn-txt'
              placeholder='Enter new password'
              className='col-12 ant-input ant-input-lg mb-4 font-16'
              name='new_password'
              stateKey='new_password'
              value={this.state.change_password.new_password}
              onChange={this.updatePasswordInput}
              showPwdKey='new_password'
              showPwd={this.state.show_hide.new_password}
              showPwdClick={this.showPasswords}
              validations={[required, password, confirm_password]}
            />
            <InputC
              type='password'
              placeholder='Confirm new password'
              title='Confirm new password'
              titleStyle='mbl-profileBtn-txt'
              className='col-12 ant-input ant-input-lg mb-4 font-16'
              name='confirm_password'
              stateKey='confirm_password'
              value={this.state.change_password.confirm_password}
              onChange={this.updatePasswordInput}
              showPwdKey='confirm_password'
              showPwd={this.state.show_hide.confirm_password}
              showPwdClick={this.showPasswords}
              validations={[required, password, confirm_password]}
            />
            <div className='hint'>
              Use 8 or more characters with a mix of letters, numbers & symbols
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Profile
