import { Col, Icon, Row, Tag } from "antd";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import ImageCard from "./ImageCard";

class DashboardCard extends React.Component {
  render() {
    return (
      <div className="dashboardCardStyle">
        <ImageCard
          img={this.props.img}
          imgRefIcon={this.props.imgRefIcon}
          videoRefIcon={this.props.videoRefIcon}
          stepsRefIcon={this.props.stepsRefIcon}
          pdfIcon={this.props.pdfIcon}
        />
        <div
          className="pt-3 pl-3 pr-3 pb-2 card border-0"
          style={{ borderRadius: 0 }}
        >
          <div className="FontAvenirMedium mb-2 pb-1 font-16 text-dark text-capitalize">
            <LinesEllipsis
              text={this.props.name}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="words"
            />
          </div>
          <div className="d-flex justify-content-between FontAvenirMedium font-14">
            <div className="row m-0 w-100">
              <div className="col-8 p-0">
                <div className="mb-2 pb-1 text-card-black">
                  <LinesEllipsis
                    text={this.props.interests.interest_details
                      .map(interest => interest.name)
                      .join(", ")}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </div>
              </div>
              <div className="col-4 p-0 text-right">
                <Icon
                  type="like"
                  style={{ color: "#0076FF" }}
                  theme="twoTone"
                  twoToneColor="#0076FF"
                  className="mr-2"
                />
                {this.props.insights_on_activity.like_count}
                <Icon type="dislike" theme="outlined" className="ml-4 mr-2" />
                {this.props.insights_on_activity.dislike_count}
              </div>
            </div>
          </div>
          <div className="d-flex mb-2 pb-1 text-gray align-items-center">
            <span>
              {`Finished - `}
              <span className="FontAvenirMedium font-14 text-dark">
                {this.props.insights_on_activity.is_finished}
              </span>
            </span>
            <span className="cardDot mr-3 ml-3"> . </span>
            <span>
              {`Viewed - `}
              <span className="FontAvenirMedium font-14 text-dark">
                {this.props.insights_on_activity.is_viewed}
              </span>
            </span>
          </div>
          <p className=" mb-0 text-gray">
            {`Average time to complete - `}
            <span className="FontAvenirMedium font-14 text-dark">
              {this.props.insights_on_activity.average_time_complete} mins
            </span>
          </p>
          <div className="border-bottom pt-3 mb-2" />
          {this.props.is_details && (
            <div>
              <p className="text-gray">Age & Difficulty</p>
              <Row className="mb-3">
                <Col span={5}>
                  <div className="fontAvenirMedium font-14 text-card-black p-0">
                    Easy -
                  </div>
                </Col>
                <Col span={19}>
                  {this.props.insights_on_activity.easy &&
                    this.props.insights_on_activity.easy.map((easy, i) => (
                      <Tag
                        color="blue"
                        key={i}
                      >{`${easy.age_group} | ${easy.count}`}</Tag>
                    ))}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={5}>
                  <div className="fontAvenirMedium font-14 text-card-black p-0">
                    Medium -
                  </div>
                </Col>
                <Col span={19}>
                  {this.props.insights_on_activity.medium &&
                    this.props.insights_on_activity.medium.map((medium, i) => (
                      <Tag
                        color="blue"
                        key={i}
                      >{`${medium.age_group} | ${medium.count}`}</Tag>
                    ))}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={5}>
                  <div className="fontAvenirMedium font-14 text-card-black p-0">
                    Hard -
                  </div>
                </Col>
                <Col span={19}>
                  {this.props.insights_on_activity.hard &&
                    this.props.insights_on_activity.hard.map((hard, i) => (
                      <Tag
                        color="blue"
                        key={i}
                      >{`${hard.age_group} | ${hard.count}`}</Tag>
                    ))}
                </Col>
              </Row>
              <div className="border-bottom pt-3 mb-2" />
            </div>
          )}
          <div
            onClick={this.props.viewDetails}
            className="FontAvenirMedium font-14 cursor-pointer text-center fontColor-blue text-uppercase"
          >
            {this.props.is_details ? "View less details" : "View details"}
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardCard;
