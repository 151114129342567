import React from "react";
// import Carousel, {Modal, ModalGateway} from "react-images";
import { Modal } from 'antd';
import 'react-image-lightbox/style.css';
import Carousel from 'react-images';

class WorkshopImageView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      IsModalopen: false,
      currentIndex: 0
    };

  }

  openModal = (index) => {
    console.log("Index and state ==> ", this.state.IsModalopen, this.state.currentIndex)
    this.setState(state => ({
      IsModalopen: !state.IsModalopen,
      currentIndex: index
    }));
  };

  // closeModal = () => {
  //     this.setState({
  //         IsModalopen: false,
  //         currentIndex: 0
  //     });
  // };

  render() {

    let images = this.props.thumb_images.length ? this.props.thumb_images.map(x => {
      return {
        source: x['image_url'],
        align: 'center',
        width: 4,
        height: 4
      }
    }) : [{ source: "" }, { source: "" }, { source: "" }];

    // let images = this.props.thumb_images.length ? this.props.thumb_images.map(x => {
    //       return x['image_url'];
    //   }) : [ "", "", ""];

    const { IsModalopen, currentIndex } = this.state;

    return (
      <React.Fragment>
        {IsModalopen ? (
          <Modal visible={IsModalopen}
            onCancel={this.openModal} footer={null}>
            <Carousel views={images} currentIndex={currentIndex} />
          </Modal>
        ) : null}
        {/* {IsModalopen && (
          <Lightbox
            mainSrc={images[currentIndex]}
            nextSrc={images[(currentIndex + 1) % images.length]}
            prevSrc={images[(currentIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ IsModalopen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currentIndex: (currentIndex + images.length - 1) % images.length,
              })
            }
            reactModalProps={{ shouldReturnFocusAfterClose: false }}
            enableZoom={false}
            nextLabel="Next"
            prevLabel="Previous"
            onMoveNextRequest={() =>
              this.setState({
                currentIndex: (currentIndex + 1) % images.length,
              })
            }
          />
        )} */}

        {this.props.thumb_images.map((data, index) => (
          <div tabIndex="1" className="col-4 mb-4 p-0 pointer-cursor" key={index} onClick={() => this.openModal(index)}>
            <img
              className="col-12 p-0"
              style={{ height: 212, width: 378, objectFit: 'cover' }}
              src={data.image_url}
              alt="featured images"
            />
          </div>
        ))}
        {/* <ModalGateway>
                        { IsModalopen ? (
                        <Modal onClose={this.closeModal}>
                            <Carousel currentIndex={ currentIndex } views={images} />
                        </Modal>
                        ) : null}
                  </ModalGateway> */}
      </React.Fragment>
    );
  }
}

export default WorkshopImageView;
