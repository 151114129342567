import {
  Button,
  Card,
  Col,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table
} from "antd/lib/index";
import axios from "axios/index";
import _ from "lodash";
import Moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import {
  APPROVE_DATAPOINTS,
  APPROVE_INTEREST,
  APPROVE_PARENT,
  GET_DATA_TYPE,
  GET_GLOBAL_INTEREST_CATEGORY,
  GET_NOTIFICATIONS_FOR_ADMIN,
  GET_SUGGESSTED_DATAPOINTS,
  GET_SUGGESSTED_INTEREST,
  ONHOLD_PARENT
} from "../../graphql";
import noNotifications from "../../static/nav/noNotifications.png";
import { openNotification, triggerGAEvent } from "../../utils";
import "./index.css";
import InvalidYoutubeLinks from "./InvalidYoutubeLinks";

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      fetchDataLoading: true,
      password_sortedInfo: {
        columnKey: "createdAt",
        field: "createdAt",
        order: "descend"
      },
      lc_sortedInfo: {
        columnKey: "createdAt",
        field: "createdAt",
        order: "descend"
      },
      data_sortedInfo: {
        columnKey: "createdAt",
        field: "createdAt",
        order: "descend"
      },
      interest_sortedInfo: {
        columnKey: "createdAt",
        field: "createdAt",
        order: "descend"
      },
      parent_sortedInfo: {
        columnKey: "createdAt",
        field: "createdAt",
        order: "descend"
      },
      data_points: [],
      interests: [],
      password_forget: [],
      parent_signup: [],
      learning_content: [],
      workshop_content: [],
      activeKey: new URLSearchParams(this.props.location.search).get('key') || "1",
      modalVisible: false,
      parentVisible: false,
      type: "",
      suggested_id: "",
      suggested_by: "",
      profile_image: "",
      name: "",
      category_id: "",
      desc: "",
      categories: [],
      selected_parent: {
        notification_name: "",
        city: "",
        phone_no: "",
        email: ""
      },
      on_hold_text: "",
      selectedCity: "",
      total_count_links: 0,
      truthObj: {
        name: false,
        desc: false,
        category_id: false,
        profile_image: false,
      },
    };
  }

  fetchData = () => {
    this.setState({ fetchDataLoading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, GET_NOTIFICATIONS_FOR_ADMIN)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
          this.setState({ loading: false });
          this.setState({ fetchDataLoading: false });
        } else {
          if (this.state.selectedCity) {
            this.setState({
              password_forget: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "password forget"
              )[0].notifications,
              data_points: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "data point"
              )[0].notifications,
              interests: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "interest"
              )[0].notifications,
              parent_signup: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "parent signup"
              )[0].notifications,
              selected_parent_signup: res.data.data.masterNotificationV2.notification_details
                .filter(not => not.name === "parent signup")[0]
                .notifications.filter(
                  parent =>
                    parent.city.toLowerCase() === this.state.selectedCity
                ),
              learning_content: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "learning content"
              )[0].notifications,
              workshop_content: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "Workshop Notification"
              )[0].notifications,
              loading: false,
              fetchDataLoading: true
            });
          } else {
            this.setState({
              password_forget: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "password forget"
              )[0].notifications,
              data_points: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "data point"
              )[0].notifications,
              interests: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "interest"
              )[0].notifications,
              parent_signup: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "parent signup"
              )[0].notifications,
              selected_parent_signup: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "parent signup"
              )[0].notifications,
              learning_content: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "learning content"
              )[0].notifications,
              workshop_content: res.data.data.masterNotificationV2.notification_details.filter(
                not => not.name === "Workshop Notification"
              )[0].notifications,
              loading: false,
              fetchDataLoading: true
            });
          }
        }
      })
      .catch(err => console.log(err));
  };

  fetchInterestCategory = () => {
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, GET_GLOBAL_INTEREST_CATEGORY)
      .then(res => {
        this.setState({
          categories: res.data.data.getGlobalInterestCategory.intrest_cat
        });
      })
      .catch(err => console.log(err));
  };

  fetchDataTypes = () => {
    let query = GET_DATA_TYPE;
    query.variables = {
      search: "",
      sort: ""
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          categories: res.data.data.getDataGrade.data_grade
        });
      })
      .catch(err => console.log(err));
  };

  approveDataPoints = status => {
    triggerGAEvent(
      "Notifications",
      status === 1
        ? "Approving a Suggested Data point"
        : "Rejecting a Suggested Data point"
    );
    let mutation = APPROVE_DATAPOINTS;
    mutation.variables = {
      data_point_id: this.state.suggested_id,
      data_grade_types_id: this.state.category_id,
      name: this.state.name,
      profile_image: this.state.profile_image,
      desc: this.state.desc,
      status: status
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          status === 1
            ? openNotification("success", "Data point approved")
            : openNotification("success", "Data point rejected");
          this.fetchData();
          this.handleModalCancel();
        }
      })
      .catch(err => console.log(err));
  };

  approveInterests = status => {
    triggerGAEvent(
      "Notifications",
      status === 1
        ? "Approving a Suggested Interest"
        : "Rejecting a Suggested Interest"
    );
    let mutation = APPROVE_INTEREST;
    mutation.variables = {
      interest_id: this.state.suggested_id,
      interest_categories_id: this.state.category_id,
      name: this.state.name,
      profile_image: this.state.profile_image,
      desc: this.state.desc,
      status: status
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          status === 1
            ? openNotification("success", "Interest approved")
            : openNotification("success", "Interest rejected");
          this.fetchData();
          this.handleModalCancel();
        }
      })
      .catch(err => console.log(err));
  };

  onApproveBtnClick = () => {
    let truthObj = {
      name: this.state.name === "",
      desc: this.state.desc === "",
      category_id: this.state.category_id === "",
      profile_image: this.state.profile_image === "",
    };
    this.setState({ truthObj });
    if (
      this.state.name &&
      this.state.category_id &&
      this.state.profile_image &&
      this.state.desc
    ) {
      if (this.state.type === "data") {
        this.approveDataPoints(1);
      } else {
        this.approveInterests(1);
      }
    } else {
      openNotification("error", "Enter all the mandatory fields");
    }
  };

  approveParent = () => {
    triggerGAEvent("Notifications", "Approving a Parent");
    let mutation = APPROVE_PARENT;
    mutation.variables = {
      notification_id: this.state.selected_parent.id,
      comment: this.state.on_hold_text
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          openNotification("success", "Parent approved");
          this.fetchData();
          this.handleModalCancel();
        }
      })
      .catch(err => console.log(err));
  };

  onHoldParent = () => {
    triggerGAEvent("Notifications", "Setting a Parent to On-Hold");
    let mutation = ONHOLD_PARENT;
    mutation.variables = {
      notification_id: this.state.selected_parent.id,
      comment: this.state.on_hold_text
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          openNotification("success", "Parent is set to on-hold");
          this.fetchData();
          this.handleModalCancel();
        }
      })
      .catch(err => console.log(err));
  };

  onClickSuggestedRow = (type, value) => {
    this.setState({ modalVisible: true, type: type });
    if (type === "data") {
      triggerGAEvent("Notifications", "See suggested Data points");
      this.seeSuggestedDataPoints(value.notification_id);
      this.fetchDataTypes();
    } else {
      triggerGAEvent("Notifications", "See suggested Interests");
      this.seeSuggestedInterest(value.notification_id);
      this.fetchInterestCategory();
    }
  };

  seeSuggestedInterest = id => {
    let query = GET_SUGGESSTED_INTEREST;
    query.variables = { id: id };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.setState({
            suggested_id:
              res.data.data.seeSuggestInterest.interest_details[0].id,
            suggested_by:
              res.data.data.seeSuggestInterest.interest_details[0].suggestedBy,
            name: res.data.data.seeSuggestInterest.interest_details[0].name,
            category_id:
              res.data.data.seeSuggestInterest.interest_details[0]
                .interest_categories_id,
            profile_image:
              res.data.data.seeSuggestInterest.interest_details[0]
                .profile_image,
            desc: res.data.data.seeSuggestInterest.interest_details[0].desc
          });
        }
      })
      .catch(err => console.log(err));
  };

  seeSuggestedDataPoints = id => {
    let query = GET_SUGGESSTED_DATAPOINTS;
    query.variables = { id: id };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.setState({
            suggested_id:
              res.data.data.seeSuggestDataPoint.data_point_details[0].id,
            suggested_by:
              res.data.data.seeSuggestDataPoint.data_point_details[0]
                .suggestedBy,
            name: res.data.data.seeSuggestDataPoint.data_point_details[0].name,
            category_id:
              res.data.data.seeSuggestDataPoint.data_point_details[0]
                .data_grade_types_id,
            profile_image:
              res.data.data.seeSuggestDataPoint.data_point_details[0]
                .profile_image,
            desc: res.data.data.seeSuggestDataPoint.data_point_details[0].desc
          });
        }
      })
      .catch(err => console.log(err));
  };

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      parentVisible: false,
      type: "",
      suggested_id: "",
      suggested_by: "",
      profile_image: "",
      name: "",
      category_id: "",
      desc: "",
      categories: [],
      on_hold_text: "",
      selected_parent: {
        notification_name: "",
        city: "",
        phone_no: "",
        email: ""
      }
    });
  };

  onInputChange = e => {
    if (e.target.name === "on_hold_text") {
      e.target.value.length <= 281 &&
        this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleTableChange = (pagination, filters, sorter, key) => {
    switch (key) {
      case "password":
        triggerGAEvent(
          "Notifications",
          "Changing filters and sort in Password Reset Table"
        );
        this.setState({ password_sortedInfo: sorter });
        break;
      case "lc":
        triggerGAEvent(
          "Notifications",
          "Changing filters and sort in Learning Content Table"
        );
        this.setState({ lc_sortedInfo: sorter });
        break;
      case "interest":
        triggerGAEvent(
          "Notifications",
          "Changing filters and sort in Suggested Interest Table"
        );
        this.setState({ interest_sortedInfo: sorter });
        break;
      case "data":
        triggerGAEvent(
          "Notifications",
          "Changing filters and sort in Suggested Data points Table"
        );
        this.setState({ data_sortedInfo: sorter });
        break;
      case "parent":
        triggerGAEvent(
          "Notifications",
          "Changing filters and sort in Parent Table"
        );
        this.setState({ parent_sortedInfo: sorter });
        break;
      default:
        this.setState({ password_sortedInfo: sorter });
        break;
    }
  };

  handleCategorySelect = value => {
    this.setState({
      category_id: value
    });
  };

  FeaturedImageUploadSuccess = value => {
    this.setState({ profile_image: value });
  };

  onMenuClick = ({ key }) => {
    this.setState({ activeKey: key });
  };

  componentDidMount() {
    this.fetchData();
  }

  onSelectChange = value => {
    triggerGAEvent("Notifications", "Changing City in Parent Table");
    this.setState({ selectedCity: value }, () => this.fetchData());
  };

  render() {
    let {
      parent_sortedInfo,
      password_sortedInfo,
      interest_sortedInfo,
      lc_sortedInfo,
      // data_sortedInfo
    } = this.state;
    parent_sortedInfo = parent_sortedInfo || {};
    password_sortedInfo = password_sortedInfo || {};
    interest_sortedInfo = interest_sortedInfo || {};
    lc_sortedInfo = lc_sortedInfo || {};
    // data_sortedInfo = data_sortedInfo || {};

    const unique = _.compact([
      ...new Set(
        this.state.parent_signup.map(
          parent => parent.city && parent.city.toLowerCase()
        )
      )
    ]);

    const passwordColumns = [
      {
        title: "Name",
        dataIndex: "created_by_name",
        width: "40%",
        render: (text, record) => `${record.first_name} ${record.last_name}`,
      },
      {
        title: "Username",
        dataIndex: "notification_name",
        width: "40%"
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        sorter: (a, b) =>
          Moment(a.createdAt).format("x") - Moment(b.createdAt).format("x"),
        sortOrder:
          password_sortedInfo.columnKey === "createdAt" &&
          password_sortedInfo.order,
        render: date => <TimeAgo date={Number(Moment(date).format("x"))} />,
        width: "20%"
      }
    ];

    const lcColumns = [
      {
        title: "Learning centre",
        dataIndex: "created_by_name",
        width: "30%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`learningcontent/${record.notification_id}/view/draft`}
          >
            <div>{`${record.first_name}`}</div>
          </Link>
        )
      },
      {
        title: "Creator Type",
        dataIndex: "role_id",
        width: "20%",
        filters: [
          {
            text: "Content creators",
            value: 4
          },
          {
            text: "Learning centre",
            value: 2
          }
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => {
          if (value === 4) {
            return parseInt(record.role_id) === 4 || parseInt(record.role_id) === 5;
          } else {
            return parseInt(record.role_id) === parseInt(value);
          }
        },
        render: (text, record) => (
          <Link
            target="_blank"
            to={`learningcontent/${record.notification_id}/view/draft`}
          >
            <div>{text === 2 ? "Learning centre" : "Content Creator"}</div>
          </Link>
        )
      },
      {
        title: "Learning Content",
        dataIndex: "notification_name",
        width: "30%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`learningcontent/${record.notification_id}/view/draft`}
          >
            <div>{text}</div>
          </Link>
        )
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        sorter: (a, b) =>
          Moment(a.createdAt).format("x") - Moment(b.createdAt).format("x"),
        sortOrder:
          lc_sortedInfo.columnKey === "createdAt" && lc_sortedInfo.order,
        width: "20%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`learningcontent/${record.notification_id}/view/draft`}
          >
            <div>
              <TimeAgo date={Number(Moment(text).format("x"))} />
            </div>
          </Link>
        )
      }
    ];

    const interestsColumns = [
      {
        title: "Interest Name",
        dataIndex: "notification_name",
        width: "40%"
      },
      {
        title: "Suggested by",
        dataIndex: "created_by_name",
        width: "40%",
        render: (text, record) => `${record.first_name}`,
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        sorter: (a, b) =>
          Moment(a.createdAt).format("x") - Moment(b.createdAt).format("x"),
        sortOrder:
          interest_sortedInfo.columnKey === "createdAt" &&
          interest_sortedInfo.order,
        render: date => <TimeAgo date={Number(Moment(date).format("x"))} />,
        width: "20%"
      }
    ];

    // const dataPointColumns = [
    //   {
    //     title: "Data point Name",
    //     dataIndex: "notification_name",
    //     width: "40%"
    //   },
    //   {
    //     title: "Suggested by",
    //     dataIndex: "created_by_name",
    //     width: "40%",
    //     render: (text, record) => `${record.first_name}`,
    //   },
    //   {
    //     title: "Time",
    //     dataIndex: "createdAt",
    //     sorter: (a, b) =>
    //       Moment(a.createdAt).format("x") - Moment(b.createdAt).format("x"),
    //     sortOrder:
    //       data_sortedInfo.columnKey === "createdAt" && data_sortedInfo.order,
    //     render: date => <TimeAgo date={Number(Moment(date).format("x"))} />,
    //     width: "20%"
    //   }
    // ];

    const parentColumns = [
      {
        title: "Parent name",
        key: "parent_name",
        sorter: (a, b) => `${a.parent_firstName} ${a.parent_lastName}`.localeCompare(`${b.parent_firstName} ${b.parent_lastName}`),
        sortOrder: parent_sortedInfo.columnKey === "parent_name" && parent_sortedInfo.order,
        render: (text, record, index) => `${record.parent_firstName || ""} ${record.parent_lastName || ""}`,
        width: "13%"
      },
      {
        title: "Child name",
        key: "child_name",
        sorter: (a, b) => `${a.student_firstName} ${a.student_lastName}`.localeCompare(`${b.student_firstName} ${b.student_lastName}`),
        sortOrder: parent_sortedInfo.columnKey === "child_name" && parent_sortedInfo.order,
        render: (text, record, index) => `${record.student_firstName || ""} ${record.student_lastName || ""}`,
        width: "10%"
      },
      {
        title: "Email",
        dataIndex: "email",
        width: "14%"
      },
      {
        title: "Mobile Number",
        dataIndex: "phone_no",
        width: "10%"
      },
      {
        title: "Applied on",
        dataIndex: "createdAt",
        sorter: (a, b) =>
          Moment(a.createdAt).format("x") - Moment(b.createdAt).format("x"),
        sortOrder:
          parent_sortedInfo.columnKey === "createdAt" &&
          parent_sortedInfo.order,
        render: date => Moment(date).format("MMM D, YYYY"),
        width: "12%"
      },
      {
        title: "Operation",
        dataIndex: "",
        render: (d, record, index) => (
          <div
            className="pointer-cursor"
            onClick={() =>
              this.setState({ parentVisible: true, selected_parent: record })
            }
          >
            Manage
          </div>
        ),
        width: "8%"
      }
    ];

    const workshopColumns = [
      {
        title: "Workshop name",
        dataIndex: "workshop_details[0].workshop_name",
        width: "30%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`workshops/${record.notification_id}/view/draft`}
          >
            <div>{`${record.workshop_details ? record.workshop_details.workshop_name : ""}`}</div>
          </Link>
        )
      },
      {
        title: "Creator name",
        dataIndex: "created_by_name",
        width: "20%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`workshops/${record.notification_id}/view/draft`}
          >
            <div>{`${record.first_name}`}</div>
          </Link>
        )
      },
      {
        title: "Workshop dates",
        dataIndex: "workshop_details.workshop_date",
        width: "20%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`workshops/${record.notification_id}/view/draft`}
          >
            <div>{text}</div>
          </Link>
        )
      },
      {
        title: "Time",
        dataIndex: "workshop_details.workshop_time",
        width: "10%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`workshops/${record.notification_id}/view/draft`}
          >
            <div>
             {Moment(text, 'h:mma').format("h:mma")} 
            </div>
          </Link>
        )
      },
      {
        title: "Fee",
        dataIndex: "workshop_details.workshop_type",
        width: "10%",
        render: (text, record) => (
          <Link
            target="_blank"
            to={`workshops/${record.notification_id}/view/draft`}
          >
            <div>{
              (record.workshop_details && record.workshop_details.workshop_type == 'Paid') ? 
              record.workshop_details.workshop_amount : text
            }</div>
          </Link>
        )
      }
    ];

    const {
      // data_points,
      interests,
      selected_parent_signup,
      parent_signup,
      learning_content,
      password_forget,
      total_count_links,
      workshop_content
    } = this.state;
    console.log(" workshop Notification ==> ", workshop_content)
    return (
      <React.Fragment>
        <div className="ml-4 mt-4 mr-4  p-2" style={{ height: "100vh" }}>
          <Row>
            <Col span={12} style={{ textAlign: "left", marginBottom: "1rem" }}>
              <h4>All Notifications</h4>
            </Col>
          </Row>
          <Card className="notification-card">
            <Row>
              <Col span={6}>
                <Menu
                  defaultSelectedKeys={["1"]}
                  selectedKeys={[this.state.activeKey]}
                  mode="vertical"
                  onClick={this.onMenuClick}
                >
                  <Menu.Item key="1">
                    <span>Passcode Reset</span>
                    {password_forget.length ? (
                      <span className="notification-count">
                        {password_forget.length}
                      </span>
                    ) : null}
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span>Learning Content Approval</span>
                    {learning_content.length ? (
                      <span className="notification-count">
                        {learning_content.length}
                      </span>
                    ) : null}
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span>Suggested Interests</span>
                    {interests.length ? (
                      <span className="notification-count">
                        {interests.length}
                      </span>
                    ) : null}
                  </Menu.Item>
                  {/*<Menu.Item key="4">*/}
                  {/*  <span>Suggested Data Points</span>*/}
                  {/*  {data_points.length ? (*/}
                  {/*    <span className="notification-count">*/}
                  {/*      {data_points.length}*/}
                  {/*    </span>*/}
                  {/*  ) : null}*/}
                  {/*</Menu.Item>*/}
                  <Menu.Item key="5">
                    <span>New Parent Onboarding</span>
                    {parent_signup.length ? (
                      <span className="notification-count">
                        {parent_signup.length}
                      </span>
                    ) : null}
                  </Menu.Item>
                  <Menu.Item key="6">
                    <span>YouTube link validation</span>
                    {total_count_links ? (
                      <span className="notification-count">
                        {total_count_links}
                      </span>
                    ) : null}
                  </Menu.Item>
                  <Menu.Item key="7">
                    <span>Workshop Approval</span>
                    {workshop_content.length ? (
                      <span className="notification-count">
                        {workshop_content.length}
                      </span>
                    ) : null}
                  </Menu.Item>
                </Menu>
              </Col>
              <Col span={18} className="notification-content">
                {this.state.activeKey === "1" && (
                  <div>
                    <div className="single-notification pointer-normal">
                      PASSCODE RESET REQUEST
                    </div>
                    {password_forget.length === 0 && (
                      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    )}
                    {password_forget.length !== 0 && (
                      <div>
                        <Table
                          className="notification-table"
                          columns={passwordColumns}
                          dataSource={password_forget}
                          rowKey={student => student.id}
                          onChange={(pagination, filters, sorter) =>
                            this.handleTableChange(
                              pagination,
                              filters,
                              sorter,
                              "password"
                            )
                          }
                          rowClassName="table-row"
                          onRow={(record, rowIndex) => ({
                            onClick: () =>
                              this.props.history.push(
                                `/students/${record.notification_id}`
                              )
                          })}
                          scroll={{ y: 500 }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.state.activeKey === "2" && (
                  <div>
                    <div className="single-notification pointer-normal">
                      LEARNING CONTENT APPROVAL REQUESTS
                    </div>
                    {learning_content.length === 0 && (
                      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    )}
                    {learning_content.length !== 0 && (
                      <div className="lcApproval">
                        <Table
                          className="notification-table"
                          columns={lcColumns}
                          dataSource={learning_content}
                          rowKey={student => student.id}
                          onChange={(pagination, filters, sorter) =>
                            this.handleTableChange(
                              pagination,
                              filters,
                              sorter,
                              "lc"
                            )
                          }
                          rowClassName="table-row"
                          scroll={{ y: 500 }}
                          // onRow={(record, rowIndex) => ({ onClick: () => this.props.history.push(`${record.notification_type === "activity" ? `/activity/draft/view/` : record.library_default_id === 1 ? `/library/book/draft/view/` : record.library_default_id === 2 ? `/library/documentary/draft/view/` : `/library/music/draft/view/`}${record.notification_id}`) })}
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.state.activeKey === "3" && (
                  <div>
                    <div className="single-notification pointer-normal">
                      SUGGESTED INTERESTS
                    </div>
                    {interests.length === 0 && (
                      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    )}
                    {interests.length !== 0 && (
                      <div>
                        <Table
                          className="notification-table"
                          columns={interestsColumns}
                          dataSource={interests}
                          rowKey={student => student.id}
                          onChange={(pagination, filters, sorter) =>
                            this.handleTableChange(
                              pagination,
                              filters,
                              sorter,
                              "interest"
                            )
                          }
                          rowClassName="table-row"
                          onRow={(record, rowIndex) => ({
                            onClick: () =>
                              this.onClickSuggestedRow("interest", record)
                          })}
                          scroll={{ y: 500 }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {/*{this.state.activeKey === "4" && (*/}
                {/*  <div>*/}
                {/*    <div className="single-notification pointer-normal">*/}
                {/*      SUGGESTED DATA POINTS*/}
                {/*    </div>*/}
                {/*    {data_points.length === 0 && (*/}
                {/*      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">*/}
                {/*        <img src={noNotifications} alt="no notifications" />*/}
                {/*        <div className="no-notifications-text">*/}
                {/*          <div>You’re all caught up!</div>*/}
                {/*          <div>Check back later for notifications</div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*    {data_points.length !== 0 && (*/}
                {/*      <div>*/}
                {/*        <Table*/}
                {/*          className="notification-table"*/}
                {/*          columns={dataPointColumns}*/}
                {/*          dataSource={data_points}*/}
                {/*          rowKey={student => student.id}*/}
                {/*          onChange={(pagination, filters, sorter) =>*/}
                {/*            this.handleTableChange(*/}
                {/*              pagination,*/}
                {/*              filters,*/}
                {/*              sorter,*/}
                {/*              "data"*/}
                {/*            )*/}
                {/*          }*/}
                {/*          rowClassName="table-row"*/}
                {/*          onRow={(record, rowIndex) => ({*/}
                {/*            onClick: () =>*/}
                {/*              this.onClickSuggestedRow("data", record)*/}
                {/*          })}*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*)}*/}
                {this.state.activeKey === "5" && (
                  <div>
                    <div className="single-notification pointer-normal">
                      NEW PARENTS
                      <Select
                        placeholder="select city"
                        size="large"
                        className="ml-3"
                        style={{ width: 200 }}
                        value={this.state.selectedCity}
                        onChange={this.onSelectChange}
                      >
                        <Select.Option key={0} value={""}>
                          -- Select City --
                        </Select.Option>
                        {unique.map((x, i) => (
                          <Select.Option key={i + 1} value={x}>
                            <span className="text-capitalize">{x}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    {parent_signup.length === 0 && (
                      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    )}
                    {parent_signup.length > 0 && (
                      <div>
                        <Table
                          className="notification-table"
                          columns={parentColumns}
                          dataSource={selected_parent_signup}
                          rowKey={student => student.id}
                          onChange={(pagination, filters, sorter) =>
                            this.handleTableChange(
                              pagination,
                              filters,
                              sorter,
                              "parent"
                            )
                          }
                          rowClassName="table-row"
                          onRow={(record, rowIndex) => ({
                            onClick: e =>
                              this.setState({
                                parentVisible: true,
                                selected_parent: record
                              })
                          })}
                          scroll={{ y: 500 }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div style={{ display: this.state.activeKey === "6" ? "block" :"none" }}>
                  <InvalidYoutubeLinks onChangeCount={(count) => this.setState({ total_count_links: count })} />
                </div>
                {this.state.activeKey === "7" && (
                  <div>
                    {workshop_content.length === 0 && (
                      <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    )}
                    {workshop_content.length > 0 && (
                      <div className="lcApproval">
                        <Table
                          className="notification-table"
                          columns={workshopColumns}
                          dataSource={workshop_content}
                          rowKey={student => student.id}
                          // onChange={(pagination, filters, sorter) =>
                          //   this.handleTableChange(
                          //     pagination,
                          //     filters,
                          //     sorter,
                          //     "workshop"
                          //   )
                          // }
                          scroll={{ y: 500 }}
                          rowClassName="table-row"
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </div>
        <Modal
          title={`Suggested by - ${this.state.suggested_by}`}
          visible={this.state.modalVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          width={700}
          footer={[
            <Button
              key="1"
              size="large"
              type="default"
              className="step-cancel"
              onClick={() =>
                this.state.type === "data"
                  ? this.approveDataPoints(2)
                  : this.approveInterests(2)
              }
            >
              Reject
            </Button>,
            <Button
              key="2"
              size="large"
              type="primary"
              onClick={this.onApproveBtnClick}
            >
              {`Approve ${this.state.type === "data" ? "Data" : "Interest"}`}
            </Button>
          ]}
        >
          <Row justify="center" align="middle" type="flex">
            <Col span={6} className="interest-image">
              <ImageUploadCrop
                imageUrl={this.state.profile_image}
                onSuccess={this.FeaturedImageUploadSuccess}
                aspect={1}
                id={this.state.truthObj["profile_image"] ? "has-error" : ""}
              />
            </Col>
            <Col span={18} className="pl-3">
              <div className="mb-3">
                <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                  {`${
                    this.state.type === "data" ? "Data Point" : "Interest"
                  } Name`}
                </div>
                <Input
                  name="name"
                  onChange={this.onInputChange}
                  value={this.state.name}
                  size="large"
                  placeholder="Enter the name"
                  id={this.state.truthObj["name"] ? "has-error" : ""}
                />
              </div>
              <div className="mb-3">
                <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                  {`${
                    this.state.type === "data" ? "Data Point" : "Interest"
                  } Category`}
                </div>
                <Select
                  style={{ width: "100%" }}
                  onChange={this.handleCategorySelect}
                  value={this.state.category_id}
                  size="large"
                  id={this.state.truthObj["category_id"] ? "has-error" : ""}
                >
                  {this.state.categories.map((category, index) => (
                    <Select.Option value={category.id} key={index}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              {" "}
              Description{" "}
            </div>
            <Input.TextArea
              rows={4}
              name="desc"
              placeholder="Enter a description"
              value={this.state.desc}
              onChange={this.onInputChange}
              size="large"
              id={this.state.truthObj["desc"] ? "has-error" : ""}
            />
          </div>
        </Modal>
        <Modal
          title={
            <div className="d-flex flex-column">
              <div style={{ margin: "0.5rem 0" }}>
                {this.state.selected_parent.notification_name}
              </div>
              <div style={{ margin: "0.5rem 0" }}>
                {this.state.selected_parent.city}
              </div>
              <div style={{ margin: "0.5rem 0" }}>
                {this.state.selected_parent.email} •{" "}
                {this.state.selected_parent.phone_no}
              </div>
            </div>
          }
          visible={this.state.parentVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          footer={[
            <Button
              key="1"
              size="large"
              type="default"
              className="step-cancel"
              onClick={this.onHoldParent}
            >
              On hold
            </Button>,
            <Button
              key="2"
              size="large"
              type="primary"
              onClick={this.approveParent}
            >
              Approve
            </Button>
          ]}
        >
          <Input.TextArea
            rows={4}
            name="on_hold_text"
            placeholder="Enter a description"
            value={this.state.on_hold_text}
            onChange={this.onInputChange}
            size="large"
          />
          <div
            style={
              this.state.on_hold_text
                ? this.state.on_hold_text.length >= 280
                  ? { color: "#FF0A00", float: "right", marginTop: 8 }
                  : { float: "right", marginTop: 8 }
                : { float: "right", marginTop: 8 }
            }
          >
            {this.state.on_hold_text ? this.state.on_hold_text.length : 0} / 280
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
