import PropTypes from "prop-types";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import ProgressiveImage from "react-progressive-image";
import styled, { css, keyframes } from "styled-components";
import CollectionIcon from "../../../static/expert/icons/collection.svg";
const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;
const CollectionContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  min-width: ${props => props.isLC ? '234px' : '100%'} !important;
  width: ${props => props.isLC ? '234px' : '100%'} !important;
  ${props =>
    props.noMargin &&
    css`
       {
        margin-bottom: 0;
      }
    `}
  .titleContainer {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    bottom: 0px;
    width: 100%;
    left: 0px;
    right: 0;
    padding-bottom: 14px;

    /* padding-bottom: 10px; */
    & .title {
      font-style: normal;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
    }
    & .subTitle {
      font-style: normal;
      padding-left: 20px;
      padding-top: 1px;
      padding-right: 20px;
      font-style: italic;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      color: #ffffff;
    }
  }
  .ImgCard {
    min-height: ${props => props.isLC ? '274px' : '182px'} !important;
    height: ${props => props.isLC ? '274px' : '182px'} !important;
    min-width: ${props => props.isLC ? '234px' : '100%'} !important;
    width: ${props => props.isLC ? '234px' : '100%'} !important;
    object-fit: cover;
  }
  .skeleton {
    min-height: ${props => props.isLC ? '274px' : '182px'};
    height: ${props => props.isLC ? '274px' : '182px'} !important;
    min-width: ${props => props.isLC ? '234px' : '100%'} !important;
    width: ${props => props.isLC ? '234px' : '100%'} !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
  .CollectionIcon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  @media only screen and (max-width: 576px) {
    width: 100%!important;
    .ImgCard {
      width: 100%!important;
    }
  }
`;

const CollectionCard = props => {
  return (
    <CollectionContainer
      className="position-relative overflow-hidden"
      noMargin={props.noMargin}
      isLC={props.isLC}
    >
      {props.collectionIcon && (
        <img alt="" src={CollectionIcon} className="img-fluid CollectionIcon" />
      )}
      <ProgressiveImage src={props.src} placeholder="">
        {(src, loading) => {
          return loading ? (
            <div className="ImgCard w-100 h-100 skeleton" />
          ) : (
            <img
              className="w-100 ImgCard h-100"
              src={src}
              alt="activity card"
            />
          );
        }}
      </ProgressiveImage>
      {/* <img src={props.src} alt="Card" /> */}
      {!props.loading && (
        <React.Fragment>
          {props.title !== "" && (
            <div className="titleContainer">
              <div className="title">
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={props.title}
                  trimRight={true}
                  maxLine={props.isLC ? "4" :"1"}
                  ellipsis="..."
                  basedOn="letters"
                />
              </div>
              {props.subTitle !== "" && (
                <div className="subTitle">
                  <LinesEllipsis
                    style={{ whiteSpace: "pre-wrap" }}
                    text={props.subTitle}
                    trimRight={true}
                    maxLine="1"
                    ellipsis="..."
                    basedOn="letters"
                  />
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </CollectionContainer>
  );
};
CollectionCard.defaultProps = {
  subTitle: "",
  title: ""
};

CollectionCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  collectionIcon: PropTypes.bool,
  isLC: PropTypes.bool,
};

export default CollectionCard;
