import { Button, Col, Input, Modal, Row } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import CategoryCard from "../../components/Card/CategoryCard";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import { ADD_DATA_TYPE, DELETE_DATATYPE, GET_DATA_TYPE } from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import emptyState from "../../static/activity/emptyStateSearch.png";
import ErrorActivity from "../../static/activity/error.svg";
import {
  openNotification,
  showNotification,
  triggerGAEvent
} from "../../utils";

const Search = Input.Search;
export default class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: null,
      loading: false,
      visible: false,
      dataTypes: [],
      newDataTypeInput: "",
      searchText: "",
      sort: "newest"
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData() {
    this.setState({ loading: true });
    let query = GET_DATA_TYPE;
    query.variables = {
      search: this.state.searchText.trim(),
      sort: this.state.sort
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          dataTypes: res.data.data.getDataGrade.data_grade || [],
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false, is_500: true });
      });
  }

  handleOk = e => {
    triggerGAEvent("Data", "Create a new Data Types");
    if (this.state.newDataTypeInput === "") {
      showNotification(
        "error",
        "Something went wrong",
        "Please enter a Data type name"
      );
    } else {
      let mutation = ADD_DATA_TYPE;
      mutation.variables = {
        name: this.state.newDataTypeInput.trim()
      };

      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
          } else {
            openNotification("success", "New data type created");
            this.handleCancel();
            this.fetchData();
          }
        })
        .catch(err => console.log(err));
    }
  };

  deleteDataType = id => {
    triggerGAEvent("Data", "Delete a Data Types");
    if (id < 4) {
      showNotification(
        "error",
        "Unable to delete Data Type",
        "You cannot delete default data points."
      );
    } else if (
      this.state.dataTypes.filter(d => d.id === id)[0].total_data_points !== 0
    ) {
      showNotification(
        "error",
        "Unable to delete Data Type",
        "Cannot delete data type when data points are present under it."
      );
    } else {
      const confirmDialog = Modal.confirm({
        title: "Delete a Data Type",
        content: "Are you sure?",
        okText: "Yes",
        onOk: () => {
          let mutation = DELETE_DATATYPE;
          mutation.variables = {
            grade_id: id
          };
          axios
            .post(`${process.env.REACT_APP_API}`, mutation)
            .then(res => {
              if (res.data.errors) {
                openNotification("error", res.data.errors[0].message);
              } else {
                this.fetchData();
              }
            })
            .catch(err => console.log(err));
        }
      });
      this.setState({ confirmDialog });
    }
  };

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id"))
    });
  };

  componentDidMount() {
    this.getLocalStorage();
    this.fetchData();
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleCancel = () => {
    triggerGAEvent("Data", "Closing Create Modal Popup");
    this.setState({ newDataTypeInput: "", visible: false, loading: false });
  };

  onSearchFetchData = () => {
    triggerGAEvent("Data", "Searching Data Types");
    this.fetchData();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };

  sortChange = value => {
    triggerGAEvent("Data", "Changing Sort in Data Types");
    this.setState({ sort: value }, () => this.fetchData());
  };

  render() {
    if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Loading is_loading={this.state.loading} />

          <div style={{ margin: "1.7rem" }}>
            <Row>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mt-3 mb-0 p-0">
                  All Data Types: {this.state.dataTypes.length}
                </h4>
                <div className="d-flex align-items-center justify-content-center">
                  <Search
                    placeholder="Search"
                    style={{ width: 400 }}
                    size="large"
                    onChange={this.onSearch}
                    allowClear
                  />
                  {this.state.role_id === 1 && (
                    <Button
                      type="primary"
                      block
                      size="large"
                      onClick={this.showModal}
                      className="ml-3 btn-w350"
                    >
                      Create New Data Type
                    </Button>
                  )}
                </div>
              </div>
            </Row>
            <div>
              <Row style={{ marginRight: 15, marginTop: 30 }}>
                <Sort
                  Edit={true}
                  data={this.state.sort}
                  sortChange={this.sortChange}
                />
              </Row>
            </div>
            <Row gutter={24} style={{ marginTop: "0.5rem" }}>
              {(this.state.dataTypes || []).length === 0 &&
                !this.state.loading && (
                  <div className=" d-flex justify-content-center align-items-center flex-column mt-4">
                    <img className="mb-3" src={emptyState} alt="" />
                    <div className="text-center">
                      <p className="FontAvenirMedium font-20 mb-2">
                        No results found. Please try again with different
                        keywords
                      </p>
                    </div>
                  </div>
                )}
              {this.state.dataTypes.map((dataType, index) => (
                <Col span={6} style={{ margin: "0.85rem 0" }} key={index}>
                  <CategoryCard
                    id={dataType.id}
                    title={dataType.name}
                    desc={`Number of data points ${dataType.total_data_points}`}
                    borderColor="lightgreen"
                    loading={this.state.loading}
                    delete={this.deleteDataType}
                    role_id={this.state.role_id}
                    DpCard
                  />
                </Col>
              ))}
            </Row>
          </div>
          <Modal
            maskClosable={false}
            title="Create New Data Type"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" size="large" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                size="large"
                type="primary"
                onClick={this.handleOk}
              >
                Create Data Type
              </Button>
            ]}
          >
            <div className="FontAvenirRoman text-secondary font-16 mb-3">
              Data Type name
            </div>
            <Input
              name="newDataTypeInput"
              onChange={this.onInputChange}
              size="large"
              placeholder="Add name"
              value={this.state.newDataTypeInput}
            />
          </Modal>
        </React.Fragment>
      );
    }
  }
}
