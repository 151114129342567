import { Button, Card, Form } from "antd";
import React from "react";
import logo from "../../assets/breadcrumb_logo.png";
import "../SignIn/Signin.css";

const FormItem = Form.Item;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.next();
    });
  };

  onChange(e) {
    this.setState({ email: e.target.value });
    this.props.onChange("email", e.target.value);
  }

  render() {
    // console.log(this.props.role_id)
    return (
      <div className="card-wrap">
        <div className="">
          <img src={logo} className="logo" alt="logo" />
          <Card className="card-radius">
            <Form onSubmit={this.handleSubmit}>
              <h3 className="heading">Welcome to the team</h3>
              {this.props.role_id !== "" && (
                <div className="flex-wrap text-uppercase">
                  {this.props.role_id === "4" ? "Expert" : ""}
                  {this.props.role_id === "2" ? "Learning Centre" : ""}
                  {this.props.role_id === "5" ? "Curator" : ""}
                </div>
              )}
              <div className="font14 mtb-20">
                Email -{" "}
                <span style={{ fontWeight: "800" }}>
                  {this.props.inviteEmail}
                </span>
              </div>
              <FormItem className="btn-signin">
                <Button type="primary" htmlType="submit" block size="large">
                  Proceed
                </Button>
              </FormItem>
              <FormItem className="t-center mb-0"></FormItem>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

const LCSignin = Form.create()(App);

export default LCSignin;
