import {
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Table,
  Tag,
  Breadcrumb,
  Card
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Audio from '../../components/Audio'
import Bars from '../../components/Bars'
import { GET_PARENT_FEEDBACK } from '../../graphql'
import PlayAudio from '../../static/Polygon.svg'
import { base64regex, triggerGAEvent } from '../../utils'
import { GET_STUDENT_ALL_FEEDBACK } from './api'
import noFeedBacks from '../../static/no_feedback.svg'
import MblFeedBackEmpty from '../../static/journal/mbl-feedback-empty.svg'
import MblFeedBackSorting from '../../static/journal/FeedBackSort.svg'
import WatchCard from '../../static/journal/mbl_watch_card.svg'
import DoCard from '../../static/journal/mbl_do_card.svg'
import './StudentFeedback.css'
class StudentFeedback extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      is_loading: false,
      is_playing: false,
      student_details: { fname: '', lname: '' },
      allFeedBacks: [],
      feedbackData: {},
      feedbackModalVisible: false,
      audioModal: false,
      isSort: false,
      sortedInfo: {
        columnKey: 'date',
        field: 'date',
        order: 'descend'
      }
    }
  }

  fetchLCFeedBacks = () => {
    let query = GET_STUDENT_ALL_FEEDBACK
    query.variables = {
      student_id: parseInt(this.props.match.params.id, 10),
      notification_id:
        this.props.location.state !== undefined
          ? this.props.location.state.id
          : null,
      sort: this.state.isSort
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          allFeedBacks: res.data.data.lcFeedbacklistV2.feedbacklist,
          student_details: res.data.data.lcFeedbacklistV2.student_details,
          feedbackModalVisible: this.props.location.state !== undefined,
          feedbackData:
            this.props.location.state !== undefined
              ? res.data.data.lcFeedbacklistV2.feedbacklist.filter(
                  feed => feed.id === this.props.location.state.notification_id
                )[0]
              : {}
        })
      })
      .catch(err => console.log(err))
  }

  fetchParentFeedback = () => {
    let query = GET_PARENT_FEEDBACK
    query.variables = {
      student_id: parseInt(this.props.match.params.id, 10),
      sort: this.state.isSort
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          allFeedBacks: res.data.data.ParentFeedbacks.feedbacklist
          // student_details: res.data.data.lcFeedbacklistV2.student_details,
        })
      })
      .catch(err => console.log(err))
  }

  componentDidMount () {
    if (this.props.history.location.pathname.includes('learner')) {
      this.fetchLCFeedBacks()
    } else {
      this.fetchParentFeedback()
    }
  }

  typeFormatter = (text, record, index) => {
    if (record.feedback_type === 'general') {
      return (
        <React.Fragment>
          <Tag color='blue'>General</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'activity') {
      return (
        <React.Fragment>
          <Tag color='orange'>Activity</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'library') {
      return (
        <React.Fragment>
          <Tag color='cyan'>Library</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Watch') {
      return (
        <React.Fragment>
          <Tag color='orange'>Watch</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Do') {
      return (
        <React.Fragment>
          <Tag color='cyan'>Do</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Listen') {
      return (
        <React.Fragment>
          <Tag color='orange'>Listen</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Read') {
      return (
        <React.Fragment>
          <Tag color='cyan'>Read</Tag>
        </React.Fragment>
      )
    }
  }

  mblTypeFormatter = (record = '') => {
    if (record.feedback_type === 'general') {
      return (
        <React.Fragment>
          <Tag color='orange' className='tagGeneral'>
            General
          </Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'activity') {
      return (
        <React.Fragment>
          <Tag color='orange'>Activity</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'library') {
      return (
        <React.Fragment>
          <Tag color='cyan'>Library</Tag>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Watch') {
      return (
        <React.Fragment>
          <div className='feedType-img'>
            <img src={WatchCard} className='img-fluid' alt='' />
          </div>
        </React.Fragment>
      )
    } else if (record.feedback_type === 'Do') {
      return (
        <React.Fragment>
          <div className='feedType-img'>
            <img src={DoCard} className='img-fluid ' alt='' />
          </div>
        </React.Fragment>
      )
    }
  }

  dateFormatter = (text, record, index) => {
    return moment(parseInt(record.date, 10)).format('DD - MM - YYYY')
  }

  feedbackDataFormatter = (text, record, index) => {
    return (
      <LinesEllipsis
        text={record.feedback}
        maxLine='3'
        ellipsis='...'
        trimRight
        basedOn='letters'
      />
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    triggerGAEvent('Students', 'Changing Filters and Sort in Learner Feedback')
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }

  viewFeedbackModal = record => {
    triggerGAEvent('Students', 'View Feedback')
    this.setState({ feedbackData: record, feedbackModalVisible: true })
  }

  onAudioBtnClick = (data = '') => {
    triggerGAEvent('Students', 'View Audio Feedback')
    if (data) {
      setTimeout(() => {
        this.setState({
          audioModal: true,
          feedbackModalVisible: false,
          feedbackData: data
        })
      }, 1)
    } else {
      this.setState({ audioModal: true })
    }
  }

  onCloseModal = () => {
    triggerGAEvent('Students', 'Closing Feedback Popup')
    this.setState(
      {
        // feedbackData: {},
        feedbackModalVisible: false,
        audioModal: false
      },
      () => {
        this.setState({ feedbackData: {} })
      }
    )
  }

  render () {
    let sortedInfo = this.state.sortedInfo || {}

    const newColumns = [
      {
        title: '',
        dataIndex: '',
        width: '3%'
      },
      {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => a.date - b.date,
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        defaultSortOrder: 'descend',
        width: '15%',
        render: this.dateFormatter,
        onCell: (record, rowIndex) => ({
          onClick: e => this.viewFeedbackModal(record)
        })
      },
      {
        title: 'Feedback',
        dataIndex: 'feedback',
        width: '50%',
        render: this.feedbackDataFormatter,
        onCell: (record, rowIndex) => ({
          onClick: e => this.viewFeedbackModal(record)
        })
      },
      {
        title: 'Audio Feedback',
        dataIndex: 'feedback_voice',
        width: '20%',
        render: (text, record, index) =>
          record.feedback_voice && (
            <div
              className='play-audio-btn pointer-cursor'
              onClick={() => this.onAudioBtnClick(record)}
            >
              <img src={PlayAudio} alt='play button' className='mr-2' />
              <span style={{ color: '#3EC0F1', marginTop: 3 }}>
                Play audio feedback
              </span>
            </div>
          )
      },
      {
        title: 'Type',
        dataIndex: 'feedback_type',
        render: this.typeFormatter,
        width: '13%',
        onCell: (record, rowIndex) => ({
          onClick: e => this.viewFeedbackModal(record)
        })
      }
    ]

    return (
      <React.Fragment>
        <div style={{ margin: '1.7rem' }} className='mbl-main-div'>
          <div className='mbl-dev-feedback-title'>
            <div className='d-flex align-items-center justify-content-start mb-2'>
              <div>
                <Breadcrumb>
                  <Breadcrumb.Item
                    style={{ color: '#828282' }}
                    href={`/parent/dashboard`}
                  >
                    Learner details
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='Mbl-Feedback-breadcumb'>
                    View all feedback
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <div className='d-flex justify-content-start'>
                <div className='mr-2'>
                  <Icon
                    type='arrow-left'
                    style={{
                      color: '#333333',
                      cursor: 'pointer',
                      fontSize: 18
                    }}
                    onClick={() => this.props.history.push(`/parent/dashboard`)}
                  />
                </div>
                <div>
                  <Link
                    to={{
                      pathname: `/parent/dashboard`
                    }}
                  >
                    <h5
                      style={{
                        color: '#333333',
                        fontWeight: 800,
                        fontSize: 18
                      }}
                    >
                      Learner Feedbacks
                    </h5>
                  </Link>
                </div>
              </div>
              <div>
                <img
                  src={MblFeedBackSorting}
                  alt='Feedback sorting'
                  className='cursor-pointer'
                  onClick={() => {
                    this.setState(
                      {
                        isSort: !this.state.isSort
                      },
                      () => {
                        if (
                          this.props.history.location.pathname.includes(
                            'learner'
                          )
                        ) {
                          this.fetchLCFeedBacks()
                        } else {
                          this.fetchParentFeedback()
                        }
                      }
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className='max-dev-feedback-title'>
            <Link
              to={{
                pathname: this.props.history.location.pathname.includes(
                  'learner'
                )
                  ? `/learners/${this.props.match.params.id}`
                  : `/parent/dashboard`
              }}
            >
              <h5 style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                Back to{' '}
                {this.props.history.location.pathname.includes('learner')
                  ?  this.state.student_details.is_delete ? "Account Deleted" : `${this.state.student_details.fname} ${this.state.student_details.lname}`
                  : `Dashboard`}
              </h5>
            </Link>
          </div>
          <Row className='max-dev-feedback-title'>
            <Col span={16}>
              <h4 style={{ margin: '0.5rem 0 0 0', padding: 0 }}>
                Learner Feedback
              </h4>
            </Col>
            <Col span={8}>
              {/*<Search*/}
              {/*placeholder="Search Activities Here"*/}
              {/*style={{ width: '100%', height: '100%'}}*/}
              {/*size="large"*/}
              {/*onChange={this.onSearch}*/}
              {/*/>*/}
            </Col>
          </Row>
        </div>
        <div style={{ margin: '1rem' }} className='studentsDataTable'>
          {this.state.allFeedBacks.length === 0 && (
            <div className='d-flex flex-column align-items-center justify-content-center max-dev-feedback-title'>
              <img src={noFeedBacks} alt='no feedbacks' className='mb-4' />
              <p className='font-20' style={{ color: '#4A4A4A' }}>
                Feedback have not given to the student yet
              </p>
            </div>
          )}
          {this.state.allFeedBacks.length === 0 && (
            <div className='Mbl-feedback-empty d-flex flex-column align-items-center justify-content-center'>
              <img src={MblFeedBackEmpty} alt='no feedbacks' className='mb-4' />
              <p
                className='font-16'
                style={{ color: '#4A4A4A', fontWeight: 500 }}
              >
                Feedback haven’t given to the student yet
              </p>
            </div>
          )}
          {this.state.allFeedBacks.length !== 0 && (
            <Table
              className='table-full'
              columns={newColumns}
              rowKey={feedback => feedback.id}
              dataSource={this.state.allFeedBacks}
              onChange={this.handleTableChange}
              rowClassName='table-row'
            />
          )}
          {/* Mbl dev feedback cards */}
          {this.state.allFeedBacks.length !== 0 && (
            <div className='mbl-FeedbacksCards'>
              {this.state.allFeedBacks.map((feedback, index) => {
                return (
                  <React.Fragment>
                    {index > 0 &&
                    moment(
                      parseInt(this.state.allFeedBacks[index - 1].date, 10)
                    ).format('YYYY-MM-DD') ==
                      moment(parseInt(feedback.date, 10)).format(
                        'YYYY-MM-DD'
                      ) ? (
                      ''
                    ) : (
                      <div className='d-flex justify-content-center w-100 m-3'>
                        <div className='mbl-feedDates'>
                          <span
                            style={{
                              color: '#333333',
                              fontSize: 16,
                              fontWeight: 500
                            }}
                          >
                            {moment(parseInt(feedback.date, 10)).format(
                              'MMM DD, YYYY'
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    <Card
                      className='mbl-Feedbackcard mb-2'
                      style={{ border: 'none' }}
                    >
                      <div className='row mb-2'>
                        <div className='col-8 pr-0'>
                          <span style={{ color: '#000', fontSize: 15 }}>
                            {feedback.feedback}
                          </span>
                        </div>
                        <div
                          className='d-flex justify-content-end col-4'
                          style={{ textAlign: 'center', paddingRight: 8 }}
                        >
                          {this.mblTypeFormatter(feedback)}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-8'>
                          <span style={{ color: '#828282', fontSize: 14 }}>
                            By {feedback.lc_name}
                          </span>
                        </div>
                        <div className='d-flex justify-content-center col-4 pr-1'>
                          {feedback.feedback_voice && (
                            <div
                              className='mbl-feed-play-audio-btn pointer-cursor'
                              onClick={() => this.onAudioBtnClick(feedback)}
                            >
                              <img
                                style={{
                                  width: 14
                                }}
                                src={PlayAudio}
                                alt='play button'
                                className='mr-2'
                              />
                              <span style={{ color: '#42C0EF' }}>Play</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  </React.Fragment>
                )
              })}
            </div>
          )}
        </div>
        <Modal
          visible={this.state.feedbackModalVisible}
          title={`FEEDBACK DETAILS - ${moment(
            parseInt(this.state.feedbackData.date, 10)
          ).format('DD/MM/YYYY')}`}
          centered
          maskClosable={false}
          width='70%'
          onCancel={this.onCloseModal}
          footer={[
            <Button
              type='primary'
              size='large'
              key='back'
              onClick={this.onCloseModal}
            >
              Okay
            </Button>
          ]}
        >
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              {this.state.feedbackData.feedback_type === 'general' && (
                <React.Fragment>
                  <Tag color='blue'>General</Tag>
                </React.Fragment>
              )}
              {this.state.feedbackData.feedback_type === 'activity' && (
                <React.Fragment>
                  <Tag color='orange'>Activity</Tag> -{' '}
                  <span className='ant-modal-title' style={{ marginLeft: 8 }}>
                    {this.state.feedbackData.card_name}
                  </span>
                </React.Fragment>
              )}
              {this.state.feedbackData.feedback_type === 'library' && (
                <React.Fragment>
                  <Tag color='cyan'>Library</Tag> -{' '}
                  <span className='ant-modal-title' style={{ marginLeft: 8 }}>
                    {this.state.feedbackData.card_name}
                  </span>
                </React.Fragment>
              )}
              {this.state.feedbackData.feedback_voice && (
                <div
                  className='play-audio-btn pointer-cursor'
                  onClick={() => this.onAudioBtnClick()}
                >
                  <img src={PlayAudio} alt='play button' className='mr-2' />
                  <span style={{ color: '#3EC0F1', marginTop: 3 }}>
                    Play audio feedback
                  </span>
                </div>
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              {this.state.feedbackData.feedback}
            </div>
            {this.state.feedbackData.student_response && (
              <div className='feedback-response'>
                <Icon type='message' />
                <p>
                  {base64regex.test(this.state.feedbackData.student_response)
                    ? decodeURIComponent(
                        escape(
                          window.atob(this.state.feedbackData.student_response)
                        )
                      )
                    : this.state.feedbackData.student_response

                  // base64regex.test(this.state.feedbackData.student_response)
                  //   ? window.atob(this.state.feedbackData.student_response)
                  //   : this.state.feedbackData.student_response
                  }
                </p>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          title='Audio Feedback'
          visible={this.state.audioModal}
          maskClosable={false}
          width={600}
          onCancel={this.onCloseModal}
          footer={[
            <Button
              key='close'
              size='large'
              type='primary'
              onClick={this.onCloseModal}
            >
              Close
            </Button>
          ]}
        >
          <div>
            <div>
              <Bars is_playing={this.state.is_playing} />
            </div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='card-title-pd'>
                <span
                  style={{ color: '#0076FF', fontSize: 14, fontWeight: '800' }}
                >
                  FEEDBACK
                </span>
                <span className='dot' />
                <span style={{ color: '#4A4A4A', fontSize: 14, opacity: 0.67 }}>
                  {this.state.feedbackData.lc_name}
                </span>
              </div>
              <div className='time' style={{ fontSize: 14, color: '#999999' }}>
                {console.log(this.state.feedbackData)}
                {moment(parseInt(this.state.feedbackData.date, 10)).format(
                  'DD/MM/YYYY'
                )}
              </div>
            </div>
            {this.state.audioModal && (
              <Audio
                src={this.state.feedbackData.feedback_voice}
                className='w-100'
                controlsList='nodownload'
                onPlay={() => this.setState({ is_playing: true })}
                onPause={() => this.setState({ is_playing: false })}
                onEnded={() => this.setState({ is_playing: false })}
              ></Audio>
            )}
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default StudentFeedback
