import moment from "moment";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";
import AudioPlayer from "../../static/expert/icons/play.svg";
import { base64regex } from "../../utils";

const FeedbackStyle = styled.div`
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 6px;
  width: 100%;
  position: relative;
  min-height: 160px;
  height: 160px;
  & .floatingImage {
    position: absolute;
    /* display: none; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 45.96%,
      rgba(0, 0, 0, 0.525) 100%
    );
  }
  .title {
    font-family: "Avenir";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #ffffff;
  }
  .textContainer {
    position: absolute;
    bottom: 0;
    padding: 10px;
  }
  & img {
    width: 100%;
    object-fit: cover;
  }
`;
const FeedbackContainer = props => {
  return (
    <FeedbackStyle>
      <div className="w-100 h-100">
        {props.type === "image" && (
          <img
            src={props.image}
            alt="Student Added thier works"
            className="img-fluid h-100"
            onClick={() => {
              props.onClick();
            }}
          />
        )}
        {props.type === "audio" && (
          <div className="col-6 p-0 mx-auto pt-3">
            <img
              src={AudioPlayer}
              alt="Student Added thier works"
              className="img-fluid"
              onClick={() => {
                props.onClick();
              }}
            />
          </div>
        )}
        <div
          className="floatingImage"
          onClick={() => {
            props.onClick();
          }}
        >
          <div className="textContainer w-100">
            <div className="title FontAvenirHeavy pb-1 text-capitalize">
              {moment(props.createdDate || "").format("DD MMM YYYY")} -{" "}
              {props.feedbackType}
            </div>
            {props.notes && (
              <div className="title FontAvenirHeavy">
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  trimRight={true}
                  maxLine="1"
                  ellipsis="..."
                  basedOn="letters"
                  text={
                    base64regex.test(props.notes)
                      ? window.atob(props.notes)
                      : props.notes
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </FeedbackStyle>
  );
};

export default FeedbackContainer;
