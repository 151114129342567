import { Breadcrumb, Button, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import Form from "react-validation/build/form";
import { CHECK_ACTIVITY_NAME } from "../../graphql";
import edit from "../../static/activity/Edit.svg";
import InputC from "../InputC";
// import './nav.css';

export default class BreadcrumbView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: true,
      data: {
        cname: this.props.title,
        cname_Backup: this.props.title
      }
      // title: this.props.title
    };
  }

  checkTitle = () => {
    let query = CHECK_ACTIVITY_NAME;
    query.variables = {
      name: this.state.data.cname
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        // this.setState({act_name_check:res.data.data.checkActivityName.is_available})
        if (res.data.data.checkActivityName.is_available) {
          // alert("Name already exist")
          message.error("Name already exist");
        } else {
          this.setState({ input: true });
          this.props.editFunction(this.state.data.cname);
        }
        return;
      })
      .catch(err => console.log(err));
  };

  onChange = (key, value) => {
    const state = { ...this.state };
    state.data[key] = value;
    this.setState({ data: state.data });
    this.props.editFunction(value);
  };

  InputVisible = () => {
    this.onChange("cname", this.props.title);
    this.onChange("cname_Backup", this.props.title);
    this.setState({ input: false });
  };

  onCancelBtnClick = () => {
    this.onChange("cname", this.state.data.cname_Backup);
    this.setState({ input: true });
  };

  render() {
    return (
      <div className="pl-4 pr-4  row m-0">
        <div className="col-6">
          <Form>
            <div className="p-2 mt-2 mb-1 ">
              <Breadcrumb className="mb-3 FontAvenirBook">
                {this.props.data.split("/").map((data, index) => {
                  if (index === this.props.data.split("/").length - 1) {
                    return (
                      <Breadcrumb.Item
                        className="FontAvenirHeavy text-capitalize"
                        key={`key-${index}`}
                        separator=" "
                      >
                        {data.replace(/~~/g, "/")}
                      </Breadcrumb.Item>
                    );
                  } else if (index !== 0) {
                    if (data === "activity cards") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity?q=3`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "Published" || data === "published") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "progress") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity?q=2`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "in-progress") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity?q=2`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "activity") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "Learning Content") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/learningcontent`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else if (this.props.data.includes("Learning Content")) {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/learningcontent/${this.props.id}/${
                            this.props.draft ? `view/draft` : `view`
                          }`}
                        >
                          {data.replace(/~~/g, "/")}
                        </Breadcrumb.Item>
                      );
                    } else if (this.props.data.includes("feedbacks")) {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/feedbacks`}
                        >
                          {data.replace(/~~/g, "/")}
                        </Breadcrumb.Item>
                      );
                    } else if (data === "Workshops") {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/workshops`}
                        >
                          {data}
                        </Breadcrumb.Item>
                      );
                    } else {
                      return (
                        <Breadcrumb.Item
                          className="text-seconday text-capitalize"
                          key={`key-${index}`}
                          href={`/activity/${
                            this.props.draft ? `draft/view/` : ``
                          }${this.props.id}`}
                        >
                          {data.replace(/~~/g, "/")}
                        </Breadcrumb.Item>
                      );
                    }
                  }
                })}
              </Breadcrumb>
              {this.state.input && (
                <h4 className="text-capitalize">
                  {this.props.title || ""}
                  {this.props.edit && (
                    <img
                      onClick={this.InputVisible}
                      className="ml-3"
                      src={edit}
                      alt="edit"
                    />
                  )}
                </h4>
              )}
              {!this.state.input && (
                <div className="d-flex justify-content-start">
                  <InputC
                    className="col-12 ant-input ant-input-lg mb-4"
                    stateKey="cname"
                    placeholder="Collection Name"
                    value={this.state.data.cname}
                    onChange={this.onChange}
                    size="large"
                  />
                  <Button
                    onClick={this.onCancelBtnClick}
                    size="large"
                    ghost
                    className="ml-3 mt-3"
                    type="primary"
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center mt-2 mb-1 ">
          {this.props.children}
        </div>
      </div>
    );
  }
}
