import { Button, Icon, Modal, Popover, Upload } from "antd";
import React from "react";
import ReactImageCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import uparrow from "../../assets/uparrow.png";
import { getS3UploadUrl, uploadFiles } from "../../utils";
import "./index.css";

class ImageUploadCrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      popover_visible: false,
      edit: false,
      src: null,
      croppedImageBlob: null,
      fileList: [],
      crop: {
        aspect: this.props.aspect,
        unit: "px",
        height: this.props.aspect === 1 ? 100 : 265.5,
        width: this.props.aspect === 1 ? 100 : 472,
        x: 0,
        y: 0
      }
    };
  }

  handleChange = ({ fileList, event }) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // console.log(reader.result);
      this.setState({ src: reader.result, fileList }, () => {
        console.log(this.state.src);
      });
    });
    reader.readAsDataURL(fileList[0].originFileObj);
  };

  onUploadBtnClick = () => {
    this.setState({ modalVisible: true });
  };

  onDeleteBtnClick = () => {
    this.setState({
      src: null,
      croppedImageBlob: null,
      fileList: [],
      popover_visible: false
    });
    this.props.onSuccess("");
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      loading: false,
      edit: false,
      src: null,
      croppedImageBlob: null,
      fileList: []
    });
  };

  uploadImageToS3 = async () => {
    this.setState({ loading: true });
    const { fileList, croppedImageBlob } = this.state;
    let file = fileList[0].originFileObj;
    const signedUploadUrl = await getS3UploadUrl(
      `${file.name}`,
      `${file.type}`
    );
    const filesArray = [
      new File([croppedImageBlob], file.name, {
        type: file.type,
        lastModified: Date.now()
      })
    ];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    this.props.onSuccess(uploadedResponse);
    this.handleCancel();
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropChange = (crop, percentCrop) => {
    if (crop.height !== 0 && crop.width !== 0) {
      this.setState({ crop });
    }
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  makeClientCrop = async crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageBlob = await this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageBlob });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  render() {
    const { imageUrl, height, width } = this.props;
    const { src, crop, fileList } = this.state;
    const popoverActions = (
      <React.Fragment>
        <div className="FontAvenirRoman text-dark font-16 mb-8px">
          Are you sure you want to delete?
        </div>
        <Button type="primary" onClick={this.onDeleteBtnClick}>
          Yes
        </Button>
        <Button
          className="ml-3"
          onClick={() => this.setState({ popover_visible: false })}
        >
          No
        </Button>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        {!imageUrl && (
          <div
            className={`upload-btn ${this.props.uploadIcon
                ? " ant-upload ant-upload-select ant-upload-select-picture-card d-flex align-items-center justify-content-center"
                : ""
              }`}
            id={this.props.id}
            onClick={this.onUploadBtnClick}
            style={{ height: height || 128, width: width || 128, borderRadius: this.props.borderRadius || "0" }}
          >
            <div className="text-center">
              {!this.props.uploadIcon && <Icon type="plus" />}
              {this.props.uploadIcon && (
                <svg
                  viewBox="64 64 896 896"
                  className="svgUpload"
                  data-icon="upload"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                </svg>
              )}
              <div className="ant-upload-text">Upload</div>
            </div>
          </div>
        )}
        {imageUrl && imageUrl.length > 0 && (
          <div
            className={this.props.imageClassName || "w-100 d-flex align-items-center justify-content-center"}
            style={this.props.imageStyle}
          >
            <Popover
              content={popoverActions}
              visible={this.state.popover_visible}
            >
              <div
                className="delete-image-btn floating-btn"
                onClick={() =>
                  this.setState({
                    popover_visible: !this.state.popover_visible
                  })
                }
              >
                <Icon type="delete" />
              </div>
            </Popover>
            <img
              src={imageUrl}
              alt="interest"
              style={{
                height: height || "100%",
                width: width || "100%",
                // objectFit: "cover",
                borderRadius: this.props.borderRadius || "0"
              }}
            />
          </div>
        )}
        <Modal
          onCancel={this.handleCancel}
          title="Image upload"
          visible={this.state.modalVisible}
          maskClosable={false}
          footer={[
            <Button key="cancel" size="large" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="ok"
              size="large"
              type="primary"
              onClick={this.uploadImageToS3}
              loading={this.state.loading}
              disabled={
                !this.state.src ||
                this.state.crop.height === 0 ||
                this.state.crop.width === 0
              }
            >
              Upload
            </Button>
          ]}
        >
          <div className="w-100 d-flex align-items-center justify-content-center modal-upload">
            <React.Fragment>
              {src ? (
                <ReactImageCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              ) : (
                <Upload.Dragger
                  fileList={fileList}
                  defaultFileList={fileList}
                  onChange={this.handleChange}
                  accept=".png,.jpg,.jpeg"
                >
                  <img src={uparrow} alt="uparrow" className="uparrow" />
                  <p className="ant-upload-text">
                    {this.props.displayText
                      ? this.props.displayText
                      : `Drag & drop an image here or Browse to upload`}
                  </p>
                </Upload.Dragger>
              )}
            </React.Fragment>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ImageUploadCrop;
