import {
  Button, Checkbox, Col, Icon, Input,
  Layout, Modal, Row, Select, Tabs
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import Moment from 'moment'
import React, { Component } from 'react'
import Modall from 'react-awesome-modal'
import Highlight from 'react-highlighter'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import WorkshopCard from '../../components/Card/WorkshopCard'
import WorkshopParentCard from '../../components/Card/WorkshopParentcard'
import WorkshopFilter from '../../components/filter/workshopFilter'
import InputC from '../../components/InputC'
import { AppContext } from '../../context/AppContext'
import { GET_PARENT_DASHBOARD } from '../../graphql'
import EmptyList from '../../static/nav/emptyList.png'
import ImgPlaceholder from '../../static/nav/imageFallback.png'
import Arrow from '../../static/workshop/arrow.svg'
import ParentAttendedEmpty from '../../static/workshop/attendedEmpty.svg'
import carousalLeftArrow from '../../static/workshop/carousalLeftArrow.svg'
import carousalRightArrow from '../../static/workshop/carousalRightArrow.svg'
import ChildProfileEmpty from '../../static/workshop/child_profile_empty.svg'
import Close from '../../static/workshop/close.svg'
import FeaturedImg from '../../static/workshop/featured.svg'
import FeaturedFilterEmptyWorkshop from '../../static/workshop/Featured_empty.svg'
import Filter from '../../static/workshop/filter.svg'
import MblFeaturedImg from '../../static/workshop/mbl_featured.svg'
import ParentSearchErrorWorkshop from '../../static/workshop/noSearch.svg'
import ParentUpcomingEmpty from '../../static/workshop/ParentUpcomingEmpty.svg'
import PlaceHolder from '../../static/workshop/placeholder.svg'
import ParentRegisteredEmpty from '../../static/workshop/RegisteredEmpty.svg'
import emptyDraftWorkshop from '../../static/workshop/workshop_draft_emptystate.svg'
import emptyFeaturedWorkshop from '../../static/workshop/workshop_featured_emptystate.svg'
import emptyPastWorkshop from '../../static/workshop/workshop_past_emptystate.svg'
import emptyPendingWorkshop from '../../static/workshop/workshop_pending_emptystate.svg'
import emptyRejectedWorkshop from '../../static/workshop/workshop_rejected_emptystate.svg'
import emptyUpcomingWorkshop from '../../static/workshop/workshop_upcoming_emptystate.svg'
import {
  openNotification, triggerGAEvent
} from '../../utils'
import {
  ADD_EDIT_FEATURED_WORKSHOP, GET_ALL_USER, GET_ALL_WORKSHOPS, GET_FEATURED_WORKSHOP, GET_UPCOMING_WORKSHOPS, WORKSHOP_FILTER_QUERY
} from './graphql'
import './workshop.css'



const Img = require('react-image')
const { Content } = Layout
const TabPane = Tabs.TabPane
const Search = Input.Search

const TitleContainer = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32.78px;
  color: #000000;
  text-align: left;
  @media only screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
    color: #333;
  }
`

const TitleContainer1 = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27.32px;
  color: #000000;
  top: 10px;
  text-align: left;
`

const CarosuelContainer = styled.div`
  width: 35px;
  height: 5px;
  background: #e0e0e0;
  border-radius: 20px;
  &.active {
    background: #42c0ef;
  }
`

const TopBanner = styled.div`
  height: 400px;
  .title {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 800;
    color: #ffffff;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 42px;
      text-align: left;
      line-height: 73px;
    }
    color: #ffffff;
  }
  .coverTitle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: 99999; */
  }
  @media (max-width: 576px) {
    height: 360px;
    .workTitle {
      font-size: 14px;
    }
    .dateTime {
      font-size: 12px;
      color: #fff;
    }
    .backgrnd-resp {
      height: 165px !important;
      background-size: cover !important;
      padding: 5px;
      margin: 5px;
    }
    .title {
      font-size: 24px;
    }
    .user-profile-img {
      width: 40px;
      height: 40px;
    }
  }
  .leftSlide,
  .rightSlide {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .leftSlide {
    left: 10px;
    & img {
      width: 28px;
      @media (min-width: 992px) {
        width: 50px;
      }
    }
  }
  .rightSlide {
    right: 10px;
    @media (min-width: 1200px) {
      right: 30px;
    }
    & img {
      width: 28px;
      @media (min-width: 992px) {
        width: 50px;
      }
    }
  }
`

const CarouselImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 200px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  padding-top: 56.25%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 60.14%, rgba(0, 0, 0, 0.24) 79.9%, rgba(0, 0, 0, 0.56) 100.08%), url("${props =>
    props.image_url}");
  @media (min-width: 320px) {
    width: 100%;
    /* height: 165.01px; */
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 52.12%, rgba(0, 0, 0, 0.24) 75.85%, rgba(0, 0, 0, 0.56) 100.08%), url("${props =>
    props.image_url}");
  }
  @media(min-width:767px){
    /* height:310px; */
  }
  @media (min-width: 1200px) {
    /* height: 416px; */
  }
  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
      /* padding: 0.5rem; */
    }
  }
  .workshopContainer:hover{
    text-decoration:none;
  }
  .leftSlide,.rightSlide{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .leftSlide{
    left:10px;
    & img{
      width:28px;
      @media (min-width: 992px) {
        width:50px;
      }
    }
  }
  .rightSlide{
    right:10px;
    & img{
      width:28px;
      @media (min-width: 992px) {
        width:50px;
      }
    }
  }
`

const TabContainer = props => {
  return (
    <div>
      <div
        className='row m-0 mt-4 tabscroll'
        style={{ height: `calc(100vh - 250px)` }}
      >
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={page => {
            // alert()
            if (!props.isLoading) {
              props.loadItems(page)
            }
          }}
          className='w-100 row m-0'
          hasMore={props.hasMoreItems}
          loader={
            <React.Fragment>
              {[{}, {}, {}, {}].map((data, index) => {
                return (
                  <div
                    className='col-4 pointer-events-none'
                    key={`loading-${index}`}
                  >
                    <WorkshopCard
                      isLoading={true}
                      videoRefIcon={
                        data.default_activities_id === 2
                          ? data.video_link !== ''
                          : data.url !== ''
                      }
                      data={data}
                      stepsRefIcon={data.steps}
                      pdfRefIcon={data.pdf_url}
                      imgRefIcon={data.activity_reference_images}
                      floatingContainerLeft={true}
                      title={data.workshop_name}
                      time={data.workshop_time}
                      date={data.workshop_date}
                      workshop_type={data.workshop_type}
                      workshop_amount={data.workshop_amount}
                      difficultyVisible={data.age_diff}
                      difficulty={data.age_diff}
                    />
                  </div>
                )
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            {
              /* console.log(data); */
            }
            return (
              <div className='col-4' key={index}>
                <Link
                  to={{
                    pathname: props.isLoading
                      ? ''
                      : data.status === 'Draft'
                        ? `/workshops/${data.id}/edit/draft`
                        : data.status === 'Pending' || data.status === 'Rejected'
                          ? data.status === 'Approved'
                            ? `/workshops/${data.id}/view`
                            : `/workshops/${data.id}/view/draft`
                          : `/workshops/${data.id}/view`,
                    state: {
                      filter: props.filter,
                      filterData: props.filterData
                    }
                  }}
                >
                  <WorkshopCard
                    // videoRefIcon={
                    //   data.default_activities_id === 2
                    //     ? data.video_link !== ""
                    //     : data.url !== ""
                    // }
                    // stepsRefIcon={data.steps}
                    // pdfRefIcon={data.pdf_url}
                    data={data}
                    floatingContainer={true}
                    floatingContainerLeft={true}
                    title={data.workshop_name}
                    date={data.workshop_date}
                    time={data.workshop_time}
                    workshop_type={data.workshop_type}
                    workshop_amount={data.workshop_amount}
                    isLoading={props.loader}
                    difficulty={data.age_diff}
                    defaultActivitiesId={data.default_activities_id}
                    difficultyVisible={data.age_diff ? true : false}
                    Tag={data.interest_details}
                    src={
                      data.featured_image
                        ? data.featured_image.length
                          ? data.featured_image[0].image_url
                          : ''
                        : ''
                    }
                  />
                </Link>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const ParentTabContainer = props => {
  let scrollParentRef
  return (
    <div className='mbl-dev-workshopsList'>
      <div
        className='row m-0 mt-4 tabscroll mbl-workshopList-h'
        style={{ height: `calc(100vh - 250px)` }}
        ref={ref => (scrollParentRef = ref)}
      >
        {props.data.length == 0 &&
          props.searchText &&
          !props.isLoading &&
          props.parentErrorState && (
            <div className='col-8 mx-auto mt-5 pb-5 text-center'>
              <img
                src={ParentSearchErrorWorkshop}
                alt='error'
                className='img-fluid'
              />
              <div className='featuredEmpty'>
                No results found. Please try again with different keywords
              </div>
            </div>
          )}
        {props.data.length == 0 &&
          props.titleID == 7 &&
          !props.isLoading &&
          props.parentErrorState && (
            <div className='col-8 mx-auto mt-5 pb-5 text-center'>
              <img
                src={ParentAttendedEmpty}
                alt='error'
                className='img-fluid'
              />
              <div className='featuredEmpty'>
                You haven’t attended any workshops yet
              </div>
            </div>
          )}
        {props.data.length == 0 &&
          props.titleID == 8 &&
          !props.isLoading &&
          props.parentErrorState && (
            <div className='col-8 mx-auto mt-5 pb-5 text-center'>
              <img
                src={ParentRegisteredEmpty}
                alt='error'
                className='img-fluid'
              />
              <div className='featuredEmpty'>
                You haven’t registered for any workshops yet
              </div>
            </div>
          )}
        {props.data.length == 0 &&
          props.titleID == 1 &&
          !props.isLoading &&
          props.searchText == '' &&
          props.parentErrorState && (
            <div className='col-8 mx-auto mt-5 pb-5 text-center'>
              <img
                src={ParentUpcomingEmpty}
                alt='error'
                className='img-fluid'
              />
              <div
                className='featuredEmpty'
                style={{
                  color: '#4f4f4f'
                }}
              >
                {/* You do not have any upcoming workshops. Explore now! */}
                Workshops for your age group is in the works! Visit us again to
                see them. <br /> In the meantime, you can explore past workshops
                and let us know what interests you.
              </div>
              <div className='mt-4'>
                <Button
                  key='submit'
                  category='Workshops'
                  action='redirect to past workshop'
                  type='primary'
                  className='workshop-Upcomingbtncolor'
                  size='large'
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_LAND_URL}/workshops?q=past`, '_blank')
                  }}
                >
                  Explore past workshops
                </Button>
              </div>
            </div>
          )}
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={page => {
            // alert()
            if (!props.isLoading) {
              props.loadItems(page)
            }
          }}
          className='w-100 row m-0'
          hasMore={props.hasMoreItems}
          getScrollParent={() => scrollParentRef}
          loader={
            <React.Fragment>
              {[{}, {}, {}].map((data, index) => {
                return (
                  <div
                    className='col-12 pointer-events-none'
                    key={`loading-${index}`}
                  >
                    <WorkshopParentCard
                      index={index}
                      history={props.history}
                      isLoading={true}
                      // videoRefIcon={
                      //   data.default_activities_id === 2
                      //     ? data.video_link !== ""
                      //     : data.url !== ""
                      // }
                      titleID={props.titleID}
                      InterestedChild={
                        data.InterestedChild && data.InterestedChild.length
                          ? data.InterestedChild
                          : ''
                      }
                      RegisteredChild={
                        data.RegisteredChild && data.RegisteredChild.length
                          ? data.RegisteredChild
                          : ''
                      }
                      AttendedChild={
                        data.AttendedChild && data.AttendedChild.length
                          ? data.AttendedChild
                          : ''
                      }
                      // stepsRefIcon={data.steps}
                      // pdfRefIcon={data.pdf_url}
                      // imgRefIcon={data.activity_reference_images}
                      floatingContainerLeft={true}
                      title={data.workshop_name}
                      time={data.workshop_time}
                      date={data.workshop_date}
                      workshop_type={data.workshop_type}
                      workshop_amount={data.workshop_amount}
                      difficultyVisible={data.age_diff}
                      difficulty={data.age_diff}
                      parentData={data}
                    />
                  </div>
                )
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            return (
              <div className='col-12 mbl-padding-0' key={index}>
                {/* <Link
                  to={{
                    pathname: props.isLoading
                      ? ""
                      : data.status === "Draft"
                        ? `/workshops/${data.id}/edit/draft`
                        : data.status === "Pending" || data.status === "Rejected"
                        ? data.status === "Approved"
                          ? `/workshops/${data.id}/view`
                          : `/workshops/${data.id}/view/draft`
                        : `/workshops/${data.id}/view`,
                    state: {
                      filter: props.filter,
                      filterData: props.filterData,
                    },
                  }}
                > */}
                <WorkshopParentCard
                  index={index}
                  parentData={data}
                  history={props.history}
                  id={data.id}
                  imgRefIcon={data.activity_reference_images}
                  InterestedChild={
                    data.InterestedChild && data.InterestedChild.length
                      ? data.InterestedChild
                      : ''
                  }
                  RegisteredChild={
                    data.RegisteredChild && data.RegisteredChild.length
                      ? data.RegisteredChild
                      : ''
                  }
                  AttendedChild={
                    data.AttendedChild && data.AttendedChild.length
                      ? data.AttendedChild
                      : ''
                  }
                  titleID={props.titleID}
                  stepsRefIcon={data.steps}
                  pdfRefIcon={data.pdf_url}
                  floatingContainer={true}
                  floatingContainerLeft={true}
                  title={data.workshop_name}
                  date={data.workshop_date}
                  time={data.workshop_time}
                  workshop_duration={data.workshop_duration}
                  workshop_type={data.workshop_type}
                  workshop_amount={data.workshop_amount}
                  isLoading={props.loader}
                  difficulty={data.age_diff}
                  defaultActivitiesId={data.default_activities_id}
                  difficultyVisible={data.age_diff ? true : false}
                  Tag={data.interest_details}
                  user_details={data.user_details}
                  src={
                    data.featured_image
                      ? data.featured_image.length
                        ? data.featured_image[0].image_url
                        : ''
                      : ''
                  }
                />
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`

const FeaturedWorkshopContainerStyle = styled.div`
  .playlistTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */

    color: #000000;
  }
  .playlistTitle .skeleton {
    width: 200px;
    height: 10px;
    border-radius: 50px;
  }
  .playlistInterest .skeleton {
    width: 100px;
    height: 10px;
    border-radius: 50px;
  }
  .playlistActive .skeleton {
    width: 30px;
    height: 10px;
    border-radius: 50px;
  }
  .skeleton {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;

    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
  .featuredTextHighlight {
    padding: 0;
    background-color: #1890ff;
    color: #fff;
  }
`
const TagContainer = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-family: 'Avenir';

  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 350px) {
    .mr-min {
      margin-right: 0.5rem !important;
    }
  }
  &.active {
    div {
      color: #0076ff;
      border: 1px solid #0076ff;
    }
  }
  div {
    display: inline-block;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 12px;
    border: 1px solid #bdbdbd;
    @media (min-width: 992px) {
      border: 1px solid transparent;
    }
  }
`
const ModelContainer = styled.div`
  border: 0.5px solid #bdbdbd;
  .active {
    background: #f2f2f2;
    color: #57479c;
  }
  .mh-80 {
    max-height: 80vh;
  }
`

const WorkshopContainer = styled.div`
  cursor: pointer;
  &.sticky {
    position: sticky;
    top: 120px;
    height: calc(100vh - 120px);
    overflow: scroll;
  }
  &.workshopTitleSticky {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    z-index: 1;
    background: #fff;
    position: sticky;
    top: 122px;
  }
  .socialIconMobile img {
    width: 25px;
  }
  &.fixedLogin {
    position: fixed;
    bottom: 0;
    z-index: 10;
    left: 0;
    right: 0;
  }

  &.showBg {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    &.large {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
  }

  .radio {
    /* margin: 0.5rem; */
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: #fff;
          border-radius: 100%;
          border: 1px solid #bdbdbd;
          display: inline-block;
          width: 1.2rem;
          height: 1.2rem;
          position: relative;
          /* top: -0.2em; */
          margin-right: 8px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
          position: absolute;
          right: 0;
          @media (min-width: 768px) {
            position: initial;
          }
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: #57479c;
            box-shadow: inset 0 0 0 4px #fff;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: #57479c;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #fff;
            border-color: darken(#fff, 25%);
            background: darken(#fff, 25%);
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  .cbox input {
    display: none;
  }

  .cbox input:checked ~ label::before {
    content: '\\2713';
    color: #fff;
    background: #57479c;
  }

  .cbox label::before {
    display: inline-block;
    content: ''; /* Blank space */
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    text-align: center;
    background: #fff;
    border: 1px solid #d9d9d9;

    position: absolute;
    right: 0;
    @media (min-width: 768px) {
      position: initial;
    }
  }

  .cbox {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
  }
  .cbox label {
    cursor: pointer;
    display: flex;
  }
  /* .cbox label:hover::before { background: #ddd; } */

  .cbox input:disabled ~ label {
    color: #aaa;
  }
  .cbox input:disabled ~ label::before {
    background: #ccc;
  }

  .button-cta {
    background: #57479c;
    border-radius: 5px;
    border-color: #57479c;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }

  @media (min-width: 767px) {
    padding: 13px;
  }

  .min-w-300 {
    min-width: 150px;
    @media (min-width: 350px) {
      min-width: 200px;
    }
    @media (min-width: 1100px) {
      min-width: 300px;
    }
  }
  .w-300 {
    width: 150px;
    @media (min-width: 350px) {
      width: 200px;
    }
    @media (min-width: 1100px) {
      width: 300px;
    }
  }
  .workshopCard {
    @media (min-width: 768px) {
      min-width: 308px;
    }
    @media (min-width: 1100px) {
      min-width: 340px;
    }
  }
  .title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    /* Gray 3 */

    color: #333333;
    text-transform: uppercase;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
    }
  }
  .largeHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
  .subHeading {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    &.color-blue {
      color: #42c0ef;
    }
  }
  .subTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .desc {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    &.font-light {
      font-family: 'Nunito';
      font-weight: 400;
    }
  }
  .subDesc {
    a {
      font-family: Avenir;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      /* identical to box height */

      /* Brand color 1 */

      color: #333;
      &:hover {
        color: #42c0ef;
      }
      &.color-purple {
        color: #57479c;
      }
    }
  }
  .price {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 18px;
    }
    /* identical to box height */

    /* Brand color 1 */

    color: #42c0ef;
  }
  .red-price {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;

    font-size: 12px;
    line-height: 13px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    }
    color: #ea1f59;
  }
  .img-card {
    width: 340px;
    height: 180px;
  }
`

const FeaturedWorkshopContainer = props => {
  return (
    <div className='p-2 pr-2 mt-4 rounded mb-4 border d-flex justify-content-between align-items-center'>
      <FeaturedWorkshopContainerStyle className='FontAvenirMedium font-16 text-dark w-100'>
        <div className='row m-0 justify-content-between d-flex'>
          <div className='PlaylistImgCard'>
            {!props.isLoading && (
              <Img
                className='w-100 PlaylistImgCard h-100'
                src={props.data.featured_image[0].image_url}
                loader={
                  <div className='PlaylistImgCard w-100 h-100 skeleton' />
                }
                unloader={
                  <img
                    className='w-100 PlaylistImgCard h-100'
                    src={ImgPlaceholder}
                    alt='Workshops'
                  />
                }
              />
            )}
            {props.isLoading && (
              <div className='PlaylistImgCard w-100 h-100 skeleton' />
            )}
          </div>
          {!props.isLoading && (
            <React.Fragment>
              <div className='col-4 playlistTitle d-flex align-items-center'>
                <div>
                  <Highlight
                    matchClass='featuredTextHighlight'
                    search={props.search}
                  >
                    {props.data.workshop_name}
                  </Highlight>
                </div>
              </div>
              <div className='d-flex align-items-center mr-3'>
                <Checkbox
                  value={props.data.isActive}
                  checked={props.data.isActive}
                  onChange={() => {
                    props.onChangeCheckbox(!props.data.isActive)
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {props.isLoading && (
            <React.Fragment>
              <div className='col-4 playlistTitle d-flex align-items-center'>
                <div className='skeleton'></div>
              </div>
              <div className='col-3 d-flex playlistInterest align-items-center'>
                <div className='skeleton'></div>
              </div>
              <div className='d-flex playlistActive align-items-center'>
                <div className='skeleton'></div>
              </div>
            </React.Fragment>
          )}
        </div>
      </FeaturedWorkshopContainerStyle>
    </div>
  )
}

class Workshop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role_id: 1,
      title: 'Upcoming workshops',
      titleID: 1,
      filter: {},
      only_draft: false,
      get_pending_only: false,
      past_workshop: false,
      attended_workshop: false,
      registered_workshop: false,
      upcoming_workshop: true,
      rejected_workshop: false,
      total_count: 0,
      is_collection_loading: false,
      isActivity_loading: false,
      isWorkshopContentLoading: false,
      isfeaturedWorkshopContentLoading: false,
      modalVisible: false,
      getAllActivity: [],
      featuredWorkshopId: null,
      featuredTitle: '',
      getfeaturedTitle: '',
      featuredSearchText: '',
      searchText: '',
      selectedWorkshopsIds: [],
      allUpcomingWorkshops: [],
      allFeaturedWorkshop: [],
      adminApprovedWorkshop: [],
      parentWorkshopdata: [],
      selectedSlide: 0,
      carosuel: [{}, {}, {}],
      is_parentWorkshop_loading: false,
      adminPendingActivities: [],
      PublishedCollection: [],
      InprogressCollection: [],
      studentsFilter: [],
      selected_date: '',
      parentErrorState: false,
      price_type: 'all',
      content_creators: [],
      activeTab: '1',
      selectedcc: null,
      user_id: null,
      slider: true,
      sort: true,
      parentPageLoad: true,
      page: 1,
      tab1Page: 1,
      tab2Page: 1,
      parentPage: 1,
      tab1Count: 0,
      tab2Count: 0,
      tab1More: true,
      tab2More: true,
      ShowMobileFilter: false,
      selectedFilter: 'student',
      mblPriceKey: 'all',
      RadioState: [],
      modalInterests: [],
      modalStudents: [],
      showAll: false,
      switchContentTab: true,
      filterData: {
        interest_details: [],
        age_groups: [],
        studentsFilter: []
      },
      type: '',
      interests: [],
      truthObj: {
        featuredTitle: false
      }
    }
  }

  fetchAllData = () => {
    if ([1, 2, 4, 5].includes(parseInt(this.state.role_id))) {
      this.getAllWorkshops()
    } else {
      this.getParentAllWorkshops()
      this.getParentAllFeaturedWorkshops()
    }
  }

  clearFilter = () => {
    this.setState({ slider: false })
    setTimeout(() => {
      this.setState({ slider: true })
    }, 1)
    const filterData = { ...this.state.filterData }
    filterData.age_groups = filterData.age_groups.map(data => {
      data.is_active = false
      return data
    })
    filterData.interest_details = filterData.interest_details.map(data => {
      data.is_active = false
      return data
    })
    this.setState(
      {
        filterData: filterData,
        filter: {},
        fetchData: true,
        adminApprovedWorkshop: [],
        adminPendingActivities: [],
        getAllActivity: [],
        PublishedCollection: [],
        InprogressCollection: [],
        ArchivedCollection: [],
        upcoming_workshop: true,
        attended_workshop: false,
        registered_workshop: false,
        title: 'Upcoming workshops',
        price_type: 'all',
        studentsFilter: this.state.studentsFilter.map(x => {
          x.is_active = false
          return x
        }),
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        parentPage: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true
      },
      () => this.fetchAllData()
    )
  }

  fetchFilter = () => {
    const query = WORKSHOP_FILTER_QUERY
    if (
      this.state.only_draft ||
      this.state.get_pending_only ||
      this.state.rejected_workshop
    ) {
      query.variables = {
        isPending: true
      }
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const resp = res.data.data.getWorkshopFilterData
        let filterData = {
          age_groups: resp.difficulty_details.age_groups,
          interest_details: resp.interest_details,
          studentsFilter: []
        }
        let modalInterests = resp.interest_details
        let mbl_age_groups = resp.difficulty_details.age_groups
        mbl_age_groups.splice(0, 0, { age: 'All', is_active: true, id: '0' })
        this.setState({
          filterData: filterData,
          Mbl_age_groups: mbl_age_groups,
          modalInterests
        })
      })
      .catch(err => console.log(err))
  }

  onChange = (key, index, value) => {
    const state = { ...this.state }
    if (key === 'age_groups') {
      state.filterData[key][index].is_active = value
      state.filter['age'] = state.filterData[key]
        .filter(data => data.is_active && parseInt(data.id, 10) != 0)
        .map(data => parseInt(data.id, 10))
    }

    if (key === 'interest_details') {
      state.filterData[key][index].is_active = value
      state.filter['interest'] = state.filterData[key]
        .filter(data => data.is_active)
        .map(data => parseInt(data.id, 10))
    }

    if (key == 'studentsFilter') {
      state[key][index].is_active = value
      switch (state[key][index].type) {
        case 'parent':
          state.filter['parent'] = state[key]
            .filter(data => data.type == 'parent')
            .filter(data => data.is_active)
            .map(data => parseInt(data.id, 10))
          break
        case 'adult':
          state.filter['adult'] = state[key]
            .filter(data => data.type == 'adult')
            .filter(data => data.is_active)
            .map(data => parseInt(data.id, 10))
          break
        default:
          state.filter['child'] = state[key]
            .filter(data => data.type == 'child')
            .filter(data => data.is_active)
            .map(data => parseInt(data.id, 10))
          break
      }
    }

    if (key == 'priceFilter') {
      this.setState({
        price_type: index
      })
      state.filter['workshop_type'] = index != 'all' ? index : ''
    }

    this.setState(
      {
        filterData: state.filterData,
        fetchData: true,
        filter: state.filter,
        studentsFilter: state.studentsFilter
      },
      () => {
        this.fetchAllData()
        this.getAllFeaturedWorkshops()
      }
    )
  }

  onChangeMblStudentFilter = (key, index, value) => {
    const state = { ...this.state }
    if (key == 'modalStudents') {
      state[key][index].is_active = value.target.checked
      this.setState({
        modalStudents: state[key]
      })
    }
  }

  applyAll = () => {
    const state = { ...this.state }
    state.filter['child'] = state['modalStudents']
      .filter(data => data.is_active)
      .map(data => parseInt(data.id, 10))
    state.filter['workshop_type'] =
      state.mblPriceKey != 'all' ? state.mblPriceKey : ''
    state.filter['interest'] = state['modalInterests']
      .filter(data => data.isActive)
      .map(data => parseInt(data.id, 10))

    this.setState(
      {
        filterData: state.filterData,
        fetchData: true,
        filter: state.filter
      },
      () => {
        this.fetchAllData()
        this.getAllFeaturedWorkshops()
      }
    )
  }

  clearAll = () => {
    let { RadioState, modalInterests, modalStudents } = this.state

    let RadioStateTemp = RadioState.map(x => {
      x.isActive = false
      return x
    })
    let modalInterestsTemp = modalInterests.map(x => {
      x.isActive = false
      return x
    })
    let modalStudentsTemp = modalStudents.map(x => {
      x.is_active = false
      return x
    })

    this.setState(
      {
        filter: {},
        RadioState: RadioStateTemp,
        modalInterests: modalInterestsTemp,
        modalStudents: modalStudentsTemp
      },
      () => {
        this.fetchAllData()
        this.getAllFeaturedWorkshops()
      }
    )
  }

  onSelectChange = e => {
    const { filter } = this.state
    filter.user_id = e
    this.setState(
      {
        filter,
        selectedcc: e
      },
      () => this.getAllWorkshops()
    )
  }

  onChangeCheckbox = (index, val) => {
    const mdata = [...this.state.allUpcomingWorkshops]
    mdata[index].isActive = val
    let workshopId = mdata[index].id
    const selectedWorkshopsData = mdata.filter(data => data.isActive)
    let selectedWorkshopsIds = selectedWorkshopsData.map(x => ({ id: x.id }))
    if (selectedWorkshopsIds.length > 5) {
      openNotification('error', `You can select only 5 workshops maximum`)
      return
    }
    if (val) {
      let work_arr = this.state.selectedWorkshopsIds
      work_arr.push({ id: workshopId })
      selectedWorkshopsIds = work_arr
    } else {
      let rmworkshopIds = this.state.selectedWorkshopsIds
      selectedWorkshopsIds = rmworkshopIds.filter(o => o.id !== workshopId)
    }
    this.setState({ allUpcomingWorkshops: mdata, selectedWorkshopsIds })
  }

  onChangeFilterShowRadio = val => {
    this.setState(
      {
        title: val.target.value,
        titleID: val.target.id,
        only_draft: val.target.id === 4,
        get_pending_only: val.target.id === 3,
        past_workshop: val.target.id === 2,
        upcoming_workshop: val.target.id === 1,
        rejected_workshop: val.target.id === 5,
        featured_workshop: val.target.id === 6,
        attended_workshop: val.target.id === 7,
        registered_workshop: val.target.id === 8,
        activeTab: val.target.id === 4 ? '1' : '0'
      },
      () => {
        this.setState(
          {
            tab2Page: 1,
            parentPage: 1,
            tab2More: true
          },
          () => {
            this.getAllWorkshops()
            this.getAllFeaturedWorkshops()
            this.fetchFilter()
          }
        )
      }
    )
  }

  onSearchChange = e => {
    triggerGAEvent(
      'Workshops',
      'Searching Upcoming workshops for featured workshops'
    )
    this.setState({ featuredSearchText: e.target.value, loading: true }, () =>
      this.fetchAllUpcomingWorkshops()
    )
  }

  handleCancel = () => {
    triggerGAEvent('Workshops', 'Closing Add Featured Workshops')
    this.setState({
      modalVisible: false,
      selectedWorkshopsIds: [],
      featuredTitle: '',
      featuredSearchText: '',
      editFeaturedWorkshopkey: false
    })
  }

  getAllUser = () => {
    const query = GET_ALL_USER
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({ is_error: true })
        } else {
          const contentCreators = res.data.data.getAllUser.users_result
          this.setState({
            content_creators: contentCreators
          })
        }
      })
      .catch(err => console.log(err))
  }

  addEditFeaturedWorkshop = () => {
    const query = ADD_EDIT_FEATURED_WORKSHOP

    if (this.state.selectedWorkshopsIds.length < 1) {
      openNotification(
        'error',
        'Please select atleast one upcoming workshop to continue'
      )
      return
    }

    query.variables = {
      workshop_title: this.state.featuredTitle,
      workshop_id: this.state.selectedWorkshopsIds
    }

    if (this.state.allFeaturedWorkshop.length) {
      query.variables.featured_workshop_id = this.state.featuredWorkshopId
    }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({ is_error: true })
        } else {
          const addresp = res.data.data.addFeaturedWorkshop.featured_workshops

          if (addresp.length) {
            if (this.state.editFeaturedWorkshopkey) {
              openNotification(
                'success',
                'Featured Workshops updated successfully'
              )
            } else {
              openNotification(
                'success',
                'Featured Workshops created successfully'
              )
            }
            this.setState({
              modalVisible: false,
              featuredTitle: '',
              selectedWorkshopsIds: []
            })
          }
          this.getAllFeaturedWorkshops()
        }
      })
      .catch(err => console.log(err))
  }

  fetchAllUpcomingWorkshops = async () => {
    const query = GET_UPCOMING_WORKSHOPS
    query.variables = {
      search: this.state.featuredSearchText
    }

    const selectedCardIds = [...this.state.selectedWorkshopsIds].map(
      data => data.id
    )

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          allUpcomingWorkshops: [],
          isWorkshopContentLoading: true
        })

        let workshop_details = res.data.data.upcomingWorkshop.workshop_details
          .map(data => {
            data.isActive = selectedCardIds.includes(data.id) ? true : false
            return data
          })
          .filter(data => data)
        workshop_details = _.orderBy(workshop_details, ['isActive'], ['desc'])
        this.setState(
          {
            allUpcomingWorkshops: workshop_details
          },
          () => {
            setTimeout(() => {
              this.setState({
                isWorkshopContentLoading: false
              })
            }, 1000)
          }
        )
      })
      .catch(err => console.log(err))
  }

  onInputChange = (key, value) => {
    triggerGAEvent('Workshops', 'Adding Featured Workshops Title')
    this.setState({
      [key]: value
    })
  }

  showModal = () => {
    triggerGAEvent('Workshops', 'Fetching Featured Workshops Content')
    this.fetchAllUpcomingWorkshops()
    this.setState({
      modalVisible: true,
      editFeaturedWorkshopkey: this.state.allFeaturedWorkshop.length > 0
    })
  }

  fetchAllWorkshops = () => {
    const query = GET_ALL_WORKSHOPS
    query.variables = {
      filter: this.state.filter,
      only_draft: this.state.only_draft,
      pending_only: this.state.get_pending_only,
      upcoming_workshop: this.state.upcoming_workshop,
      page: this.state.tab2Page,
      rejected_workshop: this.state.rejected_workshop,
      past_workshop: this.state.past_workshop,
      sort: this.state.sort ? 'desc' : 'asc'
      // user_id: this.state.user_id
    }

    // if (this.state.searchText !== "") {
    //   activities.variables.search = this.state.searchText;
    // }
    // const selectedCardIds = [...this.state.selectedCardIds].map(
    //   (data) => data.id
    // );
    // console.log(query.variables);

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({ tab2More: false })
        } else {
          if (
            res.data.data.getWorkshopList.workshop_details.length === 0 ||
            res.data.data.getWorkshopList.workshop_details.length < 20
          ) {
            this.setState({ tab2More: false })
          } else {
            this.setState({
              isActivity_loading: true
            })
          }
          const workshop_details = [
            ...res.data.data.getWorkshopList.workshop_details
          ]
          const workshop = workshop_details.map(data => {
            let difficulty = { Easy: [], Medium: [], Hard: [] }
              ; (data.age_groups || []).map(ageGrp => {
                if (ageGrp) {
                  if (ageGrp.age_group)
                    difficulty[ageGrp.age_group_dificulty.dificulty].push(
                      ageGrp.age_group.age
                    )
                }
              })
            data.age_diff = difficulty
            return data
          })
          console.log('workshop', workshop)
          this.setState(
            {
              adminApprovedWorkshop:
                this.state.tab2Page !== 1
                  ? [...this.state.adminApprovedWorkshop, ...workshop]
                  : [...workshop],
              // adminApprovedWorkshop: [...workshop],
              tab1Count: res.data.data.getWorkshopList.total_count,
              // page: this.state.page + 1,
              //pagination_loading: false,
              //loading: false,
              fetchData: false
            },
            () => {
              setTimeout(() => {
                this.setState({
                  isActivity_loading: false
                })
              }, 100)
            }
          )
        }
      })
      .catch(err => console.log(err))
  }

  getAllFeaturedWorkshops = () => {
    if (!this.state.featured_workshop) {
      return
    }
    this.setState({
      isfeaturedWorkshopContentLoading: true
    })
    const query = GET_FEATURED_WORKSHOP
    query.variables = {
      filter: this.state.filter
    }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        console.log('featured workshops ', res.data.data.getFeaturedWorkshop)
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            allFeaturedWorkshop: [],
            isActivity_loading: false,
            isfeaturedWorkshopContentLoading: false
          })
        } else if (
          res.data.data.getFeaturedWorkshop.workshop_details &&
          res.data.data.getFeaturedWorkshop.workshop_title
        ) {
          const workshop_details = [
            ...res.data.data.getFeaturedWorkshop.workshop_details
          ]

          this.setState(
            {
              featuredWorkshopId: res.data.data.getFeaturedWorkshop.id,
              featuredTitle: res.data.data.getFeaturedWorkshop.workshop_title,
              getfeaturedTitle:
                res.data.data.getFeaturedWorkshop.workshop_title,
              selectedWorkshopsIds: workshop_details.map(x => ({ id: x.id })),
              allFeaturedWorkshop: workshop_details,
              editFeaturedWorkshopkey: false
            },
            () => {
              setTimeout(() => {
                this.setState({
                  isActivity_loading: false,
                  isfeaturedWorkshopContentLoading: true
                })
              }, 100)
            }
          )
        } else {
          this.setState({
            allFeaturedWorkshop: [],
            isActivity_loading: true,
            isfeaturedWorkshopContentLoading: false
          })
        }
      })
      .catch(err => console.log(err))
  }

  getParentAllFeaturedWorkshops = () => {
    this.setState({
      isfeaturedWorkshopContentLoading: true
    })
    const query = GET_FEATURED_WORKSHOP
    query.variables = {
      filter: {}
    }

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            allFeaturedWorkshop: []
          })
        } else if (
          res.data.data.getFeaturedWorkshop.workshop_details &&
          res.data.data.getFeaturedWorkshop.workshop_details.length > 0
        ) {
          const workshop_details = [
            ...res.data.data.getFeaturedWorkshop.workshop_details
          ]

          this.setState({
            featuredWorkshopId: res.data.data.getFeaturedWorkshop.id,
            featuredTitle: res.data.data.getFeaturedWorkshop.workshop_title,
            allFeaturedWorkshop: workshop_details
          })
        }
      })
      .catch(err => console.log(err))
  }

  getParentAllWorkshops = () => {
    const query = GET_ALL_WORKSHOPS
    query.variables = {
      upcoming_workshop: this.state.upcoming_workshop,
      registered_workshop: this.state.registered_workshop,
      attended_workshop: this.state.attended_workshop,
      page: this.state.parentPage,
      filter: this.state.filter,
      search: this.state.searchText
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({ tab2More: false, parentErrorState: true })
        } else {
          // console.log(res.data.data.getWorkshopList)
          if (
            !res.data.data.getWorkshopList.workshop_details ||
            (res.data.data.getWorkshopList.workshop_details &&
              res.data.data.getWorkshopList.workshop_details.length == 0) ||
            res.data.data.getWorkshopList.workshop_details.length < 20
          ) {
            this.setState({
              tab2More: false,
              is_parentWorkshop_loading: false,
              parentErrorState: true
            })
          } else {
            this.setState({
              is_parentWorkshop_loading: true
            })
          }
          const workshop_details = [
            ...res.data.data.getWorkshopList.workshop_details
          ]
          // console.log('parent workshop', workshop_details)
          this.setState(
            {
              parentWorkshopdata:
                this.state.parentPage !== 1
                  ? [...this.state.parentWorkshopdata, ...workshop_details]
                  : [...workshop_details],
              tab1Count: res.data.data.getWorkshopList.total_count,
              fetchData: false
            },
            () => {
              setTimeout(() => {
                this.setState({
                  is_parentWorkshop_loading: false
                })
              }, 100)
            }
          )
        }
      })
      .catch(err => console.log(err))
  }

  onTabChange = key => {
    // console.log(`${key}`, key === "3", key === 3);
    // triggerGAEvent('Workshops', 'Changing Tabs in Activities');

    if (this.state.role_id === 1) {
      this.setState(
        {
          activeTab: key,
          fetchData: true
          // adminApprovedWorkshop: [],
          // adminPendingActivities: [],
          // page: 1,
          // total_count: 0
        },
        () => {
          // alert();
          // `${key}` === "1" ? this.fetchApprovedActivities() : this.fetchPendingActivities()
        }
      )
    }

    this.setState(
      { activeTab: key, only_draft: key == '1', rejected_workshop: key == '2' },
      () => {
        this.fetchAllWorkshops()
        this.props.history.replace(`/workshops?q=${this.state.activeTab}`)
      }
    )
  }

  getAllWorkshops = () => {
    // this.fetchAllWorkshops();
    this.setState(
      {
        parentPage: 1,
        tab2Page: 1,
        isActivity_loading: true,
        is_parentWorkshop_loading: true,
        adminApprovedWorkshop: [],
        parentWorkshopdata: []
      },
      () => {
        setTimeout(() => {
          if ([1, 2, 4, 5].includes(this.state.role_id)) {
            this.fetchAllWorkshops()
          } else {
            this.getParentAllWorkshops()
          }
        }, 1)
      }
    )
    // if (this.state.activeTab === "2") {
    // this.fetchOldPendingActivities();
    // } else {

    // }
  }

  loadTab1 = page => {
    if (this.state.tab1More) {
      this.setState(
        {
          tab1Page: this.state.tab1Page + 1,
          is_collection_loading: true
        },
        () => {
          if ([1, 2, 4, 5].includes(parseInt(this.state.role_id))) {
            this.fetchAllWorkshops()
          } else {
            this.getParentAllWorkshops()
          }
        }
      )
    }
  }

  loadTab2 = page => {
    if (this.state.tab2More) {
      this.setState(
        {
          tab2Page: this.state.tab2Page + 1,
          parentPage: this.state.parentPage + 1,
          isActivity_loading: true,
          is_parentWorkshop_loading: true
        },
        () => {
          if ([1, 2, 4, 5].includes(parseInt(this.state.role_id))) {
            this.fetchAllWorkshops()
          } else {
            this.getParentAllWorkshops()
          }
        }
      )
    }
  }

  onSearch = e => {
    this.setState(
      {
        tab2More: true,
        parentPage: 1,
        searchText: e.target.value,
        is_parentWorkshop_loading: true,
        parentWorkshopdata: []
      },
      () => {
        setTimeout(() => {
          this.getParentAllWorkshops()
        }, 500)
      }
    )
  }

  fetchAllStudentsFilterData = () => {
    if (this.state.role_id != 3) {
      return
    }
    let { filterData } = this.state
    let query = GET_PARENT_DASHBOARD
    query.variables = {
      date: this.state.selected_date
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let studentsFilter = res.data.data.ParentDashbordV2.students
          ? res.data.data.ParentDashbordV2.students.map(data => {
            data.is_active = false
            data.type = 'child'
            return data
          })
          : []
        let parentDetails = res.data.data.ParentDashbordV2.user_details
          ? res.data.data.ParentDashbordV2.user_details
          : {}
        this.props.setUserInfo({ parentDetails, students: res.data.data.ParentDashbordV2.students })
        parentDetails.type = Object.keys(parentDetails).length
          ? studentsFilter.length
            ? 'parent'
            : 'adult'
          : null
        parentDetails.id = parentDetails.id ? parseInt(parentDetails.id) : null
        parentDetails.is_active = false
        studentsFilter.push(parentDetails)
        filterData['studentsFilter'] = studentsFilter
        let modalStudents = studentsFilter
        this.setState({
          studentsFilter: studentsFilter,
          modalStudents
        })
      })
      .catch(err => console.log(err))
  }

  componentDidMount() {
    const priceList = [
      {
        title: 'All',
        key: 'all',
        isActive: true
      },
      {
        title: 'Free',
        key: 'free',
        isActive: false
      },
      {
        title: 'Paid',
        key: 'paid',
        isActive: false
      }
    ]
    this.setState(
      {
        role_id: Number(localStorage.getItem('role_id'))
      },
      () => {
        this.setState({
          activeTab:
            this.state.role_id === 1
              ? '1'
              : this.props.history.location.search.split('=')[1]
                ? this.props.history.location.search.split('=')[1]
                : '3',
          RadioState: priceList
        })
        this.fetchFilter()
        this.fetchAllData()
        this.getAllUser()
        this.fetchAllStudentsFilterData()
      }
    )
  }

  componentWillMount() {
    setInterval(() => {
      if ((this.state.allFeaturedWorkshop || []).length) {
        let len = (this.state.allFeaturedWorkshop || []).length
        let cont =
          this.state.selectedSlide == len - 1 ? 0 : this.state.selectedSlide + 1
        this.setState({
          selectedSlide: cont
        })
      }
    }, 3000)
  }

  render() {
    const RadioButtons = ({ title, data, onChange }) => {
      return (
        <div className=''>
          {/* <div className='subTitle text-left mb-2 mt-3 pb-1'>{title}</div> */}
          {(data || []).map((dates, dateIndex) => {
            return (
              <div
                className='desc font-light radio d-flex align-items-center mb-2 pb-1'
                key={`${title}-${dateIndex}`}
              >
                <input
                  type='radio'
                  className=''
                  id={`${title}-${dateIndex}`}
                  name={`${title}Radio`}
                  value={dates.title}
                  checked={dates.isActive}
                  onChange={e => {
                    let radioButtonData = [...data]
                    radioButtonData = radioButtonData.map(data => {
                      data.isActive = false
                      if (data.key === dates.key) {
                        data.isActive = true
                      }
                      return data
                    })
                    this.setState({
                      RadioState: radioButtonData
                    })
                    if (onChange) onChange(dates.key, data)
                  }}
                // checked={dates.isActive}
                />
                <label
                  htmlFor={`${title}-${dateIndex}`}
                  className='ml-2 radio-label mb-0 cursor-pointer'
                >
                  {dates.title}
                </label>
              </div>
            )
          })}
          <div />
        </div>
      )
    }

    const CheckboxButton = ({ title, data, limit, onChange }) => {
      return (
        <div className=''>
          {/* <div className='subTitle text-left mb-2 mt-3 pb-1'>{title}</div> */}
          {(data || []).map((dates, dateIndex) => {
            if (this.state.showAll || dateIndex < limit) {
              return (
                <div
                  className='desc font-light cbox d-flex align-items-center mb-2 pb-1'
                  key={`Date-${dateIndex}`}
                >
                  <input
                    type='checkbox'
                    className=''
                    id={`${title}-${dateIndex}`}
                    name={`${title}Radio`}
                    value={dates.id}
                    checked={dates.isActive}
                    onClick={e => {
                      let radioButtonData = [...data]
                      radioButtonData = radioButtonData.map(data => {
                        if (data.id === dates.id) {
                          data.isActive = !data.isActive
                        }
                        return data
                      })
                      if (onChange) onChange(dates.id, data)
                    }}
                  // checked={dates.isActive}
                  />
                  <label
                    for={`${title}-${dateIndex}`}
                    className='radio-label mb-0 cursor-pointer'
                  >
                    {dates.name}
                  </label>
                </div>
              )
            }
          })}
          {limit && (
            <div
              className='subHeading color-blue cursor-pointer'
              onClick={() => {
                this.setState({
                  showAll: !this.state.showAll
                })
              }}
            >
              {!this.state.showAll
                ? `+ ${(data || []).length - limit} more`
                : `Show less`}
            </div>
          )}
        </div>
      )
    }

    const ModalComponent = ({
      ShowMobileFilter,
      selectedFilter,
      interests,
      priceList,
      studentList
    }) => {
      let modalInterests = interests
      let modalPriceList = priceList
      return (
        <Modall
          visible={ShowMobileFilter}
          width='100%'
          effect='fadeInUp'
          onClickAway={() => this.setState({ ShowMobileFilter: false })}
        >
          <WorkshopContainer className='p-0'>
            <div className='d-flex justify-content-between w-100'>
              <div className='title p-3'>FILTER</div>
              <img
                src={Close}
                className='p-3'
                alt='close'
                onClick={() => this.setState({ ShowMobileFilter: false })}
              />
            </div>
            <ModelContainer className='row m-0'>
              <div className='col-5 p-0 subDesc border-right'>
                <div
                  className={`pl-3 pt-2 pb-2 d-flex justify-content-between ${selectedFilter === 'student' ? 'active' : ''
                    }`}
                  onClick={() => {
                    this.setState({
                      selectedFilter: 'student'
                    })
                  }}
                >
                  Interest shown by{' '}
                  {selectedFilter === 'student' && (
                    <img src={Arrow} alt='arrow' className='mr-2' />
                  )}
                </div>

                <div
                  className={`pl-3 pt-2 pb-2 d-flex justify-content-between ${selectedFilter === 'price' ? 'active' : ''
                    }`}
                  onClick={() => {
                    this.setState({
                      selectedFilter: 'price'
                    })
                  }}
                >
                  Price{' '}
                  {selectedFilter === 'price' && (
                    <img src={Arrow} alt='arrow' className='mr-2' />
                  )}
                </div>

                {modalInterests && modalInterests.length > 0 && (
                  <div
                    className={`pl-3 pt-2 pb-2 d-flex justify-content-between ${selectedFilter === 'interest' ? 'active' : ''
                      }`}
                    onClick={() => {
                      this.setState({
                        selectedFilter: 'interest'
                      })
                    }}
                  >
                    Interest Categories{' '}
                    {selectedFilter === 'interest' && (
                      <img src={Arrow} alt='arrow' className='mr-2' />
                    )}
                  </div>
                )}
              </div>
              <div className='col-7 mh-80 overflow-scroll'>
                {selectedFilter === 'student' && (
                  <div>
                    {/* <div className='subTitle text-left mb-2 mt-3 pb-1'>Interest shown by</div> */}
                    <Row>
                      {studentList.map((data, index) => {
                        return (
                          <Col key={`mblcheckbox`} span={24}>
                            <Checkbox
                              className='pt-2 pb-2'
                              checked={data.is_active}
                              value={data.id}
                              onChange={e =>
                                this.onChangeMblStudentFilter(
                                  'modalStudents',
                                  index,
                                  e
                                )
                              }
                            >
                              {data && data.type == 'child' && (
                                <span>
                                  <img
                                    className='ChildrenImgFilter'
                                    src={
                                      data.student_details.profile_imageUrl
                                        ? data.student_details.profile_imageUrl
                                        : ChildProfileEmpty
                                    }
                                    alt='Workshops'
                                    onError={e => {
                                      e.target.onerror = null
                                      e.target.src = ChildProfileEmpty
                                    }}
                                  />
                                  <span className='childFilterText'>
                                    {data.student_details.username}
                                  </span>
                                </span>
                              )}
                              {data && data.type == 'parent' && (
                                <span>
                                  <img
                                    className='ChildrenImgFilter border-0'
                                    src={ChildProfileEmpty}
                                    alt='Workshops'
                                    onError={e => {
                                      e.target.onerror = null
                                      e.target.src = ChildProfileEmpty
                                    }}
                                  />
                                  <span className='childFilterText'>
                                    {data.first_name
                                      ? `Me! (${data.first_name})`
                                      : ''}
                                  </span>
                                </span>
                              )}
                            </Checkbox>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                )}

                {selectedFilter === 'price' && (
                  <div>
                    <RadioButtons
                      title=''
                      data={modalPriceList}
                      onChange={(key, data) => {
                        this.setState({
                          ModalPriceList: data,
                          mblPriceKey: key
                        })
                      }}
                    />
                  </div>
                )}
                {selectedFilter === 'interest' && (
                  <div>
                    <CheckboxButton
                      title=''
                      data={modalInterests}
                      limit={3}
                      onChange={(key, data) => {
                        this.setState({
                          modalInterests: data
                        })
                      }}
                    />
                  </div>
                )}
              </div>
            </ModelContainer>
            <div className='d-flex col-12 mt-3 align-items-center justify-content-center mb-4'>
              <div
                className='w-100 text-center'
                onClick={() => {
                  this.setState({ ShowMobileFilter: false }, () =>
                    this.clearAll()
                  )
                }}
              >
                Clear All
              </div>
              <div className='w-100'>
                <div
                  className=' button-cta mt-2'
                  onClick={() => {
                    this.setState({ ShowMobileFilter: false }, () =>
                      this.applyAll()
                    )
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </WorkshopContainer>
        </Modall>
      )
    }

    const { selectedSlide } = { ...this.state }
    return (
      <Layout className='createActivity'>
        <div className='row m-0 mt-4'>
          <div className='col-xl-2 col-lg-3 p-0'>
            {this.state.slider && (
              <WorkshopFilter
                interestData={this.state.filterData.interest_details}
                interestDataKey='interest_details'
                ageData={this.state.filterData.age_groups}
                ageDataKey='age_groups'
                onCheckChange={this.onChange}
                radioData={this.state.only_draft}
                pendingOnly={this.state.get_pending_only}
                ungroupedContentsOnly={this.state.ungroupedContent_only}
                radioChange={this.filterRadioChange}
                clearFilter={this.clearFilter}
                studentsFilter={this.state.studentsFilter
                  .filter(x => x.type != 'adult')
                  .map(x => x)}
                price_type={this.state.price_type}
                showDraft={
                  this.state.activeTab === '3'
                    ? this.state.role_id === 2 ||
                    this.state.role_id === 4 ||
                    this.state.role_id === 5
                    : false
                }
                showWorkshopFilter={true}
                isContentCreator={true}
                onChangeFilterShowRadio={this.onChangeFilterShowRadio}
                onChangeShowCheckbox={this.onChangeShowCheckbox}
                filterShowRadio={this.state.title}
                role_id={this.state.role_id}
              />
            )}
          </div>
          <div className='col-xl-10 col-lg-9 p-0'>
            <Content>
              <div className='row col-12 mb-2 d-flex justify-content-between align-items-center mr-0 pr-0'>
                <div className='col-xl-6 col-lg-6 mb-2'>
                  <TitleContainer>Workshops</TitleContainer>
                </div>
                {this.state.role_id == 1 &&
                  this.state.titleID == 6 &&
                  this.state.allFeaturedWorkshop.length > 0 && (
                    <div className='col-xl-6 col-lg-6 mb-2 editFeaturedWorkshop text-right'>
                      <Button
                        key='submit'
                        category='Workshops'
                        action='Edit featured workshop'
                        type='primary'
                        className='workshop-btncolor'
                        size='large'
                        onClick={this.showModal}
                      >
                        Edit featured workshops
                      </Button>
                    </div>
                  )}

                {this.state.role_id == 3 &&
                  this.state.featuredTitle != '' &&
                  this.state.allFeaturedWorkshop.length > 0 && (
                    <div className='max-dev-topbanner col-lg-12 col-md-10 pr-0'>
                      <TopBanner className='position-relative'>
                        <img
                          src={FeaturedImg}
                          className='img-fluid topbannerImg'
                          alt='topBanner'
                        />
                        <div className='row m-0 coverTitle d-flex align-items-center justify-content-center'>
                          <div className='col-xl-5 col-lg-4 p-0'>
                            <div className='title ml-lg-0 ml-xl-4 pt-2 mt-md-0 pt-md-0'>
                              {this.state.featuredTitle
                                ? this.state.featuredTitle
                                : `Can’t-miss events for this christmas`}
                            </div>
                          </div>
                          <div className='col-xl-7 col-lg-7 pl-0 pr-xl-4 pr-lg-0 m-0'>
                            <div
                              className='d-lg-flex d-xl-flex d-sm-flex d-md-flex align-items-xl-end align-items-lg-end align-items-md-end align-items-sm-end backgrnd-resp cursor-pointer'
                              style={{
                                height: '355px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                borderRadius: 10,
                                backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 60.14%, rgba(0, 0, 0, 0.24) 79.9%, rgba(0, 0, 0, 0.56) 100.08%), url(
                                              ${this.state.allFeaturedWorkshop
                                    .length
                                    ? this.state
                                      .allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].featured_image[0]
                                      .image_url
                                    : PlaceHolder
                                  })`
                              }}
                              onClick={() =>
                                this.props.history.push(
                                  `workshops/${this.state.allFeaturedWorkshop[
                                    this.state.selectedSlide
                                  ].id
                                    ? this.state.allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].id
                                    : ``
                                  }/view`
                                )
                              }
                            >
                              <div className='leftSlide'>
                                <img
                                  src={carousalLeftArrow}
                                  alt='left arrow'
                                  onClick={e => {
                                    this.setState({
                                      selectedSlide: selectedSlide
                                        ? selectedSlide - 1
                                        : this.state.allFeaturedWorkshop
                                          .length - 1
                                    })
                                    e.stopPropagation()
                                  }}
                                />
                              </div>
                              <div className='rightSlide'>
                                <img
                                  src={carousalRightArrow}
                                  alt='right arrow'
                                  onClick={e => {
                                    this.setState({
                                      selectedSlide:
                                        (selectedSlide + 1) %
                                        (this.state.allFeaturedWorkshop || [])
                                          .length
                                    })
                                    e.stopPropagation()
                                  }}
                                />
                              </div>
                              <div className='d-lg-flex d-xl-flex d-sm-flex d-md-flex flex-xl-row flex-lg-row flex-md-row flex-sm-row p-2'>
                                <div className='d-flex flex-row justify-content-start'>
                                  <div className='p-1'>
                                    <img
                                      src={
                                        this.state.allFeaturedWorkshop.length &&
                                          this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].user_details
                                          ? this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].user_details.profile_image
                                          : `https://via.placeholder.com/50x50`
                                      }
                                      alt='Workshop user profile'
                                      className='user-profile-img'
                                    />
                                  </div>
                                  <div className='p-2 text-left'>
                                    <h5
                                      className='workTitle cursor-pointer'
                                      onClick={() =>
                                        this.props.history.push(
                                          `workshops/${this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].id
                                            ? this.state.allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].id
                                            : ``
                                          }/view`
                                        )
                                      }
                                    >
                                      {this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].workshop_name
                                        ? this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].workshop_name
                                        : `Christmas Cupcake Baking with a trained pastry`}
                                    </h5>
                                    <span className='dateTime'>
                                      {this.state.allFeaturedWorkshop &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].workshop_plan_id &&
                                        (this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].workshop_plan_id == 1 ||
                                          this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].workshop_plan_id == 2) &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].repeats_type != 'dnr' &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].Dates &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].Dates.length > 0 && (
                                          <div className='workDate'>
                                            {this.state.allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].Dates &&
                                              `${this.state.allFeaturedWorkshop[
                                                this.state.selectedSlide
                                              ].repeatDays
                                                ? this.state
                                                  .allFeaturedWorkshop[
                                                  this.state.selectedSlide
                                                ].repeatDays
                                                : ''
                                              } |
                                ${this.state.allFeaturedWorkshop[
                                                this.state.selectedSlide
                                              ].Dates[0].workshop_Date
                                                ? Moment.utc(
                                                  parseInt(
                                                    this.state.allFeaturedWorkshop[
                                                      this.state.selectedSlide
                                                    ].Dates[0].workshop_Date
                                                  )
                                                ).format(`MMM DD`)
                                                : ''
                                              } -
                                ${this.state.allFeaturedWorkshop[
                                                this.state.selectedSlide
                                              ].Dates[0].workshop_Date
                                                ? Moment.utc(
                                                  parseInt(
                                                    this.state.allFeaturedWorkshop[
                                                      this.state.selectedSlide
                                                    ].Dates[
                                                      this.state.allFeaturedWorkshop[
                                                        this.state.selectedSlide
                                                      ].Dates.length - 1
                                                    ].workshop_Date
                                                  )
                                                ).format(`MMM DD, YYYY`)
                                                : ''
                                              }`}
                                          </div>
                                        )}
                                      {this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].Dates.length > 0
                                        ? this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].Dates.map((x, i) => {
                                          if (i < 2) {
                                            return (
                                              <span
                                                style={{ paddingRight: 5 }}
                                              >
                                                {Moment.utc(
                                                  parseInt(x.workshop_Date)
                                                ).format(`ddd, MMM DD, YYYY`)}
                                              </span>
                                            )
                                          }
                                        })
                                        : ``}
                                      {this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].Dates.length > 2 && (
                                          <span className='plusmoreTag'>
                                            +
                                            {this.state.allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].Dates.length - 2}
                                            more
                                          </span>
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='d-flex justify-content-center align-items-center mt-3'>
                              {this.state.allFeaturedWorkshop.map(
                                (slider, sliderIndex) => {
                                  return (
                                    <CarosuelContainer
                                      className={`cursor-pointer mr-2 ${this.state.selectedSlide === sliderIndex
                                        ? 'active'
                                        : ''
                                        }`}
                                      onClick={() => {
                                        this.setState({
                                          selectedSlide: sliderIndex
                                        })
                                      }}
                                    ></CarosuelContainer>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </TopBanner>
                    </div>
                  )}

                <ModalComponent
                  ShowMobileFilter={this.state.ShowMobileFilter}
                  selectedFilter={this.state.selectedFilter}
                  interests={this.state.modalInterests}
                  priceList={this.state.RadioState}
                  studentList={this.state.modalStudents}
                ></ModalComponent>
              </div>

              {this.state.role_id == 3 &&
                this.state.featuredTitle != '' &&
                this.state.allFeaturedWorkshop.length > 0 && (
                  <div className='mbl-dev-topbanner col-lg-12 col-md-10 p-0 mb-2'>
                    <TopBanner className='position-relative'>
                      <img
                        src={MblFeaturedImg}
                        className='img-fluid topbannerImg'
                        alt='topBanner'
                      />
                      <div className='row m-0 coverTitle '>
                        <div
                          className={`col-12 col-md-4 col-lg-5 pl-lg-5 pr-0 d-flex align-items-center justify-content-center`}
                        >
                          <div className={`title mt-3 mt-md-0 ml-lg-4`}>
                            {this.state.featuredTitle
                              ? this.state.featuredTitle
                              : `Can’t-miss events for this christmas`}
                          </div>
                        </div>

                        <div className='col-12 col-md-8 col-lg-7'>
                          <CarouselImageContainer
                            image_url={
                              this.state.allFeaturedWorkshop.length
                                ? this.state.allFeaturedWorkshop[
                                  this.state.selectedSlide
                                ].featured_image[0].image_url
                                : PlaceHolder
                            }
                            className='position-relative cursor-pointer'
                            onClick={() => {
                              this.props.history.push(
                                `workshops/${this.state.allFeaturedWorkshop[
                                  this.state.selectedSlide
                                ].id
                                  ? this.state.allFeaturedWorkshop[
                                    this.state.selectedSlide
                                  ].id
                                  : ``
                                }/view`
                              )
                            }}
                          >
                            <div
                              className='leftSlide'
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <img
                                src={carousalLeftArrow}
                                alt='left arrow'
                                onClick={e => {
                                  this.setState({
                                    selectedSlide: selectedSlide
                                      ? selectedSlide - 1
                                      : this.state.allFeaturedWorkshop.length -
                                      1
                                  })
                                  e.stopPropagation()
                                }}
                              />
                            </div>
                            <div
                              className='rightSlide'
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <img
                                src={carousalRightArrow}
                                alt='left arrow'
                                onClick={e => {
                                  this.setState({
                                    selectedSlide:
                                      (selectedSlide + 1) %
                                      (this.state.allFeaturedWorkshop || [])
                                        .length
                                  })
                                  e.stopPropagation()
                                }}
                              />
                            </div>

                            <a
                              className='workshopContainer'
                              onClick={() =>
                                this.props.history.push(
                                  `workshops/${this.state.allFeaturedWorkshop[
                                    this.state.selectedSlide
                                  ].id
                                    ? this.state.allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].id
                                    : ``
                                  }/view`
                                )
                              }
                            >
                              <div className='titleContainer'>
                                <div className='p-2 ml-2 mb-1'>
                                  <img
                                    src={
                                      this.state.allFeaturedWorkshop.length &&
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].user_details
                                        ? this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].user_details.profile_image
                                        : `https://via.placeholder.com/50x50`
                                    }
                                    alt='Workshop user profile'
                                    className='user-profile-img'
                                  />
                                </div>
                                <div className='p-2'>
                                  <h5 className='workTitle featuredImg mb-0 pb-md-1 font-nunito'>
                                    {this.state.allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].workshop_name
                                      ? this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].workshop_name
                                      : `Christmas Cupcake Baking with a trained pastry`}
                                  </h5>

                                  <span className='dateTime d-inline-block pt-lg-2'>
                                    {this.state.allFeaturedWorkshop &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].workshop_plan_id &&
                                      (this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].workshop_plan_id == 1 ||
                                        this.state.allFeaturedWorkshop[
                                          this.state.selectedSlide
                                        ].workshop_plan_id == 2) &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].repeats_type != 'dnr' &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates.length > 0 && (
                                        <div className='workDate'>
                                          {this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].Dates &&
                                            `${this.state.allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].repeatDays
                                              ? this.state
                                                .allFeaturedWorkshop[
                                                this.state.selectedSlide
                                              ].repeatDays
                                              : ''
                                            } |
                                                ${this.state
                                              .allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].Dates[0].workshop_Date
                                              ? Moment.utc(
                                                parseInt(
                                                  this.state
                                                    .allFeaturedWorkshop[
                                                    this.state
                                                      .selectedSlide
                                                  ].Dates[0]
                                                    .workshop_Date
                                                )
                                              ).format(`MMM DD`)
                                              : ''
                                            } -
                                                ${this.state
                                              .allFeaturedWorkshop[
                                              this.state.selectedSlide
                                            ].Dates[0].workshop_Date
                                              ? Moment.utc(
                                                parseInt(
                                                  this.state
                                                    .allFeaturedWorkshop[
                                                    this.state
                                                      .selectedSlide
                                                  ].Dates[
                                                    this.state
                                                      .allFeaturedWorkshop[
                                                      this.state
                                                        .selectedSlide
                                                    ].Dates.length - 1
                                                  ].workshop_Date
                                                )
                                              ).format(`MMM DD, YYYY`)
                                              : ''
                                            }`}
                                        </div>
                                      )}
                                    {this.state.allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].Dates &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates.length > 0
                                      ? this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates.map((x, i) => {
                                        if (i < 2) {
                                          return (
                                            <span style={{ paddingRight: 5 }}>
                                              {Moment.utc(
                                                parseInt(x.workshop_Date)
                                              ).format(`ddd, MMM DD, YYYY`)}
                                            </span>
                                          )
                                        }
                                      })
                                      : ``}
                                    {this.state.allFeaturedWorkshop[
                                      this.state.selectedSlide
                                    ].Dates &&
                                      this.state.allFeaturedWorkshop[
                                        this.state.selectedSlide
                                      ].Dates.length > 2 && (
                                        <span className='plusmoreTag'>
                                          +
                                          {this.state.allFeaturedWorkshop[
                                            this.state.selectedSlide
                                          ].Dates.length - 2}
                                          more
                                        </span>
                                      )}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </CarouselImageContainer>

                          {this.state.allFeaturedWorkshop &&
                            this.state.allFeaturedWorkshop.length > 1 && (
                              <div className='d-flex justify-content-center align-items-center mt-3'>
                                {this.state.allFeaturedWorkshop.map(
                                  (slider, sliderIndex) => {
                                    return (
                                      <CarosuelContainer
                                        className={`cursor-pointer mr-2 ${this.state.selectedSlide ===
                                          sliderIndex
                                          ? 'active'
                                          : ''
                                          }`}
                                        onClick={() => {
                                          this.setState({
                                            selectedSlide: sliderIndex
                                          })
                                        }}
                                      />
                                    )
                                  }
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </TopBanner>
                  </div>
                )}

              <div className='row col-lg-12 col-xl-12 m-0 text-right justify-content-between align-items-center pl-0 pt-2 mbl-pl-none'>
                <div className='max-dev-d-none col-6'>
                  <TitleContainer1 className='mb-0'>
                    {this.state.title}
                  </TitleContainer1>
                </div>
                {this.state.role_id == 1 && this.state.titleID != 6 && (
                  <div className='col-6 p-0'>
                    <React.Fragment>
                      <label className='contentcreateLab'>
                        Filter by content creators:{' '}
                      </label>
                      <Select
                        size='large'
                        value={this.state.selectedcc}
                        onChange={e => this.onSelectChange(e)}
                        style={{ width: 150, marginLeft: 10 }}
                      >
                        <Select.Option size='large' key='all' value={null}>
                          All
                        </Select.Option>
                        {this.state.content_creators.map((data, i) => (
                          <Select.Option size='large' key={i} value={data.id}>
                            {data.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </React.Fragment>
                  </div>
                )}
                {/* {[2, 4, 5].includes(this.state.role_id) && (
                  <div className='col-6 p-0'>
                    <React.Fragment>
                      <div className='d-flex flex-row align-items-center justify-content-end'>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 500
                          }}
                        >
                          Sort By:{' '}
                        </div>
                        <div
                          className={`btn sort ${
                            this.state.sort ? 'sort-active' : ''
                          }`}
                          onClick={() => {
                            this.setState(
                              {
                                sort: true
                              },
                              () => this.getAllWorkshops()
                            )
                          }}
                        >
                          Latest First
                        </div>
                        <div
                          className={`btn sort ${
                            this.state.sort ? '' : 'sort-active'
                          }`}
                          onClick={() => {
                            this.setState(
                              {
                                sort: false
                              },
                              () => this.getAllWorkshops()
                            )
                          }}
                        >
                          Oldest First
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                )} */}

                {this.state.role_id == 3 && (
                  <React.Fragment>
                    <div className='max-dev-d-none col-6 p-0'>
                      <Search
                        size='large'
                        placeholder={`Search`}
                        onChange={this.onSearch}
                        className='search-parent'
                      />
                    </div>

                    <div className='mbl-dev-w-search col-lg-12 p-lg-0 d-flex justify-content-between align-items-center mt-lg-0'>
                      <div style={{ width: 272 }}>
                        <Input
                          size='default'
                          allowClear
                          placeholder={`What do you want to learn?`}
                          onChange={this.onSearch}
                          className='search-parent'
                          prefix={<Icon type='search' />}
                        />
                      </div>
                      <div
                        className='ml-2 d-flex d-lg-none'
                        onClick={() => {
                          this.setState({
                            ShowMobileFilter: true
                          })
                        }}
                      >
                        <img src={Filter} width={20} alt='filter' />
                        <span
                          className='ml-2'
                          style={{
                            color: '#0076FF',
                            fontSize: '16px',
                            lineHeight: '18px',
                            textAlign: 'center'
                          }}
                        >
                          Filter
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              {/* Mbl age group filter */}
              <div className='mbl-dev-d-none'>
                <div className=' pl-lg-0 pr-0 col-lg-9 mt-3 mt-lg-0'>
                  <div className='p-0 d-flex h-100 overflow-scroll hide-scrollbar'>
                    {this.state.filterData.age_groups.map((age, ageIndex) => {
                      return (
                        <TagContainer
                          key={`ageCategory-${ageIndex}`}
                          className={`font-nunito cursor-pointer mr-min w-100 text-center border-left-0 ${age.is_active ? 'active' : ''
                            } h-100 d-flex justify-content-center align-items-center`}
                          onClick={e => {
                            const ageCategory = [
                              ...this.state.filterData.age_groups
                            ]
                            let ageCategoryListTemp = ageCategory.map(ageC => {
                              ageC.is_active = false
                              return ageC
                            })
                            this.onChange(
                              'age_groups',
                              ageIndex,
                              !ageCategoryListTemp[ageIndex].is_active
                            )
                          }}
                        >
                          <div>{age.age}</div>
                        </TagContainer>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/* Mbl Tabs workshops listing */}
              <div className='mbl-dev-d-none mbl-nav-sticky mt-4'>
                <div className='m-0 row d-flex align-items-center justify-content-around pl-4 pr-4'>
                  <div
                    className={`cursor-pointer col-4 mbl-w-tab ${this.state.titleID == 1 ? 'active' : ''
                      }`}
                    onClick={() => {
                      this.onChangeFilterShowRadio({
                        target: {
                          value: 'Upcoming workshops',
                          id: 1
                        }
                      })
                    }}
                  >
                    <div>Upcoming</div>
                  </div>
                  <div
                    className={`cursor-pointer col-4 mbl-w-tab ${this.state.titleID == 8 ? 'active' : ''
                      }`}
                    onClick={() => {
                      this.onChangeFilterShowRadio({
                        target: {
                          value: 'Registered workshops',
                          id: 8
                        }
                      })
                    }}
                  >
                    <div>Registered</div>
                  </div>
                  <div
                    className={`cursor-pointer col-4 mbl-w-tab ${this.state.titleID == 7 ? 'active' : ''
                      }`}
                    onClick={() => {
                      this.onChangeFilterShowRadio({
                        target: {
                          value: 'Attended workshops',
                          id: 7
                        }
                      })
                    }}
                  >
                    <div>Attended</div>
                  </div>
                </div>
              </div>

              {/* START */}

              {[1, 2, 4, 5].includes(this.state.role_id) &&
                this.state.titleID == 4 && (
                  <Tabs
                    className='mt-2 ml-4'
                    defaultActiveKey={this.state.activeTab}
                    onChange={this.onTabChange}
                    activeKey={this.state.activeTab}
                  >
                    <TabPane
                      className=''
                      tab={`Drafts`}
                      key='1'
                    // disabled={this.state.disabled}
                    >
                      {/* {this.state.adminApprovedWorkshop.length === 0 && (
                      <React.Fragment>
                        {this.state.isActivity_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            loader
                            data={[{}, {}, {}]}
                            isLoading
                          />
                        )}
                      </React.Fragment>
                    )} */}
                      {!this.state.isActivity_loading && (
                        <React.Fragment>
                          {this.state.adminApprovedWorkshop.length === 0 &&
                            this.state.titleID != 6 && (
                              <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                                <img
                                  className='mb-3'
                                  src={
                                    this.state.titleID == 4
                                      ? emptyDraftWorkshop
                                      : ``
                                  }
                                  alt=''
                                />
                                <div className='text-center'>
                                  <p className='FontAvenirMedium font-20 mb-2'>
                                    {this.state.titleID == 3
                                      ? `None of your workshops are pending for approval`
                                      : this.state.titleID == 4
                                        ? `None of your workshops are rejected`
                                        : ``}
                                  </p>
                                </div>
                              </div>
                            )}
                        </React.Fragment>
                      )}
                      {this.state.adminApprovedWorkshop.length !== 0 && (
                        <TabContainer
                          sort={this.state.sort}
                          sortChange={this.sortChange}
                          data={this.state.adminApprovedWorkshop}
                          filter={this.state.filter}
                          filterData={this.state.filterData}
                          page={this.state.tab2Page}
                          hasMoreItems={this.state.tab2More}
                          loadItems={this.loadTab2}
                          isLoading={this.state.isActivity_loading}
                        />
                      )}
                    </TabPane>
                    <TabPane
                      className=''
                      tab={`Rejected`}
                      key='2'
                    // disabled={this.state.disabled}
                    >
                      {/* {this.state.adminPendingActivities.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            data={[{}, {}, {}]}
                            isLoading
                            loader
                          />
                        )}
                      </React.Fragment>
                    )} */}
                      {!this.state.is_collection_loading && (
                        <React.Fragment>
                          {this.state.adminApprovedWorkshop.length === 0 &&
                            this.state.titleID != 6 && (
                              <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                                <img
                                  className='mb-3'
                                  src={
                                    this.state.titleID == 3
                                      ? emptyPendingWorkshop
                                      : this.state.titleID == 4
                                        ? emptyDraftWorkshop
                                        : ``
                                  }
                                  alt=''
                                />
                                <div className='text-center'>
                                  <p className='FontAvenirMedium font-20 mb-2'>
                                    {this.state.titleID == 4
                                      ? `None of your workshops are rejected`
                                      : ``}
                                  </p>
                                </div>
                              </div>
                            )}
                        </React.Fragment>
                      )}
                      {this.state.adminApprovedWorkshop.length !== 0 && (
                        <TabContainer
                          sort={this.state.sort}
                          sortChange={this.sortChange}
                          data={this.state.adminApprovedWorkshop}
                          filter={this.state.filter}
                          filterData={this.state.filterData}
                          page={this.state.tab2Page}
                          hasMoreItems={this.state.tab2More}
                          loadItems={this.loadTab2}
                          isLoading={this.state.isActivity_loading}
                        />
                      )}
                    </TabPane>
                  </Tabs>
                )}
              {/* END */}

              {[1, 2, 4, 5].includes(this.state.role_id) &&
                this.state.titleID != 4 && (
                  <div>
                    {this.state.adminApprovedWorkshop.length === 0 && (
                      <React.Fragment>
                        {this.state.isActivity_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            loader
                            data={[{}, {}, {}]}
                            isLoading
                          />
                        )}
                      </React.Fragment>
                    )}

                    {!this.state.isActivity_loading && (
                      <React.Fragment>
                        {this.state.adminApprovedWorkshop.length === 0 &&
                          this.state.titleID != 6 && (
                            <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                              <img
                                className='mb-3'
                                src={
                                  this.state.titleID == 1
                                    ? emptyUpcomingWorkshop
                                    : this.state.titleID == 2
                                      ? emptyPastWorkshop
                                      : this.state.titleID == 3
                                        ? emptyPendingWorkshop
                                        : this.state.titleID == 5
                                          ? emptyRejectedWorkshop
                                          : ``
                                }
                                alt=''
                              />
                              <div className='text-center'>
                                <p className='FontAvenirMedium font-16 mb-2'>
                                  {this.state.titleID == 1
                                    ? `You haven’t created any workshops. Start creating by tapping the button below!`
                                    : this.state.titleID == 2
                                      ? `You haven’t conducted any workshops yet. Start creating by tapping the button below!`
                                      : this.state.titleID == 3
                                        ? `None of your workshops are pending for approval`
                                        : this.state.titleID == 5
                                          ? `You haven’t rejected any workshops`
                                          : ``}
                                </p>
                              </div>
                              <div className='text-center'>
                                <React.Fragment>
                                  {this.state.role_id !== 1 &&
                                    (this.state.titleID == 1 ? (
                                      <Button
                                        key='submit'
                                        className='mt-2'
                                        category='Workshops workshop-btncolor'
                                        action='Redirecting to create workshop'
                                        type='primary'
                                        size='large'
                                        onClick={() =>
                                          this.props.history.replace(
                                            `/workshops/workshop/create`
                                          )
                                        }
                                      >
                                        Create workshop
                                      </Button>
                                    ) : this.state.titleID == 2 ? (
                                      <Button
                                        key='submit'
                                        className='mt-2 workshop-btncolor'
                                        category='Workshops'
                                        action='Redirecting to create workshop'
                                        type='primary'
                                        size='large'
                                        onClick={() =>
                                          this.props.history.replace(
                                            `/workshops/workshop/create`
                                          )
                                        }
                                      >
                                        Create workshop
                                      </Button>
                                    ) : (
                                      ``
                                    ))}
                                </React.Fragment>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                    {this.state.adminApprovedWorkshop.length !== 0 && (
                      <TabContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.adminApprovedWorkshop}
                        filter={this.state.filter}
                        filterData={this.state.filterData}
                        page={this.state.tab2Page}
                        hasMoreItems={this.state.tab2More}
                        loadItems={this.loadTab2}
                        isLoading={this.state.isActivity_loading}
                      />
                    )}
                    {this.state.adminPendingActivities.length === 0 && (
                      <React.Fragment>
                        {this.state.is_collection_loading && (
                          <TabContainer
                            sort={this.state.sort}
                            sortChange={this.sortChange}
                            data={[{}, {}, {}]}
                            isLoading
                            loader
                          />
                        )}
                      </React.Fragment>
                    )}

                    {this.state.adminPendingActivities.length !== 0 && (
                      <TabContainer
                        sort={this.state.sort}
                        sortChange={this.sortChange}
                        data={this.state.adminPendingActivities}
                        filter={this.state.filter}
                        filterData={this.state.filterData}
                        page={this.state.tab1Page}
                        hasMoreItems={this.state.tab1More}
                        loadItems={this.loadTab1}
                        isLoading={this.state.is_collection_loading}
                      />
                    )}
                  </div>
                )}

              {this.state.role_id == 3 && (
                <div>
                  {
                    <ParentTabContainer
                      history={this.props.history}
                      sort={this.state.sort}
                      sortChange={this.sortChange}
                      data={this.state.parentWorkshopdata}
                      filter={this.state.filter}
                      filterData={this.state.filterData}
                      page={this.state.tab2Page}
                      hasMoreItems={this.state.tab2More}
                      loadItems={this.loadTab2}
                      isLoading={this.state.is_parentWorkshop_loading}
                      searchText={this.state.searchText}
                      parentErrorState={this.state.parentErrorState}
                      titleID={this.state.titleID}
                    ></ParentTabContainer>
                  }
                  {/* {this.state.parentWorkshopdata.length == 0 && (
                    <React.Fragment>
                      {this.state.is_parentWorkshop_loading && (
                        <ParentTabContainer
                          history={this.props.history}
                          sort={this.state.sort}
                          sortChange={this.sortChange}
                          data={[{}, {}, {}]}
                          isLoading={true}
                          loader
                          searchText={this.state.searchText}
                          parentErrorState={this.state.parentErrorState}
                        />
                      )}
                    </React.Fragment>
                  )} */}
                </div>
              )}

              {this.state.role_id == 1 && this.state.titleID == 6 ? (
                this.state.allFeaturedWorkshop.length > 0 ? (
                  <div className='m-3'>
                    <div className='featureTitle mt-4 mr-3 border-style'>
                      <span style={{ color: '#828282', marginRight: 15 }}>
                        Title:
                      </span>
                      <span>{this.state.getfeaturedTitle}</span>
                    </div>
                    {this.state.allFeaturedWorkshop.length !== 0 && (
                      <React.Fragment>
                        <TabContainer
                          sort={this.state.sort}
                          sortChange={this.sortChange}
                          data={this.state.allFeaturedWorkshop}
                          filter={this.state.filter}
                          filterData={this.state.filterData}
                          page={this.state.tab2Page}
                          hasMoreItems={this.state.tab2More}
                          loadItems={this.loadTab2}
                          isLoading={
                            this.state.isfeaturedWorkshopContentLoading
                          }
                        />
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  ``
                )
              ) : (
                ``
              )}

              {!this.state.isfeaturedWorkshopContentLoading &&
                this.state.featuredTitle &&
                this.state.titleID == 6 && (
                  <React.Fragment>
                    {this.state.allFeaturedWorkshop.length == 0 && (
                      <div className='d-flex justify-content-center align-items-center flex-column'>
                        <img className='mb-3' src={EmptyList} alt='' />
                        <div className='text-center'>
                          <p className='FontAvenirMedium font-16 mb-2'>
                            No results found! Try adjusting your search or
                            filter
                          </p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

              {this.state.isfeaturedWorkshopContentLoading &&
                this.state.featuredTitle &&
                this.state.titleID == 6 && (
                  <React.Fragment>
                    {this.state.allFeaturedWorkshop.length == 0 && (
                      <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                        <img
                          className='mb-3'
                          src={
                            this.state.titleID == 6
                              ? FeaturedFilterEmptyWorkshop
                              : ``
                          }
                          alt=''
                        />
                        <div className='text-center'>
                          <p className='FontAvenirMedium font-16 mb-2'>
                            {this.state.titleID == 6
                              ? `No results found! Try adjusting your search or filter`
                              : ``}
                          </p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

              {!this.state.isfeaturedWorkshopContentLoading &&
                !this.state.featuredTitle &&
                this.state.titleID == 6 && (
                  <React.Fragment>
                    {this.state.allFeaturedWorkshop.length == 0 && (
                      <div className='tabscroll d-flex justify-content-center align-items-center flex-column'>
                        <img
                          className='mb-3'
                          src={
                            this.state.titleID == 6 ? emptyFeaturedWorkshop : ``
                          }
                          alt=''
                        />
                        <div className='text-center'>
                          <p className='FontAvenirMedium font-16 mb-2'>
                            {this.state.titleID == 6
                              ? `You haven’t featured any workshops yet. Start featuring by tapping the button below!`
                              : ``}
                          </p>
                        </div>
                        <div className='text-center'>
                          <React.Fragment>
                            {this.state.role_id == 1 &&
                              (this.state.titleID == 6 ? (
                                <Button
                                  key='submit'
                                  className='mt-2 workshop-btncolor'
                                  category='Workshops'
                                  action='Redirecting to featured create workshop'
                                  type='primary'
                                  size='large'
                                  onClick={this.showModal}
                                >
                                  Add featured workshops
                                </Button>
                              ) : (
                                ``
                              ))}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
            </Content>
          </div>
        </div>

        {/* Featured workshops start */}
        <Modal
          width='75%'
          visible={this.state.modalVisible}
          title={`Select workshops that has to be featured`}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={[
            <div
              className='d-flex align-items-center justify-content-between'
              key='footer'
            >
              <div className='FontAvenirMedium font-16 text-primary'>
                {`${this.state.selectedWorkshopsIds.length
                  ? `${this.state.selectedWorkshopsIds.length} Workshop Selected`
                  : ``
                  }`}
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <Button key='back' size='large' onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button
                  key='submit'
                  size='large'
                  type='primary'
                  className='ml-3'
                  onClick={this.addEditFeaturedWorkshop}
                  disabled={!this.state.featuredTitle.length}
                >
                  {this.state.allFeaturedWorkshop.length
                    ? `Save`
                    : `Make as featured`}
                </Button>
              </div>
            </div>
          ]}
        >
          <Row className='rounded'>
            <div className='col-12 p-0'>
              <div className='featuredTitle'>Title</div>
              <InputC
                title=''
                stateKey='featuredTitle'
                id={this.state.truthObj['featuredTitle'] ? 'has-error' : ''}
                onChange={(key, value) => {
                  if (value.length <= 90) {
                    this.onInputChange(key, value)
                  }
                }}
                value={this.state.featuredTitle}
                placeholder='Enter title that will be displayed'
                className='ant-input ant-input-lg font-16'
              />
              <span
                className='featuredTitleLen'
                style={
                  this.state.featuredTitle.length > 90
                    ? { color: '#FF0A00' }
                    : {}
                }
              >
                {this.state.featuredTitle.length} / 90
              </span>
            </div>
            <div className='mt-3 mb-3 featuredUpcomingworkshop'>
              Upcoming Workshops
            </div>
            <div className='col-12 p-0 mt-2'>
              <Input.Search
                style={{ width: '100%' }}
                value={this.state.featuredSearchText}
                name='searchText'
                size='large'
                placeholder='Search'
                onChange={e => this.onSearchChange(e)}
              />
            </div>
            <div style={{ height: '25vh', overflow: 'scroll' }}>
              {!this.state.isWorkshopContentLoading && (
                <React.Fragment>
                  {this.state.allUpcomingWorkshops.length === 0 && (
                    <React.Fragment>
                      <div className=' d-flex justify-content-center align-items-center flex-column'>
                        <img className='mb-3' src={EmptyList} alt='' />
                        <div className='text-center'>
                          <p className='FontAvenirMedium font-20 mb-2'>
                            {this.state.featuredSearchText === ''
                              ? ''
                              : 'No results found. Please try again with different keywords'}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.allUpcomingWorkshops.length !== 0 && (
                    <React.Fragment>
                      {this.state.allUpcomingWorkshops.map((data, index) => {
                        return (
                          <FeaturedWorkshopContainer
                            data={data}
                            search={this.state.featuredSearchText}
                            onChangeCheckbox={val => {
                              this.onChangeCheckbox(index, val)
                            }}
                          />
                        )
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {this.state.isWorkshopContentLoading && (
                <React.Fragment>
                  {[{}, {}, {}].map((data, index) => {
                    return (
                      <FeaturedWorkshopContainer
                        data={{}}
                        search={this.state.featuredSearchText}
                        isLoading={true}
                        onChangeCheckbox={val => {
                          // this.onChangeCheckbox(index, val);
                        }}
                      />
                    )
                  })}
                </React.Fragment>
              )}
            </div>
          </Row>
        </Modal>
        {/* Featured workshops end */}
      </Layout>
    )
  }
}

const WorkshopList = (props) => {
  return <div>
    <AppContext.Consumer>
      {userInfo => (
        <Workshop {...userInfo} {...props} />
      )

      }
    </AppContext.Consumer>
  </div>
}

export default WorkshopList
