import React, { Component } from 'react';
export default class TitleDescBlue extends Component {
    render() {
        return (
            <div>
                <div className="d-inline text-uppercase">{this.props.heading}</div>
                <div className="d-inline pr-3 pl-3">-</div>
                {/* <div className="d-inline color-blue-6" >{this.props.desc}</div> */}
                {this.props.desc ? (
                  <div className="d-inline color-blue-6" >
                    <a href={this.props.desc} target="_blank" rel="noopener noreferrer">{this.props.title}</a>
                  </div>
                ) : (
                  <span>{this.props.title}</span>
                )}
            </div>
        )
    }

}
