import { message } from "antd";
import axios from "axios";
import React from "react";
import { Mutation } from "react-apollo";
import {
  CHECK_INVITE_LINK,
  GET_GLOBAL_INTEREST_CATEGORY,
  LC_ON_BOARDING
} from "../../graphql.js";
import {triggerGAEvent} from "../../utils";
import ResetPswd from "../ResetPswd";
import LCEnroll from "./LCEnroll";
import LCEnroll1 from "./LCEnroll1";
import LCSignin from "./LCSignin";

export default class Invite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      inviteEmail: "",
      role_id: "",
      inviteToken: "",
      signin: {
        email: "",
        password: "",
        confirm: ""
      },
      lg: {
        checkboxOptions: [],
        value: "",
        displayName: "",
      },
      enroll: {
        mobileno: "",
        address: "",
        description: "",
        latitude: "",
        longitude: "",
      }
    };
  }

  fetchData = () => {
    let mutation = CHECK_INVITE_LINK;
    mutation.variables = {
      inviteToken: this.props.match.params.id
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data.checkInviteLinkV2.status) {
          this.setState({
            inviteEmail: res.data.data.checkInviteLinkV2.email,
            role_id: res.data.data.checkInviteLinkV2.role_id + ""
          });
        } else {
          message.error(res.data.data.checkInviteLinkV2.message);
        }
      })
      .catch(error => console.log(error));

    axios
      .post(`${process.env.REACT_APP_API}`, GET_GLOBAL_INTEREST_CATEGORY)
      .then(res => {
        const checkBoxOpt = res.data.data.getGlobalInterestCategory.intrest_cat.map(
          data => {
            data.is_active = false;
            return data;
          }
        );
        const state = { ...this.state };
        state.lg.checkboxOptions = checkBoxOpt.filter(
          data => data.total_interest !== 0
        );
        this.setState({ lg: state.lg });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }

  next = () => {
    triggerGAEvent("LC On-boarding", "Enter data for Learning centre");
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    triggerGAEvent("LC On-boarding", "Clicking Back button");
    const current = this.state.current - 1;
    this.setState({ current });
  };

  onChangeSignIn = (key, value) => {
    triggerGAEvent("LC On-boarding", "Enter Password for Learning centre");
    const state = { ...this.state };
    state.signin[key] = value;
    this.setState({ signin: state.signin });
  };

  onChangeCheckBoxLC = index => {
    triggerGAEvent(
      "LC On-boarding",
      "Adding Interest categories for Learning centre"
    );
    const state = { ...this.state };
    state.lg.checkboxOptions[index].is_active = !this.state.lg.checkboxOptions[
      index
    ].is_active;
    this.setState({ lg: state.lg });
  };

  selectAllInterestCategories = () => {
    triggerGAEvent(
      "LC On-boarding",
      "Selecting all Interest categories for Learning centre"
    );
    const state = { ...this.state };
    state.lg.checkboxOptions.forEach((data, index) => {
      data.is_active = true;
    });
    this.setState({ lg: state.lg });
  };

  clearAllInterestCategories = () => {
    triggerGAEvent(
      "LC On-boarding",
      "Clearing all Interest categories for Learning centre"
    );
    const state = { ...this.state };
    state.lg.checkboxOptions.forEach((data, index) => {
      data.is_active = false;
    });
    this.setState({ lg: state.lg });
  };

  onChangeTextLC = (key, value) => {
    triggerGAEvent("LC On-boarding", "Enter data for Learning centre");
    const state = { ...this.state };
    state.lg[key] = value;
    this.setState({ lg: state.lg });
  };

  onChangeEnroll = (key, value) => {
    triggerGAEvent("LC On-boarding", "Enter data for Learning centre");
    const state = { ...this.state };
    state.enroll[key] = value;
    this.setState({ enroll: state.enroll });
  };

  render() {
    const { current } = this.state;
    const steps = [
      {
        title: "First",
        content: (
          <LCSignin
            next={this.next}
            onChange={this.onChangeSignIn}
            currState={this.state.signin}
            inviteEmail={this.state.inviteEmail}
            role_id={this.state.role_id}
          />
        )
      },
      {
        title: "Second",
        content: (
          <ResetPswd
            next={this.next}
            is_set={true}
            onChange={this.onChangeSignIn}
            currState={this.state.signin}
            role_id={this.state.role_id}
          />
        )
      },
      {
        title: "Last",
        content: (
          <LCEnroll
            next={this.next}
            currState={this.state.lg}
            prev={this.prev}
            onChangeCheckBox={this.onChangeCheckBoxLC}
            onChangeText={this.onChangeTextLC}
            inviteEmail={this.state.inviteEmail}
            inviteToken={this.props.match.params.id}
            name={this.state.lg.name}
            selectAll={this.selectAllInterestCategories}
            clearAll={this.clearAllInterestCategories}
            role_id={this.state.role_id}
          />
        )
      },
      {
        title: "Last",
        content: (
          <Mutation
            mutation={LC_ON_BOARDING}
            variables={{
              inviteToken: this.props.match.params.id,
              name: this.state.lg.value,
              displayName: this.state.lg.displayName,
              password: this.state.signin.password,
              bio: this.state.enroll.description,
              phoneNo: this.state.enroll.mobileno,
              address: this.state.enroll.address,
              img: this.state.enroll.profileImage,
              latitude: parseFloat(this.state.enroll.latitude),
              longitude: parseFloat(this.state.enroll.longitude),
              interest_categories_id: this.state.lg.checkboxOptions
                .filter(data => data.is_active)
                .map(key => {
                  return key.id;
                })
            }}
            onCompleted={data => {
              triggerGAEvent("LC On-boarding", "Learning centre sign up");
              if (data.onboardingLearningCentreV2.user_details.token) {
                localStorage.setItem(
                  "token",
                  data.onboardingLearningCentreV2.user_details.token
                );
                if (
                  data.onboardingLearningCentreV2.user_details.role_id === "2"
                ) {
                  this.props.history.push("/");
                }
                if (
                  data.onboardingLearningCentreV2.user_details.role_id === "4"
                ) {
                  this.props.history.push("/");
                }
                if (
                  data.onboardingLearningCentreV2.user_details.role_id === "5"
                ) {
                  this.props.history.push("/");
                }
              } else {
                alert("error");
              }
            }}
          >
            {UserLogin => (
              <LCEnroll1
                desc={this.state.enroll.description}
                phno={this.state.enroll.mobileno}
                latitude={this.state.enroll.latitude}
                longitude={this.state.enroll.longitude}
                address={this.state.enroll.address}
                prev={this.prev}
                onChange={this.onChangeEnroll}
                profileImage={this.state.enroll.profileImage}
                onboardingSubmit={UserLogin}
                role_id={this.state.role_id}
              />
            )}
          </Mutation>
        )
      }
    ];
    return (
      <div>
        <div className="steps-content">{steps[current].content}</div>
      </div>
    );
  }
}
