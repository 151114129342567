import React, { Component } from "react";
import RichTextEditor from "react-rte";
import styled from "styled-components";
const TextEditorStyle = styled.div`
  .textEditor {
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    & [class^="RichTextEditor__paragraph"],
    & [class^="RichTextEditor__block"] {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;
const styleMap = {
  BOLD: {
    fontWeight: 900,
  },
};
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [{ label: "Bold", style: "BOLD", className: "" }],

  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
export class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(props.value),
    };
  }
  onChange = (value) => {
    // this.setState({ value });
    if (this.props.onEditorChange) {
      this.props.onEditorChange(value);
    }
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };
  render() {
    return (
      <TextEditorStyle>
        <RichTextEditor
          toolbarConfig={toolbarConfig}
          value={this.props.data}
          onChange={this.onChange}
          editorClassName="textEditor"
          customStyleMap={styleMap}
          editorStyle={{
            height: "180px",
            overflow: "scroll",
          }}
        />
      </TextEditorStyle>
    );
  }
}

export default TextEditor;
