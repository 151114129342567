import { Button, Card, Col, Input, Row, Tabs } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import Filter from "../../components/filter";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import { ACTIVITY_FILTER_QUERY } from "../../graphql";
import { triggerGAEvent } from "../../utils";
import { GET_ALL_PLAYLIST } from "./api";
import "./playlist.css";

const Search = Input.Search;
const TabPane = Tabs.TabPane;

export default class PlayListsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playlists: [],
      filter: {},
      sort: "newest",
      searchText: "",
      filterData: {
        interest_details: [],
        age_groups: [],
        marks: { 0: "", 100: "" }
      },
      playlist: [],
      unpublished: [],
      archived: [],
      activeTab: this.props.history.location.search.split("=")[1]
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchAllPlaylist = () => {
    this.setState({ loading: true });
    let pListQuery = GET_ALL_PLAYLIST;
    pListQuery.variables = {
      interest_ids: this.state.filter.interest,
      sort: this.state.sort,
      search: this.state.searchText
    };
    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        this.setState({
          playlists: res.data.data.getAllPlaylist.playlists,
          unpublished: res.data.data.getAllPlaylist.unpublised,
          archived: res.data.data.getAllPlaylist.archive,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  fetchFilter = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, ACTIVITY_FILTER_QUERY)
      .then(res => {
        const filterData = res.data.data.getWebFilterData;
        filterData.age_groups =
          res.data.data.getWebFilterData.difficulty_details.age_groups;
        // const diff = res.data.data.getWebFilterData.time.max - res.data.data.getWebFilterData.time.min;
        filterData.marks = {
          0: Math.ceil(res.data.data.getWebFilterData.time.min),
          100: Math.ceil(res.data.data.getWebFilterData.time.max)
        };
        this.setState({ filterData: res.data.data.getWebFilterData });
        // return;
      })
      .catch(err => console.log(err));
  };

  onFilterChange = (key, index, value) => {
    triggerGAEvent("Playlist", "Changing Filters in Playlist");
    const state = { ...this.state };
    state.filterData[key][index].is_active = value;
    if (key === "age_groups") {
      state.filter["age"] = state.filterData[key]
        .filter(data => data.is_active)
        .map(data => parseInt(data.id, 10));
    }
    if (key === "interest_details") {
      state.filter["interest"] = state.filterData[key]
        .filter(data => data.is_active)
        .map(data => parseInt(data.id, 10));
    }
    this.fetchAllPlaylist();
    this.setState({ filterData: state.filterData, filter: state.filter });
  };

  sortChange = value => {
    triggerGAEvent("Playlist", "Changing Sort in Playlist");
    this.setState({ sort: value }, () => {
      this.fetchAllPlaylist();
    });
  };

  filterTimeChange = value => {
    const state = { ...this.state };
    state.filter.time = value;
    this.setState({ filter: state.filter });
    this.fetchData();
  };

  clearFilter = () => {
    triggerGAEvent("Playlist", "Clearing Filters in Playlist");
    this.setState({ slider: false });
    setTimeout(() => {
      this.setState({ slider: true });
    }, 1);
    const filterData = { ...this.state.filterData };
    filterData.age_groups = filterData.age_groups.map(data => ({
      ...data,
      is_active: false
    }));
    filterData.interest_details = filterData.interest_details.map(data => ({
      ...data,
      is_active: false
    }));
    this.setState({ filterData: filterData, filter: {} });
    setTimeout(() => this.fetchAllPlaylist(), 100);
  };

  onTabChange = key => {
    triggerGAEvent("Playlist", "Changing Tabs in Playlist");
  };

  onSearchFetchData = () => {
    triggerGAEvent("Playlist", "Searching in Playlist");
    this.fetchAllPlaylist();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        defaultActiveKey: this.props.location.state.defaultActiveKey
      });
    }
    this.fetchAllPlaylist();
    this.fetchFilter();
  }

  render() {
    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading} />
        <Row className="mt-4">
          <Col span={4}>
            <Filter
              interestData={this.state.filterData.interest_details}
              interestDataKey="interest_details"
              ageData={this.state.filterData.age_groups}
              ageDataKey="age_groups"
              onCheckChange={this.onFilterChange}
              time={this.state.filterData.marks}
              timeChange={this.filterTimeChange}
              clearFilter={this.clearFilter}
              showJustInterest={true}
              showTimeFilter={true}
            />
          </Col>
          <Col span={20} style={{ paddingTop: "1rem", paddingRight: "1rem" }}>
            <div className="d-flex align-items-center justify-content-between pl-3">
              <h4 className="mb-0">{`All Playlists`}</h4>
              <div className="d-flex align-items-center justify-content-end">
                <Search
                  size="large"
                  placeholder="Search Playlists here"
                  onChange={this.onSearch}
                  className="mr-3"
                  style={{ width: 400 }}
                />
                <Button
                  type="primary"
                  className="btn-w250"
                  size="large"
                  onClick={() => this.props.history.push(`playlists/create`)}
                >
                  Create New Playlist
                </Button>
              </div>
            </div>
            <Row className="mt-3">
              <Sort
                Edit={true}
                data={this.state.sort}
                sortChange={this.sortChange}
              />
            </Row>
            <Tabs
              defaultActiveKey={this.state.activeTab}
              onChange={this.onTabChange}
            >
              <TabPane
                tab={`Published - ${this.state.playlists.length}`}
                key="1"
              >
                <div className="tabscroll">
                  {this.state.playlists.map((plist, i) => (
                    <Col span={8} key={i}>
                      <Link to={`/playlists/view/${plist.id}`}>
                        <Card
                          bordered={false}
                          className="playlistCard"
                          loading={this.state.loading}
                          style={{
                            borderTop: `5px solid ${plist.color_code}`
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start h-100">
                            <div className="pTitle"> {plist.title} </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center mb-3 mr-3">
                                <span style={{ color: "grey" }}>
                                  Total Number of cards:
                                </span>
                                <span style={{ color: "black" }}>
                                  {plist.array.lib_act.length}
                                </span>
                              </div>
                              <div>
                                {plist.array.intrest.length > 0 && (
                                  <LinesEllipsis
                                    text={plist.array.intrest
                                      .map(interest => interest.name)
                                      .join(", ")}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </div>
              </TabPane>
              <TabPane
                tab={`Unpublished - ${this.state.unpublished.length}`}
                key="2"
              >
                <div className="tabscroll">
                  {this.state.unpublished.map((plist, i) => (
                    <Col span={8} key={i}>
                      <Link to={`/playlists/view/${plist.id}`}>
                        <Card
                          bordered={false}
                          className="playlistCard"
                          loading={this.state.loading}
                          style={{
                            borderTop: `5px solid ${plist.color_code}`
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start h-100">
                            <div className="pTitle"> {plist.title} </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center mb-3 mr-3">
                                <span style={{ color: "black" }}>
                                  Total Number of cards:
                                </span>
                                <span style={{ color: "grey" }}>
                                  {plist.array.lib_act.length}
                                </span>
                              </div>
                              <div>
                                {plist.array.intrest.length > 0 && (
                                  <LinesEllipsis
                                    text={plist.array.intrest
                                      .map(interest => interest.name)
                                      .join(", ")}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </div>
              </TabPane>
              <TabPane tab={`Archived - ${this.state.archived.length}`} key="3">
                <div className="tabscroll">
                  {this.state.archived.map((plist, i) => (
                    <Col span={8} key={i}>
                      <Link to={`/playlists/view/${plist.id}`}>
                        <Card
                          bordered={false}
                          className="playlistCard"
                          loading={this.state.loading}
                          style={{
                            borderTop: `5px solid ${plist.color_code}`
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between align-items-start h-100">
                            <div className="pTitle"> {plist.title} </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center mb-3 mr-3">
                                <span style={{ color: "black" }}>
                                  Total Number of cards:
                                </span>
                                <span style={{ color: "grey" }}>
                                  {plist.array.lib_act.length}
                                </span>
                              </div>
                              <div>
                                {plist.array.intrest.length > 0 && (
                                  <LinesEllipsis
                                    text={plist.array.intrest
                                      .map(interest => interest.name)
                                      .join(", ")}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </div>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
