import React from 'react';
import './index.css';

const Bars = (props) => (
  <div className={props.is_playing ? "bars" : "no-animation-bars"}>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
    <div className="bar"/>
  </div>
);

export default Bars;
