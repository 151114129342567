import React from 'react';
import {BarChart, Bar, Tooltip, Cell, ResponsiveContainer} from 'recharts';

const CollectionPopularityChart = (props) => {
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="pt-1 pb-1 pl-2 pr-2 bg-white rounded shadow border">
          <p className="m-0" style={{ color: '#4F4F4F', fontSize: 12 }}>{`Name: ${payload[0].payload.activityName}`}</p>
          <p className="m-0" style={{ color: '#4F4F4F', fontSize: 12 }}>{`Completed students: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const max = Math.max(...props.data.map(d => d.count));
  const min = Math.min(...props.data.map(d => d.count));

  console.log(max, min);
  
  return (
    <div style={{ width: '100%', height: 70 }}>
      <ResponsiveContainer>
        <BarChart data={props.data}>
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="count">
            {props.data.map((entry, index) => (
              <Cell cursor="pointer" fill={entry.count === max ? '#EC1A58' : entry.count === min ? "#FFC90D" : "#3EC1F0"} key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CollectionPopularityChart;