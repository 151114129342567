
import React, { Component } from 'react';

export default class BillingRedirect extends Component {
  componentDidMount() {
    if (localStorage.getItem("token") && Number(localStorage.getItem('role_id')) === 3) {

      window.location.href = (process.env.REACT_APP_LEARNER_APP + "parent/billings");
    } else {
      this.props.history.replace(`/?redirect_url=/parent/billings`)
    }
  }
  render() {
    return (
      <div>...</div>
    )
  }
}

