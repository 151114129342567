import { Button, Card, Col, Input, Row } from "antd";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ProgressiveImage from "react-progressive-image";
import styled, { keyframes } from "styled-components";
import deleteImage from "../static/card/asdelete.png";
import editImage from "../static/card/asEdit.png";
import { openNotification } from "../utils";
import FeaturedImageUpload from "./FeaturedImage";
const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`;
const ProgressiveStyle = styled.div`
  .skeleton {
    min-height: 250px;
    height: 140px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;

    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
`;
const { Meta } = Card;
const { TextArea } = Input;

const grid = 24;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "24px 16px 0 0",
  margin: `0 ${grid}px 0 0`,
  background: "#ffffff",
  ...draggableStyle
});

const getListStyle = (snapshot, isDraggingOver) => ({
  background: "#ffffff",
  display: "flex",
  // flexWrap: "wrap",
  padding: 0,
  overflow: "auto",
  overflowY: "hidden"
});

export default class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: true,
      items: [
        {
          id: "",
          content: "",
          desc: ""
        }
      ],
      newStep: true,
      stepTitle: "",
      stepDesc: "",
      fileList: []
    };
  }

  FeaturedImageUploadSuccess = value => {
    this.setState({ previewVisible: true });
    if (value) {
      // this.props.fileUpload(value[value.length - 1]);
      this.props.fileUpload(value[0]);
    }
  };

  onAddStepBtnClick = () => {
    if (this.props.image_card_steps !== "" && this.props.stepDesc !== "") {
      this.props.onClick();
      this.setState({ previewVisible: false, newStep: false }, () => {
        this.setState({ newStep: true });
      });
    } else {
      openNotification("error", "Upload an Image and also enter description.");
    }
  };

  onEditStepBtnClick = () => {
    if (this.props.image_card_steps !== "" && this.props.stepDesc !== "") {
      this.props.onEditClick();
      this.setState({ previewVisible: false });
    } else {
      openNotification("error", "Upload an Image and also enter description.");
    }
  };

  render() {
    return (
      <div>
        <div className="create-steps">
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              {this.state.newStep && (
                <FeaturedImageUpload
                  onChange={this.props.handleChange}
                  fileList={this.props.fileList}
                  multiple={false}
                  onSuccess={this.FeaturedImageUploadSuccess}
                />
              )}
              {this.props.fileList.length > 0 && this.state.previewVisible && (
                <div className="row m-0 mt-4">
                  {this.props.fileList.filter((image, i) => image.status === "done" && i === this.props.fileList.length - 1).map((data, i) => {
                    return (
                      <ProgressiveStyle className="col-12" key={i}>
                        <ProgressiveImage src={data.response} placeholder="">
                          {(src, loading) => {
                            return loading ? (
                              <div className="ProgressCard w-100 h-100 skeleton" />
                            ) : (
                              <img
                                className="col-12 p-0"
                                style={{ height: 180, width: 333, objectFit: 'cover' }}
                                src={src}
                                alt="featured images"
                              />
                            );
                          }}
                        </ProgressiveImage>
                        {/* <img className="img-fluid" src={imageUrl} alt="Steps" /> */}
                      </ProgressiveStyle>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col span={18}>
              <TextArea
                rows={6}
                name="stepDesc"
                onChange={e => {
                  this.setState({ previewVisible: true });
                  this.props.onChange(e);
                }}
                value={this.props.stepDesc}
                placeholder="Enter description"
              />
              <div className="btn-wrapper">
                {!this.props.editButtonVisibility && (
                  <div>
                    <Button
                      ghost
                      type="primary"
                      icon="plus"
                      onClick={this.onAddStepBtnClick}
                      disabled={
                        this.props.image_card_steps === "" ||
                        this.props.stepDesc === ""
                      }
                    >
                      Add step
                    </Button>
                    <Button
                      type="default"
                      icon="close"
                      className="step-cancel"
                      onClick={this.props.onCancelClick}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {this.props.editButtonVisibility && (
                  <div>
                    <Button
                      ghost
                      type="primary"
                      icon="plus"
                      onClick={this.onEditStepBtnClick}
                      disabled={
                        this.props.image_card_steps === "" ||
                        this.props.stepDesc === ""
                      }
                    >
                      Edit Step
                    </Button>
                    <Button
                      ghost
                      type="default"
                      icon="close"
                      className="step-cancel"
                      onClick={this.props.onCancelClick}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {this.props.items.map((item, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${index}steps`}
                      index={index}
                      key={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Card
                            style={{ width: 320, height: 460 }}
                            cover={
                              <div>
                                <img
                                  alt="example"
                                  src={item.image}
                                  style={{ height: "200px", width: "100%", objectFit: 'cover' }}
                                />
                                <div
                                  onClick={this.props.onDeleteClick.bind(
                                    this,
                                    index
                                  )}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    margin: 7,
                                    padding: "0 0.3rem",
                                    backgroundColor: "#FFFFFF",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    src={deleteImage}
                                    className="img-fluid"
                                    style={{ height: 15, width: 15 }}
                                    alt="examples"
                                  />
                                </div>
                                <div
                                  onClick={this.props.stepsClick.bind(
                                    this,
                                    index
                                  )}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 29,
                                    margin: 7,
                                    padding: "0 0.3rem",
                                    backgroundColor: "#FFFFFF",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    src={editImage}
                                    className="img-fluid"
                                    style={{ height: 15, width: 15 }}
                                    alt="examples"
                                  />
                                </div>
                              </div>
                            }
                          >
                            <div
                              style={{
                                width: "96px",
                                border: "1px solid #D9D9D9",
                                margin: "0 0 10px 0",
                                padding: "0 0.5rem",
                                fontSize: 14,
                                fontWeight: "bold"
                              }}
                            >
                              {`Step - ${index + 1}`}
                            </div>
                            <Meta
                              description={item.desc}
                              style={{ height: "200px", overflow: "scroll" }}
                            />
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
