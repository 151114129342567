import React from "react";
import Loading from "../../components/Loading";

const StudentRedirect = props => {
  console.log(props.location.pathname);
  props.history.push(props.location.pathname.replace("/student", "/learner"));
  return (
    <div>
      <Loading is_loading={true}></Loading>
    </div>
  );
};

export default StudentRedirect;
