import React, { Component } from "react";
// it's just an alias for `withSoundCloudAudio` but makes code clearer
import { withCustomAudio } from "react-soundplayer/addons";
import {
  PlayButton,
  Progress,
  Timer,
  VolumeControl
} from "react-soundplayer/components";
import "./index.css";

// some track meta information

const AWSSoundPlayer = withCustomAudio(props => {
  const {
    soundCloudAudio,
    streamUrl,
    trackTitle,
    currentTime,
    duration,
    autoplay,
    onChangeAutoPlay
  } = props;
  // console.log(this.props);
  // console.log(props);
  if (autoplay) {
    if (streamUrl !== "") {
      soundCloudAudio.play({ streamUrl });
      setTimeout(() => {
        onChangeAutoPlay();
      }, 1);
    }
  }
  if (props.playing !== props.statePlaying) {
    props.onChange(props.playing);
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center pl-2 pr-2 p-2 audioContainer">
        <PlayButton
          {...props}
          className={"button button-transparent button-grow"}
        />
        <Timer {...props} className={"pr-2"} />
        <Progress
          className="mt1 mb1 rounded"
          innerClassName="rounded-left"
          value={(currentTime / duration) * 100 || 0}
          {...props}
        />
        <div className="transform-180">
          <VolumeControl
            className="flex flex-center pr-2"
            buttonClassName="flex-none h4 button button-transparent button-grow rounded"
            {...props}
          />
        </div>
      </div>
    </div>
  );
});

export class Audio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      autoplay: true,
      showSound: true
    };
  }

  render() {
    return (
      <div className={this.props.src === "" ? "pointer-events-none" : ""}>
        {this.state.showSound && (
          <AWSSoundPlayer
            streamUrl={this.props.src}
            preloadType="auto"
            autoplay={this.state.autoplay}
            statePlaying={this.state.playing}
            onChangeAutoPlay={() => {
              this.setState({ autoplay: false });
            }}
            onChange={isplaying => {
              this.setState({ playing: isplaying }, () => {
                if (this.state.playing) {
                  if (this.props.onPlay) {
                    this.props.onPlay();
                  }
                } else {
                  if (this.props.onPause) {
                    this.props.onPause();
                  }
                }
              });
            }}
            onPlay={this.props.onPlay}
            onPause={this.props.onPause}
          />
        )}

        {/* <audio
          ref="audioSource"
          controls="controls"
          src={this.props.src}
          className="w-100"
          controlsList="nodownload"
          onPlay={() => {
            console.log("play");
            if (this.props.onPlay) {
              this.props.onPlay();
            }
          }}
          onPause={() => {
            console.log("pause");

            if (this.props.onPause) {
              this.props.onPause();
            }
          }}
          onEnded={() => {
            if (this.props.onEnded) {
              this.props.onEnded();
            }
          }}
        /> */}
      </div>
    );
  }
}

export default Audio;
