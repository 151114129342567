import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styled, { css, keyframes } from 'styled-components'
import {
  Col,
  Input,
  Row,
  Select,
  TimePicker,
  Radio,
  DatePicker,
  Icon,
  Button
} from 'antd'
import Moment from 'moment'
import './WorkshopDatetime.css'

const Option = Select.Option

const Tag = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  // cursor: pointer;
  opacity: 1;
  margin-bottom: 8px;

  font-weight: 600;
  line-height: 16px;
  /* min-width:80px; */
  width: 40px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  ${props =>
    props.grey &&
    css`
      color: #000000;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      align-items: center;
      background: #f2f2f2;
      border-radius: 2px;
    `}

  ${props =>
    props.daySelect &&
    css`
      color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      align-items: center;
      background: #0076ff;
      border-radius: 2px;
      width: 40px;
    `}
`

class WorkshopDateTime extends React.Component {
  constructor (props) {
    super(props)
    // this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      endsOn: '1',
      afterOnArr: [],
      isConsecutive: true,
      isNC_commonTimings: true,
      repeats_occurance: '1'
    }
  }

  onChangeDaySelect = (i, val) => {
    const { weekdays } = this.props
    weekdays[i].selected = !val
    this.props.onChangeDaySelect(weekdays)
  }

  onChangeAfter = val => {
    this.setState(
      {
        repeats_occurance: val
      },
      () => this.props.setonChangeAfter(this.state.repeats_occurance)
    )
  }

  componentDidMount = async () => {
    const afterOnArr = []
    for (let i = 1; i <= 26; i++) {
      afterOnArr.push(
        <Option key={`afterKey-${i}`} value={`${i}`}>
          {i}
        </Option>
      )
    }
    this.setState({
      afterOnArr
    })
  }

  onChange = e => {
    const val = e.target.value
    this.setState(
      {
        endsOn: val,
        repeats_occurance: val != 2 ? '1' : this.state.repeats_occurance
      },
      () => this.props.setEndsOn(this.state.endsOn, '1')
    )
  }

  render () {
    const durationSelectIndTag = (i, durationVal) => {
      return (
        <Select
          defaultValue='hr'
          value={durationVal ? durationVal : undefined}
          onChange={e => {
            this.props.onChangeIndtimings(i, e, 'durationVal')
          }}
          className='select-after'
        >
          <Option value='hr'>hr</Option>
          <Option value='mins'>mins</Option>
        </Select>
      )
    }

    const durationSelectTag = (
      <Select
        defaultValue='hr'
        value={this.props.durationVal ? this.props.durationVal : undefined}
        onChange={e => {
          this.props.onSelectChange('durationVal', e)
        }}
        className='select-after'
      >
        <Option value='hr'>hr</Option>
        <Option value='mins'>mins</Option>
      </Select>
    )

    const radioStyle = {
      display: 'block',
      lineHeight: '30px'
    }

    const occr = {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '140.8%',
      color: '#333333'
    }

    const disablestyle = {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '14px',
      color: '#333333'
    }

    return (
      <div className='row'>
        <div className='col-6'>
          <div>
            <DayPicker
              // classNames={ styles }
              month={this.props.workshopShow_date}
              className='ondatetime'
              selectedDays={this.props.selectedDays}
              onDayClick={this.props.setHandleDayClick}
              disabledDays={ this.props.isApproved ? [
                  new Date(),
                  {
                      before: new Date(),
                      after: new Date()
                  }
              ] : [
                {
                  before: new Date(Moment().add(1, 'days'))
                }
              ]}
            />
          </div>
        </div>
        <div className='col-6'>
          <div className={`${this.props.isApproved ? 'Txt-disable' : ''}`}>
            <h6 className='fonttime1'>
              {this.props.selectedDays.length > 1 &&
              this.props.isConsecutiveType
                ? `Consecutive days`
                : this.props.isConsecutiveType === false
                ? `Non-Consecutive days`
                : Moment(this.props.selectedDays[0]).format('ll')}
            </h6>
          </div>
          <div
            style={{ width: '100%' }}
            className='border-bottom mt-4 mb-4'
          ></div>

          {!this.props.isConsecutiveType && (
            <div className={`row mb-2`}>
              <div className='col-4'>
                <div className='mb-2 FontAvenirRoman text-secondary font-16'>
                  <Button
                    size='large'
                    className={
                      this.props.isNC_commonTimings ? 'nc-buttonSelected' : ``
                    }
                    onClick={() => this.props.setIsNC_commonTimings(true)}
                    disabled={this.props.isApproved}
                  >
                    Common timings
                  </Button>
                </div>
              </div>
              <div className='col-4'>
                <div className='mb-2 FontAvenirRoman text-secondary font-16'>
                  <Button
                    size='large'
                    className={
                      this.props.isNC_commonTimings ? `` : 'nc-buttonSelected'
                    }
                    onClick={() => this.props.setIsNC_commonTimings(false)}
                    disabled={this.props.isApproved}
                  >
                    Individual timings
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* Individual Timings START */}
          {!this.props.isConsecutiveType && (
            <div>
              {!this.props.isNC_commonTimings &&
                this.props.IndselectedDays.map((val, i) => {
                  return (
                    <div key={`Indu-${i}`}>
                      <h6
                        className={`fonttime1 ${
                          this.props.isApproved ? 'Txt-disable' : ''
                        }`}
                      >
                        {Moment(val.date).format('ll')}
                      </h6>
                      <div
                        style={{ width: '100%' }}
                        className='border-bottom mt-4 mb-3'
                      />
                      <div className='row mb-4'>
                        <div className='col-4'>
                          <div
                            className={`mb-2 FontAvenirRoman text-secondary font-16 
                                            ${
                                              this.props.isApproved
                                                ? 'Txt-disable'
                                                : ''
                                            }`}
                          >
                            START TIME
                          </div>
                          <TimePicker
                            placeholder='Start time'
                            size='large'
                            showNow={true}
                            use12Hours={true}
                            format='h:mm A'
                            className='mr-2'
                            onChange={(time, timeVal) =>
                              this.props.onChangeIndTimePick(i, time, timeVal)
                            }
                            value={
                              val.time ? Moment(val.time, 'HH:mm A') : undefined
                            }
                            disabled={this.props.isApproved}
                          />
                        </div>
                        <div className='col-8'>
                          <div
                            className={`mb-2 FontAvenirRoman text-secondary font-16 ${
                              this.props.isApproved ? 'Txt-disable' : ''
                            }`}
                          >
                            END TIME
                          </div>
                          <TimePicker
                            placeholder='End time'
                            size='large'
                            showNow={true}
                            use12Hours={true}
                            format='h:mm A'
                            className='mr-2'
                            onChange={(time, timeVal) =>
                              this.props.onChangeIndEndTimePick(
                                i,
                                time,
                                timeVal
                              )
                            }
                            value={
                              val.Endtime
                                ? Moment(val.Endtime, 'HH:mm A')
                                : undefined
                            }
                            disabled={this.props.isApproved}
                          />
                          {/* <Input
                                                size="large"
                                                type="text"
                                                // addonAfter={durationSelectIndTag(i, val.durationVal)}
                                                addonAfter="h"
                                                style={{width: '45%'}}
                                                className="timeInput mr-1"
                                                // id={
                                                //     this.props.truthObj_workshop_duration ? "has-error" : ""
                                                // }
                                                placeholder="Enter hour"
                                                value={val.duration}
                                                onChange={(e) =>
                                                    this.props.onChangeIndtimings(i, e.target.value, "duration")
                                                }
                                                onKeyPress={(evt) => {
                                                    if (evt.which < 48 || evt.which > 57) {
                                                    evt.preventDefault();
                                                    }
                                                }}
                                                onBlur={(e) =>
                                                    this.props.onChangeIndDuration(i, "duration", e.target.value)
                                                }
                                                />
                                                <Select placeholder="Select minutes" 
                                                    style={{width: '50%'}}
                                                    value={val.minutes}
                                                    onChange={(e) =>
                                                        this.props.onChangeIndDuration(i, "duration", e, 'minutes')
                                                    } 
                                                    size="large">
                                                        <Option value="0m">0m</Option>
                                                        <Option value="15m">15m</Option>
                                                        <Option value="30m">30m</Option>
                                                        <Option value="45m">45m</Option>
                                                    </Select> */}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}
          {/* Individual Timings END */}

          {this.props.isNC_commonTimings && (
            <div className='row mb-2'>
              <div className='col-4'>
                <div
                  className={`mb-2 FontAvenirRoman text-secondary font-16 
                ${this.props.isApproved ? 'Txt-disable' : ''}`}
                >
                  START TIME
                </div>
                <TimePicker
                  id={
                    this.props.datetime_truthObj['workshop_time']
                      ? 'has-error'
                      : ''
                  }
                  placeholder='Start time'
                  size='large'
                  showNow={true}
                  use12Hours={true}
                  format='h:mm A'
                  className='mr-2'
                  value={
                    this.props.workshop_time
                      ? Moment(this.props.workshop_time, 'HH:mm A')
                      : undefined
                  }
                  onChange={this.props.onChangeTimePick}
                  disabled={this.props.isApproved}
                />
              </div>
              <div className='col-8'>
                <div
                  className={`mb-2 FontAvenirRoman text-secondary font-16 
                ${this.props.isApproved ? 'Txt-disable' : ''}`}
                >
                  END TIME
                </div>
                <TimePicker
                  id={
                    this.props.datetime_truthObj['workshop_Endtime']
                      ? 'has-error'
                      : ''
                  }
                  placeholder='End time'
                  size='large'
                  showNow={true}
                  use12Hours={true}
                  format='h:mm A'
                  className='mr-2'
                  value={
                    this.props.workshop_Endtime
                      ? Moment(this.props.workshop_Endtime, 'HH:mm A')
                      : undefined
                  }
                  onChange={this.props.onChangeEndTimePick}
                  disabled={this.props.isApproved}
                />

                {/* <Input
                                    style={{width: '45%'}}
                                    id={
                                        this.props.datetime_truthObj['workshop_duration'] ? "has-error" 
                                        : ""
                                    }
                                    size="large"
                                    type="text"
                                    // addonAfter={durationSelectTag}
                                    addonAfter="h"
                                    className="timeInput mr-1"
                                    placeholder="Enter hour"
                                    value={this.props.workshop_duration}
                                    onChange={(e) =>
                                        this.props.onChange("workshop_duration", e.target.value)
                                    }
                                    onBlur={(e) =>
                                        this.props.onChangeDuration("workshop_duration", e.target.value)
                                    }
                                    onKeyPress={(evt) => {
                                        if (evt.which < 48 || evt.which > 57) {
                                        evt.preventDefault();
                                        }
                                    }}
                                    />
                                    <Select 
                                        placeholder="Select minutes"
                                        value={this.props.workshop_minutes} 
                                        style={{width: '50%'}}
                                        onChange={(e) =>
                                            this.props.onChangeDuration("workshop_duration", e, "workshop_minutes")
                                        } 
                                        size="large">
                                            <Option value="0m">0m</Option>
                                            <Option value="15m">15m</Option>
                                            <Option value="30m">30m</Option>
                                            <Option value="45m">45m</Option>
                                        </Select> */}
              </div>
            </div>
          )}

          <div>
            <div className='mt-4 mb-4 FontAvenirRoman text-secondary font-16'>
              <h6
                className={`fontrepeat ${
                  !this.props.isConsecutiveType ? 'fontrepeat-disable' : ''
                } ${this.props.isApproved ? 'Txt-disable' : ''}`}
              >
                REPEATS EVERY
              </h6>
            </div>

            <Radio.Group
              className='p-1 w-100'
              onChange={e =>
                this.props.onChangeRepeatsType('repeatsType', e.target.value)
              }
              value={this.props.repeatsType}
              disabled={
                !this.props.isApproved
                  ? !this.props.isConsecutiveType
                  : this.props.isApproved
              }
            >
              <Radio value={'dnr'}>Does not repeat</Radio>
              <Radio className='ml-5' value={'week'}>
                Week
              </Radio>
            </Radio.Group>

            {!this.props.isConsecutiveType && (
              <div className='mt-4 FontAvenirRoman text-secondary font-16'>
                <p style={{ color: '#333333' }}>
                  <Icon style={{ fontSize: '24px' }} type='info-circle' />
                  <span style={disablestyle}>
                    &ensp;You can’t repeat a workshop which has non consecutive
                    dates
                  </span>
                </p>
              </div>
            )}
            {this.props.repeatsType != 'dnr' && (
              <React.Fragment>
                <div className='mt-4 mb-4 FontAvenirRoman text-secondary font-16'>
                  <h6
                    className={`fontrepeat ${
                      this.props.isApproved ? 'Txt-disable' : ''
                    }`}
                    id={
                      this.props.datetime_truthObj['weekdays']
                        ? 'has-error-label'
                        : ''
                    }
                  >
                    REPEATS ON
                  </h6>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    {this.props.weekdays.map((data, index) => {
                      return (
                        <Tag
                          key={`weekDays-${index}`}
                          grey={true}
                          daySelect={data.selected}
                          className={`${
                            this.props.isApproved ? 'Txt-disable' : ''
                          }`}
                          // onClick={() => this.onChangeDaySelect(index, data.selected)}
                        >
                          {data.name}
                        </Tag>
                      )
                    })}
                  </div>
                </div>

                <div className='mt-4 mb-4 FontAvenirRoman text-secondary font-16'>
                  <h6
                    className={`fontrepeat ${
                      this.props.isApproved ? 'Txt-disable' : ''
                    }`}
                    id={
                      this.props.datetime_truthObj['endsOn']
                        ? 'has-error-label'
                        : ''
                    }
                  >
                    ENDS
                  </h6>
                </div>

                <div>
                  <Radio.Group
                    onChange={this.onChange}
                    value={this.props.endsOnVal}
                    disabled={this.props.isApproved}
                  >
                    <Radio className='mb-3' style={radioStyle} value={'1'}>
                      On
                      <DatePicker
                        id={
                          this.props.datetime_truthObj['workshop_date']
                            ? 'has-error-label'
                            : ''
                        }
                        className='ml-2'
                        size='large'
                        format='ll'
                        disabledDate={currentData =>
                          currentData.isBefore(
                            Moment(
                              this.props.selectedDays.length > 0
                                ? this.props.selectedDays.reverse()[0]
                                : new Date()
                            ).add(1, 'days')
                          )
                        }
                        placeholder='Select date'
                        onChange={this.props.onChangeDatePick}
                        disabled={this.props.endsOnVal == 2 ? true : false}
                        value={
                          this.props.workshop_date
                            ? Moment(this.props.workshop_date)
                            : Moment()
                        }
                      />
                    </Radio>
                    <Radio style={radioStyle} value={'2'}>
                      After
                      <Select
                        id={
                          this.props.datetime_truthObj['repeats_occurance']
                            ? 'has-error-label'
                            : ''
                        }
                        className='ml-2 mr-2'
                        size='large'
                        defaultValue='1'
                        onChange={e => {
                          this.onChangeAfter(e)
                        }}
                        style={{ width: 70 }}
                        disabled={this.props.endsOnVal == 1 ? true : false}
                        value={this.props.repeats_occuranceVal}
                      >
                        {this.state.afterOnArr}
                      </Select>
                      <span style={occr}>Occurrence</span>
                    </Radio>
                  </Radio.Group>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default WorkshopDateTime
