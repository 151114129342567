export const CHECK_WORKSHOP_NAME = {
  query: `
		  query checkWorkshopName($workshop_name: String!) {
			checkWorkshopName(workshop_name:$workshop_name){
				  is_available
			  }
		  }
	  `
}

export const ADD_DRAFT_WORKSHOP = {
  query: `mutation addWorkshopDraft($workshop_draft_id: ID, $workshop_id: ID,$workshop_name: String, $featured_image: [ActivityThumbImageType], $trailer_link: String, $description: String, $workshop_type: String, $workshop_amount: ID, $prerequisites: String, $maximum_participants: ID, $minimum_participants: ID, $skills_required: String, $learning_outcome: String, $reference_link: String, $status: String, $age_group: [agegroupworkshop], $workshopdatedraft: [WorkshopDateType], $workshopmaterials: [WorkshopMaterialTypes], $interest_id: [ID], $interest_categories_id: ID, $workshop_time: String, $workshop_duration: String, $workshop_date: String, $tags_id: [ID], $currency: String, $pdf_name: String, $workshop_dates: [String], $workshop_plan_id: ID, $repeats_type: String, $repeats_day: [ID], $repeats_occurance: ID, $workshop_EndDate: String, $workshop_schedule_date: [String], $workshop_duration_type: String, $workshop_nonConsecutive_type: [nonConsecutiveType], $workshop_common_timing: ID) {
		addWorkshopDraft(workshop_draft_id: $workshop_draft_id, workshop_id: $workshop_id,workshop_name: $workshop_name, featured_image: $featured_image, trailer_link: $trailer_link, description: $description, workshop_type: $workshop_type, workshop_amount: $workshop_amount, prerequisites: $prerequisites, maximum_participants: $maximum_participants, minimum_participants: $minimum_participants, skills_required: $skills_required, learning_outcome: $learning_outcome, reference_link: $reference_link, status: $status, age_group: $age_group, workshopdatedraft: $workshopdatedraft, workshopmaterials: $workshopmaterials, interest_id: $interest_id, interest_categories_id: $interest_categories_id, workshop_time: $workshop_time, workshop_duration: $workshop_duration, workshop_date: $workshop_date, tags_id: $tags_id, currency: $currency, pdf_name: $pdf_name, workshop_dates: $workshop_dates, workshop_plan_id: $workshop_plan_id, repeats_type: $repeats_type, repeats_day: $repeats_day, repeats_occurance: $repeats_occurance, workshop_EndDate: $workshop_EndDate, workshop_schedule_date: $workshop_schedule_date, workshop_duration_type: $workshop_duration_type, workshop_nonConsecutive_type: $workshop_nonConsecutive_type, workshop_common_timing: $workshop_common_timing) {
		  id
		  user_details{
			id
			name
			last_name
			email
			address
			phone_no
		  }
		  workshop_id
		  workshop_draft_id
		  workshop_name
		  featured_image {
			image_url
		  }
		  trailer_link
		  description
		  workshop_type
		  workshop_amount
		  prerequisites
		  maximum_participants
		  minimum_participants
		  skills_required
		  learning_outcome
		  reference_link
		  interest_categories_id
		  interest_id
		  workshop_interest_draft{
			id
			workshop_id
			workshop_draft_id
			interest_id
		  }
		  status
		  is_active
		  workshop_age_group{
			id
			workshop_id
			workshop__draft_id
			age_group_id
		  }
		  workshop_materials{
			id
			workshop_id
			workshop_draft_id
			name
			image
		  }
		  workshop_time
		  workshop_duration
		  workshop_date
		  currency
		  pdf_name
		  Workshop_tags{
			  id
			  name
		  }
		}
	}`
}

export const GET_ALL_WORKSHOPS = {
  query: `
	  query getWorkshopList($search:String,$filter:[Workshop_FilterInputType], $only_draft: Boolean, $page: Int,$pending_only:Boolean, $upcoming_workshop: Boolean, $rejected_workshop: Boolean, $sort: String, $past_workshop: Boolean, $attended_workshop: Boolean, $registered_workshop: Boolean){
		getWorkshopList(search:$search,filter:$filter, only_draft: $only_draft, page: $page,pending_only:$pending_only,upcoming_workshop: $upcoming_workshop, rejected_workshop: $rejected_workshop, sort: $sort, past_workshop: $past_workshop, attended_workshop: $attended_workshop, registered_workshop: $registered_workshop){
			total_count
			workshop_details{
				workshop_name
				id
				status
				workshop_date
				workshop_time
				workshop_type
				workshop_amount
				featured_image{
				  image_url
				}
				interest_details{
				  id
				  name
				}
				user_details{
					id
					name
					role_id
					profile_image
					displayName
				  }
				workshop_duration
				workshop_duration_type
				workshop_plan_id
				repeats_type
				repeats_day
				workshopDate
				workshopDates
				workshop_session
				age_group
				available_seats
				repeatDays
				InterestedChild{
					id
					fname
					lname
					username
				  }
				  RegisteredChild{
					id
					fname
					lname
					username
				  }
				  AttendedChild{
					id
					fname
					lname
					username
				  }
				  is_common_timing
				ListnonConsecutive{
					workshop_Date
					start_time
					duration
					duration_type
				}  
				Dates{
					workshop_Date
					end_date
					start_time
					duration
					duration_type
				  }
				InProgress
				workshop_registration_status
			  }
			}
	  }
	`
}

export const VIEW_DRAFT_WORKSHOP = {
  query: `
	  query getOneDraftWorkshop($workshop_draft_id: ID) {
		getAge{
			age_groups{
			  id,
			  age,
			  is_active
			}
		  }
		  getOneDraftWorkshop(id: $workshop_draft_id){
			workshop_details{
                id
                role_id
                workshop_draft_id
				workshop_id
				workshop_duration_type
                user_details{
                  id
                  role_id
                  name
                  last_name
				  address
				  displayName
                }
                workshop_name
                featured_image{
				  image_url
				  position
                }
                trailer_link
                description
                workshop_type
                workshop_amount
                prerequisites
                maximum_participants
                minimum_participants
                skills_required
                learning_outcome
                reference_link
                status
                is_active
				workshop_time
				workshop_EndTime
                workshop_duration
                workshop_date
                currency
                pdf_name
				workshop_Date
				workshopDates
                workshop_plan_id
                repeats_type
                end_date
				repeats_day
				repeatDays
                occurance
                no_of_days_workshop
                duration
				workshop_schedule_date
				available_seats
				age_group
				workshop_session
				workshopDate
				is_common_timing
				ListnonConsecutive{
					workshop_Date
					start_time
					duration
					duration_type
				}
				Dates{
					workshop_Date
					end_date
					start_time
					end_time
					duration
					duration_type
				  }
				workshop_registration_status
		  }
			Age_group {
			id
			age_group_id
			is_active
			age
			}
			Materials_required {
				id
				image
				name
				position
			}
			Workshop_tags {
				id
				name
			}
			notifications{
				id
				notification_id
				status
				comments
			  }
		  interest_details {
			interest_categories_id
			interest_categories_name
			interests{
			  id
			  name
			}
		  }
		  }
	  }
	`
}

export const SEND_WORKSHOP_FOR_APPROVAL = {
  query: `
		  mutation sendWorkshopApproval($workshop_draft_id: ID){
			  sendWorkshopApproval(workshop_draft_id: $workshop_draft_id){
				  id
				  status
				  message
			  }
		  }
	  `
}

export const APPROVE_WORKSHOP = {
  query: `
	  mutation approveWorkshop(
		$workshop_draft_id: ID, 
		$workshop_id: ID, 
		$workshop_name: String, 
		$featured_image: [ActivityThumbImageType], 
		$trailer_link: String, 
		$description: String, 
		$workshop_type: String, 
		$workshop_amount: ID, 
		$prerequisites: String, 
		$maximum_participants: ID, 
		$minimum_participants: ID, 
		$skills_required: String, 
		$learning_outcome: String, 
		$reference_link: String, 
		$age_group: [agegroupworkshop], 
		$workshopmaterials: [WorkshopMaterialTypes], 
		$interest_id: [ID], 
		$interest_categories_id: ID, 
		$workshop_interest: [WorkshopInterestTypes], 
		$workshop_time: String, 
		$workshop_duration: String, 
		$workshop_date: String, 
		$tags_id: [ID], 
		$currency: String, 
        $pdf_name: String,
        $workshop_dates: [String], 
        $workshop_plan_id: ID, 
        $repeats_type: String, 
        $repeats_day: [ID], 
        $repeats_occurance: ID, 
        $workshop_EndDate: String, 
		$workshop_schedule_date: [String],
		$workshop_duration_type: String,
		$workshop_nonConsecutive_type: [nonConsecutiveType],
		$workshop_common_timing: ID,
		$primary_host: String,
		$workshop_registration_status: Boolean
	  ){
		approveWorkshop(workshop_draft_id: $workshop_draft_id, workshop_id: $workshop_id, workshop_name: $workshop_name, featured_image: $featured_image, trailer_link: $trailer_link, description: $description, workshop_type: $workshop_type, workshop_amount: $workshop_amount, prerequisites: $prerequisites, maximum_participants: $maximum_participants, minimum_participants: $minimum_participants, skills_required: $skills_required, learning_outcome: $learning_outcome, reference_link: $reference_link, age_group: $age_group, workshopmaterials: $workshopmaterials, interest_id: $interest_id, interest_categories_id: $interest_categories_id, workshop_interest: $workshop_interest, workshop_time: $workshop_time, workshop_duration: $workshop_duration, workshop_date: $workshop_date, tags_id: $tags_id, currency: $currency, pdf_name: $pdf_name, workshop_dates: $workshop_dates, workshop_plan_id: $workshop_plan_id, repeats_type: $repeats_type, repeats_day: $repeats_day, repeats_occurance: $repeats_occurance, workshop_EndDate: $workshop_EndDate, workshop_schedule_date: $workshop_schedule_date, workshop_duration_type: $workshop_duration_type, workshop_nonConsecutive_type: $workshop_nonConsecutive_type, workshop_common_timing: $workshop_common_timing, primary_host: $primary_host, workshop_registration_status: $workshop_registration_status){
			workshop_details{
				id
			  }
			  interest_details{
				interests{
				  id
				  name
				}
				interest_categories_name
				interest_categories_id
			  }
			  Age_group{
				id
				age
				age_group_id
				is_active
			  }
			  Materials_required{
				id
				workshop_id
				workshop_draft_id
				name
				image
			  }
			  Workshop_tags{
				id
				name
			  }
		  }
	  }
	`
}

export const EDIT_WORKSHOP = {
	query: `mutation editWorkshop(
		$workshop_id: ID,
		$workshop_name: String,
		$featured_image: [ActivityThumbImageType],
		$trailer_link: String,
		$description: String,
		$prerequisites: String,
		$maximum_participants: ID,
		$minimum_participants: ID,
		$skills_required: String,
		$learning_outcome: String,
		$reference_link: String,
		$status: String,
		$age_group: [agegroupworkshop],
		$workshopmaterials: [WorkshopMaterialTypes],
		$interest_id: [ID],
		$tags_id: [ID],
		$pdf_name: String,
		$primary_host: String,
		$workshop_registration_status: Boolean
	  ) {
		editWorkshop(
		  workshop_id: $workshop_id,
		  workshop_name: $workshop_name,
		  featured_image: $featured_image,
		  trailer_link: $trailer_link,
		  description: $description,
		  prerequisites: $prerequisites,
		  maximum_participants: $maximum_participants,
		  minimum_participants: $minimum_participants,
		  skills_required: $skills_required,
		  learning_outcome: $learning_outcome,
		  reference_link: $reference_link,
		  status: $status,
		  age_group: $age_group,
		  workshopmaterials: $workshopmaterials,
		  interest_id: $interest_id,
		  tags_id: $tags_id,
		  pdf_name: $pdf_name,
		  primary_host: $primary_host,
		  workshop_registration_status: $workshop_registration_status
		) {
		workshop_details {
		  id
		  workshop_draft_id
		  workshop_registration_status
		}
		}
	  }`
}

export const UPDATE_WORKSHOP_REGISTRATION = {
	query: `mutation updateWorkshopRegistration($workshop_id: ID, $registration_status: Boolean) {
		updateWorkshopRegistration(workshop_id: $workshop_id, registration_status: $registration_status) {
		  workshop_id
		  workshop_name
		  regitration_status
		}
	  }`
}

export const REJECTED_WORKSHOP = {
  query: `
	  mutation rejectWorkshop($workshop_draft_id: ID, $comments: String) {
		rejectWorkshop(workshop_draft_id: $workshop_draft_id, comments: $comments) {
			workshop_details {
			  id
			  workshop_name
			  user_id
              status
              notification_details {
                id
                notification_id
                comments
              }
			}
		  }
	  }`
}

export const VIEW_WORKSHOP = {
  query: `
	  query getOneWorkshop($id: ID, $workshop_draft_id: ID) {
		getAge{
			age_groups{
			  id,
			  age,
			  is_active
			}
		  }
		  getOneWorkshop(id: $id, workshop_draft_id: $workshop_draft_id) {
			workshop_details{
				id
				role_id
				workshop_duration_type
				workshop_draft_id
				workshop_id
				user_details{
				  id
				  role_id
				  name
				  profile_image
				  bio
				  displayName
				}
				workshop_name
				featured_image{
				  image_url
				  type
				  position
				}
				trailer_link
				description
				workshop_type
				workshop_amount
				prerequisites
				maximum_participants
				minimum_participants
				skills_required
				learning_outcome
				reference_link
				status
				is_active
				workshop_time
				workshop_EndTime
				workshop_duration
				workshop_date
				currency
                pdf_name
				workshop_Date
				workshopDates
                workshop_plan_id
                repeats_type
                end_date
				repeats_day
				repeatDays
                occurance
                no_of_days_workshop
                duration
				workshop_schedule_date
				available_seats
				age_group
				workshop_session
				workshopDate
				InterestedChild{
					id
					fname
					lname
					username
				  }
				  RegisteredChild{
					id
					fname
					lname
					username
					is_attended
					registration_type
				  }
				is_cart
				is_common_timing
				ListnonConsecutive{
					workshop_Date
					start_time
					duration
					duration_type
				}
				Dates{
					workshop_Date
					end_date
					start_time
					end_time
					duration
					duration_type
				  }
				zoom_meeting
				InProgress
				primary_host
				workshop_registration_status
			  }
			  registered_student_count
				  interest_details{
				interest_categories_id
				interest_categories_name
				interests{
				  id
				  name
				}
			  }
				  Age_group{
				id
				workshop_id
				workshop_draft_id
				age_group_id
				age_group_difficulty_id
				age_group_difficulty
				age
				is_active
			  }
			   Materials_required{
				id
				workshop_id
				workshop_draft_id
				name
				image
				position
			  }   
				 Workshop_tags{
				id
				name
			  } 
			}
	  }
	`
}

export const CHECK_AGE_GROUP = {
	query: `query checkAgeGroup($workshop_id: ID, $age_group_id: ID) {
		checkAgeGroup(workshop_id: $workshop_id, age_group_id: $age_group_id) {
		  AgeStatus
		}
	  }`
}

export const GET_ALL_USER = {
  query: `
	  query getAllUser{
		getAllUser{
			users_result {
		  id
		  role_id
		  name
		  last_name
		  displayName
		  email
		  password
		  is_changed
		  token
		  forgot_password
		  bio
		  profile_image
		  phone_no
		  address
		  latitude
		  longitude
		  is_active
		  login_count
		  signupType
		  loginType
		  status
		  comments
		  createdAt
		  country
		  city
		}
		}
	  }`
}

// export const GET_REGISTERED_STUDENTS_LIST = {
//   query: `
// 	  query registeredStudentList($workshop_id: ID, $page: Int) {
// 		registeredStudentList(workshop_id: $workshop_id, page: $page){
// 			registered_list{
// 		   id
// 		   fname
// 		   lname
// 		   username
// 		   dob
// 		   age
// 			 parent_details{
// 		   id
// 		   name
// 		   last_name
// 		   display_name
// 		   email
// 		   phone_no
// 		   address
// 		   country
// 		 }
// 		   registration_status
// 		 }
// 		 workshop_name
// 		 total_count
// 		 }
// 	  }`
// }

export const ADD_EDIT_FEATURED_WORKSHOP = {
  query: `
		mutation addFeaturedWorkshop($featured_workshop_id: ID, $workshop_title: String, $workshop_id: [featuredworkshoptype]) {
			addFeaturedWorkshop(
				featured_workshop_id: $featured_workshop_id,
				workshop_title: $workshop_title
				workshop_id: $workshop_id
			  ) {
				featured_workshops {
				  id
				  workshop_title
				  user_id
				}
			  }
		}`
}

export const GET_FEATURED_WORKSHOP = {
  query: `
		  query getFeaturedWorkshop($filter: [Workshop_FilterInputType]) {
			getFeaturedWorkshop(filter: $filter) {
				id
				workshop_title
				workshop_details {
				  id
				  workshop_draft_id
				  workshop_id
					user_details{
				id
				name
				profile_image
				bio
				phone_no
				address
				role_id
			  }
				  workshop_name
					featured_image{
				image_url
			  }
				  trailer_link
				  description
				  workshop_type
				  workshop_amount
				  prerequisites
				  maximum_participants
				  minimum_participants
				  skills_required
				  learning_outcome
				  reference_link
				  status
				  is_active
					interest_details{
				id
				name
			  }
				  interest_categories_id
					workshop_age_group{
				id
				age_group_id
				is_active
			  }
					workshop_materials{
				id
				workshop_id
				name
				image
			  }
				  workshop_time
				  workshop_duration
				  workshop_date
				  workshopDate
				  workshopDates
				  workshop_plan_id
				  repeats_type
				  repeats_day
				  occurance
				  no_of_days_workshop
				  end_date
				  workshop_schedule_date
				  repeatDays
				  available_seats
				  age_group
				  workshop_session
				  ListnonConsecutive{
					workshop_Date
					start_time
					duration
					duration_type
					}
				Dates{
					workshop_Date
					start_time
					duration
					duration_type
					}
				is_common_timing
				}
				total_count
				age_groups {
				  id
				  age_group_id
				  is_active
				}
			  }
		  }`
}

export const WORKSHOP_FILTER_QUERY = {
  query: `
	query getWorkshopFilterData($isPending: Boolean) {
		getWorkshopFilterData(isPending: $isPending) {
		interest_details{
		id,
		name
		},
		difficulty_details{
		age_groups{
			id,
			age
		}
		}
	}
	}
	`
}

export const GET_UPCOMING_WORKSHOPS = {
  query: `
		  query upcomingWorkshop($search: String) {
			upcomingWorkshop(search: $search) {
				id
				workshop_title
				 workshop_details{
				id
				workshop_draft_id
				workshop_id
				workshop_name
				featured_image{
				  image_url
				}
				trailer_link
				description
				workshop_type
				workshop_amount
				prerequisites
				maximum_participants
				minimum_participants
				skills_required
				learning_outcome
				reference_link
				status
				is_active
				interest_categories_id
				workshop_time
				workshop_duration
				workshop_date
				workshopDate
				workshopDates
				workshop_plan_id
				repeats_type
				repeats_day
				occurance
				no_of_days_workshop
				end_date
				workshop_schedule_date
				repeatDays
				Dates{
					workshop_Date
					start_time
					duration
					duration_type
				  }
			  }
				total_count
				age_groups{
				id
				age_group_id
				is_active
			  }
			  }
		  }`
}

export const REGISTER_WORKSHOP = {
  query: `
		mutation registerWorkshopV2($workshop_id: ID, $registration_details: [RegisterWorkshopType]) {
			registerWorkshopV2(workshop_id: $workshop_id, registration_details: $registration_details){
				error
				msg
				is_updated
			  }
		}`
}

export const UPDATE_WORKSHOP_CART = {
  query: `
			mutation updateWorkshopCart($cart_id: ID, $isSaveLater: ID, $isDelete: Boolean) {
				updateWorkshopCart(cart_id: $cart_id, isSaveLater: $isSaveLater, isDelete: $isDelete){
					error
					msg
					is_updated
				  }
			}`
}

export const UPDATE_STUDENT_WORKSHOP_CART = {
  query: `
			mutation updateStudentWorkshopCart($cart_id: ID, $registration_details: [RegisterWorkshopType], $workshop_id: ID) {
				updateStudentWorkshopCart(cart_id: $cart_id, registration_details: $registration_details, workshop_id: $workshop_id){
					error
					msg
					is_updated
					}
			}`
}

export const PAYMENT_WORKSHOP_CART = {
  query: `
			mutation paymentWorkshopCart($cart_id: [ID], $amount: Float) {
				paymentWorkshopCart(cart_id: $cart_id, amount: $amount){
					error
					msg
					is_updated
					data{
						id
						longurl
						redirect_url
					  }
				  }
			}`
}

export const PAYMENT_WORKSHOP_CART_VERIFY = {
	query: `
			  query paymentWorkshopCartVerify($payment_request_id: String) {
				paymentWorkshopCartVerify(payment_request_id: $payment_request_id){
					  error
					  msg
					  is_updated
					}
			  }`
  }

export const PAYMENT_WORKSHOP_CART_VERIFY_RAZOR = {
  query: `query paymentWorkshopCartVerify($payment_request_id: String,$order_id: String){
  paymentWorkshopCartVerify(payment_request_id: $payment_request_id,order_id: $order_id){
    error
    msg
    data{
      id
      order_id
      method
      captured
      amount
      currency
      status
    }
  }
}`,
};

export const GET_CART = {
  query: `
			query getCart {
				getCart{
					ParentDetails{
						id
						name
						last_name
						displayName
						email
						address
						phone_no
						role_id
						profile_image
						bio
						}
					interest_details{
					interest_categories_id
					interest_categories_name
					 interests{
					id
					name
				  }
				  }
					Age_group{
					id
					workshop_id
					workshop_draft_id
					age_group_id
					age_group_difficulty_id
					age_group_difficulty
					age
					is_active
				  }
					Materials_required{
					id
					workshop_id
					workshop_draft_id
					name
					image
				  }
					Workshop_tags{
					id
					name
				  }
					registered_student_count
					is_registered
					cart_count
					cart{
					id
					 workshop_details{
					cart_age_group{
						id
						age_group_id
						is_active
						}
					ListnonConsecutive{
						workshop_Date
						start_time
						duration
						duration_type
						}
						Dates{
							workshop_Date
							end_date
							start_time
							duration
							duration_type
						  }
						  repeatDays
					is_common_timing
					id
					role_id
					workshop_draft_id
					workshop_id
						user_details{
					id
					name
					last_name
					displayName
					email
					address
					phone_no
					role_id
					profile_image
					bio
				  }
					workshop_name
					featured_image{
					image_url
				  }
					trailer_link
					description
					workshop_type
					workshop_amount
					prerequisites
					maximum_participants
					minimum_participants
					skills_required
					learning_outcome
					reference_link
					status
					workshop_duration_type
					is_active
					workshop_time
					workshop_duration
					workshop_date
					currency
					pdf_name
					workshop_Date
					workshopDates
					workshop_plan_id
					repeats_type
					end_date
					repeats_day
					repeatDays
					occurance
					no_of_days_workshop
					duration
					workshop_schedule_date
					available_seats
					age_group
					workshop_session
					workshopDate
					workshop_registration_status
					InProgress
				  }
					parent_id
					learners{
						id
						fname
						lname
						username
						is_attended
						registration_type
					  }
					total_workshop
					total_amount
					payment_resonse
					txn_id
					status
					save_for_later
					is_delete
					is_Edit
				  }
					 save_for_later{
					id
					 workshop_details{
						cart_age_group{
							id
							age_group_id
							is_active
							}
					id
					role_id
					workshop_draft_id
					workshop_id
						user_details{
					id
					name
					last_name
					displayName
					email
					address
					phone_no
					role_id
					profile_image
					bio
				  }
					workshop_name
					featured_image{
					image_url
				  }
				  Dates{
					workshop_Date
					end_date
					start_time
					duration
					duration_type
				  }
				  repeatDays
					trailer_link
					description
					workshop_type
					workshop_amount
					prerequisites
					maximum_participants
					minimum_participants
					skills_required
					learning_outcome
					reference_link
					status
					workshop_duration_type
					is_active
					workshop_time
					workshop_duration
					workshop_date
					currency
					pdf_name
					workshop_Date
					workshopDates
					workshop_plan_id
					repeats_type
					end_date
					repeats_day
					repeatDays
					occurance
					no_of_days_workshop
					duration
					workshop_schedule_date
					available_seats
					age_group
					workshop_session
					workshopDate
					workshop_registration_status
					InProgress
				  }
					parent_id
					learners{
						id
						fname
						lname
						username
						is_attended
						registration_type
					  }
				  
					total_workshop
					total_amount
					payment_resonse
					txn_id
					status
					save_for_later
					is_delete
					is_Edit
				  }
					save_for_later_count
					GST
					Price
				  }
			}`
}

export const GET_REGISTERED_STUDENTS_LIST = {
  query: `
	query registeredStudentListV2($workshop_id: ID, $page: Int) {
	registeredStudentListV2(workshop_id: $workshop_id, page: $page) {
		workshop_name
		workshop_details{
			id
			workshop_name
			user_details{
				id
				name
				last_name
				displayName
				email
				address
				phone_no
				role_id
				profile_image
				bio
			  }
			  zoom_meeting
			  primary_host
		}
		final_list {
		  registered_list {
			id
			fname
			lname
			username
			dob
			age
			parent_details {
			  id
			  name
			  last_name
			  email
			  phone_no
			  address
			  country
			  age
			  status
			}
			registration_status
			registration_type
			is_attended
		  }
		  session
		  start_time
		  end_time
		  is_started
		  session_date
		  total_count
		}
		}
	  }`
}

export const UPDATE_WORKSHOP_ATTENDANCE = {
	query: `
			  mutation updateWorkshopAttendence($date: String, $session: Int, $start_time: String, $end_time: String, $learner_type: String, $workshop_id: ID, $learner_id: ID, $attendance: Boolean) {
				updateWorkshopAttendence(
					date: $date
					workshop_id: $workshop_id
					learner_id: $learner_id
					learner_type: $learner_type
					session: $session
					start_time: $start_time
					end_time: $end_time
					attendance: $attendance
				  ) {
					workshop_id
					attendence_status
				  }
			  }`
}
