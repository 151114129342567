import { Card, Checkbox, Col, Collapse, Input, Radio, Row, Slider } from 'antd'
import React, { Component } from 'react'
import './filter.css'
import ChildProfileEmpty from '../../static/workshop/child_profile_empty.svg'

const Img = require('react-image')

const Search = Input.Search
const Panel = Collapse.Panel

export default class WorkshopFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchText: '',
      ShowInterest: false
    }
  }

  onSelect () {}

  onOpen () {}

  onClose () {}

  onSearchChange = e => {
    this.setState({ ShowInterest: false })
    this.setState({ searchText: e.target.value })
  }

  onInterestChange = (key, index, e) => {
    const interestSelection = this.props.interestData
      .map((data, index) => {
        if ('' + data.id === '' + e.target.value) return index + 1
      })
      .filter(data => data)
    this.props.onCheckChange(key, interestSelection[0] - 1, e.target.checked)
  }

  onChange = (key, index, e) => {
    this.props.onCheckChange(key, index, e.target.checked)
  }

  render () {
    return (
      <Card
        className={`${
          this.props.role_id == 3 ? `ParentFilterCard` : `FilterCard`
        } rounded vh-100 overflow-scroll p-0`}
      >
        <div className='mt-2 mb-2 border text-black border-left-0 border-right-0 border-top-0'>
          <div className='p-3 FontAvenirMedium font-19 d-flex justify-content-between align-items-center'>
            <span style={{ color: '#000000' }}>Filters</span>
            {(this.props.ageData.filter(data => data.is_active).length > 0 ||
              this.props.interestData.filter(data => data.is_active).length >
                0) && (
              <span
                className='FontAvenirMedium font-12 color-blue-6 pointer-cursor'
                onClick={this.props.clearFilter}
              >
                Clear All
              </span>
            )}
          </div>
        </div>
        <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
          {this.props.showWorkshopFilter && (
            <Panel header='SHOW' key='1'>
              {this.props.isContentCreator && (
                <Radio.Group
                  onChange={this.props.onChangeFilterShowRadio}
                  value={this.props.filterShowRadio}
                >
                  <Radio
                    className='pt-2 pb-2'
                    value='Upcoming workshops'
                    id={1}
                  >
                    Upcoming workshops
                  </Radio>
                  {this.props.role_id != 3 && (
                    <Radio className='pt-2 pb-2' value='Past workshops' id={2}>
                      Past workshops
                    </Radio>
                  )}
                  {this.props.role_id != 3 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Pending Approval'
                      id={3}
                    >
                      Pending Approval
                    </Radio>
                  )}
                  {this.props.role_id != 1 && this.props.role_id != 3 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Drafts & Rejected'
                      id={4}
                    >
                      Drafts & Rejected
                    </Radio>
                  )}
                  {this.props.role_id == 1 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Rejected workshops'
                      id={5}
                    >
                      Rejected workshops
                    </Radio>
                  )}
                  {this.props.role_id == 1 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Featured workshops'
                      id={6}
                    >
                      Featured workshops
                    </Radio>
                  )}
                  {this.props.role_id == 3 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Attended workshops'
                      id={7}
                    >
                      Attended workshops
                    </Radio>
                  )}
                  {this.props.role_id == 3 && (
                    <Radio
                      className='pt-2 pb-2'
                      value='Registered workshops'
                      id={8}
                    >
                      Registered workshops
                    </Radio>
                  )}
                </Radio.Group>
              )}
            </Panel>
          )}

          <Panel header='PRICE' key='5'>
            {
              // this.props.studentsFilter.map((data, index) => {
              // return (
              <Radio.Group
                value={this.props.price_type}
                onChange={e => this.onChange('priceFilter', e.target.value, e)}
              >
                <div className='d-flex flex-column'>
                  <Radio className='pt-2 pb-2' value='all'>
                    All
                  </Radio>
                  <Radio className='pt-2 pb-2' value='free'>
                    Free
                  </Radio>
                  <Radio className='pt-2 pb-2' value='Paid'>
                    Paid
                  </Radio>
                </div>
              </Radio.Group>
              // )
              // })
            }
          </Panel>

          {this.props.role_id == 3 && this.props.studentsFilter.length && (
            <Panel header='LEARNERS' key='4'>
              <Row>
                {this.props.studentsFilter.map((data, index) => {
                  return (
                    <Col key={`checkbox`} span={24}>
                      <Checkbox
                        className='pt-2 pb-2'
                        checked={data.is_active}
                        value={data.id}
                        onChange={e =>
                          this.onChange('studentsFilter', index, e)
                        }
                      >
                        {data && data.type == 'child' && (
                          <span>
                            <img
                              className='ChildrenImgFilter'
                              src={
                                data.student_details
                                  ? data.student_details.profile_imageUrl
                                    ? data.student_details.profile_imageUrl
                                    : ChildProfileEmpty
                                  : ChildProfileEmpty
                              }
                              alt='Workshops'
                              onError={e => {
                                e.target.onerror = null
                                e.target.src = ChildProfileEmpty
                              }}
                            />
                            <span className='childFilterText'>
                              {data.student_details.username}
                            </span>
                          </span>
                        )}
                        {data && data.type == 'parent' && (
                          <span>
                            <img
                              className='ChildrenImgFilter border-0'
                              src={ChildProfileEmpty}
                              alt='Workshops'
                              onError={e => {
                                e.target.onerror = null
                                e.target.src = ChildProfileEmpty
                              }}
                            />
                            <span className='childFilterText'>
                              {data.first_name ? `Me! (${data.first_name})` : ''}
                            </span>
                          </span>
                        )}
                      </Checkbox>
                    </Col>
                  )
                })}
              </Row>
            </Panel>
          )}

          <Panel header='AGE' key='2'>
            <Row>
              {this.props.ageData.map((data, index) => {
                return (
                  <Col key={`checkbox-${index}`} span={24}>
                    <Checkbox
                      onChange={e =>
                        this.onChange(this.props.ageDataKey, index, e)
                      }
                      className='pt-2 pb-2'
                      checked={data.is_active}
                      value={data.id}
                    >
                      {data.age}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Panel>

          <Panel header='INTEREST' key='3'>
            <Search
              className='mb-3'
              placeholder='Search'
              onChange={this.onSearchChange}
            />
            <Row>
              {this.state.searchText === ''
                ? this.props.interestData.map((data, index) => {
                    if (index < 3 || this.state.ShowInterest) {
                      return (
                        <Col key={`checkbox-${index}`} span={24}>
                          <Checkbox
                            onChange={e => {
                              this.onInterestChange(
                                this.props.interestDataKey,
                                index,
                                e
                              )
                            }}
                            className='pt-2 pb-2'
                            checked={data.is_active}
                            value={data.id}
                          >
                            {data.name}
                          </Checkbox>
                        </Col>
                      )
                    }
                  })
                : this.props.interestData
                    .filter(data =>
                      data.name
                        .toLowerCase()
                        .trim()
                        .includes(this.state.searchText.toLowerCase().trim())
                    )
                    .map((data, index) => {
                      if (index < 3 || this.state.ShowInterest) {
                        return (
                          <Col key={`checkbox-${index}`} span={24}>
                            <Checkbox
                              onChange={e => {
                                this.onInterestChange(
                                  this.props.interestDataKey,
                                  index,
                                  e
                                )
                              }}
                              className='pt-2 pb-2'
                              checked={data.is_active}
                              value={data.id}
                            >
                              {data.name}
                            </Checkbox>
                          </Col>
                        )
                      }
                    })}
              <div className='text-info FontAvenirMedium cursor-pointer'>
                <div
                  onClick={() => {
                    this.setState({
                      ShowInterest: !this.state.ShowInterest
                    })
                  }}
                >
                  {this.state.ShowInterest
                    ? `Show less`
                    : this.props.interestData.filter(data =>
                        data.name
                          .trim()
                          .toLowerCase()
                          .includes(this.state.searchText.trim().toLowerCase())
                      ).length > 3 && (
                        <div>
                          {`${this.props.interestData.filter(data =>
                            data.name
                              .trim()
                              .toLowerCase()
                              .includes(
                                this.state.searchText.trim().toLowerCase()
                              )
                          ).length - 3} `}
                          More
                        </div>
                      )}
                </div>

                {/* <div
                  onClick={() => {
                    this.setState({
                      ShowInterest: !this.state.ShowInterest
                    })
                  }}
                >
                  {this.state.ShowInterest
                    ? `Show less`
                    : this.props.interestData &&
                      this.props.interestData.filter(data =>
                        data.name
                          .trim()
                          .toLowerCase()
                          .includes(this.state.searchText.trim().toLowerCase())
                      ).length > 0 &&
                      `${this.props.interestData.filter(data =>
                        data.name
                          .trim()
                          .toLowerCase()
                          .includes(this.state.searchText.trim().toLowerCase())
                      ).length - 3} More`}
                </div> */}
              </div>
            </Row>
          </Panel>
        </Collapse>
      </Card>
    )
  }
}
