import React from "react";
import journal_audio from "../../static/journal/journal_audio.svg";
import journal_captured from "../../static/journal/journal_captured.svg";
import journal_doodle from "../../static/journal/journal_doodle.svg";
import journal_notes from "../../static/journal/journal_notes.svg";
import journal_upload from "../../static/journal/journal_upload.svg";

const JournalEntryIcon = (props) => {
	return (
		<React.Fragment>
			{props.type === "audio" && (
				<img
					src={journal_audio}
					alt="journal icon"
				/>
			)}
			{props.type === "captured" && (
				<img
					src={journal_captured}
					alt="journal icon"
				/>
			)}
			{props.type === "doodle" && (
				<img
					src={journal_doodle}
					alt="journal icon"
				/>
			)}
			{props.type === "notes" && (
				<img
					src={journal_notes}
					alt="journal icon"
				/>
			)}
			{props.type === "uploaded" && (
				<img
					src={journal_upload}
					alt="journal icon"
				/>
			)}
		</React.Fragment>
	);
};

export default JournalEntryIcon;
