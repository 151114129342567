// import gql from "graphql-tag";

export const GET_DATA_FOR_SELECTION = {
  query: `
    query getDataForSelection($filter: ID!) {
      getAllActivity(filter: $filter) {
        activity_details {
          id
          name
          status
        }
      }
      getAllLibrary(filter: $filter) {
        library_details {
          id
          name
          status
        }
      }
    }
  `
};

export const GET_ALL_ACTIVITY_COLLECTION = {
  query: `
    query getAllCollecion($sort:String!, $search:String!, $filter:[FilterInputType]){
      getAllCollection(sort:$sort, search: $search, filter: $filter){
        inprogress_collection{
          id
          name
          is_publish
        }
        published_collection {
          id
          name
          is_publish
        }
      }
    }
  `
};

export const GET_ALL_LIBRARY_COLLECTION = {
  query: `
    query getAllLibraryCollection($filter:[FilterInputType]) {
      getAllLibraryCollection(filter: $filter){
        inprogress_libcollection {
          id
          name
          is_publish
          default_libraries_id
        }
        published_libcollection {
          id
          name
          is_publish
          default_libraries_id
        }
      }
    }
  `
};

export const GET_ACTIVITY = {
  query: `
    query getAllActivity($collections_id: ID!, $filter: [FilterInputType]){
      getAllActivity( collections_id:$collections_id, filter: $filter)   {
        activity_details {
          name
          status
          id
          activity_state
        }
        collection_details{
          id
          name
          is_publish
        }
      }
    }
  `
};

export const GET_LIBRARY = {
  query: `
    query getAllLibrary($libcollections_id: ID!, $filter: [FilterInputType]){
     getAllLibrary( libcollections_id:$libcollections_id, filter: $filter)   {
       library_details {
        id,
        default_libraries_id
        name,
        status,
      }
    }
  }
`
};

export const GET_ASSOCIATED_DATA = {
  query: `
    query getListLibIntrest($array:[arrayInputtype]){
      getListLibIntrest(array:$array)   {
        lib_act{
          id,
          name,
          order,
          type,
          status,
          age_group{
            age,
            id
          }
          thumb_image{
            image_url
          }
        }
        intrest {
          id
          name
        }
        data_point {
          id
          name
        }
      }
    }
  `
};

export const GET_AGE_DATA = {
  query: `
    query getAge{
      getAge{
        age_groups {
          id
          age
          is_active
        }
      }
    }
  `
};

export const CREATE_PLAYLIST = {
  query: `
    mutation addPlaylist($title: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype] ) {
      addPlayList(title: $title, source_text: $source_text, source_link:$source_link, age_group: $age_group, array: $array ) {
        id
        title
        source_link
        source_text
      }
    }
  `
};

export const EDIT_PLAYLIST = {
  query: `
    mutation editPlaylist($title: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype], $id: ID! ) {
      editPlaylist(title: $title, source_text: $source_text, source_link:$source_link, age_group: $age_group, array: $array, id: $id ) {
        id
        title
        source_link
        source_text
      }
    }
  `
};

export const GET_ALL_PLAYLIST = {
  query: `
    query getAllPlaylist($interest_ids: [Int], $sort: String!, $search : String!) {
      getAllPlaylist (interest_ids: $interest_ids, sort: $sort, search: $search){
        playlists {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
        unpublised {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
        archive {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
      }
    }
  `
};

export const GET_ONE_PLAYLIST = {
  query: `
    query getOnePlaylist($id: ID!) {
      getOnePlaylist(id: $id) {
        id
        user_id
        title
        source_text
        source_link
        is_archive
        is_publish
        age_group {
          id
          age
        }
        array {
          lib_act {
            id
            name
            type
            order
            status
            thumb_image{
              image_url
            }
            age_group {
              id
              age
            }
          }
          intrest {
            id
            name
          }
          data_point {
            id
            name
          }
        }
      }
    }
  `
};

export const PUBLISH_PLAYLIST = {
  query: `
    mutation publishPlaylist($p_id: Int!) {
      publishPlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `
};

export const UNPUBLISH_PLAYLIST = {
  query: `
    mutation unpublishPlaylist($p_id: Int!) {
      unpublishPlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `
};

export const ARCHIVE_PLAYLIST = {
  query: `
    mutation archivePlaylist($p_id: Int!) {
      archivePlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `
};
