export const GET_REPORT_DATE = {
  query: `
    query getReportsDate {
      getReportsDate {
        cards_cronDate
        collply_cronDate
      }
    }
  `
};

export const GET_ONBOARDING_JOURNEY = {
  query: `
    query getParentOnboardsStudReports {
      getParentOnboardsStudReports {
        parent_onboarded_details {
          parent_onboard_count
          student_onboard_count
          student_notOnboard_count
          student_logged_count
          student_notlogged_count
          students_CardsView
          students_not_start_learn_count
          students_start_learn_count
          students_pastMonth_count
        }
        student_onboarded_details {
          student_onboard_count
          parent_onboard_count
          parent_notOnboard_count
          student_logged_count
          student_notlogged_count
          students_CardsView
          students_not_start_learn_count
          students_start_learn_count
          students_pastMonth_count
          student_parentIn_logged
          student_parentIn_Notlogged
          student_parentNot_logged
          student_parentNot_Notlogged
        }
      }
    }
  `,
};

export const GET_LOGIN_ACTIVITY = {
  query: `
    query getStudentLoginActivity($date: String!){
      getStudentLoginActivity (date: $date){
        studentActivity_result {
          date
          count
        }
      }
    }
  `,
};

export const GET_COLLECTION_POPULARITY = {
  query: `
    query getCollPlyReports($page: Int, $lc_id: ID, $type: String, $filter: [studentrecentloginFilterInputType]){
      getCollPlyReports (page: $page, lc_id: $lc_id, type: $type, filter: $filter){
        totalPages
        coll_Ply_result{
          id
          CollPly_id
          name
          user_id
          totalCards
          completedCards
          cards_arr {
            activityName
            count
          }
        }
      }
    }
  `,
};

export const GET_CARDS_POPULARITY = {
  query: `
    query getCardsPopularityReports($type: String, $page: Int, $start_date: String, $end_date: String, $filter: [studentrecentloginFilterInputType]){
      getCardsPopularityReports (type: $type, page: $page, start_date: $start_date, end_date: $end_date, filter: $filter){
        totalPages
        cards_result {
          activities_id
          name
          type
          username
          user_id
          recentDate
          completedCount
          collply_arr {
            coll_id
            ply_id
            type
            collply_name
            isPlaylist
          }
        }
      }
    }
  `,
};

export const GET_ALL_USERS = {
  query: `
    query getAllUser {
      getAllUser {
        users_result {
          id
          role_id
          name
          last_name
          displayName
        }
      }
    }
  `
};

export const GET_POWER_LEARNERS_LIST = {
  query: `
    query getStudentsRankingReports($page: Int, $filter: [studentrecentloginFilterInputType]){
      getStudentsRankingReports (page: $page, filter: $filter){
        totalPages
        student_cards_result {
          students_id
          fname
          lname
          dob
          username
          count
          last_login
        }
      }
    }
  `,
};

export const GET_PARENTS_LIST = {
  query: `
    query getParentSignedupReports($page: Int, $isParent: Boolean, $filter: [parentsignedupFilterInputType]) {
      getParentSignedupReports(page: $page, isParent: $isParent, filter: $filter) {
        totalPages
        parents_signedup_result {
          id
          name
          last_name
          displayName
          email
          phone_no
          address
          signupType
          loginType
          country
          city
          status
          students {
            id
            fname
            lname
            dob
            age_group
          }
        }
      }
    }
  `
};

export const GET_PARENTS_CREATED_LEARNERS_LIST = {
  query: `
    query getParentSignedupReports($page: Int, $isParent: Boolean, $isStudent: Boolean, $filter: [parentsignedupFilterInputType]) {
      getParentSignedupReports(page: $page, isParent: $isParent, isStudent: $isStudent, filter: $filter) {
        totalPages
        parents_signedup_result {
          id
          name
          last_name
          displayName
          email
          phone_no
          address
          signupType
          loginType
          country
          city
          status
          students {
            id
            fname
            lname
            dob
            age_group
            username
            is_delete
          }
        }
      }
    }

  `
};

export const GET_LEARNERS_LIST = {
  query: `
    query getLearnerSignedupReports($page: Int, $isStudent: Boolean, $filter: [parentsignedupFilterInputType]) {
      getLearnerSignedupReports(page: $page, isStudent: $isStudent, filter: $filter) {
        totalPages
        learner_signedup_result {
          id
          fname
          lname
          username
          dob
          age_group
          address
          last_login
          is_delete
          parents {
            id
            name
            last_name
            displayName
            email
            phone_no
            address
            signupType
            loginType
            country
            city
          }
        }
      }
    }
  `
};

export const GET_LEARNERS_CREATED_PARENTS_LIST = {
  query: `
    query getLearnerSignedupReports($page: Int, $isParent: Boolean, $isStudent: Boolean, $filter: [parentsignedupFilterInputType]) {
      getLearnerSignedupReports(page: $page, isParent: $isParent, isStudent: $isStudent, filter: $filter) {
        totalPages
        learner_signedup_result {
          id
          fname
          lname
          username
          dob
          age_group
          address
          last_login
          is_delete
          parents {
            id
            name
            last_name
            displayName
            status
            email
            phone_no
            address
            signupType
            loginType
            country
            city
          }
        }
      }
    }
  `,
};

export const GET_LEARNERS_LOGGEDIN_LIST = {
  query: `
    query getLearnersLoggedReports($page: Int, $isLoggedin: Boolean $filter: [parentsignedupFilterInputType]) {
      getLearnersLoggedReports(page: $page, isLoggedin: $isLoggedin, filter: $filter) {
        totalPages
        students_result {
          id
          fname
          lname
          username
          dob
          age_group
          address
          last_login
          is_delete
          parents {
            id
            name
            last_name
            displayName
            email
            phone_no
            address
            signupType
            loginType
            country
            city
          }
        }
      }
    }
  `
};

export const GET_LEARNERS_STARTED_LEARNING_LIST = {
  query: `
    query getStudentStartLearnReports($page: Int, $isStudentLearn: Boolean, $filter: [parentsignedupFilterInputType]) {
      getStudentStartLearnReports(page: $page, isStudentLearn: $isStudentLearn, filter: $filter) {
        totalPages
        students_result {
          id
          fname
          lname
          username
          dob
          age_group
          address
          last_login
          is_delete
          parents {
            id
            name
            last_name
            displayName
            email
            phone_no
            address
            signupType
            loginType
            country
            city
          }
        }
      }
    }
  `
};

export const GET_PAST_MONTH_LEARNERS_LIST = {
  query: `
    query getStudentsPastMonthReports($page: Int, $start_date: String, $end_date: String, $filter: [parentsignedupFilterInputType]) {
      getStudentsPastMonthReports(page: $page, start_date: $start_date, end_date: $end_date, filter: $filter) {
        totalPages
        students_result {
          id
          fname
          lname
          username
          dob
          age_group
          address
          last_login
          parents {
            id
            name
            last_name
            displayName
            email
            phone_no
            address
            signupType
            loginType
            country
            city
          }
        }
      }
    }
  `
};
