import axios from "axios";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Route, Switch } from "react-router-dom";
import Auth from "./Auth";
import GenericNotFound from "./pages/404";
import CreateCollection from "./pages/Collection/create";
import ViewCollection from "./pages/Collection/view";
import ContentCreator from "./pages/ContentCreator";
import DashBoard from "./pages/Dashboard";
import LCView from "./pages/Dashboard/lcView";
import Data from "./pages/Data";
import DataView from "./pages/Data/DataView";
import Feedback from "./pages/Feedback";
import ContentFeedback from "./pages/Feedback/contentFeedback";
import Interests from "./pages/Interests";
import InterestView from "./pages/Interests/InterestView";
import invite from "./pages/LcEnroll/Invite";
import LearningContent from "./pages/LearningContent";
import ContentImage from "./pages/LearningContent/contentImages";
import LearningCenterCreate from "./pages/LearningContent/create";
import EditLearningContent from "./pages/LearningContent/edit";
import ViewLearningCenter from "./pages/LearningContent/view";
import Notifications from "./pages/Notifications";
import Parents from "./pages/Parent";
import BillingRedirect from "./pages/Parent/BillingRedirect";
import ParentDashboard from "./pages/Parent/ParentDashboard";
import ParentView from "./pages/Parent/ParentView";
import ParentRedirect from "./pages/Parent/redirect";
import CreatePlayList from "./pages/Playlists/CreatePlayList";
import EditPlayList from "./pages/Playlists/EditPlayList";
import PlayListsDashboard from "./pages/Playlists/PlayListsDashboard";
import ViewPlayList from "./pages/Playlists/ViewPlayList";
import Profile from "./pages/Profile";
import Reports from "./pages/Reports";
import LearnersCreatedParents from "./pages/Reports/LearnersCreatedParents";
import LearnersLoggedIn from "./pages/Reports/LearnersLoggedIn";
import LearnersNotCreatedParents from "./pages/Reports/LearnersNotCreatedParents";
import LearnersNotLoggedIn from "./pages/Reports/LearnersNotLoggedIn";
import LearnersNotStartedLearning from "./pages/Reports/LearnersNotStartedLearning";
import LearnersStartedLearning from "./pages/Reports/LearnersStartedLearning";
import NoOfLearnersSignedUp from "./pages/Reports/NoOfLearnersSignedUp";
import NoOfParentsSignedUp from "./pages/Reports/NoOfParentsSignedUp";
import ParentsCreatedLearners from "./pages/Reports/ParentsCreatedLearners";
import ParentsNotCreatedLearners from "./pages/Reports/ParentsNotCreatedLearners";
import PastMonthLearners from "./pages/Reports/PastMonthLearners";
import SharingProgress from "./pages/SharingProgress";
import AcceptPasswordReset from "./pages/SignIn/AcceptPasswordReset";
import SignUpAccountSetup from "./pages/SignIn/AccountSetup";
import ChildAccountSetup from "./pages/SignIn/ChildAccountSetup";
import EmailSignupAccountSetup from "./pages/SignIn/EmailSignupAccountSetup";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import LogIn from "./pages/SignIn/Login";
import ResetPassword from "./pages/SignIn/ResetPassword";
import Signin from "./pages/SignIn/Signin";
import SocialSignUp from "./pages/SignIn/SignUp";
import SocialSignUpAccountSetup from "./pages/SignIn/SocialSignUpAccountSetup";
import VerifyParentEmail from "./pages/SignIn/VerifyParentEmail";
import StudentRedirect from "./pages/StudentRedirect";
import Students from "./pages/Students";
import StudentFeedback from "./pages/Students/StudentFeedback";
import StudentView from "./pages/Students/StudentView";
import StudentLearnerCards from "./pages/Students/StudentWeeklyUpdatesCards";
import WorkshopList from "./pages/workshop";
import WorkshopCreate from "./pages/workshop/create";
import EditWorkshopContent from "./pages/workshop/edit";
import ParentViewWorkshop from "./pages/workshop/parentView";
import ParentWorkshopCartView from "./pages/workshop/ParentWorkshopCartView";
import paymentSuccess from "./pages/workshop/paymentSuccess";
import WorkshopRegisteredStudents from "./pages/workshop/RegisteredStudents";
import ViewWorkshop from "./pages/workshop/view";


// import ActivityDashboard from "./pages/Activities/ActivityDashboard";
// import CreateActivity from "./pages/Activities/CreateActivity";
// import EditActivity from "./pages/Activities/EditActivity";
// import InProgressActivity from "./pages/Activities/inprogress";
// import PublishedActivity from "./pages/Activities/publish";
// import ViewActivity from "./pages/Activities/ViewActivity";
// import ActivityImage from "./pages/Activities/ViewActivity/images";
// import CreateBook from "./pages/Libraries/Book/Create";
// import EditBookLibrary from "./pages/Libraries/Book/Edit";
// import ViewBook from "./pages/Libraries/Book/View";
// import CreateDocumentary from "./pages/Libraries/Documentary/Create";
// import EditDocumentLibrary from "./pages/Libraries/Documentary/Edit";
// import ViewDocumentary from "./pages/Libraries/Documentary/View";
// import InProgressLibrary from "./pages/Libraries/Inprogress";
// import LibraryCard from "./pages/Libraries/LibraryCard";
// import LibraryDashboard from "./pages/Libraries/LibraryDashboard";
// import CreateMusic from "./pages/Libraries/Music/Create";
// import EditMusicLibrary from "./pages/Libraries/Music/Edit";
// import ViewMusic from "./pages/Libraries/Music/View";
// import PublishLibrary from "./pages/Libraries/Publish";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    const isOnline = window.navigator.onLine;
    if (isOnline) {
      console.log("online");
    } else {
      console.log("offline");
    }

    // Do something before request is sent
    axios.interceptors.request.use(function (config) {
      let token = localStorage.getItem("token");
      if (token) {
        config.headers.token = token;
      }
      return config;
    });

    // Do something with response data
    axios.interceptors.response.use(
      function (res) {
        if (res.data.errors) {
          // showNotification("error", "Please Try Again", res.data.errors[0].message);
          console.log(res.data.errors);
          // openNotification("error", "Please Try Again");
        } else {
          return res;
        }
        return res;
      },
      function (error) {
        // Do something with response error
        console.error(error);
        return Promise.reject(error);
      }
    );

    //Initializing Google Analytics
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKID}`);
  }

  render() {
    return (
      <main>
        <Switch>
          <Route exact path="/" component={Signin} />
          <Route exact path="/signIn" component={LogIn} />
          <Route exact path="/signup" component={SocialSignUp} />
          <Route exact path="/accountSetup" component={SignUpAccountSetup} />
          <Route exact path="/childSetup" component={ChildAccountSetup} />
          <Route exact path="/parent/invite/:id" component={EmailSignupAccountSetup} />
          <Route exact path="/socialAccountSetup" component={SocialSignUpAccountSetup} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/student/accept-passowrdreset/:token" component={StudentRedirect} />
          <Route exact path="/learner/accept-passowrdreset/:token" component={AcceptPasswordReset} />
          <Route exact path="/parent/verify/:token" component={VerifyParentEmail} />
          <Route exact path="/parent/redirect" component={ParentRedirect} />

          <Route exact path="/learning-centre/resetpassword/:token" component={ResetPassword} />
          <Route exact path="/learning-centre/invite/:id" component={invite} />
          {/* <Route exact path="/parent/invite/:id" component={ParentInvite} /> */}
          <Route exact path="/progress/:photo" component={SharingProgress} />
          <Auth exact path="/contentcreators" component={ContentCreator} />
          <Auth exact path="/feedbacks" component={Feedback} />
          <Auth exact path="/contentfeedback/:id" component={ContentFeedback} />
          <Auth exact path="/parent/learnerCards/:id" component={StudentLearnerCards} />
          <Auth exact path="/profile" component={Profile} />
          <Auth exact path="/dashboard" component={DashBoard} />
          <Auth exact path="/parents" component={Parents} />
          <Auth exact path="/learners>18" component={Parents} />
          <Auth exact path="/parents/:status/:email" component={ParentView} />
          <Auth exact path="/parent/dashboard" component={ParentDashboard} />
          <Auth exact path="/parent/feedback/:id" component={StudentFeedback} />
          <Auth exact path="/reports" component={Reports} />
          <Auth exact path="/reports/parents" component={NoOfParentsSignedUp} />
          <Auth exact path="/reports/learners" component={NoOfLearnersSignedUp} />
          <Auth exact path="/reports/parents-to-learners" component={ParentsCreatedLearners} />
          <Auth exact path="/reports/parents-not-created-learners" component={ParentsNotCreatedLearners} />
          <Auth exact path="/reports/learners-to-parents" component={LearnersCreatedParents} />
          <Auth exact path="/reports/learners-not-created-parents" component={LearnersNotCreatedParents} />
          <Auth exact path="/reports/loggedin-learners" component={LearnersLoggedIn} />
          <Auth exact path="/reports/yet-to-login-learners" component={LearnersNotLoggedIn} />
          <Auth exact path="/reports/learners-started-learning" component={LearnersStartedLearning} />
          <Auth exact path="/reports/learners-not-started-learning" component={LearnersNotStartedLearning} />
          <Auth exact path="/reports/past-month-learners" component={PastMonthLearners} />
          <Auth exact path="/interests" component={Interests} />
          <Auth exact path="/interests/:id" component={InterestView} />
          <Auth exact path="/data" component={Data} />
          <Auth exact path="/data/:id" component={DataView} />
          <Auth exact path="/lc/:name" component={LCView} />
          <Auth exact path="/students" component={StudentRedirect} />
          <Auth exact path="/learners" component={Students} />
          <Auth exact path="/students/:id" component={StudentRedirect} />
          <Auth exact path="/learners/:id" component={StudentView} />
          <Auth exact path="/students/:id/feedbacks" component={StudentRedirect} />
          <Auth exact path="/learners/:id/feedbacks" component={StudentFeedback} />
          <Auth exact path="/playlists" component={PlayListsDashboard} />
          <Auth exact path="/playlists/create" component={CreatePlayList} />
          <Auth exact path="/playlists/view/:id" component={ViewPlayList} />
          <Auth exact path="/playlists/edit/:id" component={EditPlayList} />
          <Auth exact path="/notifications" component={Notifications} />
          <Auth exact path="/learningcontent" component={LearningContent} />
          <Auth exact path="/learningcontent/:id/create" component={LearningCenterCreate} />
          <Auth exact path="/learningcontent/:id/view" component={ViewLearningCenter} />
          <Auth exact path="/learningcontent/:id/view/images,videos&steps" component={ContentImage} key="learning-content-image" />
          <Auth exact path="/learningcontent/:id/view/draft" component={ViewLearningCenter} />
          <Auth exact path="/learningcontent/:id/view/draft/images,videos&steps" component={ContentImage} key="learning-content-draft-image" />
          <Auth exact path="/learningcontent/:id/edit/draft" component={EditLearningContent} key="learning-content-draft-edit" />
          <Auth exact path="/learningcontent/:id/edit" component={EditLearningContent} key="learning-content-edit" />
          <Auth exact path="/collection/create" component={CreateCollection} key="create-new-collection" />
          <Auth exact path="/collection/:id/edit" component={CreateCollection} key="edit-collection" />
          <Auth exact path="/playlist/:id/edit" component={CreateCollection} key="edit-playlist" />
          <Auth exact path="/collection/:id/view" component={ViewCollection} key="learning-content-view-collection" />
          <Auth exact path="/playlist/:id/view" component={ViewCollection} key="learning-content-view-playlist" />
          <Auth exact path="/workshops" component={WorkshopList} key="workshops" />
          <Auth exact path="/parent/workshops" component={WorkshopList} key="parent-workshops" />
          <Auth exact path="/parent/workshops/cart" component={ParentWorkshopCartView} key="parent-workshops-cart" />
          <Auth exact path="/parent/workshops/paymentSuccess" component={paymentSuccess} key="parent-workshops-paymentSuccess" />
          <Auth exact path="/workshops/:id/create" component={WorkshopCreate} />
          <Auth exact path="/workshops/:id/view/draft" component={ViewWorkshop} />
          <Auth exact path="/workshops/:id/view" component={ViewWorkshop} />
          <Auth exact path="/parent/workshops/:id/view" component={ParentViewWorkshop} />
          <Auth exact path="/workshops/:id/edit/draft" component={EditWorkshopContent} key="workshop-content-draft-edit" />
          <Auth exact path="/workshops/:id/edit" component={EditWorkshopContent} key="workshop-content-edit" />
          <Auth exact path="/workshops/:id/registered-students" component={WorkshopRegisteredStudents} />
          <Auth exact path="/workshops/:id/registered-students" component={WorkshopRegisteredStudents} />
          <Route exact path="/parent/billings" component={BillingRedirect} />
          <Route exact path="*" component={GenericNotFound} />

          {/*<Auth exact path="/activity" component={ActivityDashboard} active="1"/>*/}
          {/*<Auth exact path="/library" component={LibraryDashboard} />*/}
          {/*<Auth exact path="/activity/create" component={CreateActivity} />*/}
          {/*<Auth exact path="/activity/edit/:id" component={EditActivity} key="edit-activity"/>*/}
          {/*<Auth exact path="/activity/:id" component={ViewActivity} key="view-activity"/>*/}
          {/*<Auth exact path="/activity/:id/images,videos&steps" component={ActivityImage} key="view-activity-image"/>*/}
          {/*<Auth exact path="/activity/in-progress/:id" component={InProgressActivity} />*/}
          {/*<Auth exact path="/activity/published/:id" component={PublishedActivity} />*/}
          {/*<Auth exact path="/activity/draft/view/:id" component={ViewActivity} key="view-activity-draft"/>*/}
          {/*<Auth exact path="/activity/draft/view/:id/images,videos&steps" component={ActivityImage} key="view-activity-draft-image"/>*/}
          {/*<Auth exact path="/activity/draft/edit/:id" component={EditActivity} key="edit-activity-draft"/>*/}
          {/*<Auth exact path="/library/book/create" component={CreateBook} />*/}
          {/*<Auth exact path="/library/book/view/:id" component={ViewBook} key="view-book"/>*/}
          {/*<Auth exact path="/library/book/edit/:id/" component={EditBookLibrary} />*/}
          {/*<Auth exact path="/library/book/draft/view/:id" component={ViewBook} key="view-book-draft"/>*/}
          {/*<Auth exact path="/library/book/draft/edit/:id/" component={EditBookLibrary} />*/}
          {/*<Auth exact path="/library/documentary/create" component={CreateDocumentary} />*/}
          {/*<Auth exact path="/library/documentary/view/:id" component={ViewDocumentary} key="view-doc"/>*/}
          {/*<Auth exact path="/library/documentary/edit/:id/" component={EditDocumentLibrary} />*/}
          {/*<Auth exact path="/library/documentary/draft/view/:id" component={ViewDocumentary} key="view-doc-draft"/>*/}
          {/*<Auth exact path="/library/documentary/draft/edit/:id/" component={EditDocumentLibrary} />*/}
          {/*<Auth exact path="/library/music/create" component={CreateMusic} />*/}
          {/*<Auth exact path="/library/music/view/:id" component={ViewMusic} key="view-music"/>*/}
          {/*<Auth exact path="/library/music/edit/:id/" component={EditMusicLibrary} />*/}
          {/*<Auth exact path="/library/music/draft/view/:id" component={ViewMusic} key="view-music-draft"/>*/}
          {/*<Auth exact path="/library/music/draft/edit/:id/" component={EditMusicLibrary} />*/}
          {/*<Auth exact path="/library/in-progress/:cardTypeId/:id" component={InProgressLibrary} />*/}
          {/*<Auth exact path="/library/published/:cardTypeId/:id" component={PublishLibrary} />*/}
          {/*<Auth exact path="/library/card/:cardTypeId/:id" component={LibraryCard} />*/}
          {/*<Auth exact path="/library/pending/:cardTypeId/:id" component={LibraryCard} />*/}
        </Switch>
      </main>
    );
  }
}
