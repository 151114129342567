import {
  Button, Checkbox, Modal
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Form from 'react-validation/build/form'
import styled from 'styled-components'
import '../../App.css'
import AccountLogo from '../../assets/Account_created.svg'
import mbllogo from '../../assets/breadcrumb_resplogo.svg'
import InputC from '../../components/InputC'
import { CHECK_PARENT_INVITE, PARENT_ONBOARDING } from '../../graphql'
import BGBLANKLOGO from '../../static/nav/bg_blank_img.svg'
import MBLBGBLANKLOGO from '../../static/nav/mbl_bg_blank_logo.svg'
import Invalid from '../../static/PasswordReset/Invalid.svg'
import { openNotification, SetTokenCookie, triggerGAEvent } from '../../utils'
import {
  confirmpassword, isRequired, password,
  required
} from '../../validation'
import './Signin.css'
const { confirm } = Modal

const MaxSignup = styled.div`
  @media (max-width: 576px) {
    display: none !important;
  }
  .pt-c {
    padding-top: 8rem !important;
  }
  @media (min-width: 1500px) {
    .pt-c {
      padding-top: 15rem !important;
    }
  }
  height: 100vh;
  background: url(${BGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4c39b3 !important;
    border-color: #4c39b3 !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #4c39b3 !important;
  }
`

const MblCard = styled.div`
  background: url(${MBLBGBLANKLOGO}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4c39b3 !important;
    border-color: #4c39b3 !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #4c39b3 !important;
  }
  .cardd1 {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 12px;
    padding: 40px;
  }
  @media (min-width: 576px) {
    .mblSignIn {
      display: none !important;
    }
  }
  @media (max-width: 576px) {
    height: 100vh;
    background: #f9fafb;
    .mblSignIn {
      width: 90% !important;
    }
    .cusColor .ant-radio-checked .ant-radio-inner {
      border-color: #4c39b3 !important;
    }
    .cusColor > .ant-radio-checked .ant-radio-inner::after {
      background-color: #4c39b3 !important;
    }
    .mbl-cardStart {
      display: none;
    }
    justify-content: center !important;
    .navSticky {
      position: sticky;
      top: 0;
      z-index: 1000;
    }
    .btnBottomSticky {
      box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
      background: #fff;
      position: absolute;
      bottom: 0;
      padding: 16px;
      z-index: 1000;
      width: 100%;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  margin: 0 auto;
`
class EmailSignupAccountSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      login_type: '',
      terms: false,
      status: true,
      touVisible: false,
      login: {
        email: '',
        password: '',
        confirm: ''
      },
      truthObj: {
        email: false,
        password: false,
        confirm: false
      },
      form: React.createRef(),
      submitted: false,
      showHide: {
        password: false,
        showconfirmpwd: false
      }
    }
  }

  showConfirm = () => {
    let confirmPopup = confirm({
      icon: null,
      content: (
        <React.Fragment>
          <div className='d-flex align-items-center flex-column'>
            <div>
              <img src={AccountLogo} className='' alt='logo' />
            </div>
            <div className='mt-4 mb-2'>
              <h5
                style={{
                  color: '#111827',
                  fontSize: 22,
                  fontWeight: 800
                }}
              >
                Account created
              </h5>
            </div>
            <div
              style={{
                textAlign: 'center',
                color: '#4F4F4F',
                fontSize: 14,
                fontWeight: 400
              }}
            >
              You can continue to use Breadcrumb as a learner yourself or  add a child if you're setting up Breadcrumb for them
            </div>
            <div className='mt-4 d-flex justify-content-between'>
              <div>
                <Button
                  style={{
                    height: 40,
                    color: '#333333',
                    width: 100
                  }}
                  type='link'
                  block
                  size='large'
                  onClick={() => {
                    triggerGAEvent('Login', 'Logging in as Parent')
                    SetTokenCookie()
                    confirmPopup.destroy();
                    window.open(
                      process.env.REACT_APP_LEARNER_APP + "dashboard"
                    )

                    // this.props.history.push(`/parent/workshops`)
                  }}
                >
                  Continue
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    height: 40,
                    color: '#fff',
                    background: '#4C39B3',
                    borderRadius: 6,
                    border: 'none',
                    width: 120
                  }}
                  type='primary'
                  block
                  size='large'
                  onClick={() => {
                    triggerGAEvent('Login', 'Child setup with as Parent')
                    confirmPopup.destroy()
                    this.props.history.push(`/childSetup`)
                  }}
                >
                  Add a child
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      centered: true
    })
  }

  onBoardParent = () => {
    triggerGAEvent('Parent On-boarding', 'On-borading a parent')
    let mutation = PARENT_ONBOARDING
    mutation.variables = {
      inviteToken: this.props.match.params.id,
      password: this.state.login.password
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          localStorage.setItem('role_id', '3')
          localStorage.setItem('token', res.data.data.OnboardingParentV2.token)
          localStorage.setItem(
            'isChild',
            res.data.data.OnboardingParentV2.isChild
          )
          if (res.data.data.OnboardingParentV2.child_setup) {
            this.showConfirm()
          } else {
            this.props.history.push('/parent/workshops')
          }
        }
      })
      .catch(error => console.log(error))
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    this.state.form.current.validateAll()
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.login)
    getKeys.forEach((key, index) => {
      let getItem = this.state.login[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    let re = /\S+@\S+\.\S+/
    truthObj['email'] = !re.test(this.state.login.email)
    truthObj['confirm'] = this.state.login.password
      ? this.state.login.password != this.state.login.confirm
      : false
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please fill all mandatory fields correctly')
      this.setState({ isFormSubmitting: false })
    } else {
      this.onBoardParent()
    }
  }

  showPwdClick = key => {
    triggerGAEvent('Signup', 'Show password in Login')
    const state = { ...this.state }
    state.showHide[key] = !state.showHide[key]
    this.setState({ showHide: state.showHide })
  }

  fetchData = () => {
    let mutation = CHECK_PARENT_INVITE
    mutation.variables = {
      inviteToken: this.props.match.params.id
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        let phone_no = ''
        let country_code = ''
        res.data.data.checkInviteLinkV2.phone_no.split(' ').forEach((p, i) => {
          if (p.includes('+')) {
            country_code = p
          } else {
            country_code = '+91'
            phone_no = phone_no + p
          }
        })
        this.setState({
          login: {
            email: res.data.data.checkInviteLinkV2.email,
            first_name: res.data.data.checkInviteLinkV2.name,
            last_name: res.data.data.checkInviteLinkV2.last_name,
            city: res.data.data.checkInviteLinkV2.city,
            country: res.data.data.checkInviteLinkV2.country || 'India',
            country_code: country_code,
            phone_no: phone_no
          }
        })
        !res.data.data.checkInviteLinkV2.status &&
          triggerGAEvent('Parent On-boarding', 'Visiting an Invalid link')
      })
      .catch(error => {
        this.setState({ status: false })
        console.log(error)
      })
  }

  updateParentData = (key, value) => {
    let login = { ...this.state.login }
    login[key] = value
    this.setState({
      login
    })
  }

  disableDobHandler = current => {
    return current.isAfter(moment())
  }

  passRef = ele => {
    ele.target.type = 'password'
  }

  touOnclick = () => {
    this.setState({
      touVisible: true
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.status && (
          <div className='tabscroll d-flex align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-center justify-content-center bg-white'>
              <img
                src={Invalid}
                alt='request processed'
                style={{ padding: '4rem 8rem 2rem 4rem', maxWidth: '100vw' }}
              />
              <p className='text-dark font-16'>This is an invalid link</p>
            </div>
          </div>
        )}
        {this.state.status && (
          <React.Fragment>
            <MaxSignup>
              <div>
                {/* <Menu
                  className='pr-2 w-100 parentMenu'
                  mode='horizontal'
                  style={{
                    background: '#4C39B3',
                    boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
                  }}
                >
                  <Menu.Item
                    key='logo'
                    index='1'
                    className='pl-0'
                    title={`BREADCRUMB`}
                  >
                    <img
                      src={ParentLogo}
                      alt='breadcrumb logo'
                      style={{ width: 230, marginBottom: -1 }}
                    />
                  </Menu.Item>
                </Menu> */}

                <div className='d-flex align-items-center justify-content-center pt-c'>
                  <div
                    className='mbl-signup-card mbl-card-radius mx-auto'
                    style={{ width: 584, padding: 40 }}
                  >
                    <div className='d-flex align-items-center justify-content-center mb-4'>
                      <div>
                        <h5
                          className='max-signup-title FontBariolHeavy text-center'
                          style={{ fontSize: 36 }}
                        >
                          Let’s set up your account
                        </h5>
                      </div>
                    </div>
                    <div className='mb-0'>
                      <Form
                        ref={this.state.form}
                        className={this.state.submitted ? 'formSubmitted' : ''}
                        autoComplete='off'
                      >
                        <div className='row align-items-center justify-content-center mb-4'>
                          <div
                            className='col-4 FontBariolMedium'
                            style={{
                              fontSize: 16,
                              color: '#333333'
                            }}
                          >
                            Registered Email
                          </div>
                          <div className='col-8'>
                            <InputC
                              type='text'
                              placeholder='Enter Your Email'
                              id={
                                this.state.truthObj['email'] ? 'has-error' : ''
                              }
                              className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                              titleStyle='FontBariolMedium text-darkBlack font-16'
                              value={this.state.login.email}
                              stateKey='email'
                              onChange={(key, value) =>
                                this.updateParentData(key, value)
                              }
                              autoComplete='off'
                              disabled={this.state.login.email ? true : false}
                            />
                          </div>
                        </div>
                        <div className='row align-items-center justify-content-center mb-4'>
                          <div
                            className='col-4 FontBariolMedium'
                            style={{
                              fontSize: 16,
                              color: '#333333'
                            }}
                          >
                            Set Password
                          </div>
                          <div className='col-8'>
                            <InputC
                              autoComplete='off'
                              type='password'
                              placeholder='Enter Your Password'
                              id={
                                this.state.truthObj['password']
                                  ? 'has-error'
                                  : ''
                              }
                              className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                              titleStyle='FontBariolMedium text-darkBlack font-16'
                              value={this.state.login.password}
                              stateKey='password'
                              name='password'
                              showPwdKey='password'
                              showPwd={this.state.showHide.password}
                              validations={[
                                required,
                                password,
                                confirmpassword
                              ]}
                              showPwdClick={this.showPwdClick}
                              onChange={(key, value) =>
                                this.updateParentData(key, value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row align-items-center justify-content-center mb-4'>
                          <div
                            className='col-4 FontBariolMedium'
                            style={{
                              fontSize: 16,
                              color: '#333333'
                            }}
                          >
                            Confirm Password
                          </div>
                          <div className='col-8'>
                            <InputC
                              autoComplete='off'
                              type='password'
                              placeholder='Confirm Your Password'
                              id={
                                this.state.truthObj['confirm']
                                  ? 'has-error'
                                  : ''
                              }
                              className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                              titleStyle='FontBariolMedium text-darkBlack font-16'
                              value={this.state.login.confirm}
                              stateKey='confirm'
                              name='confirm'
                              onChange={(key, value) =>
                                this.updateParentData(key, value)
                              }
                              showPwdClick={this.showPwdClick}
                              validations={[
                                required,
                                password,
                                confirmpassword
                              ]}
                              showPwd={this.state.showHide.showconfirmpwd}
                              showPwdKey='showconfirmpwd'
                            />
                          </div>
                        </div>

                        <div className='row align-items-center justify-content-end mb-4'>
                          <div className='col-4'></div>
                          <div
                            className='col-8 FontBariol'
                            style={{
                              fontSize: 14
                            }}
                          >
                            <span
                              style={{
                                color: '#000000',
                                fontWeight: 'bold'
                              }}
                            >
                              Note:&ensp;
                            </span>
                            Use 8 or more characters with a mix of capital and
                            small letters, numbers and symbols.
                          </div>
                        </div>
                        <div className='row align-items-center justify-content-center mb-4'>
                          <div className='col-4'></div>
                          <div className='col-8'>
                            <Checkbox
                              onChange={e =>
                                this.setState({
                                  terms: e.target.checked
                                })
                              }
                              value={this.state.terms}
                            >
                              <span
                                style={{
                                  color: '#000',
                                  fontSize: 14
                                }}
                              >
                                I agree to these{' '}
                                <span
                                  style={{
                                    color: '#4C39B3',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={e => {
                                    e.preventDefault()
                                    this.touOnclick()
                                  }}
                                >
                                  Terms Of Use
                                </span>
                              </span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <Button
                            className='mbl-btn-bottom disable-btn1'
                            block
                            size='large'
                            onClick={this.validateStateValues}
                            disabled={
                              !this.state.login.password ||
                              !this.state.login.confirm ||
                              !this.state.terms
                            }
                            style={{
                              color: '#fff',
                              background: '#4C39B3',
                              border: 0,
                              width: 232,
                              height: 50
                            }}
                          >
                            Create Account
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </MaxSignup>

            {/* Mbl signup page */}
            <MblCard className='d-flex'>
              <div className='mblSignIn'>
                {/* <div className=''>
                  <Menu
                    className='pr-2 w-100'
                    mode='horizontal'
                    style={{
                      background: '#4C39B3',
                      boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)'
                    }}
                  >
                    <Menu.Item
                      key='logo'
                      index='1'
                      className='pl-0'
                      title={`BREADCRUMB`}
                    >
                      <img
                        src={ParentLogo}
                        alt='breadcrumb logo'
                        style={{ width: 230, marginBottom: -1 }}
                      />
                    </Menu.Item>
                  </Menu>
                </div> */}
                <div
                  className='card-w-auto cardd1'
                  style={{
                    padding: 16
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center mt-2'>
                    <img
                      style={{
                        height: 31,
                        width: 190
                      }}
                      src={mbllogo}
                      className='mbl-logo1'
                      alt='logo'
                    />
                  </div>
                  <div
                    className='pt-2 mb-2 FontBariolHeavy text-center'
                    style={{
                      color: '#000',
                      fontSize: 28
                    }}
                  >
                    Let’s set up your account
                  </div>

                  <div className='mb-0'>
                    <Form
                      ref={this.state.form}
                      className={this.state.submitted ? 'formSubmitted' : ''}
                      autoComplete='off'
                    >
                      <div className='row align-items-center justify-content-center mb-4'>
                        <div className='col-12'>
                          <InputC
                            type='text'
                            title='Registered Email'
                            placeholder='Enter Your Email'
                            id={this.state.truthObj['email'] ? 'has-error' : ''}
                            className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                            titleStyle='FontBariolMedium mb-2 font-16'
                            value={this.state.login.email}
                            stateKey='email'
                            onChange={(key, value) =>
                              this.updateParentData(key, value)
                            }
                            autoComplete='off'
                            disabled={this.state.login.email ? true : false}
                          />
                        </div>
                      </div>
                      <div className='row align-items-center justify-content-center mb-4'>
                        <div className='col-12'>
                          <InputC
                            autoComplete='off'
                            type='password'
                            title='Set Password'
                            placeholder='Enter your password'
                            id={
                              this.state.truthObj['password'] ? 'has-error' : ''
                            }
                            className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16'
                            titleStyle='FontBariolMedium mb-2 font-16'
                            value={this.state.login.password}
                            stateKey='password'
                            name='password'
                            showPwdKey='password'
                            showPwd={this.state.showHide.password}
                            validations={[required, password, confirmpassword]}
                            showPwdClick={this.showPwdClick}
                            onChange={(key, value) =>
                              this.updateParentData(key, value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row align-items-center justify-content-center mb-4'>
                        <div className='col-12'>
                          <InputC
                            autoComplete='off'
                            type='password'
                            title='Confirm Password'
                            placeholder='Confirm Your Password'
                            id={
                              this.state.truthObj['confirm'] ? 'has-error' : ''
                            }
                            className='mbl-signup-input FontBariol col-12 ant-input ant-input-lg font-16 mb-2'
                            titleStyle='FontBariolMedium mb-2 font-16'
                            value={this.state.login.confirm}
                            stateKey='confirm'
                            name='confirm'
                            onChange={(key, value) =>
                              this.updateParentData(key, value)
                            }
                            showPwdClick={this.showPwdClick}
                            validations={[required, password, confirmpassword]}
                            showPwd={this.state.showHide.showconfirmpwd}
                            showPwdKey='showconfirmpwd'
                          />
                        </div>
                      </div>
                      <div className='row mb-2'>
                        <div
                          className='col-12 mt-2 FontBariol'
                          style={{
                            fontSize: 14
                          }}
                        >
                          <span
                            style={{
                              color: '#000000',
                              fontWeight: 'bold'
                            }}
                          >
                            Note:
                            <br />
                          </span>
                          Use 8 or more characters with a mix of capital and
                          small letters, numbers and symbols.
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <div className='col-12'>
                          <Checkbox
                            onChange={e =>
                              this.setState({
                                terms: e.target.checked
                              })
                            }
                            value={this.state.terms}
                          >
                            <span
                              style={{
                                color: '#000',
                                fontSize: 14
                              }}
                            >
                              I agree to these{' '}
                              <span
                                style={{
                                  color: '#4C39B3',
                                  textDecoration: 'underline',
                                  cursor: 'pointer'
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  this.touOnclick()
                                }}
                              >
                                Terms Of Use
                              </span>
                            </span>
                          </Checkbox>
                        </div>
                      </div>

                      <Button
                        type='primary'
                        block
                        size='large'
                        className='disable-btn1'
                        onClick={this.validateStateValues}
                        disabled={
                          !this.state.login.password ||
                          !this.state.login.confirm ||
                          !this.state.terms
                        }
                        style={{
                          background: '#4C39B3',
                          color: '#FFF',
                          border: 0,
                          height: 42
                        }}
                      >
                        Create Account
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </MblCard>
          </React.Fragment>
        )}

        {/* terms of use modal */}
        <Modal
          visible={this.state.touVisible}
          closable={true}
          centered={true}
          width='60%'
          // footer={null}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() =>
            this.setState({
              touVisible: false
            })
          }
          onCancel={() =>
            this.setState({
              touVisible: false
            })
          }
        >
          <h4 className='heading'>Terms Of Use</h4>
          <div className='border-bottom'></div>
          <div className='mt-4'>
            <div>
              <h5>
                <strong>Terms of Use</strong>
              </h5>
              <p>
                Breadcrumb is an App and related website owned and operated by
                SDL Ed Apps LLP ("SDL" or "We" or "Us" or "Our"). These Terms of
                Use(“Terms of Use” or “Terms”) govern the use of the website
                located at the URL https://learnwithbreadcrumb.com, the iOS and
                Android compatible applications owned by SDL and made available
                as Breadcrumb ("App"), SDL Material and other services provided
                by Us ("Services"). Upon using the Services and / or allowing
                your child / ward to use the Services ("You" or "Your" or
                "Yourself"), You agree to bind Yourself to these Terms of Use.
                These Terms of Use also govern Your use, in any manner, of
                materials, information, photography, writings and other creative
                content provided by SDL and shall include all copyrights,
                trademarks and other intellectual property rights belonging to
                SDL ("SDL Material"). These Terms shall constitute a valid
                contract under the terms of the Indian Contract Act, 1872. These
                Terms also constitute an electronic record in accordance with
                the provisions of the Information Technology Act, 2000, as
                amended from time to time. Please read these Terms of Use and
                the Privacy Policy carefully. Your registration on the
                Breadcrumb App or website confirms that You have read,
                understood and accepted these Terms and the Privacy Policy. If
                You do not agree with this Terms of Use and Privacy Policy,
                please do not use any of Our Services.
              </p>
              <h5>
                <strong>1. Interpretation</strong>
              </h5>

              <p>
                For the purposes of this Agreement, except as expressly provided
                herein: All references to a section, sub-section, paragraph or
                other subdivision or is to that designated section, sub-section,
                paragraph or other subdivision of this Agreement unless
                otherwise specifically stated. The words “herein”, “hereof” and
                “hereunder” or words of similar import refer to the Agreement as
                a whole and not to any particular clause, sub-clause or
                schedule. The singular of any term includes the plural and vice
                versa,where necessary for the relevant provision to be
                meaningful, and the use of apronoun is applicable to any gender
                or to a body corporate as necessitated by the context. The word
                “or” is not exclusive and the word “including” is not limiting.
                The term ‘Expert’ means (i) creators of content whose name
                appears with a Red Check mark on the Breadcrumb App and whose
                content is designated as “Expert Content”, and (ii) persons who
                are onboarded by SDL to provide Feedback to the child using the
                Breadcrumb App.”
              </p>
              <h5>
                <strong>2. Admissibility</strong>
              </h5>

              <p>
                You must be at least eighteen years of age to register a child
                to use the Services and/ or use the Services.
              </p>

              <h5>
                <strong>3. Registration of Account and Security</strong>
              </h5>

              <p>
                <b>3.1</b> Subject to clause 3.2 and 3.3, on downloading the
                App, You and Your ward / child may explore the content that is
                made available through the Services. Registration is mandatory
                to view the content and / or undertake any activities made
                available along with content, and to avail of the Services.
                <br />
                <b>3.2</b> Your ward / child may use the App for a period of 24
                hours before You are required to complete the registration
                process.
                <br />
                <b>3.3</b> You must complete the registration process within a
                period of 24 hours for Your child / ward to completely utilise
                the Services.
                <br />
                <b>3.4</b> You may register using Your existing Facebook or
                Google account to expedite through the verification process.
                <br />
                <b>3.5</b> You agree to ensure that the details provided by You
                at the time of registration are accurate and updated. Inaccuracy
                of the information provided by You shall be grounds for SDL to
                either deactivate or suspend Your account.
                <br />
                <b>3.6</b> The ward or child shall be provided with unique user
                name and pass code to ensure that his or her account is unique.
                You undertake and agree that You shall be responsible to
                regularly monitor the use of our Services by the minor You have
                registered.
                <br />
                <b>3.7</b> You shall select a username and password for the
                overall account and the secrecy and confidentiality of the same
                shall be maintained by You. Any access gained to Your account,
                whether authorised or unauthorised, causing any loss to SDL
                shall be attributed to You. Please ensure that Your account is
                maintained on devices within Your possession and that You log
                out of Your account when it is not in use to ensure security of
                Your account.
                <br />
                <b>3.8</b> You represent and warrant that You own the
                information that You share with Us and that You are free to
                share the information with us.
              </p>

              <h5>
                <strong>4. Use of our Services</strong>
              </h5>

              <p>
                <b>4.1</b> You Agree to abide by the following while using the
                Services offered by SDL:
                <br />
                <b>4.1.1</b> You shall not copy, reverse engineer or dismantle
                the content accessible via the Breadcrumb website or App and SDL
                Material. You shall also not republish, copy, distribute,
                modify, display, distribute, disseminate, transfer, sell or
                derivate any of the information, data accessible via the
                Breadcrumb website or App, or breach any of the intellectual
                property that You access through Your use of the Services.
                <br />
                <b>4.1.2</b> You shall not use the Services in an unauthorised
                manner such as by any activity which is prohibited under law or
                may cause harm to SDL or any other person. These activities
                shall also include those of a commercial nature including
                publishing advertisements and promotions. <br />
                <b>4.1.3</b> You must not use the Services to abuse, defame,
                harass, threaten, impersonate, stalk anyone or in any way affect
                any of their rights. Materials which are unlawful, obscene or in
                any other manner prohibited under the law shall not be published
                over the Breadcrumb App or website. In addition, You are
                forbidden to delete or modify any material uploaded to the
                Services including but not limited to any legal notice,
                copyright labels, etc.
                <br />
                <b>4.1.4</b> You shall not use any deep-link, robot, spider or
                other automatic device, program, algorithm or methodology, or
                any similar or equivalent manual process, to access, acquire,
                copy or monitor in any manner any portion of the Services, or in
                any way reproduce or circumvent the navigational structure or
                presentation of the Breadcrumb website or App.
                <br />
                <b>4.1.5</b> You should only use the Breadcrumb website and App,
                and avail of the Services as allowed under these Terms of Use.
                Obtaining any documents or files or readouts in any manner which
                is not as per the Terms of Use is strictly prohibited.
                <br />
                <b>4.1.6</b> You shall not use the Services in any manner for
                which has not been authorised by SDL in these Terms of Use or
                under applicable laws.
                <br />
                <b>4.1.7</b> You shall not disrupt the smooth functioning of the
                Breadcrumb website or App or the provision of the Services. You
                shall also not prevent or disrupt others' peaceful use of the
                Breadcrumb website or App or the Services.
                <br />
                <b>4.1.8</b> You shall not misuse the Services to Your benefit
                and to the prejudice of others, including us, in any manner, and
                acknowledge that doing which so which may constitute an unlawful
                act. Specifically, You agree that You shall not infringe or copy
                the content on the Services / SDL Material and/or infringe SDL’s
                intellectual property in any manner.
                <br />
                <b>4.1.9</b> You shall comply with applicable laws and
                regulations, at all times, in Your use of the Services.
                <br />
                <b>4.1.10</b> You agree that You shall make only personal and
                non-commercial use of the Services.
              </p>

              <h5>
                <strong>5. Support and Assistance</strong>
              </h5>

              <p>
                <b>5.1</b> You may contact Customer Support for the resolution
                of any technical difficulties. You also authorise Us to contact
                You through any electronic form of communication including
                email, over the App, etc. for Our response and resolution of any
                issues that You may face.
                <br />
                <b>5.2</b> You may access / modify the information collected by
                us and / or request the deletion of Your account and / or Your
                child / ward’s profile, at any time by logging in to Your
                account at{' '}
                <a href='https://learnwithbreadcrumb.com' target='_blank'>
                  www.learnwithbreadcrumb.com
                </a>{' '}
                and following these steps :<br />
                <b>5.2.1</b> For deleting your account on Breadcrumb please
                reach out to us at{' '}
                <a href='mailto:contact@learnwithbreadcrumb.com'>
                  contact@learnwithbreadcrumb.com
                </a>
                , and we will facilitate it.
                <br />
                <b>5.3</b> If You have more than one child / ward’s profile
                linked with Your account, You may choose to delete any and / or
                all profiles. Should You choose to delete Your account, please
                note that information with respect to all connected profiles
                shall be stand deleted by SDL.
              </p>

              <h5>
                <strong>6. Intellectual Property Rights</strong>
              </h5>

              <p>
                <b>6.1</b> The Services and SDL Material subject to any third
                party propriertary materials / content / information that may be
                contained within the SDL Material that is the exclusive property
                of the respective third-party, including without limitation, the
                trademarks, logos, literary works, images, artistic works,
                content in which copyright and other intellectual property
                subsistsare and remain the exclusive property of SDL.
                Specifically, the contents of the Services are protected as
                collected/complied under laws related to copyright in India and
                under International treaties and are also included in SDL
                Material.
                <br />
                <b>6.2</b> Your use of the Services does not create any
                exclusive license in the SDL Material except any non-exclusive
                permission to use the SDL Material as per these Termsand does
                not permit You to copy the SDL Material or make any derivative
                use of the Service or SDL Material. These Terms of Use do not
                create any right for You to copy, modify, alter, recreate,
                derive or otherwise disseminate any of the SDL Material or
                Services.
                <br />
                <b>6.3</b> You may not mine data or gather data for any purpose
                from the Breadcrumb website or App.
                <br />
                <b>6.4</b> The Terms do not transfer any intellectual property
                in the Services or rights/ title in any SDL Material to You.
                <br />
                <b>6.5</b> You may not use any SDL Material or the Services for
                any purpose not expressly permitted by the Terms of Use.
                <br />
                <b>6.6</b> You shall not use or misuse or register as the owner,
                licensee, or cause to be registered, nor assist any other person
                or entity in using or misusing or in registering as the owner or
                causing to be registered, in any part of the world, any SDL
                Material.
              </p>

              <h5>
                <strong>7. Copyright Infringement</strong>
              </h5>

              <p>
                <b>7.1</b> SDL has the utmost respect for third-party
                intellectual property rights. If you believe that your copyright
                has been infringed by the SDL Material or through the Services,
                please send us a notice on contact@learnwithbreadcrumb.com.
                <br />
                <b>7.2</b> You are called upon to provide us with the following
                information in your notice:
                <br />
                <b>7.2.1</b> The description of the work with adequate
                information to identify the work.
                <br />
                <b>7.2.2</b> Details establishing that you are the owner or
                exclusive licensee of copyright in the work.
                <br />
                <b>7.2.3</b> Details establishing that the information available
                on the Services is infringing.
                <br />
                <b>7.2.4</b> Details of location on the Services where the
                impugned information is available.
                <br />
                <b>7.2.5</b> Your details including name, email address and
                telephone number.
                <br />
                <b>7.2.6</b> Undertaking made under the penalty of perjury that
                the information you provide in this regard is accurate.
              </p>

              <h5>
                <strong>8. Services on an “As is” basis</strong>
              </h5>
              <p>
                The Services provided are on an “As is” basis. That is to say,
                SDL hereby disclaims all warranties of any kind, express or
                implied, to the extent permissible by law. We make no warranty
                that the Services shall be free from errors or that the App and
                website may not occasionally suffer from interruptions. Neither
                is there any warranty that the Services will function without a
                hitch and will be timely and secure.
              </p>

              <h5>
                <strong>9. Limitation of Liability and Indemnification</strong>
              </h5>
              <p>
                <b>9.1</b> You expressly agree that Your use of the Breadcrumb
                website or App and that Your availing of the Services is at Your
                sole risk, and that SDL shall not be responsible for any damage
                arising from the SDL Material or Your use of the Services. You
                also agree that You understand that although SDL has conducted
                reasonable due diligence when onboarding Experts that guide the
                child and provide feedback, any communication between the child
                and Expert through the direct messaging tab is outside SDL’s
                review and that SDL bears no responsibility for the same.
                Accordingly, You are solely responsible to ensure monitoring of
                such communication at all times, and to notify SDL if there are
                any issues.
                <br />
                <b>9.2</b> You shall fully indemnify, defend, and hold harmless
                SDL from and against any and all claims, losses, damages,
                expenses arising from Your and / or Your child / ward's use of
                the Services, provided that such claim, loss, damage, expense,
                or liability does not arise from negligence attributable to SDL.
              </p>

              <h5>
                <strong>10. Assignment</strong>
              </h5>
              <p>
                You shall not assign or sub-contract any part of or the whole of
                the Terms of Use to any third party, without our prior written
                consent.{' '}
              </p>

              <h5>
                <strong>11. Severability</strong>
              </h5>
              <p>
                If any provision of this Agreement is prohibited or
                unenforceable, such part shall not invalidate the remaining
                portions of the Agreement.
              </p>

              <h5>
                <strong>12. Updates of Terms of Use</strong>
              </h5>
              <p>
                We reserve the right to alter, modify and update these Terms of
                Use with or without any prior notice to You. You are required to
                update Yourself on the Terms of Use based on the updates that
                are provided in Our Services.
              </p>

              <h5>
                <strong>13. Governing Law and Jurisdiction </strong>
              </h5>
              <p>
                In case of any dispute, controversy, claim or disagreement
                arising out of or touching upon or in relation to the Terms of
                Use, including the interpretation and validity thereof and the
                respective rights and obligations, shall be shall be governed
                by, and construed in accordance with the laws of India. The
                courts at New Delhi will have the exclusive jurisdiction to
                entertain and try any dispute which arises in relation these
                Terms or to Your use of the Breadcrumb website or App.
              </p>

              <h5>
                <strong>14. Survival </strong>
              </h5>
              <p>
                The provisions of these Terms that, by their nature, require
                performance following the termination or expiration of these
                Terms, shall survive the termination or expiration of these
                Terms of Use.
              </p>
            </div>
            <div className='mb-4'></div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default EmailSignupAccountSetup
