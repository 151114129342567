import { Tag } from "antd";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import ImageCard from "./ImageCard";

const StudentCard = props => (
  <div className="dashboardCardStyle">
    <Link
      to={{
        pathname: `${
          props.type === "activity"
            ? `/learningcontent/${props.id}/view`
            : props.library_default_id === "1"
            ? `/library/book/view/${props.id}`
            : props.library_default_id === "2"
            ? `/library/documentary/view/${props.id}`
            : `/library/music/view/${props.id}`
        }`
      }}
    >
      <ImageCard img={props.image} />
    </Link>
    <div className="pt-3 pr-3 pl-3 pb-2 card border-0">
      <Link
        to={{
          pathname: `${
            props.type === "activity"
              ? `/learningcontent/${props.id}/view`
              : props.library_default_id === "1"
              ? `/library/book/view/${props.id}`
              : props.library_default_id === "2"
              ? `/library/documentary/view/${props.id}`
              : `/library/music/view/${props.id}`
          }`
        }}
      >
        <div className="FontAvenirMedium mb-2 pb-1 font-16 text-dark text-capitalize">
          <LinesEllipsis
            text={props.title}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </div>
        <div className="d-flex justify-content-between FontAvenirMedium font-14">
          <div className="row m-0 w-100">
            <div className="col-8 p-0">
              <div
                className="mb-2 pb-1 text-card-black"
                style={{ minHeight: 25 }}
              >
                <LinesEllipsis
                  text={props.interests.interest_details
                    .map(interest => interest.name)
                    .join(",")}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
              <div>
                <Tag color="green">Approved</Tag>
                {props.activity_state === "Active" ? (
                  <span>
                    <Tag color="orange">Active</Tag>
                  </span>
                ) : (
                  <span>
                    <Tag color="red">Hidden</Tag>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
      {props.role_id === 2 && (
        <div>
          <div className="border-bottom pt-3 mb-2" />
          {props.isDeletedAccount ? (
            <div style={{opacity: '0.5'}}
              className="FontAvenirMedium font-14 text-center fontColor-blue text-uppercase"
            >
              ADD FEEDBACK
          </div>
          ) : (
          <div
            onClick={props.openFeedBackModal}
            className="FontAvenirMedium font-14 cursor-pointer text-center fontColor-blue text-uppercase"
          >
            ADD FEEDBACK
          </div>
          )}
        </div>
      )}
    </div>
  </div>
);

export default StudentCard;
