import React from "react";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";

const SankeyNode = ({ name, x0, x1, y0, y1, color, node_height, onClick, ...rest }) => (
  <React.Fragment>
    <rect
      x={x0}
      y={node_height ? 0 : y0}
      width={x1 - x0}
      height={node_height ? node_height : y1 - y0}
      fill={color}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <title>{name}</title>
    </rect>
    <text
      textAnchor="start"
      x={x0 + (x1 - x0) + 10}
      y={y0 + (y1 - y0) / 2}
      style={{
        fontSize: 14,
        color: '#333333',
        fontWeight: 600,
      }}
    >
      {name.match(/.{1,40}\b/g).map((n, i) => (
        <tspan x={x0 + (x1 - x0) + 5} y={(y0 + (y1 - y0) / 2) + (i * 10)} key={i}>{n}</tspan>
      ))}
    </text>
  </React.Fragment>
);

const SankeyLink = ({ link }) => (
  <path
    d={sankeyLinkHorizontal()(link)}
    style={{
      fill: 'none',
      strokeOpacity: ".3",
      stroke: `url(#${link.gradient})`,
      strokeWidth: Math.max(1, link.width)
    }}
    onClick={link.onClick}
  >
    <title>{`${link.source.name} -> ${link.target.name} \nCount: ${link.value}`}</title>
  </path>
);

const MysteriousSankey = ({ data, width, height }) => {
  const { nodes, links } = sankey()
    .nodeWidth(20)
    .nodePadding(10)
    .extent([[1, 1], [width - 1, height - 5]])(data);

  return (
    <g style={{ mixBlendMode: "multiply" }}>
      {nodes.map((node, i) => (
        <SankeyNode
          {...node}
          color={node.color}
          key={node.name}
          height={node.height}
        />
      ))}
      {links.map((link, i) => (
        <SankeyLink
          link={link}
          // color={color(colorScale(link.source.index)).hex()}
        />
      ))}
    </g>
  );
};

export default MysteriousSankey;
