import {Button, Card, Checkbox, Col, Input, Row} from "antd";
import axios from "axios";
import React from "react";
import Form from "react-validation/build/form";
import "../../App.css";
import logo from "../../assets/breadcrumb_logo.png";
import {CHECK_LC_NAME} from "../../graphql.js";
import {openNotification} from "../../utils";
import {isRequired} from "../../validation";
import "../ResetPswd.css";
import "../SignIn/Signin.css";
import "./LCEnroll.css";
import _ from "lodash";

class LCEnroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      checkboxOptions: this.props.currState.checkbox,
      checked_count: 0,
      lcExist: false,
      moreChar: false,
      is_name_available: "",
      form: React.createRef(),
      truthObj: {
        value: false,
        displayName: false,
      },
    };
  }
  
  validateStateValues = () => {
    let truthObj = {};
    let getKeys = Object.keys(this.state.truthObj);
    getKeys.forEach((key, index) => {
      truthObj[key] = isRequired(this.props.currState[key]);
    });
    truthObj['displayName'] = this.props.role_id === "2" ? isRequired(this.props.currState['displayName']) : false;
    setTimeout(() => this.setState({ truthObj: truthObj }), 1000);
    setTimeout(() => this.checkErrorExistence(), 1500);
  };
  
  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj);
    let includesErrors = _.includes(errors, true);
    if (includesErrors) {
      openNotification("error", "Please fill all mandatory fields correctly");
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    this.setState({ isSubmit: true });
    this.props.next();
  };

  onChangeChkbox = i => {
    this.props.onChangeCheckBox(i);
  };

  onChange = (key, value) => {
    this.setState({ lcExist: false });
    this.props.onChangeText(key, value);
  };

  checkTitle = () => {
    let query = CHECK_LC_NAME;
    query.variables = {
      inviteToken: this.props.inviteToken,
      email: this.props.inviteEmail,
      name: this.props.currState.value
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let chk = res.data.data.checkLearningCentreNameV2.is_available;
        this.setState({ is_name_available: chk });
        if (res.data.data.checkLearningCentreNameV2.is_available) {
          this.setState({ lcExist: true });
        } else {
          this.setState({ act_name_check: this.props.currState.value });
        }
      })
      .catch(err => console.log(err));
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      // <div>
      <div className="card-wrap lc-onboarding">
        <div className="">
          <img src={logo} className="logo" alt="logo" />
          <Card className="enroll-card">
            <Form
              ref={this.state.form}
              className={this.state.isSubmit ? "formSubmitted" : ""}
            >
              <h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18">{`Please fill the following details to enroll as a ${
                this.props.role_id === "4"
                  ? "Expert"
                  : this.props.role_id === "5"
                  ? "Curator"
                  : "Learning Centre"
              }`}</h3>
              <Row>
                <Col span={this.props.role_id === "2" ? 12 : 24} className="pr-3">
                  <div className="position-relative">
                    <div className="titleColor font-weight-300 font16 mb-3 text-uppercase">
                      {`${
                        this.props.role_id === "4"
                          ? "Expert"
                          : this.props.role_id === "5"
                          ? "Curator"
                          : "Learning Centre"
                      } name`}
                    </div>
                    <div className="d-flex flex-column align-items-end justify-content-start">
                      <Input
                        size="large"
                        className="mb-2"
                        id={this.state.truthObj["value"] ? "has-error": ""}
                        value={this.props.currState.value}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            this.onChange("value", e.target.value)
                          }
                        }}
                        onPaste={(e) => {
                          if (e.clipboardData.getData('Text').length > 32) {
                            this.onChange("value", e.clipboardData.getData('Text').substring(0, 32));
                          }
                        }}
                        placeholder={`Enter ${
                          this.props.role_id === "4"
                            ? "Expert"
                            : this.props.role_id === "5"
                            ? "Curator"
                            : "Learning Centre"
                        } name`}
                      />
                      <span style={this.props.currState.value.length >= 32 ? { color: "#FF0A00" } : {}}>
                        {this.props.currState.value.length} / 32
                      </span>
                    </div>
                    {this.state.lcExist && (
                      <div className="position-absolute form-error">
                        Name already exist
                      </div>
                    )}
                  </div>
                </Col>
                {this.props.role_id === "2" && (
                  <Col span={12}>
                    <div className="titleColor font-weight-300 font16 mb-3 text-uppercase">
                      {`${
                        this.props.role_id === "4"
                          ? "Expert"
                          : this.props.role_id === "5"
                          ? "Curator"
                          : "Learning Centre"
                      } display name`}
                    </div>
                    <div className="d-flex flex-column align-items-end justify-content-start">
                      <Input
                        size="large"
                        className="mb-2"
                        id={this.state.truthObj["displayName"] ? "has-error": ""}
                        value={this.props.currState.displayName}
                        onChange={(e) => {
                          if (e.target.value.length <= 20) {
                            this.onChange("displayName", e.target.value)
                          }
                        }}
                        onPaste={(e) => {
                          if (e.clipboardData.getData('Text').length > 20) {
                            this.onChange("displayName", e.clipboardData.getData('Text').substring(0, 20));
                          }
                        }}
                        placeholder={`Enter ${
                          this.props.role_id === "4"
                            ? "Expert"
                            : this.props.role_id === "5"
                            ? "Curator"
                            : "Learning Centre"
                        } display name`}
                      />
                      <span style={this.props.currState.displayName.length >= 20 ? { color: "#FF0A00" } : {}}>
                      {this.props.currState.displayName.length} / 20
                    </span>
                    </div>
                  </Col>
                )}
              </Row>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="FontAvenirRoman text-secondary">
                    SELECT INTEREST CATEGORIES
                  </span>
                  <span
                    className="FontAvenirRoman color-blue ml-3 pointer-cursor"
                    onClick={this.props.selectAll}
                  >
                    Select All
                  </span>
                  <span
                    className="FontAvenirRoman color-blue ml-3 pointer-cursor"
                    onClick={this.props.clearAll}
                  >
                    Clear All
                  </span>
                </div>
                {this.props.currState.checkboxOptions.filter(
                  data => data.is_active
                ).length > 0 && (
                  <div>
                    {
                      this.props.currState.checkboxOptions.filter(
                        data => data.is_active
                      ).length
                    }{" "}
                    Interests categories selected
                  </div>
                )}
              </div>

              <Row
                className="chkbox"
                style={{ height: "30vh", overflowY: "scroll" }}
              >
                {this.props.currState.checkboxOptions.map((data, i) => (
                  <Col
                    span={7}
                    className="lc-onboarding__card"
                    key={i}
                    style={{
                      backgroundImage: `linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(0, 0, 0, 0.87) 125%), url(${data.background_image})`
                    }}
                  >
                    <Checkbox
                      key={i}
                      name={data.name}
                      value={data.name}
                      checked={data.is_active}
                      onChange={() => this.onChangeChkbox(i)}
                    />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: 17,
                        fontWeight: 900,
                        marginBottom: 7
                      }}
                    >
                      {data.name}
                    </span>
                  </Col>
                ))}
              </Row>
              <div className="border-bottom pt-4 mb-4" />
              <div className="d-flex justify-content-between">
                <Button size="large" onClick={() => this.props.prev()}>
                  Back
                </Button>
                <Button
                  onClick={this.validateStateValues}
                  size="large"
                  type="primary"
                  className="f-r"
                >
                  Continue
                </Button>
              </div>
            </Form>
          </Card>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

// const ResetPswd = Form.create()(LCEnroll);

export default LCEnroll;
