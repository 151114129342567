import React from "react";
import { Redirect, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import { getToken } from "./utils";
import Nav from "./components/Nav";

class GAComponent extends React.Component{

  componentDidUpdate() {
    this.props.history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    const { Component } = this.props;
    return (
      <Component {...this.props} />
    );
  }
}

const Auth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      getToken() ? (
        <div>
          <Nav {...props} />
          <GAComponent Component={Component} {...props} />
        </div>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default Auth;
