import { Input, Layout } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CollectionCard from "../../components/Expert/CollectionCard";
import Filter from "../../components/filter";
import Sort from "../../components/Sort";
import {
  ACTIVITY_FILTER_QUERY,
  LIST_EXPERT_COLLECTION_PLAYLIST
} from "../../graphql";
import emptyState from "../../static/activity/emptystate.svg";

const { Content } = Layout;
const Search = Input.Search;

const TitleContainer = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
`;
const CollectionContainer = props => {
  return (
    <div>
      {!props.noSort && (
        <Sort data={props.sort} sortChange={props.sortChange} />
      )}
      <div className="row m-0 tabscroll">
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={page => {
            if (!props.isLoading) {
              props.loadItems(page);
            }
          }}
          className="w-100 row m-0"
          hasMore={props.hasMoreItems}
          loader={
            <React.Fragment>
              {[{}, {}, {}, {}].map((data, index) => {
                return (
                  <div
                    className="col-4 pointer-events-none"
                    key={`loading-${index}`}
                  >
                    <CollectionCard
                      loading={true}
                      title={data.title}
                      noMargin
                      src={data.background_image}
                    ></CollectionCard>
                  </div>
                );
              })}
            </React.Fragment>
          }
        >
          {props.data.map((data, index) => {
            return (
              <div className="col-4" key={index}>
                {/* <CollectionCard src="http://altius-testing-images.s3-website.ap-south-1.amazonaws.com/341x192/2JH3BTbKx5batman2.jpg"></CollectionCard> */}
                <Link
                  to={{
                    pathname: `${
                      data.is_playlist ? "playlist" : "collection"
                    }/${data.id}/view`
                  }}
                >
                  <CollectionCard
                    loading={props.loader}
                    title={data.title}
                    subTitle={`By ${(data.user || {}).name}`}
                    src={data.background_image}
                    collectionIcon={data.is_playlist}
                  ></CollectionCard>
                </Link>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export class ContentCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_id: 2,
      slider: true,
      filter: {},
      searchText: "",
      filterShowRadio: "all_contents",
      // filterData: {
      //   interest_details: [],
      //   age_groups: [],
      //   marks: {
      //     0: "",
      //     100: ""
      //   }
      // },
      getAllActivity: [],
      only_draft: false,
      only_playlist: true,
      only_collection: true,
      isAddedToMyLC: false,
      total_count: 0,
      get_pending_only: false,
      sort: "newest",
      activeTab: "3",
      PublishedCollection: [],
      LearningPath: [],
      ArchivedCollection: [],
      page: 1,
      adminApprovedActivities: [],
      adminPendingActivities: [],
      is_collection_loading: false,
      isActivity_loading: false,
      filterData: {
        interest_details: [],
        age_groups: [],
        marks: {
          0: 0,
          100: 1000
        },
        time: {
          min: 0,
          max: 1000
        }
      },
      tab1Page: 1,
      tab1Count: 0,
      tab2Count: 0,
      tab2Page: 1,
      tab1More: true,
      tab2More: true,
      switchContentTab: false
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }
  componentDidMount() {
    this.setState(
      {
        role_id: Number(localStorage.getItem("role_id"))
      },
      () => {
        this.setState({
          activeTab:
            this.state.role_id === 1
              ? "1"
              : this.props.history.location.search.split("=")[1]
              ? this.props.history.location.search.split("=")[1]
              : "3"
        });
        this.fetchFilter();
        this.fetchAllData();
      }
    );
  }
  onSearch = e => {
    this.setState(
      {
        searchText: e.target.value,
        fetchData: true,
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        is_collection_loading: true,
        isActivity_loading: true,

        LearningPath: []
      },
      () => this.onSearchFetchData()
    );
  };

  onSearchFetchData = () => {
    // triggerGAEvent('Activities', 'Searching Activities');
    this.fetchAllData();
  };
  clearFilter = () => {
    // triggerGAEvent('Learning Content', 'Clearing Filters in Activities');
    this.setState({ slider: false });
    setTimeout(() => {
      this.setState({ slider: true });
    }, 1);
    const filterData = { ...this.state.filterData };
    filterData.age_groups = filterData.age_groups.map(data => {
      data.is_active = false;
      return data;
    });
    filterData.interest_details = filterData.interest_details.map(data => {
      data.is_active = false;
      return data;
    });
    this.setState(
      {
        filterData: filterData,
        filter: {},
        fetchData: true,

        LearningPath: [],

        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true
      },
      () => this.fetchAllData()
    );
  };
  fetchFilter = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, ACTIVITY_FILTER_QUERY)
      .then(res => {
        const filterData = res.data.data.getWebFilterData;
        filterData.age_groups =
          res.data.data.getWebFilterData.difficulty_details.age_groups;
        filterData.marks = {
          0: Math.ceil(res.data.data.getWebFilterData.time.min),
          100: Math.ceil(res.data.data.getWebFilterData.time.max)
        };
        this.setState({
          filterData: res.data.data.getWebFilterData
        });
      })
      .catch(err => console.log(err));
  };

  filterRadioChange = e => {
    // triggerGAEvent('Learning centre', 'Changing Filters in Activities');
    // console.log(e.target.value);
    this.setState(
      {
        get_pending_only: e.target.value === "pending",
        only_draft: e.target.value ? e.target.value !== "pending" : false,
        getAllActivity: [],
        LearningPath: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true
      },
      () => this.fetchAllData()
    );
  };
  fetchData = () => {
    const query = LIST_EXPERT_COLLECTION_PLAYLIST;
    this.setState({ is_collection_loading: true });
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText.trim(),
      only_playlist: this.state.only_playlist,
      only_collection: this.state.only_collection,
      filter: this.state.filter,
      added: this.state.isAddedToMyLC
      // user_id
      // page: this.state.tab1Page
    };
    // alert(this.state.tab1Page);
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const cards = res.data.data.ListExpertCollectionPlaylist.cards
          ? res.data.data.ListExpertCollectionPlaylist.cards
          : [];
        let LearningPath = cards;
        if (cards.length === 0 || cards.length < 20) {
          // alert();
          this.setState({ tab1More: false });
        }

        this.setState({
          LearningPath:
            this.state.tab1Page === 1
              ? [...LearningPath]
              : [...this.state.LearningPath, ...LearningPath],
          tab1More: false
        });
        setTimeout(() => {
          this.setState({
            is_collection_loading: false
          });
        }, 100);
      })
      .catch(e => {
        this.setState({ is_collection_loading: false });

        // console.log(e);
      });
  };

  filterTimeChange = value => {
    // triggerGAEvent('Learning centre', 'Changing Filters in Activities');
    const state = { ...this.state };
    state.filter.time = value;
    this.setState(
      {
        filter: state.filter,
        fetchData: true,

        getAllActivity: [],

        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true
      },
      () => this.fetchAllData()
    );
  };
  onChange = (key, index, value) => {
    // triggerGAEvent('Learning Content', 'Changing Filters in Activities');
    // console.log(key, value, index);
    const state = { ...this.state };
    state.filterData[key][index].is_active = value;
    if (key === "age_groups") {
      state.filter["age"] = state.filterData[key]
        .filter(data => data.is_active)
        .map(data => parseInt(data.id, 10));
    }
    if (key === "interest_details") {
      state.filter["interest"] = state.filterData[key]
        .filter(data => data.is_active)
        .map(data => parseInt(data.id, 10));
    }
    // alert();
    this.setState(
      {
        filterData: state.filterData,
        fetchData: true,
        filter: state.filter,
        getAllActivity: [],
        LearningPath: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        switchContentTab: true,
        is_collection_loading: true,
        isActivity_loading: true
      },
      () => this.fetchAllData()
    );
  };
  fetchAllData = () => {
    if (this.state.role_id === 2) {
      this.fetchData();
    }
  };

  sortChange = value => {
    // triggerGAEvent('Content Learning', 'Changing Sort in Activities');
    this.setState(
      {
        sort: value,
        getAllActivity: [],
        adminApprovedActivities: [],
        adminPendingActivities: [],
        PublishedCollection: [],
        LearningPath: [],

        ArchivedCollection: [],
        page: 1,
        tab1Page: 1,
        tab2Page: 1,
        tab1More: true,
        tab2More: true,
        fetchData: true
      },
      () => {
        this.fetchAllData();
        // this.fetchAllActivities();
      }
    );
  };
  
  onChangeFilterShowRadio = (e) => {
    let value = e.target.value;
    this.setState({
      filterShowRadio: value,
      isAddedToMyLC: value === "isAddedToMyLC",
      only_collection: value === "all_contents" || value === "isAddedToMyLC" || value === "only_collections",
      only_playlist: value === "all_contents" || value === "isAddedToMyLC" || value === "only_playlist",
    }, () => this.fetchData());
  };

  onChangeShowCheckbox = (key, value) => {
    this.setState(
      {
        [key]: value,
        is_collection_loading: true,
        isActivity_loading: true,
        LearningPath: []
      },
      () => {
        this.fetchData();
      }
    );
  };
  loadTab1 = page => {
    if (this.state.tab1More) {
      this.setState(
        {
          tab1Page: this.state.tab1Page + 1,
          is_collection_loading: true
        },
        () => {
          if (this.state.role_id === 1) {
            // this.fetchPendingActivities();
          } else {
            // this.fetchAllActivities();
            this.fetchData();
          }
        }
      );
    }
  };

  render() {
    // console.log(
    //   this.state.activeTab === "1" ||
    //     this.state.activeTab === "2" ||
    //     this.state.activeTab === "4",
    //   this.state.activeTab === "3"
    //     ? this.state.role_id === 4 || this.state.role_id === 2
    //     : false
    // );
    return (
      <Layout className="createActivity">
        <div className="row m-0 mt-4">
          <div className="col p-0 min-300">
            {this.state.slider && (
              <Filter
                interestData={this.state.filterData.interest_details}
                interestDataKey="interest_details"
                ageData={this.state.filterData.age_groups}
                ageDataKey="age_groups"
                onCheckChange={this.onChange}
                time={this.state.filterData.marks}
                timeChange={this.filterTimeChange}
                radioData={this.state.only_draft}
                pendingOnly={this.state.get_pending_only}
                radioChange={this.filterRadioChange}
                clearFilter={this.clearFilter}
                isContentCreator
                showDraft={false}
                showCollectionFilter={true}
                isCollectionSelected={this.state.only_collection}
                isPlaylistSelected={this.state.only_playlist}
                // isAddedToMyLC={this.state.isAddedToMyLC}
                // onChangeShowCheckbox={this.onChangeShowCheckbox}
                filterShowRadio={this.state.filterShowRadio}
                onChangeFilterShowRadio={this.onChangeFilterShowRadio}
                showTimeFilter={
                  this.state.role_id !== 1
                    ? this.state.activeTab === "1" ||
                      this.state.activeTab === "2" ||
                      this.state.activeTab === "4"
                    : false
                }
              />
            )}
          </div>
          <div className="col-10 p-0">
            <Content>
              <div className="col-12 row mb-3 d-flex text-right justify-content-between align-items-center">
                <TitleContainer className="mb-0 ml-2">
                  Content creators
                </TitleContainer>

                <div className="d-flex text-right justify-content-end align-items-center">
                  <Search
                    size="large"
                    placeholder={`Search`}
                    onChange={this.onSearch}
                    style={{ width: 400 }}
                    className="mr-3"
                    allowClear
                  />
                </div>
              </div>
              {this.state.role_id !== 1 && (
                <React.Fragment>
                  {this.state.LearningPath.length === 0 && (
                    <React.Fragment>
                      {this.state.is_collection_loading && (
                        <div className="pointer-events-none">
                          <CollectionContainer
                            data={[{}, {}, {}]}
                            isLoading
                            loader
                          ></CollectionContainer>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    {this.state.LearningPath.length !== 0 && (
                      <CollectionContainer
                        sort={this.state.sort}
                        page={this.state.tab1Count}
                        hasMoreItems={this.state.tab1More}
                        loadItems={this.loadTab1}
                        sortChange={this.sortChange}
                        data={this.state.LearningPath}
                        isLoading={this.state.is_collection_loading}
                      ></CollectionContainer>
                    )}
                    {!this.state.is_collection_loading && (
                      <React.Fragment>
                        {this.state.LearningPath.length === 0 && (
                          <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                            <img className="mb-3" src={emptyState} alt="" />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                Contents created by experts / curators will
                                appear here
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                  {/* )} */}
                </React.Fragment>
              )}
            </Content>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContentCreator;
